import { FC, useRef } from 'react';
import Icon from 'components/icons/Icon';
import { NodeInterface } from 'models/VisualExplainPlan';
import { PercentageBackgroundBar } from 'components/visualizations/BackgroundBar';
import Quantity from 'components/numbers/Quantity';
import { fromName as unitFromName } from 'models/numbers/Unit';
import Qty from 'models/numbers/Quantity';

interface SidebarPropsInterface {
    node: NodeInterface | null;
    onClose: () => void;
}

const Sidebar: FC<SidebarPropsInterface> = ({ node, onClose }) => {
    // the ref is used to display the stale node while the sidebar animates out of view
    const displayNode = useRef(node);
    if (node) displayNode.current = node;

    const formatValue = (value: unknown) => {
        if (typeof value === 'number') {
            return <Quantity quantity={new Qty(value as number, unitFromName('e0'))} />;
        } else {
            return String(value);
        }
    };

    return (
        <div className={`sidebar__selection${node ? ' sidebar__selection--open' : ''}`} data-testid="vep-sidebar">
            {displayNode.current && (
                <>
                    <Icon
                        icon="close"
                        className="flex h2 mainColor absolute right-2 top-2"
                        onClick={onClose}
                        data-testid="dialog-close-button"
                    />
                    <h3 id="nodeLabel" title={displayNode.current.label} className="fz16 bold truncate no-shrink">
                        {displayNode.current.label}
                    </h3>

                    <PercentageBackgroundBar
                        percentage={displayNode.current.display.cost.percentage}
                        className="container vc-viz-background-bar--grey1 no-shrink"
                    />

                    <div className="overflow-auto">
                        <table
                            aria-describedby="nodeLabel"
                            className="full-width layout-fixed sidebar__selection__table"
                        >
                            <tbody>
                                {Object.entries(displayNode.current.extra).map(([key, value]) => (
                                    <tr className="left-align fz12" key={key}>
                                        <th
                                            className="semiDark regular px1 truncate sidebar__selection__table__key"
                                            title={key}
                                            scope="row"
                                        >
                                            {`${key}:`}
                                        </th>
                                        <td className="py2 bold truncate" title={String(value)}>
                                            {formatValue(value)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default Sidebar;
