import { useEffect, useState, FC, Fragment } from 'react';
import { userIsAllowedTo } from 'services/security/accessControl';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import { getHostClusters } from 'services/api/hosts';
import Host from 'models/hosts/Host';
import Group from 'models/hosts/NodeGroup';
import HostsNotInAnyGroupTable from './HostsNotInAnyGroup';
import NodeHostRow from './NodeHostRow';
import NodeGroupRow from './NodeGroupRow';
import GroupsLoader from './GroupsLoader';
import { useTimeInterval } from 'components/context/TimeInterval';
import Logger from 'services/logger';
import InventoryControls, { InventoryType } from '../Controls';

const logger = Logger.get('GroupsTable');

const Groups: FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [cluster, setCluster] = useState<Group | null>(null);
    const [collapsedHosts, setCollapsedHosts] = useState<number[]>([]);

    const { interval } = useTimeInterval();

    function setRowsStatus(expanded: boolean) {
        if (expanded) {
            setCollapsedHosts([]);
        } else {
            const newCollapsedHosts: number[] = [];

            cluster?.groups.forEach(group => {
                group.asArray.forEach(item => {
                    const host = item instanceof Host ? item : item.host;
                    if (host.cluster) {
                        newCollapsedHosts.push(host.cluster);
                    }
                });
            });

            setCollapsedHosts(newCollapsedHosts);
        }
    }

    const fetchHosts = async () => {
        try {
            setIsLoading(true);
            const clusters = await getHostClusters(interval);
            setCluster(clusters.cluster);
        } catch (e) {
            logger.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggleClick = (group: Group) => {
        let newCollapsedHosts;
        const index = collapsedHosts.indexOf(group.host.id);

        const childrenGroupIds = group.asArray.filter(item => item instanceof Group).map(g => (g as Group).host.id);

        if (index > -1) {
            // unmark the group and the group children as collapsed
            newCollapsedHosts = collapsedHosts.filter(
                collapsedHost => !childrenGroupIds.find(child => collapsedHost === child)
            );
            newCollapsedHosts.splice(index, 1);
        } else {
            // mark the group and the group children as collapsed
            newCollapsedHosts = [...collapsedHosts, ...childrenGroupIds];
            newCollapsedHosts.push(index);
        }

        setCollapsedHosts(newCollapsedHosts);
    };

    const handleHostDeletion = () => {
        // Deleting a host from the clusters structure and updating the groups state is not trivial, so we're re-fetching the hosts after deletion.
        // This can be improved in a new iteration.
        fetchHosts();
    };

    const isCollapsed = (host: Host) => {
        return collapsedHosts.indexOf(host.id) > -1;
    };

    const isClusterCollapsed = (host: Host) => {
        return !!(host.cluster && collapsedHosts.indexOf(host.cluster) > -1);
    };

    const getGroupRow = (group: Group | Host) => {
        return group instanceof Group && !isClusterCollapsed(group.host) ? (
            <NodeGroupRow
                group={group}
                className={`level${group.level}`}
                onToggle={handleToggleClick}
                isCollapsed={isCollapsed(group.host)}
            />
        ) : null;
    };

    const getHostRow = (host: Group | Host) => {
        return host instanceof Host && !isClusterCollapsed(host) ? (
            <NodeHostRow className={`level${host.level}`} host={host} onHostDeletion={handleHostDeletion} />
        ) : null;
    };

    useEffect(() => {
        fetchHosts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval]);

    return (
        <div className="view view--filters view--salesforce inventory">
            <InventoryControls type={InventoryType.groups} onExpandToggle={setRowsStatus} />
            <>
                {!isLoading && cluster?.groups.length === 0 && (
                    <EmptyState type={EmptyTypes.INFO} fullSize={false}>
                        There are no node groups to show.
                    </EmptyState>
                )}
                {!isLoading && !cluster?.groups && (
                    <EmptyState type={EmptyTypes.SERVER} reload={true}>
                        There was an error while fetching the groups.
                    </EmptyState>
                )}
                {(isLoading || !!cluster?.groups?.length) && (
                    <table
                        className="vc-table vc-table--machines--nodes full-width pb3 vc-table--sortable"
                        data-testid="groups-table"
                    >
                        <thead>
                            <tr>
                                <th className="vc-table--machines__toggler"></th>
                                <th className="servers-table__name" colSpan={3}>
                                    Name
                                </th>
                                <th className="servers-table__host" colSpan={2}>
                                    OS Host
                                </th>
                                <th className="servers-table__lastseen">Last Seen</th>
                                <th className="servers-table__tags">Tags</th>
                                {userIsAllowedTo('changeEnvSettings') && <th>Settings</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <GroupsLoader cols={userIsAllowedTo('changeEnvSettings') ? 6 : 5} />}
                            {!isLoading &&
                                cluster?.groups.map(group => {
                                    return group.asArray.map(item => {
                                        return (
                                            <Fragment key={item instanceof Group ? item.host.id : item.id}>
                                                {getGroupRow(item)}
                                                {getHostRow(item)}
                                            </Fragment>
                                        );
                                    });
                                })}
                        </tbody>
                    </table>
                )}
                {cluster?.hosts?.length && (
                    <HostsNotInAnyGroupTable hosts={cluster.hosts} onHostDeletion={handleHostDeletion} />
                )}
            </>
        </div>
    );
};

export default Groups;
