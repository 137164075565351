import { get } from 'services/http';

const ENDPOINT = 'https://dpm.statuspage.io/api/v2/incidents/unresolved.json';

export interface IncidentDataInterface {
    id: string;
    impact: 'major' | 'minor';
    name: string;
    shortlink: string;
}

interface IncidentResponseDataInterface {
    page: {
        id: string;
        name: string;
        url: string;
        time_zone: string;
        updated_at: string;
    };
    incidents: IncidentDataInterface[];
}

export async function getIncidents() {
    const response = await get<IncidentResponseDataInterface>(ENDPOINT);

    return response.data.incidents;
}
