import Icon from 'components/icons/Icon';
import * as React from 'react';
import useAuthenticatedContext from 'hooks/useAuthenticatedContext';
import { SettingsNavItems, SettingsNavItemInterface } from 'providers/settings/NavItems';
import { FeatureNavLink } from 'components/url/FeatureLink';
import Feature from 'components/serviceLevel/Feature';

const SettingsLink: React.FC<{ setting: SettingsNavItemInterface }> = ({ setting }) => {
    return (
        <li className="menu__item relative">
            <FeatureNavLink
                to={setting.link}
                activeClassName="menu__item--selected"
                className="menu__item__title white no-decoration flex full-width items-center px2 bold fz16"
                title={setting.title}
            >
                <Icon icon={setting.icon} className="menu__item__title__icon flex grey3 h2 mr2" />
                <span>{setting.title}</span>
            </FeatureNavLink>
        </li>
    );
};

const SettingsNavigation = () => {
    const { env } = useAuthenticatedContext();
    const navItems = new SettingsNavItems(env).getItems();

    return (
        <div className="settings__nav">
            <nav className="flex flex-column">
                {navItems.map(navItem => (
                    <div key={navItem.header}>
                        <div className="navigation__group__title menu__item__title white no-decoration flex full-width items-center px2 bold fz16 cursor-hand user-select navigation__group__title--shown">
                            <Icon className="menu__item__title__icon flex grey3 h2" icon="organization-settings" />
                            <span>{navItem.header}</span>
                        </div>
                        <ul className="menu__item__title__list list-reset full-width overflow-hidden">
                            {navItem.items.map((setting, index) => {
                                const link = <SettingsLink key={index} setting={setting}></SettingsLink>;
                                return setting.title === 'Anomaly Detection' ? (
                                    <Feature name="anomalies" key={setting.title}>
                                        {link}
                                    </Feature>
                                ) : (
                                    link
                                );
                            })}
                        </ul>
                    </div>
                ))}
            </nav>
        </div>
    );
};

export default SettingsNavigation;
