export type ColumnType = 'text' | 'quantity' | 'age';
export type RenderAs = 'main' | ColumnType;

export interface ColumnDefinitionInterface {
    id: string;
    title: string;
    type: ColumnType;
    unit: string;
    summarizeAs: string;
    rankable?: boolean;
}

export interface ColumnInterface extends ColumnDefinitionInterface {
    renderAs: RenderAs;
}

export interface QuantityColumnInterface extends ColumnInterface {
    renderAs: 'quantity';
}

export interface MainColumnInterface extends ColumnInterface {
    renderAs: 'main';
}

abstract class AbstractColumn {
    constructor(private definition: ColumnDefinitionInterface) {}

    get id() {
        return this.definition.id;
    }

    get title() {
        return this.definition.title;
    }

    get summarizeAs() {
        return this.definition.summarizeAs;
    }

    get type() {
        return this.definition.type;
    }

    get unit() {
        return this.definition.unit;
    }

    get rankable() {
        return !!this.definition.rankable;
    }
}

export class RegularColumn extends AbstractColumn implements ColumnInterface {
    get renderAs(): RenderAs {
        return this.type as RenderAs;
    }
}

export class MainColumn extends AbstractColumn implements ColumnInterface {
    get renderAs(): RenderAs {
        return 'main';
    }
}
