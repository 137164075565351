import APIRequest from 'services/api/Request';
import axios, { AxiosResponse, AxiosError } from 'axios';
import Logger from 'services/logger';
import moment from 'moment-timezone';
import { updateServerTimestamp } from 'services/time';
import { onSessionExpired } from './sessionStatus';

let networkCheckInProgress = false;
let checkTimeout: ReturnType<typeof setTimeout> | null = null;
let networkOnline = true;
let networkStatusRequest: APIRequest<null> | null;
let sessionActive = true;

const REQUEST_TIMEOUT = 4000;
const TIMEOUT_FREQUENCY = REQUEST_TIMEOUT + 3000;
const logger = Logger.get('networkStatus');

onSessionExpired(() => {
    sessionActive = false;
    if (checkTimeout) {
        clearTimeout(checkTimeout);
        checkTimeout = null;
    }
});

async function checkNetworkStatus() {
    logger.info('Checking network status.');

    networkCheckInProgress = true;

    if (checkTimeout) {
        clearTimeout(checkTimeout);
        checkTimeout = null;
    }

    if (networkStatusRequest && networkStatusRequest.isInProgress) {
        networkStatusRequest.cancel();
    }

    networkStatusRequest = null;

    if (networkOnline) {
        return;
    }

    if (!sessionActive) {
        // Do not call the API ping if session is lost
        return;
    }

    networkStatusRequest = new APIRequest<null>({
        path: 'ping',
        timeout: REQUEST_TIMEOUT,
        method: 'head',
        requiresAuth: false,
    });

    try {
        await networkStatusRequest.request();

        networkOnline = true;
        networkCheckInProgress = false;

        logger.info('Network is online.');

        document.dispatchEvent(new CustomEvent('networkStateChanged', { detail: 'online' }));

        return;
    } catch (e) {
        logger.info(`Network is offline. Retrying in ${TIMEOUT_FREQUENCY}ms.`);
        document.dispatchEvent(new CustomEvent('networkStateChanged', { detail: 'offline' }));
    }

    checkTimeout = setTimeout(checkNetworkStatus, TIMEOUT_FREQUENCY);
}

const successfulRequestInterceptor = (response: AxiosResponse) => {
    const timestamp = moment(response.headers.date).unix();

    updateServerTimestamp(timestamp);

    return response;
};

const failedRequestInterceptor = (error: AxiosError) => {
    if (error.response?.status === undefined && !networkCheckInProgress) {
        networkOnline = false;
        checkNetworkStatus();
    }

    return Promise.reject(error);
};

export function init() {
    axios.interceptors.response.use(successfulRequestInterceptor, failedRequestInterceptor);
}

export const isOnline = () => networkOnline;
