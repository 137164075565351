import * as React from 'react';
import TimePicker from 'components/timePicker/TimePicker';
import Sharer from 'components/sharer/Sharer';
import HostsSelector from 'components/host/HostsSelector';
import AutoRefresh from 'components/autoRefresh/AutoRefresh';
import useAuthenticatedContext from 'hooks/useAuthenticatedContext';
import { isCurrentLocationSettings } from 'services/routes';
import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';
import { useLocation } from 'react-router-dom';
import Icon from 'components/icons/Icon';

const MembersHeader: React.FC<{ sidebarCollapsed: boolean; fullscreen: boolean; children?: React.ReactNode }> = ({
    children,
    sidebarCollapsed = false,
    fullscreen = false,
}) => {
    const context = useAuthenticatedContext();
    const hasEnv = !!context.env;
    const { pathname } = useLocation();
    const { readOnly: hostSelectorIsReadOnly } = useHostFilter();
    const { readOnly: timePickerIsReadOnly } = useTimeInterval();

    const headerClassNames = `fixed top-0 full-width z2 ${hasEnv ? '' : 'header-no-environment'} ${
        sidebarCollapsed ? 'nav-collapsed' : ''
    }`;

    return (
        <header className={headerClassNames} data-testid="header">
            <div className="bg-vantaDark main-header full-height">
                <div
                    data-testid="command-bar"
                    className="flex flex-row justify-between full-width pr2 header__command-bar full-height"
                >
                    {!fullscreen && (
                        <div className="logo flex" data-testid="site-logo">
                            <a
                                href="/"
                                target="_self"
                                title="Organization Home"
                                className="flex full-height justify-center items-center bg-swiOrange"
                            >
                                <Icon icon="logo" className="flex white fz12 cursor-hand" />
                            </a>
                            <h1 className="logo__name absolute white flex items-center bold fz18 justify-center flex-grow-1 bg-vantaDark">
                                DPM
                            </h1>
                        </div>
                    )}
                    <div className="flex flex-grow-1 justify-end relative z1 header__host-selector">
                        {hasEnv && !isCurrentLocationSettings(pathname) && (
                            <>
                                <div className="flex items-start justify-end flex-grow-1 mr2 mt2 host-filter z1">
                                    <HostsSelector readOnly={hostSelectorIsReadOnly} />
                                </div>
                                <TimePicker
                                    className="header__tool header__tool--time-picker flex items-center z1 flex-shrink"
                                    data-testid="command-bar-timepicker"
                                    readOnly={timePickerIsReadOnly}
                                />
                            </>
                        )}
                    </div>
                    {hasEnv && !isCurrentLocationSettings(pathname) && (
                        <div className="header__tool header__tool--sharer flex items-center z1 ml2">
                            <AutoRefresh />
                            <Sharer />
                        </div>
                    )}
                    <div className="header__tool header__tool--changelog flex items-center z1 header__changelog--settings ml2">
                        {children}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default MembersHeader;
