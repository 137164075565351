import Host, { HostDataInterface } from './Host';
import NodeGroup from './NodeGroup';
import Logger, { LoggerInterface } from 'services/logger';

interface GroupsByIdInterface {
    [id: number]: NodeGroup;
}

const rootHost: HostDataInterface = {
    id: 0,
    rootHost: true,
    name: '',
    parent: 0,
    cluster: 0,
    created: 0,
    deleted: 0,
    lastSeen: 0,
    description: '',
    options: {},
    type: 'mysql',
    os: '',
    status: 0,
    tags: [],
    updated: 0,
    uuid: '',
    bindings: null,
    isNameUnique: true,
};

export default class HostClusters {
    private logger: LoggerInterface;

    hosts: Host[] = [];
    machines: NodeGroup[];
    cluster: NodeGroup;

    constructor(private responseData: HostDataInterface[]) {
        this.logger = Logger.get('HostsClusters');

        const [group, osHosts] = this.buildClusters(responseData);

        const asArray: Host[] = (group as NodeGroup).asArray
            .map(node => {
                if (node instanceof NodeGroup) {
                    return node.host;
                }
                return node;
            })
            .filter(host => !host.rootHost);

        this.hosts = [...asArray, ...(osHosts as Host[])];

        this.cluster = group as NodeGroup;

        this.machines = this.buildMachines().groups;
    }

    private buildMachines() {
        // Root NodeGroup as the root for the machines hierarchy
        const rootGroup = new NodeGroup(new Host(rootHost));
        // temporary index
        const groupsById: GroupsByIdInterface = {};

        // create one NodeGroup per OS host
        this.hosts
            .filter(host => host.isOs)
            .map(host => new NodeGroup(host))
            .forEach(function (newGroup) {
                // store the new group in the temporary index
                groupsById[newGroup.host.id] = newGroup;

                rootGroup.addGroup(newGroup);
            });

        // Attach database hosts to the corresponding groups
        this.hosts
            .filter(host => host.isDatabase)
            .forEach(host => {
                const group = groupsById[host.parent?.id || 0];

                if (!group) {
                    this.logger.error(new Error(`[HostsClusters] - cant find group for host ${host.parent?.id}`));
                    return;
                }

                group.addHost(host);
            });

        return rootGroup;
    }

    /**
     * Recursively builds Host and NodeGroup instances from the api reponse {data} and organizes them in the {group} object
     */
    private buildClusters(data: HostDataInterface[], group?: NodeGroup, osHosts: Host[] = [], level = 0) {
        // Root host to hold all the tree structure
        group = group || new NodeGroup(new Host(rootHost), level);

        data.forEach(hostData => {
            const parent = this.responseData.find(host => host.id === hostData.parent);
            const h = new Host(hostData, parent);

            if (h.isOs) {
                osHosts.push(h);

                // ignore OS hosts, they are not part of the clusters inventory
                return;
            }

            if (h.isDatabase) {
                h.level = level + 1;
                group?.addHost(h);
            } else if (h.isGroup) {
                level += 1;
                const g = new NodeGroup(h, level);
                if (hostData.children) {
                    this.buildClusters(hostData.children, g, osHosts, level);
                    group?.addGroup(g);
                }
            }
        });

        return [group, osHosts];
    }
}
