import Host from './Host';

export default class NodeGroup {
    host: Host;
    hosts: Host[] = [];
    groups: NodeGroup[] = [];
    level: number;

    constructor(host: Host, level = 0) {
        this.host = host;
        this.level = level;
    }

    addHost(host: Host) {
        this.hosts.push(host);
    }

    addGroup(group: NodeGroup) {
        this.groups.push(group);
    }

    isRoot() {
        // This is just a convention, we create the root nodes with a fake host of id = 0
        return this.host.id === 0;
    }

    /**
     * Returns an array containing all of the hosts in this group
     * Not only the "leaf" hosts are returned, but also the hosts representing nested groups
     */
    get asArray() {
        const result: (NodeGroup | Host)[] = [];

        // add the host representing this group (a shard, for example)
        result.push(this);

        // Add the group's hosts into the array
        Array.prototype.push.apply(result, this.hosts);

        // recurse into the groups of this group
        this.groups.forEach(group => {
            Array.prototype.push.apply(result, group.asArray);
        });

        return result;
    }
}
