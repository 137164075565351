import { AuthenticatedBootstrap } from 'models/Bootstrap';
import FeatureFlag, { FeatureFlagConfigInterface } from 'models/FeatureFlag';
// See README for details about defining configuration.
import configuration from './config.json';

interface ServiceLevelConfigurationInterface {
    [key: string]: FeatureFlagConfigInterface;
}

export type FlaggedFeature = keyof typeof configuration;

export function getFeatureConfig(feature: FlaggedFeature, bootstrap: AuthenticatedBootstrap): FeatureFlag {
    const config = (configuration as ServiceLevelConfigurationInterface)[feature];

    if (!config) {
        throw new Error(`[feature-flags] unknown feature "${feature}"`);
    }

    return new FeatureFlag(config, bootstrap);
}
