import { Multiplexer, ResolverInterface, AppName } from './multiplexer';

interface CachedMultiplexer {
    [s: string]: ResolverInterface;
}

const cache: CachedMultiplexer = {};

export default function getAppMultiplexer(app: AppName, paths: string[]): ResolverInterface {
    const cacheName = app;
    if (cache[cacheName]) {
        return cache[cacheName];
    }

    return (cache[cacheName] = new Multiplexer(app, paths));
}

export const getWebappMultiplexer: (paths: string[]) => ResolverInterface = (paths: string[]) =>
    getAppMultiplexer('webapp', paths);
