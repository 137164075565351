import { FC, useRef } from 'react';
import Icon from 'components/icons/Icon';
import useHover from 'hooks/useHover';

type PopoverDirection = 'right' | 'left' | 'top' | 'down';

interface PopoverPropsInterface {
    text?: string;
    icon?: string;
    iconClassName?: string;
    direction?: PopoverDirection;
    children?: React.ReactNode;
}

const Popover: FC<PopoverPropsInterface> = ({
    children,
    text,
    icon,
    direction = 'right',
    iconClassName = 'h2 mainColor',
}) => {
    const hoverRef = useRef(null);

    const isHover = useHover(hoverRef);

    return (
        <div className="popover relative inline-flex" ref={hoverRef}>
            {text && (
                <div className="popover__label" data-testid="popover-toggle">
                    {text}
                </div>
            )}
            {icon && (
                <Icon icon={icon} className={`${iconClassName} relative`} data-testid="popover-icon-toggle"></Icon>
            )}
            {isHover && (
                <div
                    className={`bg-dark white p2 rounded flex absolute z4 popover__content popover__content--${direction}`}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default Popover;
