import Agent from 'models/Agent';
import { AgentLogInterface, fetchLog } from 'services/api/agents';
import { getLogId } from 'services/api/agents';

interface CancellerInterface {
    (): void;
}

interface LogPollerInterface {
    agent: Agent;
    hostId: number;
    size: number;
}

/**
 * Pulls the log and resolves the deferred if the log contains data,
 * otherwise it will wait and try again.
 */
export default class LogPoller {
    private cancellers: CancellerInterface[] = [];
    private pollingFrequency = 5e3;
    private logId?: string;
    private hostId: number;
    private size: number;
    private agent: Agent;

    private removeCanceller(canceller: CancellerInterface) {
        this.cancellers = this.cancellers.filter(storedCanceller => storedCanceller !== canceller);
    }

    private pollLog(
        resolve: (value: string) => void,
        reject: (reason?: unknown) => void
    ): Promise<void | AgentLogInterface> {
        let pollLogTimeout: ReturnType<typeof setTimeout>;

        if (!this.logId) {
            throw new Error('The log ID must be set in order to fetch the log');
        }

        const canceller = () => {
            reject();

            if (pollLogTimeout) {
                // Cancel the timeout so it stops waiting
                clearInterval(pollLogTimeout);
            }

            this.removeCanceller(canceller);
        };

        this.cancellers.push(canceller);

        const fetchRequest = fetchLog({ agentName: this.agent.name, logId: this.logId, hostId: this.hostId });

        return fetchRequest.then(log => {
            if (log.content !== undefined) {
                this.removeCanceller(canceller);

                return resolve(log.content);
            }

            pollLogTimeout = setTimeout(() => {
                this.pollLog(resolve, reject);
            }, this.pollingFrequency);
        });
    }

    constructor({ agent, hostId, size }: LogPollerInterface) {
        this.agent = agent;
        this.hostId = hostId;
        this.size = size;
    }

    async start() {
        if (!this.logId) {
            this.logId = await getLogId([this.agent], this.size);
        }

        return new Promise((resolve, reject) => {
            return this.pollLog(resolve, reject);
        });
    }

    stopAll() {
        let canceller;
        while ((canceller = this.cancellers.shift())) {
            canceller();
        }
    }
}
