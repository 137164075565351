import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { UIStatusConfigInterface, UIStatusValueInterface } from '../services/status/UIStatus';
import { buildStatus, getStatusInstance } from 'services/status/pageStatus';
import Logger from 'services/logger';

/**
 * Hook used to read and write the status of different components in the app.
 *
 * @param defaults - An object containing the keys that will be stored along with their default values.
 * @param properties - An object containing the ID that englobes the keys, and two optional arrays: excludeSearchKeys and excludeStorageKeys.
 * The first array is used to specify keys that musn't be stored in the query string part of the URL, and the second one is used to list keys that won't be stored in the local storage.
 * @param onRouteChange - Optional callback that will be executed when the parameters in the URL change.
 */

type RouteChangeCallbackType = (location: Location) => void;

const useUIStatus = (
    defaults: UIStatusValueInterface,
    properties: UIStatusConfigInterface,
    onRouteChange?: RouteChangeCallbackType
) => {
    const location = useLocation();
    const history = useHistory();
    const logger = Logger.get('UIStatus');

    //register route change callback
    useEffect(() => {
        if (!onRouteChange) {
            return;
        }

        onRouteChange(location);
    }, [location, onRouteChange]);

    const updateSearchParams = (params: URLSearchParams) => {
        history.push({ pathname: window.location.pathname, search: params.toString() });
    };

    const replaceSearchParams = (params: URLSearchParams) => {
        history.replace({ pathname: window.location.pathname, search: params.toString() });
    };

    const [status, setStatus] = useState(
        buildStatus(defaults, properties, { updateSearchParams, replaceSearchParams })
    );
    const [statusContent, setStatusContent] = useState(status.read());

    const writeUIStatus = (data: UIStatusValueInterface) => {
        const latestStatus = getStatusInstance(status.id);

        if (!latestStatus) {
            logger.error(`No status with id ${status.id} was found`);
            return;
        }

        latestStatus.write(data);

        setStatus(latestStatus);
        setStatusContent(latestStatus.read());
    };
    return [statusContent, writeUIStatus];
};

export default useUIStatus;
