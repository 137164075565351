import tokenStorage from 'services/token/storage';
import Logger from 'services/logger';

const logger = Logger.get('AccessControl');

type PermissionType =
    | 'env:read'
    | 'env:write'
    | 'env:settings:read'
    | 'env:settings:write'
    | 'env:credentials:read'
    | 'env:credentials:write'
    | 'org:team:update'
    | 'env:samples:read'
    | 'org:user:read'
    | 'org:env:create'
    | 'org:user:read'
    | 'org:team:read'
    | 'org:team:role:update'
    | 'org:team:env:update'
    | 'org:user:invite'
    | 'acct:auth:update'
    | 'acct:owner:update'
    | 'acct:cel'
    | 'acct:licenses:read'
    | 'acct:licenses:write'
    | 'org:config:update'
    | 'env:token:create';

interface PermissionsInterface {
    [key: string]: PermissionType[] | undefined;
}

export type ActionType =
    | 'accessEnv'
    | 'changeEnv'
    | 'accessEnvSettings'
    | 'changeEnvSettings'
    | 'readCredentials'
    | 'writeCredentials'
    | 'setupHosts'
    | 'seeQuerySamples'
    | 'createEnv'
    | 'addTeamMembers'
    | 'listOrgUsers'
    | 'listEnvUsers'
    | 'inviteUsers'
    | 'removeUsers'
    | 'createTeam'
    | 'linkTeam'
    | 'changeTeamRole'
    | 'setupAppAuth'
    | 'updateAccountOwner'
    | 'cancelAccount'
    | 'readLicenses'
    | 'setLicenses'
    | 'changeOrgSettings'
    | 'purgeQuerySamples'
    | 'configureQuerySamples'
    | 'createToken';

const permissions: PermissionsInterface = {
    accessEnv: ['env:read'],
    changeEnv: ['env:write'],
    accessEnvSettings: ['env:settings:read'],
    changeEnvSettings: ['env:settings:write'],
    readCredentials: ['env:credentials:read'],
    writeCredentials: ['env:credentials:write'],
    setupHosts: ['env:write', 'env:credentials:write'],
    seeQuerySamples: ['env:samples:read'],
    createEnv: ['org:env:create'],
    addTeamMembers: ['org:team:update'],
    listOrgUsers: ['org:user:read', 'org:team:read'],

    /**
     * @see https://github.com/VividCortex/ng-app/issues/7830#issuecomment-393977330
     * @see https://github.com/VividCortex/api-tokens/blob/master/main.go#L246-L247
     */
    listEnvUsers: ['env:read', 'org:user:read', 'org:team:read'],
    inviteUsers: ['org:user:invite'],
    removeUsers: ['org:user:invite'],
    createTeam: ['org:team:update'],
    linkTeam: ['org:team:env:update'],
    changeTeamRole: ['org:team:role:update'],
    setupAppAuth: ['acct:auth:update'],
    updateAccountOwner: ['acct:owner:update'],
    cancelAccount: ['acct:cel'],
    readLicenses: ['acct:licenses:read'],
    setLicenses: ['acct:licenses:write'],
    changeOrgSettings: ['org:config:update'],
    purgeQuerySamples: ['env:write', 'env:samples:read'],
    configureQuerySamples: ['env:write', 'env:samples:read', 'env:settings:write'],
    createToken: ['env:token:create'],
};

function getCurrentUserPermissions(): string[] {
    if (tokenStorage.envToken?.actions) {
        return tokenStorage.envToken.actions;
    }

    // If the Env token is not available. We use the org token instead.
    // Here we assume that the env token has all the roles of the org token
    // In other words, the set of roles of the org token is always included in the set of roles of the env token
    if (tokenStorage.orgToken?.actions) {
        return tokenStorage.orgToken.actions;
    }

    return [];
}

export function userIsAllowedTo(action: ActionType) {
    const userPermissions: string[] = getCurrentUserPermissions();

    const requiredPermissions = permissions[action];

    if (!requiredPermissions) {
        logger.error(`The action ${action} is not defined.`);
        return false;
    }

    return requiredPermissions.every(requiredPermission => userPermissions.includes(requiredPermission));
}
