import SettingsLayout from '../Layout';
import OverviewRetention from './Retention';
import OverviewPeople from './People';
import OverviewTeams from './Teams';
import OverviewEnvs from './Envs';
import OverviewImpersonation from './Impersonation';
import { userIsAllowedTo } from 'services/security/accessControl';

const SettingsOverview = () => {
    return (
        <SettingsLayout title="Organization Overview" metaTitle="Overview">
            <OverviewRetention />
            <OverviewPeople />
            <OverviewTeams />
            <OverviewEnvs />
            {userIsAllowedTo('changeOrgSettings') && <OverviewImpersonation data-testid="impersonation-form" />}
        </SettingsLayout>
    );
};
export default SettingsOverview;
