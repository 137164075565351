import agentsVersion from 'helpers/agents/version';

export interface AgentDataInterface {
    active: boolean;
    agent: string;
    autoUpgrade: boolean;
    cdnPath: string;
    checksum: string;
    commandsPending: number;
    currentVersion: string;
    enabled: boolean;
    isSupervisor: boolean;
    lastErrorDesc: string;
    lastErrorEvent: string;
    lastErrorTs: number;
    lastSeen: number;
    lastSuccessTs: number;
    needsRestart: boolean;
    osHost: number;
    running: boolean;
    status: string;
    targetHost: number;
    version: string;
}

export default class Agent {
    readonly lastSeen;
    readonly currentVersion;
    readonly lastSuccessTs;
    readonly lastErrorTs;
    readonly name;
    readonly targetHost;
    readonly isSupervisor;
    readonly active;
    readonly status;
    readonly enabled;
    readonly lastErrorDesc;
    readonly lastErrorEvent;
    readonly autoUpgrade;

    constructor(data: AgentDataInterface) {
        // is this necessary, or can we just use data.lastSeen?
        this.lastSeen = Math.max(data.lastSuccessTs, data.lastErrorTs);
        this.currentVersion = agentsVersion(data.currentVersion);
        this.lastSuccessTs = data.lastSuccessTs;
        this.lastErrorTs = data.lastErrorTs;
        this.lastErrorDesc = data.lastErrorDesc;
        this.lastErrorEvent = data.lastErrorEvent;
        this.name = data.agent;
        this.targetHost = data.targetHost;
        this.isSupervisor = data.isSupervisor;
        this.active = data.active;
        this.status = data.status;
        this.enabled = data.enabled;
        this.autoUpgrade = data.autoUpgrade;
    }

    /**
     * Conditions for OK: the status is OK, the last success TS is greater
     * than the last error TS, is enabled and is active
     */
    get isOk() {
        return this.status === 'ok' && this.isLastReportValid && this.enabled && this.active;
    }

    get isOff() {
        return !this.enabled;
    }

    get hasWarnings() {
        return !this.isOff && !this.isOk;
    }

    get canBeStarted() {
        return !this.isSupervisor && this.isOff;
    }

    get canBeRestarted() {
        return !this.isSupervisor && !this.isOff;
    }

    get canBeStopped() {
        return !this.isOff;
    }

    /**
     * `lastSuccessTs` and `lastErrorTs` are both timestamps
     * that tell when was the last time a success or
     * error were reported. If a success report happened after
     * an error report, it means it's valid.
     */
    private get isLastReportValid() {
        return this.lastSuccessTs > this.lastErrorTs;
    }
}
