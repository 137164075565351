import TimeAgo from 'components/format/TimeAgo';
import Timestamp from 'components/format/Timestamp';
import Icon from 'components/icons/Icon';
import Host from 'models/hosts/Host';
import { FC, useState, useEffect } from 'react';
import { userIsAllowedTo } from 'services/security/accessControl';
import HostIcon from 'components/icons/HostIcon';
import HostTags from '../HostTags';
import HostConnectionStatus from '../HostConnectionStatus';
import EditableInput from 'components/editable/EditableInput';
import useHostAttributeUpdate from 'hooks/hosts/useHostAttributeUpdate';
import EditSettingsModal from '../EditSettingsModal';
import useDisplayMessage from 'hooks/useDisplayMessage';

const MachineRow: FC<{
    host: Host;
    hasChildren: boolean;
    onToggle?: (host: Host) => void;
    onHostDeletion?: (host: Host) => void;
    collapsed: boolean;
}> = ({ host, hasChildren, onToggle = () => {}, onHostDeletion = () => {}, collapsed }) => {
    const { success, error } = useDisplayMessage();

    const [isCollapsed, setCollapsed] = useState(false);
    const [isEditingHost, setIsEditingHost] = useState(false);

    const updateHostName = useHostAttributeUpdate('name');
    const updateHostDescription = useHostAttributeUpdate('description');

    useEffect(() => {
        setCollapsed(collapsed);
    }, [collapsed]);

    const handleHostNameChange = async (newName: string) => {
        updateHostName(host, newName);
    };

    const handleHostDescChange = async (newDesc: string) => {
        updateHostDescription(host, newDesc);
    };

    const handleHostDeleteSuccess = async (selectedHost: Host) => {
        setIsEditingHost(false);
        success(`The host ${selectedHost.name} has been successfully deleted`);
        onHostDeletion(host);
    };

    return (
        <tr data-testid="host-row">
            <td
                onClick={() => {
                    setCollapsed(!isCollapsed);
                    onToggle(host);
                }}
                className={'vc-table--machines__toggler' && hasChildren ? '' : 'vc-table--machines__toggler--disabled'}
            >
                <Icon
                    icon="keyboard_arrow_right"
                    className={`inline-flex h2 mainColor cursor-hand relative ${
                        (!isCollapsed && 'machines--hidden') || 'machines--shown'
                    }`}
                />
            </td>
            <td>
                <HostConnectionStatus host={host} />
            </td>
            <td title={host.type} className="vc-table--machines__host">
                <div className="flex justify-center">
                    <HostIcon host={host} />
                </div>
            </td>
            <td>
                <div className="dark regular vc-table--machines__name__host  editable-input relative">
                    <EditableInput value={host.name} onChange={handleHostNameChange} />
                </div>

                <div className="monospace fz13 mt1 grey3 vc-table--machines__name__description line-height-120  editable-input relative">
                    <EditableInput value={host.description} onChange={handleHostDescChange} />
                </div>
            </td>
            <td>
                <span className="dark monospace spacing fz13 line-height-120 nowrap">
                    <TimeAgo date={host.lastSeen} />
                </span>
                <div className="dark monospace spacing fz13 line-height-120 servers-table__lastseen__date">
                    <Timestamp date={host.lastSeen} />
                </div>
            </td>
            <td className="vc-table--machines__tags">
                <HostTags tags={host.tags} />
            </td>
            {userIsAllowedTo('changeEnvSettings') && (
                <td title="Edit or delete this server">
                    <div className="flex justify-center" onClick={() => setIsEditingHost(true)}>
                        <Icon icon="settings" className="grey3 h2 hoverable"></Icon>
                    </div>

                    <EditSettingsModal
                        host={host}
                        visible={isEditingHost}
                        onClose={() => {
                            setIsEditingHost(false);
                        }}
                        onSuccess={selectedHost => {
                            success(`The host ${selectedHost.name} has been successfully updated`);
                            setIsEditingHost(false);
                        }}
                        onError={selectedHost => {
                            error(`The host ${selectedHost.name} could not be updated. Please try again later.`);
                            setIsEditingHost(false);
                        }}
                        onDeleteSuccess={handleHostDeleteSuccess}
                        onDeleteError={selectedHost => {
                            error(`The host ${selectedHost.name} could not be deleted. Please try again later.`);
                            setIsEditingHost(false);
                        }}
                    />
                </td>
            )}
        </tr>
    );
};

export default MachineRow;
