import { FC, useState } from 'react';
import Icon from 'components/icons/Icon';

interface PickListDropdownInterface {
    items: string[];
    onSelectItem: (item: string) => void;
    className?: string;
}

const PickListDropdown: FC<PickListDropdownInterface> = ({ items, onSelectItem, className = '' }) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<string>(items[0] || '');
    const onClick = () => setOpen(!open);
    const itemClick = (item: string) => () => {
        setSelected(item);
        onSelectItem(item);
    };
    const onBlur = () => setOpen(false);

    const renderItem = (item: string) => (
        <span
            key={item}
            className={`dropdown_menu__item${item === selected ? ' bg-grey0' : ''}`}
            onClick={itemClick(item)}
            data-testid="menu_item"
        >
            {item}
        </span>
    );

    const RenderItems = () => <>{!items.length ? renderItem('No Items') : items.map(item => renderItem(item))}</>;

    return (
        <div
            onClick={onClick}
            className={`dropdown__picklist ${className}`}
            onBlur={onBlur}
            tabIndex={-1}
            data-testid="pickList_dropdown"
        >
            <span className="nowrap ml2" data-testid="selected_item">
                {selected}
            </span>
            <Icon icon="keyboard_arrow_down" className={`fz18 mainColor mr1 dropdown-arrow-${open ? 'open' : ''}`} />
            {open && (
                <div className="dropdown_picklist_content">
                    <RenderItems />
                </div>
            )}
        </div>
    );
};

export default PickListDropdown;
