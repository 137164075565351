import Footer from './Footer';
import Header from './PublicHeader';
import * as React from 'react';

const Public: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <div className="flex flex-column security__container relative z1">
        <Header />

        <div
            className="security__display-fix flex justify-center items-start flex-grow-1 no-shrink"
            data-testid="public-layout"
        >
            <div className="wrapper px3 flex">
                <div className="full-width full-height block" role="main">
                    {children}
                </div>
            </div>
        </div>

        <Footer />
    </div>
);

export default Public;
