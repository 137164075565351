import { get } from './http';

const baseUrl = 'https://docs.vividcortex.com';

interface PostDataInterface {
    title: string;
    content_text: string;
    url: string;
    _raw_url: string;
    date_published: string;
}

interface PostSetDataInterface {
    home_page_url: string;
    items: PostDataInterface[];
}

export interface PostInterface {
    title: string;
    content: string;
    url: string;
    templateUrl: string;
    formattedDate: string;
}

export interface TopicPostInterface extends PostInterface {
    topic: TopicInterface;
}

export interface PostSetInterface {
    items: PostInterface[];
    siteUrl: string;
}

class Post implements PostInterface {
    constructor(
        readonly title: string,
        readonly content: string,
        readonly url: string,
        readonly templateUrl: string,
        readonly formattedDate: string
    ) {}

    static fromAPIResponse(data: PostDataInterface): Post {
        const date = new Date(data.date_published);

        return new Post(
            data.title,
            data.content_text,
            data.url,
            // eslint-disable-next-line no-underscore-dangle
            data._raw_url,
            new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date)
        );
    }
}

export interface TopicInterface {
    title: string;
    url: string;
}

const getSectionUrl = (section: string) => `${baseUrl}/${section}/index.json`;

export const fetchTopics: () => Promise<TopicInterface[]> = () =>
    get<{ data: TopicInterface[] }>(getSectionUrl('topics')).then(response => response.data.data);

export const fetchChangelogs: () => Promise<PostSetInterface> = () =>
    get<PostSetDataInterface>(getSectionUrl('changelog')).then(response => ({
        siteUrl: response.data.home_page_url,
        items: response.data.items.map(item => Post.fromAPIResponse(item)),
    }));

export const fetchTopicPosts: (topic: TopicInterface) => Promise<PostSetInterface> = topic =>
    get<PostSetDataInterface>(topic.url).then(response => ({
        siteUrl: response.data.home_page_url,
        items: response.data.items.map(item => {
            const date = new Date(item.date_published);

            return new Post(
                item.title,
                item.content_text,
                item.url,
                // eslint-disable-next-line no-underscore-dangle
                item._raw_url,
                new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(date)
            );
        }),
    }));

export const fetchPostTemplate: (post: PostInterface) => Promise<string> = post =>
    get<string>(post.templateUrl)
        .then(response => response.data)
        .then(content => content.replace(/src="\/img\/docs/g, `src="${baseUrl}/img/docs`))
        .then(content => content.replace(/href="\//g, `href="${baseUrl}/`));

export async function loadPostByUrl(link: string): Promise<PostInterface> {
    return get<PostDataInterface>(`${link.replace(/\/$/, '')}/index.json`)
        .then(response => response.data)
        .then(item => Post.fromAPIResponse(item));
}
