import ContactLink from './ContactLink';
import * as React from 'react';

const ServerError: React.FC = () => (
    <div data-testid="server-error">
        An error occurred and we could not load some information. Please try again or{' '}
        <ContactLink className="inline-flex" /> for support.
    </div>
);

export default ServerError;
