import { SyntheticEvent, FC } from 'react';
import sprite from './sprite/icons_sprite.svg';

interface IconPropsInterface {
    icon: string;
    className?: string;
    onClick?(e?: SyntheticEvent): void;
}

const Icon: FC<IconPropsInterface> = ({ icon, className = '', onClick, ...rest }) => {
    const iconUrl = `${sprite}#symbol-${icon}`;
    return (
        <div
            className={`icon__wrapper flex ${className}${onClick ? ' clickable' : ''}`}
            data-testid="icon-component"
            onClick={onClick}
            {...rest}
        >
            <svg className="icon" focusable="false">
                <desc>{`${icon} icon`}</desc>
                <use href={iconUrl} xlinkHref={iconUrl} />
            </svg>
        </div>
    );
};
export default Icon;
