import BouncerButton from 'components/form/BouncerButton';
import { FC, SyntheticEvent, useState } from 'react';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { store as addLicenses, AugmentedLicensesDataInterface } from 'services/api/licenses';

enum AddLicensesStatus {
    READY = 'ready',
    ADDING = 'adding',
}

interface AddLicensesStateInterface {
    amount: string;
    status: AddLicensesStatus;
}

interface AddLicensesPropsInterface {
    currentLicenses: AugmentedLicensesDataInterface;
    onSuccess?: () => void;
    className?: string;
}

const AddLicenses: FC<AddLicensesPropsInterface> = ({ onSuccess, currentLicenses, className = '' }) => {
    const [state, setState] = useState<AddLicensesStateInterface>({
        amount: '0',
        status: AddLicensesStatus.READY,
    });
    const { amount, status } = state;
    const { success, error } = useDisplayMessage();

    function handleChange(e: SyntheticEvent<HTMLInputElement>) {
        const changedAmount = e.currentTarget.value ? Math.abs(parseInt(e.currentTarget.value, 10) || 0) : '';

        setState({ ...state, amount: `${changedAmount}` });
    }

    async function handleAddLicenses() {
        setState({ ...state, status: AddLicensesStatus.ADDING });

        try {
            // We want to pass a new value for direct licenses only and ignore AWS licenses.
            // See https://github.com/VividCortex/artifacts/issues/6146
            const awsLicenses = currentLicenses.aws || 0;
            const totalDirectLicenses = currentLicenses.total - awsLicenses + parseInt(amount, 10);
            const response = await addLicenses(totalDirectLicenses);

            success(`Hooray! Your licenses were increased to ${response.total}`);

            if (onSuccess) {
                onSuccess();
            }
        } catch (e) {
            error('There was an error while trying to add licenses.');
        }

        setState({ ...state, amount: '0', status: AddLicensesStatus.READY });
    }

    const amountIsValid = amount && amount !== '0';

    return (
        <div className={`dialog__container__content__add-licenses ${className}`}>
            <div className="flex pt4">
                <label>
                    <span className="dark light">Add</span>
                    <input
                        data-testid="add-licenses-input"
                        className="bg-white mx3 pl3 licenses__input"
                        value={amount}
                        onChange={handleChange}
                        type="number"
                    />
                    <span className="dark light">licenses</span>
                </label>
            </div>
            <p className="h4 lighter my3 line-height-120 grey3">
                Additional licenses will be reflected in next month's bill.
            </p>
            <div className="flex full-width justify-end items-center">
                {amountIsValid && (
                    <p className="red mr3" data-testid="warning-message">
                        Your organization will be charged for these added licenses. If you have questions regarding
                        pricing, please contact your account manager before adding licenses.
                    </p>
                )}
                <BouncerButton
                    onClick={handleAddLicenses}
                    disabled={!amountIsValid}
                    className="primary-orange nowrap"
                    bounce={status === AddLicensesStatus.ADDING}
                    data-testid="add-licenses-button"
                >
                    Add Licenses
                </BouncerButton>
            </div>
        </div>
    );
};

export default AddLicenses;
