import { useEffect, useState, FC, MouseEvent } from 'react';
import Icon from 'components/icons/Icon';
import dictionary from 'services/contextual-help/dictionary';
import Logger from 'services/logger';
import { useDocumentationContext } from 'components/context/Documentation';

type Mode = 'text' | 'icon';

const logger = Logger.get('contextual-help');

interface HelpInterface {
    url: string;
    name?: string;
    topic?: { name: string; url: string };
}

const ContextualHelp: FC<{ mode?: Mode; id: string; children?: React.ReactNode }> = ({
    children,
    mode = 'text',
    id,
}) => {
    const baseUrl = 'https://docs.vividcortex.com';
    const [help, setHelp] = useState<HelpInterface>({ url: baseUrl });
    const { displayDocumentationHelp } = useDocumentationContext();

    useEffect(() => {
        const entry = dictionary[id];
        if (!entry) {
            logger.warn(`Dictionary entry ${id} not found`);
            return;
        }

        setHelp({
            url: `${baseUrl}/${entry.path}`,
            name: entry.name,
            topic: entry.topic,
        });
    }, [id]);

    if (!help.name) {
        return null;
    }

    function handleClick(e: MouseEvent) {
        e.preventDefault();
        displayDocumentationHelp(help.url);
    }

    return (
        <a
            href={help.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`vc-contextual-help--docs${mode === 'icon' ? ' vc-contextual-help--docs__icon-only' : ''}`}
            title={`Learn more about ${help.name}`}
            data-testid="contextual-help"
            onClick={handleClick}
        >
            {mode === 'text' && <span className="vc-contextual-help--text">{children || 'Learn more'}</span>}

            {mode === 'icon' && <Icon icon="help" className="h4 inline-flex grey3 hoverable" />}
        </a>
    );
};

export default ContextualHelp;
