import * as React from 'react';
import emptyState from './backgrounds/emptyStates.svg';
import cn from 'helpers/classname';

export enum EmptyTypes {
    INFO = 'infoImage',
    OK = 'okImage',
    SERVER = 'serverImage',
}

interface EmptyStateInterface {
    type: EmptyTypes;
    reload?: boolean;
    fullSize?: boolean;
    children?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateInterface> = ({ type, reload, children, fullSize = true }) => {
    const stateUrl = `${emptyState}#state-${type}`;
    return (
        <div className={`sw-empty${cn('sw-empty--center', !fullSize)}`}>
            <svg className="mb3" focusable="false" width="201px" height="201px">
                <title>{`${type}`}</title>
                <use href={stateUrl} xlinkHref={stateUrl} />
            </svg>

            {children}
            {reload && (
                <button className="link" onClick={() => window.location.reload()} data-testid="empty-state-reload">
                    Please reload the page to try again.
                </button>
            )}
        </div>
    );
};

export default EmptyState;
