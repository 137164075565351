import { get as getContext } from 'services/context';

/**
 * Returns the home URL of an org. E.g. orgName.app.vividcortex.com
 */
export const orgHomeUrl = (nickname: string, url: string) => url.replace('{org}', nickname);
/**
 * Builds a URL with current or provided environment, a given path and optional
 * parameters.
 */
export const envPath = (path: string, env = getContext().env, params = {}) => {
    if (!env) {
        throw new Error('Trying to build an environment path with no environment');
    }

    const queryParams = new URLSearchParams(params);
    const query = queryParams.toString() ? `?${queryParams.toString()}` : '';

    return `${env.url}/${path}${query}`;
};

export const isCurrentLocationSettings = (location: string) => location.includes('/settings');

export const securityRoutes = [
    '/login',
    '/reset',
    '/integrations/:name/login',
    '/integrations/:name/auth',
    '/marketplace/:hash',
    '/find',
    '/find/:hash/:subscription',
    '/forgot',
    '/join',
    '/oauth/services/:service/emails',
    '/reset',
];
