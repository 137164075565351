export interface RoleDataInterface {
    id: number;
    name: Roles;
}

export enum Roles {
    OWNER = 'Owner',
    DEFAULT = 'Default',
    READ_ONLY = 'Read-Only',
    READ_ONLY_SAMPLES = 'Read-Only (with samples)',
    READ_WRITE = 'Read-Write',
}

interface RoleAssignableInterface {
    toTokens: boolean;
    toTeams: boolean;
}

export default class Role {
    readonly id: number;
    readonly name: Roles;
    readonly assignable: RoleAssignableInterface;

    constructor(data: RoleDataInterface) {
        this.id = data.id;
        this.name = data.name;

        const isOwnerRole = data.name === Roles.OWNER;

        this.assignable = {
            toTokens: !isOwnerRole,
            toTeams: !isOwnerRole,
        };
    }
}
