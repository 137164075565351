import * as React from 'react';
import Tag from 'components/form/fields/Tag';

interface HostTagsInterface {
    tags: string[];
}

const HostTags: React.FC<HostTagsInterface> = ({ tags }) => (
    <div className="edit-tags flex-grow-1">
        <div className="relative">
            <ul className="list-reset flex flex-wrap flex-grow-1">
                {tags.map(tag => (
                    <Tag key={tag} tag={tag} />
                ))}
            </ul>
        </div>
    </div>
);
export default HostTags;
