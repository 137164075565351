import Alert, { AlertTypes } from 'components/messages/Alert';
import CreateEnvironment from './Create';
import Icon from 'components/icons/Icon';
import Env from 'models/Env';
import EnvironmentActions from './Actions';
import EnvironmentFilter from './Filter';
import EnvironmentList from './List';
import { FC, useState } from 'react';
import useDisplayMessage from 'hooks/useDisplayMessage';
import useEnvs from 'hooks/useEnvs';
import useOrg from 'hooks/useOrg';
import { Helmet } from 'react-helmet';
import { UserHasPermission } from 'components/security/UserHasPermission';
import FeatureLink from 'components/url/FeatureLink';
import ContextualHelp from 'components/documentation/ContextualHelp';

const EnvironmentsSummary: FC = () => {
    const org = useOrg();
    const envs = useEnvs();
    const [filter, setFilter] = useState('');
    const [showCreateForm, toggleCreateForm] = useState(false);
    const [editEnv, setEditEnv] = useState<Env | null>(null);
    const [addSuccess, createSucceeded] = useState(false);
    const { success } = useDisplayMessage();

    const orgName = org.name;

    const handleFilterChange = (name: string) => setFilter(name);

    return (
        <>
            <Helmet>
                <title>Summary - DPM</title>
            </Helmet>
            <CreateEnvironment
                visible={showCreateForm}
                onClose={() => {
                    toggleCreateForm(false);
                }}
                onSuccess={() => {
                    toggleCreateForm(false);
                    createSucceeded(true);
                }}
            />
            {editEnv && (
                <EnvironmentActions
                    visible
                    onClose={() => {
                        setEditEnv(null);
                    }}
                    onRemove={() => {
                        success(`Environment ${editEnv.name} successfully removed`);

                        setEditEnv(null);
                    }}
                    onRename={() => {
                        success(`Environment successfully renamed to ${editEnv.name}.`);

                        setEditEnv(null);
                    }}
                    env={editEnv}
                />
            )}
            <div className="view view--home">
                <div className="flex flex-column">
                    <h1 className="h2 lighter flex-grow-1 nowrap flex items-center mb3">{orgName} Environments</h1>
                    <div className="flex nowrap mb3 border-bottom border-color-grey2 pb3 justify-between">
                        <EnvironmentFilter onChange={handleFilterChange} name={filter} />
                        <UserHasPermission to="createEnv">
                            <div className="view--home__create-new">
                                <button
                                    className="primary-white flex items-center nowrap"
                                    data-testid="create-env-button"
                                    onClick={() => toggleCreateForm(true)}
                                >
                                    <span>Add Environment</span>
                                </button>
                            </div>
                        </UserHasPermission>
                    </div>
                </div>

                {addSuccess && (
                    <Alert type={AlertTypes.SUCCESS} sticky className="my2">
                        Awesome! You may now want to{' '}
                        <FeatureLink to="/settings/teams" appendEnv={false}>
                            add some teams
                        </FeatureLink>{' '}
                        to this environment.
                    </Alert>
                )}

                <EnvironmentList
                    filter={filter}
                    clearFilter={() => handleFilterChange('')}
                    envs={[...envs]}
                    editEnv={setEditEnv}
                />

                <div className="view__footer fixed bottom-0 bg-grey0 z1 px4">
                    <div className="flex items-start py3 pr4 border-top border-color-grey2">
                        <Icon icon="info" className=" flex fz27 mainColor mr2" />
                        <p className="dark mt1 line-height-120 h4 pr4 mr4">
                            Environments are logically separate groups of hosts like development, staging, and
                            production. You can create as many as you want. Their monitoring data is separate and they
                            have separate teams and permissions. Each agent can only monitor hosts in a single
                            environment and data cannot be shared between environments.&nbsp;
                            <ContextualHelp id="environments">Learn more</ContextualHelp>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EnvironmentsSummary;
