import { FC, useState } from 'react';
import Dropdown from 'components/dropdowns/Dropdown';
import Icon from 'components/icons/Icon';
import UsersList from 'components/lists/UsersList';
import Bouncer from 'components/icons/Bouncer';
import Role from 'models/Role';
import Team from 'models/Team';
import User from 'models/User';
import Env from 'models/Env';
import { addEnvToTeam, removeEnvFromTeam } from 'services/api/teams';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { userIsAllowedTo } from 'services/security/accessControl';
import { useFinalActionModal } from 'components/context/FinalActionModal';

const EnvTeamsTableRow: FC<{ team: Team; roles: Role[]; env: Env; onDelete?: (team: Team) => void }> = ({
    team,
    roles,
    env,
    onDelete,
}) => {
    const [roleForEnv, setRoleForEnv] = useState(team.envs.find(e => e.id === env.id)?.role);
    const [isDeleting, setIsDeleting] = useState(false);
    const [roleDropdownExpanded, setRoleDropdownExpanded] = useState(false);

    const { success, error } = useDisplayMessage();
    const { openModal } = useFinalActionModal();

    const canModifyTeam = () => {
        if (!userIsAllowedTo('changeEnvSettings')) {
            return false;
        }

        return team.type !== 'owner';
    };

    const handleDelete = async () => {
        setIsDeleting(true);

        try {
            await removeEnvFromTeam(team, env);

            setIsDeleting(false);

            if (onDelete) {
                const newTeam = { ...team };
                newTeam.envs = newTeam.envs.filter(envTeam => envTeam.id !== env.id);

                onDelete(new Team(newTeam));
            }
        } catch {
            setIsDeleting(false);
            error(`There was an error removing team ${team.name}`);
        }
    };

    const handleRoleSelection = async (role: Role) => {
        setRoleDropdownExpanded(false);

        try {
            await addEnvToTeam(team, env, role);
            setRoleForEnv(role);
            success('Role updated');
        } catch {
            error(`There was an error while trying to update ${team.name}'s role`);
        }
    };

    return (
        <tr key={team.id} data-testid="team">
            <td>
                <div className="flex items-center flex-grow-1">
                    <Icon className="grey3 fz27 mr3" icon="people" />
                    <span className="nowrap" data-testid="team-name">
                        {team.name}
                    </span>
                </div>
            </td>
            <td>
                <UsersList users={team.users as User[]} limit={10} />
            </td>
            <td>
                <div className="flex items-center justify-between">
                    {!canModifyTeam() && (
                        <span className="px2" data-testid="team-role-name">
                            {roleForEnv?.name}
                        </span>
                    )}
                    {canModifyTeam() && (
                        <div className="mainColor" data-testid="team-role-name-dropdown">
                            <Dropdown
                                className="flex dropdown--right"
                                text={roleForEnv?.name}
                                isExpanded={roleDropdownExpanded}
                                onToggle={(expanded: boolean) => setRoleDropdownExpanded(expanded)}
                            >
                                <div data-testid="dropdown-content">
                                    <ul className="add-to-team-list list-reset overflow-auto py2">
                                        {roles.map(
                                            role =>
                                                role.name !== roleForEnv?.name && (
                                                    <li
                                                        key={role.id}
                                                        className="add-to-team-list__item py2 px3 cursor-hand mainColor"
                                                        onClick={() => handleRoleSelection(role)}
                                                        data-testid="dropdown-role-value"
                                                    >
                                                        {role.name}
                                                    </li>
                                                )
                                        )}
                                    </ul>
                                </div>
                            </Dropdown>
                        </div>
                    )}
                    {isDeleting && <Bouncer />}
                    {!isDeleting && canModifyTeam() && (
                        <Icon
                            className="grey3 fz27 ml2 hoverable"
                            icon="trash"
                            onClick={() =>
                                openModal({
                                    onSubmit: () => handleDelete(),
                                    confirmMsg: `Are you sure you want to remove team: ${team.name}?`,
                                    buttonText: 'Yes, delete this team.',
                                    title: `Confirmation to remove team`,
                                })
                            }
                        />
                    )}
                </div>
            </td>
        </tr>
    );
};

export default EnvTeamsTableRow;
