import { ChangeEvent, FC, useEffect, useState } from 'react';
import HostBreakDown from 'models/HostsBreakDown';
import WidgetBox from 'components/layout/WidgetBox';
import Icon from 'components/icons/Icon';
import { useTimeInterval } from 'components/context/TimeInterval';
import { useHostFilter } from 'components/context/HostFilter';
import Host from 'models/hosts/Host';
import PickListDropdown from 'components/dropdowns/PickListDropdown';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import { fetchHostsBreakDown } from 'services/api/queries';
import { getHosts } from 'services/api/hosts';
import Paginator, { PaginatorLayout } from 'components/lists/Paginator';
import BreakDownList from '../BreakDownList';

const DROPDOWN_ITEMS = ['Total Time', 'Count', 'Error'];
const BREAKDOWN_MAPPER: { [index: string]: string } = { 'Total Time': 'time', Count: 'throughput', Error: 'errors' };
const PER_PAGE = 5;

interface HostsBreakDownInterface {
    queryId: string;
}

const QueryHostsBreakDown: FC<HostsBreakDownInterface> = ({ queryId }) => {
    const [hostsBreakDown, setHostsBreakDown] = useState<HostBreakDown[]>([]);
    const [search, setSearch] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [columnID, setColumnID] = useState('time');
    const [paginatedList, setPaginatedList] = useState<HostBreakDown[]>([]);

    const { interval } = useTimeInterval();
    const { filter: hostFilter, set: setHostFilter } = useHostFilter();

    useEffect(() => {
        setIsLoading(true);
        fetchHostsBreakDown(queryId, columnID, interval, hostFilter)
            .then(fetchedBreakDowns => {
                const hostBreakDown = [] as HostBreakDown[];
                getHosts(interval, hostFilter).then(fetchedHosts => {
                    fetchedBreakDowns.forEach(breakDown => {
                        const hostData = fetchedHosts.find(host => host.id === breakDown.host.hostID);
                        hostBreakDown.push(new HostBreakDown(breakDown, hostData));
                    });
                    setHostsBreakDown(hostBreakDown);
                    setPaginatedList(hostBreakDown.slice(0, PER_PAGE));
                    setIsLoading(false);
                });
            })
            .catch(() => setHostsBreakDown([]));
    }, [queryId, hostFilter, columnID, interval]);

    const filterList = (list: HostBreakDown[], filterInput = search) => {
        if (!filterInput.length) return list;
        return list.filter(breakDown => {
            const name = breakDown.host?.name;
            return name?.toLowerCase().includes(filterInput.toLowerCase());
        });
    };

    const PaginateList = (page: number, filterInput = search) => {
        const list = filterList(hostsBreakDown, filterInput);
        setPaginatedList(list.slice(PER_PAGE * (page - 1), PER_PAGE * page));
    };

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.currentTarget.value);
        PaginateList(1, event.currentTarget.value);
    };

    const onHostClick = (selectedHost?: Host) => () => {
        setHostFilter(selectedHost?.id.toString() || '');
        PaginateList(1, '');
    };

    const onDropDownItemClick = (item: string) => {
        setColumnID(BREAKDOWN_MAPPER[item]);
    };

    const handleFilterClear = () => {
        setSearch('');
    };

    const renderFilter = () => (
        <div className="nowrap relative">
            <input
                type="text"
                value={search}
                onChange={handleFilterChange}
                data-testid="filter-hosts"
                placeholder="Filter Hosts"
                className="bg-white flex-grow-1 flex-grow-1 relative filter_input"
            />
            {search.length > 0 && (
                <Icon
                    icon="cancel"
                    onClick={handleFilterClear}
                    className="filter__icon absolute right-2 cursor-hand grey3 h2 flex items-center"
                />
            )}
        </div>
    );

    const filteredList = filterList(hostsBreakDown, search);

    return (
        <>
            <WidgetBox className="host_breakdown_widget">
                <div className="flex grid-align-center">
                    <h2 className="h2 grey3 pb2 pt2" data-testid="hosts-breakdownheader">
                        Host Breakdown by
                    </h2>
                    <PickListDropdown items={DROPDOWN_ITEMS} onSelectItem={onDropDownItemClick} className="ml2" />
                </div>
                <div className="flex justify-between items-center border-bottom border-color-grey1 mb1">
                    <div className="widget--counter flex">
                        <p className="bold flex items-center">
                            {filteredList.length} {filteredList.length === 1 ? 'Host' : 'Hosts'}
                        </p>
                    </div>
                    {hostsBreakDown.length > 1 && renderFilter()}
                </div>
                {isLoading ? (
                    <Loader type={LoaderTypes.PARAGRAPH}></Loader>
                ) : (
                    <BreakDownList list={paginatedList} onHostClick={onHostClick} />
                )}
                <Paginator
                    itemsLength={filteredList.length}
                    layout={PaginatorLayout.SMALL}
                    perPage={PER_PAGE}
                    onPaging={PaginateList}
                />
            </WidgetBox>
        </>
    );
};

export default QueryHostsBreakDown;
