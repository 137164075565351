import { useEffect, useRef } from 'react';
import { useCallback } from 'react';

function targetIsNode(target: EventTarget | null): target is Node {
    return target !== null && 'nodeName' in target;
}

function useOnOutsideClick<ElementType extends Node>(handleOutsideClick: (event: MouseEvent) => void) {
    const ref = useRef<ElementType>(null);

    const onClick = useCallback(
        (event: MouseEvent) => {
            if (!ref || !ref.current) {
                return;
            }

            if (!ref.current) {
                return;
            }

            if (targetIsNode(event.target) && !ref.current.contains(event.target)) {
                handleOutsideClick(event);
            }
        },
        [handleOutsideClick]
    );

    useEffect(() => {
        document.addEventListener('click', onClick, true);
        return () => {
            document.removeEventListener('click', onClick, true);
        };
    }, [onClick]);

    return { ref };
}

export default useOnOutsideClick;
