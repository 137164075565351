import { createContext, useContext, useState, FC } from 'react';

interface PostUrlInterface {
    url: string;
    anchor: string;
}

interface DocumentationContextProps {
    url: PostUrlInterface;
    displayDocumentationHelp: (fullUrl: string) => void;
}

export const DocumentationContext = createContext<DocumentationContextProps>({
    url: { url: '', anchor: '' },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    displayDocumentationHelp: (fullUrl: string) => {},
});

export function useDocumentationContext() {
    return useContext(DocumentationContext);
}

class PostUrl implements PostUrlInterface {
    constructor(readonly url: string, readonly anchor: string) {}

    static fromFullUrl(fullUrl: string) {
        const regex = /(#[\w-]*)$/;
        const matchAnchor = regex.exec(fullUrl);

        return new PostUrl(fullUrl.replace(regex, ''), matchAnchor ? matchAnchor[0] : '');
    }
}

const DocumentationProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [url, setUrl] = useState<PostUrlInterface>({ url: '', anchor: '' });

    return (
        <DocumentationContext.Provider
            value={{
                url,
                displayDocumentationHelp: (fullUrl: string) => setUrl(PostUrl.fromFullUrl(fullUrl)),
            }}
        >
            {children}
        </DocumentationContext.Provider>
    );
};

export default DocumentationProvider;
