import Host from 'models/hosts/Host';
import Icon from './Icon';
import * as React from 'react';

const standardDbRoles = ['mongod', 'postgres'];

const HostIcon: React.FC<{ host: Host; className?: string }> = ({ className = '', host }) => {
    const primaryIcon = host.type === 'os' ? host.os : host.type;
    const secondaryIcon = standardDbRoles.includes(`${host.role}`) ? null : host.role;
    const title = host.role ? `${host.type} - ${host.role}` : host.type;

    return (
        <div className={className}>
            <div title={title} className="relative host-icon">
                <Icon icon={primaryIcon} className="flex grey3" />
                {secondaryIcon && (
                    <Icon
                        icon={secondaryIcon}
                        className="host-icon__secondary-icon flex grey3 fz18 absolute right-0 bottom-0 bg-white circle border border-color-white border-thick"
                    />
                )}
            </div>
        </div>
    );
};

export default HostIcon;
