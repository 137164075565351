import { CredentialDataInterface, HostCredential } from './Credential';
import { CredentialHostType } from 'services/api/credentials';

export type RedisCredentialDataInterface = CredentialDataInterface;

export default class RedisCredential extends HostCredential {
    constructor(data: CredentialDataInterface) {
        super(CredentialHostType.redis, data);
    }

    update(values: this, allowPasswordChange?: boolean) {
        super.update(values, allowPasswordChange ?? true);
    }

    asPayloadObject() {
        return {
            ...super.asPayloadObject(),
        };
    }
}
