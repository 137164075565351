import { show as showSalesForceChat, isReady as salesForceChatReady } from 'services/analytics/salesforceChat';
import React, { FC, SyntheticEvent } from 'react';
import { get as getContext } from 'services/context';

const ContactLink: FC<{ className?: string; children?: React.ReactNode }> = ({ children, className = '' }) => {
    const bootstrap = getContext();
    const openSalesForceChat = (e: SyntheticEvent) => {
        if (!salesForceChatReady()) {
            return;
        }

        e.preventDefault();

        showSalesForceChat();
    };

    return (
        <span className={className}>
            <span className="contact-us">
                <span className="contact-us__method">
                    <a
                        className="contact-us__link"
                        onClick={openSalesForceChat}
                        href={`mailto:${bootstrap.supportEmail}`}
                        title="Send us a direct message"
                    >
                        {children || 'contact us'}
                    </a>
                </span>
            </span>
        </span>
    );
};

export default ContactLink;
