import 'assets/styles/styles.scss';
import { Route } from 'react-router-dom';
import AppFirewall from './AppFirewall';
import AppParameterHandler from 'components/routing/AppParameterHandler';
import SalesforceChat from 'components/context/SalesforceChat';
import Bootstrap from 'components/context/Bootstrap';
import Bugsnag from 'components/context/Bugsnag';
import Context from 'components/Context';
import GTM from 'components/context/GTM';
import VirtualPageView from 'components/analytics/VirtualPageView';
import { init as initNetworkStatus } from 'services/networkStatus';
import UIStatusParameterHandler from 'components/routing/UIStatusParameterHandler';

initNetworkStatus();

const App = () => (
    <Bootstrap>
        <Context>
            <AppFirewall>
                <SalesforceChat />
                <Bugsnag />
                <GTM />
                <Route path="/">
                    <VirtualPageView />
                </Route>
                <Route path="/">
                    <AppParameterHandler />
                </Route>
                <Route path="/">
                    <UIStatusParameterHandler />
                </Route>
            </AppFirewall>
        </Context>
    </Bootstrap>
);

export default App;
