import { useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';

const gitHubInfo: DPMOAuthInfoData = {
    'auth.oauth.provider': 'github',
    'auth.oauth.github.email': 'someone@vividcortex.com',
    'auth.oauth.github.organizations': [{ name: 'VividCortex' }],
};

const googleInfo: DPMOAuthInfoData = {
    'auth.oauth.provider': 'google',
    'auth.oauth.email': 'brainiac@vividcortex.com',
    'auth.oauth.google.emailDomain': 'vividcortex.com',
};

const OAuth: FC = () => {
    const params = useParams<{ provider: 'github' | 'google' }>();

    useEffect(() => {
        if (!window?.opener?.VividCortex) {
            // This shouldn't happen, but we need to check anyway.
            console.error('Property VividCortex not found in opener window.');
            return;
        }

        if (params.provider === 'github') {
            window.opener.VividCortex.OAuthInfo.info = gitHubInfo;
        } else if (params.provider === 'google') {
            window.opener.VividCortex.OAuthInfo.info = googleInfo;
        }
    }, [params.provider]);

    return <div>Sending OAuth info to window opener. This window should automatically close.</div>;
};

export default OAuth;
