import { RequestParamInterface, hasParam, TimeIntervalInterface } from './requestParamsEncoder';

export default function encodeInterval(params: RequestParamInterface) {
    if (!hasParam('interval', params)) {
        return;
    }
    const interval = params.interval as TimeIntervalInterface;

    params.from = interval.fromTs;
    params.until = interval.untilTs;
    delete params.interval;
}
