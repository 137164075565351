import { Redirect, Route, Switch } from 'react-router-dom';
import Login from 'components/modules/public/Login';
import OrgLogin from 'components/modules/public/OrgLogin';
import AWSOrgLogin from 'components/modules/public/AWSOrgLogin';
import FindOrganization from 'components/modules/public/FindOrganization';
import ForgotPassword from 'components/modules/public/ForgotPassword';
import ResetPassword from 'components/modules/public/ResetPassword';
import OAuthEmailSelection from 'components/modules/public/OAuthEmailSelection';
import Join from 'components/modules/public/Join';
import { get as getContext } from 'services/context';

const LoginRedirect = () => <Redirect to="/login" />;

const AppRouter = () => {
    const { org } = getContext();

    if (!org) {
        return (
            <Switch>
                <Route path="/login" exact>
                    <OrgLogin />
                </Route>
                <Route path="/integrations/:name/login" exact>
                    <OrgLogin />
                </Route>
                <Route path="/integrations/:name/auth">
                    <OrgLogin />
                </Route>
                <Route path="/marketplace/:hash" exact>
                    <AWSOrgLogin />
                </Route>
                <Route path="/find" exact>
                    <FindOrganization />
                </Route>
                <Route path="/find/:hash/:subscription" exact>
                    <FindOrganization />
                </Route>
                <Route>
                    <LoginRedirect />
                </Route>
            </Switch>
        );
    }

    return (
        <Switch>
            <Route path="/login" exact>
                <Login />
            </Route>
            <Route path="/marketplace/:hash" exact>
                <Login />
            </Route>
            <Route path="/forgot" exact>
                <ForgotPassword />
            </Route>
            <Route path="/join" exact>
                <Join />
            </Route>
            <Route path="/oauth/services/:service/emails" exact>
                <OAuthEmailSelection />
            </Route>
            <Route path="/reset" exact>
                <ResetPassword />
            </Route>
            <Route>
                <LoginRedirect />
            </Route>
        </Switch>
    );
};

export default AppRouter;
