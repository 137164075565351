import useMounted from 'hooks/useMounted';
import { useEffect, useState } from 'react';
import { fetchChangelogs, PostSetInterface } from 'services/documentation';
import Logger from 'services/logger';

type State = 'idle' | 'loading' | 'loaded' | 'error';

const logger = Logger.get('documentation');
const cache: PostSetInterface = { siteUrl: '', items: [] };

/**
 * Load the changelogs from the documentation site and cache the result, to
 * prevent loading them over and over while users navigate through the in-app
 * documentation.
 */
export default function useChangelogs() {
    const [posts, setPosts] = useState<PostSetInterface>({ siteUrl: '', items: [] });
    const [state, setState] = useState<State>('idle');
    const { resolveIfMounted } = useMounted();

    async function load(): Promise<PostSetInterface> {
        if (cache.items.length > 0) {
            return cache;
        }

        const changelogs = await fetchChangelogs();

        cache.items = changelogs.items;
        cache.siteUrl = changelogs.siteUrl;

        return changelogs;
    }

    useEffect(() => {
        setState('loading');

        resolveIfMounted(load())
            .then(fetchedPosts => {
                setState('loaded');
                setPosts(fetchedPosts);
            })
            .catch(() => {
                logger.error('Failed to load changelogs from Documentation Site');

                setState('error');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { state, posts };
}
