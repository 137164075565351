import { FC, useState } from 'react';
import APIToken, { APIRotationTokenInterface, APIDeprecatedTokenInterface } from 'models/APIToken';
import BouncerButton from 'components/form/BouncerButton';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import { finalize as finalizeRotation } from 'services/api/tokens';
import useDisplayMessage from 'hooks/useDisplayMessage';
import Icon from 'components/icons/Icon';
import Timestamp from 'components/format/Timestamp';
import APITokenHash from './Hash';

const APITokenRotation: FC<{
    token: APIToken;
    onRevoke: (token: APIRotationTokenInterface | APIDeprecatedTokenInterface) => void;
    onFinalizeRotation: (token: APIToken) => void;
    onDismissHash: (token: APIToken) => void;
}> = ({ token, onRevoke, onFinalizeRotation, onDismissHash }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { error } = useDisplayMessage();
    const { openModal } = useFinalActionModal();

    const finalize = async () => {
        setIsLoading(true);

        try {
            await finalizeRotation(token);
            setIsLoading(false);
            onFinalizeRotation(token);
        } catch {
            setIsLoading(false);
            error(
                'Oops! Something went wrong when trying to finalize your token rotation. Please try again, or contact us.'
            );
        }
    };

    return (
        <table className="vc-table vc-table--table-token__rotation full-width bg-grey0">
            <tbody>
                <tr className="vc-table--table-token__rotation__new-token-row">
                    {token.hash && (
                        <td className="vc-table--table-token__rotation__deprecated__label full-width">
                            <b className="uppercase fz10 spacing bold nowrap flex items-center ml2">New Token</b>
                        </td>
                    )}
                    {token.hash && (
                        <td className="table-token__token" colSpan={4}>
                            <APITokenHash token={token} onDismiss={onDismissHash} />
                            {token.type === 'agent' && (
                                <div>
                                    <div className="mt2 table-token__copy-keys grey3 spacing regular fz12">
                                        <p className="mb2">
                                            In order to exchange this rotation token for the agent token, please run
                                            this script on each server in this environment where DPM is installed:
                                        </p>
                                        <pre className="pre-wrap">
                                            sh install --rotate --token {token.hash}
                                            <span className="flex">
                                                {'curl https://download.vividcortex.com/install > install'}
                                            </span>
                                        </pre>
                                    </div>
                                </div>
                            )}
                        </td>
                    )}
                    <td
                        className={`vc-table--settings--api-tokens__actions ${
                            !token.hash ? 'vc-table--settings--api-tokens__actions--empty-row' : ''
                        }`}
                        colSpan={token.hash ? 1 : 6}
                    >
                        <div className="table-token__rotation__finalize flex flex-column items-start justify-end justify-end">
                            <span className="bold orange italic mb2 nowrap">Rotation in progress</span>
                            <BouncerButton
                                className="primary-orange mr3 nowrap vc-table--settings--api-tokens__actions__button"
                                type="button"
                                disabled={isLoading}
                                bounce={isLoading}
                                bouncerClassName="white inline-flex ml2"
                                onClick={() => {
                                    openModal({
                                        onSubmit: () => {
                                            finalize();
                                        },
                                        onClose: () => {
                                            setIsLoading(false);
                                        },
                                        confirmMsg: `You are about to finalize the rotation of the "${token.name}" token.`,
                                        buttonText: 'Finalize this token rotation',
                                        title: 'Token rotation',
                                    });
                                }}
                            >
                                Finalize
                            </BouncerButton>
                        </div>
                    </td>
                </tr>
                {token.rotationToken && (
                    <tr className="vc-table--table-token__rotation__rotated">
                        <td className="vc-table--table-token__rotation__deprecated__label" colSpan={3}>
                            <b className="uppercase fz10 spacing bold dark nowrap ml2">Rotation Token</b>
                        </td>
                        <td className="table-token__created">
                            <span className="monospace">
                                <Timestamp date={token.rotationToken.created} />
                            </span>
                        </td>
                        <td className="table-token__last-used">
                            <div className="monospace">
                                {token.rotationToken.lastUsed ? (
                                    <Timestamp date={token.rotationToken.lastUsed} />
                                ) : (
                                    'Unknown'
                                )}
                            </div>
                        </td>

                        <td className="vc-table--settings--api-tokens__actions right-align">
                            <Icon
                                icon="trash"
                                className="grey3 hoverable cursor-hand h2 relative align__top--2"
                                onClick={() => onRevoke(token.rotationToken as APIRotationTokenInterface)}
                                data-testid={`delete-token-${token.rotationToken.id}`}
                            />
                        </td>
                    </tr>
                )}
                {token.deprecatedTokens?.map((deprecatedToken, index) => (
                    <tr key={deprecatedToken.id} className="vc-table--table-token__rotation__deprecated">
                        {index === 0 && (
                            <td className="vc-table--table-token__rotation__deprecated__label">
                                <b className="uppercase fz10 spacing bold dark nowrap ml2">Deprecated Tokens</b>
                            </td>
                        )}
                        <td colSpan={index === 0 ? 2 : 3} className="right-align">
                            {index + 1}
                        </td>
                        <td className="table-token__created">
                            <span className="monospace">
                                <Timestamp date={deprecatedToken.created} />
                            </span>
                        </td>
                        <td className="table-token__last-used">
                            <div className="monospace">
                                {deprecatedToken.lastUsed ? <Timestamp date={deprecatedToken.lastUsed} /> : 'Unknown'}
                            </div>
                        </td>
                        <td className="vc-table--settings--api-tokens__actions right-align">
                            <Icon
                                icon="trash"
                                className="grey3 hoverable cursor-hand h2 relative align__top--2"
                                onClick={() => onRevoke(deprecatedToken)}
                                data-testid={`delete-token-${deprecatedToken.id}`}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default APITokenRotation;
