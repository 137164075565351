import { useEffect, useState, FC, useCallback } from 'react';
import rankProfiler, { ResultElementInterface as APIResultElementInterface } from 'services/api/profiler';
import { AsyncColumnDataType } from 'services/api/profiler/columnData';
import { ColumnInterface } from 'models/profiler/columns';
import { ProfilerInterface } from 'models/profiler';
import { useTimeInterval } from 'components/context/TimeInterval';
import { useHostFilter } from 'components/context/HostFilter';
import useMounted from 'hooks/useMounted';
import ProfilerRow, { ResultElementInterface } from 'components/modules/app/Profiler/Table/Row';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import ContactLink from 'components/messages/ContactLink';
import LoaderRow from 'components/loaders/LoaderRow';
import { getSampleSize } from 'helpers/sampleSize';
import { sparklinesSize } from 'config/app';
import useEnv from 'hooks/useEnv';

type TableState =
    | { name: 'idle' }
    | { name: 'empty' }
    | { name: 'error'; errorMessage: string }
    | { name: 'loading' }
    | { name: 'loaded'; elements: ResultElementInterface[] };

/**
 * Custom hook that returns a function for ranking a profiler instance for the
 * current time interval and host selection.
 */
function useTableDataLoader(profiler: ProfilerInterface) {
    const { interval } = useTimeInterval();
    const { filter } = useHostFilter();
    const { resolveIfMounted } = useMounted();
    const env = useEnv();

    return useCallback(async () => {
        const sampleSize = getSampleSize(sparklinesSize.width, interval.tsOffset);

        const { elements, fetchProfilerColumnData } = await resolveIfMounted(
            rankProfiler(profiler, interval, filter, sampleSize)
        );

        if (elements.length === 0) {
            // This should set the empty state
            return [] as ResultElementInterface[];
        }

        // Fetch the rest of the table data, each column on their own promise
        const promises = fetchProfilerColumnData();

        // map each resulting element into a ResultElementInterface so inner
        // cells can display the data asynchronously
        const elementMapper = (element: APIResultElementInterface, index: number): ResultElementInterface => ({
            ...element,
            metricUrl: `/${env.name}/metrics/${element.metric}`,
            getColumnData<Column extends ColumnInterface>(column: Column): AsyncColumnDataType<Column> {
                if (column.id === 'rank') {
                    // The rank comes from the initial request,
                    // so there is no need for looking for that
                    // value in a new promise
                    return Promise.resolve(this.rank.toString(10)) as AsyncColumnDataType<Column>;
                }

                return promises[column.id].then(({ elements: resultElements }) => resultElements[index]);
            },
        });

        return elements.map(elementMapper);
    }, [env.name, filter, interval, profiler, resolveIfMounted]);
}

const TableComponent: FC<{ profiler: ProfilerInterface; state: TableState }> = ({ profiler, state }) => {
    if (state.name === 'empty') {
        return (
            <EmptyState type={EmptyTypes.OK}>
                <div data-testid="profiler-empty">There is no data to show</div>
            </EmptyState>
        );
    }

    return (
        <table
            data-testid="profiler-table"
            className="vc-table vc-table--tile vc-table--profiler full-width vc-profiler-table vc-sort-table rendered"
        >
            <thead>
                <tr>
                    {profiler.columns.map(column => (
                        <th key={column.id}>{column.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {state.name === 'loaded' &&
                    state.elements.map((element, index) => (
                        <ProfilerRow key={index} element={element} profiler={profiler} />
                    ))}

                {state.name === 'loading' && <LoaderRow cols={profiler.columns.length} />}
            </tbody>
            <tfoot>
                {state.name === 'error' && (
                    <tr className="empty">
                        <td className="center" colSpan={profiler.columns.length}>
                            Oops. The server failed to return data. Please retry later or <ContactLink />.
                        </td>
                    </tr>
                )}
            </tfoot>
        </table>
    );
};

const Table: FC<{ profiler: ProfilerInterface }> = ({ profiler }) => {
    const [state, setState] = useState<TableState>({ name: 'idle' });
    const rank = useTableDataLoader(profiler);

    useEffect(() => {
        setState({ name: 'loading' });

        rank()
            .then(elements =>
                setState(() => (elements.length === 0 ? { name: 'empty' } : { name: 'loaded', elements }))
            )
            .catch(e => setState({ name: 'error', errorMessage: e.message }));
    }, [rank]);

    return <TableComponent profiler={profiler} state={state} />;
};

export default Table;
