import APIRequest from './Request';
import Logger from 'services/logger';
const logger = Logger.get('retention');

export interface RetentionDataInterface {
    resolution: number;
    retention: number;
}

export async function get() {
    logger.info('getting the retention data');

    const response = await new APIRequest<RetentionDataInterface[]>({
        path: 'org/retention',
    }).request();

    return response.data;
}
