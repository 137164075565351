import OrgSelectionForm from './Form';
import MetaTags from 'components/layout/MetaTags';

const OrgLogin = () => (
    <>
        <MetaTags title="Login to your organization at DPM" />
        <OrgSelectionForm />
    </>
);

export default OrgLogin;
