import Loader, { LoaderTypes } from 'components/loaders/Loader';
import { useEffect, useState, FC } from 'react';
import { fetchTopicPosts, PostInterface, PostSetInterface, TopicInterface } from 'services/documentation';
import Logger from 'services/logger';

const logger = Logger.get('documentation');

const Topic: FC<{ topic: TopicInterface; onClick: (post: PostInterface) => void }> = ({ topic, onClick }) => {
    const [posts, setPosts] = useState<PostInterface[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        logger.debug('Displaying topic', topic.title);

        setIsLoading(true);

        fetchTopicPosts(topic)
            .then((fetchedPosts: PostSetInterface) => setPosts(fetchedPosts.items))
            .finally(() => setIsLoading(false));
    }, [topic]);

    if (!posts.length && !isLoading) {
        return null;
    }

    return (
        <div className="documentation-posts mx3">
            <h3 className="regular fz16 pb3" data-testid="documentation-topic-title">
                {topic.title}
            </h3>

            {isLoading && <Loader type={LoaderTypes.PARAGRAPH}></Loader>}
            {!isLoading && (
                <ul>
                    {posts.map(post => (
                        <li className="p2" key={post.url}>
                            <button
                                className="h3 regular link"
                                data-testid="in-app-documentation-topic-post"
                                onClick={() => onClick(post)}
                            >
                                {post.title}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Topic;
