import * as React from 'react';
import Input from '../Input';
import { FieldRenderProps } from 'react-final-form';

export interface TextFieldPropsInterface extends FieldRenderProps<string, HTMLInputElement> {
    helpText?: string;
    className?: string;
    type?: string;
    prepend?: React.ReactNode;
    append?: React.ReactNode;
}

const TextField: React.FC<TextFieldPropsInterface> = ({
    className = '',
    input,
    meta: { touched, error },
    helpText,
    type,
    prepend,
    append,
    ...rest
}) => {
    const extraClassName = touched && error ? ' border border-color-red' : '';

    const inputType = type ? type : input.type;

    return (
        <Input
            className={`${className}${extraClassName}`}
            helpText={helpText}
            append={append}
            prepend={prepend}
            error={touched ? error : ''}
            {...input}
            {...rest}
            type={inputType}
        />
    );
};

export default TextField;
