import { FC } from 'react';
import { QuantityDataInterface } from 'models/profiler/columnData';
import { PercentageBackgroundBar } from 'components/visualizations/BackgroundBar';
import QuantityElement from 'components/numbers/Quantity';

const QuantityCell: FC<{ data: QuantityDataInterface }> = ({ data }) => {
    return (
        <div className="secCol secCol--quantity monospace px2" data-testid="quantity-cell-data">
            <div className="secCol--quantity__value flex justify-center items-end">
                <div className="grey-3 fz12 line-height-120 flex-grow-1">
                    <QuantityElement quantity={data.percentage} />
                </div>
                <div className="dark fz16 line-height-120">
                    <QuantityElement quantity={data.value} />
                </div>
            </div>
            <PercentageBackgroundBar className="mt1" percentage={data.percentage.value} />
        </div>
    );
};

export default QuantityCell;
