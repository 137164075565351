import { useBootstrap } from 'components/context/Bootstrap';
import { AuthenticatedBootstrap } from 'models/Bootstrap';

const useEnv = () => {
    const [{ bootstrap }] = useBootstrap();

    if (!(bootstrap instanceof AuthenticatedBootstrap)) {
        throw new Error('Trying to access the env within an unauthenticated context.');
    }

    const env = (bootstrap as AuthenticatedBootstrap).env;

    if (!env) {
        throw new Error('A selected environment was expected');
    }

    return env;
};

export default useEnv;
