import Bouncer from 'components/icons/Bouncer';
import FeatureTogglerProvider from 'components/context/FeatureToggler';
import GlobalMessagesProvider from 'components/context/GlobalMessages';
import { FC, useEffect, useState } from 'react';
import { actions } from 'reducers/bootstrap';
import { AuthenticatedUserProvider } from 'components/context/AuthenticatedUser';
import { getBootstrap } from 'services/api/context';
import { useBootstrap } from 'components/context/Bootstrap';
import { AppContext } from 'models/Bootstrap';
import * as contextService from 'services/context';
import { init as initTimezone } from 'services/timezone';
import tokenRefresh from 'services/api/tokenRefresh';

const Context: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [{ loaded, bootstrap }, dispatch] = useBootstrap();
    const [contextInitialized, setContextInitialized] = useState(false);

    // Note: this effect runs for every change in the Bootstrap object in context.
    useEffect(() => {
        if (!loaded) {
            return;
        }

        contextService.init(bootstrap);

        // Initializing the timezone allows us to have a sync time-formatting implementation.
        initTimezone();

        setContextInitialized(true);

        const { orgTokenRefresh, envTokenRefresh } = bootstrap.urls;
        if (orgTokenRefresh && envTokenRefresh) {
            tokenRefresh.enableRefresh({
                org: orgTokenRefresh,
                env: envTokenRefresh,
            });
        }
    }, [bootstrap, loaded]);

    // Note: this effect runs only when the Bootstrap is (re)loaded remotely.
    useEffect(() => {
        if (loaded) {
            return;
        }

        setContextInitialized(false);

        getBootstrap().then((bootstrapContext: AppContext) => {
            // The bootstrap was reloaded, so we need to refresh the store before
            // dispatching the action to keep the UI in sync.
            dispatch(actions.set(bootstrapContext));
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    if (!loaded || !contextInitialized) {
        return <Bouncer />;
    }

    return (
        <AuthenticatedUserProvider>
            <FeatureTogglerProvider>
                <GlobalMessagesProvider>{children}</GlobalMessagesProvider>
            </FeatureTogglerProvider>
        </AuthenticatedUserProvider>
    );
};

export default Context;
