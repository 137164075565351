import Alert, { AlertTypes } from 'components/messages/Alert';
import AddLicenses from './AddLicenses';
import Bouncer from 'components/icons/Bouncer';
import Icon from 'components/icons/Icon';
import LicensesTable from './LicensesTable';
import LicensesGraph from './LicensesGraph';
import Modal from 'components/modals/Modal';
import { FC, useState, useEffect } from 'react';
import SettingsLayout from '../Layout';
import Timestamp from 'components/format/Timestamp';
import { get as getLicenses, AugmentedLicensesDataInterface } from 'services/api/licenses';
import { DateTimeFormat } from 'services/timezone';

const SettingsLicenses: FC = () => {
    const [status, setStatus] = useState<AugmentedLicensesDataInterface | null>(null);
    const [modalVisible, displayModal] = useState(false);

    useEffect(() => {
        if (status === null) {
            getLicenses().then(licenses => setStatus(licenses));
        }
    }, [status]);

    const showModal = () => displayModal(true);
    const hideModal = () => displayModal(false);

    return (
        <SettingsLayout title="Licenses">
            <>
                {status !== null && (
                    <div className="licenses__alerts mb3 mt4">
                        {status.fewLicenses && (
                            <Alert sticky type={AlertTypes.WARNING}>
                                <div className="flex items-center">
                                    <Icon icon="warning" className="white h2 ml2 mr3" />
                                    <p>
                                        You only have {status.free} {status.free > 1 ? 'licenses' : 'license'} left.
                                        Some of your hosts may not be able to register without additional licenses.{' '}
                                        <span
                                            onClick={showModal}
                                            className="inline-flex bold nowrap underline cursor-hand"
                                        >
                                            Add licenses now
                                        </span>
                                    </p>
                                </div>
                            </Alert>
                        )}
                        {status.noLicenses && (
                            <Alert sticky type={AlertTypes.ERROR}>
                                <div className="flex items-center">
                                    <Icon icon="error" className="white h2 ml2 mr3" />
                                    <p>
                                        You have no licenses left. Your hosts may fail to register without additional
                                        licenses.&nbsp;
                                        <span onClick={showModal} className="underline inline-flex bold cursor-hand">
                                            Add licenses now
                                        </span>
                                    </p>
                                </div>
                            </Alert>
                        )}
                    </div>
                )}

                <p className="h4 lighter mt4 mb3 line-height-120">
                    Licenses allow you to control the scale of your Database Performance Monitor use. Each database and
                    its associated monitoring operating system (whether remote or local) consumes one license. Any
                    operating system instances which do not monitor a database also consume one license.
                </p>
                <div className="grey3 flex light fz16 mt3">
                    <span>Total licenses:</span>&nbsp;
                    <span data-testid="total-licenses">{status && status.total}</span>
                </div>

                {status === null && (
                    <div className="mx3 mt3 pb4 viz__licenses">
                        <Bouncer className="inline-flex" />
                    </div>
                )}

                {status !== null && (
                    <>
                        <LicensesGraph {...status} />
                        <div className="mt3">
                            <LicensesTable />
                            <div>
                                <div className="px3 pt4 pb3 bg-grey05 rounded-top">
                                    <h1 className="h2 mr2 lighter grey3 flex-grow-1">Add Licenses</h1>
                                    <AddLicenses onSuccess={() => setStatus(null)} currentLicenses={status} />
                                </div>
                                <div className="h4 px3 py2 lighter rounded-bottom line-height-120 grey3 bg-white border border-color-grey05 flex items-start">
                                    <Icon icon="info-outline" className="h2 grey3 mr2" />
                                    <p className="relative align__top--3">
                                        Licenses are not needed for users. You can add unlimited users to your
                                        organization&nbsp;
                                        <a href="/settings/people" rel="noopener noreferrer">
                                            here
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {status.aws && (
                            <div className="mt4">
                                <div className="flex">
                                    <h1 className="h2 mr2 lighter grey3 flex-grow-1">AWS Marketplace Licenses</h1>
                                </div>

                                <div className="flex items-baseline mt3">
                                    <div className="h2 mr3" data-testid="aws-licenses">
                                        {status.aws}
                                    </div>
                                    <div className="fz10 uppercase bold spacing mr2">Expiration date:</div>
                                    <div>
                                        <Timestamp
                                            date={status.awsExpiration as number}
                                            format={DateTimeFormat.TIMESTAMP_SHORT}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <p className="lighter mt2 line-height-120">
                                        The licenses you buy through AWS Marketplace are added into your total shown
                                        above. You can use licenses bought through AWS Martketplace just as you would
                                        use your licenses purchased through us.
                                    </p>
                                </div>
                            </div>
                        )}

                        <Modal visible={modalVisible} title="Add Licenses" onClose={hideModal}>
                            <AddLicenses
                                onSuccess={() => {
                                    hideModal();
                                    setStatus(null);
                                }}
                                currentLicenses={status}
                                className="pl4 pr4 pb4"
                            />
                        </Modal>
                    </>
                )}
            </>
        </SettingsLayout>
    );
};

export default SettingsLicenses;
