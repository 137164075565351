import Category, { BySeverityInterface } from './Category';

export interface AssertsSummaryDataInterface {
    bySeverity: BySeverityInterface;
    category: string;
    failedAsserts: number;
    failedOccurrences: number;
    passedOccurrences: number;
    suppressedOccurrences: number;
}

export default class Summary {
    critical = 0;
    warning = 0;
    info = 0;
    passed = 0;
    muted = 0;
    categories: Category[] = [];

    constructor(asserts: AssertsSummaryDataInterface[] = []) {
        this.update(asserts);
    }

    cloneAndUpdate(asserts: AssertsSummaryDataInterface[]) {
        const summary = new Summary([]);

        summary.categories = this.categories;
        summary.update(asserts);

        return summary;
    }

    update(asserts: AssertsSummaryDataInterface[]) {
        this.critical = 0;
        this.warning = 0;
        this.info = 0;
        this.passed = 0;
        this.muted = 0;

        this.categories = asserts.map(assert => {
            let category = this.categories.find(({ name }) => name === assert.category);

            if (category) {
                category.update(
                    assert.failedAsserts,
                    assert.failedOccurrences,
                    assert.passedOccurrences,
                    assert.suppressedOccurrences,
                    assert.bySeverity
                );
            } else {
                category = new Category(
                    assert.category,
                    assert.failedAsserts,
                    assert.failedOccurrences,
                    assert.passedOccurrences,
                    assert.suppressedOccurrences,
                    assert.bySeverity
                );
            }

            this.critical += assert.bySeverity.crit ?? 0;
            this.warning += assert.bySeverity.warn ?? 0;
            this.info += assert.bySeverity.info ?? 0;
            this.passed += assert.passedOccurrences ?? 0;
            this.muted += assert.suppressedOccurrences ?? 0;

            return category;
        });
    }
}
