import FindOrganizationForm from './Form';
import Heading from 'components/modules/public/Heading';
import MetaTags from 'components/layout/MetaTags';

const FindOrganization = () => (
    <>
        <MetaTags title="Forgotten organization - DPM" />

        <Heading>Find my organizations</Heading>

        <section id="reset" className="card-block shadow mx-auto my4 relative flex bg-white p3 flex-column">
            <FindOrganizationForm />
        </section>
    </>
);

export default FindOrganization;
