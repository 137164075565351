export interface TokenRefreshURLInterface {
    org: string;
    env: string;
}

class TokenRefresh {
    private org?: string;
    private env?: string;

    constructor() {
        this.org = undefined;
        this.env = undefined;
    }

    get isRefreshEnabled() {
        return this.org && this.env;
    }

    get orgUrl(): string {
        if (!this.org) {
            throw new Error('Token refresh is not enabled');
        }

        return this.org;
    }

    getEnvUrl(env: number): string {
        if (!this.env) {
            throw new Error('Token refresh is not enabled');
        }

        return this.env.replace(':id', env.toString());
    }

    enableRefresh(url: TokenRefreshURLInterface) {
        this.org = url.org;
        this.env = url.env;
    }
}

const tokenRefresh = new TokenRefresh();

export default tokenRefresh;
