import APIError from 'services/api/error/Error';
import { FC, useState, SyntheticEvent } from 'react';
import BouncerButton from 'components/form/BouncerButton';
import Select from 'components/form/Select';
import Role from 'models/Role';
import useDisplayMessage from 'hooks/useDisplayMessage';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import APIToken from 'models/APIToken';
import { createEnvToken } from 'services/api/tokens';

interface TokenCreationInterface {
    roles: Role[];
    onCancel: () => void;
    onSuccess?: (token: APIToken) => void;
}

const APITokenCreation: FC<TokenCreationInterface> = ({ roles, onCancel, onSuccess }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [role, setRole] = useState<Role>(roles[0]);
    const [name, setName] = useState('');

    const roleOptions = roles
        .filter(({ assignable }) => assignable.toTokens)
        .map(({ name: roleName, id: value }) => ({ name: roleName, value }));

    const { success, error } = useDisplayMessage();

    const handleRoleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
        const newRole = roles.find(({ id }) => id === parseInt(e.currentTarget.value, 10));

        if (newRole) {
            setRole(newRole);
        }
    };

    const handleSubmit = async () => {
        setIsSaving(true);
        try {
            const token = await createEnvToken(name, role);
            setIsSaving(false);

            if (onSuccess) {
                onSuccess(token);
                success('The token was successfully created.');
            }
        } catch (e) {
            let message = 'An error occurred when creating the new token.';

            if (e instanceof APIError) {
                switch (e.status) {
                    case 403:
                        message = "You don't have permission to create this token";
                        break;
                    case 409:
                        message = 'API Tokens must have unique names';
                        break;
                }
            }

            error(message);
            setIsSaving(false);
        }
    };

    return (
        <>
            <form className="settings__form settings__form--create-teams p3 bg-white" name="tokenForm">
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mb3" label="Name:">
                    <input
                        value={name}
                        onChange={(e: SyntheticEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
                        data-testid="new-token-input"
                        className="full-width"
                    />
                </FormGroup>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Select a role:">
                    <Select options={roleOptions} onChange={handleRoleChange} data-testid="new-role-select" />
                </FormGroup>
            </form>
            <div className="flex justify-end p3 bg-grey05">
                <button type="button" className="primary-grey mr2" onClick={() => onCancel()}>
                    Cancel
                </button>
                <BouncerButton
                    bounce={isSaving}
                    onClick={handleSubmit}
                    disabled={isSaving || !name}
                    className="primary-orange"
                    data-testid="create-button"
                >
                    Create
                </BouncerButton>
            </div>
        </>
    );
};

export default APITokenCreation;
