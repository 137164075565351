import { RequestTarget, TargetEnv } from './Request';
import * as tokenHandler from 'services/token/handler';
import Logger from 'services/logger';
import moment from 'moment-timezone';
import * as contextService from 'services/context';
import tokenStorage from 'services/token/storage';
import { post, HttpError } from 'services/http';
import { reportSessionExpired, SessionExpiredError } from 'services/sessionStatus';
import tokenRefresh from './tokenRefresh';

const logger = Logger.get('Auth');

function set(target: RequestTarget, jwt: string) {
    switch (target) {
        case TargetEnv.CURRENT_ENV: {
            tokenStorage.envToken = tokenHandler.decode(jwt);
            break;
        }
        case TargetEnv.CURRENT_ORG: {
            tokenStorage.orgToken = tokenHandler.decode(jwt);
            break;
        }
        default: {
            tokenStorage.setTokenForEnv(target as number, tokenHandler.decode(jwt));
        }
    }
}

async function performRequest(url: string, tokenPath: string, message: string) {
    try {
        const response = await post<{ [s: string]: string }>(url);
        const responseToken = response.data[tokenPath];
        return responseToken;
    } catch (e) {
        if (e instanceof HttpError && e.isUnauthorized) {
            throw new SessionExpiredError(e.message);
        }

        throw new Error(`Unable to obtain token for ${message}`);
    }
}

async function fetchEnvToken(env: number) {
    return performRequest(tokenRefresh.getEnvUrl(env), TargetEnv.CURRENT_ENV, `env ${env}`);
}

async function fetchOrgToken() {
    return performRequest(tokenRefresh.orgUrl, TargetEnv.CURRENT_ORG, 'current org');
}

async function refreshOrgToken() {
    const jwt = await fetchOrgToken();
    set(TargetEnv.CURRENT_ORG, jwt);
    return jwt;
}

async function refreshEnvToken(target: RequestTarget, env: number) {
    const jwt = await fetchEnvToken(env);
    set(target, jwt);
    return jwt;
}

async function doRefreshToken(target: RequestTarget) {
    if (target === TargetEnv.CURRENT_ORG) {
        return refreshOrgToken();
    }

    const currentEnvID = contextService.get().env?.id;
    if (target === TargetEnv.CURRENT_ENV && !currentEnvID) {
        throw new Error('Current env is not defined');
    }

    const env = target === TargetEnv.CURRENT_ENV ? (currentEnvID as number) : (target as number);

    return refreshEnvToken(target, env);
}

async function refreshToken(target: RequestTarget) {
    return doRefreshToken(target).catch(e => {
        if (e instanceof SessionExpiredError) {
            reportSessionExpired(e);
        }

        return Promise.reject(e);
    });
}

function getTokenRemainingTime(token: tokenHandler.TokenInterface) {
    const now = moment();
    const expirationSeconds = moment.unix(token.expires).diff(now, 'seconds');
    logger.debug(`tokens will expire in ${expirationSeconds} seconds`);
    return expirationSeconds;
}

function isTokenFresh(token: tokenHandler.TokenInterface, target: RequestTarget) {
    const remaining = getTokenRemainingTime(token);

    if (remaining <= 20) {
        logger.debug('token expired');
        return false;
    }

    if (remaining < 120 && tokenRefresh.isRefreshEnabled) {
        logger.debug('token will expire soon');

        // tokens are going to expire soon, let's renew them in background
        refreshToken(target);
        return true;
    }

    logger.debug('token still fresh');
    return true;
}

function getTokenFromStorage(target: RequestTarget) {
    switch (target) {
        case TargetEnv.CURRENT_ENV:
            return tokenStorage.envToken;

        case TargetEnv.CURRENT_ORG:
            return tokenStorage.orgToken;

        default:
            return tokenStorage.getTokenForEnv(target);
    }
}

export async function get(target: RequestTarget) {
    let token = getTokenFromStorage(target);

    if (token && isTokenFresh(token, target)) {
        return token;
    }

    if (!token) {
        logger.warn(`Token for target ${target} not found`);
    }

    if (tokenRefresh.isRefreshEnabled) {
        await refreshToken(target);
        token = getTokenFromStorage(target);
    }

    //this shouldn't happen as the token is refreshed if it was initially not set
    if (!token) {
        throw new Error(`The token is undefined for target ${target}`);
    }

    return token;
}

export async function requestNewTokens() {
    await refreshToken(TargetEnv.CURRENT_ORG);

    if (contextService.get().env) {
        await refreshToken(TargetEnv.CURRENT_ENV);
    }
}
