import Bootstrap from 'models/Bootstrap';
import moment from 'moment-timezone';
import Logger from 'services/logger';

interface InitParamInteface {
    baseLiveAgentContentURL: string;
    deploymentId: string;
    buttonId: string;
    baseLiveAgentURL: string;
    eswLiveAgentDevName: string;
    isOfflineSupportEnabled: boolean;
}

declare global {
    interface Window {
        embedded_svc: {
            settings: string;
            isButtonDisabled: boolean;
            onHelpButtonClick: () => boolean;
            init: (...[string]: (string | InitParamInteface)[]) => void;
        };
    }
}

interface EntityFieldMap {
    doCreate: boolean;
    doFind: boolean;
    fieldName: string;
    isExactMatch: boolean;
    label: string;
}

interface Conf {
    label: string;
    transcriptFields: string[];
    value: string;
    displayToAgent: boolean;
}

interface Configuration {
    scriptUrl: string;
    domain: string;
    initParams: (string | InitParamInteface)[];
    clientSettings?: {
        displayHelpButton: boolean;
        language: string;
        storageDomain: string;
        enabledFeatures: string[];
        entryFeature: string;
        disabledMinimizedText: string;
        extraPrechatInfo: {
            entityName: string;
            entityFieldMaps: EntityFieldMap[];
        }[];
        prepopulatedPrechatFields: {
            FirstName?: string;
            LastName?: string;
            Email?: string;
        };
        extraPrechatFormDetails: Conf[];
    };
}

export const getSalesforceChatConfiguration = (context: Bootstrap) => {
    const org = context.org?.nickname;

    const PROD_CONFIGURATION: Configuration = {
        scriptUrl: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
        domain: `${org}.app.vividcortex.com`,
        initParams: [
            'https://solarwindscore.my.salesforce.com',
            'https://solarwindssupport.secure.force.com/TSDPMChat',
            'https://service.force.com',
            '00D500000006e2N',
            'TS_DPM_Chat',
            {
                baseLiveAgentContentURL: 'https://c.la3-c2-ia6.salesforceliveagent.com/content',
                deploymentId: '5722J000000k9ca',
                buttonId: '5732J000000k9eb',
                baseLiveAgentURL: 'https://d.la3-c2-ia6.salesforceliveagent.com/chat',
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I2J000000blK7UAI_175dd5a6bb1',
                isOfflineSupportEnabled: false,
            },
        ],
    };

    const STAGE_CONFIGURATION: Configuration = {
        scriptUrl: 'https://solarwindscore--test.my.salesforce.com/embeddedservice/5.0/esw.min.js',
        domain: `${org}.stage.stage.vividcortex.com`,
        initParams: [
            'https://solarwindscore--test.my.salesforce.com',
            'https://test-solarwindssupport.cs24.force.com/TSDPMChat',
            'https://service.force.com',
            '00D190000001Wxz',
            'TS_DPM_Chat',
            {
                baseLiveAgentContentURL: 'https://c.la1-c1cs-ph2.salesforceliveagent.com/content',
                deploymentId: '5722J000000k9ca',
                buttonId: '5732J000000k9eb',
                baseLiveAgentURL: 'https://d.la1-c1cs-ph2.salesforceliveagent.com/chat',
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I2J000000blK7UAI_175dd5a6bb1',
                isOfflineSupportEnabled: false,
            },
        ],
    };

    const generatePrePopulatedPrechatFields = () => {
        if (!context.user) {
            return {};
        }

        const user = context.user;

        // Salesforce requires first name and last name, but we only have a full name. So we are forced to split.
        const split = user.name.split(/\s/);
        const firstName = split.shift();
        const lastName = split.join(' ');

        return {
            FirstName: firstName,
            LastName: lastName,
            Email: user.email,
        };
    };

    const generateDataToPrePopulate = () => {
        const installationPrefix = context.isMainInstallation() ? '' : `${context.installation}-`;
        const conf: Conf[] = [];

        if (context.user) {
            const user = context.user;

            conf.push(
                {
                    label: 'User ID',
                    transcriptFields: ['DPM_User_ID__c'],
                    value: installationPrefix + user.id,
                    displayToAgent: true,
                },
                {
                    label: 'User Creation Date',
                    transcriptFields: ['DPM_User_Creation_Date__c'],
                    value: moment(user.created).format('YYYY-MM-DD HH:mm:ss'),
                    displayToAgent: true,
                }
            );

            // We only send the organization for authenticated users to avoid polution while impersonating.
            // See https://github.com/VividCortex/ng-app/issues/5321
            if (context.org) {
                const contextOrg = context.org;
                conf.push(
                    {
                        label: 'Company ID',
                        transcriptFields: ['DPM_Company_ID__c'],
                        value: contextOrg.id + '',
                        displayToAgent: true,
                    },
                    {
                        label: 'Company Name',
                        transcriptFields: ['DPM_Company_Name__c'],
                        value: contextOrg.name,
                        displayToAgent: true,
                    },
                    {
                        label: 'Company Creation Date',
                        transcriptFields: ['DPM_Company_Creation_Date__c'],
                        value: moment(contextOrg.created).format('YYYY-MM-DD HH:mm:ss'),
                        displayToAgent: true,
                    }
                );
            }
        }

        return conf;
    };

    const CONF = context.stage === 'prod' ? PROD_CONFIGURATION : STAGE_CONFIGURATION;

    CONF.clientSettings = {
        displayHelpButton: true,

        // e.g. 'en', 'en-US'
        language: 'en-US',

        // Sets the domain so that visitors can navigate subdomains during a chat session
        storageDomain: CONF.domain, // Comment this to test locally

        enabledFeatures: ['LiveAgent'],
        entryFeature: 'LiveAgent',

        // Text shown when all reps are offline. It defaults to "Agents Offline"
        // See https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_set_chat_text.htm
        disabledMinimizedText: 'No one available',

        extraPrechatInfo: [
            {
                entityName: 'Contact',
                entityFieldMaps: [
                    {
                        doCreate: true,
                        doFind: false,
                        fieldName: 'DPM_User_ID__c',
                        isExactMatch: true,
                        label: 'User ID',
                    },
                    {
                        doCreate: true,
                        doFind: false,
                        fieldName: 'DPM_User_Creation_Date__c',
                        isExactMatch: true,
                        label: 'User Creation Date',
                    },
                    {
                        doCreate: true,
                        doFind: false,
                        fieldName: 'DPM_Company_ID__c',
                        isExactMatch: true,
                        label: 'Company ID',
                    },
                    {
                        doCreate: true,
                        doFind: false,
                        fieldName: 'DPM_Company_Name__c',
                        isExactMatch: true,
                        label: 'Company Name',
                    },
                    {
                        doCreate: true,
                        doFind: false,
                        fieldName: 'DPM_Company_Creation_Date__c',
                        isExactMatch: true,
                        label: 'Company Creation Date',
                    },
                ],
            },
        ],

        prepopulatedPrechatFields: generatePrePopulatedPrechatFields(),
        extraPrechatFormDetails: generateDataToPrePopulate(),
    };

    return CONF;
};

export const init = (salesforceChatConfiguration: Configuration) => {
    Logger.get('SalesforceChat').info('script loaded');
    if (!window.embedded_svc) {
        throw new Error('Error while initializing SalesForce Chat');
    }

    const client = window.embedded_svc;

    Logger.get('SalesforceChat').debug(salesforceChatConfiguration);
    Object.assign(client.settings, salesforceChatConfiguration.clientSettings);

    Logger.get('SalesforceChat').info('initializing chat');
    client.init(...salesforceChatConfiguration.initParams);
};

export const isReady = () => !window.embedded_svc?.isButtonDisabled;

export const show = () => window.embedded_svc?.onHelpButtonClick();
