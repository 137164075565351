import Icon from 'components/icons/Icon';
import Dropdown from 'components/dropdowns/Dropdown';
import CreateEnvironment from 'components/modules/app/Environments/Create';
import { FC, useState } from 'react';
import useDisplayMessage from 'hooks/useDisplayMessage';
import useAuthenticatedContext from 'hooks/useAuthenticatedContext';
import { UserHasPermission } from 'components/security/UserHasPermission';

const EnvironmentSelector: FC = () => {
    const [open, setOpen] = useState(false);
    const [addEnv, setAddEnv] = useState(false);
    const context = useAuthenticatedContext();
    const { success } = useDisplayMessage();

    const currentEnv = context.env;

    if (!currentEnv) {
        return null;
    }

    const envs = context.envs;
    const filteredEnvs = envs.filter(env => env.id !== currentEnv?.id).sort((a, b) => a.name.localeCompare(b.name));

    const handleOnToggle = (state: boolean) => {
        if (!state) {
            setOpen(false);
        }
    };

    const onClick = () => {
        setOpen(false);
        setAddEnv(true);
    };

    return (
        <>
            <div
                className={`env-selector cursor-hand user-select no-shrink border-bottom border-color-semi-dark mb3 ${
                    open ? 'env-selector--open' : ''
                }`}
            >
                <div className="env-selector__toggler flex relative items-center full-height">
                    <Icon icon="env-selector" className="env-selector__toggler__org-icon flex grey2 h1 ml3  mr2 fz20" />
                    <div
                        className="cursor-hand white no-decoration full-width full-height flex pl2 pr4 flex-column justify-center relative z2 truncate dropdown__label"
                        data-testid="header-env-selector-toggler"
                        onClick={() => setOpen(!open)}
                    >
                        <div className="white env-selector__toggler__env-name h4 line-height-120">
                            {currentEnv.name}
                        </div>
                        <div className="env-selector__toggler__org-name h4 monospace capitalize truncate grey3 lighter line-height-120">
                            {context.org.name}
                        </div>
                        <Icon
                            icon="keyboard_arrow_right"
                            className="h2 flex grey2 absolute right-2 env-selector__toggler__arrow"
                        />
                    </div>
                </div>

                <Dropdown isExpanded={open} className="dropdown--right" onToggle={handleOnToggle}>
                    <div className="bg-paleDark white z1 rounded">
                        {filteredEnvs.length === 0 && (
                            <p className="centerGrey pt2 px2 pb3 h4 cursor-normal line-height-120">
                                There is no other environment installed
                            </p>
                        )}
                        {filteredEnvs.length > 0 && (
                            <>
                                <p className="flex px3 pt3 pb1 swiOrange uppercase">Select Environment</p>
                                <ul className="env-selector__toggler__env__list list-reset overflow-auto pt1 pb2">
                                    {filteredEnvs.map(env => (
                                        <li className="env-selector__content__list__item" key={env.id}>
                                            <a
                                                href={env.url}
                                                title={env.name}
                                                className="flex items-center justify-between truncate nowrap white no-decoration py2 pl3 cursor-hand fz16"
                                            >
                                                <span className="truncate">{env.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        <UserHasPermission to="createEnv">
                            <div className="cursor-normal pb3 px2" data-testid="header-create-env-button">
                                <button
                                    className="create-env-button primary-semiDark flex items-center nowrap"
                                    data-testid="create-env-button"
                                    onClick={onClick}
                                >
                                    <span className="fz15">Add Environment</span>
                                </button>
                            </div>
                        </UserHasPermission>
                    </div>
                </Dropdown>
            </div>
            <CreateEnvironment
                visible={addEnv}
                onClose={() => {
                    setAddEnv(false);
                }}
                onSuccess={name => {
                    setAddEnv(false);
                    success(`Environment ${name} successfully added`);
                }}
            />
        </>
    );
};

export default EnvironmentSelector;
