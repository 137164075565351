import useMounted from 'hooks/useMounted';
import { useEffect, useState } from 'react';
import { fetchTopics, TopicInterface } from 'services/documentation';
import Logger from 'services/logger';

type State = 'idle' | 'loading' | 'loaded' | 'error';

const logger = Logger.get('documentation');
const cache: TopicInterface[] = [];

/**
 * Load the topics from the documentation site and cache the result, to prevent
 * loading them over and over while users navigate through the in-app
 * documentation.
 */
export default function useTopics() {
    const [topics, setTopics] = useState<TopicInterface[]>([]);
    const [state, setState] = useState<State>('idle');
    const { resolveIfMounted } = useMounted();

    async function load(): Promise<TopicInterface[]> {
        if (cache.length > 0) {
            return cache;
        }

        const fetchedTopics = await fetchTopics();

        cache.push(...fetchedTopics);
        return cache;
    }

    useEffect(() => {
        setState('loading');

        resolveIfMounted(load())
            .then(fetchedTopics => {
                setTopics(fetchedTopics);
                setState('loaded');
            })

            .catch(() => {
                logger.error('Failed to load topics from Documentation Site');
                setState('error');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { state, topics };
}
