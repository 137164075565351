import Alert, { AlertTypes } from 'components/messages/Alert';
import Bouncer from 'components/icons/Bouncer';
import Icon from 'components/icons/Icon';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import FeatureLink from 'components/url/FeatureLink';
import { useState, useEffect, SyntheticEvent, FC } from 'react';
import Select, { GroupedSelectOptionsInterface } from 'components/form/Select';
import SlideSwitch from 'components/form/SlideSwitch';
import Timestamp from 'components/format/Timestamp';
import {
    clearEnvironmentTimezone,
    clearUserTimezone,
    fromEnvironment,
    fromUser,
    getTimezones,
    getCurrentTimezone,
    saveUserTimezone,
    saveEnvironmentTimezone,
} from 'services/timezone';
import { getCurrentTimestamp } from 'services/time';

function groupByTimezone(timezones: string[]) {
    return timezones.reduce((groups: GroupedSelectOptionsInterface[], timezone: string) => {
        const groupName = timezone.split('/')[0];
        let group = groups.find(({ name }) => name === groupName);

        if (!group) {
            group = { name: groupName, options: [] };
            groups.push(group);
        }

        group.options.push({
            name: timezone,
            value: timezone,
        });

        return groups;
    }, []);
}

const states = {
    READY: 'ready',
    SAVING: 'saving',
    ERROR: 'error',
};

const TimezoneSelector: FC<{ isEnvironmentSetting: boolean }> = ({ isEnvironmentSetting }) => {
    const [customTimezone, setUseCustomTimezone] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState<string | null>(null);
    const [state, setState] = useState(states.READY);

    const save = isEnvironmentSetting ? saveEnvironmentTimezone : saveUserTimezone;
    const get = isEnvironmentSetting ? fromEnvironment : fromUser;
    const clear = isEnvironmentSetting ? clearEnvironmentTimezone : clearUserTimezone;

    const timezones = groupByTimezone(getTimezones());

    async function initSelectedTimezone() {
        let timezone = await get();

        if (timezone && !customTimezone) {
            setUseCustomTimezone(true);
        } else {
            timezone = (await getCurrentTimezone()) as string;
        }

        setSelectedTimezone(timezone);
    }

    useEffect(() => {
        initSelectedTimezone();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function handleChange(e: SyntheticEvent<HTMLSelectElement>) {
        const currentTimezone = selectedTimezone;

        setSelectedTimezone(e.currentTarget.value);
        setState(states.SAVING);

        try {
            await save(e.currentTarget.value);

            setState(states.READY);
        } catch {
            setState(states.ERROR);
            setSelectedTimezone(currentTimezone);
        }
    }

    async function handleSwitchChange() {
        const currentStatus = customTimezone;

        setState(states.SAVING);
        setUseCustomTimezone(!customTimezone);

        try {
            if (currentStatus) {
                await clear();
            } else {
                await save(selectedTimezone as string);
            }

            setState(states.READY);
        } catch (e) {
            setUseCustomTimezone(currentStatus);

            setState(states.ERROR);
        }
    }

    if (!selectedTimezone) {
        return <Bouncer />;
    }

    return (
        <div>
            {state === states.ERROR && (
                <Alert type={AlertTypes.ERROR} className="mt3">
                    There was an error updating your timezone selection.
                </Alert>
            )}
            <form className="settings__form settings__form--preferences pt4">
                <FormGroup
                    variant={FormGroupVariantType.HORIZONTAL}
                    label="Use a custom time zone:"
                    className="field-slideswitch-viariable-help"
                >
                    <>
                        <SlideSwitch
                            data-testid="custom-timezone-switch"
                            onChange={handleSwitchChange}
                            checked={customTimezone}
                        />
                        <div className="form__input-container__explain grey3 line-height-120 flex flex-column mb3">
                            {isEnvironmentSetting && (
                                <>
                                    <span>Switch between your browser's time zone and a custom one.</span>

                                    {customTimezone && (
                                        <span>*Every user sees times in the time zone you select below.</span>
                                    )}

                                    {!customTimezone && (
                                        <div className="flex items-start line-height-120 mt3">
                                            <Icon icon="info-outline" className="h2 orange mr2" />
                                            <div className="flex flex-column">
                                                <span className="orange mt1">
                                                    Your custom timezone will override this setting.
                                                </span>
                                                <FeatureLink to="/settings/profile" appendEnv={false}>
                                                    Set your custom timezone
                                                </FeatureLink>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {!isEnvironmentSetting && (
                                <>
                                    By default, Database Performance Monitor uses your browser's timezone or custom
                                    environment timezone if one is set. You can toggle this setting to override the
                                    currently used timezones and set your own. Setting the timezone here will not affect
                                    other users.
                                </>
                            )}
                        </div>
                    </>
                </FormGroup>

                {customTimezone && selectedTimezone && (
                    <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Select your desired time zone:">
                        <>
                            <Select
                                data-testid="custom-timezone-select"
                                options={timezones}
                                value={selectedTimezone}
                                className="block"
                                grouped
                                onChange={handleChange}
                            />

                            <p className="form__input-container__explain grey3 pt2 grey3 line-height-120">
                                {state !== states.SAVING && <Timestamp date={getCurrentTimestamp()} />}
                            </p>
                        </>
                    </FormGroup>
                )}
            </form>
        </div>
    );
};

export default TimezoneSelector;
