import Role, { RoleDataInterface, Roles } from 'models/Role';
import APIRequest, { TargetEnv } from './Request';

export async function get() {
    const response = await new APIRequest<RoleDataInterface[]>({
        path: 'roles',
        target: TargetEnv.CURRENT_ORG,
    }).request();

    return response.data.map(data => new Role(data));
}

export async function getOwnerRole() {
    const roles = await get();

    return roles.find(role => role.name === Roles.OWNER);
}
