import { FC } from 'react';
import Icon from 'components/icons/Icon';
import Host from 'models/hosts/Host';

export interface OccurenceAndHostInterface {
    occurence: { hostId: number; title: string };
    host: Host;
    read: boolean;
}

export interface NotificationRowInterface {
    occurenceAndHost: OccurenceAndHostInterface;
    onUpdate: (occurenceAndHost: OccurenceAndHostInterface) => void;
}

const NotificationRow: FC<NotificationRowInterface> = ({ occurenceAndHost, onUpdate }) => {
    const update = () => {
        onUpdate(occurenceAndHost);
    };
    const occurenceRedClass = occurenceAndHost.read ? '' : 'bg-red';

    return (
        <div className="flex items-center p3 border-bottom border-color-white">
            <span className={`${occurenceRedClass} notifications__notification circle flex mt1 mr2 no-shrink`}></span>
            <Icon icon={occurenceAndHost.host?.type || ''} className="h2 grey3 fz18" />
            <p className="fz13 relative align__top--3 px2 break-all line-height-120">
                {occurenceAndHost.occurence.title + occurenceAndHost.host.name}
            </p>
            <div>
                <button className="primary-mainColor nowrap" data-testid="updateNow-button" onClick={update}>
                    Update now
                </button>
            </div>
        </div>
    );
};

export default NotificationRow;
