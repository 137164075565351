import axios from 'axios';
import { notifyError, ErrorSettingsInterface } from './error/reporter';
import { ContextualError, ErrorContextInterface } from './error/error';

interface RequestConfigInterface {
    expectedStatus?: number[];
    reportExpectedAsInfo?: boolean;
    context?: string;
}

const defaultRequestConfig: Required<RequestConfigInterface> = {
    expectedStatus: [],
    reportExpectedAsInfo: false,
    context: '',
};

type HttpMethod = 'get' | 'head' | 'post' | 'patch';

export interface HttpResponseInterface<T> {
    status: number;
    data: T;
    headers?: Record<string, string | boolean>;
}

export interface GenericHttpErrorInterface<T = unknown> extends Error {
    response?: HttpResponseInterface<T>;
}

export class HttpError<T = unknown> extends ContextualError {
    readonly response?: HttpResponseInterface<T>;
    constructor(error: GenericHttpErrorInterface<T>, name = 'HttpError', context: ErrorContextInterface = {}) {
        super(name, error.message, context);

        this.response = error.response;
    }

    get isUnauthorized() {
        return 401 === this.response?.status;
    }
}

function buildErrorSettings(
    method: string,
    url: string,
    error: HttpError,
    { expectedStatus, reportExpectedAsInfo, context }: Required<RequestConfigInterface>
): ErrorSettingsInterface | undefined {
    const status = error.response?.status || 0;
    if (expectedStatus.includes(status) && !reportExpectedAsInfo) {
        return undefined;
    }

    const severity = expectedStatus.includes(status) && reportExpectedAsInfo ? 'info' : 'error';

    return {
        context,
        severity,
        metadata: {
            'http-request': { url, method },
            'http-response': { status },
        },
    };
}

async function request<T>(
    method: HttpMethod,
    url: string,
    data?: unknown,
    config: RequestConfigInterface = {}
): Promise<HttpResponseInterface<T>> {
    try {
        const response = await axios({
            method,
            url,
            data,
        });
        return { data: response.data, status: response.status };
    } catch (error) {
        const httpError = new HttpError<T>(error instanceof Error ? error : new Error('Unknown error'));
        const normalizedConfig: Required<RequestConfigInterface> = { ...defaultRequestConfig, ...config };

        const errorSettings = buildErrorSettings(method, url, httpError, normalizedConfig);
        if (errorSettings !== undefined) {
            notifyError(httpError, errorSettings);
        }

        throw httpError;
    }
}

export async function get<T>(url: string, config: RequestConfigInterface = {}): Promise<HttpResponseInterface<T>> {
    return request('get', url, undefined, config);
}

export function post<T>(
    url: string,
    data?: unknown,
    config: RequestConfigInterface = {}
): Promise<HttpResponseInterface<T>> {
    return request<T>('post', url, data, config);
}
export function patch<T>(
    url: string,
    data?: unknown,
    config: RequestConfigInterface = {}
): Promise<HttpResponseInterface<T>> {
    return request<T>('patch', url, data, config);
}
