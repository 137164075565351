import { FC, SyntheticEvent } from 'react';
import Team from 'models/Team';
import { InvitationInterface } from './InviteUsersModal';
import { FieldRenderProps } from 'react-final-form';
import Input from 'components/form/fields/TextField';
import { Field } from 'react-final-form-html5-validation';
import Group, { FormGroupVariantType } from 'components/form/Group';
import Icon from 'components/icons/Icon';
import Select from 'components/form/Select';

const InviteCard: FC<{
    invitation: InvitationInterface;
    onRemoveInvite: (invitation: InvitationInterface) => void;
    teams: Team[];
    onInviteChange: (invitation: InvitationInterface) => void;
    isRemovable: boolean;
}> = ({ invitation, onRemoveInvite, teams, onInviteChange, isRemovable }) => {
    const handleEmailChange = (value = '') => {
        invitation.email = value;
        onInviteChange(invitation);
        return value;
    };

    const handleNameChange = (value = '') => {
        invitation.name = value;
        onInviteChange(invitation);
        return value;
    };

    const handleTeamChange = (event: SyntheticEvent<HTMLSelectElement>) => {
        const teamID = event.currentTarget.value;
        const team = teams.find(({ id }) => id === +teamID);
        if (!team) {
            return;
        }
        invitation.team = team;
        onInviteChange(invitation);
    };

    const teamOptions = teams.map(({ name, id: value }) => ({ name, value }));

    return (
        <fieldset
            className="invite-form__fieldsets bg-white p3 mx3 mb3 relative"
            key={invitation.position}
            data-testid="invite-card"
        >
            {isRemovable && (
                <Icon
                    icon="close"
                    data-testid={`remove-invite-card-${invitation.position}`}
                    className="mr2 inline-flex relative alert__close-icon cursor-hand absolute top-2 right-2 fz18"
                    onClick={() => onRemoveInvite(invitation)}
                />
            )}
            <Group variant={FormGroupVariantType.HORIZONTAL} className="mb3 mt3" label="Full name:">
                <Field
                    type="text"
                    name={`name[${invitation.position}]`}
                    data-testid={`invite-name-${invitation.position}`}
                    required
                    valueMissing="The name is required"
                    parse={handleNameChange}
                >
                    {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <Input {...props} />}
                </Field>
            </Group>

            <Group variant={FormGroupVariantType.HORIZONTAL} className="mb3" label="Email:">
                <Field
                    type="email"
                    name={`email[${invitation.position}]`}
                    data-testid={`invite-email-${invitation.position}`}
                    required
                    valueMissing="The email is required"
                    typeMismatch="The email is not correct"
                    parse={handleEmailChange}
                >
                    {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <Input {...props} />}
                </Field>
            </Group>

            <Group variant={FormGroupVariantType.HORIZONTAL} className="mb3" label="Select a team:">
                <Select
                    options={teamOptions}
                    className=""
                    onChange={handleTeamChange}
                    data-testid={`invite-team-${invitation.position}`}
                />
            </Group>
        </fieldset>
    );
};

export default InviteCard;
