import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import { useHostFilter } from 'components/context/HostFilter';
import { useLocation } from 'react-router-dom';
import { isCurrentLocationSettings } from 'services/routes';

const excludePaths = ['/'];

const HostsSelectionStatus = () => {
    const { totals, set } = useHostFilter();
    const location = useLocation();

    if (!totals || excludePaths.includes(location.pathname) || isCurrentLocationSettings(location.pathname)) {
        return null;
    }

    if (totals.active === 0) {
        return <EmptyState type={EmptyTypes.SERVER}>There are no active hosts.</EmptyState>;
    }

    if (totals.matching === 0) {
        return (
            <EmptyState type={EmptyTypes.SERVER}>
                There are no hosts matching your filter
                <br />
                <p>
                    Try{' '}
                    <span className="cursor-pointer link" onClick={() => set('')}>
                        clearing the hosts filter
                    </span>
                </p>
            </EmptyState>
        );
    }

    return null;
};

export default HostsSelectionStatus;
