import { useReducer } from 'react';
import { PostInterface, TopicInterface } from 'services/documentation';

type State = 'idle' | 'main' | 'topic' | 'post' | 'foundPost';

export interface DocumentationStateInterface {
    name: State;
}

abstract class BaseState implements DocumentationStateInterface {
    constructor(readonly name: State) {}
}

export class MainState extends BaseState {
    constructor() {
        super('main');
    }
}

export class IdleState extends BaseState {
    constructor() {
        super('idle');
    }
}

export class TopicState extends BaseState {
    constructor(readonly topic: TopicInterface) {
        super('topic');
    }
}

export class PostState extends BaseState {
    constructor(readonly post: PostInterface) {
        super('post');
    }
}
export class TopicPostState extends PostState {
    constructor(readonly topic: TopicInterface, post: PostInterface) {
        super(post);
    }
}

type StateAction =
    | { type: 'idle' }
    | { type: 'main' }
    | { type: 'topic'; topic: TopicInterface }
    | { type: 'post'; post: PostInterface }
    | { type: 'topicPost'; topic: TopicInterface; post: PostInterface };

const reducer: React.Reducer<DocumentationStateInterface, StateAction> = (state, action) => {
    switch (action.type) {
        case 'idle':
            return new IdleState();
        case 'main':
            return new MainState();
        case 'topic':
            return new TopicState(action.topic);
        case 'post':
            if (state instanceof TopicState) {
                return new TopicPostState(state.topic, action.post);
            }
            return new PostState(action.post);
        default:
            throw new Error(`[documentation] unexpected action type ${action.type}`);
    }
};

export function useDocumentationState() {
    const [state, dispatch] = useReducer(reducer, new IdleState());

    return {
        state,
        idle: () => dispatch({ type: 'idle' }),
        goToMain: () => dispatch({ type: 'main' }),
        activateTopic: (topic: TopicInterface) => dispatch({ type: 'topic', topic }),
        activatePost: (post: PostInterface) => dispatch({ type: 'post', post }),
    };
}
