import APIRequest from './Request';
import { get as httpGet } from 'services/http';
import Logger from 'services/logger';
const logger = Logger.get('licenses');

export interface LicensesDataInterface {
    total: number;
    used: number;
    byContractType?: {
        direct: number;
        awsmp: number;
    };
}

export interface LicensesPerEnvDataInterface {
    perEnv: [{ env: string; licenses: number }];
}

export interface AugmentedLicensesDataInterface {
    total: number;
    used: number;
    free: number;
    noLicenses: boolean;
    fewLicenses: boolean;
    aws?: number;
    awsExpiration?: number;
}

export async function get() {
    logger.info('getting the organization licenses');

    const response = await new APIRequest<LicensesDataInterface>({
        path: 'org/licenses',
    }).request();

    const free = response.data.total - response.data.used;
    const noLicenses = free <= 0;

    const licenses: AugmentedLicensesDataInterface = {
        total: response.data.total,
        used: response.data.used,
        free,
        noLicenses,
        fewLicenses: !noLicenses && response.data.total > 2 && free < 3,
    };

    if (response.data.byContractType?.awsmp) {
        const awsLicenses = await httpGet<{ expires: number }>('/xhr/aws-licenses');

        licenses.aws = response.data.byContractType?.awsmp;
        licenses.awsExpiration = awsLicenses.data.expires;
    }

    return licenses;
}

export async function getPerEnv() {
    logger.info('getting the organization licenses per environment');

    const response = await new APIRequest<LicensesPerEnvDataInterface>({
        path: 'org/licenses?perEnv=true',
    }).request();

    return response.data.perEnv;
}

export async function store(licenses: number) {
    logger.info('saving the number of licenses for the organization');

    const response = await new APIRequest<LicensesDataInterface>({
        path: 'org/licenses',
        method: 'put',
        params: { total: licenses },
    }).request();

    return response.data;
}
