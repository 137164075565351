import allUnits from './units';

type Dimension = 'time' | 'dimensionless' | 'binary' | 'ratio' | 'frequency';

export type Unit = {
    symbol: string;
    name: string;
    dimension: Dimension;
    factor: number;
};

export function fromDimension(dimension: Dimension): Array<Unit> {
    return allUnits.filter(unit => unit.dimension === dimension);
}

export function fromName(name: string): Unit {
    const result = allUnits.find(unit => unit.name === name);

    if (!result) {
        throw new Error(`Unknown unit ${name}`);
    }

    return result;
}
