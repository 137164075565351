/**
 * Use FeatureLink to link to other features in the app.
 * Usage to link to the same feature the rendered component is being used is
 * discouraged.
 */
import useMultiplexer from 'hooks/useMultiplexer';
import { useState, useEffect, FC } from 'react';
import { NavLinkProps, LinkProps } from 'react-router-dom';
import { Link, NavLink } from 'components/url/Link';

const useUrl = (href: string) => {
    const multiplexer = useMultiplexer();
    const [url, setUrl] = useState(multiplexer.handleFeatureUrl(href));

    useEffect(() => {
        setUrl(() => multiplexer.handleFeatureUrl(href));
    }, [href, multiplexer]);

    return url;
};

const FeatureLink: FC<LinkProps & { propagateStatus?: boolean; appendEnv?: boolean }> = ({
    to,
    propagateStatus = true,
    appendEnv = true,
    children,
    ...rest
}) => {
    const url = useUrl(to.toString());

    return (
        <Link
            to={url.toString()}
            propagateStatus={propagateStatus}
            external={!url.local}
            appendEnv={appendEnv}
            {...rest}
        >
            {children}
        </Link>
    );
};

export default FeatureLink;

export const FeatureNavLink: FC<NavLinkProps & { propagateStatus?: boolean }> = ({
    to,
    activeClassName,
    exact = false,
    propagateStatus = true,
    children,
    className,
    style,
    ...rest
}) => {
    const url = useUrl(to.toString());

    if (url.local) {
        return (
            <NavLink
                to={url.toString()}
                activeClassName={activeClassName}
                propagateStatus={propagateStatus}
                exact={exact}
                className={className}
                style={style}
                {...rest}
            >
                {children}
            </NavLink>
        );
    }

    return (
        <Link
            to={url.toString()}
            external={true}
            propagateStatus={propagateStatus}
            appendEnv={false}
            className={typeof className === 'function' ? className(false) : className}
            style={typeof style === 'function' ? style(false) : style}
            {...rest}
        >
            {children}
        </Link>
    );
};
