import { FC, useMemo } from 'react';
import { NodeInterface, EdgeInterface } from 'models/VisualExplainPlan';
import DiagramNodes from './DiagramNodes';
import DiagramEdges from './DiagramEdges';
import {
    DiagramEdgeInput,
    makeTreeLayout,
    useDiagram,
    useDiagramZoom,
    ViewBox,
    useDiagramPan,
    DiagramHoverProvider,
    applyPadding,
} from 'components/diagram';
import Icon from 'components/icons/Icon';

const nodeWidth = 180;
const nodeHeight = 80;
const treeLayout = makeTreeLayout<NodeInterface, EdgeInterface>({
    horizontalSpacing: 100,
    nodeHeight,
    nodeWidth,
    verticalSpacing: 50,
});
const layout = applyPadding(treeLayout, 50);

export interface DiagramInterface {
    nodes: NodeInterface[];
    edges: EdgeInterface[];
    selectedNode: NodeInterface | null;
    onNodeClick: (node: NodeInterface) => void;
}

const Diagram: FC<DiagramInterface> = ({ nodes, edges, selectedNode, onNodeClick }) => {
    const diagramEdges = useMemo<ReadonlyArray<DiagramEdgeInput<NodeInterface, EdgeInterface>>>(() => {
        const nodesById = new Map<number, NodeInterface>(nodes.map(node => [node.id, node]));
        return edges.map<DiagramEdgeInput<NodeInterface, EdgeInterface>>(edge => {
            const source = nodesById.get(edge.source);
            const target = nodesById.get(edge.target);
            if (!source) {
                throw new Error(`Edge source node with ID ${edge.source} not found`);
            } else if (!target) {
                throw new Error(`Edge target node with ID ${edge.target} not found`);
            } else {
                return { edge, source, target };
            }
        });
    }, [edges, nodes]);

    const diagram = useDiagram<NodeInterface, EdgeInterface>({
        edges: diagramEdges,
        layout,
        minViewBox: new ViewBox(0, 0, nodeWidth * 12, nodeHeight * 2),
        nodes,
    });

    useDiagramPan(diagram);

    const { zoomIn, zoomOut, canZoomIn, canZoomOut } = useDiagramZoom(diagram);

    return (
        <div className="full-width vc-viz-diagram" data-testid="vep-diagram">
            <div className="vc-viz-diagram__zoom-controls">
                <button
                    aria-label="Reset"
                    title="Reset"
                    disabled={diagram.viewBox.equals(diagram.initialViewBox)}
                    onClick={() => diagram.reset()}
                >
                    <Icon icon="refresh" />
                </button>
                <button aria-label="Zoom In" title="Zoom In" disabled={!canZoomIn} onClick={() => zoomIn()}>
                    <Icon icon="add" />
                </button>
                <button aria-label="Zoom Out" title="Zoom Out" disabled={!canZoomOut} onClick={() => zoomOut()}>
                    <Icon icon="minus" />
                </button>
            </div>
            <svg data-testid="VEP-Diagram-SVG" height="100%" width="100%" {...diagram.svgProps}>
                <DiagramHoverProvider edges={diagram.edges}>
                    <DiagramEdges edges={diagram.edges} isNodeSelected={Boolean(selectedNode)} />
                    <DiagramNodes nodes={diagram.nodes} selectedNode={selectedNode} onNodeClick={onNodeClick} />
                </DiagramHoverProvider>
                <defs>
                    <marker id="arrow-light" markerHeight="4" markerWidth="2" orient="auto" refX="0.1" refY="2">
                        <path d="M 0 0 V 4 L 2 2 Z" fill="var(--grey05)" />
                    </marker>
                    <marker id="arrow-dark" markerHeight="4" markerWidth="2" orient="auto" refX="0.1" refY="2">
                        <path d="M 0 0 V 4 L 2 2 Z" fill="var(--grey2)" />
                    </marker>
                </defs>
            </svg>
        </div>
    );
};

export default Diagram;
