import Heading from 'components/modules/public/Heading';
import MetaTags from 'components/layout/MetaTags';
import ResetPasswordForm from './Form';
import useUrl from 'hooks/useUrl';

const ResetPassword = () => {
    const homepageUrl = useUrl('homepage');
    const resetEndpoint = useUrl('reset');
    const loginUrl = useUrl('login');

    return (
        <>
            <MetaTags title="Password reset - DPM" />
            <Heading>Reset your account password</Heading>

            <section id="reset" className="card-block shadow mx-auto my4 relative flex bg-white p3 flex-column">
                <ResetPasswordForm loginUrl={loginUrl} homepageUrl={homepageUrl} endpoint={resetEndpoint} />
            </section>
        </>
    );
};

export default ResetPassword;
