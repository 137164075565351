import APIError from './Error';
import { notifyError } from 'services/error/reporter';
import { GenericHttpErrorInterface } from 'services/http';

function notifyHttpErrorIfUnexpected(error: APIError): void {
    if (error.isExpected) {
        return;
    }

    const { status } = error.response;

    notifyError(error, {
        metadata: {
            'http-response': { status },
        },
    });
}

export class RequestErrorHandler {
    static handle(error: GenericHttpErrorInterface) {
        const apiError = new APIError(error, this.expectedErrorCodes);
        notifyHttpErrorIfUnexpected(apiError);

        return apiError;
    }

    static get expectedErrorCodes(): number[] {
        return [];
    }
}
