import SlideSwitchField from 'components/form/fields/SlideSwitchField';
import TextField from 'components/form/fields/TextField';
import CheckboxField from 'components/form/fields/CheckboxField';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import Bouncer from 'components/icons/Bouncer';
import MySQLCredentialModel, { MySQLCredentialDataInterface } from 'models/credentials/MySQLCredential';
import Host, { HostCaptureModes } from 'models/hosts/Host';
import { FC, useEffect, useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { Field as HTML5Field } from 'react-final-form-html5-validation';
import {
    CredentialHostType,
    get as getCredentials,
    getForHost as getCredentialsForHost,
} from 'services/api/credentials';

const MySQLCredentialsFields: FC<{
    host?: Host;
    onLoad?: (credentials: MySQLCredentialModel) => void;
    values?: MySQLCredentialModel;
}> = ({ host, onLoad = () => {}, values }) => {
    const [loading, setLoading] = useState(true);

    const fetchCredentials = async () => {
        const result = host
            ? await getCredentialsForHost<MySQLCredentialDataInterface>(host)
            : await getCredentials<MySQLCredentialDataInterface>(CredentialHostType.mysql);

        setLoading(false);

        if (result === undefined) {
            onLoad(
                new MySQLCredentialModel({
                    sslEnabled: 'false',
                    db: '',
                    user: '',
                    host: '',
                    port: '',
                    pass: '',
                    sslCA: '',
                    sslCert: '',
                    sslKey: '',
                    gcpProjectID: '',
                    gcpResourceID: '',
                })
            );
        } else {
            const credentials = new MySQLCredentialModel(result);
            credentials.isEmpty = false;
            onLoad(credentials);
        }
    };

    useEffect(() => {
        fetchCredentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host]);

    return (
        (!loading && values && (
            <>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Address">
                    <HTML5Field
                        name="address"
                        required
                        placeholder="Usually localhost:3306"
                        helpText="Examples: localhost:3306, 10.0.10.1:3306, [::1]:3306, /tmp/mysql.sock"
                        component={TextField}
                    />
                </FormGroup>

                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="Database">
                    <Field name="database" placeholder="Database to connect to, if required">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <TextField {...props} />}
                    </Field>
                </FormGroup>

                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="User">
                    <Field name="user" placeholder="Connection's username">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <TextField {...props} />}
                    </Field>
                </FormGroup>

                <FormGroup
                    className="mt3 field-inline-labels field-inline-labels--tooltip"
                    label="Password"
                    variant={FormGroupVariantType.HORIZONTAL}
                >
                    <Field name="password" type="password" placeholder="Connection's password">
                        {(props: FieldRenderProps<string, HTMLInputElement>) => (
                            <TextField
                                {...props}
                                type="password"
                                disabled={!values.changePasswordAllowed}
                                tooltip="For security purposes, we do not populate passwords in this field.
                        If you wish to update the password, please check the box and enter a
                        new password in the input."
                            />
                        )}
                    </Field>
                </FormGroup>

                <FormGroup className="mt3" variant={FormGroupVariantType.HORIZONTAL} label=" ">
                    <Field type="checkbox" name="changePasswordAllowed">
                        {(props: FieldRenderProps<string, HTMLInputElement>) => (
                            <CheckboxField
                                {...props}
                                label="I want to change the password"
                                id="changePasswordAllowed"
                                data-testid="password-checkbox"
                            />
                        )}
                    </Field>
                </FormGroup>

                {host?.captureMode === HostCaptureModes.POLL && (
                    <>
                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3"
                            label="Google Cloud Project ID"
                        >
                            <HTML5Field name="gcpProjectID" data-testid="gcpProject" component={TextField}></HTML5Field>
                        </FormGroup>

                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3"
                            label="Google Cloud Database ID"
                        >
                            <HTML5Field
                                name="gcpResourceID"
                                data-testid="gcpResource"
                                required={values?.gcpProjectID}
                                valueMissing="Required when configuring Google Cloud"
                                component={TextField}
                            ></HTML5Field>
                        </FormGroup>
                    </>
                )}

                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="SSL Enabled">
                    <Field name="sslEnabled">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <SlideSwitchField checked={values.sslEnabled} {...props} />
                        )}
                    </Field>
                </FormGroup>

                {values.sslEnabled && (
                    <>
                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3 field-inline-labels field-inline-labels--tooltip"
                            label="SSL Authority"
                        >
                            <Field name="sslCA">
                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                    <TextField
                                        {...props}
                                        tooltip="The location of the root certificate file. The file must contain PEM encoded data."
                                    />
                                )}
                            </Field>
                        </FormGroup>

                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3 field-inline-labels field-inline-labels--tooltip"
                            label="SSL Certificate"
                        >
                            <Field name="sslCert">
                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                    <TextField
                                        {...props}
                                        tooltip="Cert file location. The file must contain PEM encoded data."
                                    />
                                )}
                            </Field>
                        </FormGroup>

                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3 field-inline-labels field-inline-labels--tooltip"
                            label="SSL Key"
                        >
                            <Field name="sslKey">
                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                    <TextField
                                        {...props}
                                        tooltip="Key file location. The file must contain PEM encoded data."
                                    />
                                )}
                            </Field>
                        </FormGroup>
                    </>
                )}
            </>
        )) || <Bouncer className="my4 flex full-width justify-center" />
    );
};

export default MySQLCredentialsFields;
