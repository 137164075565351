import APIRequest from './Request';
import Logger from 'services/logger';
import { HostCredential } from 'models/credentials/Credential';
import { RequestErrorHandler } from 'services/api/error/RequestErrorHandler';
import Host from 'models/hosts/Host';
const logger = Logger.get('credentials');

class CredentialsErrorHandler extends RequestErrorHandler {
    static get expectedErrorCodes(): number[] {
        return [404];
    }
}

export enum CredentialHostType {
    mongo = 'mongo',
    mysql = 'mysql',
    pgsql = 'pgsql',
    redis = 'redis',
    mssql = 'mssql',
}

export async function get<CredentialDataType>(CredentialsType: CredentialHostType) {
    logger.info('getting the credentials data');

    try {
        const response = await new APIRequest<CredentialDataType>({
            path: `hosts/0/credentials?type=${CredentialsType}`,
            errorHandler: CredentialsErrorHandler,
        }).request();

        return response.data;
    } catch {
        return undefined;
    }
}

export async function getForHost<CredentialDataType>(host: Host) {
    logger.info(`getting the credentials data for host ${host.id}`);

    try {
        const response = await new APIRequest<CredentialDataType>({
            path: `hosts/${host.id}/credentials`,
            errorHandler: CredentialsErrorHandler,
        }).request();

        return JSON.stringify(response.data) !== '{}' ? response.data : undefined;
    } catch {
        return undefined;
    }
}

export async function update<CredentialDataType>(model: HostCredential, host?: Host) {
    logger.info('updating the credentials data');
    const payload = model.asPayloadObject();

    //if no host is provided, we store general env credentials with ID = 0
    const hostID = host ? host.id : 0;

    if (host && host.type !== model.type) {
        throw new Error(`[apiCredentials] host type mismatch (host is ${host.type} and credentials ${model.type})`);
    }

    const response = await new APIRequest<CredentialDataType>({
        method: 'put',
        path: `hosts/${hostID}/credentials?type=${model.type}`,
        params: payload,
    }).request();

    return response.data;
}

export async function remove(host: Host) {
    logger.info(`deleting the credentials data for host ${host.id}`);

    return new APIRequest<null>({
        method: 'delete',
        path: `hosts/${host.id}/credentials`,
    }).request();
}
