import User from 'models/User';
import Bugsnag, { Event, Client } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import sanitizeReport from 'services/error/sanitizeReport';
import Logger from 'services/logger';
import { ContextualError } from './error';
import * as React from 'react';

let client: Client;

class ReporterError extends ContextualError {
    constructor(message: string) {
        super('ReporterError', message);
    }
}

export const init = () => {
    client = Bugsnag.start({
        apiKey: 'd5a93f623e235e827f011dc1dc1f8143',
        releaseStage: '__STAGE__',
        enabledReleaseStages: ['stage', 'prod', 'raven'],
        appVersion: process.env.REACT_APP_VERSION,
        onError: sanitizeReport,
        plugins: [new BugsnagPluginReact()],
        redactedKeys: ['Authorization', 'X-XSRF-TOKEN', 'tokens', /password$/i],
    });
};

const getClient = (): Client => {
    if (!client) {
        init();
    }

    return client;
};

export const addUser = (user: User): void => {
    getClient().setUser(user.id.toString(), user.email, user.name);
};

export const createErrorBoundary = (react: typeof React) => {
    const reactPlugin = getClient().getPlugin('react');
    if (!reactPlugin) {
        throw new ReporterError('Unable to load Bugsnag React Plugin');
    }

    return reactPlugin.createErrorBoundary(react);
};

type ErrorMetadata = { [s: string]: { [s: string]: unknown } };

export interface ErrorSettingsInterface {
    severity?: 'info' | 'warning' | 'error';
    metadata?: ErrorMetadata;
    context?: string;
}

export const notifyError = (error: Error, settings: ErrorSettingsInterface = {}): void => {
    getClient().notify(
        error,
        (event: Event) => {
            if (settings.severity) {
                event.severity = settings.severity;
            }

            if (settings.context) {
                event.context = settings.context;
            }

            Object.entries(settings.metadata || {}).forEach(([key, value]) => {
                event.addMetadata(key, value);
            });

            if (error instanceof ContextualError) {
                Object.entries(error.context || {}).forEach(([key, value]) => {
                    event.addMetadata(key, value);
                });
            }
        },
        err => {
            if (err) {
                Logger.get('BugsnagReporter').error(error);
            }
        }
    );
};
