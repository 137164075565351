import { TokenInterface } from 'services/token/handler';
import { decode as decodeToken } from 'services/token/handler';
import Logger from 'services/logger';

const logger = Logger.get('TokenStorage');

type TokensByEnvID = {
    [key in number]: TokenInterface;
};

export interface TokenStorageInterface {
    readonly orgToken?: TokenInterface;
    readonly envToken?: TokenInterface;
    readonly tokensByEnvID?: TokensByEnvID;
}

export class TokenStorage implements TokenStorageInterface {
    private org?: TokenInterface;
    private env?: TokenInterface;
    private envsTokens: TokensByEnvID = {};

    refreshFromObject({ org, env }: { org?: string; env?: string }) {
        try {
            this.orgToken = org ? decodeToken(org) : undefined;
            this.envToken = env ? decodeToken(env) : undefined;
        } catch (e) {
            logger.error(e);
        }
    }

    get orgToken(): TokenInterface | undefined {
        return this.org;
    }

    set orgToken(token: TokenInterface | undefined) {
        this.org = token;
    }

    get envToken(): TokenInterface | undefined {
        return this.env;
    }

    set envToken(token: TokenInterface | undefined) {
        this.env = token;
    }

    getTokenForEnv(envId: number): TokenInterface | undefined {
        return this.envsTokens[envId];
    }

    setTokenForEnv(envId: number, token: TokenInterface) {
        this.envsTokens[envId] = token;
    }
}

const storage = new TokenStorage();

export default storage;
