import { useState, useEffect } from 'react';
import { Link } from 'components/url/Link';
import AlertsTable from 'components/modules/app/Alerts/Table';
import { getDestinations, APIDestinationInterface } from 'services/api/envConfig';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';
import { userIsAllowedTo } from 'services/security/accessControl';
import useDisplayMessage from 'hooks/useDisplayMessage';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import ModuleTitle from '../ModuleTitle';
import FeatureLink from 'components/url/FeatureLink';

const Alerts = () => {
    const [destinations, setDestinations] = useState<APIDestinationInterface[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const button = (
        <div>
            <Link to="settings/integrations/new">
                <button className={(destinations.length > 0 ? 'primary-grey' : 'primary-orange') + ' mr2'}>
                    Setup Integration
                </button>
            </Link>
            {destinations.length > 0 && (
                <FeatureLink to="alerts/new">
                    <button className="primary-orange">Create New Alert</button>
                </FeatureLink>
            )}
        </div>
    );

    const fetchDestinations = async () => {
        try {
            setDestinations(await getDestinations());
        } catch {
            error('There was an error fetching integration information.');
        } finally {
            setIsLoading(false);
        }
    };

    const { error } = useDisplayMessage();

    useEffect(() => {
        fetchDestinations();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="view alerts">
            <FinalActionModalProvider>
                <ModuleTitle headerContent={userIsAllowedTo('changeEnvSettings') && button} contextualHelpId="alerts">
                    Alerts
                </ModuleTitle>
                {!isLoading && destinations.length === 0 && (
                    <EmptyState type={EmptyTypes.INFO}>
                        There are no integrations created for this environment.
                    </EmptyState>
                )}
                {!isLoading && destinations.length > 0 && <AlertsTable destinations={destinations} />}
            </FinalActionModalProvider>
        </div>
    );
};

export default Alerts;
