import { FC, useEffect } from 'react';
import useRanks from 'hooks/profiler/useRanks';
import useUIStatus from 'hooks/useUIStatus';
import { Profiler, ProfilerInterface } from 'models/profiler';
import { RankInterface } from 'models/profiler/ranks';

const Form: FC<{ onFilter: (profiler: ProfilerInterface) => void }> = ({ onFilter }) => {
    const ranks = useRanks();
    const [uiStatus] = useUIStatus(
        {
            rank: 'clients',
            by: 'throughput',
            cols: ['rank', 'throughput', 'warnings', 'errors'],
        },
        { id: 'profiler' }
    );

    useEffect(() => {
        // This code is temporary, and must replace accordingly by the build of
        // a Profiler instance based on the configuration form of the profiler
        // page.

        const rankDefinition = ranks.find(def => uiStatus.rank === def.id);
        if (!rankDefinition) {
            return;
        }

        const rankColumns = rankDefinition.columns.filter(column => uiStatus.cols.includes(column.id));
        const rank: RankInterface = { ...rankDefinition };

        onFilter(new Profiler(rank, uiStatus.by, rankColumns));
    }, [ranks, uiStatus.rank, uiStatus.by, uiStatus.cols, onFilter]);

    return null;
};

export default Form;
