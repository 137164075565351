import { SyntheticEvent, useState, useEffect } from 'react';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import FormActions from 'components/form/Actions';
import Feature from 'components/serviceLevel/Feature';
import { isEnabled } from 'services/serviceLevel/featureFlag';
import ContactLink from 'components/messages/ContactLink';
import Alert, { AlertTypes } from 'components/messages/Alert';
import { getVersion as getAgentVersionConfig, updateWindows } from 'services/api/agentConfig';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { AgentWindows, agentDaysOfWeek, agentTimes } from 'models/AgentWindow';
import BouncerButton from 'components/form/BouncerButton';
import Bouncer from 'components/icons/Bouncer';

const AgentMaintenance = () => {
    const [values, setValues] = useState<AgentWindows>(new AgentWindows([]));
    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [touched, setTouched] = useState(false);

    const { success, error } = useDisplayMessage();

    const changeValues = (newValues: AgentWindows) => {
        setValues(newValues);
        if (newValues.updateOnDays.some(day => day) && newValues.updateOnTimes.some(time => time)) {
            setTouched(true);
        } else {
            setTouched(false);
        }
    };

    const handleDaysChange = (event: SyntheticEvent<HTMLInputElement>) => {
        values.updateOnDays[+event.currentTarget.value] = event.currentTarget.checked;
        const newValues = values.clone();
        changeValues(newValues);
    };

    const handleTimesChange = (event: SyntheticEvent<HTMLInputElement>) => {
        values.updateOnTimes[+event.currentTarget.value] = event.currentTarget.checked;
        const newValues = values.clone();
        changeValues(newValues);
    };

    const getInitialData = async () => {
        try {
            setIsLoading(true);
            const agentVersion = await getAgentVersionConfig();
            setValues(agentVersion.updatewindows);
        } catch {
            error('There was an error retrieving the agents maintenance window information');
        } finally {
            setIsLoading(false);
        }
    };

    const saveChanges = async () => {
        setSubmitting(true);
        try {
            await updateWindows(values);
            success('Agents maintenance window successfully updated');
            setTouched(false);
        } catch {
            error('There was an error trying to save the agents maintenance window');
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (!isEnabled('agentsUpdate')) {
            return;
        }
        getInitialData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="border-top border-color-grey1 pt4 mb3">
            <h1 className="h2 mr2 lighter grey3 flex-grow-1">Agents Maintenance Window</h1>
            <Feature name="agentsUpdate">
                <p className="h4 lighter mt3">
                    Specify when the Database Performance Monitor agents should be updated in your environment.
                </p>
                {!isLoading && (
                    <div>
                        <form className="settings__form settings__form--preferences">
                            <FormGroup
                                className="mt3"
                                variant={FormGroupVariantType.HORIZONTAL}
                                label="Days of the week:"
                            >
                                <ul className="day-list list-reset flex flex-wrap mt2">
                                    {agentDaysOfWeek.map((day, index) => (
                                        <li className="day-list__day" key={index}>
                                            <label data-testid={day}>
                                                <input
                                                    type="checkbox"
                                                    id={day}
                                                    name="weekday"
                                                    value={index}
                                                    checked={values.updateOnDays[index]}
                                                    onChange={handleDaysChange}
                                                />
                                                <span>{day}</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </FormGroup>

                            <FormGroup className="mt3" variant={FormGroupVariantType.HORIZONTAL} label="Time of day:">
                                <ul className="period list-reset grid horizontal-layout mt2">
                                    {agentTimes.map((time, index) => (
                                        <li className="period__item" key={index}>
                                            <label data-testid={time.id}>
                                                <input
                                                    type="checkbox"
                                                    id={time.id}
                                                    name="time"
                                                    value={index}
                                                    checked={values.updateOnTimes[index]}
                                                    onChange={handleTimesChange}
                                                />
                                                <span>
                                                    {time.startFrame} - {time.endFrame}
                                                </span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </FormGroup>
                            <FormActions className="settings__form__actions settings__form__actions--multi-line mt3 flex justify-end items-center">
                                <p className="mt2 grey3 line-height-120" data-testid="agents-window-summary">
                                    {values.calculateSummaryComment()}
                                </p>
                                <BouncerButton
                                    className="primary-orange mt2 ml3"
                                    bounce={submitting}
                                    type="button"
                                    bouncerClassName="white inline-flex ml2"
                                    onClick={saveChanges}
                                    disabled={!touched}
                                >
                                    Update
                                </BouncerButton>
                            </FormActions>
                        </form>
                    </div>
                )}
                {isLoading && <Bouncer />}
            </Feature>
            {!isEnabled('agentsUpdate') && (
                <div className="mt3" data-testid="feature-not-enabled">
                    <Alert sticky type={AlertTypes.WARNING}>
                        This feature is not available in your current plan; <ContactLink>Contact us</ContactLink> to
                        enable it.
                    </Alert>
                </div>
            )}
        </div>
    );
};
export default AgentMaintenance;
