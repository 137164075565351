import { FC, FormEvent, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getDestination, getIntegrations, saveDestination, APIDestinationInterface } from 'services/api/envConfig';
import Integration from 'models/Integration';
import SettingsLayout from '../Layout';
import Bouncer from 'components/icons/Bouncer';
import BouncerButton from 'components/form/BouncerButton';
import Alert, { AlertTypes } from 'components/messages/Alert';
import { Form, Field } from 'react-final-form';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { FieldRenderProps } from 'react-final-form';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import TextField from 'components/form/fields/TextField';
import FormActions from 'components/form/Actions';
import { UserHasPermission, UserHasNoPermission } from 'components/security/UserHasPermission';
import { envPath } from 'services/routes';
import { Link } from 'components/url/Link';
import IntegrationIcon from 'components/modules/settings/Integrations/IntegrationIcon';

const SettingsIntegrationEdit: FC = () => {
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [selectedIntegration, setSelectedIntegrations] = useState<Integration>();
    const [destination, setDestination] = useState<APIDestinationInterface>();
    const [state, setState] = useState({
        isLoading: false,
        isSaving: false,
    });

    const history = useHistory();
    const { success, error } = useDisplayMessage();
    const params = useParams<{ id: string }>();

    const goBackToList = () => {
        /**
         * @todo The current environment is hardcoded, but will need to be taken from bootstrap
         * data.
         */
        history.push(envPath('settings/integrations'));
    };

    const onSubmit = async (values: { name: string; integration: string; [key: string]: string }) => {
        setState({ ...state, isSaving: true });
        const { name, integration, ...others } = values;
        const config: { [key: string]: string[] } = {};

        if (undefined === selectedIntegration) {
            return;
        }

        const configKeysForIntegration = selectedIntegration.config.map(conf => conf.key);

        for (const key in others) {
            // If the user filled out the form for multiple integrations, React-final-form will
            // have saved values for those integrations. We want to only send values that are part
            // of the selected integration.
            if (configKeysForIntegration.includes(key)) {
                config[key] = [others[key]];
            }
        }

        try {
            await saveDestination(name, parseInt(integration), config, destination);

            setState({ ...state, isSaving: false });
            success('Integration has been saved.');
            goBackToList();
        } catch (e) {
            setState({ ...state, isSaving: false });
            error('There was an error saving this integration.');
        }
    };

    const fetchDestination = async () => {
        // If we're creating a new destination, this parameter will be undefined
        if (undefined === params.id) {
            return;
        }

        setState({ ...state, isLoading: true });

        try {
            const response = await getDestination(params.id);
            setDestination(response);
        } catch (e) {
            error('There was an error fetching this integration.');
        } finally {
            setState({ ...state, isLoading: false });
        }
    };

    const fetchIntegrations = async () => {
        setState({ ...state, isLoading: true });

        try {
            setIntegrations(await getIntegrations());
        } catch (e) {
            error('There was an error fetching integrations.');
        } finally {
            setState({ ...state, isLoading: false });
        }
    };

    const fetch = async () => {
        // Ensure integrations are fetched first, because we require a list of integrations
        // to correctly populate the form for the selected destination.
        await fetchIntegrations();
        await fetchDestination();
    };

    const handleIntegrationSelection = (e: FormEvent<HTMLInputElement>) => {
        const integration = integrations.find(({ id }) => id.toString() === e.currentTarget.id);
        setSelectedIntegrations(integration);
    };

    useEffect(() => {
        fetch();
    }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (undefined === destination) {
            return;
        }

        const integration = integrations.find(i => i.id === destination.integration);
        setSelectedIntegrations(integration);
    }, [destination]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SettingsLayout title="Integrations">
            <div>
                <p className="h4 lighter mt4 mb3" data-testid="integrations-intro-text">
                    Database Performance Monitor integrates with your favorite applications to help you and your team be
                    more awesome.
                </p>
            </div>
            {state.isLoading && <Bouncer />}
            <UserHasNoPermission to="changeEnvSettings">
                <Alert type={AlertTypes.ERROR} sticky className="my2" data-testid="integration-permission-alert">
                    You do not have permission to modify this integration.
                </Alert>
            </UserHasNoPermission>
            <UserHasPermission to="changeEnvSettings">
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        name: destination?.name || '',
                        integration: destination?.integration.toString() || '',
                    }}
                    render={({ handleSubmit }) => (
                        <form
                            onSubmit={handleSubmit}
                            data-testid="integration-edit-form"
                            className="settings__form--integrations"
                        >
                            <fieldset className="mb3">
                                <label className="settings__form__label uppercase fz10 spacing inline-flex mb1 items-center">
                                    <b>{destination ? 'Selected Integration:' : 'Integrate with an application:'}</b>
                                </label>
                                {destination && (
                                    <div className="inline-flex flex-row flex-wrap items-center relative top-2">
                                        <div className="integration__icon relative mr1">
                                            <IntegrationIcon name={selectedIntegration?.name || ''} />
                                        </div>
                                        <label
                                            htmlFor="{{ ctrl.selectedIntegration.name }}"
                                            data-testid="destination-name"
                                        >
                                            {destination.name}
                                        </label>
                                    </div>
                                )}
                                {!destination && (
                                    <div className="inline-flex flex-row flex-wrap settings__form--integrations__integration">
                                        {integrations.map(integration => (
                                            <div
                                                className="flex items-center flex-third my3"
                                                data-testid="adaptor"
                                                key={integration.id}
                                            >
                                                <label className="cursor-hand flex">
                                                    <Field
                                                        name="integration"
                                                        value={integration.id}
                                                        id={integration.id}
                                                        component="input"
                                                        type="radio"
                                                        checked={selectedIntegration?.id === integration.id}
                                                        onClick={handleIntegrationSelection}
                                                        data-testid="adaptor-radio-button"
                                                    />
                                                    <span className="flex">
                                                        <IntegrationIcon
                                                            className="absolute integration__icon__img"
                                                            name={integration.name}
                                                            withName
                                                        />
                                                    </span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </fieldset>

                            {selectedIntegration && (
                                <div className="integrations__form__fields">
                                    {!destination && (
                                        <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Name Integration:">
                                            <Field
                                                name="name"
                                                type="text"
                                                required
                                                placeholder="Enter a name to describe this integration"
                                            >
                                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                    <TextField {...props} />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    )}
                                    {selectedIntegration.config.map((config, i) => (
                                        <FormGroup
                                            variant={FormGroupVariantType.HORIZONTAL}
                                            className="mt3"
                                            label={config.label + ':'}
                                            key={i}
                                        >
                                            <Field
                                                name={config.key}
                                                required={config.key !== 'secret_token'}
                                                initialValue={
                                                    destination?.config[config.key] &&
                                                    destination?.config[config.key][0]
                                                }
                                            >
                                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                    <TextField {...props} helpText={config.hint} />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    ))}
                                </div>
                            )}

                            <FormActions className="mt3">
                                {selectedIntegration && (
                                    <BouncerButton
                                        disabled={state.isSaving}
                                        className="primary-orange nowrap"
                                        bounce={state.isSaving}
                                        data-testid="save-destination-button"
                                    >
                                        {destination ? 'Update' : 'Create'} Integration
                                    </BouncerButton>
                                )}
                                <Link to="settings/integrations">
                                    <button type="button" className="primary-grey">
                                        Cancel
                                    </button>
                                </Link>
                            </FormActions>
                        </form>
                    )}
                />
            </UserHasPermission>
        </SettingsLayout>
    );
};
export default SettingsIntegrationEdit;
