import BouncerButton from 'components/form/BouncerButton';
import Group, { FormGroupVariantType } from 'components/form/Group';
import Actions from 'components/form/Actions';
import Input from 'components/form/fields/TextField';
import TagsInput from 'components/form/fields/TagsInput';
import { FC } from 'react';
import { update } from 'services/api/hosts';
import { Form, FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { Field as RFField } from 'react-final-form';
import Host from 'models/hosts/Host';
import useDisplayMessage from 'hooks/useDisplayMessage';

interface EditHostPropsInterface {
    onCancel: (host: Host) => void;
    onSuccess: (host: Host) => void;
    onError: (host: Host) => void;
    host: Host;
}

const EditHost: FC<EditHostPropsInterface> = ({ onCancel, onSuccess, onError, host }) => {
    const { error } = useDisplayMessage();

    function assignHostAttrs(name: string, desc: string, tags: string) {
        host.name = name;
        host.description = desc;
        host.tags = tags !== undefined && tags !== '' ? tags.split(',').map(tag => tag.trim()) : [];
    }

    async function handleSubmit({ name, description, tags }: { name: string; description: string; tags: string }) {
        const originalName = host.name;
        const originalDesc = host.description;
        const originalTags = host.tags.join();

        try {
            assignHostAttrs(name, description, tags);

            await update(host);

            onSuccess(host);
        } catch (e) {
            assignHostAttrs(originalName, originalDesc, originalTags);

            onError(host);
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={{ name: host.name, description: host.description, tags: host.tags.join() }}
            render={({ pristine, invalid, handleSubmit: onSubmit, submitting }) => (
                <form onSubmit={onSubmit} data-testid="host-edit-form" className="dialog__host-edit-form pt2">
                    <Group label="Host name:" className="px3 pt2" variant={FormGroupVariantType.HORIZONTAL}>
                        <Field
                            type="text"
                            name="name"
                            required
                            maxLength="45"
                            autoFocus
                            badInput="Please enter a different name"
                            tooLong="The name is too long"
                            valueMissing="The name is required"
                        >
                            {(props: FieldRenderProps<string, HTMLInputElement>) => (
                                <Input className="full-width bg-white" {...props} data-testid="host-name-input" />
                            )}
                        </Field>
                    </Group>

                    <Group label="Description:" className="px3 pt2" variant={FormGroupVariantType.HORIZONTAL}>
                        <Field type="text" name="description">
                            {(props: FieldRenderProps<string, HTMLInputElement>) => (
                                <Input
                                    className="full-width bg-white"
                                    {...props}
                                    data-testid="host-description-input"
                                />
                            )}
                        </Field>
                    </Group>

                    <Group label="Tags:" className="px3 pt2" variant={FormGroupVariantType.HORIZONTAL}>
                        <RFField type="text" name="tags">
                            {(props: FieldRenderProps<string, HTMLInputElement>) => (
                                <TagsInput
                                    className="full-width bg-white"
                                    {...props}
                                    data-testid="host-tags-input"
                                    onError={() => error('Duplicated tag')}
                                />
                            )}
                        </RFField>
                    </Group>

                    <Actions className="form__actions--dialog p3 bg-white right-align full-width mt3">
                        <button type="button" className="primary-grey" onClick={() => onCancel(host)}>
                            Cancel
                        </button>
                        <BouncerButton
                            data-testid="save-host-button"
                            bounce={submitting}
                            disabled={pristine || invalid || submitting}
                            type="submit"
                            className="primary-mainColor nowrap createEnvForm__submit"
                        >
                            Save
                        </BouncerButton>
                    </Actions>
                </form>
            )}
        />
    );
};

export default EditHost;
