import { FC } from 'react';
import { Link } from 'components/url/Link';

const InventoryButtons: FC = () => {
    return (
        <>
            {/**This URL is not confirmed yet. For now, we know there will be a new page for the setup flow instead of it being a modal window */}
            <Link to="setup">
                <button className="primary-mainColor mr2">Add new host</button>
            </Link>
            <Link to="/settings/licenses" appendEnv={false}>
                <button className="primary-mainColor">Add licenses</button>
            </Link>
        </>
    );
};
export default InventoryButtons;
