import AuthMethod, { AuthMethodInterface } from './AuthMethod';
import { BootstrapDataInterface } from './Bootstrap';

export interface CaptchaConfigurationType {
    key: string;
    requiredLogin: boolean;
}

class Authentication {
    static BASIC_METHOD = 'basic';
    static SAML_METHOD = 'saml';
    static OAUTH_METHOD = 'oauth';
    static GOOGLE_PROVIDER = 'google';
    static GITHUB_PROVIDER = 'github';

    private methods: AuthMethod[];
    private supportedMethods: string[];
    private captchaConfig: CaptchaConfigurationType;
    readonly errorCode: string;

    constructor(
        methods: AuthMethod[],
        supportedMethods: string[],
        captchaConfig: CaptchaConfigurationType,
        errorCode = ''
    ) {
        this.methods = methods;
        this.supportedMethods = supportedMethods;
        this.captchaConfig = captchaConfig;
        this.errorCode = errorCode;
    }

    static fromBootstrapData(data: BootstrapDataInterface) {
        let methods: AuthMethod[] = [];
        let supportedMethods: string[] = [];

        if (data.auth) {
            methods = data.auth.methods.map((method: AuthMethodInterface) => AuthMethod.fromObject(method));
            supportedMethods = data.auth.supportedMethods;
        }

        return new Authentication(methods, supportedMethods, data.thirdParty.captcha, data.errors?.auth);
    }

    get samlProvider(): string | null {
        for (const authMethod of this.methods) {
            if (authMethod.method === Authentication.SAML_METHOD) {
                return authMethod.provider || null;
            }
        }

        return null;
    }

    get captchaKey() {
        return this.captchaConfig.key;
    }

    isMethodSupported(method: string, provider?: string): boolean {
        for (const authMethod of this.methods) {
            if (authMethod.method === method && (!provider || authMethod.provider === provider)) {
                return true;
            }
        }

        return false;
    }

    requiredCaptchaForLogin() {
        return this.captchaConfig.requiredLogin;
    }

    supportsMultipleMethods() {
        return this.supportedMethods.length > 1;
    }
}

export default Authentication;
