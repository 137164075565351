/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import loglevel from 'loglevel';

export interface LoggerInterface {
    debug(...args: any[]): void;
    info(...args: any[]): void;
    warn(...args: any[]): void;
    error(...args: any[]): void;
    trace(...args: any[]): void;
}

export type LogLevel = loglevel.LogLevelDesc;

export class ConsoleLogger implements LoggerInterface {
    private loglevelLogger?: loglevel.Logger;

    constructor(private name: string, private level: LogLevel) {
        this.debug = this.debug.bind(this);
        this.info = this.info.bind(this);
        this.warn = this.warn.bind(this);
        this.error = this.error.bind(this);
        this.trace = this.trace.bind(this);
    }

    debug(...args: any[]) {
        this.logger.debug(`[${this.name}]`, ...args);
    }

    info(...args: any[]) {
        this.logger.info(`[${this.name}]`, ...args);
    }

    warn(...args: any[]) {
        this.logger.warn(`[${this.name}]`, ...args);
    }

    error(...args: any[]) {
        this.logger.error(`[${this.name}]`, ...args);
    }

    trace(...args: any[]) {
        this.logger.trace(`[${this.name}]`, ...args);
    }

    private get logger() {
        if (!this.loglevelLogger) {
            this.loglevelLogger = loglevel.getLogger(this.name);
            this.loglevelLogger.setLevel(this.level);
        }

        return this.loglevelLogger;
    }
}

export class NullLogger implements LoggerInterface {
    constructor(readonly name: string) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    debug(..._args: any[]) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    info(..._args: any[]) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    warn(..._args: any[]) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error(..._args: any[]) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    trace(..._args: any[]) {}
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new (class {
    private consoleDisabled = false;
    private loggers: { [s: string]: LoggerInterface } = {};

    disableConsole() {
        if (!this.consoleDisabled) {
            this.loggers = {};
        }

        this.consoleDisabled = true;
    }

    get(name: string, level: LogLevel = 'debug'): LoggerInterface {
        if (!name) {
            throw new Error('Logger name cannot be empty');
        }

        if (this.loggers[name]) {
            return this.loggers[name];
        }

        return (this.loggers[name] = this.create(name, level));
    }

    reset() {
        this.consoleDisabled = false;
    }

    private create(name: string, level: LogLevel) {
        if (this.consoleDisabled) {
            return new NullLogger(name);
        }

        return new ConsoleLogger(name, level);
    }
})();
