import { RankDefinitionInterface, RankInterface } from 'models/profiler/ranks';
import { ParameterizableIntervalInterface } from '../../../models/TimeInterval';
import APIRequest from '../Request';
import { fetchColumnDefinitions } from './columns';

export function fetchRankDefinitions(
    interval: ParameterizableIntervalInterface,
    hostFilter: string
): Promise<RankDefinitionInterface[]> {
    return new APIRequest<RankDefinitionInterface[]>({
        method: 'get',
        path: 'features/ranks',
        params: {
            ...interval.asParams(),
            host: hostFilter,
        },
    })
        .request()
        .then(({ data }) => data);
}

export async function fetchRanks(
    interval: ParameterizableIntervalInterface,
    hostFilter: string
): Promise<RankInterface[]> {
    const [columnDefinitions, rankDefinitions] = await Promise.all([
        fetchColumnDefinitions(interval, hostFilter),
        fetchRankDefinitions(interval, hostFilter),
    ]);

    return rankDefinitions.reduce((accumulator, rankDefinition) => {
        const columns = columnDefinitions.filter(column => rankDefinition.columns.includes(column.id));
        const rank: RankInterface = { ...rankDefinition, columns };
        accumulator.push(rank);

        return accumulator;
    }, [] as RankInterface[]);
}
