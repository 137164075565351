import { FC, useState, SyntheticEvent } from 'react';
import { Link } from 'components/url/Link';
import BouncerButton from 'components/form/BouncerButton';
import Select from 'components/form/Select';
import Role from 'models/Role';
import Team from 'models/Team';
import Env from 'models/Env';
import { addEnvToTeam } from 'services/api/teams';
import useDisplayMessage from 'hooks/useDisplayMessage';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';

interface AssignTeamInterface {
    teams: Team[];
    roles: Role[];
    env: Env;
    onCancel: () => void;
    onSuccess?: (team: Team) => void;
}

const AssignTeam: FC<AssignTeamInterface> = ({ teams, roles, env, onCancel, onSuccess }) => {
    const filteredRoles = roles.filter(role => role.assignable.toTeams);
    const [team, setTeam] = useState<Team | undefined>(teams.length > 0 ? teams[0] : undefined);
    const [role, setRole] = useState<Role | undefined>(filteredRoles.length > 0 ? filteredRoles[0] : undefined);
    const [isSaving, setIsSaving] = useState(false);

    const roleOptions = filteredRoles.map(({ name, id: value }) => ({ name, value }));
    const teamOptions = teams.map(({ name, id: value }) => ({ name, value }));

    const { error } = useDisplayMessage();

    const handleRoleChange = (e: SyntheticEvent<HTMLSelectElement>) => {
        const newRole = roles.find(({ id }) => id === parseInt(e.currentTarget.value, 10));

        if (newRole) {
            setRole(newRole);
        }
    };

    const handleTeamChange = (e: SyntheticEvent<HTMLSelectElement>) => {
        const newTeam = teams.find(t => t.id === parseInt(e.currentTarget.value, 10));

        if (newTeam) {
            setTeam(newTeam);
        }
    };

    const handleSubmit = async () => {
        if (!team || !role) {
            error('You need to select a team and a role.');
            return;
        }

        setIsSaving(true);

        try {
            await addEnvToTeam(team, env, role);
            setIsSaving(false);

            if (onSuccess) {
                const newTeam = { ...team };

                newTeam.envs = newTeam.envs.filter(envTeam => envTeam.id !== env.id);
                newTeam.envs.push({ id: env.id, role: role });

                onSuccess(new Team(newTeam));
            }
        } catch {
            error('An error occurred when assigning this team to the environment.');
            setIsSaving(false);
        }
    };

    const canSubmit = team && role;

    return (
        <>
            <form className="settings__form settings__form--create-teams p3 bg-white" name="teamForm">
                <p className="mb3 line-height-120">
                    Assign teams from your organization to this environment with specific roles. You can create new
                    teams from the{' '}
                    <Link to="/settings/teams" appendEnv={false}>
                        Organization Page
                    </Link>
                </p>

                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mb3" label="Select A Team:">
                    <Select
                        options={teamOptions}
                        value={team ? team.id : ''}
                        onChange={handleTeamChange}
                        data-testid="assign-team-select"
                    />
                </FormGroup>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Select a role:">
                    <Select
                        options={roleOptions}
                        value={role ? role.id : ''}
                        onChange={handleRoleChange}
                        data-testid="new-role-select"
                    />
                </FormGroup>
            </form>
            <div className="flex justify-end p3 bg-grey05">
                <button type="button" className="primary-grey mr2" onClick={() => onCancel()}>
                    Cancel
                </button>
                <BouncerButton
                    bounce={isSaving}
                    onClick={handleSubmit}
                    disabled={isSaving || !canSubmit}
                    className="primary-orange"
                    data-testid="save-new-team"
                >
                    Create
                </BouncerButton>
            </div>
        </>
    );
};

export default AssignTeam;
