import { SyntheticEvent } from 'react';
import Icon from 'components/icons/Icon';

export interface TagPropsInterface {
    tag: string;
    hasButton?: boolean;
    onClick?(e?: SyntheticEvent): void;
}

const Tag: React.FC<TagPropsInterface> = ({ tag, hasButton, onClick }) => (
    <li className="edit-tags__tag border border-color-MainColor rounded p1 inline-flex items-center m1 truncate">
        <div className="fz12 monospace dark truncate edit-tags__list__tag__name">{tag}</div>
        {hasButton && <Icon icon="close" className="mainColor" onClick={onClick} />}
    </li>
);

export default Tag;
