import { Unit } from './Unit';
import { formatQuantity } from 'helpers/numbers';

/**
 * A Quantity represents a number and unit couple
 *
 * Basic Usage:
 *
 * q = new Quantity(1024, kilobyte);
 * q.toString()      // 1024 KB
 * q.unit            // KB
 * q.value;          // 1024
 */
export default class Quantity {
    readonly value: number;
    readonly unit: Unit;

    constructor(value: number, unit: Unit) {
        this.value = value;
        this.unit = unit;
    }

    toString() {
        const { value, symbol } = formatQuantity(this);

        return `${value} ${symbol}`.trim();
    }
}
