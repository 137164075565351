import * as React from 'react';
import Loader, { LoaderTypes } from 'components/loaders/Loader';

const GroupsLoader: React.FC<{ cols: number }> = ({ cols }) => {
    const getRows = () =>
        [1, 2, 3].map(index => (
            <tr data-testid="loader-row" key={`row_${index}`}>
                {[...Array(cols)].map((_, i) => (
                    <td key={i} colSpan={i === 1 ? 3 : i === 2 ? 2 : 1}>
                        <Loader className="loader--thick" type={LoaderTypes.TABLECELL}></Loader>
                    </td>
                ))}
            </tr>
        ));
    return <>{getRows()}</>;
};

export default GroupsLoader;
