import { useState, useEffect, FunctionComponent } from 'react';

export interface SlideSwitchEventInterface {
    target: {
        name?: string;
        checked: boolean;
        value?: boolean;
    };
}

interface SwitchProps {
    /**
     * Bind to false to disallow changing the switch value
     */
    disabled?: boolean;
    /**
     * Function to call on a switch event. It get's a 'checked' parameter which is the new status of the switch input
     */
    onChange: (e: SlideSwitchEventInterface) => void;
    /**
     * Status of switch.
     */
    checked?: boolean;
    /**
     * Optional string to identify the switch
     */
    name?: string;
}

const SlideSwitch: FunctionComponent<SwitchProps> = ({
    disabled = false,
    onChange,
    checked = false,
    name,
    ...rest
}) => {
    const [isOn, setIsOn] = useState<boolean>(checked);
    const [isDisabled] = useState<boolean>(disabled);

    const handleClick = () => {
        if (isDisabled) {
            return;
        }

        const newStatus = !isOn;

        onChange({
            target: {
                name,
                checked: newStatus,
                value: newStatus,
            },
        });

        setIsOn(newStatus);
    };

    // Used to change the value of the switch from a parent component after the initial load.
    // This is only necessary if a value is passed from the parent.
    useEffect(() => {
        if (undefined !== checked) {
            setIsOn(checked);
        }
    }, [checked]);

    return (
        <div
            role="switch"
            aria-checked={isOn}
            className={'switch-on-off-icon relative cursor-hand' + (isOn ? ' on' : '')}
            onClick={handleClick}
            {...rest}
        ></div>
    );
};

export default SlideSwitch;
