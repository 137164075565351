import { CredentialDataInterface, HostCredential } from './Credential';
import { CredentialHostType } from 'services/api/credentials';

export interface MySQLCredentialDataInterface extends CredentialDataInterface {
    sslEnabled: string;
    user: string;
    db: string;
    sslCA: string;
    sslCert: string;
    sslKey: string;
    gcpProjectID: string;
    gcpResourceID: string;
}

export default class MySQLCredential extends HostCredential {
    user: string;
    sslEnabled: boolean;
    database: string;
    sslCA: string;
    sslCert: string;
    sslKey: string;
    gcpProjectID: string;
    gcpResourceID: string;

    constructor(data: MySQLCredentialDataInterface) {
        super(CredentialHostType.mysql, data);
        this.user = data.user || '';
        this.sslEnabled = data.sslEnabled === 'true';
        this.database = data.db || '';
        this.sslCA = data.sslCA || '';
        this.sslCert = data.sslCert || '';
        this.sslKey = data.sslKey || '';
        this.gcpProjectID = data.gcpProjectID || '';
        this.gcpResourceID = data.gcpResourceID || '';
    }

    update(values: this, allowPasswordChange?: boolean) {
        super.update(values, allowPasswordChange ?? true);
        this.user = values.user;
        this.sslEnabled = values.sslEnabled;
        this.database = values.database;
        this.sslCA = values.sslCA;
        this.sslCert = values.sslCert;
        this.sslKey = values.sslKey;
        this.gcpProjectID = values.gcpProjectID;
        this.gcpResourceID = values.gcpResourceID;
    }

    asPayloadObject() {
        return {
            ...super.asPayloadObject(),
            user: this.user,
            sslEnabled: this.sslEnabled.toString(),
            db: this.database,
            sslCA: this.sslCA,
            sslCert: this.sslCert,
            sslKey: this.sslKey,
            gcpProjectID: this.gcpProjectID,
            gcpResourceID: this.gcpResourceID,
        };
    }
}
