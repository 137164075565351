import Quantity from 'models/numbers/Quantity';
import { fromName as unitFromName } from 'models/numbers/Unit';

const secureGatewayId = 'VCSG:';

export interface CPRNodeInterface {
    id: number;
    label: string;
    type: string;
    metadata: Record<string, unknown> | null;
}

export interface CPREdgeInterface {
    source: number;
    target: number;
    type: string;
    metadata: Record<string, unknown> | null;
}

export interface CPRInterface {
    type: string;
    host: {
        type: string;
        dbname: string;
        dbversion: string;
    };
    metadata: Record<string, unknown> | null;
    nodes: CPRNodeInterface[];
    edges: CPREdgeInterface[];
}

export interface SampleDataInterface {
    FailedRules: string | null;
    applicationName: string;
    connectionId: string | null;
    cpr?: CPRInterface;
    db: string;
    host: number;
    latency: number;
    origin: string;
    plan?: Record<string, unknown>;
    query: string;
    text: string | null;
    textEmptyReason?: string;
    truncated: boolean;
    ts: number;
    user: string;
}

/**
 * {
 *      query:"3108357559912008755",
 *      db:"template",
 *      user:"shards",
 *      origin:"10.10.10.178:36214",
 *      plan: {
 *          ExplainType: "mysql/json/1",
 *          ExplainWarnings: [],
 *          ...
 *      }
 *      applicationName:"",
 *      host:133,
 *      ts:1496172680,
 *      latency:0.000143,
 *      text:"SU5TRVJUIElHTk9SRSBgZW52XzIwNzdgLmBldmVudF8xXzE0OTYxMDI0MDBfMTQ5NjE4ODgwMGA=",
 *      truncated:false,
 *      sampleerror:"1146: Table doesn't exist",
 *      samplewarnings: 1
 *      FailedRules:null,
 *      connectionId:"8414849"
 * }
 */
export default class Sample {
    queryId: string;
    hostId: number;
    latency: number;
    latencyQuantity: Quantity;
    db: string;
    origin: string;
    user: string;
    text = '';
    applicationName: string;
    timestamp: number;
    isOnGateway: boolean;
    connectionId = '';
    gatewayToken = '';
    queryDigest = '';
    cpr?: CPRInterface;

    constructor(data: SampleDataInterface) {
        this.queryId = data.query;
        this.hostId = data.host;
        this.latency = data.latency;

        const second = unitFromName('second');
        this.latencyQuantity = new Quantity(data.latency, second);

        this.db = data.db;
        this.origin = data.origin;
        this.user = data.user;
        this.applicationName = data.applicationName;
        this.timestamp = data.ts;
        this.cpr = data.cpr;

        if (data.text) {
            try {
                this.text = atob(data.text);
            } catch (e) {
                this.text = data.text;
            }
        }

        this.isOnGateway = this.text.includes(secureGatewayId);

        if (data.truncated && !this.isOnGateway) {
            this.text += '[truncated]';
        }

        // See: https://github.com/VividCortex/ng-app/blob/master/src/js/models/sample.js#L122
        if (data.connectionId) {
            this.connectionId = data.connectionId;
        }

        if (this.isOnGateway) {
            this.gatewayToken = this.text.substring(secureGatewayId.length);

            // If there is a gateway token, then the text will, by default, be of
            // the format "VCSG:{token}". We already have the token stored above,
            // so no longer need to store it in the text. This has the added
            // benefit that if nothing is returned from the Secure Gateway,
            // this value will already be populated correctly.
            this.text = '';
        }
    }
}
