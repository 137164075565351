import { FC, useState, useEffect } from 'react';
import Filters from './Filters';
import TableRow from './TableRow';
import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';
import PinSwitch from 'components/form/PinSwitch';
import OffsetPaginator from 'components/lists/OffsetPaginator';
import Icon from 'components/icons/Icon';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import Header from 'components/layout/Header';
import useUIStatus from 'hooks/useUIStatus';
import Query from 'models/Query';
import { fetch, fetchQuery, QueriesFilterInterface } from 'services/api/queries';

const type = 'queries';

const Queries: FC = () => {
    const [uiStatus, setUIStatus] = useUIStatus(
        { queries: [] },
        {
            id: 'pins',
            excludeSearchKeys: [type],
        }
    );

    const [queries, setQueries] = useState<Query[]>();
    const [pinnedQueries, setPinnedQueries] = useState<Query[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [offset, setOffset] = useState<string[]>([]);
    const [filters, setFilters] = useState<QueriesFilterInterface>();
    const [showPins, setShowPins] = useState(true);
    const [error, setError] = useState(false);

    const { filter, noMatchingHosts } = useHostFilter();
    const { interval } = useTimeInterval();

    const pins: string[] = uiStatus[type];

    const fetchPins = async () => {
        const promises: Promise<Query>[] = [];
        pins?.forEach(pin => {
            promises.push(fetchQuery(pin));
        });

        Promise.all(promises).then(results => {
            setPinnedQueries(results);
        });
    };

    const fetchQueries = async () => {
        const counteract = offset.length === 0 ? '' : offset[offset.length - 1];
        setError(false);

        if (filters === undefined) {
            return;
        }

        try {
            const response = await fetch(filter, interval, { ...filters, offset: counteract });
            setQueries(response.queries);
            setHasMore(response.hasMore);
        } catch {
            setQueries([]);
            setError(true);
        }
    };

    const isPinnedQuery = (queryId: string) => {
        return pinnedQueries.find(query => query.id === queryId) !== undefined;
    };

    const handlePageIncrease = () => {
        if (queries === undefined) {
            return;
        }

        const newOffset = offset;
        newOffset.push(queries[queries.length - 1].id);

        setOffset(newOffset);
        fetchQueries();
    };

    const handlePageDecrease = () => {
        const newOffset = offset;
        newOffset.pop();

        setOffset(newOffset);
        fetchQueries();
    };

    const handleAddPin = (query: Query) => {
        const newPinnedQueries = [...pinnedQueries];
        newPinnedQueries.push(query);
        setPinnedQueries(newPinnedQueries);
        setUIStatus({ queries: newPinnedQueries.map(({ id }) => id) });
    };

    const handleRemovePin = (query: Query) => {
        const newPinnedQueries = pinnedQueries.filter(pinQuery => pinQuery.id !== query.id);
        setPinnedQueries(newPinnedQueries);
        setUIStatus({ queries: newPinnedQueries.map(({ id }) => id) });
    };

    const handleRemoveAllPins = () => {
        setPinnedQueries([]);
        setUIStatus({ queries: [] });
    };

    useEffect(() => {
        fetchQueries();
    }, [filters, interval, filter]); // eslint-disable-line

    useEffect(() => {
        fetchPins();
    }, []); // eslint-disable-line

    return (
        <div className="view view--filters">
            <Header title="Queries" contextualHelpId="queries" leftContent={<Filters onApply={setFilters} />} />
            {!noMatchingHosts && queries?.length === 0 && pins?.length === 0 && !error && (
                <EmptyState type={EmptyTypes.INFO}>
                    There are no results with the current filter and time selections. Use the filter above to search for
                    queries.
                </EmptyState>
            )}
            {!noMatchingHosts && error && (
                <EmptyState type={EmptyTypes.INFO}>
                    We're having trouble loading your queries. Please try again in a moment.
                </EmptyState>
            )}
            {pinnedQueries.length > 0 && (
                <PinSwitch
                    visible={true}
                    text="Show pinned queries"
                    onChange={() => setShowPins(!showPins)}
                    onRemove={handleRemoveAllPins}
                />
            )}
            {((queries && queries?.length > 0) || pinnedQueries?.length > 0) && (
                <div className="full-width" data-testid="queries-table">
                    <table className="vc-table vc-table--tile vc-table--queries vc-table--profiler full-width pb3">
                        <thead>
                            <tr>
                                <th>
                                    <Icon icon="pin" className="grey3 fz18 flex justify-center" />
                                </th>
                                <th>
                                    <Icon icon="bell" className="grey3 fz18 flex justify-center" />
                                </th>
                                <th>Time</th>
                                <th>First Seen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {showPins &&
                                pinnedQueries &&
                                pinnedQueries.map(query => (
                                    <TableRow query={query} onPinToggle={handleRemovePin} key={query.id} isPin />
                                ))}
                            {queries &&
                                queries
                                    .filter(query => {
                                        if (!showPins) {
                                            return true;
                                        }
                                        return !pinnedQueries.map(pin => pin.id).includes(query.id);
                                    })
                                    .map(query => (
                                        <TableRow
                                            query={query}
                                            onPinToggle={handleAddPin}
                                            key={query.id}
                                            isPin={isPinnedQuery(query.id)}
                                        />
                                    ))}
                        </tbody>
                    </table>
                    {(hasMore || offset.length > 0) && (
                        <OffsetPaginator
                            onFirstPage={offset.length === 0}
                            onLastPage={!hasMore}
                            onPageUp={handlePageIncrease}
                            onPageDown={handlePageDecrease}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default Queries;
