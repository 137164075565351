import { BrowserRouter as Router } from 'react-router-dom';
import { useAuthenticatedUser } from 'components/context/AuthenticatedUser';
import PublicLayout from 'components/layout/Public';
import MembersLayout from 'components/layout/Members';
import * as React from 'react';
import SessionManager from 'components/context/SessionManager';
import BSOD from 'components/messages/BSOD';
import PublicRouter from 'components/routing/PublicRouter';
import MembersRouter from 'components/routing/MembersRouter';

const AppFirewall: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const user = useAuthenticatedUser();
    let Layout = PublicLayout,
        AppRouter = PublicRouter,
        bsodTimeoutMessage = 'Your form has expired. Please reload the page.';

    if (user) {
        Layout = MembersLayout;
        AppRouter = MembersRouter;
        bsodTimeoutMessage = 'Your session expired due to inactivity. Please reload the page.';
    }

    return (
        <Router>
            <SessionManager>
                <BSOD message={bsodTimeoutMessage} onClick={() => window.location.reload()} />
            </SessionManager>
            <Layout>
                {children}
                <AppRouter />
            </Layout>
        </Router>
    );
};

export default AppFirewall;
