import Actions from 'components/form/Actions';
import Alert, { AlertTypes } from 'components/messages/Alert';
import BouncerButton from 'components/form/BouncerButton';
import Env from 'models/Env';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { FC, useState, SyntheticEvent } from 'react';
import { remove, ResponseErrorMessage } from 'services/api/envs';

export interface DeleteEnvironmentPropsInterface {
    env: Env;
    onClose: () => void;
    onSuccess: () => void;
}

const DeleteEnvironment: FC<DeleteEnvironmentPropsInterface> = ({ env, onClose, onSuccess }) => {
    const { error: errorMessage } = useDisplayMessage();
    const [submitting, setSubmitting] = useState(false);
    const [accepted, setAccepted] = useState(false);

    async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
        event.preventDefault();

        setSubmitting(true);

        try {
            await remove(env);

            onSuccess();
        } catch (error) {
            if (error instanceof Error && error.message === ResponseErrorMessage.FAILURE) {
                errorMessage('This environment cannot be removed.');
            } else {
                errorMessage('An error happened when trying to delete this environment.');
            }
        }

        setSubmitting(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="p3">
                <Alert type={AlertTypes.WARNING} className="mb2" sticky>
                    Be careful, this action CANNOT be undone.
                </Alert>

                <p className="line-height-120 py2">
                    You are about to delete the environment <strong>{env.name}</strong>.
                </p>

                <p className="line-height-120 mt2">
                    <label htmlFor="confirm-check" data-testid="confirm-check">
                        <input
                            type="checkbox"
                            id="confirm-check"
                            checked={accepted}
                            onChange={() => setAccepted(!accepted)}
                        />
                        <span>I know what I'm doing</span>
                    </label>
                </p>
            </div>

            <Actions className="form__actions--dialog p3 bg-white right-align full-width">
                <button type="button" className="primary-grey mr2" onClick={onClose}>
                    Cancel
                </button>
                <BouncerButton
                    bounce={submitting}
                    disabled={!accepted}
                    type="submit"
                    data-testid="confirm-submit"
                    className="primary-red nowrap deleteEnvForm__submit"
                >
                    Delete this environment
                </BouncerButton>
            </Actions>
        </form>
    );
};

export default DeleteEnvironment;
