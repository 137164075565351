import { useTimeInterval } from 'components/context/TimeInterval';
import { useHostFilter } from 'components/context/HostFilter';

function useTimeAndHostFilter() {
    const { filter, noMatchingHosts } = useHostFilter();
    const { interval } = useTimeInterval();

    return { filter, interval, noMatchingHosts };
}

export default useTimeAndHostFilter;
