import { useDiagramPlugin } from '../diagram';
import type { Diagram } from '../diagram';
import { getMouseSvgPoint, ViewBox } from '../../../helpers/diagram';

const DOM_DELTA_LINE = 1;

export interface UseDiagramZoomOptions {
    /**
     * The scaling factor to use when zooming. Each zoom step will resize the ViewBox by this value.
     * @default 1.25
     */
    readonly scaleFactor?: number;
}

export interface UseDiagramZoomResult {
    zoomIn(): void;
    zoomOut(): void;
    canZoomIn: boolean;
    canZoomOut: boolean;
}

export function useDiagramZoom<TNode, TEdge>(
    diagram: Diagram<TNode, TEdge>,
    { scaleFactor = 1.25 }: UseDiagramZoomOptions = {}
): UseDiagramZoomResult {
    const { initialViewBox: maxViewBox, minViewBox, viewBox } = diagram;

    function scaleViewBox(steps: number, prevViewBox: ViewBox, center = prevViewBox.center): ViewBox {
        const newViewBox = prevViewBox.scale(Math.pow(scaleFactor, steps), center);

        if (newViewBox.width < minViewBox.width && newViewBox.height < minViewBox.height) {
            // If we tried to take multiple steps at once, walk backward until we find a ViewBox that fits.
            // This zooms in as far as possible while still maintaining a power of maxViewBox.
            return steps < -1 ? scaleViewBox(steps + 1, prevViewBox, center) : prevViewBox;
        } else if (newViewBox.width > maxViewBox.width && newViewBox.height > maxViewBox.height) {
            return maxViewBox;
        } else {
            return newViewBox;
        }
    }

    function onWheel(e: React.WheelEvent<SVGSVGElement>): void {
        const cursorPoint = getMouseSvgPoint(e);
        const steps = Math.round(e.deltaY / (e.deltaMode === DOM_DELTA_LINE ? 3 : 50));
        diagram.setViewBox(prev => scaleViewBox(steps, prev, cursorPoint));
    }

    useDiagramPlugin(diagram, {
        svgProps: { onWheel },
    });

    return {
        get canZoomIn() {
            return scaleViewBox(-1, viewBox) !== viewBox;
        },
        get canZoomOut() {
            return (
                viewBox.height.toFixed(4) !== maxViewBox.height.toFixed(4) ||
                viewBox.width.toFixed(4) !== maxViewBox.width.toFixed(4)
            );
        },
        zoomIn() {
            diagram.setViewBox(prev => scaleViewBox(-1, prev));
        },
        zoomOut() {
            diagram.setViewBox(prev => scaleViewBox(1, prev));
        },
    };
}
