import { useState, useEffect } from 'react';
import SettingsLayout from '../Layout';
import { SlideSwitchEventInterface } from 'components/form/SlideSwitch';
import EmailPreferenceAutoSubscribe from './AutoSubscribe';
import EmailPreferenceBulkSubscribe from './BulkSubscribe';
import EmailPreferenceRow from './Row';
import { getSubscriptions, update, SubscriptionInterface } from 'services/api/userSubscriptions';
import Bouncer from 'components/icons/Bouncer';
import Alert, { AlertTypes } from 'components/messages/Alert';
import useUser from 'hooks/useUser';
import useEnvs from 'hooks/useEnvs';
import Env from 'models/Env';
import { TargetEnv } from 'services/api/Request';

export const APIStatus = {
    default: 0,
    inProcess: 1,
    success: 2,
    error: 3,
};

const SettingsEmailPreferences = () => {
    const [subscriptions, setSubscriptions] = useState<SubscriptionInterface[]>([]);
    const [state, setState] = useState({
        isLoading: false,
        isErrorOnBulkUpdate: false,
        isErrorOnFetch: false,
    });
    const envs = useEnvs();
    const user = useUser();

    const fetchSubscriptions = () => {
        setState({ ...state, isLoading: true });

        getSubscriptions(user)
            .then(response => {
                setState(currentState => ({ ...currentState, isLoading: false }));
                if (undefined === envs) {
                    return;
                }

                // Map API response subscription data to actual environments through EnvironmentID.
                const fetchedSubscriptions = envs.map((env: Env) => {
                    const envSub = response.find(e => e.env === env.id);
                    return {
                        id: env.id,
                        name: env.name,
                        subscribed: !!envSub && envSub.subscribed,
                        status: APIStatus.default,
                    };
                });

                setSubscriptions(fetchedSubscriptions);
            })
            .catch(() => {
                setState(currentState => ({ ...currentState, isErrorOnFetch: true, isLoading: false }));
            });
    };

    const saveSubscriptions = (subscription: SubscriptionInterface, atIndex: number) => {
        const newSubscriptions = [...subscriptions];
        newSubscriptions.splice(atIndex, 1, subscription);
        setSubscriptions(newSubscriptions);
    };

    const handleUpdatedSubscription = (e: SlideSwitchEventInterface) => {
        const orgIndex = subscriptions.findIndex(
            (subscription: SubscriptionInterface) => subscription.name === e.target.name
        );
        const updatedSubscription = subscriptions.find(subscription => subscription.name === e.target.name);

        if (!updatedSubscription) {
            return;
        }

        updatedSubscription.status = APIStatus.inProcess;
        saveSubscriptions(updatedSubscription, orgIndex);

        update(user, updatedSubscription.id, e.target.checked)
            .then(() => {
                updatedSubscription.subscribed = e.target.checked;
                updatedSubscription.status = APIStatus.success;
            })
            .catch(() => {
                updatedSubscription.status = APIStatus.error;
            })
            .finally(() => {
                saveSubscriptions(updatedSubscription, orgIndex);
            });
    };

    const updateAllSubscriptions = (shouldSubscribe: boolean) => {
        const affectedSubscriptions = subscriptions.filter(env => env.subscribed !== shouldSubscribe);

        if (affectedSubscriptions.length === 0) {
            return;
        }

        setState({ ...state, isErrorOnBulkUpdate: false });

        affectedSubscriptions.forEach((subscription, index) => {
            subscription.status = APIStatus.inProcess;
            saveSubscriptions(subscription, index);
        });

        update(user, TargetEnv.CURRENT_ORG, shouldSubscribe)
            .then(() => {
                affectedSubscriptions.forEach(subscription => {
                    subscription.subscribed = shouldSubscribe;
                    subscription.status = APIStatus.success;
                });
            })
            .catch(() => {
                setState({ ...state, isErrorOnBulkUpdate: true });
                affectedSubscriptions.forEach(subscription => {
                    subscription.status = APIStatus.default;
                });
            })
            .finally(() => {
                setSubscriptions(affectedSubscriptions);
            });
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SettingsLayout title="Email Preferences">
            <div className="settings__form settings__form--email-pref mt4 flex items-start flex-wrap">
                <p className="settings__form__label bold uppercase fz10 spacing inline-flex">Daily summary emails:</p>
                <div className="settings__form--email-pref__list relative">
                    <p className="grey3 line-height-120 inline-flex">
                        This email includes a list of 10 most time consuming queries for each day per environment.
                    </p>
                    <EmailPreferenceBulkSubscribe
                        onSubscribe={() => updateAllSubscriptions(true)}
                        onUnsubscribe={() => updateAllSubscriptions(false)}
                        showError={state.isErrorOnBulkUpdate}
                    />

                    {state.isLoading && <Bouncer />}
                    <Alert type={AlertTypes.ERROR} className="my3" visible={state.isErrorOnFetch} sticky>
                        An error occurred fetching subscription status. Please try again or report this situation to our
                        support team.
                    </Alert>
                    {subscriptions.map(subscription => (
                        <EmailPreferenceRow
                            key={`${subscription.id}-${subscription.status}`}
                            subscription={subscription}
                            onChange={handleUpdatedSubscription}
                        />
                    ))}
                    <EmailPreferenceAutoSubscribe />
                </div>
            </div>
        </SettingsLayout>
    );
};

export default SettingsEmailPreferences;
