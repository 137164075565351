import { useState, useEffect, FC } from 'react';
import md5 from 'blueimp-md5';
import { getInitialsFromName } from 'services/user/gravatar';

interface GravatarPropsInterface {
    className?: string;
    email: string;
    name: string;
    size?: string;
}

export enum GravatarSize {
    SMALL = 'small',
    MEDIUM = 'medium',
    BIG = 'big',
}

type GravatarWidthType = {
    [index: string]: string;
};

const widths: GravatarWidthType = {
    [GravatarSize.SMALL]: '36',
    [GravatarSize.MEDIUM]: '48',
    [GravatarSize.BIG]: '64',
};

const Gravatar: FC<GravatarPropsInterface> = ({ className, email, name, size = GravatarSize.SMALL }) => {
    const [brokenImage, setBrokenImage] = useState(false);

    useEffect(() => {
        setBrokenImage(false);
    }, [email]);

    const handleFailedLoad = () => setBrokenImage(true);

    const initials = getInitialsFromName(name);
    const hash = email ? md5(email) : null;

    const avatar = (
        <img
            src={`https://gravatar.com/avatar/${hash}?d=404&s=${widths[size]}`}
            alt={`${name} gravatar`}
            onError={handleFailedLoad}
        />
    );

    const showImage = !brokenImage && hash;

    return (
        <div className={`gravatar--${size} ${className}`}>
            {showImage && avatar}
            {!showImage && <span className="initials">{initials}</span>}
        </div>
    );
};

export default Gravatar;
