import { RequestParamInterface, hasParam, HostInterface } from './requestParamsEncoder';

function encodeHostsArray(hosts: HostInterface[], separateHosts = false) {
    const hostIds = hosts.map(host => host.id).sort();
    return hostIds
        .filter((value: number, index: number) => hostIds.indexOf(value) === index)
        .filter((host: number) => {
            // The API will not send back host information (separateHosts=1) if host=0 is sent with the API
            // request. This is true no matter what value is sent for separateHosts. Therefore, when
            // separateHosts is true, we want to remove it
            return separateHosts ? host !== 0 : true;
        })
        .join(',');
}

export default function encodeHosts(params: RequestParamInterface) {
    if (!hasParam('hosts', params)) {
        return;
    }
    params.host = encodeHostsArray(params.hosts as HostInterface[], !!params.separateHosts);
    delete params.hosts;
    return;
}
