import { useLayoutEffect, useMemo, useState } from 'react';
import type { SetStateAction, SVGAttributes } from 'react';
import type { DiagramEdge, DiagramEdgeInput, DiagramLayoutFunction, DiagramNode } from './types';
import { ViewBox } from '../../helpers/diagram';

export interface UseDiagramOptions<TNode, TEdge> {
    readonly edges: ReadonlyArray<DiagramEdgeInput<TNode, TEdge>>;
    readonly layout: DiagramLayoutFunction<TNode, TEdge>;
    /**
     * @default ViewBox(0, 0, 0, 0)
     */
    readonly minViewBox?: ViewBox;
    readonly nodes: ReadonlyArray<TNode>;
}

// Symbols for functions internal to this module.
// This allows certain Diagram functions to be accessible only within useDiagramPlugin.
// Routing through useDiagramPlugin future proofs the plugin API if it needs to rely on React hooks later.
const addSvgProps = Symbol('addSvgProps');

export interface Diagram<TNode, TEdge> {
    readonly edges: ReadonlyArray<DiagramEdge<TNode, TEdge>>;
    readonly initialViewBox: ViewBox;
    readonly minViewBox: ViewBox;
    readonly nodes: ReadonlyArray<DiagramNode<TNode>>;
    readonly svgProps: Readonly<SVGAttributes<SVGSVGElement>>;
    readonly viewBox: ViewBox;
    [addSvgProps](props: Readonly<SVGAttributes<SVGSVGElement>>): void;
    reset(): void;
    setViewBox(action: SetStateAction<ViewBox>): void;
}

const ZERO_VIEWBOX = new ViewBox(0, 0, 0, 0);

/**
 * useMemo for ViewBox that memoizes by value.
 * @param viewBox
 * @returns
 */
function useMemoViewBox(viewBox: ViewBox): ViewBox {
    const { height, minX, minY, width } = viewBox;
    return useMemo(() => new ViewBox(minX, minY, width, height), [height, minX, minY, width]);
}

export function useDiagram<TNode, TEdge>({
    edges,
    layout,
    minViewBox,
    nodes,
}: UseDiagramOptions<TNode, TEdge>): Diagram<TNode, TEdge> {
    const { viewBox: layoutViewBox, ...positions } = useMemo(() => layout(nodes, edges), [edges, layout, nodes]);
    const initialViewBox = useMemoViewBox(minViewBox ? ViewBox.max(layoutViewBox, minViewBox) : layoutViewBox);
    const [viewBox, setViewBox] = useState<ViewBox>(initialViewBox);
    useLayoutEffect(() => {
        setViewBox(initialViewBox);
    }, [initialViewBox]);
    return {
        [addSvgProps](props) {
            Object.assign(this.svgProps, props);
        },
        edges: positions.edges,
        initialViewBox,
        minViewBox: minViewBox ?? ZERO_VIEWBOX,
        nodes: positions.nodes,
        svgProps: {
            viewBox: viewBox.toString(),
        },
        viewBox,
        reset() {
            setViewBox(initialViewBox);
        },
        setViewBox,
    };
}

export interface DiagramPlugin {
    /**
     * Attributes and props to add to the SVG element.
     * Values will be merged with existing values when possible.
     * Events will be invoked and styles will be merged, but scalar values will override previous values.
     */
    svgProps?: SVGAttributes<SVGSVGElement>;
}

/**
 * Registers a plugin with the specified diagram.
 * Ensures that plugin is applied properly and efficiently.
 * @param diagram Diagram instance to apply plugin to. Should be result from useDiagram.
 * @param plugin The plugin definition to register.
 */
export function useDiagramPlugin<TNode, TEdge>(diagram: Diagram<TNode, TEdge>, plugin: DiagramPlugin): void {
    // plugin.svgProps could be a getter, so only invoke it once
    const svgProps = plugin.svgProps;
    if (svgProps) {
        diagram[addSvgProps](svgProps);
    }
}
