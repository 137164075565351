import { RequestParamInterface } from './requestParamsEncoder';

export default function encodeSimpleParams(params: RequestParamInterface) {
    Object.keys(params).forEach(key => {
        // Boolean values are transformed to the integer 1 or 0
        if (typeof params[key] === 'boolean') {
            params[key] = params[key] ? 1 : 0;
        }

        if (Array.isArray(params[key])) {
            params[key] = (params[key] as []).join(',');
        }
    });
}
