import { OFFSET_PRESETS, getOffsetPreset } from 'services/time';

export interface StepInterface {
    value: number;
    x: number;
    name: string;
}

const range = OFFSET_PRESETS.map(preset => preset.seconds);

const makeDomain = () => range.map((value: number, i: number) => (i * 100) / (range.length - 1));

const buildSteps = (domain: number[]): StepInterface[] =>
    domain.map((x, i) => ({
        value: range[i],
        x,
        name: getOffsetPreset(range[i])?.text || `${range[i]}`,
    }));

export function get() {
    return buildSteps(makeDomain());
}
