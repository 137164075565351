import Icon from 'components/icons/Icon';
import Timer from 'services/timer';
import Logger from 'services/logger';
import { FC, useEffect, useState } from 'react';
import { getIncidents, IncidentDataInterface } from 'services/incidents';

const timer = new Timer();
const POLL_TIME = 3600000;
const logger = Logger.get('Incidents');

const Incidents: FC = () => {
    const [incidents, setIncidents] = useState<IncidentDataInterface[]>([]);
    const [dismissedIds, setDismissedIds] = useState<string[]>([]);

    useEffect(() => {
        function refresh() {
            timer.set('incidents', refresh, POLL_TIME);

            getIncidents()
                .then(setIncidents)
                .catch(e => {
                    setIncidents([]);
                    logger.error(e);
                });
        }

        refresh();

        return () => timer.clear('incidents');
    }, []);

    const displayIncidents = incidents.filter(incident => !dismissedIds.includes(incident.id));

    if (!displayIncidents.length) {
        return null;
    }

    return (
        <div className="show-status-message">
            {displayIncidents.map(incident => (
                <div className="status-message-wrp z2 fixed top-0 full-width" key={incident.id}>
                    <div
                        className={`flex full-height items-center pl3 status-message-wrp__message white bg-orange-message status-${incident.impact}`}
                        data-testid="incident"
                    >
                        <Icon
                            icon="warning"
                            className="white inline-flex fz18 mr2 status-message-wrp__message__warning"
                        />
                        <p className="status-message__message">
                            {incident.name}&nbsp;
                            <a href={incident.shortlink} target="_blank" rel="noopener noreferrer">
                                details
                            </a>
                        </p>
                        <Icon
                            icon="close"
                            className="inline-flex fz18 white ml2 cursor-hand"
                            onClick={() => setDismissedIds([...dismissedIds, incident.id])}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Incidents;
