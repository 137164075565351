import ContactLink from 'components/messages/ContactLink';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { purge as purgeSamples } from 'services/api/samples';

const DeleteSamples = () => {
    const { success, error } = useDisplayMessage();

    const { openModal } = useFinalActionModal();

    const handleSubmit = async () => {
        try {
            await purgeSamples();
            success('Samples successfully deleted');
        } catch {
            error('There was an error when trying to delete samples. Please try again later');
        }
    };

    return (
        <div className="border-top border-color-grey1 pt4">
            <div className="settings__form--preferences">
                <div className="flex">
                    <h1 className="h2 mr2 lighter grey3 flex-grow-1">Delete Query Samples</h1>
                </div>

                <p className="h4 lighter mt3 mb3 line-height-120">
                    Delete all samples collected in this environment. If sampling is enabled, all samples up to this
                    point will be deleted. If you have any questions about this process please{' '}
                    <ContactLink>contact support</ContactLink> to learn more.
                </p>

                <div className="flex items-center">
                    <p className="red mb2 pr4 line-height-120">
                        <b>Warning:</b> This action cannot be undone. Once samples are deleted it is impossible to get
                        them back.
                    </p>
                    <button
                        className="primary-red nowrap"
                        data-testid="delete-samples"
                        onClick={() => {
                            openModal({
                                onSubmit: handleSubmit,
                                confirmMsg:
                                    'This action cannot be undone. You will lose all your samples collected up to this point.',
                                buttonText: 'Delete samples',
                                title: 'Are you sure you want to delete samples?',
                            });
                        }}
                    >
                        Delete Samples
                    </button>
                </div>
            </div>
        </div>
    );
};
export default DeleteSamples;
