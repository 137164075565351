import { FC } from 'react';

const WidgetBox: FC<{ wholeRow?: boolean; className?: string; children?: React.ReactNode }> = ({
    wholeRow = false,
    className = '',
    children,
}) => {
    const fullRowClassName = 'flex-grow-1 p3 mt1 p3 tile m3';
    const colClassName = 'tile p2 table full-width top-align overflow-hidden mb2';
    return (
        <div
            data-testid={`${wholeRow ? 'whole-row' : 'column'}-widgetBox`}
            className={`${wholeRow ? fullRowClassName : colClassName} ${className}`}
        >
            {children}
        </div>
    );
};

export default WidgetBox;
