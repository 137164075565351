import { FC, useState, useEffect } from 'react';
import { get as getOrgConfig, store as storeOrgConfig } from 'services/api/orgConfig';
import SlideSwitch, { SlideSwitchEventInterface } from 'components/form/SlideSwitch';
import Logger from 'services/logger';
import Bouncer from 'components/icons/Bouncer';

const logger = Logger.get('OverviewImpersonation');

const OverviewImpersonation: FC = ({ ...rest }) => {
    const [impersonationGranted, setImpersonationGranted] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    async function fetchOrgConfig() {
        try {
            const orgConfig = await getOrgConfig();
            setImpersonationGranted(orgConfig.impersonationGranted === '1');
        } catch (e) {
            logger.warn('error while fetching the org config');
        } finally {
            setIsLoading(false);
        }
    }

    const handleSwitch = async (e: SlideSwitchEventInterface) => {
        try {
            await storeOrgConfig('impersonationGranted', e.target.checked ? '1' : '0');
        } catch (error) {
            logger.warn('error while saving impersonation config');
        }
    };

    useEffect(() => {
        fetchOrgConfig();
    }, []);

    return (
        <div {...rest}>
            <div className="flex mt4">
                <h1 className="h2 mr2 lighter grey3 flex-grow-1">Configuration</h1>
            </div>
            <form className="settings__form py4">
                <fieldset>
                    <label className="flex items-center">
                        <b className="settings__form__label uppercase fz10 spacing inline-flex items-center">
                            Allow impersonation
                        </b>

                        <div className="inline-flex">
                            {isLoading ? (
                                <Bouncer></Bouncer>
                            ) : (
                                <SlideSwitch checked={impersonationGranted} onChange={handleSwitch}></SlideSwitch>
                            )}
                        </div>
                    </label>
                    <div className="form__label form__label--margin grey3 line-height-120">
                        <span>
                            Give DPM employees permission to access my dashboard in order to provide support and help me
                            diagnose issues.
                        </span>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

export default OverviewImpersonation;
