import { useState, useEffect } from 'react';
import SettingsLayout from '../Layout';
import EnvTeamsTable from './Table';
import AssignTeam from './AssignTeam';
import Modal from 'components/modals/Modal';
import Bouncer from 'components/icons/Bouncer';
import Role from 'models/Role';
import Team from 'models/Team';
import { get as getAllTeams } from 'services/api/teams';
import { get as getRoles } from 'services/api/roles';
import useEnv from 'hooks/useEnv';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { UserHasPermission } from 'components/security/UserHasPermission';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';

const SettingsEnvTeams = () => {
    const [allTeams, setAllTeams] = useState<Team[]>([]);
    const [envTeams, setEnvTeams] = useState<Team[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [modalVisible, displayModal] = useState(false);
    const [loading, isLoading] = useState(false);

    const env = useEnv();
    const envName = env.name;

    const { success, error } = useDisplayMessage();
    const showModal = () => displayModal(true);
    const hideModal = () => displayModal(false);

    const button = (
        <UserHasPermission to="linkTeam">
            <button className="primary-orange" onClick={showModal}>
                Assign Team
            </button>
        </UserHasPermission>
    );

    const fetchTeams = async () => {
        isLoading(true);
        try {
            const [fetchedRoles, fetchedAllTeams, fetchedAllEnvTeams] = await Promise.all([
                getRoles(),
                getAllTeams({ withUsers: true, format: 'object' }),
                getAllTeams({ withUsers: true, format: 'object', forEnv: true }),
            ]);
            setRoles(fetchedRoles);
            setAllTeams(fetchedAllTeams);
            setEnvTeams(fetchedAllEnvTeams);
        } catch (e) {
            error('We are unable to fetch teams at this time. Please try again or contact support.');
        } finally {
            isLoading(false);
        }
    };

    const handleAddedTeam = (team: Team) => {
        setEnvTeams((currentEnvTeams: Team[]) => {
            const teams = [...currentEnvTeams];
            teams.unshift(team);
            return teams;
        });

        hideModal();

        success(`${team.name} successfully added.`);
    };

    const onDelete = (team: Team) => {
        setEnvTeams((currentEnvTeams: Team[]) => {
            const teams = [...currentEnvTeams];
            teams.splice(
                teams.findIndex(currentTeam => currentTeam.id === team.id),
                1
            );
            return teams;
        });

        success(`${team.name} successfully removed.`);
    };

    useEffect(() => {
        fetchTeams();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SettingsLayout
            title={`Teams that have access to ${envName} environment`}
            metaTitle="Teams"
            headerContent={env && button}
        >
            <FinalActionModalProvider>
                <div>
                    <p className="h4 lighter mt4 mb3" data-testid="env-teams-intro-text">
                        Below are the teams assigned to the environments. Each team has its own permissions.
                    </p>
                </div>
                {loading && <Bouncer />}
                {!loading && <EnvTeamsTable teams={envTeams} roles={roles} env={env} onDelete={onDelete} />}
                <Modal visible={modalVisible} title="Assign Team" onClose={hideModal}>
                    <AssignTeam
                        teams={allTeams.filter(team => !envTeams.map(envTeam => envTeam.id).includes(team.id))}
                        roles={roles}
                        env={env}
                        onCancel={hideModal}
                        onSuccess={handleAddedTeam}
                    />
                </Modal>
            </FinalActionModalProvider>
        </SettingsLayout>
    );
};
export default SettingsEnvTeams;
