import { FC, useState, useEffect } from 'react';
import { Form, FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import TextField from 'components/form/fields/TextField';
import CheckboxField from 'components/form/fields/CheckboxField';
import SelectField from 'components/form/fields/SelectField';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import useUIStatus from 'hooks/useUIStatus';
import { QueriesFilterInterface } from 'services/api/queries';

const Filters: FC<{
    onApply?: (values: QueriesFilterInterface) => void;
}> = ({ onApply = () => {} }) => {
    const defaultStatusValues = {
        limit: 15,
        filter: '',
        exists: '',
        offset: '',
        errorCode: '',
        filterCheckbox: 0,
    };

    const [uiStatus, setUIStatus] = useUIStatus(defaultStatusValues, {
        id: 'queries',
    });

    const [filter, setFilter] = useState({ ...uiStatus });
    const [initialValues] = useState<QueriesFilterInterface>({
        ...filter,
        newQueriesOnly: filter.filterCheckbox === 1,
        ignoreHostsAndTime: filter.filterCheckbox === 2,
    });

    const options: { name: string; value: string }[] = [
        { value: '', name: 'No filter' },
        { value: 'host.queries.*.*.errors.tput', name: 'Errors' },
        { value: 'host.queries.*.*.warnings.tput', name: 'Warnings' },
        {
            value: 'host.queries.*.*.no_index.tput',
            name: 'Missing Indexes',
        },
        {
            value: 'host.queries.*.*.slow.tput',
            name: 'Slow Response Times',
        },
    ];

    const handleApply = (values: QueriesFilterInterface) => {
        const { newQueriesOnly, ignoreHostsAndTime, ...newValues } = values;
        newValues.filterCheckbox = newQueriesOnly ? 1 : ignoreHostsAndTime && newValues.exists === undefined ? 2 : 0;

        setFilter(newValues);

        // We pass default values because React Final Form does not pass empty values
        // when submitting.
        // See https://github.com/final-form/react-final-form/issues/130
        setUIStatus({ ...defaultStatusValues, ...newValues });
    };

    useEffect(() => {
        onApply(filter);
    }, [filter]); // eslint-disable-line

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleApply}
            render={({ pristine, invalid, handleSubmit, values }) => {
                const { exists, newQueriesOnly, ignoreHostsAndTime } = values;
                return (
                    <form
                        className="flex items-center flex-grow-1 full-height overflow-auto px2"
                        onSubmit={handleSubmit}
                    >
                        <fieldset className="queries__filters__show">
                            <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Show">
                                <Field name="exists">
                                    {({ ...props }: FieldRenderProps<string, HTMLSelectElement>) => (
                                        <SelectField {...props} options={options} data-testid="filter-exists-value" />
                                    )}
                                </Field>
                            </FormGroup>
                        </fieldset>
                        {exists === 'host.queries.*.*.errors.tput' && (
                            <fieldset className="flex-grow-1 ml3">
                                <label className="flex items-center">
                                    <b className="mr2 fz10 uppercase nowrap">with code</b>
                                    <div className="flex-grow-1 relative">
                                        <Field
                                            className=""
                                            component={TextField}
                                            id="errorCode"
                                            name="errorCode"
                                            placeholder="(optional)"
                                            data-testid="query-error-code-input"
                                        />
                                    </div>
                                </label>
                            </fieldset>
                        )}
                        <fieldset className="flex-grow-1 ml3">
                            <label className="flex items-center">
                                <b className="mr2 fz10 uppercase nowrap">Query Text</b>
                                <div className="flex-grow-1 relative">
                                    <Field
                                        className=""
                                        component={TextField}
                                        id="filter"
                                        name="filter"
                                        placeholder="Filter queries by content"
                                        data-testid="query-filter-text-input"
                                    />
                                </div>
                            </label>
                        </fieldset>
                        <fieldset className="ml3 filters__queries__checkbox">
                            <Field
                                type="checkbox"
                                name="newQueriesOnly"
                                disabled={ignoreHostsAndTime}
                                component={CheckboxField}
                                label="New Queries Only"
                                id="new-queries-only-checkbox"
                            />
                        </fieldset>
                        <fieldset className="ml3 filters__queries__checkbox">
                            <Field
                                type="checkbox"
                                name="ignoreHostsAndTime"
                                disabled={newQueriesOnly || exists}
                                component={CheckboxField}
                                label="Ignore time/hosts filters"
                                id="ignore-time-hosts-checkbox"
                            />
                        </fieldset>
                        <fieldset className="ml3">
                            <button disabled={pristine || invalid} type="submit" className="primary-orange">
                                Apply
                            </button>
                        </fieldset>
                    </form>
                );
            }}
        />
    );
};

export default Filters;
