import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const AppParameterHandler: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get('app')) {
        searchParams.delete('app');

        const search = searchParams.toString() ? `?${searchParams.toString()}` : '';

        history.replace(location.pathname + search);
    }

    return null;
};

export default AppParameterHandler;
