import { createContext, useContext, useReducer, FC, Dispatch } from 'react';
import { reducer, getInitialState, StateInterface, ActionInterface } from 'reducers/globalMessages';

type GlobalMessagesContextType = [StateInterface, Dispatch<ActionInterface>];

export const GlobalMessagesContext = createContext<GlobalMessagesContextType | undefined>(undefined);

const GlobalMessagesProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <GlobalMessagesContext.Provider value={useReducer(reducer, getInitialState())}>
            {children}
        </GlobalMessagesContext.Provider>
    );
};

export const useGlobalMessages = (): GlobalMessagesContextType => {
    const context = useContext(GlobalMessagesContext);

    if (context === undefined) {
        throw new Error('Trying to access undefined context');
    }

    return context;
};

export default GlobalMessagesProvider;
