import { ChangeEvent, useState, useEffect, useMemo } from 'react';
import OrgTeam from './Team';
import AddTeam from './AddTeam';
import Modal from 'components/modals/Modal';
import SettingsLayout from '../Layout';
import Role from 'models/Role';
import User from 'models/User';
import Team from 'models/Team';
import { get as getAllTeams } from 'services/api/teams';
import { get as getRoles } from 'services/api/roles';
import { get as getUsers } from 'services/api/users';
import useOrg from 'hooks/useOrg';
import useEnvs from 'hooks/useEnvs';
import useUser from 'hooks/useUser';
import useDisplayMessage from 'hooks/useDisplayMessage';
import Bouncer from 'components/icons/Bouncer';
import Icon from 'components/icons/Icon';
import { isUsingSAMLGroupProvisioning } from 'services/settings/authentication';
import { UserHasPermission } from 'components/security/UserHasPermission';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';

const SettingsOrgTeams = () => {
    const [allTeams, setAllTeams] = useState<Team[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [roles, setRoles] = useState<Role[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [title, setTitle] = useState<string>('');
    const [modalVisible, displayModal] = useState(false);
    const [state, setState] = useState({
        isLoading: false,
        usingSAMLGroupProvisioning: false,
    });
    const org = useOrg();
    const envs = useEnvs();
    const currentUser = useUser();
    const { success, error } = useDisplayMessage();

    const showModal = () => displayModal(true);
    const hideModal = () => displayModal(false);
    const button = (
        <UserHasPermission to="createTeam">
            <button className="primary-orange" onClick={showModal}>
                New Team
            </button>
        </UserHasPermission>
    );

    const canInviteUsers = (team: Team) => {
        if (team.type === 'everyone') {
            return false;
        }

        const ownersTeam = allTeams.find(t => t.type === 'owners');
        const currentUserIsOwner =
            undefined !== ownersTeam && undefined !== ownersTeam.users.find(user => user.id === currentUser.id);
        const currentUserBelongsToTeam = undefined !== team.users.find(user => user.id === currentUser.id);

        return currentUserBelongsToTeam || currentUserIsOwner;
    };

    const fetchTeams = async () => {
        setState({ ...state, isLoading: true });

        try {
            const promises = [
                getRoles().then(currentRoles => setRoles(currentRoles)),
                getUsers().then(currentUsers => setUsers(currentUsers)),
                getAllTeams({
                    withUsers: true,
                    format: 'object',
                }).then(teams => {
                    setAllTeams(teams);
                    setTitle(`${teams.length} teams in the ${org.name} organization`);
                }),
            ];

            // Now we wait for ALL the request to complete
            await Promise.all(promises);
        } catch (e) {
            error('We are unable to fetch teams at this time. Please try again or contact support.');
        } finally {
            setState({ ...state, isLoading: false });
        }

        const usingSAMLGroupProvisioning: boolean = await isUsingSAMLGroupProvisioning();

        setState({ ...state, usingSAMLGroupProvisioning });
    };

    const filteredTeams = useMemo(() => {
        if (!allTeams.length || !filter.length) {
            return allTeams;
        }
        const input = filter.toLowerCase();
        const teams = allTeams.filter(team => {
            if (team.name.toLowerCase().includes(input)) {
                return true;
            }

            const environments = team.envs.map(teamEnv => envs.find(env => env.id === teamEnv.id));
            if (environments.filter(env => env?.name.toLowerCase().includes(input)).length > 0) {
                return true;
            }

            const teamUsers = team.users as User[];
            const usernames = teamUsers.map(user => user.name.toLowerCase());
            if (usernames.filter(name => name.includes(input)).length > 0) {
                return true;
            }

            return false;
        });

        return teams;
    }, [filter, allTeams, envs]);

    const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const handleFilterClear = () => setFilter('');

    const handleAddedTeam = (team: Team) => {
        handleFilterClear();

        const teams = [...allTeams];
        teams.unshift(team);
        setAllTeams(teams);

        hideModal();

        success(`${team.name} successfully added.`);
    };

    const onDelete = (team: Team) => {
        handleFilterClear();
        const teams = [...allTeams];
        teams.splice(teams.indexOf(team), 1);
        setAllTeams(teams);

        success(`${team.name} successfully removed.`);
    };

    const onRename = (team: Team) => {
        const indexInAllTeams = allTeams.findIndex(t => t.id === team.id);

        const newAllTeams = [...allTeams];
        newAllTeams.splice(indexInAllTeams, 1, team);
        setAllTeams(newAllTeams);
    };

    useEffect(() => {
        fetchTeams();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SettingsLayout title={title} metaTitle="Organization Teams" headerContent={allTeams?.length > 0 && button}>
            <FinalActionModalProvider>
                {state.isLoading && <Bouncer />}
                {!state.isLoading && (
                    <div>
                        {state.usingSAMLGroupProvisioning && (
                            <div className="h4 lighter mt4 mb3 flex" data-testid="saml-provisioning-message">
                                <Icon icon="info" className="grey3 mr2" />
                                When using SAML groups provisioning, teams aren't added or deleted from this page. To
                                edit your teams, please do so from your identity provider.
                            </div>
                        )}
                        <p className="h4 lighter mt4 mb3" data-testid="org-teams-intro-text">
                            These groups of users can be assigned access permission to the environments in this
                            organization.
                        </p>
                        <div className="flex justify-end nowrap mb3">
                            <label className="nowrap relative settings__section-content__filter-teams flex items-center">
                                <span className="uppercase mr2 fz10 monospace nowrap">Filter</span>
                                <input
                                    type="text"
                                    value={filter}
                                    onChange={handleFilterChange}
                                    data-testid="filter-team"
                                    placeholder="By team, user or environment"
                                    className="full-width"
                                />
                                {filter.length > 0 && (
                                    <Icon
                                        icon="cancel"
                                        onClick={handleFilterClear}
                                        className="absolute right-2 cursor-hand grey3 h2 flex items-center"
                                    ></Icon>
                                )}
                            </label>
                        </div>
                        {filteredTeams.map(team => (
                            <OrgTeam
                                key={team.id}
                                team={team}
                                roles={roles}
                                users={users}
                                canInviteUsers={canInviteUsers(team)}
                                onRename={onRename}
                                onDelete={onDelete}
                                usingSAMLGroupProvisioning={state.usingSAMLGroupProvisioning}
                            />
                        ))}
                    </div>
                )}
                <Modal visible={modalVisible} title="Create Team" onClose={hideModal}>
                    <AddTeam roles={roles} onCancel={hideModal} onSuccess={handleAddedTeam} />
                </Modal>
            </FinalActionModalProvider>
        </SettingsLayout>
    );
};

export default SettingsOrgTeams;
