export interface ErrorContextInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [s: string]: any;
}

export class ContextualError extends Error {
    /**
     *
     * @param {String} name This allows to improve error reports in Bugsnag, and
     *                      prevents all errors to default to the name `Error`.
     */
    constructor(name: string, message: string, readonly context: ErrorContextInterface = {}) {
        super(message);
        this.name = name;
    }
}
