import Host from 'models/hosts/Host';
import HostAttribute, { HostAttributeType } from './HostAttribute';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import Logger from 'services/logger';
import { FC, useEffect, useState } from 'react';
import { getHost } from 'services/api/hosts';
import { useTimeInterval } from 'components/context/TimeInterval';

const logger = Logger.get('AsyncHostAttribute');

const AsyncHostAttribute: FC<{ attribute?: HostAttributeType; className?: string; id: number }> = ({
    attribute = 'name',
    className = '',
    id,
    ...rest
}) => {
    const [host, setHost] = useState<Host | null>(null);
    const [error, setError] = useState(false);
    const { interval } = useTimeInterval();

    useEffect(() => {
        setError(false);

        getHost(interval, id)
            .then(fetchedHost => setHost(fetchedHost))
            .catch(errorMsg => {
                logger.error(errorMsg);

                setError(true);
            });
    }, [id, interval]);

    if (error) {
        return <span className={className}>Host {id}</span>;
    }

    if (!host) {
        return <Loader type={LoaderTypes.TABLECELL} />;
    }

    return <HostAttribute attribute={attribute} className={className} host={host} {...rest} />;
};

export default AsyncHostAttribute;
