import Icon from 'components/icons/Icon';
import React from 'react';

interface ExpandButtonsInterface {
    onToggle: (expanded: boolean) => void;
}

const ExpandButtons: React.FC<ExpandButtonsInterface> = ({ onToggle }) => (
    <div>
        <div className="flex items-center flex-grow-1 full-height overflow-auto">
            <button onClick={() => onToggle(true)} className="link h4 bold flex items-center mr4 capitalize ml2 nowrap">
                <Icon icon="expand_all" className="fz18 mainColor mr1"></Icon>Expand all
            </button>
            <button onClick={() => onToggle(false)} className="link h4 bold flex items-center capitalize pr3 nowrap">
                <Icon icon="expand_all" className="fz18 flip-vertical mainColor mr1"></Icon>Collapse all
            </button>
        </div>
    </div>
);
export default ExpandButtons;
