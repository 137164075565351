import { useRef, useState } from 'react';
import { useDiagramPlugin } from '../diagram';
import type { Diagram } from '../diagram';
import { getPointerSvgPoint } from '../../../helpers/diagram';

export interface UseDiagramPanResult {
    readonly isPanning: boolean;
}

export function useDiagramPan<TNode, TEdge>(diagram: Diagram<TNode, TEdge>): UseDiagramPanResult {
    const animationFrame = useRef<number>(0);
    const [startingPoint, setStartingPoint] = useState<[number, number] | null>(null);
    const isPanning = !!startingPoint;

    function handleStart(e: React.PointerEvent<SVGSVGElement>): void {
        const cursorPoint = getPointerSvgPoint(e);

        setStartingPoint([cursorPoint.x, cursorPoint.y]);
    }

    function handleMove(e: React.PointerEvent<SVGSVGElement>): void {
        if (startingPoint) {
            const [startX, startY] = startingPoint;
            const cursorPoint = getPointerSvgPoint(e);
            window.cancelAnimationFrame(animationFrame.current);
            animationFrame.current = window.requestAnimationFrame(() => {
                const [x, y] = [cursorPoint.x - startX, cursorPoint.y - startY];
                diagram.setViewBox(prev => prev.translate(-x, -y));
            });
        }
    }

    function handleEnd(e: React.PointerEvent<SVGSVGElement>): void {
        if (startingPoint) {
            window.cancelAnimationFrame(animationFrame.current);
            const [startX, startY] = startingPoint;
            const cursorPoint = getPointerSvgPoint(e);
            const [x, y] = [cursorPoint.x - startX, cursorPoint.y - startY];
            diagram.setViewBox(prev => prev.translate(-x, -y));
        }
        setStartingPoint(null);
    }

    useDiagramPlugin(diagram, {
        svgProps: {
            onPointerDown: handleStart,
            onPointerLeave: handleEnd,
            onPointerMove: handleMove,
            onPointerUp: handleEnd,
            style: {
                cursor: isPanning ? 'grabbing' : 'grab',
                touchAction: isPanning ? 'none' : undefined,
                userSelect: isPanning ? 'none' : undefined,
            },
        },
    });

    return {
        isPanning,
    };
}
