import { Route, Switch } from 'react-router-dom';
import Alerts from 'components/modules/app/Alerts';
import Deadlocks from 'components/modules/app/Deadlocks';
import Deadlock from 'components/modules/app/Deadlocks/Deadlock';
import BestPractices from 'components/modules/app/BestPractices';
import Environments from 'components/modules/app/Environments';
import SettingsOverview from 'components/modules/settings/Overview';
import FakeLogout from 'components/development/Logout';
import InvalidEnvRedirect from 'components/development/InvalidEnvRedirect';
import DevelopmentOAuthHelper from 'components/development/OAuth';
import KPISummary from 'components/modules/app/KPISummary';
import Samples from 'components/modules/app/Samples';
import SettingsAuthentication from 'components/modules/settings/Authentication';
import SettingsBilling from 'components/modules/settings/Billing/Billing';
import SettingsLicenses from 'components/modules/settings/Licenses';
import SettingsPeople from 'components/modules/settings/People/People';
import SettingsOrgTeams from 'components/modules/settings/OrgTeams';
import SettingsProfile from 'components/modules/settings/Profile';
import SettingsEmailPreferences from 'components/modules/settings/EmailPreferences';
import SettingsCredentials from 'components/modules/settings/Credentials/Credentials';
import SettingsIntegrations from 'components/modules/settings/Integrations';
import SettingsIntegrationEdit from 'components/modules/settings/IntegrationEdit';
import SettingsEnvTeams from 'components/modules/settings/EnvTeams';
import SettingsAPITokens from 'components/modules/settings/APITokens';
import SettingsQueryData from 'components/modules/settings/QueryData';
import SettingsAnomalyDetection from 'components/modules/settings/AnomalyDetection';
import SettingsEnvPreferences from 'components/modules/settings/Preferences/Preferences';
import Groups from 'components/modules/app/Inventory/Groups/Groups';
import Machines from 'components/modules/app/Inventory/Machines/Machines';
import Agents from 'components/modules/app/Inventory/Agents/Agents';
import Queries from 'components/modules/app/Queries/Queries';
import QueryDetails from 'components/modules/app/Queries/QueryDetails/QueryDetails';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';
import VisualExplainPlan from 'components/modules/app/VisualExplainPlans';
import Profiler from 'components/modules/app/Profiler';
import { securityRoutes } from 'services/routes';

const isLocalBuild = process.env.NODE_ENV === 'development' || process.env.REACT_APP_LOCAL_BUILD;

const AppRouter = () => (
    <>
        <Switch>
            {/* Redirect for local environments to simulate the sf-app behavior. */}
            {isLocalBuild &&
                securityRoutes.map((path: string) => (
                    <Route path={path} key={path}>
                        <InvalidEnvRedirect />
                    </Route>
                ))}
            <Route path="/" exact>
                <Environments />
            </Route>
            <Route path="/:env?/settings/overview" exact>
                <SettingsOverview />
            </Route>
            <Route path="/settings/authentication" exact>
                <SettingsAuthentication />
            </Route>
            <Route path="/settings/billing" exact>
                <SettingsBilling />
            </Route>
            <Route path="/settings/licenses" exact>
                <SettingsLicenses />
            </Route>
            <Route path="/settings/people" exact>
                <SettingsPeople />
            </Route>
            <Route path="/settings/teams" exact>
                <SettingsOrgTeams />
            </Route>

            <Route path="/settings/profile" exact>
                <SettingsProfile />
            </Route>
            <Route path="/settings/email-preferences" exact>
                <SettingsEmailPreferences />
            </Route>

            <Route path="/:env/settings/credentials" exact>
                <SettingsCredentials />
            </Route>
            <Route path="/:env/settings/integrations" exact>
                <SettingsIntegrations />
            </Route>
            <Route path="/:env/settings/integrations/new" exact>
                <SettingsIntegrationEdit />
            </Route>
            <Route path="/:env/settings/integrations/:id" exact>
                <SettingsIntegrationEdit />
            </Route>
            <Route path="/:env/settings/teams" exact>
                <SettingsEnvTeams />
            </Route>
            <Route path="/:env/settings/api-tokens" exact>
                <SettingsAPITokens />
            </Route>
            <Route path="/:env/settings/query-data" exact>
                <SettingsQueryData />
            </Route>
            <Route path="/:env/settings/anomaly-detection" exact>
                <FinalActionModalProvider>
                    <SettingsAnomalyDetection />
                </FinalActionModalProvider>
            </Route>
            <Route path="/:env/settings/preferences" exact>
                <SettingsEnvPreferences />
            </Route>

            {isLocalBuild && (
                <Route path="/logout" exact>
                    <FakeLogout />
                </Route>
            )}

            <Route path="/:env/alerts" exact>
                <Alerts />
            </Route>
            <Route path="/:env/deadlocks" exact>
                <Deadlocks />
            </Route>
            <Route path="/:env/deadlocks/:id/:ts" exact>
                <Deadlock />
            </Route>
            <Route path="/:env/best-practices" exact>
                <BestPractices />
            </Route>
            <Route path="/:env/samples" exact>
                <Samples />
            </Route>
            <Route path="/:env/queries" exact>
                <Queries />
            </Route>
            <Route path="/:env/queries/:id" exact>
                <QueryDetails />
            </Route>
            <Route path="/:env/profiler" exact>
                <Profiler />
            </Route>

            <Route path="/:env/inventory/machines" exact>
                <Machines />
            </Route>

            <Route path="/:env/inventory/groups" exact>
                <Groups />
            </Route>

            <Route path="/:env/inventory/agents" exact>
                <FinalActionModalProvider>
                    <Agents />
                </FinalActionModalProvider>
            </Route>

            <Route path="/:env" exact>
                <KPISummary />
            </Route>

            <Route path="/:env/queries/:query/visual-explain-plan" exact>
                <VisualExplainPlan />
            </Route>

            {isLocalBuild && (
                <Route path="/oauth/services/:provider/request-info">
                    <DevelopmentOAuthHelper />
                </Route>
            )}
        </Switch>
        {isLocalBuild && (
            <Route path="/:env" exact>
                <InvalidEnvRedirect />
            </Route>
        )}
    </>
);

export default AppRouter;
