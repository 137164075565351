import Alert, { AlertTypes } from 'components/messages/Alert';
import APIToken from 'models/APIToken';
import BouncerButton from 'components/form/BouncerButton';
import Logger from 'services/logger';
import { FC, SyntheticEvent, useState } from 'react';
import { createOrgToken } from 'services/api/tokens';
import { getOwnerRole } from 'services/api/roles';

enum OrgTokenGeneratorStatus {
    CREATING = 'creating',
    CREATE_ERROR = 'create-error',
    OPEN = 'open',
    CLOSED = 'closed',
}

interface OrgTokenGeneratorStateInterface {
    status: OrgTokenGeneratorStatus;
    tokenName: string;
}

const logger = Logger.get('OrgTokenGenerator');

const OrgTokenGenerator: FC<{ onCreate: (token: APIToken) => void }> = ({ onCreate }) => {
    const [state, setState] = useState<OrgTokenGeneratorStateInterface>({
        status: OrgTokenGeneratorStatus.CLOSED,
        tokenName: '',
    });
    const { status, tokenName } = state;

    const handleTokenChange = (e: SyntheticEvent<HTMLInputElement>) => {
        setState({ ...state, tokenName: e.currentTarget.value });
    };

    const open = () => {
        setState({ ...state, status: OrgTokenGeneratorStatus.OPEN });
    };

    const cancel = () => {
        setState({ ...state, tokenName: '', status: OrgTokenGeneratorStatus.CLOSED });
    };

    const creating = () => {
        setState({ ...state, status: OrgTokenGeneratorStatus.CREATING });
    };

    const error = () => {
        setState({ ...state, status: OrgTokenGeneratorStatus.CREATE_ERROR });
    };

    const create = async () => {
        creating();

        try {
            const role = await getOwnerRole();

            if (!role) {
                throw new Error('Owner role could not be found.');
            }

            const token = await createOrgToken(tokenName, role);

            if (!token) {
                throw new Error('Error while creating the token.');
            }

            cancel();
            onCreate(token);
        } catch (e) {
            error();
            logger.error(e);
        }
    };

    return (
        <div className="inline-flex my2 mt3">
            {status === OrgTokenGeneratorStatus.CLOSED && (
                <button type="button" className="primary-orange no-decoration" onClick={open}>
                    Create New Token
                </button>
            )}
            {status !== OrgTokenGeneratorStatus.CLOSED && (
                <div className="form form--table-settings__row">
                    <h2 className="h3 mr2 lighter grey3 flex-grow-1">Create new token</h2>

                    <fieldset>
                        <label>
                            <b className="settings__form__label uppercase fz10 spacing inline-flex">Token name:</b>
                            <input
                                type="text"
                                name="tokenName"
                                placeholder="Token name"
                                autoComplete="off"
                                required
                                onChange={handleTokenChange}
                                value={tokenName}
                            />
                        </label>
                    </fieldset>
                    {status === OrgTokenGeneratorStatus.CREATE_ERROR && (
                        <Alert type={AlertTypes.ERROR}>There was an error while creating the token.</Alert>
                    )}
                    <div className="settings__form__actions mt3">
                        <button
                            type="button"
                            className="primary-grey mr2"
                            onClick={cancel}
                            disabled={status === OrgTokenGeneratorStatus.CREATING}
                        >
                            Cancel
                        </button>
                        <BouncerButton
                            type="button"
                            className="primary-orange"
                            onClick={create}
                            bounce={status === OrgTokenGeneratorStatus.CREATING}
                            disabled={!tokenName}
                        >
                            Create
                        </BouncerButton>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrgTokenGenerator;
