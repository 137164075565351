import Icon from 'components/icons/Icon';
import Org from 'models/Org';
import * as React from 'react';
import { orgHomeUrl } from 'services/routes';

function displayHomeUrl(homeUrl: string) {
    const url = new URL(homeUrl);

    return url.origin;
}

const PreviousOrgs: React.FC<{ orgs: Org[]; homeUrl: string; titleClassName?: string }> = ({
    orgs,
    homeUrl,
    titleClassName = 'fz18',
}) => {
    return (
        <>
            <hr className="section-divider full-width" />

            <div className="card-block__login__previous-orgs full-width">
                <h2 className={`${titleClassName} regular my3`}>Choose your organization</h2>

                {orgs.map(org => (
                    <a
                        key={org.id}
                        href={orgHomeUrl(org.nickname, homeUrl)}
                        className="card-block__login__previous-orgs__org flex items-center rounded no-decoration"
                    >
                        <div className="flex-grow-1 mainColor line-height-120 p2">
                            <span className="dark no-decoration bold">{org.name}</span>
                            <div className="card-block__login__previous-orgs__org__link mt1" title={org.name}>
                                {displayHomeUrl(orgHomeUrl(org.nickname, homeUrl))}
                            </div>
                        </div>
                        <Icon icon="keyboard_arrow_right" className="h2 mainColor" />
                    </a>
                ))}
            </div>
        </>
    );
};

export default PreviousOrgs;
