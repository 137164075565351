import * as React from 'react';

interface BSODPropsInterface {
    message: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const BSOD: React.FC<BSODPropsInterface> = ({ message, onClick }) => {
    return (
        <div id="bsod" className="dialog fixed full-height full-width flex items-center justify-center z3 top-0">
            <div className="dialog__backdrop fixed full-height full-width" />
            <div className="dialog__container relative z1">
                <div className="dialog__container__header bg-MainColor flex p3 line-height-120 items-center">
                    <h3 className="h4 white bold flex-grow-1 truncate">Message</h3>
                </div>
                <div className="dialog__container__content bg-grey05">
                    <p className="p3" data-testid="bsod-message">
                        {message}
                    </p>
                    <div className="p3 bg-white right-align">
                        <button className="primary-mainColor" data-testid="bsod-ok" onClick={onClick}>
                            RELOAD
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BSOD;
