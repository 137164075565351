import { AgentVersionResponseDataInterface, AgentVersionInterface } from 'services/api/agentConfig';
import { AgentWindows } from './AgentWindow';

export default class AgentVersion {
    latestStableVersion: AgentVersionInterface;
    latestVersion: AgentVersionInterface;
    newHostPin: string;
    pins: string[] | number[];
    updatewindows: AgentWindows;

    constructor(data: AgentVersionResponseDataInterface) {
        this.latestStableVersion = data.latest_stable_version;
        this.latestVersion = data.latest_version;
        this.newHostPin = data.new_host_pin;
        this.pins = data.pins;
        this.updatewindows = new AgentWindows(data.updatewindows);
    }
}
