import { FC, useState } from 'react';
import FeatureLink from 'components/url/FeatureLink';
import { removeAlert, APIAlertInterface } from 'services/api/envConfig';
import Bouncer from 'components/icons/Bouncer';
import SlideSwitch from 'components/form/SlideSwitch';
import Icon from 'components/icons/Icon';
import IntegrationIcon from 'assets/icons/integrations/integrations.svg';
import { UserHasPermission } from 'components/security/UserHasPermission';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { useFinalActionModal } from 'components/context/FinalActionModal';

const AlertsTableRow: FC<{
    alert: APIAlertInterface;
    type: string;
    integrations: string[];
    onChange: (alert: APIAlertInterface) => void;
    onDeleted?: (alert: APIAlertInterface) => void;
}> = ({ alert, type, integrations, onChange, onDeleted }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const { openModal } = useFinalActionModal();
    const { success, error } = useDisplayMessage();

    const integrationUrl = `${IntegrationIcon}#integration-`;

    const DISABLED = {
        NO: 0,
        YES: 1,
    };

    const handleToggle = () => {
        const disabled = alert.disabled ? DISABLED.NO : DISABLED.YES;
        alert.disabled = disabled;
        onChange({ ...alert, disabled });
    };

    const handleDelete = async () => {
        setIsDeleting(true);

        try {
            await removeAlert(alert);

            setIsDeleting(false);
            if (onDeleted) {
                onDeleted(alert);
            }

            success(`Alert "${alert.name}" has been deleted.`);
        } catch (e) {
            setIsDeleting(false);
            error('There was an error deleting the alert.');
        }
    };

    return (
        <tr key={alert.id}>
            <td>
                <span data-testid="switch">
                    <SlideSwitch name={alert.name} checked={!alert.disabled} onChange={handleToggle} />
                </span>
            </td>
            <td>{alert.name}</td>
            <td>{type}</td>
            <td>
                {integrations.map(i => (
                    <div className="inline-flex mr1 integration__icon" key={i}>
                        <svg focusable="false" width="30px" height="30px">
                            <title>{`${integrations}`}</title>
                            <use href={integrationUrl + i.toLowerCase()} xlinkHref={integrationUrl + i.toLowerCase()} />
                        </svg>
                    </div>
                ))}
            </td>
            <td>
                <div className="flex items-center">
                    <UserHasPermission to="changeEnvSettings">
                        <FeatureLink to={`alerts/${alert.id}`}>
                            <Icon icon="edit" className="grey3 fz27 hoverable"></Icon>
                        </FeatureLink>
                        {isDeleting ? (
                            <Bouncer className="fz27 ml2" />
                        ) : (
                            <Icon
                                className="grey3 fz27 ml3 hoverable"
                                icon="trash"
                                onClick={() =>
                                    openModal({
                                        onSubmit: handleDelete,
                                        confirmMsg: `Are you sure you want to delete this alert?`,
                                        buttonText: 'Yes, delete this alert.',
                                        title: 'Delete this alert?',
                                    })
                                }
                            />
                        )}
                    </UserHasPermission>
                </div>
            </td>
        </tr>
    );
};

export default AlertsTableRow;
