import moment from 'moment-timezone';

function cacheResult<ResultType>(callback: () => Promise<ResultType>, ttl: number) {
    let result: Promise<ResultType>;
    let timestamp: number;

    return function (): Promise<ResultType> {
        const now = moment().unix();

        if (result && now - timestamp < ttl) {
            return result;
        }

        timestamp = moment().unix();
        result = new Promise((resolve, reject) => {
            callback().then(resolve).catch(reject);
        });

        return result;
    };
}

export default cacheResult;
