import SettingsLayout from '../Layout';
import { Tabs, Tab, TabPanel } from 'components/tabs/Tabs';
import MongoCredentials from './MongoCredentials';
import MySQLCredentials from './MySQLCredentials';
import PostgreSQLCredentials from './PostgreSQLCredentials';
import RedisCredentials from './RedisCredentials';
import SQLServerCredentials from './SQLServerCredentials';
import Icon from 'components/icons/Icon';
import ContactLink from 'components/messages/ContactLink';
import Feature from 'components/serviceLevel/Feature';

const SettingsCredentials = () => {
    return (
        <SettingsLayout title="Credentials">
            <div className="flex items-center mt3 mb2" data-testid="credentials-page">
                Please enter the connection credentials our agent should use to connect to your servers. The account
                should have the privileges defined in the documentation.
            </div>

            <Tabs defaultTab="mongo" className="tabs--credentials">
                <div className="tabs">
                    <Tab name="mongo" className="flex p2 dark no-decoration fz16">
                        <div className="flex items-center">
                            <Icon icon="mongo" className="mr2 h2 grey3" />
                            MongoDB
                        </div>
                    </Tab>
                    <Tab name="mysql" className="flex p2 dark no-decoration fz16">
                        <div className="flex items-center">
                            <Icon icon="mysql" className="mr2 h2 grey3" />
                            MySQL
                        </div>
                    </Tab>
                    <Tab name="pgsql" className="flex p2 dark no-decoration fz16">
                        <div className="flex items-center">
                            <Icon icon="pgsql" className="mr2 h2 grey3" />
                            PostgreSQL
                        </div>
                    </Tab>
                    <Tab name="redis" className="flex p2 dark no-decoration fz16">
                        <div className="flex items-center">
                            <Icon icon="redis" className="mr2 h2 grey3" />
                            Redis
                        </div>
                    </Tab>
                    <Feature name="mssql">
                        <Tab name="mssql" className="flex p2 dark no-decoration fz16">
                            <div className="flex items-center">
                                <Icon icon="mssql" className="mr2 h2 grey3" />
                                SQL Server
                            </div>
                        </Tab>
                    </Feature>
                </div>
                <div className="full-width">
                    <TabPanel name="mongo">
                        <MongoCredentials />
                    </TabPanel>
                    <TabPanel name="mysql">
                        <MySQLCredentials />
                    </TabPanel>
                    <TabPanel name="pgsql">
                        <PostgreSQLCredentials />
                    </TabPanel>
                    <TabPanel name="redis">
                        <RedisCredentials />
                    </TabPanel>
                    <Feature name="mssql">
                        <TabPanel name="mssql">
                            <SQLServerCredentials />
                        </TabPanel>
                    </Feature>
                </div>
            </Tabs>
            <div className="mt2">
                You can find more help in our support website. Questions or problems?{' '}
                <ContactLink>Contact us</ContactLink> and we'll help you.
            </div>
        </SettingsLayout>
    );
};

export default SettingsCredentials;
