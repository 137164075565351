import TimeAgo from 'components/format/TimeAgo';
import Timestamp from 'components/format/Timestamp';
import Icon from 'components/icons/Icon';
import Host from 'models/hosts/Host';
import * as React from 'react';
import { userIsAllowedTo } from 'services/security/accessControl';
import HostIcon from 'components/icons/HostIcon';
import HostTags from '../HostTags';
import EditableInput from 'components/editable/EditableInput';
import useHostAttributeUpdate from 'hooks/hosts/useHostAttributeUpdate';
import EditSettingsModal from '../EditSettingsModal';
import useDisplayMessage from 'hooks/useDisplayMessage';

const NodeHostRow: React.FC<{
    host: Host;
    className?: string;
    onHostDeletion?: (host: Host) => void;
}> = ({ host, className = '', onHostDeletion = () => {} }) => {
    const updateHostName = useHostAttributeUpdate('name');
    const updateHostDescription = useHostAttributeUpdate('description');
    const [isEditingHost, setIsEditingHost] = React.useState(false);

    const { success, error } = useDisplayMessage();

    const handleHostNameChange = async (newName: string) => {
        updateHostName(host, newName);
    };

    const handleHostDescChange = async (newDesc: string) => {
        updateHostDescription(host, newDesc);
    };

    const handleHostDeleteSuccess = async (deletedHost: Host) => {
        setIsEditingHost(false);
        success(`The host ${deletedHost.name} has been successfully deleted`);
        onHostDeletion(deletedHost);
    };

    return (
        <tr className={className} data-testid={host.cluster ? 'node-host' : 'host-no-group'}>
            <td title={`id=${host.id}`}></td>
            <td className="vc-table--machines__host" title={host.type}>
                <HostIcon host={host} />
            </td>
            <td className="vc-table--machines__name py2">
                <div title={host.name} className="dark regular vc-table--machines__name__host  editable-input relative">
                    <EditableInput value={host.name} onChange={handleHostNameChange} />
                </div>

                <div
                    title={host.description}
                    className="monospace fz13 mt1 grey3 vc-table--machines__name__description line-height-120  editable-input relative"
                >
                    <EditableInput value={host.description} onChange={handleHostDescChange} />
                </div>

                {host.roleTags && (
                    <div>
                        <ul className="list-reset">
                            {host.roleTags.map(roleTag => (
                                <li
                                    key={roleTag}
                                    className="mt1 mr1 border border-color-grey-2 rounded p1 inline-flex items-center fz12 monospace grey3 capitalize"
                                >
                                    {roleTag}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {host.deleted > 0 && (
                    <div className="grey3 fz13 mt2 inline-flex items-center">
                        <Icon icon="info" className="grey2 mr2 inline-flex fz18" />
                        <span className="delete-tag">Deleted </span>
                        <span>
                            on <Timestamp date={host.deleted} />
                        </span>
                    </div>
                )}
            </td>
            <td className="server-sparkline servers-table__sparkline"></td>
            {host.parent && (
                <td className="vc-table--machines__host" title="parent">
                    <HostIcon host={host.parent} />
                </td>
            )}
            {host.parent && (
                <td className="vc-table--machines__name py2">
                    <div title={host.parent.name} className="dark regular vc-table--machines__name__host">
                        {host.parent.name}
                    </div>
                    <div className="monospace fz13 mt1 grey3 vc-table--machines__name__description line-height-120">
                        {host.parent.description}
                    </div>{' '}
                    {host.parent.deleted > 0 && (
                        <div className="grey3 mt2 fz13 inline-flex items-center">
                            {' '}
                            <Icon icon="info" className="grey2 mr2 inline-flex fz18" />
                            <span className="delete-tag">Deleted </span>
                            <span>
                                on <Timestamp date={host.parent.deleted} />
                            </span>
                        </div>
                    )}
                </td>
            )}
            <td className="nodes--last-seen servers-table__lastseen">
                <span className="dark monospace spacing fz13 line-height-120 nowrap">
                    <TimeAgo date={host.lastSeen} />
                </span>
                {host.lastSeen !== 0 && (
                    <div className="dark monospace spacing fz13 line-height-120 servers-table__lastseen__date">
                        <Timestamp date={host.lastSeen} />
                    </div>
                )}
            </td>
            <td className="vc-table--machines__tags">
                <HostTags tags={host.tags} />
            </td>
            {userIsAllowedTo('changeEnvSettings') && (
                <td title="Edit or delete this server">
                    <div className="flex justify-center" onClick={() => setIsEditingHost(true)}>
                        <Icon icon="settings" className="grey3 h2 hoverable"></Icon>
                    </div>

                    <EditSettingsModal
                        host={host}
                        visible={isEditingHost}
                        onClose={() => {
                            setIsEditingHost(false);
                        }}
                        onSuccess={selectedHost => {
                            success(`The host ${selectedHost.name} has been successfully updated`);
                            setIsEditingHost(false);
                        }}
                        onError={selectedHost => {
                            error(`The host ${selectedHost.name} could not be updated. Please try again later.`);
                            setIsEditingHost(false);
                        }}
                        onDeleteSuccess={handleHostDeleteSuccess}
                        onDeleteError={selectedHost => {
                            error(`The host ${selectedHost.name} could not be deleted. Please try again later.`);
                            setIsEditingHost(false);
                        }}
                    />
                </td>
            )}
        </tr>
    );
};

export default NodeHostRow;
