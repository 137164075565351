import Icon from 'components/icons/Icon';
import { FC } from 'react';

export enum LoaderTypes {
    TABLECELL = 'tableCell',
    PARAGRAPH = 'paragraph',
    VISUALIZATION = 'visualization',
}

interface LoaderPropsInterface {
    type: LoaderTypes;
    className?: string;
}

const Loader: FC<LoaderPropsInterface> = ({ type, className = '' }) => {
    return (
        <div>
            <div
                className={`loader loader--${type} ${className}`}
                data-testid={`loader-${type.toLowerCase()}-component`}
            >
                {type === LoaderTypes.VISUALIZATION && (
                    <Icon icon="visualization-loader" className="white h1 loader--visualization__icon" />
                )}
            </div>
            {type === LoaderTypes.PARAGRAPH && (
                <ul>
                    <li className="loader loader--paragraph mt2" />
                    <li className="loader loader--paragraph mt2" />
                </ul>
            )}
        </div>
    );
};

export const TableCellLoader: FC<Omit<LoaderPropsInterface, 'type'>> = ({ className = '' }) => (
    <Loader type={LoaderTypes.TABLECELL} className={className} />
);

export default Loader;
