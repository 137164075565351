import type { DiagramEdge, DiagramLayoutFunction, DiagramNode } from '../types';
import { getAngle, getPointOnRectangle, ViewBox } from '../../../helpers/diagram';

export interface CircularLayoutOptions {
    edgeEnd?: 'border' | 'center';
    nodeHeight: number;
    nodeSpacing: number;
    nodeWidth: number;
}

export function makeCircularLayout<TNode, TEdge>({
    nodeWidth,
    nodeHeight,
    nodeSpacing,
    edgeEnd = 'center',
}: CircularLayoutOptions): DiagramLayoutFunction<TNode, TEdge> {
    return (nodes, edges) => {
        const orderedNodes: TNode[] = [];
        const nodeQueue = [...nodes];
        let currentNode: TNode | null = nodeQueue.shift() ?? null;
        while (currentNode) {
            while (currentNode && !orderedNodes.includes(currentNode)) {
                orderedNodes.push(currentNode);
                const nodeToFind: TNode = currentNode;
                currentNode = edges.find(x => x.source === nodeToFind)?.target ?? null;
            }
            currentNode = nodeQueue.shift() ?? null;
        }

        const circumference = orderedNodes.length * nodeSpacing;
        const radius = circumference / (2 * Math.PI);
        const diameter = radius * 2;
        const nodeCoordinates = orderedNodes.map<DiagramNode<TNode>>((data, i) => {
            // element placement orderedNodes
            const angle = (i / (orderedNodes.length / 2)) * -Math.PI - Math.PI / 2;
            // element x position
            const x = radius * Math.cos(angle) + diameter / 2;
            // element y position
            const y = radius * Math.sin(angle) + diameter / 2;
            return {
                node: data,
                x,
                y,
            };
        });

        const edgeCoordinates = edges.map<DiagramEdge<TNode, TEdge>>(edge => {
            const source = nodeCoordinates.find(x => x.node === edge.source);
            const target = nodeCoordinates.find(x => x.node === edge.target);
            if (!source || !target) {
                throw new Error('Edge source and target must exist in node array.');
            }

            if (edgeEnd === 'border') {
                const angle = getAngle([source.x, source.y], [target.x, target.y]);
                const [x1, y1] = getPointOnRectangle(angle, nodeWidth, nodeHeight, [
                    source.x + nodeWidth / 2,
                    source.y + nodeHeight / 2,
                ]);
                const [x2, y2] = getPointOnRectangle(Math.PI + angle, nodeWidth, nodeHeight, [
                    target.x + nodeWidth / 2,
                    target.y + nodeHeight / 2,
                ]);
                return {
                    ...edge,
                    // Shift x and y to the border of the node
                    x1,
                    x2,
                    y1,
                    y2,
                };
            } else {
                return {
                    ...edge,
                    // Shift x and y to the center of the node
                    x1: source.x + nodeWidth / 2,
                    x2: target.x + nodeWidth / 2,
                    y1: source.y + nodeHeight / 2,
                    y2: target.y + nodeHeight / 2,
                };
            }
        });

        const viewBox = new ViewBox(
            -nodeSpacing / 2,
            -nodeSpacing / 2,
            Math.max(0, ...nodeCoordinates.map(x => x.x)) -
                Math.min(0, ...nodeCoordinates.map(x => x.x)) +
                nodeSpacing +
                nodeWidth,
            Math.max(0, ...nodeCoordinates.map(x => x.y)) -
                Math.min(0, ...nodeCoordinates.map(x => x.y)) +
                nodeSpacing +
                nodeHeight
        );

        return {
            edges: edgeCoordinates,
            nodes: nodeCoordinates,
            viewBox,
        };
    };
}
