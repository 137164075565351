/* eslint-disable @typescript-eslint/naming-convention */
// Topic to display in the in-app docs

interface TopicInterface {
    name: string;
    url: string;
}

const topics: { [s: string]: TopicInterface } = {
    pages: {
        name: 'Pages in Vividcortex',
        url: 'https://docs.vividcortex.com/topics/pages-in-vividcortex/index.json',
    },
    events: {
        name: 'Events and Alerts',
        url: 'https://docs.vividcortex.com/topics/events-and-alerts/index.json',
    },
    installation: {
        name: 'Installation',
        url: 'https://docs.vividcortex.com/topics/installation/index.json',
    },
    queries: {
        name: 'Query Samples',
        url: 'https://docs.vividcortex.com/topics/query-samples/index.json',
    },
    users: {
        name: 'Users and Teams',
        url: 'https://docs.vividcortex.com/topics/users-and-teams/index.json',
    },
    settings: {
        name: 'Settings',
        url: 'https://docs.vividcortex.com/topics/settings/index.json',
    },
};

const dictionary: { [s: string]: { path: string; name: string; topic?: TopicInterface } } = {
    install: {
        path: 'getting-started/managing-hosts/',
        name: 'Install hosts',
    },

    hosts: {
        path: 'how-to-use-vividcortex/host-list/',
        name: 'Hosts List',
    },

    'hosts-filter': {
        path: 'how-to-use-vividcortex/host-filter/',
        name: 'Hosts Filter',
    },

    performance_schema: {
        path: 'getting-started/off-host-installation/#enabling-performance-schema-on-mysql',
        name: 'Performance Schema',
    },

    'privileges-required-for-mysql': {
        path: 'getting-started/privileges/#privileges-required-for-mysql',
        name: 'Privileges required for MySQL',
    },

    privileges: {
        path: 'getting-started/privileges/',
        name: 'Privileges',
    },

    'privileges-required-for-postgresql': {
        path: 'getting-started/privileges/#privileges-required-for-postgresql',
        name: 'Privileges required for PostgreSQL',
    },

    pgScriptedInstallation: {
        path: 'getting-started/privileges/#recommended-scripted-postgresql-installation',
        name: 'Monitoring scripted',
    },

    'privileges-required-for-mongodb': {
        path: 'getting-started/privileges/#privileges-required-for-mongodb',
        name: 'Privileges required for MongoDB',
    },

    events: {
        path: 'how-to-use-vividcortex/events-dashboard/',
        name: 'Events',
        topic: topics.pages,
    },

    environments: {
        path: 'general-reference/environments-team/',
        name: 'Environments',
        topic: topics.settings,
    },

    'event-type': {
        path: 'general-reference/event-types/',
        name: 'Event Types',
        topic: topics.pages,
    },

    'change-markers': {
        path: 'how-to-use-vividcortex/events-on-charts/',
        name: 'Change Markers',
    },

    'off-host-installation': {
        path: 'getting-started/off-host-installation/#enabling-pg-stat-statements-on-postgresql/',
        name: 'Off host Installation',
        topic: topics.installation,
    },

    agents: {
        path: 'how-to-use-vividcortex/host-list/#agents-dashboard',
        name: 'Agents',
    },

    profiler: {
        path: 'how-to-use-vividcortex/profiler/',
        name: 'Profiler',
        topic: topics.pages,
    },

    'compare-queries': {
        path: 'how-to-use-vividcortex/compare-queries/',
        name: 'Compare Queries',
        topic: topics.queries,
    },

    faults: {
        path: 'how-to-use-vividcortex/faults/',
        name: 'Adaptive Fault Detection',
        topic: topics.pages,
    },

    samplesConfiguration: {
        path: 'general-reference/samples/#configuring-capture-of-query-samples',
        name: 'Configure Capture of Query Samples',
        topic: topics.queries,
    },

    credentials: {
        path: 'getting-started/configuring/#connection-credentials',
        name: 'Connection Credentials',
        topic: topics.installation,
    },

    'host-filter': {
        path: 'how-to-use-vividcortex/host-filter/',
        name: 'Host Filter',
    },

    queries: {
        path: 'how-to-use-vividcortex/queries/',
        name: 'Queries Dashboard',
        topic: topics.pages,
    },

    'queries-notifications': {
        path: 'how-to-use-vividcortex/queries/#viewing-query-samples',
        name: 'Queries Notifications',
        topic: topics.queries,
    },

    metrics: {
        path: 'how-to-use-vividcortex/metrics/',
        name: 'Metrics Dashboard',
        topic: topics.pages,
    },

    'sample-warnings': {
        path: 'how-to-use-vividcortex/queries/#viewing-query-notifications',
        name: 'Sample Warnings',
        topic: topics.queries,
    },

    integrations: {
        path: 'how-to-use-vividcortex/integrations/',
        name: 'Integrations',
    },

    alerts: {
        path: 'how-to-use-vividcortex/alerts/',
        name: 'Alerts',
        topic: topics.events,
    },

    'alerts-configuration': {
        path: 'how-to-use-vividcortex/alerts/#basic-configuration',
        name: 'Alerts',
        topic: topics.events,
    },

    authentication: {
        path: 'general-reference/authentication/',
        name: 'Authentication',
        topic: topics.users,
    },

    authenticationDefault: {
        path: 'general-reference/authentication/#default',
        name: 'Default authentication',
        topic: topics.users,
    },

    authenticationOAuthRequired: {
        path: 'general-reference/authentication/#require-oauth',
        name: 'required OAuth authentication',
        topic: topics.users,
    },

    authenticationSaml: {
        path: 'general-reference/authentication/#saml-sso',
        name: 'SAML authentication',
        topic: topics.users,
    },

    authenticationConfig: {
        path: 'general-reference/sso/',
        name: 'Single Sign-On Configuration',
        topic: topics.users,
    },

    environmentPreferences: {
        path: 'general-reference/environment-preferences/',
        name: 'Environment Preferences',
        topic: topics.settings,
    },

    profilerCompareMoreRows: {
        path: 'how-to-use-vividcortex/profiler/#rows',
        name: 'Number of rows in Profiler when comparing can be greater than the limit',
    },

    charts: {
        path: 'how-to-use-vividcortex/graphs/',
        name: 'Charts',
        topic: topics.pages,
    },

    sso: {
        path: 'general-reference/sso/#single-sign-on',
        name: 'Single Sign-On',
        topic: topics.users,
    },

    'best-practices': {
        path: 'how-to-use-vividcortex/best-practices/',
        name: 'Best Practices',
        topic: topics.pages,
    },

    'no-sample-text': {
        path: 'getting-started/troubleshooting/#why-am-i-not-seeing-query-samples',
        name: 'Why is there no query text for this sample',
        topic: topics.queries,
    },

    samples: {
        path: 'how-to-use-vividcortex/samples-explorer/',
        name: 'Samples Explorer',
        topic: topics.pages,
    },

    dashboards: {
        path: 'how-to-use-vividcortex/graphs/',
        name: 'Dashboards',
        topic: topics.pages,
    },

    notebooks: {
        path: 'how-to-use-vividcortex/notebooks/',
        name: 'Notebooks',
        topic: topics.pages,
    },

    'explorer-filtering': {
        path: 'how-to-use-vividcortex/explorer/#viewing-query-other-data',
        name: 'Explorer Filtering',
        topic: topics.pages,
    },

    explorer: {
        path: 'how-to-use-vividcortex/explorer/',
        name: 'Explorer',
    },

    summary: {
        path: 'how-to-use-vividcortex/summary/',
        name: 'Summary',
    },

    'pre-built-dashboards': {
        path: 'how-to-use-vividcortex/graphs/#pre-built-dashboards',
        name: 'Pre-Built Dashboards',
    },
};

export default dictionary;
