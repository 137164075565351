import APIRequest from './Request';
import { get as httpGet, patch, HttpError } from 'services/http';
import Logger from 'services/logger';
import User, { UserDataInterface } from 'models/User';
import { get as getContext } from 'services/context';
import { AuthenticatedBootstrap } from 'models/Bootstrap';

export enum UserRoles {
    OWNER = 'Owner',
    MEMBER = 'Member',
    CURRENT = 'You',
}

export interface UserToAddInterface {
    name: string;
    email: string;
    teams: number[];
}

export interface UserAddedError {
    user: UserToAddInterface;
    code: number;
    message: string;
}

const logger = Logger.get('users');

function isOrgOwner(user: User) {
    const context = getContext() as AuthenticatedBootstrap;
    return user.id === context.org.user;
}

function isLoggedUser(user: User) {
    const context = getContext() as AuthenticatedBootstrap;
    return user.id === context.user.id;
}

export function getRole(user: User) {
    if (isOrgOwner(user)) {
        return UserRoles.OWNER;
    }
    if (isLoggedUser(user)) {
        return UserRoles.CURRENT;
    }

    return user.status;
}

export async function get() {
    logger.info('getting the users');

    const response = await new APIRequest<UserDataInterface[]>({
        path: 'users',
    }).request();

    const data = response.data.map(responseData => new User(responseData));
    return data.sort((a, b) => a.name.localeCompare(b.name));
}

export async function add(users: UserToAddInterface[]) {
    logger.info('adding users');
    const response = await new APIRequest<UserDataInterface[], UserToAddInterface[]>({
        path: 'users',
        method: 'post',
        params: users,
    }).request();
    return { data: response.data.map(data => new User(data)), errors: response.errors };
}

export async function remove(user: User) {
    logger.info('removing a user');

    if (isOrgOwner(user)) {
        throw new Error(
            `${user.name} cannot be removed because it's the owner of your account. If you need to transfer the ownership of your account, please contact us.`
        );
    }

    if (isLoggedUser(user)) {
        throw new Error("You can't remove yourself from the team. Please contact us for help.");
    }

    try {
        await new APIRequest<boolean>({
            path: `users/${user.id}`,
            method: 'delete',
        }).request();
    } catch (e) {
        throw new Error(`There was an error removing the user ${user.name}. Please try again later`);
    }
}

export async function invite(user: User) {
    logger.info('inviting a user');

    return new APIRequest<boolean>({
        path: `users/${user.id}/invite`,
        method: 'post',
    }).request();
}

interface UpdateUserErrorInterface {
    errors: string[] | UpdateUserValidationError;
}

interface UpdateUserValidationError {
    0: string;
    length: undefined;
}

const DEFAULT_UPDATE_ERROR = 'The request failed, please try again in a few seconds or contact us.';

function parseUpdateError(response: UpdateUserErrorInterface) {
    if (!response.errors?.length && response.errors['0']) {
        return response.errors['0'].replace('name: ', '');
    }

    if (response.errors.length) {
        const error = response.errors.reduce((message, errorMessage) => {
            return `${message} ${errorMessage.replace(/(current|new): /, '')}`;
        }, '');

        return error.trim() || DEFAULT_UPDATE_ERROR;
    }

    return DEFAULT_UPDATE_ERROR;
}

interface UpdateUserDataInterface {
    id: number;
    name: string;
    email: string;
    status: string;
}

interface UpdateUserPasswordDataInterface {
    password: {
        new: string;
        current: string;
    };
}

export async function update(data: UpdateUserDataInterface | UpdateUserPasswordDataInterface) {
    const { urls } = getContext() as AuthenticatedBootstrap;

    try {
        await patch(urls.user, data);

        return true;
    } catch (e) {
        if (e instanceof HttpError && e.response?.data?.errors) {
            throw new Error(parseUpdateError(e.response.data));
        }

        throw new Error(DEFAULT_UPDATE_ERROR);
    }
}

const symbolsRegex = /[@!?#$%^&*+=<>(){}[\]]/;

export function validateName(name: string) {
    return !name.match(symbolsRegex);
}

export interface UserActivityDataInterface {
    ip: string;
    ts: number;
}

export async function getActivity(): Promise<UserActivityDataInterface[]> {
    const { urls } = getContext() as AuthenticatedBootstrap;

    try {
        const response = await httpGet<{ data: UserActivityDataInterface[] }>(urls.userActivity);

        return response?.data?.data;
    } catch (e) {
        logger.error(e);

        throw new Error('The request failed');
    }
}
