/* eslint-disable @typescript-eslint/naming-convention */
import APIRequest from './Request';
import Logger from 'services/logger';
import AgentVersion from 'models/AgentVersion';
import { AgentWindows } from 'models/AgentWindow';

const logger = Logger.get('agentConfig');

export interface AgentVersionInterface {
    version: string;
    created: number;
}

export interface DayWindow {
    day_start: number;
    day_end: number;
}

export interface TimeWindow {
    minute_start: number;
    minute_end: number;
}

export interface AgentWindowInterface extends DayWindow, TimeWindow {
    comment: string;
}

export interface AgentVersionResponseDataInterface {
    latest_stable_version: AgentVersionInterface;
    latest_version: AgentVersionInterface;
    new_host_pin: string;
    pins: string[] | number[];
    updatewindows: AgentWindowInterface[];
}

export interface AgentConfigDataInterface {
    [key: string]: string;
}

export interface AgentConfigSavingInterface {
    [key: string]: string | boolean | null;
}

export async function get() {
    logger.info('getting agent config data');

    const response = await new APIRequest<AgentConfigDataInterface>({
        path: `hosts/0/agents/all/config`,
    }).request();

    return response.data;
}

export async function save(settings: AgentConfigSavingInterface) {
    logger.info('saving destination');

    const response = await new APIRequest({
        path: `hosts/0/agents/all/config`,
        method: `put`,
        params: { ...settings },
    }).request();

    return response.data;
}

export async function getVersion() {
    logger.info('getting agent version data');

    const response = await new APIRequest<AgentVersionResponseDataInterface>({
        path: 'config/agents/version',
    }).request();

    return new AgentVersion(response.data);
}

export async function updateWindows(windows: AgentWindows) {
    logger.info('updating agent update windows data');

    return await new APIRequest<AgentVersionResponseDataInterface>({
        path: 'config/agents/update-windows',
        method: 'post',
        params: { updatewindows: windows.toRawFormat() },
    }).request();
}
