import { Link } from 'components/url/Link';
import SettingsLayout from '../Layout';
import IntegrationsTable from './Table';
import { userIsAllowedTo } from 'services/security/accessControl';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';

const SettingsIntegrations = () => {
    const button = (
        <Link to="settings/integrations/new">
            <button className="primary-orange">Create New Integration</button>
        </Link>
    );

    return (
        <SettingsLayout title="Integrations" headerContent={userIsAllowedTo('changeEnvSettings') && button}>
            <FinalActionModalProvider>
                <div className="relative settings__form--integrations__content-wrap">
                    <p className="h4 lighter mt4 mb3" data-testid="integrations-intro-text">
                        Database Performance Monitor integrates with your favorite applications to help you and your
                        team be more awesome.
                    </p>

                    <IntegrationsTable />
                </div>
            </FinalActionModalProvider>
        </SettingsLayout>
    );
};
export default SettingsIntegrations;
