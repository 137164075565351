import APIError from 'services/api/error/Error';
import { FC, useState } from 'react';
import Bouncer from 'components/icons/Bouncer';
import APIToken, { APIRotationTokenInterface, APIDeprecatedTokenInterface } from 'models/APIToken';
import Icon from 'components/icons/Icon';
import Timestamp from 'components/format/Timestamp';
import { removeEnvToken, rotate } from 'services/api/tokens';
import Logger from 'services/logger';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import APITokenRotation from './Rotation';
import BouncerButton from 'components/form/BouncerButton';
import { UserHasPermission } from 'components/security/UserHasPermission';
import APITokenHash from './Hash';

const logger = Logger.get('APITokensRow');

const APITokensRow: FC<{
    token: APIToken;
    onDeleted: (token: APIToken | APIDeprecatedTokenInterface | APIRotationTokenInterface) => void;
    onFinalizeRotation: (token: APIToken) => void;
    onRotation: (tokens: APIToken[]) => void;
    onDismissHash: (token: APIToken) => void;
}> = ({ token, onDeleted, onRotation, onFinalizeRotation, onDismissHash }) => {
    const [rotationClicked, setRotationClicked] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const { success, error } = useDisplayMessage();
    const { openModal } = useFinalActionModal();

    const handleDeleteToken = async () => {
        setIsDeleting(true);

        try {
            await removeEnvToken(token);
            setIsDeleting(false);
            if (onDeleted) {
                onDeleted(token);
            }
        } catch (e) {
            setIsDeleting(false);

            logger.error(`Error trying to delete env token ${token.id}`);

            error('There was an error when trying to delete an API token. Please retry or contact us.');
        }
    };

    const handleRotateToken = async () => {
        try {
            setRotationClicked(true);
            const tokens = await rotate(token);
            setRotationClicked(false);
            if (tokens) {
                onRotation(tokens);
            }
        } catch (e) {
            setRotationClicked(false);
            error('Oops! Something went wrong when trying to rotate that token. Please try again, or contact us.');
        }
    };

    const handleRevokedToken = (revokedToken: APIRotationTokenInterface | APIDeprecatedTokenInterface) => {
        openModal({
            onSubmit: async () => {
                try {
                    await removeEnvToken(revokedToken);
                    success(`The token ${revokedToken.name} was revoked`);
                    if (onDeleted) {
                        onDeleted(revokedToken);
                    }
                } catch (e) {
                    const message =
                        e instanceof APIError && e.response.status === 403
                            ? `You don't have permission to revoke the token ${revokedToken.name}`
                            : `Something went wrong while revoking token ${revokedToken.name}`;

                    error(message);
                }
            },
            confirmMsg: `You are about to revoke the "${revokedToken.name}" token.`,
            buttonText: 'Delete this token',
            title: 'Revoke API token',
        });
    };

    /**
     * Decide if we should show or not the rotation panel for a token
     * In the case of agent tokens, this requires the existence of either deprecated tokens or a TRT
     */
    const isRotating = () =>
        (token.deprecatedTokens && token.deprecatedTokens.length > 0) || token.rotationToken !== undefined;

    return (
        <>
            <tr
                key={token.id}
                className={
                    token.deprecatedTokens?.length || token.hash
                        ? 'bg-grey0 vc-table--settings--api-tokens__superseded-row'
                        : ''
                }
                data-testid="token-row"
            >
                <td className="vc-table--settings--api-tokens__name nowrap">{token.name}</td>
                <td data-testid="token-type">{token.type}</td>
                <td className="vc-table--settings--api-tokens__expiration" data-testid="expiration-date">
                    {token.expires ? <Timestamp date={token.expires} /> : 'Never'}
                </td>
                <td>{token.role.name}</td>
                <td className="vc-table--settings--api-tokens__created">
                    {token.created ? <Timestamp date={token.created} /> : 'Unknown'}
                </td>
                <td className="vc-table--settings--api-tokens__last-used">
                    {token.lastUsed ? <Timestamp date={token.lastUsed} /> : 'Unknown'}
                </td>
                <td className="center">
                    <div className="flex items-center justify-between">
                        <UserHasPermission to="createToken">
                            <BouncerButton
                                className="primary-orange mr3 nowrap vc-table--settings--api-tokens__actions__button"
                                type="button"
                                disabled={rotationClicked}
                                bounce={rotationClicked}
                                bouncerClassName="white inline-flex ml2"
                                onClick={() => {
                                    openModal({
                                        onSubmit: () => {
                                            handleRotateToken();
                                        },
                                        confirmMsg: `You are about to rotate the "${token.name}" token.`,
                                        buttonText: 'Rotate this token',
                                        title: 'Rotate API Token',
                                    });
                                }}
                            >
                                Rotate
                            </BouncerButton>
                        </UserHasPermission>
                        {isDeleting && <Bouncer />}
                        {!isDeleting && !(token.type === 'agent') && (
                            <Icon
                                icon="trash"
                                className="grey3 h2 hoverable"
                                onClick={() =>
                                    openModal({
                                        onSubmit: () => {
                                            handleDeleteToken();
                                        },
                                        confirmMsg: `You are about to delete the "${token.name}" token.`,
                                        buttonText: 'Delete this token',
                                        title: 'Delete API Token',
                                    })
                                }
                            />
                        )}
                    </div>
                </td>
            </tr>

            {isRotating() && (
                <tr data-testid="rotating-token-row">
                    <td className="table-token__rotation" colSpan={7}>
                        <APITokenRotation
                            token={token}
                            onRevoke={handleRevokedToken}
                            onFinalizeRotation={onFinalizeRotation}
                            onDismissHash={onDismissHash}
                        ></APITokenRotation>
                    </td>
                </tr>
            )}

            {token.hash && !token.deprecatedTokens?.length && (
                <tr className="bg-grey0 vc-table--settings--api-tokens__new-token-hash">
                    <td className="vc-table--table-token__rotation__deprecated__label">
                        <b className="uppercase fz10 spacing bold nowrap flex items-center ml2">New Token</b>
                    </td>
                    <td className="table-token__token" colSpan={5}>
                        <APITokenHash token={token} onDismiss={onDismissHash} />
                    </td>
                    <td className="vc-table--settings--api-tokens__actions"></td>
                </tr>
            )}
        </>
    );
};

export default APITokensRow;
