import { FC, useState, useMemo } from 'react';
import User from 'models/User';
import UserRow from './Row';
import AddToTeamsDropdown from './AddToTeamsDropdown';
import Team from 'models/Team';

const PeopleList: FC<{
    people: User[];
    onDeleted: (users: User[]) => void;
    teams: Team[];
    usingSSO: boolean;
    loadingTeams: boolean;
}> = ({ people, onDeleted, teams, usingSSO, loadingTeams }) => {
    const [searchText, setSearchText] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

    const handleUnselectUsers = () => {
        setSelectedUsers([]);
    };

    const handleSelectUser = (user: User, selected: boolean) => {
        if (selected) {
            setSelectedUsers([...selectedUsers, user]);
        } else {
            setSelectedUsers(selectedUsers.filter(selectedUser => selectedUser.id !== user.id));
        }
    };

    const filteredUsers = useMemo(() => {
        if (searchText) {
            return people.filter(
                user =>
                    user.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchText.toLowerCase())
            );
        } else {
            return people;
        }
    }, [people, searchText]);

    const handleSearchInputChange = (text: string) => {
        setSearchText(text);
    };

    return (
        <div data-testid="people-list" className="full-width">
            <div className="flex flex-row">
                <div className="flex-grow-1 self-end">
                    <input
                        type="text"
                        value={searchText}
                        placeholder="Find a member..."
                        onChange={e => handleSearchInputChange(e.target.value)}
                        className="mb3 members-table__find"
                    />
                </div>
                <div className="mb3">
                    <div className={`mt2 ${!selectedUsers.length ? 'dropdown-disabled' : ''}`}>
                        <AddToTeamsDropdown
                            selectedUsers={selectedUsers}
                            teams={teams}
                            onDeleted={onDeleted}
                            onUnselect={handleUnselectUsers}
                        />
                    </div>
                </div>
            </div>
            <div className="table-scrollhint">
                <div className="overflow-auto relative z1">
                    <table className="vc-table vc-table--settings full-width vc-table--people full-width">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Teams</th>
                                <th>{/* Actions */}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map(user => (
                                <UserRow
                                    key={user.email}
                                    user={user}
                                    onDeleted={onDeleted}
                                    isSelected={
                                        selectedUsers.find(selectedUser => selectedUser.id === user.id) ? true : false
                                    }
                                    onSelectUser={handleSelectUser}
                                    usingSSO={usingSSO}
                                    loadingTeams={loadingTeams}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PeopleList;
