import Icon from 'components/icons/Icon';
import * as React from 'react';
import { AuthConfigDataInterface } from 'services/settings/authentication';

interface GitHubConfigurationPropsInterface {
    config: AuthConfigDataInterface;
    onChange: (e: React.SyntheticEvent<HTMLSelectElement>) => void;
    gitHubOrgs: GitHubOrgInterface[];
    setupOAuth: () => void;
}

export interface GitHubOrgInterface {
    name: string;
}

const GitHubConfiguration: React.FC<GitHubConfigurationPropsInterface> = ({
    config,
    onChange,
    gitHubOrgs = [],
    setupOAuth,
}) => {
    return (
        <>
            <div className="mt3" data-testid="github-provider-setup">
                {gitHubOrgs.length > 0 && (
                    <div data-testid="github-provider-orgs">
                        <label className="vc-select">
                            <span className="mr2 fz12 uppercase bold">
                                Require users to belong to the GitHub organization:
                            </span>
                            <select
                                name="auth.oauth.github.organization"
                                data-testid="github-org-select"
                                onChange={onChange}
                                required
                            >
                                {gitHubOrgs.map((org, i) => (
                                    <option value={org.name} key={i}>
                                        {org.name}
                                    </option>
                                ))}
                            </select>
                            <Icon
                                icon="keyboard_arrow_down"
                                className="flex fz18 mainColor absolute right-1 bottom-2 pointer-events"
                            />
                        </label>
                    </div>
                )}

                {config['auth.oauth.github.organization'] && !gitHubOrgs.length && (
                    <div data-testid="github-provider-org">
                        <dl className="mt2">
                            <dt className="uppercase fz10 spacing inline-flex bold">Github organization:</dt>
                            <dd className="ml3 inline-flex">{config['auth.oauth.github.organization']}</dd>
                        </dl>
                    </div>
                )}
            </div>

            {!config['auth.oauth.github.organization'] && !gitHubOrgs.length && (
                <button type="button" className="primary-orange mt2" onClick={() => setupOAuth()}>
                    Initiate OAuth Setup
                </button>
            )}
        </>
    );
};

export default GitHubConfiguration;
