import { FC, useState, SyntheticEvent } from 'react';
import BouncerButton from 'components/form/BouncerButton';
import Select from 'components/form/Select';
import Role from 'models/Role';
import { create as addTeam } from 'services/api/teams';
import useDisplayMessage from 'hooks/useDisplayMessage';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import Team from 'models/Team';

const AddTeam: FC<{ roles: Role[]; onCancel: () => void; onSuccess?: (team: Team) => void }> = ({
    roles,
    onCancel,
    onSuccess,
}) => {
    const teamRoles = roles.filter(role => role.assignable.toTeams);
    const [name, setName] = useState<string>('');
    const [isSaving, setIsSaving] = useState(false);
    const [role, setRole] = useState<Role>(teamRoles[0]);
    const roleOptions = teamRoles.map(teamRole => ({
        name: teamRole.name,
        value: teamRole.id,
    }));

    const { error } = useDisplayMessage();

    const handleSelectChange = (e: SyntheticEvent<HTMLSelectElement>) => {
        const newRole = roles.find(({ id }) => id === parseInt(e.currentTarget.value, 10));

        if (newRole) {
            setRole(newRole);
        }
    };

    const handleSubmit = async () => {
        setIsSaving(true);
        try {
            const team = await addTeam(name, { id: role.id, name: role.name });

            setIsSaving(false);

            if (onSuccess) {
                onSuccess(team);
            }
        } catch {
            error('An error was found when creating the new team.');
            setIsSaving(false);
        }
    };

    return (
        <>
            <form className="settings__form settings__form--create-teams p3 bg-white" name="teamForm">
                <fieldset className="mb3">
                    <div className="flex">
                        <label
                            htmlFor="team-name"
                            className="form__label uppercase fz10 spacing inline-flex bold items-center no-shrink"
                        >
                            Team name:
                        </label>
                        <input
                            value={name}
                            onChange={(e: SyntheticEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
                            data-testid="new-team-input"
                            className="full-width"
                        />
                    </div>
                </fieldset>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Select a role:">
                    <Select
                        options={roleOptions}
                        onChange={handleSelectChange}
                        data-testid="new-team-select"
                        error={''}
                    />
                </FormGroup>
            </form>
            <div className="flex justify-end p3 bg-grey05">
                <button type="button" className="primary-grey mr2" onClick={() => onCancel()}>
                    Cancel
                </button>
                <BouncerButton
                    bounce={isSaving}
                    onClick={handleSubmit}
                    disabled={name === '' || isSaving}
                    className="primary-orange"
                    data-testid="save-new-team"
                >
                    Create
                </BouncerButton>
            </div>
        </>
    );
};

export default AddTeam;
