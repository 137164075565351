import Popover from 'components/popover/Popover';
import SelectField from 'components/form/fields/SelectField';
import TextField from 'components/form/fields/TextField';
import useUIStatus from 'hooks/useUIStatus';
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { getInteractionReporter } from 'services/analytics/analytics';
import { SamplesFilterInterface } from 'services/api/samples';

const reportActivity = getInteractionReporter('samples-dashboard');
const reportDBFocus = () => reportActivity('focus', 'db');
const reportUserFocus = () => reportActivity('focus', 'user');
const reportOriginFocus = () => reportActivity('focus', 'origin');
const reportLatencyMinFocus = () => reportActivity('focus', 'latencyMin');
const reportLatencyMaxFocus = () => reportActivity('focus', 'latencyMax');
const reportConnectionFocus = () => reportActivity('focus', 'connectionId');

const limitOptions = [
    {
        name: 10,
        value: '10',
    },
    {
        name: 25,
        value: '25',
    },
    {
        name: 50,
        value: '50',
    },
    {
        name: 100,
        value: '100',
    },
    {
        name: 200,
        value: '200',
    },
];

const iconsClassName = 'grey3 fz18';
const defaultValues = {
    db: '',
    user: '',
    origin: '',
    latencyMin: '',
    latencyMax: '',
    connectionId: '',
    limit: '50',
};

const SamplesFilters: FC<{ onSubmit(arg0: SamplesFilterInterface): void }> = ({ onSubmit }) => {
    const [uiStatus, setUIStatus] = useUIStatus(defaultValues, {
        id: 'samples',
    });

    // When coming from ng-app, the latencyMin and latencyMax fields might have a value of zero when it actually means
    // an empty value. This was a workaround for not being able to set null to a numeric field, so we need to address that
    // special case for compatibility here.
    // Source: https://github.com/VividCortex/ng-app/blob/master/src/js/app/samples/controller.js#L71-L81
    if (uiStatus.latencyMin === '0') {
        uiStatus.latencyMin = '';
    }

    if (uiStatus.latencyMax === '0') {
        uiStatus.latencyMax = '';
    }

    const [filter, setFilter] = useState({ ...uiStatus });

    useEffect(() => {
        onSubmit(filter);
    }, [filter]); // eslint-disable-line

    function handleSubmit(values: typeof defaultValues) {
        const newValues = { ...defaultValues, ...values };
        setFilter(newValues);
        setUIStatus(newValues);
    }

    return (
        <div className="tile mb3" data-testid="samples-filters">
            <Form
                initialValues={filter}
                onSubmit={handleSubmit}
                render={({ pristine, invalid, handleSubmit: onFormSubmit }) => (
                    <form className="form__samples-filters" onSubmit={onFormSubmit}>
                        <fieldset className="mr2 filters__queries__input">
                            <div className="flex flex-wrap items-center">
                                <label htmlFor="database" className="fz10 uppercase bold mr2">
                                    Database
                                </label>
                                <div className="flex items-center flex-grow-1">
                                    <Field
                                        className="samples__filters__input-text"
                                        component={TextField}
                                        id="database"
                                        name="db"
                                        onFocus={reportDBFocus}
                                    />
                                    <Popover icon="info" iconClassName={iconsClassName}>
                                        <p className="settings__form__help">
                                            This field supports pattern matching through the use of the * character;
                                            e.g. env* or *2
                                        </p>
                                    </Popover>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="mr2 filters__queries__input">
                            <div className="flex flex-wrap items-center">
                                <label htmlFor="user" className="fz10 uppercase bold mr2">
                                    User
                                </label>
                                <div className="flex items-center flex-grow-1">
                                    <Field
                                        className="samples__filters__input-text"
                                        component={TextField}
                                        id="user"
                                        name="user"
                                        onFocus={reportUserFocus}
                                    />
                                    <Popover icon="info" iconClassName={iconsClassName}>
                                        <p className="settings__form__help">
                                            This field supports pattern matching through the use of the * character;
                                            e.g. env* or *2
                                        </p>
                                    </Popover>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="mr2 filters__queries__input">
                            <div className="flex flex-wrap items-center">
                                <label htmlFor="origin" className="fz10 uppercase bold mr2">
                                    Origin
                                </label>
                                <div className="flex items-center flex-grow-1">
                                    <Field
                                        className="samples__filters__input-text"
                                        component={TextField}
                                        id="origin"
                                        name="origin"
                                        onFocus={reportOriginFocus}
                                    />
                                    <Popover icon="info" iconClassName={iconsClassName}>
                                        <p className="settings__form__help">
                                            This field supports pattern matching through the use of the * character;
                                            e.g. env* or *2
                                        </p>
                                    </Popover>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="mr3">
                            <div className="flex filters__multi-input flex-wrap samples__latency__filters">
                                <label className="fz10 uppercase bold mr2 nowrap flex self-center">
                                    Latency (seconds)
                                </label>
                                <div className="flex items-center flex-grow-1">
                                    <Field
                                        className="samples__filters__latency"
                                        component={TextField}
                                        data-testid="latency-min"
                                        placeholder="Minimum"
                                        name="latencyMin"
                                        onFocus={reportLatencyMinFocus}
                                        pattern="^\d+(?:[.,]\d+)?$"
                                    />
                                    <span className="mx2">{'-'}</span>
                                    <Field
                                        className="samples__filters__latency"
                                        component={TextField}
                                        data-testid="latency-max"
                                        placeholder="Maximum"
                                        name="latencyMax"
                                        onFocus={reportLatencyMaxFocus}
                                        pattern="^\d+(?:[.,]\d+)?$"
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="mr2 filters__queries__input">
                            <div className="flex flex-wrap items-center">
                                <label htmlFor="connection-id" className="fz10 uppercase bold mr2">
                                    Connection ID
                                </label>
                                <div className="flex items-center flex-grow-1">
                                    <Field
                                        className="samples__filters__input-text"
                                        component={TextField}
                                        id="connection-id"
                                        name="connectionId"
                                        onFocus={reportConnectionFocus}
                                    />
                                    <Popover icon="info" iconClassName={iconsClassName}>
                                        <p className="settings__form__help">
                                            This field supports pattern matching through the use of the * character;
                                            e.g. env* or *2
                                        </p>
                                    </Popover>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className="mr3 filters__queries__input">
                            <label htmlFor="limit" className="vc-select flex-wrap">
                                <b className="fz10 uppercase bold mr2">Limit</b>
                                <Field
                                    component={SelectField}
                                    options={limitOptions}
                                    name="limit"
                                    id="limit"
                                    required
                                />
                            </label>
                        </fieldset>
                        <div className="flex flex-wrap">
                            <button className="primary-orange" type="submit" disabled={pristine || invalid}>
                                Apply
                            </button>
                        </div>
                    </form>
                )}
            />
        </div>
    );
};

export default SamplesFilters;
