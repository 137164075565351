import GlobalMessage from 'components/messages/GlobalMessage';
import { useGlobalMessages } from 'components/context/GlobalMessages';

const MessagesDisplayer = () => {
    const [{ messages }] = useGlobalMessages();

    return (
        <div className="vc-msg-container">
            {messages.map(({ id, message, type, dismissable, fade }) => (
                <GlobalMessage key={id} id={id} dismissable={dismissable} type={type} fade={fade}>
                    {message}
                </GlobalMessage>
            ))}
        </div>
    );
};

export default MessagesDisplayer;
