import { useState, useEffect } from 'react';
import { getAutoSubscription, updateAutoSubscription } from 'services/api/userSubscriptions';
import { APIStatus } from './EmailPreferences';
import Bouncer from 'components/icons/Bouncer';
import useUser from 'hooks/useUser';
import useDisplayMessage from 'hooks/useDisplayMessage';

const EmailPreferenceAutoSubscription = () => {
    const [isAutoSubscribed, setIsAutoSubscribed] = useState(true);
    const [status, setStatus] = useState(APIStatus.default);
    const [disabled, setDisabled] = useState(false);
    const user = useUser();

    const { error } = useDisplayMessage();

    const handleChange = () => {
        setStatus(APIStatus.inProcess);
        const newStatus = !isAutoSubscribed;
        setIsAutoSubscribed(newStatus);

        updateAutoSubscription(user, newStatus)
            .then(() => {
                setStatus(APIStatus.success);
            })
            .catch(() => {
                setStatus(APIStatus.error);
                setIsAutoSubscribed(!newStatus);
            });
    };

    const fetchAutoSubscriptions = () => {
        getAutoSubscription(user)
            .then(response => {
                setIsAutoSubscribed(response);
            })
            .catch(() => {
                error(
                    'There was an error fetching autosubscription status, and therefore, this setting has been disabled. Please try again in a few minutes.'
                );
                setDisabled(true);

                // Set checkbox as indeterminate when the request fails. This cannot be done in the HTML.
                const checkbox: HTMLInputElement | null = document.getElementById('email-pref') as HTMLInputElement;
                if (checkbox !== null) {
                    checkbox.indeterminate = true;
                }
            });
    };

    useEffect(() => {
        fetchAutoSubscriptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mt4 settings__form--email-pref__list__chkbox" data-testid="email-pref-autosubscribe">
            {status === APIStatus.inProcess && (
                <Bouncer className="grey2 absolute settings__form--email-pref__list__chkbox__info settings__form--email-pref__list__chkbox__info--bouncer" />
            )}
            {status === APIStatus.success && (
                <span
                    className="absolute green settings__form--email-pref__list__chkbox__info"
                    data-testid="autosubscription-updated"
                >
                    Updated!
                </span>
            )}
            {status === APIStatus.error && (
                <span
                    className="absolute red settings__form--email-pref__list__chkbox__info nowrap"
                    data-testid="autosubscription-failed"
                >
                    Update failed!
                </span>
            )}
            <label htmlFor="email-pref">
                <input
                    type="checkbox"
                    id="email-pref"
                    name="checkbox-email-pref"
                    data-testid="email-pref-checkbox"
                    checked={isAutoSubscribed}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span className="settings__form--email-pref__list__chkbox__label" data-testid="checkbox-email-pref">
                    Automatically subscribe me to emails for new environments
                </span>
            </label>
            <p className="grey3 line-height-120 mt3 ml3 pl1">
                Define your subscription for new environments that you are given access to. Those environments will be
                listed above when you have access and you can individually subscribe or unsubscribe.
            </p>
        </div>
    );
};

export default EmailPreferenceAutoSubscription;
