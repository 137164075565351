import { useEffect, useState } from 'react';
import { getWebappMultiplexer } from 'services/multiplex';
import { get as getContext } from 'services/context';

export default function useMultiplexer() {
    const { multiplexerPaths } = getContext();
    const [multiplexer, setMultiplexer] = useState(getWebappMultiplexer(multiplexerPaths));

    useEffect(() => {
        setMultiplexer(() => getWebappMultiplexer(multiplexerPaths));
    }, [multiplexerPaths]);

    return multiplexer;
}
