import moment from 'moment-timezone';
import { get as getContext } from 'services/context';

let lastKnownServerTime: number;
let localTimeAtKnownServerTime: number;

function init() {
    const context = getContext();

    localTimeAtKnownServerTime = moment().unix();
    lastKnownServerTime = context.timestamp;
}

export function updateServerTimestamp(timestamp: number) {
    localTimeAtKnownServerTime = moment().unix();
    lastKnownServerTime = timestamp;
}

export function getCurrentTimestamp(): number {
    if (!lastKnownServerTime) {
        init();
    }

    const elapsedSeconds = moment().unix() - localTimeAtKnownServerTime;

    return lastKnownServerTime + elapsedSeconds;
}

export function getCurrentMsTimestamp() {
    return toMs(getCurrentTimestamp());
}

export const DEFAULT_INTERVAL = {
    from: -3600,
    until: 0,
};

export const OFFSET_PRESETS = [
    { text: '10 m', seconds: 10 * 60 },
    { text: '30 m', seconds: 30 * 60 },
    { text: '1 h', seconds: 60 * 60 },
    { text: '3 h', seconds: 3 * 60 * 60 },
    { text: '12 h', seconds: 12 * 60 * 60 },
    { text: '1 d', seconds: 24 * 60 * 60 },
    { text: '4 d', seconds: 4 * 24 * 60 * 60 },
    { text: '7 d', seconds: 7 * 24 * 60 * 60 },
    { text: '30 d', seconds: 30 * 24 * 60 * 60 },
];

export function getOffsetPreset(seconds: number) {
    return OFFSET_PRESETS.find(preset => preset.seconds === seconds);
}

export function humanizeDuration(seconds: number) {
    const day = 86400;
    const hour = 3600;

    let duration = '';

    if (seconds >= day) {
        const days = Math.floor(seconds / day);

        duration += days;
        duration += days === 1 ? ' day ' : ' days ';

        seconds = seconds % day;
    }

    if (seconds === 0) {
        return duration.trim();
    } else if (seconds < hour) {
        duration += Math.floor(seconds / 60) + ' minutes';
    } else if (seconds === hour) {
        duration += '1 hour';
    } else if (seconds < day) {
        const hours = Math.floor(seconds / hour);
        const minutes = Math.floor((seconds % hour) / 60);

        duration += hours + (hours > 1 ? ' hours' : ' hour') + (minutes > 0 ? ` ${minutes} minutes` : '');
    }

    return duration.trim();
}

// See: https://github.com/VividCortex/ng-app/pull/8439#issuecomment-412626903
const SUPPORTED_TIME_FORMATS = [
    {
        format: 'ha',
        regex: /^\d{1,2}(am|pm)$/i,
    },
    {
        format: 'h a',
        regex: /^\d{1,2} \b(am|pm)$/i,
    },
    {
        format: 'h:mma',
        regex: /^\d{1,2}:\d{2}(am|pm)$/i,
    },
    {
        format: 'h:mm a',
        regex: /^\d{1,2}:\d{2} \b(am|pm)$/i,
    },
    {
        format: 'H:mm',
        regex: /^\d{1,2}:\d{2}$/i,
    },
];

export function detectFormat(time: string) {
    return SUPPORTED_TIME_FORMATS.find(format => format.regex.test(time));
}

export function toMs(timestamp: number) {
    return timestamp > 9999999999 ? timestamp : timestamp * 1e3;
}
