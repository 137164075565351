import EditPassword from './EditPassword';
import EditProfile from './EditProfile';
import EditTimezone from './EditTimezone';
import * as React from 'react';
import RecentActivity from './RecentActivity';
import SettingsLayout from '../Layout';

const SettingsProfile: React.FC = () => {
    return (
        <SettingsLayout title="Profile">
            <EditProfile />
            <EditTimezone />
            <EditPassword />
            <RecentActivity />
        </SettingsLayout>
    );
};

export default SettingsProfile;
