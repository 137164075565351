import React from 'react';
import Header from 'components/layout/Header';
import { userIsAllowedTo } from 'services/security/accessControl';
import ExpandButtons from './ExpandButtons';
import InventoryButtons from './Buttons';

export interface RefObject {
    setRowsStatus: (expanded: boolean) => void;
}

export enum InventoryType {
    machines = 'machines',
    groups = 'groups',
}

const InventoryControls: React.FC<{ type: InventoryType; onExpandToggle: (expand: boolean) => void }> = ({
    type,
    onExpandToggle,
}) => {
    const leftContent = (
        <ExpandButtons
            onToggle={expanded => {
                onExpandToggle(expanded);
            }}
        />
    );

    return (
        <Header
            title={type === InventoryType.groups ? 'Groups' : 'Machines'}
            leftContent={leftContent}
            rightContent={userIsAllowedTo('changeEnvSettings') && <InventoryButtons />}
        />
    );
};

export default InventoryControls;
