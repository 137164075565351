import APIRequest from './Request';
import Summary, { AssertsSummaryDataInterface } from 'models/bestPractices/Summary';
import TimeInterval from 'models/TimeInterval';
import Occurrence, { OccurrenceDataInterface } from 'models/bestPractices/Occurrence';
import OccurrenceTarget, { OccurrenceHost, OccurrenceObject } from 'models/bestPractices/OccurrenceTarget';

interface AssertsOptionsInterface {
    interval: TimeInterval;
    host: string;
    category: string;
    showPassed: boolean;
    showMuted: boolean;
    notify?: boolean;
    offset: number;
    paginateId?: number;
}

interface AssertsOptionsForNotificationsInterface {
    interval: TimeInterval;
    host: string;
    filterNotify?: boolean;
}

interface FetchOccurrencesParamsInterface {
    [key: string]: string | undefined;
    category: string;
    host: string;
    showPassed: string;
    showSuppressed: string;
    limit: string;
    from: string;
    until: string;
    filterNotify?: string;
    type?: string;
}

interface FetchOccurrencesForNotificationsParamsInterface {
    [key: string]: string | undefined;
    host: string;
    from: string;
    until: string;
    filterNotify?: string;
}

export async function fetchOccurrences(options: AssertsOptionsInterface) {
    const interval = options.interval.asParams();

    const params: FetchOccurrencesParamsInterface = {
        category: options.category,
        host: options.host,
        showPassed: options.showPassed ? '1' : '0',
        showSuppressed: options.showMuted ? '1' : '0',
        limit: `${options.offset}`,
        from: `${interval.from}`,
        until: `${interval.until}`,
    };

    if (options.notify) {
        params.filterNotify = options.notify ? '1' : '0';
    }

    if (options.paginateId) {
        params.type = `${options.paginateId}`;
    }

    const query = new URLSearchParams(params as Record<string, string>).toString();

    const result = await new APIRequest<OccurrenceDataInterface[]>({
        path: `asserts/list-asserts?${query}`,
        method: 'post',
    }).request();

    return result.data.map(data => new Occurrence(data));
}

export async function fetchOccurrencesForNotifications(options: AssertsOptionsForNotificationsInterface) {
    const interval = options.interval.asParams();

    const params: FetchOccurrencesForNotificationsParamsInterface = {
        host: options.host,
        from: `${interval.from}`,
        until: `${interval.until}`,
    };

    if (options.filterNotify) {
        params.filterNotify = options.filterNotify ? '1' : '0';
    }

    const query = new URLSearchParams(params as Record<string, string>).toString();

    const result = await new APIRequest<OccurrenceDataInterface[]>({
        path: `asserts/asserts?${query}`,
        method: 'get',
    }).request();

    return result.data.map(data => new Occurrence(data));
}

export interface AssertsSummaryOptionsInterface {
    interval: TimeInterval;
    showPassed: boolean;
    showMuted: boolean;
    host: string;
}

export async function fetchSummary(options: AssertsSummaryOptionsInterface, refreshSummary?: Summary) {
    const interval = options.interval.asParams();

    const params: Record<string, string> = {
        showPassed: options.showPassed ? '1' : '0',
        showSuppressed: options.showMuted ? '1' : '0',
        from: `${interval.from}`,
        until: `${interval.until}`,
        host: options.host,
    };

    const query = new URLSearchParams(params).toString();

    const result = await new APIRequest<AssertsSummaryDataInterface[]>({
        path: `asserts/summary?${query}`,
        method: 'post',
    }).request();

    if (refreshSummary) {
        return refreshSummary.cloneAndUpdate(result.data);
    }

    return new Summary(result.data);
}

export async function mute(occurrence: Occurrence, target?: OccurrenceTarget) {
    const params: Record<string, string> = {
        assert: `${occurrence.id}`,
    };

    if (target instanceof OccurrenceHost) {
        params.host = `${target.hostId}`;
    } else if (target instanceof OccurrenceObject) {
        params.objectsID = `${target.queryId}`;
    } else {
        params.host = '0';
    }

    await new APIRequest<null>({
        path: `asserts/assert/suppress?${new URLSearchParams(params).toString()}`,
        method: 'post',
    }).request();
}

export async function unmute(occurrence: Occurrence, target?: OccurrenceTarget) {
    const params: Record<string, string> = {
        assert: `${occurrence.id}`,
    };

    if (target instanceof OccurrenceHost) {
        params.host = `${target.hostId}`;
    } else if (target instanceof OccurrenceObject) {
        params.objectsID = `${target.queryId}`;
    } else {
        params.host = '0';
    }

    await new APIRequest<null>({
        path: `asserts/assert/suppress?${new URLSearchParams(params).toString()}`,
        method: 'delete',
    }).request();
}
