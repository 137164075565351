import * as orgConfigService from 'services/api/orgConfig';
import { AuthenticatedBootstrap } from 'models/Bootstrap';
import { AuthMethodType, OauthProviderType } from 'models/AuthMethod';
import { get as getContext } from 'services/context';
import { requestNewTokens } from 'services/api/authTokens';

export interface AuthConfigDataInterface {
    [key: string]: string;
    'auth.method': AuthMethodType;
    'auth.oauth.email': string;
    'auth.oauth.github.email': string;
    'auth.oauth.github.organization': string;
    'auth.oauth.google.emailDomain': string;
    'auth.oauth.provider': OauthProviderType | '';
    'auth.saml.IdPMetadata': string;
}

export const defaultConfig: AuthConfigDataInterface = {
    'auth.method': 'basic',
    'auth.oauth.email': '',
    'auth.oauth.github.email': '',
    'auth.oauth.github.organization': '',
    'auth.oauth.google.emailDomain': '',
    'auth.oauth.provider': '',
    'auth.saml.IdPMetadata': '',
};

export async function getConfig() {
    const config = await orgConfigService.get();

    return { ...defaultConfig, ...config };
}

export async function saveConfig(config: AuthConfigDataInterface) {
    const data: orgConfigService.OrgConfigDataInterface = {
        'auth.method': config['auth.method'],
    };

    switch (config['auth.method']) {
        case 'oauth':
            data['auth.oauth.github.email'] = config['auth.oauth.github.email'];
            data['auth.oauth.github.organization'] = config['auth.oauth.github.organization'];
            data['auth.oauth.google.emailDomain'] = config['auth.oauth.google.emailDomain'];
            data['auth.oauth.provider'] = config['auth.oauth.provider'];
            break;
        case 'saml':
            data['auth.saml.IdPMetadata'] = config['auth.saml.IdPMetadata'];
            break;
        case 'basic':
            await orgConfigService.store('auth.method', data['auth.method']);
            requestNewTokens();

            return;
    }

    await orgConfigService.storeMultiple(data);

    requestNewTokens();
}

const disallowedOAuthDomains = ['gmail.com', 'googlemail.com'];

export function isOAuthEmailDomainValid(config: AuthConfigDataInterface) {
    return !disallowedOAuthDomains.includes(config['auth.oauth.google.emailDomain']);
}

export function isOAuthEmailValid(config: AuthConfigDataInterface) {
    const context = getContext() as AuthenticatedBootstrap;

    return context.user.email === config['auth.oauth.email'];
}

export function isConfigValid(config: AuthConfigDataInterface) {
    // Basic
    if (config['auth.method'] === 'basic') {
        return true;
    }

    // SAML
    if (config['auth.method'] === 'saml' && config['auth.saml.IdPMetadata']) {
        return true;
    }

    // OAuth
    if (config['auth.oauth.provider'] === 'google') {
        return (
            !!config['auth.oauth.google.emailDomain'] && isOAuthEmailValid(config) && isOAuthEmailDomainValid(config)
        );
    }

    if (config['auth.oauth.provider'] === 'github') {
        return !!config['auth.oauth.github.email'] && !!config['auth.oauth.github.organization'];
    }

    return false;
}

export async function isUsingSSO() {
    const config = await getConfig();

    return config['auth.method'] === 'saml';
}

export async function isUsingSAMLGroupProvisioning() {
    const config = await orgConfigService.get();

    return config['provisioning.groups.enabled'] === '1';
}
