import { CredentialDataInterface, HostCredential } from './Credential';
import { CredentialHostType } from 'services/api/credentials';

export interface MongoCredentialDataInterface extends CredentialDataInterface {
    sslEnabled: string;
    sslKey: string;
    user: string;
}

export default class MongoCredential extends HostCredential {
    user: string;
    sslEnabled: boolean;
    sslKey: string;

    constructor(data: MongoCredentialDataInterface) {
        super(CredentialHostType.mongo, data);
        this.user = data.user || '';
        this.sslEnabled = data.sslEnabled === 'true';
        this.sslKey = data.sslKey || '';
    }

    update(values: this, allowPasswordChange?: boolean) {
        super.update(values, allowPasswordChange ?? true);
        this.user = values.user;
        this.sslEnabled = values.sslEnabled;
        this.sslKey = values.sslKey;
    }

    asPayloadObject() {
        return {
            ...super.asPayloadObject(),
            user: this.user,
            sslEnabled: this.sslEnabled.toString(),
            sslKey: this.sslKey,
        };
    }
}
