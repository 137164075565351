import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import { FC, useEffect, useState } from 'react';
import { get as getAgents, start, stop, restart, AgentsCommands } from 'services/api/agents';
import { userIsAllowedTo } from 'services/security/accessControl';
import InventoryButtons from '../Buttons';
import Header from 'components/layout/Header';
import AgentsFilter from './Filters';
import Logger from 'services/logger';
import Agent from 'models/Agent';
import LoaderRow from 'components/loaders/LoaderRow';
import AgentRow from './Row';
import AgentActions from './Actions';
import ServerError from 'components/messages/ServerError';
import { useHostFilter } from 'components/context/HostFilter';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import LogView from './LogView';

interface CommandActionInterface {
    name: AgentsCommands;
    command: typeof start | typeof stop | typeof restart;
    agents: Agent[];
}

interface LogFetchInterface {
    agent: Agent;
    log: string;
}

const logger = Logger.get('Agents');

const Agents: FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [agents, setAgents] = useState<Agent[]>([]);
    const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
    const [selectedAgents, setSelectedAgents] = useState<Agent[]>([]);
    const [toggleAllChecked, setToggleAllChecked] = useState(false);
    const [showLogView, setShowLogView] = useState(false);
    const [logData, setLogData] = useState<LogFetchInterface | null>(null);

    const { filter } = useHostFilter();

    const { success, error } = useDisplayMessage();

    const { openModal } = useFinalActionModal();

    const fetchAgents = async () => {
        try {
            setIsLoading(true);
            const fetchedAgents = await getAgents(filter);
            setAgents(fetchedAgents);
            setFilteredAgents(fetchedAgents);
        } catch (e) {
            logger.error(e);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAgents();
        // eslint-disable-next-line
    }, [filter]);

    const handleFiltering = (text: string) => {
        const filteredAgentsList = text ? agents.filter(agent => agent.name.includes(text)) : agents;
        setFilteredAgents(filteredAgentsList);
    };

    const isSelected = (agent: Agent) => selectedAgents.includes(agent);

    const handleSelectToggle = (agent: Agent) => {
        let selected = [...selectedAgents];
        if (isSelected(agent)) {
            selected = selectedAgents.filter(selectedAgent => selectedAgent !== agent);
        } else {
            selected.push(agent);
        }
        setSelectedAgents(selected);
    };

    const handleToggleAll = (checked: boolean) => {
        if (checked) {
            setSelectedAgents([...filteredAgents]);
            setToggleAllChecked(true);
        } else {
            setSelectedAgents([]);
            setToggleAllChecked(false);
        }
    };

    const handleCommand = async ({ name, command, agents: agentList }: CommandActionInterface) => {
        try {
            await command(agentList);
            success(`The ${name} command was successfully scheduled.`);

            fetchAgents();
        } catch {
            error(`The ${name} command could not be scheduled. Please try again later.`);
        } finally {
            handleToggleAll(false);
        }
    };

    const handleLogFetched = (log: string, agent: Agent) => {
        setLogData({ log, agent });
        setShowLogView(true);
    };

    return (
        <div className="view view--filters view--salesforce agents">
            <Header
                title="Agents"
                leftContent={<AgentsFilter onApply={handleFiltering} />}
                rightContent={userIsAllowedTo('changeEnvSettings') && <InventoryButtons />}
            />

            {!isLoading && !hasError && agents.length === 0 && (
                <EmptyState type={EmptyTypes.INFO}> There are no active agents to display.</EmptyState>
            )}
            {!isLoading && hasError && (
                <EmptyState type={EmptyTypes.SERVER}>
                    <ServerError />
                </EmptyState>
            )}
            {(isLoading || agents.length > 0) && (
                <div>
                    <AgentActions
                        agents={selectedAgents}
                        onStart={(agentsList: Agent[]) =>
                            handleCommand({ name: AgentsCommands.START, command: start, agents: agentsList })
                        }
                        onRestart={(agentsList: Agent[]) =>
                            handleCommand({ name: AgentsCommands.RESTART, command: restart, agents: agentsList })
                        }
                        onStop={(agentsList: Agent[]) => {
                            openModal({
                                onSubmit: () =>
                                    handleCommand({ name: AgentsCommands.STOP, command: stop, agents: agentsList }),
                                confirmMsg: 'This will stop all agents in the selected hosts. Are you sure?',
                                buttonText: 'Stop agents',
                                title: 'Stop agents',
                                showWarning: false,
                            });
                        }}
                    />
                    <table className="vc-table vc-table--agents full-width" data-testid="agents-table">
                        <thead>
                            <tr>
                                <th>
                                    {userIsAllowedTo('changeEnvSettings') && (
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="None"
                                                id="agent-select"
                                                name="check"
                                                checked={toggleAllChecked}
                                                onChange={e => handleToggleAll(e.currentTarget.checked)}
                                            />
                                            <span />
                                        </label>
                                    )}
                                    Status
                                </th>
                                <th>Agent</th>
                                <th>Version</th>
                                <th>Host</th>
                                <th>Last seen</th>
                                {userIsAllowedTo('changeEnvSettings') && <th>Actions</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && (
                                <>
                                    <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 6 : 5} />
                                    <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 6 : 5} />
                                    <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 6 : 5} />
                                </>
                            )}

                            {!isLoading &&
                                filteredAgents.map(agent => {
                                    return (
                                        <AgentRow
                                            key={agent.name}
                                            agent={agent}
                                            onToggle={handleSelectToggle}
                                            selected={isSelected(agent)}
                                            onStartAgent={(agentsList: Agent[]) =>
                                                handleCommand({
                                                    name: AgentsCommands.START,
                                                    command: start,
                                                    agents: agentsList,
                                                })
                                            }
                                            onStopAgent={(agentsList: Agent[]) =>
                                                handleCommand({
                                                    name: AgentsCommands.STOP,
                                                    command: stop,
                                                    agents: agentsList,
                                                })
                                            }
                                            onRestartAgent={(agentsList: Agent[]) =>
                                                handleCommand({
                                                    name: AgentsCommands.RESTART,
                                                    command: restart,
                                                    agents: agentsList,
                                                })
                                            }
                                            onLogFetched={handleLogFetched}
                                        />
                                    );
                                })}
                        </tbody>
                    </table>

                    {logData && (
                        <LogView
                            visible={showLogView}
                            log={logData.log}
                            onClose={() => setShowLogView(false)}
                            agent={logData.agent}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
export default Agents;
