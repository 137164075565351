import BouncerButton from 'components/form/BouncerButton';
import FormActions from 'components/form/Actions';
import { FC, useState } from 'react';
import { FieldRenderProps, Form, Field } from 'react-final-form';
import Host from 'models/hosts/Host';
import SlideSwitchField from 'components/form/fields/SlideSwitchField';
import cn from 'helpers/classname';
import MongoCredentialsFields from 'components/credentials/MongoCredentialsFields';
import PostgreSQLCredentialsFields from 'components/credentials/PostgreSQLCredentialsFields';
import RedisCredentialsFields from 'components/credentials/RedisCredentialsFields';
import MySQLCredentialsFields from 'components/credentials/MySQLCredentialsFields';
import { update as updateCredentials, remove as deleteCredentials } from 'services/api/credentials';
import { HostCredential } from 'models/credentials/Credential';
import { FormApi } from 'final-form';
import SQLServerCredentialsFields from 'components/credentials/SQLServerCredentialsFields';
import MongoCredentialModel from 'models/credentials/MongoCredential';
import MySQLCredentialModel from 'models/credentials/MySQLCredential';
import PostgreSQLCredentialModel from 'models/credentials/PostgreSQLCredential';
import RedisCredentialModel from 'models/credentials/RedisCredential';
import SQLServerCredentialModel from 'models/credentials/SQLServerCredential';

interface EditCredentialsPropsInterface {
    onCancel: (host: Host) => void;
    onSuccess: (host: Host) => void;
    onError: (host: Host) => void;
    host: Host;
}

interface EditFormInterface extends HostCredential {
    defaultCredentials: boolean;
}

const EditCredentials: FC<EditCredentialsPropsInterface> = ({ onCancel, onSuccess, onError, host }) => {
    const [credentialsModel, setCredentialsModel] = useState<HostCredential | undefined>(undefined);

    const displayCredentialsFields = (
        form: FormApi<EditFormInterface, Partial<EditFormInterface>>,
        values: HostCredential
    ) => {
        switch (host.type) {
            case 'mongo':
                return (
                    <MongoCredentialsFields
                        host={host}
                        values={values as MongoCredentialModel}
                        onLoad={model => handleCredentialsLoad(model, form)}
                    />
                );
            case 'mysql':
                return (
                    <MySQLCredentialsFields
                        host={host}
                        values={values as MySQLCredentialModel}
                        onLoad={model => handleCredentialsLoad(model, form)}
                    />
                );
            case 'pgsql':
                return (
                    <PostgreSQLCredentialsFields
                        host={host}
                        values={values as PostgreSQLCredentialModel}
                        onLoad={model => handleCredentialsLoad(model, form)}
                    />
                );
            case 'redis':
                return (
                    <RedisCredentialsFields
                        host={host}
                        values={values as RedisCredentialModel}
                        onLoad={model => handleCredentialsLoad(model, form)}
                    />
                );
            case 'mssql':
                return (
                    <SQLServerCredentialsFields
                        host={host}
                        values={values as SQLServerCredentialModel}
                        onLoad={model => handleCredentialsLoad(model, form)}
                    />
                );
            default:
                return null;
        }
    };

    function handleCredentialsLoad(
        model: HostCredential,
        form: FormApi<EditFormInterface, Partial<EditFormInterface>>
    ) {
        if (!model.isEmpty && credentialsModel === undefined) {
            form.change('defaultCredentials', false);
        }
        setCredentialsModel(model);
    }

    const saveChanges = async (formValues: EditFormInterface) => {
        if (!credentialsModel) {
            return;
        }

        let promise;
        if (formValues.defaultCredentials) {
            promise = deleteCredentials(host);
        } else {
            credentialsModel.update(formValues);
            promise = updateCredentials(credentialsModel, host);
        }

        try {
            await promise;
            onSuccess(host);
        } catch {
            onError(host);
        }
    };

    return (
        <section className="host-info__edit-credentials">
            {
                <div className="form mt2">
                    <Form
                        initialValues={{
                            ...credentialsModel,
                            ...{ defaultCredentials: credentialsModel?.isEmpty },
                        }}
                        validateOnBlur={true}
                        onSubmit={saveChanges}
                        render={({ pristine, invalid, handleSubmit, submitting, values, form }) => (
                            <form className="credential__form" onSubmit={handleSubmit}>
                                <div className="mx3 credential__form__wrapper overflow-auto">
                                    <fieldset>
                                        <div className="flex items-center justify-center">
                                            <button
                                                className={`link use-credentials mr2 ${cn(
                                                    'active',
                                                    values.defaultCredentials
                                                )}`}
                                                type="button"
                                                onClick={() => {
                                                    form.change('defaultCredentials', true);
                                                }}
                                            >
                                                Use default environment credentials
                                            </button>

                                            <Field name="defaultCredentials">
                                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                    <SlideSwitchField
                                                        checked={!values.defaultCredentials}
                                                        onChange={() => {
                                                            form.change(
                                                                'defaultCredentials',
                                                                !values.defaultCredentials
                                                            );
                                                        }}
                                                        {...props}
                                                    />
                                                )}
                                            </Field>

                                            <button
                                                className={`link use-credentials ml2 ${cn(
                                                    'active',
                                                    !values.defaultCredentials
                                                )}`}
                                                type="button"
                                                onClick={() => {
                                                    form.change('defaultCredentials', false);
                                                }}
                                            >
                                                Use custom host credentials
                                            </button>
                                        </div>
                                    </fieldset>
                                    {(credentialsModel === undefined || !values.defaultCredentials) && (
                                        <div className="mt3"> {displayCredentialsFields(form, values)} </div>
                                    )}
                                </div>
                                <FormActions className="form__actions--dialog p3 bg-white right-align full-width mt3">
                                    <button type="button" className="primary-grey mr2" onClick={() => onCancel(host)}>
                                        Cancel
                                    </button>
                                    <BouncerButton
                                        data-testid="save-host-button"
                                        bounce={submitting}
                                        disabled={!values.defaultCredentials && (pristine || invalid || submitting)}
                                        type="submit"
                                        className="primary-mainColor nowrap createEnvForm__submit"
                                    >
                                        Save
                                    </BouncerButton>
                                </FormActions>
                            </form>
                        )}
                    />
                </div>
            }
        </section>
    );
};

export default EditCredentials;
