import APIRequest from './Request';
import Deadlock, { DeadlockDataInterface } from 'models/queries/Deadlock';
import DeadlockPreview, { DeadlockPreviewDataInterface } from 'models/queries/DeadlockPreview';
import TimeInterval from 'models/TimeInterval';
import { getHost } from 'services/api/hosts';
import { RequestParamInterface } from './params/requestParamsEncoder';

export async function getAll(
    hostFilter: string,
    interval: TimeInterval,
    offsetDeadlock: undefined | DeadlockPreview,
    limit = 15
) {
    const offset = offsetDeadlock
        ? `${offsetDeadlock.timestamp},${offsetDeadlock.hostId},${offsetDeadlock.id}`
        : undefined;

    const result = await new APIRequest<
        DeadlockPreviewDataInterface[],
        RequestParamInterface,
        { hasMore: boolean; data: DeadlockPreviewDataInterface[] }
    >({
        path: 'queries/deadlocks',
        method: 'get',
        params: {
            ...interval.asParams(),
            host: hostFilter,
            limit: limit.toString(),
            offset,
        },
    }).request();

    for (const data of result.data) {
        const host = await getHost(interval, data.hostId);

        data.hostName = host?.name;
    }

    return {
        data: result.data.map(data => new DeadlockPreview(data)),
        hasMore: result.response.hasMore,
    };
}

export async function get(id: string, ts: string, hostFilter: string, interval: TimeInterval) {
    const result = await new APIRequest<DeadlockDataInterface | null>({
        path: 'queries/deadlocks',
        method: 'get',
        params: {
            ...interval.asParams(),
            host: hostFilter,
            id,
            ts,
        },
    }).request();

    return result.data === null ? null : new Deadlock(result.data);
}
