import { useEffect, useState, FC, Fragment } from 'react';
import { userIsAllowedTo } from 'services/security/accessControl';
import MachineRow from './Row';
import Host from 'models/hosts/Host';
import LoaderRow from 'components/loaders/LoaderRow';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import { getHostClusters } from 'services/api/hosts';
import { useTimeInterval } from 'components/context/TimeInterval';
import Logger from 'services/logger';
import InventoryControls, { InventoryType } from '../Controls';
import Paginator from 'components/lists/Paginator';
import InventoryPaginator from 'helpers/pagination/InventoryPaginator';
import NodeGroup from 'models/hosts/NodeGroup';

const logger = Logger.get('MachinesTable');

const Machines: FC = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [paginator, setPaginator] = useState<InventoryPaginator | null>(null);

    const [groups, setGroups] = useState<NodeGroup[] | null>(null);

    const [collapsedHosts, setCollapsedHosts] = useState<Host[]>([]);

    const { interval } = useTimeInterval();

    function setRowsStatus(expanded: boolean) {
        if (expanded === null) {
            return;
        }

        if (expanded) {
            setCollapsedHosts([]);
        } else {
            const newCollapsedHosts: Host[] = [];

            groups?.forEach(group => {
                newCollapsedHosts.push(group.host);
            });

            setCollapsedHosts(newCollapsedHosts);
        }
    }

    const fetchHosts = async () => {
        try {
            setIsLoading(true);
            const clusters = await getHostClusters(interval);

            const hostsPaginator = new InventoryPaginator(clusters.machines, clusters.hosts.length, {
                column: 'name',
                direction: 'asc',
            });

            setPaginator(hostsPaginator);
            setGroups(hostsPaginator.itemsInCurrentPage);
        } catch (e) {
            logger.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggleClick = (host: Host) => {
        const newCollapsedHosts = [...collapsedHosts];
        const index = newCollapsedHosts.indexOf(host);
        if (index > -1) {
            newCollapsedHosts.splice(index, 1);
        } else {
            newCollapsedHosts.push(host);
        }
        setCollapsedHosts(newCollapsedHosts);
    };

    const handleChangePage = (page: number) => {
        setGroups(() => {
            if (!paginator) {
                return null;
            }
            paginator.goToPage(page);
            return paginator.itemsInCurrentPage;
        });
    };

    const handleHostDeletion = () => {
        // Deleting a host from the clusters structure and updating the groups state is not trivial, so we're re-fetching the hosts after deletion.
        // This can be improved in a new iteration.
        fetchHosts();
    };

    const isCollapsed = (host: Host) => {
        return collapsedHosts.indexOf(host) > -1;
    };

    useEffect(() => {
        fetchHosts();
        // eslint-disable-next-line
    }, [interval]);

    return (
        <div className="view view--filters view--salesforce inventory">
            <InventoryControls type={InventoryType.machines} onExpandToggle={setRowsStatus} />

            <>
                {!isLoading && groups?.length === 0 && (
                    <EmptyState type={EmptyTypes.INFO}>
                        There are no machines configured for this environment.
                    </EmptyState>
                )}
                {!isLoading && groups === null && (
                    <EmptyState type={EmptyTypes.SERVER} reload={true}>
                        There was an error while fetching the hosts
                    </EmptyState>
                )}
                {(isLoading || (groups && groups.length > 0)) && (
                    <>
                        <table
                            className="vc-table vc-table--machines full-width pb3 vc-table--sortable"
                            data-testid="machines-table"
                        >
                            <thead>
                                <tr>
                                    <th className="vc-table--machines__toggler"></th>
                                    <th>Status</th>
                                    <th>Host</th>
                                    <th>Name and description</th>
                                    <th>Last seen</th>
                                    <th>Tags</th>
                                    {userIsAllowedTo('changeEnvSettings') && <th>Settings</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading && (
                                    <>
                                        <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 7 : 6} />
                                        <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 7 : 6} />
                                        <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 7 : 6} />
                                    </>
                                )}
                                {!isLoading &&
                                    groups?.map(group => {
                                        return (
                                            <Fragment key={group.host.id}>
                                                <MachineRow
                                                    host={group.host}
                                                    hasChildren={group.hosts.length > 0}
                                                    onToggle={handleToggleClick}
                                                    collapsed={isCollapsed(group.host)}
                                                    onHostDeletion={handleHostDeletion}
                                                />
                                                {!isCollapsed(group.host) &&
                                                    group.hosts.map(host => (
                                                        <MachineRow
                                                            key={`${host.id}_host`}
                                                            host={host}
                                                            hasChildren={false}
                                                            collapsed={isCollapsed(group.host)}
                                                            onHostDeletion={handleHostDeletion}
                                                        />
                                                    ))}
                                            </Fragment>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {paginator && paginator.pages.length > 0 ? (
                            <Paginator
                                itemsLength={paginator.pages.length * paginator.pageLimit}
                                perPage={paginator.pageLimit}
                                onPaging={handleChangePage}
                            />
                        ) : null}
                    </>
                )}
            </>
        </div>
    );
};

export default Machines;
