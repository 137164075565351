import { EdgeInterface, NodeInterface } from 'components/visualizations/deadlocks/Diagram';

interface DeadlockHeaderInterface {
    label: string;
    values: string[];
}

export interface DeadlockNodeSectionDetailDataInterface {
    label: string;
    values: string[] | null;
    ids: string[] | null;
}

interface DeadlockNodeSectionDetailInterface {
    type: 'table' | 'tabs';
    label: string;
    sections: null;
    data: DeadlockNodeSectionDetailDataInterface[];
}

interface DeadlockNodeDataInteface {
    name: string;
    sections: DeadlockNodeSectionDetailInterface[];
}

interface DeadlockNodeInterface {
    label: string;
    id: string;
    isVictim: boolean;
    type: 'process' | 'resource';
    data: DeadlockNodeDataInteface[];
}

interface DeadlockEdgeInterface {
    label: string;
    source: string;
    target: string;
}

export interface DeadlockDataInterface {
    hostId: number;
    header: DeadlockHeaderInterface[];
    nodes: DeadlockNodeInterface[];
    edges: DeadlockEdgeInterface[];
}

export class DeadlockNodeSection {
    type: 'table' | 'tabs';
    label: string;
    data: DeadlockNodeSectionDetailDataInterface[];
    emptyData: boolean;

    constructor(sectionData: DeadlockNodeSectionDetailInterface) {
        this.type = sectionData.type;
        this.label = sectionData.label;
        this.data = sectionData.data;
        this.emptyData = sectionData.data.reduce((empty: boolean, data) => empty && data.values === null, true);
    }
}

export class DeadlockNodeData {
    name: string;
    sections: DeadlockNodeSection[];

    constructor(data: DeadlockNodeDataInteface) {
        this.name = data.name;
        this.sections = data.sections.map(section => new DeadlockNodeSection(section));
    }
}

export default class Deadlock {
    constructor(private data: DeadlockDataInterface) {}

    get header() {
        return this.data.header;
    }

    get hostId() {
        return this.data.hostId;
    }

    get headerRows() {
        if (!this.header.length) {
            return [];
        }

        const rows: string[][] = [];

        this.header[0].values.forEach((value, j) => {
            const row: string[] = [];

            this.header.forEach((_, i) => {
                row.push(this.header[i].values[j]);
            });

            rows.push(row);
        });

        return rows;
    }

    get diagramNodes() {
        const nodes: NodeInterface[] = this.data.nodes.map(node => {
            const [label, ...value] = node.label.split(' ');

            return {
                id: node.id,
                display: {
                    label: label,
                    icon: resourceIcon(node),
                    value: value.join(' '),
                },
                extra: {},
                highlighted: node.isVictim,
            };
        });

        return nodes;
    }

    get diagramEdges() {
        const edges: EdgeInterface[] = this.data.edges.map(edge => ({
            display: { label: edge.label },
            source: edge.source,
            target: edge.target,
        }));

        return edges;
    }

    get defaultVictimNode() {
        return this.diagramNodes.find(node => node.highlighted);
    }

    getNodeDrillInSections(nodeId: number | string) {
        const deadlockNode = this.data.nodes.find(node => node.id === nodeId);
        const nodeData = deadlockNode?.data || [];

        return nodeData.map(data => new DeadlockNodeData(data));
    }
}

function resourceIcon(node: DeadlockNodeInterface) {
    if (node.isVictim) {
        return 'warning';
    }

    switch (node.type) {
        case 'process':
            return 'settings';
        case 'resource':
            return 'lock';
        default:
            return 'deadlocks';
    }
}
