export interface AuthMethodInterface {
    method: AuthMethodType;
    provider?: OauthProviderType;
}

export type AuthMethodType = 'basic' | 'oauth' | 'saml';
export type OauthProviderType = 'google' | 'github';

class AuthMethod implements AuthMethodInterface {
    readonly method: AuthMethodType;
    readonly provider?: OauthProviderType;

    constructor(method: AuthMethodType, provider?: OauthProviderType) {
        this.method = method;
        this.provider = provider;
    }

    static fromObject({ method, provider }: AuthMethodInterface): AuthMethod {
        return new AuthMethod(method, provider);
    }
}

export default AuthMethod;
