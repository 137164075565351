import BouncerButton from 'components/form/BouncerButton';
import ContactLink from 'components/messages/ContactLink';
import Icon from 'components/icons/Icon';
import Org from 'models/Org';
import PreviousOrgs from '../OrgLogin/PreviousOrgs';
import * as React from 'react';
import useUrl from 'hooks/useUrl';
import { AnyObject } from 'final-form';
import { Field } from 'react-final-form-html5-validation';
import { Form } from 'react-final-form';
import { get as getContext } from 'services/context';
import { Link } from 'react-router-dom';
import { orgHomeUrl } from 'services/routes';
import { useParams, useLocation } from 'react-router-dom';

const OrgSelectionForm: React.FC = () => {
    const bootstrap = getContext();
    const forgotOrganizationUrl = useUrl('find');
    let signupUrl = useUrl('signup');
    const { hash } = useParams<{ hash: string }>();
    const { search } = useLocation();

    const homeUrl = `${useUrl('orgHome')}marketplace/${hash}`;
    const searchParams = new URLSearchParams(search);
    const marketplaceSubscription = searchParams.get('subscription') || '';

    const onSubmit = (values: AnyObject) => {
        window.location.assign(orgHomeUrl(values.orgName, homeUrl));
    };

    const handleOrgNameChange = (value: string) => Org.nameToNickname(value);

    if (bootstrap.stage === 'stage') {
        signupUrl = 'http://dev.content.azure.solarwinds.com/database-performance-monitor/registration';
    }

    return (
        <>
            <h1 className="h2 dark center lighter">Welcome to SolarWinds Database Performance Monitor!</h1>

            <div className="rounded white flex mt3 items-start bg-semiDark p3">
                <Icon icon="info-outline" className="white h2 mr3" />
                <p className="mt1 line-height-120">
                    Please login to your existing DPM account to activate the licenses purchased through the AWS
                    Marketplace. If you have any questions or issues with this step contact our{' '}
                    <ContactLink>support team</ContactLink>.
                </p>
            </div>

            <div className="flex justify-center">
                <section className="card-block card-block--aws-account shadow bg-white relative flex mt4 mr2 flex-wrap">
                    <h2 className="fz18 regular relative full-width">I already have an account</h2>
                    <p className="lighter mt3 fz16 line-height-120">
                        If you already use SolarWinds DPM, login through your existing organization account. Licenses
                        you added through AWS will be reflected in the app and you can continue to use it as you
                        normally would.
                    </p>
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, submitting, invalid }) => (
                            <form id="org-form" onSubmit={handleSubmit}>
                                <fieldset className="mb3">
                                    <label>
                                        <b className="uppercase fz10 spacing inline-flex items-center">Organization:</b>
                                        <div className="relative my2 flex items-center">
                                            <Field
                                                id="organization"
                                                name="orgName"
                                                component="input"
                                                type="text"
                                                placeholder="Organization"
                                                autoComplete="organization"
                                                autoFocus
                                                className="pl4 flex-grow-1"
                                                parse={handleOrgNameChange}
                                                required
                                            />
                                            <b className="ml2 regular">.app.vividcortex.com</b>
                                            <Icon icon="business" className="grey2 h2 absolute mt1 top-0 left-2" />
                                        </div>
                                    </label>
                                </fieldset>

                                <fieldset>
                                    <div className="flex justify-between items-center">
                                        <Link
                                            id="forgot-org-link"
                                            to={`${forgotOrganizationUrl}/${hash}/${marketplaceSubscription}`}
                                        >
                                            I forgot my Organization
                                        </Link>

                                        <BouncerButton
                                            className="primary-mainColor"
                                            id="monitoring-org-name-submit"
                                            type="submit"
                                            disabled={invalid}
                                            bounce={submitting}
                                            bouncerClassName="white inline-flex ml2"
                                        >
                                            Continue
                                        </BouncerButton>
                                    </div>
                                </fieldset>
                            </form>
                        )}
                    />

                    {bootstrap.previousOrgs.length > 0 && (
                        <PreviousOrgs orgs={bootstrap.previousOrgs} homeUrl={homeUrl} titleClassName="fz14" />
                    )}
                </section>

                <section className="card-block card-block--aws-no-account shadow bg-white relative flex flex-column mt4 ml2">
                    <h2 className="fz18 regular relative">I don’t have an account</h2>
                    <div className="lighter fz16 line-height-120">
                        <p className="mt3">Create an account and organization to access your new DPM licenses.</p>
                        <p className="mt3">
                            You will automatically have all your licenses purchased through AWS assigned to your new
                            account.
                        </p>
                        <p className="mt3">
                            Once logged in, you can immediately start utilizing DPM and install your first hosts.
                        </p>
                    </div>
                    <a
                        className="cnt__link"
                        data-testid="sign-up-link"
                        href={`${signupUrl}?dpm_source=awsmp&dpm_state=${encodeURIComponent(marketplaceSubscription)}`}
                    >
                        <button className="primary-mainColor mt4">CREATE YOUR ACCOUNT</button>
                    </a>
                </section>
            </div>
        </>
    );
};

export default OrgSelectionForm;
