import { FC } from 'react';
import Icon from 'components/icons/Icon';

const OffsetPaginator: FC<{
    onFirstPage: boolean;
    onLastPage: boolean;
    onPageUp?: () => void;
    onPageDown?: () => void;
}> = ({ onFirstPage, onLastPage, onPageUp = () => {}, onPageDown = () => {} }) => {
    return (
        <div className="p3 flex justify-between">
            {!onFirstPage && (
                <button className="primary-grey flex items-center" onClick={onPageDown}>
                    <Icon icon="keyboard_arrow_left" className="fz18 white relative" /> Previous Page
                </button>
            )}
            {!onLastPage && (
                <button className="primary-grey flex items-center ml-auto" onClick={onPageUp}>
                    Next Page
                    <Icon icon="keyboard_arrow_right" className="fz18 white relative" />
                </button>
            )}
        </div>
    );
};

export default OffsetPaginator;
