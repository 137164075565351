import { createContext, FC, useState, useContext } from 'react';
import { FinalActionModalInterface } from 'components/modals/FinalAction';
import FinalActionModal from 'components/modals/FinalAction';

export interface FinalActionModalContextInterface {
    state: FinalActionModalInterface;
    openModal: (state: OpenFinalActionModalInterface) => void;
    closeModal: () => void;
}

interface OpenFinalActionModalInterface {
    onSubmit: () => Promise<void> | void;
    title: string;
    confirmMsg: string;
    buttonText?: string;
    onClose?: () => void;
    showWarning?: boolean;
}

const FinalActionModalContext = createContext<FinalActionModalContextInterface | undefined>(undefined);

const FinalActionModalProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState<FinalActionModalInterface>({
        visible: false,
        onSubmit: () => {},
        title: '',
        confirmMsg: '',
        showWarning: true,
    });
    const openModal = (newState: OpenFinalActionModalInterface) => {
        setState({ ...state, ...newState, visible: true });
    };
    const closeModal = () => {
        setState({ ...state, visible: false });
    };

    return (
        <FinalActionModalContext.Provider value={{ state, openModal, closeModal }}>
            {children}
            <FinalActionModal {...state} />
        </FinalActionModalContext.Provider>
    );
};

const useFinalActionModal = (): FinalActionModalContextInterface => {
    const context = useContext(FinalActionModalContext);
    if (context === undefined) {
        throw new Error('useFinalActionModal must be used within FinalActionModalProvider');
    }

    return context;
};

export { FinalActionModalProvider, useFinalActionModal, FinalActionModalContext };
