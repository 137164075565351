import Host from 'models/hosts/Host';
import * as React from 'react';
import NodeHostRow from './NodeHostRow';

const HostsNotInAnyGroupTable: React.FC<{
    hosts: Host[];
    onHostDeletion?: (host: Host) => void;
}> = ({ hosts, onHostDeletion = () => {} }) => {
    return (
        <>
            <div className="flex items-baseline px2 py3 groups__title">
                <h1 className="h2 mr2 lighter">Hosts not in any group</h1>
            </div>

            <div className="table-content">
                <table className="vc-table vc-table--machines vc-table--machines full-width px2 pb3">
                    <thead>
                        <tr>
                            <th className="vc-table--machines__toggler"></th>
                            <th className="servers-table__name" colSpan={3}>
                                Name
                            </th>
                            <th className="servers-table__host" colSpan={2}>
                                OS Host
                            </th>
                            <th className="servers-table__lastseen">Last Seen</th>
                            <th className="servers-table__tags">Tags</th>
                            <th className="servers-table__settings servers-table__column__small centered">Settings</th>
                        </tr>
                    </thead>

                    <tbody>
                        {hosts.map((host: Host) => (
                            <NodeHostRow host={host} key={host.id} onHostDeletion={onHostDeletion} />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default HostsNotInAnyGroupTable;
