import LoginForm from './Form';
import MetaTags from 'components/layout/MetaTags';

const LoginPage = () => (
    <>
        <MetaTags title="Login to your DPM account" />
        <LoginForm />
    </>
);

export default LoginPage;
