import { get, HttpError } from './http';

export interface CheckResultInterface {
    expired: boolean;
    error: boolean;
}

export default async function ping(pingUrl: string): Promise<CheckResultInterface> {
    try {
        await get(pingUrl);
        return { expired: false, error: false };
    } catch (e) {
        // 401 means the session expired
        const expired = e instanceof HttpError && e?.response?.status === 401;
        return Promise.reject({ expired, error: true });
    }
}
