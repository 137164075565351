import FeatureUrl, { FeatureUrlInterface } from './featureUrl';
import { match } from 'path-to-regexp';

export type AppName = 'webapp' | 'ng-app';

export interface ResolverInterface {
    getFeatureApp(url: string): AppName;

    handleFeatureUrl(url: string): FeatureUrlInterface;
}

export class Multiplexer implements ResolverInterface {
    constructor(readonly currentApp: AppName, readonly allowPaths: string[]) {}

    handleFeatureUrl(url: string): FeatureUrl {
        const app = this.getFeatureApp(url);
        const index = url.indexOf('?');
        const local = app === this.currentApp;

        if (!local) {
            url += (-1 === index ? '?' : '&') + `app=${encodeURIComponent(app)}`;
        }

        return new FeatureUrl(url, local);
    }

    getFeatureApp(url: string): AppName {
        const urlObj = new URL(
            url,
            'https://app.vividcortex.com' // optional base in case the URL does not contain a hostname
        );

        const found = this.allowPaths.find(path => match(path)(urlObj.pathname));

        return found ? 'webapp' : 'ng-app';
    }
}
