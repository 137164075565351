import { createContext, useContext, useReducer, FC, Dispatch } from 'react';
import { reducer, getInitialState, StateInterface, ActionInterface } from 'reducers/bootstrap';

type BootstrapContextType = [StateInterface, Dispatch<ActionInterface>];

const BootstrapComponent: FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <BootstrapContext.Provider value={useReducer(reducer, getInitialState())}>{children}</BootstrapContext.Provider>
    );
};

export const BootstrapContext = createContext<BootstrapContextType | undefined>(undefined);

export const useBootstrap = (): BootstrapContextType => {
    const context = useContext(BootstrapContext);

    if (context === undefined) {
        throw new Error('Trying to access undefined context');
    }

    return context;
};

export default BootstrapComponent;
