import { FC, useState } from 'react';
import Modal from 'components/modals/Modal';
import Team from 'models/Team';
import InviteCard from './InviteCard';
import { add as addUsers, UserAddedError } from 'services/api/users';
import useDisplayMessage from 'hooks/useDisplayMessage';
import User from 'models/User';
import { Form } from 'react-final-form';
import Icon from 'components/icons/Icon';
import { useLocation } from 'react-router-dom';

export interface InvitationInterface {
    position: number;
    name: string;
    email: string;
    team: Team;
}

function initialState(search: string) {
    const params = new URLSearchParams(search);

    return params.get('invite') !== null ? true : false;
}

const InviteUsersModal: FC<{ teams: Team[]; onSuccess: (users: User[]) => void; disabled: boolean }> = ({
    teams,
    onSuccess,
    disabled,
}) => {
    const location = useLocation();
    const [modalVisible, displayModal] = useState(initialState(location.search));
    const { error, success } = useDisplayMessage();

    const createInvite = (position: number) => {
        const invite: InvitationInterface = {
            position,
            name: '',
            email: '',
            team: teams[0],
        };
        return invite;
    };

    const [invitations, setInvitations] = useState<InvitationInterface[]>([createInvite(1)]);

    const addInvite = () => {
        const invites = [...invitations];
        const lastItem = invites[invites.length - 1].position;
        invites.push(createInvite(lastItem + 1));
        setInvitations(invites);
    };

    const handleRemoveInvite = (invitation: InvitationInterface) => {
        const invites = invitations.filter(invite => invite.position !== invitation.position);
        setInvitations(invites);
    };

    const handleInviteChange = (invitation: InvitationInterface) => {
        const invites = [...invitations];
        const foundIndex = invites.findIndex(invite => invite.position === invitation.position);
        invites[foundIndex] = invitation;
        setInvitations(invites);
    };

    const showModal = () => displayModal(true);

    const hideModal = () => {
        setInvitations([createInvite(1), createInvite(2)]);
        displayModal(false);
    };

    const sendInvitations = async () => {
        const newUsers = invitations.map(invitation => ({
            name: invitation.name,
            email: invitation.email,
            teams: [invitation.team.id],
        }));

        try {
            const response = await addUsers(newUsers);
            const users = response.data;

            if (users.length) {
                users.forEach((user: User) => {
                    success(`${user.email} successfully invited`);
                });

                //assign the corresponding teams to the currently added Users
                users.forEach(user => {
                    const invitation = invitations.find(({ email }) => email === user.email);
                    if (invitation) {
                        invitation.team.addUsers([user]);
                        user.setTeams([invitation.team]);
                    }
                });
                onSuccess(users);
            }

            if (response.errors && (response.errors as UserAddedError[]).length) {
                (response.errors as UserAddedError[]).forEach((e: UserAddedError) => {
                    error(`${e.user.email} failed to be invited`);
                });
            }
        } catch {
            error('There was an error while trying to invite the user(s). Please try again later.');
        } finally {
            hideModal();
        }
    };

    return (
        <>
            <button className="flex dropdown--right primary-orange" disabled={disabled} onClick={showModal}>
                Invite coworkers
            </button>

            <Modal visible={modalVisible} title="Invite multiple coworkers" onClose={hideModal}>
                <div className="invite-workers">
                    <div className="invite-form__form-wrap">
                        <Form
                            onSubmit={sendInvitations}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div>
                                        <p className="invite-form__desc h4 lighter p3 line-height-120">
                                            Enter the information for one or more coworkers you would like to invite. We
                                            will send them an email invitation with instructions to join this
                                            organization.
                                        </p>
                                        <div>
                                            <div className="form__invites">
                                                {invitations.map(invitation => (
                                                    <InviteCard
                                                        isRemovable={invitations.length > 1}
                                                        key={invitation.position}
                                                        invitation={invitation}
                                                        onRemoveInvite={handleRemoveInvite}
                                                        onInviteChange={handleInviteChange}
                                                        teams={teams}
                                                    />
                                                ))}

                                                <div className="pb3 pl3 pr3">
                                                    <button
                                                        onClick={() => addInvite()}
                                                        className="mainColor inline-flex cursor-hand"
                                                    >
                                                        <Icon icon="add" className="mainColor" />
                                                        Add another
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex p3 items-center justify-end bg-white">
                                        <button onClick={hideModal} className="secondary-grey mr2 ml4">
                                            Cancel
                                        </button>
                                        <button type="submit" className="primary-mainColor nowrap">
                                            Invite
                                        </button>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default InviteUsersModal;
