import Icon from 'components/icons/Icon';
import Dropdown from 'components/dropdowns/Dropdown';
import Feature from 'components/serviceLevel/Feature';
import FeatureLink from 'components/url/FeatureLink';
import { FC, useState } from 'react';
import useAuthenticatedContext from 'hooks/useAuthenticatedContext';
import { UserHasPermission } from 'components/security/UserHasPermission';

const AddButton: FC = () => {
    const [open, setOpen] = useState(false);
    const context = useAuthenticatedContext();

    if (!context.env) {
        return null;
    }

    const handleOnToggle = (state: boolean) => {
        if (!state) {
            setOpen(false);
        }
    };

    return (
        <UserHasPermission to="changeEnv">
            <div
                className={`flex relative full-width mt1 navigation__group navigation__group--quick-add${
                    open ? ' navigation__group--quick-add--open' : ''
                }`}
                data-testid="add-button-component"
            >
                <button
                    className="navigation__group--quick-add__button"
                    data-testid="add-button-component-trigger"
                    onClick={() => setOpen(!open)}
                >
                    <div className="flex items-center">
                        <Icon
                            icon="plus-sign"
                            className="menu__item__title__icon menu__item__title__icon--quick-add black h2 my2 mr3"
                        />
                        <span className="white uppercase navigation__group--quick-add__button__label">Create</span>
                    </div>
                </button>
                <Dropdown
                    isExpanded={open}
                    onToggle={handleOnToggle}
                    className="add_dropdown navigation__group--quick-add__dropdown"
                >
                    <div className="bg-paleDark white z1 py2 rounded">
                        <ul className="list-reset" onClick={() => setOpen(false)} data-testid="add-button-links">
                            <UserHasPermission to="changeEnvSettings">
                                <li className="navigation__group--quick-add__item">
                                    <FeatureLink
                                        to="alerts/new"
                                        className="px2 py2 flex full-width items-center no-decoration nowrap"
                                    >
                                        <span className="white h4 mx2">Create Alert</span>
                                    </FeatureLink>
                                </li>
                                <li className="navigation__group--quick-add__item">
                                    <FeatureLink
                                        to="settings/integrations/new"
                                        className="px2 py2 flex full-width items-center no-decoration nowrap"
                                    >
                                        <span className="white h4 mx2">Add Integration</span>
                                    </FeatureLink>
                                </li>
                            </UserHasPermission>
                            <Feature name="notebooks">
                                <li className="navigation__group--quick-add__item">
                                    <FeatureLink
                                        to="notebooks?notbeookId=-1"
                                        className="px2 py2 flex full-width items-center no-decoration nowrap"
                                    >
                                        <span className="white h4 mx2">Create Notebook</span>
                                    </FeatureLink>
                                </li>
                            </Feature>
                            <li className="navigation__group--quick-add__item">
                                <FeatureLink
                                    to="inventory/machines?add"
                                    className="px2 py2 flex full-width items-center no-decoration nowrap"
                                >
                                    <span className="white h4 mx2">Install Host</span>
                                </FeatureLink>
                            </li>
                            <UserHasPermission to="inviteUsers">
                                <li className="navigation__group--quick-add__item rounded">
                                    <FeatureLink
                                        to="/settings/people?invite"
                                        className="px2 py2 flex full-width items-center no-decoration nowrap"
                                        appendEnv={false}
                                    >
                                        <span className="white h4 mx2">Invite Colleagues</span>
                                    </FeatureLink>
                                </li>
                            </UserHasPermission>
                            <li className="navigation__group--quick-add__item rounded">
                                <FeatureLink
                                    to="/settings/licenses"
                                    className="px2 py2 flex full-width items-center no-decoration nowrap"
                                    appendEnv={false}
                                >
                                    <span className="white h4 mx2">Add License</span>
                                </FeatureLink>
                            </li>
                        </ul>
                    </div>
                </Dropdown>
            </div>
        </UserHasPermission>
    );
};

export default AddButton;
