import LoginError from 'models/errors/LoginError';
import ServiceError from 'models/errors/ServiceError';
import Logger from 'services/logger';
import { post, HttpError } from 'services/http';

interface XhrResponseInterface {
    success?: { code: string };
}

export interface LoginDataType {
    username: string;
    password: string;
    rememberMe: boolean;
    captcha: string | null;
}

export enum LoginErrorCode {
    SAML = 'saml-auth-error',
    CREDENTIALS = 'bad-credentials',
    ACCOUNT_DISABLED = 'account-disabled',
    ACCOUNT_LOCKED = 'account-locked',
    ORG_LOCKED = 'org-locked',
    CSRF = 'csrf-failure',
    TRIAL_EXPIRED = 'trial-org-locked',
    UNKNOWN = 'unknown',
}

const errorStatus = [
    LoginErrorCode.SAML,
    LoginErrorCode.CREDENTIALS,
    LoginErrorCode.ACCOUNT_DISABLED,
    LoginErrorCode.ACCOUNT_LOCKED,
    LoginErrorCode.ORG_LOCKED,
    LoginErrorCode.CSRF,
    LoginErrorCode.TRIAL_EXPIRED,
    LoginErrorCode.UNKNOWN,
];

const logger = Logger.get('LoginService');

function prepareData({ username, password, rememberMe, captcha }: LoginDataType): URLSearchParams {
    const data = new URLSearchParams();
    data.set('_username', username);
    data.set('_password', password);
    if (rememberMe) {
        data.set('_remember_me', '1');
    }
    if (captcha) {
        data.set('_recaptcha', captcha);
    }

    return data;
}

export const doLogin = async (endpoint: string, data: LoginDataType) => {
    try {
        await post(endpoint, prepareData(data), {
            expectedStatus: [401],
            reportExpectedAsInfo: true,
            context: 'User login',
        });

        logger.debug('[sign-in] Auth succeeded');

        return true;
    } catch (error) {
        let errorResponse = error instanceof HttpError && error?.response?.data ? error.response.data.error : '';
        let displayCaptcha = false;

        // Default to 'unknown' when an unexpected server error happens
        // otherwise the user see no message at all.
        if (errorStatus.indexOf(errorResponse) < 0) {
            errorResponse = LoginErrorCode.UNKNOWN;

            logger.error('UNEXPECTED_LOGIN_CHECK_RESPONSE', error instanceof Error ? error.message : 'Unknown error');
        }

        logger.error('[sign-in] Auth failed');

        if (error instanceof HttpError && error?.response?.headers && error.response.headers['X-VC-Login-ReCaptcha']) {
            displayCaptcha = true;
        }

        throw new LoginError(errorResponse, displayCaptcha);
    }
};

export const requestOrgReminder = async (endpoint: string, data: { email: string }) => {
    const successCode = 'reminder-sent';

    try {
        const response = await post<XhrResponseInterface>(endpoint, data);

        if (response.data.success?.code === successCode) {
            return true;
        }

        throw new ServiceError(`Unexpected response for ${endpoint}`);
    } catch (e) {
        logger.error('[find-org] error sending request', e);

        throw e;
    }
};

export const requestPasswordReset = async (endpoint: string, data: { email: string }) => {
    const successCode = 'reset-email-sent';

    try {
        const response = await post<XhrResponseInterface>(endpoint, data);

        if (response.data.success?.code === successCode) {
            return true;
        }

        throw new ServiceError(`Unexpected response for ${endpoint}`);
    } catch (e) {
        logger.error('[password-reset] error sending request', e);

        throw e;
    }
};

export const requestPasswordChange = async (endpoint: string, data: { password: string }) => {
    const successCode = 'password-changed';

    try {
        const response = await post<XhrResponseInterface>(endpoint, data);

        if (response.data.success?.code === successCode) {
            return true;
        }

        throw new ServiceError(`Unexpected response for ${endpoint}`);
    } catch (e) {
        logger.error('[password-change] error sending request', e);

        throw e;
    }
};

export const selectOauthEmail = async (endpoint: string, data: { email: string }) => {
    try {
        const response = await post<XhrResponseInterface>(endpoint, data);

        if (response.data.success) {
            logger.debug('[sign-in] Auth succeeded');

            return true;
        }

        throw new ServiceError(`Unexpected response for ${endpoint}`);
    } catch (error) {
        if (!(error instanceof HttpError) || !error.response || !error.response.data.error) {
            throw error;
        }

        throw new ServiceError(`Error response for ${endpoint}: ${error.response.data.error}`);
    }
};
