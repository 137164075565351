import { actions } from 'reducers/globalMessages';
import { useGlobalMessages } from 'components/context/GlobalMessages';
import md5 from 'blueimp-md5';
import { GlobalMessageTypes } from '../components/messages/GlobalMessage';
import { useState } from 'react';

const useDisplayMessage = () => {
    const [{ messages }, dispatch] = useGlobalMessages();
    const DEFAULT_TIMEOUT = 5e3;
    const [sourceId] = useState(Math.random().toString(36).substring(2));

    const error = (message: string, dismissable?: boolean) => push(GlobalMessageTypes.ERROR, message, dismissable);
    const warning = (message: string, dismissable?: boolean) => push(GlobalMessageTypes.WARNING, message, dismissable);
    const success = (message: string, dismissable?: boolean, timeout = DEFAULT_TIMEOUT) => {
        push(GlobalMessageTypes.SUCCESS, message, dismissable, timeout);
    };

    const dismiss = (id: string) => {
        dispatch(actions.fadeout(id));
        setTimeout(() => dispatch(actions.dismiss(id)), 500);
    };

    const push = (type: GlobalMessageTypes, message: string, dismissable?: boolean, timeout?: number) => {
        const id = md5(`${message}${Date.now()}`);
        messages.forEach(msg => {
            // Dismiss duplicate messages or dismiss all messages from same source in case of success
            if ((type === GlobalMessageTypes.SUCCESS && msg.sourceId === sourceId) || msg.message === message) {
                dismiss(msg.id);
            }
        });
        dispatch(actions.push(message, id, sourceId, type, dismissable));
        if (timeout) {
            setTimeout(() => dismiss(id), timeout);
        }
    };

    return { error, warning, success, dismiss };
};

export default useDisplayMessage;
