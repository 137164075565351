import APIError from 'services/api/error/Error';
import APIRequest, { TargetEnv } from './Request';
import Env, { EnvDataInterface } from 'models/Env';
import Logger from 'services/logger';
import { AuthenticatedBootstrap } from 'models/Bootstrap';
import { get as getContext } from 'services/context';

const logger = Logger.get('APIEnvs');

export enum ResponseErrorMessage {
    FAILURE = 'failure',
    UNKNOWN = 'unknown',
    DUPLICATE = 'duplicate',
    QUOTA_LIMIT = 'quota-limit',
}

export const create = async (name: string) => {
    try {
        const response = await new APIRequest<EnvDataInterface>({
            path: 'env',
            target: TargetEnv.CURRENT_ORG,
            method: 'post',
            params: {
                name,
            },
        }).request();

        logger.debug(`added env ${name}`);

        const env = new Env(response.data);
        const context = getContext() as AuthenticatedBootstrap;

        context.addEnv(env);

        return env;
    } catch (error) {
        logger.error(error);

        let message = ResponseErrorMessage.UNKNOWN;
        if (error instanceof APIError && 409 === error.status) {
            message = ResponseErrorMessage.DUPLICATE;
        } else if (error instanceof APIError && 403 === error.status) {
            message = ResponseErrorMessage.QUOTA_LIMIT;
        }

        throw new Error(message);
    }
};

export const remove = async (env: Env) => {
    try {
        await new APIRequest<null>({
            path: `env/${env.id}`,
            target: TargetEnv.CURRENT_ORG,
            method: 'delete',
        }).request();

        const context = getContext() as AuthenticatedBootstrap;
        context.removeEnv(env);

        logger.debug(`removed env ${env.name}`);
    } catch (error) {
        logger.error(error);

        const message =
            error instanceof APIError && 400 === error.status
                ? ResponseErrorMessage.FAILURE
                : ResponseErrorMessage.UNKNOWN;

        throw new Error(message);
    }
};

export const rename = async (env: Env, newName: string) => {
    try {
        await new APIRequest<null>({
            path: `env/${env.id}`,
            target: TargetEnv.CURRENT_ORG,
            method: 'put',
            params: {
                name: newName,
            },
        }).request();

        const context = getContext() as AuthenticatedBootstrap;
        context.renameEnv(env, newName);

        return true;
    } catch (error) {
        const message =
            error instanceof APIError && 400 === error.status
                ? ResponseErrorMessage.FAILURE
                : ResponseErrorMessage.DUPLICATE;

        throw new Error(message);
    }
};
