import * as React from 'react';
import SlideSwitch from 'components/form/SlideSwitch';
import { FieldRenderProps } from 'react-final-form';

export interface SlideSwitchFieldPropsInterface extends FieldRenderProps<string, HTMLInputElement> {
    helpText?: string;
    className?: string;
    type?: string;
}

const SlideSwitchField: React.FC<SlideSwitchFieldPropsInterface> = ({
    input,
    meta: { touched, error },
    helpText,
    className,
    ...rest
}) => {
    return (
        <>
            <div className="flex items-center form__switch">
                <SlideSwitch onChange={input.onChange} {...rest} />
            </div>
            {error && touched && <div className="red">{error}</div>}
            {helpText && <p className="grey3">{helpText}</p>}
        </>
    );
};

export default SlideSwitchField;
