import useDisplayMessage from 'hooks/useDisplayMessage';
import Host from 'models/hosts/Host';
import { update as updateHost } from 'services/api/hosts';

export default function useHostAttributeUpdate(attr: 'description' | 'name') {
    const { success, error } = useDisplayMessage();

    return async (host: Host, value: string) => {
        const originalValue = host[attr];
        try {
            host[attr] = value;
            await updateHost(host);

            success(`The host ${host.name} was successfully updated`);
        } catch {
            const message = `The host ${host.name} could not be updated. Please try again later.`;

            error(message);
            host[attr] = originalValue;
        }
    };
}
