import * as React from 'react';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { useEffect, useState } from 'react';

const OfflineIndicator: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [offline, setOffline] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const { success, error } = useDisplayMessage();

    useEffect(() => {
        function listener(event: Event) {
            const detail = (event as CustomEvent).detail;
            setOffline(detail === 'offline');
            setShowAlert(true);
        }
        document.addEventListener('networkStateChanged', listener);

        return () => document.removeEventListener('networkStateChanged', listener);
    }, []);

    useEffect(() => {
        if (showAlert) {
            if (offline) {
                error('Connection lost, check your internet connection.');
            } else {
                success('Connected again!');
            }
            setShowAlert(false);
        }
    }, [error, offline, showAlert, success]);

    return <div className={offline ? 'vc-offline' : ''}>{children}</div>;
};

export default OfflineIndicator;
