import { AnyObject } from 'final-form';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { orgHomeUrl } from 'services/routes';
import BouncerButton from 'components/form/BouncerButton';
import Heading from 'components/modules/public/Heading';
import Icon from 'components/icons/Icon';
import JoinBlock from './JoinBlock';
import Org from 'models/Org';
import PreviousOrgs from './PreviousOrgs';
import useUrl from 'hooks/useUrl';
import { Link } from 'react-router-dom';
import { get as getContext } from 'services/context';

const OrgSelectionForm = () => {
    const bootstrap = getContext();
    const forgotOrganizationUrl = useUrl('find');
    const homeUrl = useUrl('orgHome');

    const onSubmit = (values: AnyObject) => {
        window.location.assign(orgHomeUrl(values.orgName, homeUrl));
    };

    const handleOrgNameChange = (value: string) => Org.nameToNickname(value);

    return (
        <>
            <Heading>Login to your organization at DPM</Heading>

            <section className="card-block  shadow mx-auto my4 relative flex card-block__login--org">
                <div className="flex card-block__login__join">
                    <JoinBlock />
                </div>

                <div className="bg-white flex-grow-1 p3">
                    <Form
                        onSubmit={onSubmit}
                        render={({ handleSubmit, submitting, invalid }) => (
                            <form id="org-form" onSubmit={handleSubmit}>
                                <h2 className="fz18 regular mb3">Member Login</h2>
                                <fieldset className="mb3">
                                    <label>
                                        <b className="uppercase fz10 spacing inline-flex items-center">Organization:</b>
                                        <div className="relative my2 flex items-center">
                                            <Field
                                                id="organization"
                                                name="orgName"
                                                component="input"
                                                type="text"
                                                placeholder="Organization"
                                                autoComplete="organization"
                                                autoFocus
                                                className="pl4 flex-grow-1"
                                                parse={handleOrgNameChange}
                                                required
                                            />
                                            <b className="ml2">.app.vividcortex.com</b>
                                            <Icon icon="business" className="grey2 h2 absolute mt1 top-0 left-2" />
                                        </div>
                                    </label>
                                </fieldset>

                                <fieldset className="mb3">
                                    <div className="flex justify-between items-center">
                                        <Link id="forgot-org-link" to={forgotOrganizationUrl}>
                                            I forgot my Organization
                                        </Link>

                                        <BouncerButton
                                            className="primary-mainColor"
                                            id="monitoring-org-name-submit"
                                            type="submit"
                                            disabled={invalid}
                                            bounce={submitting}
                                            bouncerClassName="white inline-flex ml2"
                                        >
                                            Continue
                                        </BouncerButton>
                                    </div>
                                </fieldset>
                            </form>
                        )}
                    />

                    {bootstrap.previousOrgs.length > 0 && (
                        <PreviousOrgs orgs={bootstrap.previousOrgs} homeUrl={homeUrl} />
                    )}
                </div>
            </section>
        </>
    );
};

export default OrgSelectionForm;
