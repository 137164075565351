export interface DeadlockPreviewDataInterface {
    id: string;
    hostId: number;
    hostName?: string;
    timestamp: number;
}

export default class DeadlockPreview {
    constructor(private data: DeadlockPreviewDataInterface) {}

    get id() {
        return this.data.id;
    }

    get hostId() {
        return this.data.hostId;
    }

    get hostName() {
        return this.data.hostName || '';
    }

    get timestamp() {
        return this.data.timestamp;
    }
}
