import { useBootstrap } from 'components/context/Bootstrap';
import { AuthenticatedBootstrap } from 'models/Bootstrap';

const useEnvs = () => {
    const [{ bootstrap }] = useBootstrap();

    if (!(bootstrap instanceof AuthenticatedBootstrap)) {
        throw new Error('Trying to access the org within an unauthenticated context.');
    }

    return (bootstrap as AuthenticatedBootstrap).envs;
};

export default useEnvs;
