import EditEnvironment from './Edit';
import Modal from 'components/modals/Modal';
import { FC } from 'react';

interface CreateEnvironmentPropsInterface {
    visible: boolean;
    onClose: () => void;
    onSuccess: (name?: string) => void;
}

const CreateEnvironment: FC<CreateEnvironmentPropsInterface> = ({ visible, onClose, ...props }) => {
    return (
        <Modal title="Create New Environment" visible={visible} onClose={onClose}>
            <EditEnvironment {...props} onClose={onClose} />
        </Modal>
    );
};

export default CreateEnvironment;
