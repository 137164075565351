import { useState, useEffect } from 'react';
import SettingsLayout from '../Layout';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import FormActions from 'components/form/Actions';
import SlideSwitch from 'components/form/fields/SlideSwitchField';
import TextField from 'components/form/fields/TextField';
import CheckboxField from 'components/form/fields/CheckboxField';
import BouncerButton from 'components/form/BouncerButton';
import Bouncer from 'components/icons/Bouncer';
import SDVInfo from 'components/messages/SDVInfo';
import { get as getAgentConfig, save as saveAgentConfig } from 'services/api/agentConfig';
import useDisplayMessage from 'hooks/useDisplayMessage';

const SettingsQueryData = () => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [state, setState] = useState<{ [key: string]: string | boolean | null }>({
        'disable-sampling': false,
        'disable-sampling-text': false,
        'force-offhost-samples': false,
        'query-blacklist-pattern': null,
        'query-whitelist-pattern': null,
        'query-comment-pattern': null,
        'tag-delimiters': null,
    });
    const { success, error } = useDisplayMessage();

    const fetchConfig = async () => {
        const response = await getAgentConfig();
        setState({
            ...state,
            ...response,
            'disable-sampling': response['disable-sampling'] === 'true',
            'disable-sampling-text': response['disable-sampling-text'] === 'true',
            'force-offhost-samples': response['force-offhost-samples'] === 'true',
        });

        setLoading(false);
    };

    const onSubmit = async (values: { [key: string]: string | boolean | null }) => {
        // If custom tag delimiters switch is enabled, then child fields must be completed.
        if (values['tag-delimiters-enabled'] && (!values['tag-delimiters-char0'] || !values['tag-delimiters-char1'])) {
            error('You must set both tag delimiter fields if you enable custom tag delimiters.');
            return;
        }

        setSaving(true);

        const settings = {
            'disable-sampling': (!values['enable-sampling']).toString(),
            'disable-sampling-text': (values['enable-sampling'] && values['disable-sampling-text'])?.toString() || null,
            'force-offhost-samples': values['force-offhost-samples']?.toString() || null,
            'query-blacklist-pattern': values['query-blacklist-pattern'] || null,
            'query-whitelist-pattern': values['query-whitelist-pattern'] || null,
            'query-comment-pattern': values['query-comment-pattern'] || null,
            'tag-delimiters': values['tag-delimiters-enabled']
                ? (((values['tag-delimiters-char0'] as string) + values['tag-delimiters-char1']) as string)
                : null,
        };

        try {
            await saveAgentConfig(settings);
            success('Settings have been updated.');
            setState({
                ...settings,
                'disable-sampling': settings['disable-sampling'] === 'true',
                'disable-sampling-text': settings['disable-sampling-text'] === 'true',
                'force-offhost-samples': settings['force-offhost-samples'] === 'true',
            });
        } catch {
            error('There was an error while trying to save your settings.');
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchConfig();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SettingsLayout title="Query Data Settings">
            {loading && <Bouncer />}
            {!loading && (
                <>
                    <div className="settings__form settings__form--query-data mt3">
                        <SDVInfo fullSize></SDVInfo>
                    </div>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            'enable-sampling': !state['disable-sampling'],
                            'disable-sampling-text': state['disable-sampling-text'],
                            'force-offhost-samples': state['force-offhost-samples'],
                            'query-blacklist-pattern': state['query-blacklist-pattern'],
                            'query-whitelist-pattern': state['query-whitelist-pattern'],
                            'query-comment-pattern': state['query-comment-pattern'],
                            'tag-delimiters-enabled': state['tag-delimiters'] !== null,
                            'tag-delimiters-char0': (state['tag-delimiters'] as string)?.charAt(0) || '',
                            'tag-delimiters-char1': (state['tag-delimiters'] as string)?.charAt(1) || '',
                        }}
                        render={({ pristine, invalid, handleSubmit, values }) => {
                            const tagDelimitersEnabled = values['tag-delimiters-enabled'];
                            const samplingEnabled = values['enable-sampling'];

                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className="settings__form settings__form--query-data mt3"
                                    name="queryDataForm"
                                >
                                    <div className="relative">
                                        <FormGroup
                                            variant={FormGroupVariantType.HORIZONTAL}
                                            className="mt3 field-inline-labels"
                                            label="Enable sampling:"
                                        >
                                            <Field name="enable-sampling">
                                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                    <SlideSwitch
                                                        {...props}
                                                        name="enable-sampling"
                                                        checked={!state['disable-sampling']}
                                                        value={props.input.checked}
                                                        helpText="Enabling samples allows Database Performance Monitor to collect raw text of samples in this environment and provide richer detail into query activity. Selecting “Collect only metadata” will collect metadata for individual queries, such as connection ID, query latency, and EXPLAIN plans, but not raw query samples."
                                                        data-testid="enable-sampling-switch"
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                        <FormGroup className="absolute settings__form__collect-metadata">
                                            <Field type="checkbox" name="disable-sampling-text">
                                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                    <CheckboxField
                                                        {...props}
                                                        label="Collect only metadata"
                                                        id="disable-sampling-text-checkbox"
                                                        disabled={!samplingEnabled}
                                                        data-testid="disable-sampling-text-checkbox"
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </div>
                                    <FormGroup
                                        variant={FormGroupVariantType.HORIZONTAL}
                                        className="mt3"
                                        label="Force off host samples"
                                    >
                                        <Field name="force-offhost-samples">
                                            {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                <SlideSwitch
                                                    {...props}
                                                    name="force-offhost-samples"
                                                    checked={state['force-offhost-samples']}
                                                    value={props.input.checked}
                                                    helpText="Allow the agent to automatically configure the performance schema to collect queries. The agent must have the update grant on performance_schema.setup_consumers."
                                                    data-testid="force-offhost-samples-switch"
                                                />
                                            )}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup
                                        className="mt3"
                                        variant={FormGroupVariantType.HORIZONTAL}
                                        label="Blocklist queries"
                                    >
                                        <Field name="query-blacklist-pattern">
                                            {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                <TextField
                                                    {...props}
                                                    helpText="Regular expression pattern describing queries that should NOT be sampled."
                                                    data-testid="query-blacklist-pattern-input"
                                                />
                                            )}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup
                                        className="mt3"
                                        variant={FormGroupVariantType.HORIZONTAL}
                                        label="Safelist queries"
                                    >
                                        <Field name="query-whitelist-pattern">
                                            {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                <TextField
                                                    {...props}
                                                    helpText="Regular expression pattern describing queries that should be sampled."
                                                    data-testid="query-whitelist-pattern-input"
                                                />
                                            )}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup
                                        className="mt3"
                                        variant={FormGroupVariantType.HORIZONTAL}
                                        label="Query comments"
                                    >
                                        <Field name="query-comment-pattern">
                                            {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                <TextField
                                                    {...props}
                                                    helpText="Regular expression pattern describing the part of comments to capture."
                                                    data-testid="query-comment-pattern-input"
                                                />
                                            )}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup
                                        className="mt3 field-inline-labels"
                                        variant={FormGroupVariantType.HORIZONTAL}
                                        label="Set custom query tag delimiter:"
                                    >
                                        <Field name="tag-delimiters-enabled">
                                            {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                <SlideSwitch
                                                    {...props}
                                                    name="tag-delimiters"
                                                    checked={state['tag-delimiters']}
                                                    value={props.input.checked}
                                                    helpText="Set custom delimiters below for parsing query tags. Turning off this setting will revert to default values."
                                                    data-testid="tag-delimiters-enabled-switch"
                                                />
                                            )}
                                        </Field>
                                        <div className="settings__form--query-data__delimiters mt3 grey3">
                                            <Field name="tag-delimiters-char0">
                                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                    <TextField
                                                        {...props}
                                                        disabled={!tagDelimitersEnabled}
                                                        append="The character that separates tags in your query."
                                                        data-testid="tag-delimiters-char0-input"
                                                    />
                                                )}
                                            </Field>

                                            <Field name="tag-delimiters-char1">
                                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                                    <TextField
                                                        {...props}
                                                        disabled={!tagDelimitersEnabled}
                                                        append="The character that separates tags pairs from each other. An empty space is allowed."
                                                        data-testid="tag-delimiters-char1-input"
                                                    />
                                                )}
                                            </Field>
                                        </div>
                                    </FormGroup>
                                    <FormActions className="mt3">
                                        <BouncerButton
                                            bounce={loading || saving}
                                            onClick={handleSubmit}
                                            disabled={pristine || invalid || loading || saving}
                                            className="primary-orange"
                                            data-testid="save-new-team"
                                        >
                                            Update
                                        </BouncerButton>
                                    </FormActions>
                                </form>
                            );
                        }}
                    />
                </>
            )}
        </SettingsLayout>
    );
};

export default SettingsQueryData;
