import { memo, useEffect, useRef } from 'react';
import { EdgeInterface, NodeInterface } from 'models/VisualExplainPlan';
import Quantity from 'models/numbers/Quantity';
import { fromName as unitFromName } from 'models/numbers/Unit';
import { DiagramNode, useDiagramHover } from 'components/diagram';

export interface DiagramNodeInterface {
    nodes: ReadonlyArray<DiagramNode<NodeInterface>>;
    selectedNode: NodeInterface | null;
    onNodeClick: (node: NodeInterface) => void;
}

const ratio = unitFromName('ratio');

const DiagramNodes = memo<DiagramNodeInterface>(({ nodes, selectedNode, onNodeClick }) => {
    const { isNodeHovered, onNodeHover, onMouseOut, isAnythingHovered } = useDiagramHover<
        NodeInterface,
        EdgeInterface
    >();

    const mouseStartPos = useRef<{ clientX: number; clientY: number } | null>(null);

    useEffect(() => {
        if (selectedNode) {
            onNodeHover(selectedNode);
        } else {
            onMouseOut();
        }
    }, [selectedNode, onMouseOut, onNodeHover]);

    return (
        <>
            {nodes.map(({ node, x, y }) => {
                const label = node.label.length > 19 ? node.label.substr(0, 16).trim().concat('...') : node.label;

                const costPerc = new Quantity(node.display.cost.percentage, ratio);

                return (
                    // adjust x & y since location passed is based on the center of the element
                    <svg
                        aria-selected={selectedNode === node}
                        x={x - 90 - 5}
                        y={y - 40 - 5}
                        className={`cursor-hand vc-viz-diagram-node${
                            selectedNode === node ? ' vc-viz-diagram-node--selected' : ''
                        }`}
                        tabIndex={0}
                        onMouseDown={e => {
                            mouseStartPos.current = {
                                clientX: e.clientX,
                                clientY: e.clientY,
                            };
                        }}
                        onMouseUp={e => {
                            if (
                                mouseStartPos.current &&
                                mouseStartPos.current.clientX === e.clientX &&
                                mouseStartPos.current.clientY === e.clientY
                            ) {
                                onNodeClick(node);
                            }
                            mouseStartPos.current = null;
                        }}
                        onKeyPress={e => (e.key === 'Enter' ? onNodeClick(node) : null)}
                        opacity={!isAnythingHovered || isNodeHovered(node) ? 1 : 0.3}
                        key={node.id}
                    >
                        <title>{`${node.label} - Cost: ${node.display.cost.value} | ${costPerc.toString()}`}</title>
                        <rect
                            x={5}
                            y={5}
                            rx={6}
                            width={180}
                            height={80}
                            fill="var(--white)"
                            stroke="var(--mainColor)"
                            className="vc-viz-diagram-node__rect"
                        />

                        <text x={20} y={30} className="fz16 vc-viz-diagram-node__label" fill="black" fontWeight="bold">
                            {label}
                        </text>

                        <g>
                            <linearGradient id={`backgroundBarGradient${node.id}`}>
                                <stop
                                    offset={node.display.cost.percentage}
                                    stopOpacity={1}
                                    stopColor="var(--mainColor)"
                                >
                                    <animate
                                        attributeName="offset"
                                        dur="1s"
                                        from="0"
                                        to={node.display.cost.percentage}
                                    />
                                </stop>
                                <stop offset={node.display.cost.percentage} stopOpacity={1} stopColor="var(--grey1)">
                                    <animate
                                        attributeName="offset"
                                        dur="1s"
                                        from="0"
                                        to={node.display.cost.percentage}
                                    />
                                </stop>
                            </linearGradient>
                            <rect
                                x={20}
                                y={42}
                                fill={`url(#backgroundBarGradient${node.id})`}
                                rx="6"
                                height="10"
                                width="146"
                            />
                        </g>

                        <g className="nunito" fill="var(--semiDark)">
                            <text x={20} y={72} className="fz10">
                                Cost
                            </text>
                            <text x={103} y={72} textAnchor="end" className="fz12">
                                {node.display.cost.value}
                            </text>
                            <path d="M 114 62 V 75" stroke="var(--grey2)" />
                            <text x={125} y={72} className="fz12">
                                {costPerc.toString()}
                            </text>
                        </g>
                    </svg>
                );
            })}
        </>
    );
});
DiagramNodes.displayName = 'DiagramNodes';

export default DiagramNodes;
