import Icon from 'components/icons/Icon';
import { FullScreenHandle } from 'react-full-screen';

const FullScreenButton: React.FC<{ handle: FullScreenHandle }> = ({ handle }) => {
    const onClick = () => {
        handle.active ? handle.exit() : handle.enter();
    };

    return (
        <div
            className="header__tool__dropdown header__tool__dropdown--fullscreen"
            data-testid="fullscreen-container"
            onClick={onClick}
        >
            <div data-testid="fullscreen-icon" className="dropdown relative">
                <div className="icon__wrapper flex h2 mainColor cursor-hand relative">
                    <Icon icon={handle.active ? 'exit-full-screen' : 'full-screen'} className="h1 white" />
                </div>
            </div>
        </div>
    );
};

export default FullScreenButton;
