import { useEffect, FC } from 'react';
import { addUser } from 'services/error/reporter';
import { useAuthenticatedUser } from 'components/context/AuthenticatedUser';

const Bugsnag: FC = () => {
    const user = useAuthenticatedUser();

    useEffect(() => {
        if (user) {
            addUser(user);
        }
    }, [user]);

    return null;
};

export default Bugsnag;
