import Bouncer from 'components/icons/Bouncer';
import { useState, useEffect } from 'react';
import SettingsLayout from '../Layout';
import { get as getLicenses, AugmentedLicensesDataInterface } from 'services/api/licenses';

const SettingsBilling = () => {
    const [billingType, setBillingType] = useState<string | null>(null);

    useEffect(() => {
        getLicenses().then((licenses: AugmentedLicensesDataInterface) => {
            if (!licenses.aws) {
                setBillingType('default');
            } else if (licenses.total > licenses.aws) {
                setBillingType('split');
            } else {
                setBillingType('aws');
            }
        });
    }, []);

    if (!billingType) {
        return (
            <SettingsLayout title="Billing">
                <Bouncer className="inline-flex" />
            </SettingsLayout>
        );
    }

    return (
        <SettingsLayout
            title={billingType === 'aws' || billingType === 'split' ? 'AWS Marketplace Billing' : 'Billing'}
            metaTitle="Billing"
        >
            <div className="settings__billing">
                {(billingType === 'split' || billingType === 'aws') && (
                    <>
                        <div>
                            <p className="h4 lighter mt3 line-height-120" data-testid="aws-billing">
                                Your billing is handled directly by AWS as your licenses were purchased through the AWS
                                Marketplace. To view your billing information or make any changes please check your AWS
                                accounts settings.
                            </p>

                            <a
                                href="https://console.aws.amazon.com/marketplace/home"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="primary-orange mt3" type="button">
                                    Go to Amazon Marketplace
                                </button>
                            </a>
                        </div>
                    </>
                )}

                {billingType !== 'aws' && (
                    <div className="mt4">
                        {billingType === 'split' && (
                            <div className="flex">
                                <h1 className="h2 mr2 lighter grey3 flex-grow-1">DPM Direct Billing</h1>
                            </div>
                        )}

                        <div>
                            <p className="h4 lighter mt3 line-height-120" data-testid="split-billing">
                                For questions regarding invoicing activity or to make a payment, please contact{' '}
                                <a href="mailto:Accounting@solarwinds.com">Accounting@solarwinds.com</a>.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </SettingsLayout>
    );
};

export default SettingsBilling;
