import { WebappStage, AuthenticatedBootstrap } from 'models/Bootstrap';
import { OrgTier } from 'models/Org';

interface FlaggedEntityInterface {
    id: string;
    comments?: string;
}

export interface FeatureFlagConfigInterface {
    internal?: boolean;
    tiers?: OrgTier[];
    trial?: boolean;
    allowOrgs?: FlaggedEntityInterface[];
    allowStages?: WebappStage[];
    disallowOrgs?: FlaggedEntityInterface[];
    allowUsers?: FlaggedEntityInterface[];
    controlledRollup?: number;
}

export default class FeatureFlag {
    readonly internal: boolean;
    readonly tiers: OrgTier[];
    readonly trial: boolean;
    readonly allowOrgs: string[];
    readonly allowStages: WebappStage[];
    readonly disallowOrgs: string[];
    readonly allowUsers: string[];
    readonly selectedInControlledRollup: boolean;
    private context: AuthenticatedBootstrap;

    constructor(config: FeatureFlagConfigInterface, context: AuthenticatedBootstrap) {
        this.internal = config.internal || false;
        this.tiers = config.tiers || [];
        this.trial = config.trial === undefined ? true : config.trial;
        this.allowOrgs = config.allowOrgs?.map(({ id }) => id) || [];
        this.allowStages = config.allowStages || [];
        this.disallowOrgs = config.disallowOrgs?.map(({ id }) => id) || [];
        this.allowUsers = config.allowUsers?.map(({ id }) => id) || [];
        this.context = context;

        this.selectedInControlledRollup = this.isSelectedForControlledRollup(config.controlledRollup);
    }

    isStageAllowed() {
        if (!this.allowStages.length) {
            return true;
        }

        return this.allowStages.includes(this.context.stage);
    }

    isOrgExplicitlyAllowed() {
        if (!this.allowOrgs.length) {
            return false;
        }

        return this.isOrgContained(this.allowOrgs);
    }

    isOrgExplicitlyDisallowed() {
        if (!this.disallowOrgs.length) {
            return false;
        }

        return this.isOrgContained(this.disallowOrgs);
    }

    private isSelectedForControlledRollup(rollupCoeficient?: number) {
        if (!rollupCoeficient) {
            return false;
        }

        const orgIdDigits = `${this.context.org.id}`.split('');
        const orgIdDigitsSum = orgIdDigits.reduce((sum: number, digit: string) => {
            return sum + parseInt(digit, 10);
        }, 0);

        return orgIdDigitsSum / orgIdDigits.length <= rollupCoeficient * 10;
    }

    isUserAllowed() {
        if (!this.allowUsers.length) {
            return false;
        }

        return this.allowUsers.includes(this.context.user.hashedIdentifier);
    }

    isTierAllowed() {
        if (!this.tiers.length) {
            return true;
        }

        return this.context.org.tier ? this.tiers.includes(this.context.org.tier) : false;
    }

    isTrialAllowed() {
        if (this.trial) {
            return true;
        }

        return !this.context.org.isTrial();
    }

    isBeta() {
        return this.internal === true && this.allowOrgs.length > 0;
    }

    private isOrgContained(list: string[]) {
        return list.includes(this.context.org.featureFlagIdentifier);
    }
}
