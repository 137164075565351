import { useState, useEffect } from 'react';
import WidgetBox from 'components/layout/WidgetBox';
import { getPerEnv } from 'services/api/licenses';
import Paginator, { PaginatorLayout } from 'components/lists/Paginator';

const PER_PAGE = 5;

const LicensesTable = () => {
    const [environments, setEnvironments] = useState<{ env: string; licenses: number }[]>([]);
    const [paginatedList, setPaginatedList] = useState<{ env: string; licenses: number }[]>([]);

    const PaginateList = (page: number) => {
        setPaginatedList(environments.slice(PER_PAGE * (page - 1), PER_PAGE * page));
    };

    useEffect(() => {
        getPerEnv().then(envs => setEnvironments(envs));
    }, []);

    useEffect(() => {
        setPaginatedList(environments.slice(0, PER_PAGE));
    }, [environments]);

    return (
        <WidgetBox className="environments_widget">
            <div className="flex grid-align-center">
                <h2 className="h2 grey3 pb2 pt2" data-testid="licenses-by-env">
                    Licenses In Use By Environment
                </h2>
            </div>
            <div className="mb2">
                {paginatedList.map(environment => (
                    <div key={environment.env} className="license_breakdown" data-testid="environment-row">
                        <div className="flex">
                            <span className="license_title">{environment.env}</span>
                        </div>
                        <div className="flex">
                            <span className="environment-count">{`${environment.licenses} licenses`}</span>
                        </div>
                    </div>
                ))}
            </div>
            <Paginator
                itemsLength={environments.length}
                layout={PaginatorLayout.SMALL}
                perPage={PER_PAGE}
                onPaging={PaginateList}
            />
        </WidgetBox>
    );
};

export default LicensesTable;
