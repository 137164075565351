import UIKey, { ValueType } from './UIKey';
import { get as getContext } from 'services/context';

export interface UIStatusConfigInterface {
    id: string;
    excludeSearchKeys?: string[];
    excludeStorageKeys?: string[];
    clearOnRouteChange?: boolean;
}

export interface UIStatusValueInterface {
    [key: string]: ValueType;
}

export interface UIStatusCallbacks {
    updateSearchParams: (params: URLSearchParams) => void;
    replaceSearchParams: (params: URLSearchParams) => void;
}

export default class UIStatus {
    private callbacks: UIStatusCallbacks;
    private config: UIStatusConfigInterface = {
        // used to build the prefix for the local storage key
        id: '',

        // keys to be ignored for read/writes in the url
        excludeSearchKeys: [],

        // keys to be ignored for read/writes in localStorage
        excludeStorageKeys: [],

        // when changing views, this parameter will be removed from the URL
        clearOnRouteChange: true,
    };
    private defaults: UIStatusValueInterface;
    private keys: UIKey[] = [];
    private storagePrefix = '';

    constructor(
        defaults: UIStatusValueInterface,
        config: UIStatusConfigInterface,
        callbacks: UIStatusCallbacks,
        private params: URLSearchParams
    ) {
        this.callbacks = callbacks;
        this.defaults = defaults;
        this.config = { ...this.config, ...config };

        const context = getContext();

        this.storagePrefix =
            'app.' +
            (context.org ? context.org.nickname + '.' : '') +
            (context.env ? context.env.name + '.' : '') +
            this.config.id +
            '.status.';

        Object.entries(this.defaults).forEach(([key, value]) => {
            const excludeSearchKey = !!this.config.excludeSearchKeys && this.config.excludeSearchKeys.includes(key);
            const excludeStorageKey = !!this.config.excludeStorageKeys && this.config.excludeStorageKeys.includes(key);

            this.keys.push(
                new UIKey(key, value, this.storagePrefix, excludeStorageKey, excludeSearchKey, this.searchParams)
            );
        });

        this.initSearchParams();
    }

    private getKey(key: string) {
        return this.keys.find(uiKey => uiKey.key === key);
    }

    private readAll() {
        const values: UIStatusValueInterface = {};

        this.keys.forEach(uiKey => {
            values[uiKey.key] = uiKey.read();
        });

        return values;
    }

    private writeKey(key: string, value: ValueType) {
        const uiKey = this.getKey(key);

        if (!uiKey) {
            throw Error(`UIKey ${key} doesn't exist`);
        }

        uiKey.write(value);
    }

    get clearOnRouteChange() {
        return this.config.clearOnRouteChange;
    }

    get id() {
        return this.config.id;
    }

    get searchParams() {
        return this.params;
    }

    set searchParams(params: URLSearchParams) {
        this.params = params;
        this.keys.forEach(key => (key.searchParams = params));
        this.initSearchParams();
    }

    read(key?: string) {
        if (!key) {
            return this.readAll();
        }

        const uiKey = this.getKey(key);

        if (!uiKey) {
            throw Error(`UIKey ${key} doesn't exist`);
        }

        return uiKey.read();
    }

    write(status: UIStatusValueInterface) {
        const initialParams = this.searchParams.toString();

        Object.entries(status).forEach(([key, value]) => {
            this.writeKey(key, value);
        });

        if (this.searchParams.toString() !== initialParams) {
            this.callbacks.updateSearchParams(this.searchParams);
        }
    }

    initSearchParams() {
        const initialParams = this.searchParams.toString();

        this.keys.forEach(uiKey => {
            uiKey.initSearchParams();
        });

        if (this.searchParams.toString() !== initialParams) {
            this.callbacks.replaceSearchParams(this.searchParams);
        }
    }
}
