import Alert, { AlertTypes } from 'components/messages/Alert';
import { LoginErrorCode } from 'services/api/login';
import * as React from 'react';
import useUrl from 'hooks/useUrl';
import { get as getContext } from 'services/context';
import { Link } from 'react-router-dom';

const AuthError: React.FC<{ error: string }> = ({ error }) => {
    const { supportEmail, salesEmail } = getContext();
    const forgotPasswordUrl = useUrl('forgot');

    return (
        <div className="mb3" data-testid="auth-error-component">
            <Alert type={AlertTypes.ERROR} sticky>
                {error === LoginErrorCode.CSRF && (
                    <>There was an error processing your request. Please, reload the page and try again.</>
                )}

                {error === LoginErrorCode.SAML && (
                    <>
                        There was an error authenticating via SAML. Please try again or contact your administrator. If
                        you are the administrator of the account, you can sign-in using your email and password.
                    </>
                )}

                {error === LoginErrorCode.CREDENTIALS && <>Bad Credentials. Please try again.</>}

                {error === LoginErrorCode.ACCOUNT_DISABLED && (
                    <>
                        Your account has not been activated. Please validate your email and activate your account by{' '}
                        <Link to={forgotPasswordUrl}>resetting your password</Link>. If you do not remember the email
                        address associated with your account please contact our{' '}
                        <a href={`mailto:${supportEmail}?Subject=Account%20disabled`}>Support</a> team.
                    </>
                )}

                {error === LoginErrorCode.ACCOUNT_LOCKED && (
                    <>
                        Your account has been locked for security reasons. We have sent you an email with instructions
                        about how to unlock it.
                    </>
                )}

                {error === LoginErrorCode.TRIAL_EXPIRED && (
                    <>
                        Wow! Time flies! Your 14 day free trial has expired. To unlock your account, please contact your
                        sales representative or{' '}
                        <a href={`mailto:${salesEmail}?Subject=DPM%20Trial%20expired`}>{salesEmail}</a>.
                    </>
                )}

                {error === LoginErrorCode.ORG_LOCKED && (
                    <>
                        Your account has been locked. To unlock your account, please contact your account manager or{' '}
                        <a href={`mailto:${supportEmail}?Subject=Organization%20locked`}>{supportEmail}</a>.
                    </>
                )}

                {error === LoginErrorCode.UNKNOWN && (
                    <>
                        An unknown error happened. Please try again or report this situation to our{' '}
                        <a href={`mailto:${supportEmail}?Subject=Account%20locked`}>support</a> team.
                    </>
                )}
            </Alert>
        </div>
    );
};

export default AuthError;
