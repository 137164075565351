import Host from './hosts/Host';

export interface HostBreakdownInterface {
    host: {
        Pct: number;
        hostID: number;
    };
    rank: number;
}

export class HostBreakDown {
    host?: Host;
    pct: number;
    rank: number;

    constructor({ host: { Pct }, rank }: HostBreakdownInterface, hostData?: Host) {
        this.host = hostData;
        this.pct = Pct;
        this.rank = rank;
    }
}

export default HostBreakDown;
