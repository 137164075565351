const reservedNames = [
    'xhr',
    'api',
    'logout',
    'login',
    'integrations',
    'find',
    'forgot',
    'reset',
    'join',
    'password',
    'oauth',
    'settings',
];

export const isReserved = (name: string) => reservedNames.includes(name);
