import Dropdown from 'components/dropdowns/Dropdown';
import { FC, useEffect, useState } from 'react';
import Agent from 'models/Agent';
import cn from 'helpers/classname';
import LogFetcher from './LogFetcher';

const AgentActions: FC<{
    onStart: (agents: Agent[]) => void;
    onRestart: (agents: Agent[]) => void;
    onStop: (agents: Agent[]) => void;
    agents: Agent[];
    showLog?: boolean;
    onLogFetched?: (log: string, agent: Agent) => void;
}> = ({ onStart, onRestart, onStop, agents, showLog, onLogFetched = () => {} }) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [requestingLog, setRequestingLog] = useState(false);

    const handleToggle = (expanded: boolean) => {
        setOpenDropdown(expanded);
    };

    useEffect(() => {
        if (!agents.length) {
            setOpenDropdown(false);
        }
    }, [agents]);

    const canStopAgents = agents.filter(agent => agent.canBeStopped).length > 0;

    const canStartAgents = agents.filter(agent => agent.canBeStarted).length > 0;

    const canRestartAgents = agents.filter(agent => agent.canBeRestarted).length > 0;

    const showStartInfo = agents.length === agents.filter(agent => agent.isSupervisor && agent.isOff).length;

    const isDropdownDisabled = !agents.length || (!canStartAgents && !canStopAgents && !canRestartAgents);

    const handleRequestLog = () => {
        setRequestingLog(true);
    };

    const handleFetchLog = (log: string, agent: Agent) => {
        setRequestingLog(false);
        setOpenDropdown(false);
        onLogFetched(log, agent);
    };

    const handleFetchLogError = () => {
        setRequestingLog(false);
    };

    return (
        <div className="relative flex pb2">
            <Dropdown
                isExpanded={openDropdown}
                onToggle={handleToggle}
                className={`${cn('bg-white dark', !showLog)} ${cn('dropdown-disabled', isDropdownDisabled)}`}
                text={!showLog ? 'Actions' : undefined}
                icon={showLog ? 'settings' : undefined}
                iconClassName={'grey3 h2'}
                direction={showLog ? 'right' : 'left'}
            >
                <ul className="list-reset px2 pt2 pb1 m1 flex nowrap">
                    <li>
                        <button
                            className="primary-mainColor agent-actions__dropdown__button"
                            disabled={!canStartAgents}
                            onClick={() => {
                                onStart(agents.filter(agent => agent.canBeStarted));
                            }}
                        >
                            Start
                        </button>
                    </li>

                    <li className="mx2">
                        <button
                            className="primary-mainColor agent-actions__dropdown__button"
                            disabled={!canRestartAgents}
                            onClick={() => {
                                onRestart(agents.filter(agent => agent.canBeRestarted));
                            }}
                        >
                            Restart
                        </button>
                    </li>

                    <li>
                        <button
                            className="primary-mainColor agent-actions__dropdown__button"
                            disabled={!canStopAgents}
                            onClick={() => {
                                setOpenDropdown(false);
                                onStop(agents.filter(agent => agent.canBeStopped));
                            }}
                        >
                            Stop
                        </button>
                    </li>
                </ul>
                {showStartInfo && (
                    <span>
                        To start this agent, please start the <b>VividCortex service</b> in the box.
                    </span>
                )}
                {showLog && (
                    <div className="px2 pb2 pt1">
                        <h1 className="grey3 left-align regular">Get agent log</h1>
                        <div className="flex nowrap">
                            <LogFetcher
                                size={4096}
                                agent={agents[0]}
                                enabled={!requestingLog}
                                onRequestLog={handleRequestLog}
                                onFetchLog={handleFetchLog}
                                onFetchLogError={handleFetchLogError}
                            />
                            <LogFetcher
                                size={16384}
                                agent={agents[0]}
                                enabled={!requestingLog}
                                onRequestLog={handleRequestLog}
                                onFetchLog={handleFetchLog}
                                onFetchLogError={handleFetchLogError}
                            />
                            <LogFetcher
                                size={65536}
                                agent={agents[0]}
                                enabled={!requestingLog}
                                onRequestLog={handleRequestLog}
                                onFetchLog={handleFetchLog}
                                onFetchLogError={handleFetchLogError}
                            />
                        </div>
                    </div>
                )}
            </Dropdown>
        </div>
    );
};
export default AgentActions;
