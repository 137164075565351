import BackgroundBar from 'components/visualizations/BackgroundBar';
import * as React from 'react';
import { AugmentedLicensesDataInterface } from 'services/api/licenses';

const LicensesGraph: React.FC<AugmentedLicensesDataInterface> = ({ total, used, free, fewLicenses, noLicenses }) => {
    let className = '';

    if (fewLicenses) {
        className = 'viz__licenses--orange';
    } else if (noLicenses) {
        className = 'viz__licenses--red';
    }

    return (
        <div className={`mx3 mt3 pb4 viz__licenses ${className}`}>
            <div className="flex justify-between mb2">
                <span className="mainColor h1" data-testid="used-licenses">
                    {used}
                </span>
                <span className="grey-2 h1 viz__licenses__left" data-testid="free-licenses">
                    {free}
                </span>
            </div>

            <BackgroundBar
                className="relative vc-viz-background-bar--licenses capsule"
                val={used}
                min={0}
                max={Math.max(total, used)}
            />

            <div className="flex light justify-between mt2 fz15 grey3">
                <span>Licenses in use</span>
                <span>Licenses Remaining</span>
            </div>
        </div>
    );
};

export default LicensesGraph;
