/* eslint-disable @typescript-eslint/no-shadow */
import cn from 'helpers/classname';
import Deadlock, { DeadlockNodeData, DeadlockNodeSectionDetailDataInterface } from 'models/queries/Deadlock';
import Icon from 'components/icons/Icon';
import Link from 'components/url/FeatureLink';
import ToggleableDigest from 'components/query/ToggleableDigest';
import { FC, Fragment } from 'react';
import { NodeInterface } from 'components/visualizations/deadlocks/Diagram';
import { useEffect, useState } from 'react';
import { Tabs, TabPanel, Tab } from 'components/tabs/Tabs';

export const DeadlockNodeDetailEmpty: FC<{ data: DeadlockNodeSectionDetailDataInterface[] }> = () => {
    return (
        <div className="p3" data-testid="deadlock-detail-empty">
            The information is not available at this time.
        </div>
    );
};

export const DeadlockNodeDetailList: FC<{ data: DeadlockNodeSectionDetailDataInterface[] }> = ({ data }) => {
    const keys = data.find(data => data.label === 'keys');
    const values = data.find(data => data.label === 'values');

    if (!keys || !values) {
        return <div>We found no data for this node.</div>;
    }

    return (
        <ul className="px4 list-reset" data-testid="deadlock-detail-list">
            {keys.values?.map((value, i) => (
                <li className="detail__list__item" key={value}>
                    <h3 className="detail__list__item__title">{value}</h3>
                    <p className="bold flex-grow-1">{values.values ? values.values[i] : ''}</p>
                </li>
            ))}
        </ul>
    );
};

export const DeadlockNodeDetailTable: FC<{ data: DeadlockNodeSectionDetailDataInterface[] }> = ({ data }) => {
    const keys = data.find(data => data.label === 'keys');
    const values = data.filter(data => data.label === 'values');

    if (!keys || !values.length) {
        return <div>We found no data for this node.</div>;
    }

    return (
        <table className="deadlock__table deadlock__table--sidebar mt4 ml1" data-testid="deadlock-detail-table">
            <thead>
                <tr>
                    {keys.values?.map(value => (
                        <th key={value}>{value}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {values.map((row, i) => (
                    <tr key={i}>
                        {row.values?.map((value, j) => (
                            <td className="nowrap" key={j}>
                                {value}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export const DeadlockNodeDetailTabs: FC<{ data: DeadlockNodeSectionDetailDataInterface[] }> = ({ data }) => {
    return (
        <div className="p3" data-testid="deadlock-detail-tabs">
            <Tabs
                className="deadlock__tabs deadlock__tabs--details"
                defaultTab={data.length > 0 ? `tab-${data[0].label}` : ''}
            >
                <div className="border-bottom border-color-grey1">
                    {data.map(tab => (
                        <Tab name={`tab-${tab.label}`} key={tab.label}>
                            {tab.label === 'Sample' && (
                                <Icon icon="more_horiz_outline" className="centerGrey absolute" />
                            )}
                            <strong>{tab.label}</strong>
                        </Tab>
                    ))}
                </div>
                {data.map(tab => (
                    <TabPanel name={`tab-${tab.label}`} key={tab.label}>
                        {tab.values === null && <div className="mt3">{`${tab.label} not available.`}</div>}
                        {tab.values?.map((value, i) => (
                            <ToggleableDigest className="mt3" key={value}>
                                {tab.ids && tab.ids[i] ? (
                                    <Link className="dark" propagateStatus appendEnv to={`queries/${tab.ids[i]}`}>
                                        {value}
                                    </Link>
                                ) : (
                                    value
                                )}
                            </ToggleableDigest>
                        ))}
                    </TabPanel>
                ))}
            </Tabs>
        </div>
    );
};

const wideNodeDetails = ['call stacks', 'locks'];

const DeadlockNodeDetail: FC<{ deadlock: Deadlock; node: NodeInterface }> = ({ deadlock, node }) => {
    const [wide, setWide] = useState(false);

    const tabs = deadlock.getNodeDrillInSections(node.id);

    const handleTabsChange = (tab: DeadlockNodeData) => {
        setWide(wideNodeDetails.includes(tab.name.toLowerCase()));
    };

    useEffect(() => {
        if (tabs.length > 0) {
            handleTabsChange(tabs[0]);
        }
    }, [node]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={`flex deadlock__node-selection flex-column border-left border-color-grey1 ${cn(
                'deadlock__node-selection--wide',
                wide
            )}`}
            data-testid="deadlock-detail"
        >
            <div className="full-height">
                <Tabs
                    className="deadlock__tabs deadlock__tabs--sidebar"
                    defaultTab={tabs.length > 0 ? `tab-${tabs[0].name}-${node.id}` : ''}
                    key={node.id}
                >
                    <div className="deadlock__tabs__header">
                        <div className="flex-grow-1 flex-column truncate">
                            <h2 className="fz18 mr2 truncate" data-testid="deadlock-node-name">
                                {node.display.label} {node.display.value}
                            </h2>
                        </div>
                        <div className="flex-column mr2 relative align__top--3">
                            {tabs.map(tab => (
                                <Tab
                                    name={`tab-${tab.name}-${node.id}`}
                                    key={`${tab.name}-${node.id}`}
                                    onClick={() => handleTabsChange(tab)}
                                >
                                    <strong>{tab.name}</strong>
                                </Tab>
                            ))}
                        </div>
                    </div>
                    {tabs.map(tab => (
                        <TabPanel name={`tab-${tab.name}-${node.id}`} key={`${tab.name}-${node.id}`}>
                            {tab.sections.map((section, i) =>
                                section.emptyData ? (
                                    <Fragment key={i}>
                                        <DeadlockNodeDetailEmpty data={section.data} />
                                    </Fragment>
                                ) : (
                                    <Fragment key={i}>
                                        {section.type === 'table' && section.data.length > 2 && (
                                            <DeadlockNodeDetailTable data={section.data} />
                                        )}
                                        {section.type === 'table' && section.data.length <= 2 && (
                                            <DeadlockNodeDetailList data={section.data} />
                                        )}
                                        {section.type === 'tabs' && <DeadlockNodeDetailTabs data={section.data} />}
                                    </Fragment>
                                )
                            )}
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default DeadlockNodeDetail;
