import { useEffect, useState, FC } from 'react';
import { fromNow } from 'services/timezone';

const TimeAgo: FC<{ date: number }> = ({ date }) => {
    const [timeAgo, setTimeAgo] = useState('');

    const calculateTimeAgo = async () => {
        let newTimeAgo = '';
        // The API returns 0 for unset timestamps like host.lastSeen, so this means 'never'
        // We need to change this if we use this filter in places where the (valid) timestamp 0 could make sense
        if (date === 0) {
            return setTimeAgo('never');
        }

        newTimeAgo = await fromNow(date);
        setTimeAgo(newTimeAgo);
    };

    useEffect(() => {
        calculateTimeAgo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    return timeAgo ? <span data-testid="time-ago">{timeAgo}</span> : null;
};

export default TimeAgo;
