import { fetchPostTemplate, PostInterface } from 'services/documentation';
import { useCallback, useEffect, useState, FC } from 'react';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import useMounted from 'hooks/useMounted';
import { useDocumentationContext } from 'components/context/Documentation';
import useClickableDocumentationContent from './hooks/useClickableContent';

const Post: FC<{
    post: PostInterface;
    onContentNavigation: (anchor: string) => void;
}> = ({ post, onContentNavigation }) => {
    const {
        url: { anchor },
    } = useDocumentationContext();

    const { resolveIfMounted } = useMounted();
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState('');
    const [postAnchor, setPostAnchor] = useState(anchor);

    const ref = useClickableDocumentationContent<HTMLDivElement>((href: string) => setPostAnchor(href));

    const navigate = useCallback(
        (selectedAnchor: string) => {
            onContentNavigation(selectedAnchor);

            // Reset anchor to prevent continous scrolling
            setPostAnchor('');
        },
        [onContentNavigation]
    );

    useEffect(() => {
        setIsLoading(true);

        // Load the post template and update states only if the element is still mounted
        resolveIfMounted(fetchPostTemplate(post))
            .then(newContent => setContent(newContent))
            .catch(() => null) // empty catch, to display the error message
            .finally(() => setIsLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post]);

    useEffect(() => {
        if (!content || !postAnchor) {
            return;
        }

        navigate(postAnchor);
    }, [content, navigate, postAnchor]);

    return (
        <div className="documentation-post mx3 mt1 mb3" ref={ref}>
            <h3 className="regular fz16 pb3" data-testid="documentation-post-title">
                {post.title}
            </h3>

            {isLoading ? (
                <Loader type={LoaderTypes.PARAGRAPH}></Loader>
            ) : content === '' ? (
                <div className="grey3 center" data-testid="documentation-post-error">
                    Oops, something went wrong!
                </div>
            ) : (
                <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    data-testid="documentation-post-content"
                    className="documentation-post__post fz13 grey3 line-height-120"
                ></div>
            )}
        </div>
    );
};

export default Post;
