import Icon from 'components/icons/Icon';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

interface TextFieldPropsInterface extends FieldRenderProps<string, HTMLInputElement> {
    helpText?: string;
    icon: string;
    label: string;
    className: string;
}

const TextField: React.FC<TextFieldPropsInterface> = ({
    className,
    input,
    meta: { touched, error },
    label,
    icon,
    helpText,
    children,
    ...rest
}) => (
    <fieldset className={`${className} ${touched && error ? 'error' : ''}`}>
        <label>
            <b className="uppercase fz10 spacing inline-flex items-center full-width mb1" id={`${input.name}-label`}>
                {label}
            </b>
            {helpText && <span className="grey3 view-value">{helpText}</span>}
            <div className="relative my2">
                <input className="full-width pl4" {...input} {...rest} aria-labelledby={`${input.name}-label`} />
                <Icon icon={icon} className="grey2 h2 absolute mt1 top-0 left-2" />
            </div>
            {error && touched && <div className="red">{error}</div>}
            {children}
        </label>
    </fieldset>
);

export default TextField;
