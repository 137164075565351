import { useState, useEffect, FC } from 'react';
import SessionChecker from 'services/sessionChecker';
import Logger from 'services/logger';
import { get as getContext } from 'services/context';
import { onSessionExpired, reportSessionExpired, SessionExpiredError } from 'services/sessionStatus';

const SessionManager: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const {
        urls: { ping: pingUrl },
        sessionTTL,
    } = getContext();
    const [expired, setExpired] = useState(false);

    useEffect(() => {
        if (expired) {
            // Do not check if session already expired.
            return;
        }

        return onSessionExpired(() => {
            // An attempt of refreshing API tokens failed due to the session expired
            setExpired(true);
            Logger.get('SessionManager').error('Expired');
        });
    }, [expired]);

    useEffect(() => {
        const logger = Logger.get('SessionManager');

        if (!pingUrl) {
            logger.error('No ping URL defined');
            return;
        }

        if (expired) {
            // Do not check if session already expired.
            return;
        }

        const checker = new SessionChecker(pingUrl, sessionTTL);

        // Register activity on document click, which might trigger a session check
        const clickHandler = () => checker.registerActivity();
        document.addEventListener('click', clickHandler, true);

        const subscription = checker.subscribe(
            () => {
                reportSessionExpired(new SessionExpiredError('Ping failed'));
                document.removeEventListener('click', clickHandler, true);
            },
            ({ message, isError }) => {
                const method = isError ? 'error' : 'debug';
                logger[method](message);
            }
        );

        // Start checking for session status
        checker.init();

        return () => {
            subscription.unsubscribe();
            document.removeEventListener('click', clickHandler, true);
        };
    }, [expired, pingUrl, sessionTTL]);

    if (expired) {
        return <>{children}</>;
    }

    return null;
};

export default SessionManager;
