import APIToken from 'models/APIToken';
import Bouncer from 'components/icons/Bouncer';
import CopyToClipboard from 'components/clipboard/CopyToClipboard';
import Icon from 'components/icons/Icon';
import OrgTokenGenerator from './OrgTokenGenerator';
import { FC, useState, useEffect } from 'react';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import { getOrgAPITokens, removeOrgToken } from 'services/api/tokens';

enum TokenListStatus {
    LOADING = 'loading',
    REVOKING = 'revoking',
    READY = 'ready',
}

const OrgTokenManager: FC = () => {
    const [status, setStatus] = useState<TokenListStatus>(TokenListStatus.LOADING);
    const [tokens, setTokens] = useState<APIToken[]>([]);
    const [createdToken, setCreatedToken] = useState<APIToken | null>();
    const { openModal } = useFinalActionModal();

    async function init() {
        const fetchTokens = await getOrgAPITokens();
        setStatus(TokenListStatus.READY);
        setTokens(fetchTokens);
    }

    async function revokeToken(token: APIToken) {
        setStatus(TokenListStatus.REVOKING);
        if (token.id === createdToken?.id) {
            dismissNewToken();
        }

        await removeOrgToken(token);
        init();
    }

    const onTokenCreate = (token: APIToken) => {
        setTokens([...tokens, token]);
        setCreatedToken(token);
    };

    const dismissNewToken = () => {
        setCreatedToken(null);
    };

    useEffect(() => {
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <table className="vc-table vc-table--settings vc-table--provisioning full-width">
                <thead>
                    <tr>
                        <th colSpan={2} className="full-width">
                            Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {status === TokenListStatus.LOADING && (
                        <tr>
                            <td colSpan={2}>
                                <Bouncer />
                            </td>
                        </tr>
                    )}
                    {status === TokenListStatus.READY && tokens.length === 0 && (
                        <tr>
                            <td className="center" colSpan={2}>
                                There are no API tokens created.
                            </td>
                        </tr>
                    )}
                    {tokens.map(token => (
                        <tr key={token.id}>
                            <td className="full-width">{token.name}</td>
                            <td>
                                <div className="flex">
                                    {status !== TokenListStatus.REVOKING && (
                                        <Icon
                                            icon="trash"
                                            className="flex grey3 fz27 mr2 hoverable"
                                            onClick={() =>
                                                openModal({
                                                    onSubmit: () => revokeToken(token),
                                                    confirmMsg: `You are about to delete the "${token.name}" API token.`,
                                                    buttonText: 'Delete this token',
                                                    title: 'Revoke API token',
                                                })
                                            }
                                        />
                                    )}
                                    {status === TokenListStatus.REVOKING && <Bouncer />}
                                </div>
                            </td>
                        </tr>
                    ))}
                    {createdToken && (
                        <tr>
                            <td colSpan={2}>
                                <div className="flex flex-column py3">
                                    <div className="flex">
                                        <input
                                            autoFocus
                                            id="token-hash"
                                            type="text"
                                            value={createdToken.hash}
                                            className="flex-grow-1"
                                            readOnly
                                        />
                                        <CopyToClipboard target="token-hash"></CopyToClipboard>
                                    </div>
                                    <div className="mt2">
                                        <p>
                                            Make sure to copy your new API token now. You won&apos;t be able to see it
                                            again!{' '}
                                            <span
                                                onClick={dismissNewToken}
                                                className="cursor-hand mainColor inline-flex"
                                            >
                                                Dismiss
                                            </span>{' '}
                                            this message.
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {!createdToken && <OrgTokenGenerator onCreate={onTokenCreate} />}
        </>
    );
};

export default OrgTokenManager;
