import AsyncHostAttribute from 'components/host/AsyncHostAttribute';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import ExportToggler from 'components/dropdowns/ExportToggler';
import FeatureLink from 'components/url/FeatureLink';
import Filters from './Filters';
import Icon from 'components/icons/Icon';
import ModuleTitle from 'components/modules/app/ModuleTitle';
import Quantity from 'components/numbers/Quantity';
import Sample from 'models/Sample';
import Timestamp from 'components/format/Timestamp';
import { FC, useState, useEffect } from 'react';
import { getInteractionReporter } from 'services/analytics/analytics';
import { encodeSamplesForExport, search, SamplesFilterInterface } from 'services/api/samples';
import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';

const reportActivity = getInteractionReporter('samples-dashboard');

const Samples: FC = () => {
    const [samples, setSamples] = useState<Sample[] | undefined>(undefined);
    const [hasMore, setHasMore] = useState(false);
    const [formFilters, setFilters] = useState<SamplesFilterInterface>();
    const { interval } = useTimeInterval();
    const { filter, noMatchingHosts } = useHostFilter();

    function onSubmit(filters: SamplesFilterInterface) {
        setSamples([]);

        search(filter, interval, filters)
            .then(({ samples: filteredSamples, hasMore: filteredHasMore }) => {
                setSamples(filteredSamples);
                setHasMore(filteredHasMore);
            })
            .catch(() => setSamples([]));
    }

    useEffect(() => {
        if (formFilters === undefined) {
            return;
        }

        onSubmit(formFilters);
    }, [formFilters, interval, filter]); // eslint-disable-line

    function reportClick() {
        reportActivity('sample', 'click');
    }

    async function getDataForExport() {
        if (!samples) {
            return [];
        }

        return await encodeSamplesForExport(interval, samples);
    }

    return (
        <div className="view view--salesforce samples">
            <div>
                <div className="pb3">
                    <ModuleTitle
                        contextualHelpId="samples"
                        headerContent={
                            <ExportToggler
                                title="Samples"
                                getData={getDataForExport}
                                className={!samples || !samples.length ? 'disabled' : ''}
                            />
                        }
                    >
                        Samples
                    </ModuleTitle>
                </div>

                <Filters onSubmit={setFilters} />

                {samples && (
                    <section className="flex pb3">
                        {!noMatchingHosts && samples.length === 0 && (
                            <EmptyState type={EmptyTypes.INFO}>
                                There are no results with the current filter and time selections. Use the filter above
                                to search for samples.
                            </EmptyState>
                        )}

                        {samples.length > 0 && (
                            <div className="full-width" data-testid="samples-table">
                                <table className="vc-table vc-table--tile vc-table--samples full-width">
                                    <thead>
                                        <tr>
                                            <th>Query Digest</th>
                                            <th>Database</th>
                                            <th>User</th>
                                            <th>Origin</th>
                                            <th>Inventory Hostname</th>
                                            <th>Timestamp</th>
                                            <th>Latency</th>
                                            <th>Connection ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {samples.map((sample, i) => (
                                            <tr data-testid="sampleRow" key={i}>
                                                <td className="full-width">
                                                    <div className="relative">
                                                        <div
                                                            className="table-cell truncate absolute vc-table--samples__sample-link"
                                                            title={sample.queryDigest}
                                                        >
                                                            <FeatureLink
                                                                className="monospace"
                                                                to={`queries/${sample.queryId}`}
                                                                onClick={reportClick}
                                                            >
                                                                {sample.queryDigest}
                                                            </FeatureLink>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="monospace fz13">{sample.db}</span>
                                                </td>
                                                <td>
                                                    <span className="monospace fz13">{sample.user}</span>
                                                </td>
                                                <td>
                                                    <span className="monospace fz13">{sample.origin}</span>
                                                </td>
                                                <td>
                                                    <span className="monospace fz13">
                                                        <AsyncHostAttribute id={sample.hostId} />
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="monospace fz13 nowrap">
                                                        <Timestamp date={sample.timestamp} />
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="monospace fz13">
                                                        <Quantity quantity={sample.latencyQuantity} />
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="monospace fz13">{sample.connectionId}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    {hasMore && (
                                        <tfoot>
                                            <tr className="center">
                                                <td colSpan={8}>
                                                    <div className="flex items-center justify-center">
                                                        <Icon icon="info" className="grey3 fz18 mr2" />
                                                        There are more results to show. Adjust your limit or change your
                                                        filter to see them.
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                            </div>
                        )}
                    </section>
                )}
            </div>
        </div>
    );
};

export default Samples;
