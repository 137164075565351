import { memo, useRef } from 'react';
import { NodeInterface } from './Diagram';
import { DiagramNode } from 'components/diagram';
import sprite from 'components/icons/sprite/icons_sprite.svg';

export interface DiagramNodeInterface {
    nodes: ReadonlyArray<DiagramNode<NodeInterface>>;
    selectedNode: NodeInterface | null;
    onNodeClick: (node: NodeInterface) => void;
}

const DiagramNodes = memo<DiagramNodeInterface>(({ nodes, selectedNode, onNodeClick }) => {
    const mouseStartPos = useRef<{ clientX: number; clientY: number } | null>(null);

    return (
        <>
            {nodes.map(({ node, x, y }) => {
                const label =
                    node.display.label.length > 5
                        ? node.display.label.substr(0, 4).trim().concat('...')
                        : node.display.label;

                return (
                    <svg
                        aria-selected={selectedNode?.id === node.id}
                        x={x}
                        y={y}
                        className={`cursor-hand vc-viz-diagram-node${
                            selectedNode?.id === node.id ? ' vc-viz-diagram-node--selected' : ''
                        } ${node.highlighted ? 'vc-viz-diagram-node--highlighted' : ''}`}
                        tabIndex={0}
                        onMouseDown={e => {
                            mouseStartPos.current = {
                                clientX: e.clientX,
                                clientY: e.clientY,
                            };
                        }}
                        onMouseUp={e => {
                            if (
                                mouseStartPos.current &&
                                mouseStartPos.current.clientX === e.clientX &&
                                mouseStartPos.current.clientY === e.clientY
                            ) {
                                onNodeClick(node);
                            }
                            mouseStartPos.current = null;
                        }}
                        onKeyPress={e => (e.key === 'Enter' ? onNodeClick(node) : null)}
                        opacity={1}
                        key={node.id}
                    >
                        <title>{`${node.display.label}`}</title>
                        <rect x={5} y={5} rx={6} width={170} height={50} className={`vc-viz-diagram-node__rect`} />

                        {node.display.icon && (
                            <g>
                                <svg
                                    className={`icon ${node.display.icon}`}
                                    focusable="false"
                                    width={25}
                                    height={35}
                                    x={20}
                                    y={12}
                                >
                                    <desc>{`${node.display.icon} icon`}</desc>
                                    <use
                                        href={`${sprite}#symbol-${node.display.icon}`}
                                        xlinkHref={`${sprite}#symbol-${node.display.icon}`}
                                    />
                                </svg>
                            </g>
                        )}

                        <text
                            x={55}
                            y={35}
                            className="fz16 vc-viz-diagram-node__label"
                            data-testid="deadlock-node-label"
                        >
                            {label}
                        </text>
                        <text x={100 + label.length} y={35} className="fz16 vc-viz-diagram-node__value">
                            {node.display.value}
                        </text>
                    </svg>
                );
            })}
        </>
    );
});
DiagramNodes.displayName = 'DiagramNodes';

export default DiagramNodes;
