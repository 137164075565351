import * as React from 'react';
import Select, { SelectOptionsInterface } from '../Select';
import { FieldRenderProps } from 'react-final-form';

export interface SelectFieldPropsInterface extends FieldRenderProps<string, HTMLSelectElement> {
    helpText?: string;
    className?: string;
    type?: string;
    prepend?: React.ReactNode;
    append?: React.ReactNode;
    options: SelectOptionsInterface[];
}

const SelectField: React.FC<SelectFieldPropsInterface> = ({
    className,
    input,
    meta: { touched, error },
    helpText,
    prepend,
    append,
    options,
    ...rest
}) => {
    const extraClassName = touched && error ? ' border border-color-red' : '';

    return (
        <Select
            className={`${className}${extraClassName}`}
            append={append}
            prepend={prepend}
            helpText={helpText}
            options={options}
            error={touched ? error : ''}
            {...input}
            {...rest}
        />
    );
};

export default SelectField;
