import useEnvs from 'hooks/useEnvs';
import OverviewItemRow, { SettingOverviewLink } from './ItemRow';
import { userIsAllowedTo } from 'services/security/accessControl';

const OverviewEnvs = () => {
    const envs = useEnvs() || [];

    const urlAttrs: SettingOverviewLink = {
        href: '/',
        text: 'Create New Environment',
    };

    return (
        <OverviewItemRow label="Environments" urlAttrs={userIsAllowedTo('createEnv') ? urlAttrs : undefined}>
            <span className="flex-grow-1" data-testid="envs-info">
                {envs.length}
            </span>
        </OverviewItemRow>
    );
};

export default OverviewEnvs;
