import Icon from 'components/icons/Icon';
import Feature from 'components/serviceLevel/Feature';
import NavItems, { NavItem } from 'providers/app/NavItems';
import { FC, SyntheticEvent, useState } from 'react';
import { FeatureNavLink as NavLink } from 'components/url/FeatureLink';
import useAuthenticatedContext from 'hooks/useAuthenticatedContext';
import { useLocation } from 'react-router-dom';
import Dropdown from 'components/dropdowns/Dropdown';

function isCurrentLocation(path: string, item: NavItem) {
    if (item.url === path) {
        return true;
    }

    if (item.url?.includes('settings') && path.includes('settings')) {
        return true;
    }

    return item.children.reduce((current, children) => current || children.url === path, false);
}

function isChildrenSelected(path: string, header: NavItem) {
    return header.children.some(child => path.includes(child.url));
}

const NavigationHeader: FC<{ header: NavItem; expanded: boolean; onClick(): void }> = ({
    header,
    expanded,
    onClick,
}) => {
    const className = expanded ? 'navigation__group__title--shown bg-vantaDark' : 'navigation__group__title--hidden';
    const location = useLocation();
    return (
        <div
            className={`navigation__group__title menu__item__title white no-decoration relative flex full-width items-center px3 fz14 cursor-hand user-select uppercase ${className}`}
            onClick={onClick}
            data-testid={`nav-header-${header.name.toLowerCase()}`}
            title={`Go to ${header.name}`}
        >
            {header.icon && (
                <Icon
                    className={`${
                        isChildrenSelected(location.pathname, header) ? 'menu__item__title__icon' : ''
                    } flex grey2 h2 mr3 fz18`}
                    icon={header.icon}
                />
            )}
            <span className={`no-decoration ${isChildrenSelected(location.pathname, header) ? 'swiOrange' : 'white'}`}>
                {header.name}
            </span>
            <Icon
                icon="keyboard_arrow_down"
                className="menu__item__title__arrow flex grey2 h2 absolute right-3"
                data-testid={`nav-toggle-${header.name.toLowerCase()}`}
            />
        </div>
    );
};

const NavigationItem: FC<{
    item: NavItem;
    onToggle(item: NavItem | null): void;
    expanded: boolean;
    collapsed: boolean;
}> = ({ item, onToggle, expanded, collapsed }) => {
    const location = useLocation();
    const header = item.children.length ? item : null;
    const items = item.children.length ? item.children : [item];
    const [displayDropdown, setDisplayDropdown] = useState<boolean>();
    const [displayTooltip, setDisplayTooltip] = useState<boolean>();

    const handleHover = () => {
        if (collapsed) setDisplayTooltip(!displayTooltip);
    };

    if (!item.accessible) {
        return null;
    }

    const filteredItems = items.filter(navigationItem => navigationItem.accessible);

    const onClick = (e: SyntheticEvent, navigationItem: NavItem) => {
        if (!header) {
            onToggle(navigationItem);
        }
        e.stopPropagation();
    };

    const onHeaderClick = (navigationItem: NavItem) => {
        onToggle(navigationItem);
        if (collapsed) setDisplayDropdown(!displayDropdown);
    };

    const renderTooltip = () => (
        <div className="menu__item__title__tooltip white bg-paleDark rounded ml1 px3 py2 z4 cursor-hand">
            {item.name}
        </div>
    );

    const renderDropDown = () => (
        <Dropdown
            isExpanded={displayDropdown}
            className="menu__item__title__tooltip menu__item__title__tooltip--dropdown"
            onToggle={() => setDisplayDropdown(!displayDropdown)}
        >
            <div className="bg-paleDark white z1 rounded py2">
                <span className="flex px3 py1 pb2 uppercase menu__item__title__tooltip--dropdown__header">
                    {item.name}
                </span>
                <ul>
                    {filteredItems.map((navigationItem, key) => (
                        <Feature name={navigationItem.feature} key={key}>
                            <li className="menu__item">
                                <NavLink
                                    to={navigationItem.url}
                                    activeClassName="menu__item--selected"
                                    className={`menu__item__title white no-decoration flex full-width items-center px3 capitalize relative ${
                                        isCurrentLocation(location.pathname, navigationItem)
                                            ? ' menu__item--selected'
                                            : ''
                                    }`}
                                    title={`Go to ${navigationItem.name}`}
                                    exact
                                    onClick={(e: SyntheticEvent) => onClick(e, navigationItem)}
                                >
                                    {navigationItem.name}
                                </NavLink>
                            </li>
                        </Feature>
                    ))}
                </ul>
            </div>
        </Dropdown>
    );

    return (
        <div
            className={`navigation__group ${
                isCurrentLocation(location.pathname, item) ? 'group__menu__item--selected bg-vantaDark' : ''
            }`}
        >
            {header && (
                <Feature name={item.feature}>
                    <NavigationHeader header={item} onClick={() => onHeaderClick(item)} expanded={expanded} />
                </Feature>
            )}
            <ul
                className={`menu__item__title__list main_menu list-reset full-width overflow-hidden ${
                    expanded ? 'bg-vantaDark' : ''
                }`}
            >
                {filteredItems.map((navigationItem, key) => (
                    <Feature name={navigationItem.feature} key={key}>
                        <li className="menu__item relative">
                            {navigationItem.url ? (
                                <NavLink
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleHover}
                                    to={navigationItem.url}
                                    activeClassName="menu__item--selected"
                                    className={`menu__item__title white no-decoration flex full-width items-center px3 fz14${
                                        isCurrentLocation(location.pathname, navigationItem)
                                            ? ' menu__item--selected'
                                            : ''
                                    } ${navigationItem.icon ? 'uppercase' : ''}`}
                                    title={`Go to ${navigationItem.name}`}
                                    exact
                                    onClick={(e: SyntheticEvent) => onClick(e, navigationItem)}
                                >
                                    {navigationItem.icon && (
                                        <Icon
                                            icon={navigationItem.icon}
                                            className="menu__item__title__icon flex grey2 h2 mr3 fz20"
                                        />
                                    )}
                                    <span>{navigationItem.name}</span>
                                </NavLink>
                            ) : (
                                <>
                                    {item.icon && (
                                        <Icon
                                            icon={item.icon}
                                            className="menu__item__title__icon flex grey2 h2 mr3 fz20"
                                        />
                                    )}
                                    <span>{item.name}</span>)
                                </>
                            )}
                        </li>
                    </Feature>
                ))}
            </ul>
            {displayTooltip && renderTooltip()}
            {displayDropdown && renderDropDown()}
        </div>
    );
};

const Navigation: FC<{ collapsed: boolean }> = ({ collapsed }) => {
    const context = useAuthenticatedContext();
    const location = useLocation();

    const items = new NavItems(context.env).getItems();
    const navBaseItems = new NavItems().getBaseItems();
    const currentItem = items.find(item => isCurrentLocation(location.pathname, item));
    const [expandedItem, setExpandedItem] = useState<NavItem | null>(currentItem || null);

    const onToggle = (item: NavItem | null) => {
        setExpandedItem(expandedItem?.name === item?.name ? null : item);
    };
    return (
        <div className="flex flex-column overflow-hidden">
            <div className="nav__wrapper flex flex-column relative overflow-auto">
                <div className="navigation__list flex flex-column no-shrink">
                    <nav className="flex flex-column">
                        {items.map((item, key) => (
                            <NavigationItem
                                item={item}
                                key={key}
                                onToggle={onToggle}
                                expanded={expandedItem?.name === item.name}
                                collapsed={collapsed}
                            />
                        ))}
                        <div className="navigation__list__nav-section">
                            {navBaseItems.map((item, key) => (
                                <NavigationItem
                                    item={item}
                                    key={key}
                                    onToggle={onToggle}
                                    expanded={false}
                                    collapsed={collapsed}
                                />
                            ))}
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
