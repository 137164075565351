import { useEffect, useState } from 'react';
import Dropdown from 'components/dropdowns/Dropdown';
import SlideSwitch from 'components/form/SlideSwitch';
import { getShortUrl } from 'services/api/urlShortener';
import { useLocation } from 'react-router-dom';
import { useTimeInterval } from 'components/context/TimeInterval';
import CopyToClipboard from 'components/clipboard/CopyToClipboard';

const Sharer: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isFreeze, setIsFreeze] = useState(true);
    const [shortUrl, setShortUrl] = useState('');
    const [freezeUrl, setFreezeUrl] = useState('');
    const [unfreezeUrl, setUnfreezeUrl] = useState('');
    const location = useLocation();
    const { interval } = useTimeInterval();

    const fetchShortUrl = async (isUrlFreeze: boolean) => {
        if ((isUrlFreeze && freezeUrl) || (!isUrlFreeze && unfreezeUrl)) {
            return;
        }
        const from = isUrlFreeze ? interval.fromTs : interval.from;
        const until = isUrlFreeze ? interval.untilTs : interval.until;
        const params = new URLSearchParams(location?.search);
        params.set('from', from.toString());
        params.set('until', until.toString());
        const sharerUrl = new URL(`${location?.pathname}?${params.toString()}`, window.location.origin);
        const fetchedShortUrl = await getShortUrl(sharerUrl.toString());
        if (isUrlFreeze) {
            setFreezeUrl(fetchedShortUrl.shortUrl);
        } else {
            setUnfreezeUrl(fetchedShortUrl.shortUrl);
        }
    };

    const handleToggle = () => {
        fetchShortUrl(!isFreeze);
        setIsFreeze(!isFreeze);
    };

    const onDropdownToggle = (expanded: boolean) => {
        if (expanded !== isExpanded) {
            setIsExpanded(expanded);
            fetchShortUrl(isFreeze);
        }
    };

    useEffect(() => {
        if (isFreeze) {
            setShortUrl(freezeUrl);
        } else {
            setShortUrl(unfreezeUrl);
        }
    }, [freezeUrl, isFreeze, unfreezeUrl]);

    return (
        <Dropdown
            icon="share"
            className="header__tool__dropdown header__tool__dropdown--sharer"
            direction="right"
            isExpanded={isExpanded}
            onToggle={onDropdownToggle}
        >
            <div className="header__tool__dropdown--sharer__wrapper bg-grey05">
                <h2 className="fz16 pt3 px3 regular">Share this link</h2>
                {!shortUrl ? (
                    <div data-testid="sharer-loader-row" className="monospace fz12 grey3 full-width center py3">
                        Generating link...
                    </div>
                ) : (
                    <div>
                        <div className="flex items-center p3 py2">
                            <input
                                className="monospace dark border-color-MainColor flex-grow-1"
                                value={shortUrl}
                                id="short-url"
                                data-testid="sharer-short-url"
                                readOnly
                            />
                            <CopyToClipboard target="short-url">Copy Link</CopyToClipboard>
                        </div>
                        <div className="p3 bg-white">
                            <div className="flex">
                                <label className="fz16 capitalize mr2">Freeze Time Range</label>
                                <SlideSwitch
                                    data-testid="switch-short-url"
                                    onChange={handleToggle}
                                    checked={isFreeze}
                                />
                            </div>
                            <p className="monospace fz12 grey3 mt2 line-height-120">
                                The link shows the same time range you're viewing now.
                                {/* The link shows a time range relative to the time it's viewed. */}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </Dropdown>
    );
};

export default Sharer;
