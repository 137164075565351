import Env from 'models/Env';
import Icon from 'components/icons/Icon';
import * as React from 'react';
import { UserHasPermission } from 'components/security/UserHasPermission';

interface EnvironmentItemPropsInterface {
    env: Env;
    edit: (env: Env) => void;
}

const EnvironmentItem: React.FC<EnvironmentItemPropsInterface> = ({ env, edit }) => {
    return (
        <li className="view--home__env-list__item" data-testid="environment-item">
            <a
                data-testid="environment"
                href={env.url}
                className="flex no-decoration flex-grow-1 full-height items-center"
            >
                <Icon icon="env-selector" className="view--home__env-list__item__icon" />
                <h2 className="environment-box__env-name">{env.name}</h2>
            </a>

            <UserHasPermission to="createEnv">
                <div className="flex px3">
                    <span className="cursor-hand mr2" data-testid="edit-env-dialog-button" onClick={() => edit(env)}>
                        <Icon icon="settings" className="environment-tool" />
                    </span>
                </div>
            </UserHasPermission>
        </li>
    );
};

export default EnvironmentItem;
