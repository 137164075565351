import Logger from 'services/logger';
import { HttpResponseInterface, GenericHttpErrorInterface } from 'services/http';
const logger = Logger.get('APIError');

export default class APIError<T = unknown> extends Error {
    private httpResponse: HttpResponseInterface<T>;
    private allowedCodes: number[];

    constructor(e: GenericHttpErrorInterface<T>, allowedCodes: number[] = []) {
        super(e.message);
        this.name = e.name;
        if (!e.response) {
            const errorMsg = 'The APIError must have a response attribute';
            logger.error(errorMsg, e);
            throw new Error(errorMsg);
        }
        this.httpResponse = e.response;
        this.allowedCodes = allowedCodes;
    }

    get response() {
        return this.httpResponse;
    }

    get status() {
        return this.httpResponse.status;
    }

    get isExpired() {
        return this.httpResponse.status === 401;
    }

    get isExpected() {
        // -1 means the browser is offline.
        return this.status === -1 || this.allowedCodes.includes(this.status);
    }
}
