import Agent, { AgentDataInterface } from 'models/Agent';
import APIRequest from './Request';
import Logger from 'services/logger';

const logger = Logger.get('agents');

export enum AgentsCommands {
    START = 'start',
    STOP = 'stop',
    RESTART = 'restart',
    FETCH_LOG = 'fetchlog',
}

interface AgentLogFetchInterface {
    hostId: number;
    agentName: string;
    logId: string;
}

export interface AgentLogInterface {
    id: string;
    size: number;
    status: string;
    ts: number;
    content?: string;
}

interface CommandParamsInterface {
    size: number;
}

interface LogMessageInterface {
    data: { id: string };
}

interface LogAgentInterface {
    code: number;
    message: LogMessageInterface;
}

interface LogAgentsInterface {
    agents: LogAgentInterface[];
}

interface HostParamInterface {
    id: number;
    agents: string[];
}

interface AgentPayloadInterface {
    command: AgentsCommands;
    hosts: HostParamInterface[];
    params?: CommandParamsInterface;
}

function buildHostsParam(agents: Agent[]) {
    const hosts: HostParamInterface[] = [];

    agents.forEach(agent => {
        let host = hosts.find(({ id }) => id === agent.targetHost);
        if (!host) {
            host = {
                id: agent.targetHost,
                agents: [],
            };

            hosts.push(host);
        }

        host.agents.push(agent.name);
    });

    return hosts;
}

function executeCommand(command: AgentsCommands, agents: Agent[], params?: CommandParamsInterface) {
    const data: AgentPayloadInterface = {
        command,
        hosts: buildHostsParam(agents),
    };

    if (params) {
        data.params = params;
    }

    logger.info(`executing ${command} command`);

    return new APIRequest<null, AgentPayloadInterface>({
        path: 'hosts/agents/command',
        params: data,
        method: 'put',
    }).request();
}

export async function get(filter = '') {
    logger.info('fetching agents');

    const response = await new APIRequest<AgentDataInterface[]>({
        path: 'hosts/agents',
        params: {
            host: filter,
        },
    }).request();

    return response.data.map(data => new Agent(data));
}

export async function start(agents: Agent[]) {
    return executeCommand(AgentsCommands.START, agents);
}

export async function stop(agents: Agent[]) {
    return executeCommand(AgentsCommands.STOP, agents);
}

export async function restart(agents: Agent[]) {
    return executeCommand(AgentsCommands.RESTART, agents);
}

export async function getLogId(agents: Agent[], size: number) {
    const data = {
        command: AgentsCommands.FETCH_LOG,
        hosts: buildHostsParam(agents),
        params: { size },
    };

    logger.info(`executing fetchlog command`);

    const response = await new APIRequest<LogAgentsInterface[], AgentPayloadInterface>({
        path: 'hosts/agents/command',
        params: data,
        method: 'put',
    }).request();

    return response.data[0].agents[0].message.data.id;
}

export async function fetchLog({ hostId, agentName, logId }: AgentLogFetchInterface) {
    logger.info(`fetching agent log ${logId}`);

    const response = await new APIRequest<AgentLogInterface>({
        path: `hosts/${hostId}/agents/${agentName}/logs/${logId}`,
        method: 'get',
    }).request();

    return response.data;
}
