import Icon from 'components/icons/Icon';
import UserSelector from './UserSelector';
import { FC, useState, useEffect } from 'react';
import { useBootstrap } from 'components/context/Bootstrap';

const openKeys = ['`', '!'];
const closeKey = 'Escape';

const Impersonator: FC = () => {
    const [visible, setVisible] = useState(false);
    const [{ bootstrap }] = useBootstrap();

    useEffect(() => {
        // We don't want non-brainiacs to bypass the impersonator.
        if (!bootstrap.isBrainiac()) {
            return;
        }

        const toggler = (e: KeyboardEvent) => {
            if (openKeys.includes(e.key)) {
                e.preventDefault();
                setVisible(v => !v);
            } else if (e.code === closeKey) {
                setVisible(false);
            }
        };

        document.addEventListener('keydown', toggler);

        return () => document.removeEventListener('keydown', toggler);
    }, [bootstrap]);

    if (!bootstrap.isBrainiac() || !visible) {
        return null;
    }

    return (
        <div className="impersonator fixed z4 bg-DarkMainColor" data-testid="impersonator">
            {bootstrap.isImpersonation() && (
                <div className="impersonator__exit flex justify-center bg-white">
                    <a href="/impersonator/exit" className="impersonator__exit-link p2 flex items-center">
                        <Icon icon="close" className="mainColor inline-flex fz18 mr2" />
                        Exit impersonation
                    </a>
                </div>
            )}

            {!bootstrap.isImpersonation() && <UserSelector />}
        </div>
    );
};

export default Impersonator;
