import { FC, useEffect, useState } from 'react';
import Bouncer from 'components/icons/Bouncer';
import { FieldRenderProps, Field } from 'react-final-form';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import CheckboxField from 'components/form/fields/CheckboxField';
import TextField from 'components/form/fields/TextField';
import SlideSwitchField from 'components/form/fields/SlideSwitchField';
import {
    get as getCredentials,
    getForHost as getCredentialsForHost,
    CredentialHostType,
} from 'services/api/credentials';
import SQLServerCredentialModel, { SQLServerCredentialDataInterface } from 'models/credentials/SQLServerCredential';
import SelectField from 'components/form/fields/SelectField';
import Host from 'models/hosts/Host';

const sslModeOptions = [
    {
        name: 'None',
        value: '',
    },
    {
        name: 'Require',
        value: 'require',
    },
    {
        name: 'Disable',
        value: 'disable',
    },
];

const SQLServerCredentialsFields: FC<{
    host?: Host;
    onLoad?: (credentials: SQLServerCredentialModel) => void;
    values?: SQLServerCredentialModel;
    allowPasswordChange?: boolean;
}> = ({ host, onLoad = () => {}, values, allowPasswordChange = true }) => {
    const [loading, setLoading] = useState(true);

    const fetchCredentials = async () => {
        const result = host
            ? await getCredentialsForHost<SQLServerCredentialDataInterface>(host)
            : await getCredentials<SQLServerCredentialDataInterface>(CredentialHostType.mssql);

        setLoading(false);

        if (result === undefined) {
            onLoad(
                new SQLServerCredentialModel({
                    sslEnabled: 'true',
                    sslMode: '',
                    db: 'master',
                    user: '',
                    host: '127.0.0.1',
                    port: '1433',
                    pass: '',
                })
            );
        } else {
            const credentials = new SQLServerCredentialModel(result);
            credentials.isEmpty = false;
            onLoad(credentials);
        }
    };

    useEffect(() => {
        fetchCredentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host]);

    return (
        (!loading && values && (
            <>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Address">
                    <Field name="address" required placeholder="Usually 127.0.0.1:1433">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <TextField {...props} helpText="Examples: 127.0.0.1:1433, 10.0.10.1:1433, [::1]:1433" />
                        )}
                    </Field>
                </FormGroup>

                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="Database">
                    <Field name="database" placeholder="Database to connect to, if required">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <TextField {...props} />}
                    </Field>
                </FormGroup>

                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="User">
                    <Field name="user" placeholder="Connection's username">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <TextField {...props} />}
                    </Field>
                </FormGroup>

                <FormGroup
                    className="mt3  field-inline-labels field-inline-labels--tooltip"
                    label="Password"
                    variant={FormGroupVariantType.HORIZONTAL}
                >
                    <Field name="password" type="password" placeholder="Connection's password">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <TextField
                                {...props}
                                type="password"
                                disabled={!values.changePasswordAllowed}
                                tooltip="For security purposes, we do not populate passwords in this field.
                        If you wish to update the password, please check the box and enter a
                        new password in the input."
                            />
                        )}
                    </Field>
                </FormGroup>

                <FormGroup className="mt3" variant={FormGroupVariantType.HORIZONTAL} label=" ">
                    <Field type="checkbox" name="changePasswordAllowed">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <CheckboxField
                                {...props}
                                label="I want to change the password"
                                id="changePasswordAllowed"
                                data-testid="password-checkbox"
                            />
                        )}
                    </Field>
                </FormGroup>

                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="SSL Enabled">
                    <Field name="sslEnabled" required>
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <SlideSwitchField checked={values?.sslEnabled} {...props} />
                        )}
                    </Field>
                </FormGroup>

                {values.sslEnabled && (
                    <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="SSL Mode">
                        <Field name="sslMode">
                            {({ ...props }: FieldRenderProps<string, HTMLSelectElement>) => (
                                <SelectField {...props} options={sslModeOptions} data-testid="ssl-mode" />
                            )}
                        </Field>
                    </FormGroup>
                )}
            </>
        )) || <Bouncer className="my4 flex full-width justify-center" />
    );
};

export default SQLServerCredentialsFields;
