import Popover from 'components/popover/Popover';
import { SyntheticEvent, FC, ReactNode } from 'react';

export interface InputPropsInterface {
    helpText?: string;
    className?: string;
    type?: string;
    prepend?: ReactNode;
    append?: ReactNode;
    onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
    value: string;
    error?: string;
    disabled?: boolean;
    tooltip?: string;
    tooltipIcon?: string;
    name?: string;
}

const Input: FC<InputPropsInterface> = ({
    className = '',
    onChange,
    helpText,
    type = 'text',
    prepend,
    append,
    value,
    error = '',
    tooltip,
    tooltipIcon = 'info',
    ...rest
}) => {
    return (
        <div className="grid grid-align-stretch form__input-container">
            {prepend && <span className="self-center form__input-container__prepend pr2">{prepend}</span>}
            <input className={className} onChange={onChange} value={value} type={type} {...rest} />
            {append && <span className="self-center form__input-container__append pl2">{append}</span>}
            {error && <div className="form__input-container__error red mt2 full-width">{error}</div>}
            {helpText && <span className="form__input-container__explain grey3 pt2">{helpText}</span>}
            {tooltip && (
                <div className="icon__wrapper flex fz18 flex items-center">
                    <Popover iconClassName="fz18 grey3" icon={tooltipIcon} direction="left">
                        {tooltip}
                    </Popover>
                </div>
            )}
        </div>
    );
};

export default Input;
