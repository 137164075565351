export function byName<SortableType extends { name: string }>(collection: SortableType[]) {
    return byAttribute(collection, 'name', 'asc');
}

export type SortByDirectionType = 'asc' | 'desc';

export function byAttribute<SortableType>(
    collection: SortableType[],
    attribute: string,
    direction: SortByDirectionType
) {
    const directionCoef = direction === 'asc' ? 1 : -1;
    const sortedCollection = [...collection];

    sortedCollection.sort((a, b) => {
        const aValue = a[attribute as keyof SortableType];
        const bValue = b[attribute as keyof SortableType];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.toLowerCase() < bValue.toLowerCase() ? -1 * directionCoef : 1 * directionCoef;
        }

        return aValue < bValue ? -1 * directionCoef : 1 * directionCoef;
    });

    return sortedCollection;
}
