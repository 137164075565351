import * as React from 'react';
import Tag from 'components/form/fields/Tag';
import { FieldRenderProps } from 'react-final-form';
import { SyntheticEvent } from 'react';

export interface TagsInputPropsInterface extends FieldRenderProps<string, HTMLInputElement> {
    className?: string;
    onError: () => void;
}

const TagsInput: React.FC<TagsInputPropsInterface> = ({
    className = '',
    input: { onChange, value, type },
    meta,
    onError,
    ...rest
}) => {
    const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const addTag = (e: SyntheticEvent<HTMLInputElement>) => {
        if (e.currentTarget.value) {
            if (value.split(',').includes(e.currentTarget.value)) {
                e.currentTarget.value = '';
                onError();
                return;
            }
            inputRef.current.value = value + (value ? ',' : '') + e.currentTarget.value;
            e.currentTarget.value = '';
            inputRef.current.click();
        }
    };

    const removeTag = (index: number) => {
        const tags = value.split(',');
        tags.splice(index, 1);
        inputRef.current.value = tags.length ? tags.join(',') : '';
        inputRef.current.click();
    };

    const onInputChange = (e: SyntheticEvent<HTMLInputElement>, key: string) => {
        if (key === 'Backspace' && e.currentTarget.value === '') {
            const tags = value.split(',');
            const lastIndex = tags.length - 1;
            e.currentTarget.value = tags[lastIndex];
            removeTag(lastIndex);
        }
        if (key === ' ' || key === ',') {
            e.currentTarget.value = e.currentTarget.value.slice(0, -1);
            addTag(e);
        }
        if (key === 'Enter') {
            addTag(e);
        }
    };

    return (
        <label className="grid grid-align-stretch form__input-container">
            <div className={className}>
                {value &&
                    value
                        .split(',')
                        .map((tag, index) => <Tag key={tag} tag={tag} hasButton onClick={() => removeTag(index)} />)}
                <input
                    onBlur={addTag}
                    onKeyUp={e => onInputChange(e, e.key)}
                    type={type}
                    className="no-border bg-transparent"
                    data-testid="tags-input-input"
                />
                <input onClick={onChange} type="hidden" ref={inputRef} {...rest} />
            </div>
        </label>
    );
};

export default TagsInput;
