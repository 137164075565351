import { ContextualError } from './error/error';

export class SessionExpiredError extends ContextualError {
    constructor(message: string) {
        super('SessionExpiredError', message);
    }
}

type UnregisterCallback = () => void;
type ListenerCallback = (error: SessionExpiredError) => void;

const listeners: ListenerCallback[] = [];

/**
 * Registers a session expired listener and returns a function that can be used
 * for unregistering the given listener.
 *
 * These listeners can be used for doing some work when tokens cannot be
 * refreshed due to an expired session. For example, the session manager will
 * show the BSOD component when the session is lost, forcing users to reload the
 * page.
 */
export function onSessionExpired(cb: ListenerCallback): UnregisterCallback {
    listeners.push(cb);

    return () => {
        const index = listeners.indexOf(cb);
        if (0 <= index) {
            listeners.splice(index, 1);
        }
    };
}

export function reportSessionExpired(error: SessionExpiredError) {
    listeners.forEach(cb => cb(error));
}
