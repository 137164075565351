import { FC, useState } from 'react';
import Dropdown from 'components/dropdowns/Dropdown';
import ContactLink from 'components/messages/ContactLink';
import Icon from 'components/icons/Icon';
import useEnv from 'hooks/useEnv';

const SDVInfo: FC<{ fullSize?: boolean }> = ({ fullSize }) => {
    const [open, setOpen] = useState(false);

    const env = useEnv();

    const toggle = () => {
        setOpen(!open);
    };

    return (
        <>
            {!!env.securityGatewayUri && (
                <div className="flex relative" data-testid="sdv-warning">
                    {!fullSize && (
                        <div>
                            <div onClick={() => toggle()} className="relative sdv-warning">
                                <Icon
                                    icon="info-outline"
                                    className="flex grey3 h2 absolute sdv-warning__icon pt1 pl1 align__top--3 align__left--3 z4"
                                ></Icon>
                                <Dropdown
                                    isExpanded={open}
                                    onToggle={toggle}
                                    className="dropdown--right dropdown--grey05 dropdown--label-icon"
                                    text="Using SDV"
                                >
                                    <div className="sdv__dropdown__content bg-grey05 rounded-bottom fz12">
                                        <p className="p2 line-height-120">
                                            Your environment is configured to use Sensitive Data Vault for keeping your
                                            sample data on premises.
                                        </p>
                                        <p className="p2 line-height-120">
                                            DPM Sensitive Data Vault is a lightweight, on-premise solution which allows
                                            for the secure capture and display of query data. Once installed, the
                                            application does not communicate with (nor requires access to) the open
                                            internet, though access is required for installation. It is not self
                                            updating or self-configuring. Solarwinds employees do not have access to it
                                            or the underlying database.
                                        </p>
                                        <p className="p2 line-height-120">
                                            Please <ContactLink></ContactLink> if you have any questions.
                                        </p>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                    {fullSize && (
                        <div className="bg-grey05 rounded">
                            <div
                                className="flex items-center cursor-hand p2"
                                onClick={() => toggle()}
                                data-testid="sdv-full-message"
                            >
                                <Icon icon="info-outline" className="flex h2 grey3 mr2" />
                                <span className="flex-grow-1">
                                    Your environment is configured to use Sensitive Data Vault for keeping your sample
                                    data on premises.
                                </span>
                                <Icon
                                    icon="keyboard_arrow_down"
                                    className={
                                        'flex fz18 mainColor ml2 SDV__toggle__icon ' +
                                        (open ? 'SDV__toggle__icon--hidden' : 'SDV__toggle__icon--shown')
                                    }
                                />
                            </div>
                            <div
                                className={
                                    'fz12 SDV-meaning overflow-hidden ' +
                                    (open ? 'SDV-meaning--shown' : 'SDV-meaning--hidden')
                                }
                            >
                                <p className="mt2 line-height-120 px2">
                                    DPM Sensitive Data Vault is a lightweight, on-premise solution which allows for the
                                    secure capture and display of query data. Once installed, the application does not
                                    communicate with (nor requires access to) the open internet, though access is
                                    required for installation. It is not self updating or self-configuring. Solarwinds
                                    employees do not have access to it or the underlying database.
                                </p>
                                <p className="line-height-120 p2">
                                    Please <ContactLink></ContactLink> if you have any questions.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default SDVInfo;
