import { FC, useLayoutEffect, useRef, useState } from 'react';
import { useElementDimensions } from 'hooks/useDimensions';

export const openClassName = 'toggleable-digest--open';
export const closedClassName = 'toggleable-digest--closed';

const ToggleableDigest: FC<{ className?: string; children?: React.ReactNode }> = ({
    className = '',
    children = '',
}) => {
    const [open, setOpen] = useState(false);
    const [overflows, setOverflow] = useState(false);
    const container = useRef<HTMLDivElement | null>(null);
    const { scrollHeight, clientHeight } = useElementDimensions(container);

    useLayoutEffect(() => {
        if (!container.current) {
            return;
        }

        const ratio = Math.floor(scrollHeight / clientHeight);

        setOverflow(ratio > 1);
    }, [scrollHeight, clientHeight]);

    return (
        <div className={className}>
            <div className={`toggleable-digest ${open ? openClassName : closedClassName}`} ref={container}>
                {children}
            </div>
            {overflows && (
                <div className="mainColor mt2 cursor-hand" onClick={() => setOpen(!open)}>
                    Show {open ? 'less' : 'more'}
                </div>
            )}
        </div>
    );
};

export default ToggleableDigest;
