import { FieldRenderProps, Field } from 'react-final-form';
import { Field as HTML5Field } from 'react-final-form-html5-validation';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import TextField from 'components/form/fields/TextField';
import CheckboxField from 'components/form/fields/CheckboxField';
import SelectField from 'components/form/fields/SelectField';
import SlideSwitchField from 'components/form/fields/SlideSwitchField';
import { useState, FC, useEffect } from 'react';
import PostgreSQLCredentialModel, { PostgreSQLCredentialDataInterface } from 'models/credentials/PostgreSQLCredential';
import Host, { HostCaptureModes } from 'models/hosts/Host';
import {
    get as getCredentials,
    getForHost as getCredentialsForHost,
    CredentialHostType,
} from 'services/api/credentials';
import Bouncer from 'components/icons/Bouncer';

const sslModeOptions = [
    {
        name: 'None',
        value: '',
    },
    {
        name: 'Require',
        value: 'require',
    },
    {
        name: 'Verify CA',
        value: 'verify-ca',
    },
    {
        name: 'Verify Full',
        value: 'verify-full',
    },
];

function getSSLModeHelpText(mode: string) {
    switch (mode) {
        case 'require':
            return 'Always SSL (skip verification)';
        case 'verify-ca':
            return 'Always SSL (verify that the certificate presented by the server was signed by a trusted CA)';
        case 'verify-full':
            return 'Always SSL (verify that the certification presented by the server was signed by a trusted CA)';
        default:
            return undefined;
    }
}

const PostgreSQLCredentialsFields: FC<{
    host?: Host;
    onLoad?: (credentials: PostgreSQLCredentialModel) => void;
    values?: PostgreSQLCredentialModel;
    allowPasswordChange?: boolean;
}> = ({ host, onLoad = () => {}, values, allowPasswordChange = true }) => {
    const [loading, setLoading] = useState(true);

    const fetchCredentials = async () => {
        const result = host
            ? await getCredentialsForHost<PostgreSQLCredentialDataInterface>(host)
            : await getCredentials<PostgreSQLCredentialDataInterface>(CredentialHostType.pgsql);

        setLoading(false);

        if (result === undefined) {
            onLoad(
                new PostgreSQLCredentialModel({
                    sslEnabled: 'false',
                    db: '',
                    user: '',
                    host: '',
                    port: '',
                    pass: '',
                    sslMode: '',
                    sslCA: '',
                    sslCert: '',
                    sslKey: '',
                    gcpProjectID: '',
                    gcpResourceID: '',
                })
            );
        } else {
            const credentials = new PostgreSQLCredentialModel(result);
            credentials.isEmpty = false;
            onLoad(credentials);
        }
    };

    useEffect(() => {
        fetchCredentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host]);

    return (
        (!loading && values && (
            <>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Address">
                    <HTML5Field
                        name="address"
                        required
                        component={TextField}
                        placeholder="Usually localhost:5432"
                        helpText="Examples: localhost:5432, 10.0.10.1:5432, [::1]:5432"
                    />
                </FormGroup>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="Database">
                    <Field name="database" placeholder="Database to connect to, if required">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <TextField {...props} />}
                    </Field>
                </FormGroup>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="User">
                    <Field name="user" placeholder="Connection's username">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => <TextField {...props} />}
                    </Field>
                </FormGroup>
                <FormGroup
                    className="mt3 field-inline-labels field-inline-labels--tooltip"
                    label="Password"
                    variant={FormGroupVariantType.HORIZONTAL}
                >
                    <Field name="password" type="password" placeholder="Connection's password">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <TextField
                                {...props}
                                type="password"
                                disabled={!values.changePasswordAllowed}
                                tooltip="For security purposes, we do not populate passwords in this field.
                        If you wish to update the password, please check the box and enter a
                        new password in the input."
                            />
                        )}
                    </Field>
                </FormGroup>

                <FormGroup className="mt3" variant={FormGroupVariantType.HORIZONTAL} label=" ">
                    <Field type="checkbox" name="changePasswordAllowed">
                        {(props: FieldRenderProps<string, HTMLInputElement>) => (
                            <CheckboxField
                                {...props}
                                label="I want to change the password"
                                id="changePasswordAllowed"
                                data-testid="password-checkbox"
                            />
                        )}
                    </Field>
                </FormGroup>

                {host?.captureMode === HostCaptureModes.POLL && (
                    <>
                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3"
                            label="Google Cloud Project ID"
                        >
                            <HTML5Field name="gcpProjectID" data-testid="gcpProject" component={TextField}></HTML5Field>
                        </FormGroup>

                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3"
                            label="Google Cloud Database ID"
                        >
                            <HTML5Field
                                name="gcpResourceID"
                                data-testid="gcpResource"
                                required={values?.gcpProjectID}
                                valueMissing="Required when configuring Google Cloud"
                                component={TextField}
                            ></HTML5Field>
                        </FormGroup>
                    </>
                )}
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="SSL Enabled">
                    <Field name="sslEnabled">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <SlideSwitchField checked={values?.sslEnabled} {...props} />
                        )}
                    </Field>
                </FormGroup>

                {values.sslEnabled && (
                    <>
                        <FormGroup variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="SSL Mode">
                            <Field name="sslMode">
                                {({ ...props }: FieldRenderProps<string, HTMLSelectElement>) => (
                                    <SelectField
                                        {...props}
                                        options={sslModeOptions}
                                        data-testid="ssl-mode"
                                        helpText={getSSLModeHelpText(props.value)}
                                    />
                                )}
                            </Field>
                        </FormGroup>
                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3 field-inline-labels field-inline-labels--tooltip"
                            label="SSL Authority"
                        >
                            <Field name="sslCA">
                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                    <TextField
                                        {...props}
                                        tooltip="The location of the root certificate file. The file must contain PEM encoded data."
                                    />
                                )}
                            </Field>
                        </FormGroup>
                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3 field-inline-labels field-inline-labels--tooltip"
                            label="SSL Certificate"
                        >
                            <Field name="sslCert">
                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                    <TextField
                                        {...props}
                                        tooltip="Cert file location. The file must contain PEM encoded data."
                                    />
                                )}
                            </Field>
                        </FormGroup>
                        <FormGroup
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mt3 field-inline-labels field-inline-labels--tooltip"
                            label="SSL Key"
                        >
                            <Field name="sslKey">
                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                    <TextField
                                        {...props}
                                        tooltip="Key file location. The file must contain PEM encoded data."
                                    />
                                )}
                            </Field>
                        </FormGroup>
                    </>
                )}
            </>
        )) || <Bouncer className="my4 flex full-width justify-center" />
    );
};

export default PostgreSQLCredentialsFields;
