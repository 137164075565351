import Icon from 'components/icons/Icon';
import BackgroundBar from 'components/visualizations/BackgroundBar';
import HostBreakDown from 'models/HostsBreakDown';
import { FC } from 'react';
import Host from 'models/hosts/Host';

interface BreakDownListInterface {
    list: HostBreakDown[];
    onHostClick: (selectedHost: Host | undefined) => React.MouseEventHandler;
}

const BreakDownList: FC<BreakDownListInterface> = ({ list, onHostClick }) => {
    return (
        <>
            {list.map(breakDown => {
                const percentage = breakDown.pct * 100;
                return (
                    <div key={breakDown.host?.id} className="host_breakdown">
                        <div className="flex">
                            <Icon icon={`${breakDown.host?.type}`} className="grey3 h2 mr3"></Icon>
                            <span className="host_title" onClick={onHostClick(breakDown.host)}>
                                {breakDown.host?.name}
                            </span>
                        </div>
                        <div className="flex">
                            <span className="host_breakdown-percentage">
                                {`${percentage === 100 ? percentage : percentage.toFixed(2)}%`}
                            </span>
                            <BackgroundBar className="breakDown_progress_bar" val={percentage} min={0} max={100} />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default BreakDownList;
