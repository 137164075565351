import { FC, useState } from 'react';
import cn from 'helpers/classname';
import Icon from 'components/icons/Icon';
import useOnOutsideClick from 'hooks/useOnOutsideClick';

const EditableInput: FC<{
    value: string;
    onChange: (value: string) => void;
    className?: string;
    disabled?: boolean;
}> = ({ value, onChange, className = '', disabled = false }) => {
    const [editing, setEditing] = useState(false);
    const [editedText, setEditedText] = useState(value);

    const handleFocusOut = () => {
        saveChanges();
    };

    const { ref } = useOnOutsideClick<HTMLDivElement>(handleFocusOut);

    const handleKeyPress = (key: string, targetValue: string) => {
        setEditedText(targetValue);
        if (key === 'Enter') {
            saveChanges();
        }
    };

    const cancel = () => {
        setEditedText(value);
        setEditing(false);
    };

    const saveChanges = () => {
        setEditing(false);
        onChange(editedText);
    };

    const startEditing = () => {
        setEditing(true);
    };

    return (
        <div className={`${className} editable-input relative`}>
            {disabled && <span>{value}</span>}
            {!disabled && (
                <div className={`editable-input__content ${cn('hidden', editing)}`}>
                    <span>{value}</span>
                    <Icon
                        icon="edit"
                        className="mainColor fz18 cursor-hand ml2 absolute inline-flex"
                        data-testid="activate-edit-input-button"
                        onClick={startEditing}
                    />
                </div>
            )}
            {editing && (
                <div className="absolute z1 editable-input__dialog flex items-center full-width" ref={ref}>
                    <input
                        className="editable-input__dialog__input bg-white truncate flex-grow-1"
                        type="text"
                        defaultValue={editedText}
                        data-testid="editable-input"
                        onKeyUp={e => handleKeyPress(e.key, e.currentTarget.value)}
                    />

                    <div className="editable-input__dialog__actions flex nowrap items-baseline fz16 nunito spacing-normal regular">
                        <button className="link mx2" onClick={cancel}>
                            <Icon icon="close" className="mainColor inline-flex mr1 fz18" />
                            Cancel
                        </button>
                        <button className="link" onClick={saveChanges}>
                            <Icon icon="done" className="mainColor inline-flex mr1 fz18" />
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditableInput;
