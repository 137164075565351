import moment from 'moment-timezone';
import Host from 'models/hosts/Host';

const TTL = 60; // seconds

function isExpired(cache: HostCacheInterface) {
    const now = moment().unix();

    return now - cache.timestamp > TTL;
}

interface HostCacheInterface {
    host: Host;
    timestamp: number;
}

const cache: Record<number, HostCacheInterface> = {};

export function get(id: number) {
    if (!cache[id] || isExpired(cache[id])) {
        return null;
    }

    return cache[id].host;
}

export function set(host: Host) {
    cache[host.id] = {
        host,
        timestamp: moment().unix(),
    };
}
