import Logger from 'services/logger';
import moment from 'moment-timezone';

export type ValueType =
    | string
    | number
    | boolean
    | Record<string, unknown>
    | string[]
    | number[]
    | boolean[]
    | Date
    | moment.Moment;

const logger = Logger.get('UIKey');

export default class UIKey {
    readonly key: string;
    private defaultValue: ValueType;
    private type: string;
    private storagePrefix: string;
    private excludeFromStorage: boolean;
    private excludeFromParams: boolean;

    constructor(
        key: string,
        value: ValueType,
        storagePrefix: string,
        excludeFromStorage: boolean,
        excludeFromParams: boolean,
        private params: URLSearchParams
    ) {
        this.key = key;
        this.defaultValue = value;
        this.type = this.getType(value);
        this.storagePrefix = storagePrefix;
        this.excludeFromStorage = excludeFromStorage;
        this.excludeFromParams = excludeFromParams;

        if (this.excludeFromParams && this.excludeFromStorage) {
            throw new Error(`No storage method provided for UI Key ${this.key}`);
        }
    }

    initSearchParams() {
        if (this.excludeFromParams) {
            return;
        }

        const value = this.read();

        this.saveInSearchParams(value);
    }

    private toString(value: ValueType) {
        // We stringify strings so that we can maintain compatibility with ng-app. ng-app
        // wraps strings in double quotes. For key/values that are used in both projects,
        // we must wrap values here, as well.
        // See https://github.com/VividCortex/webapp/issues/1434
        // Also, see https://github.com/VividCortex/ng-app/blob/master/src/js/models/localStorage.js#L69
        if (typeof value === 'string') {
            return JSON.stringify(value);
        }

        let normalized = value;
        if (moment.isMoment(value)) {
            normalized = value.unix();
        }
        if (value instanceof Date) {
            normalized = moment(value).unix();
        }
        return JSON.stringify(normalized);
    }

    private parse(value: string | string[]) {
        if (Array.isArray(value)) {
            return value;
        }

        switch (this.type) {
            case 'string':
                // See https://github.com/VividCortex/webapp/issues/1434
                // We need to be able to parse values from ng-app if they are in double-quotes
                try {
                    return JSON.parse(value).toString();
                } catch {
                    return value;
                }
            case 'moment-timezone': {
                const int = parseInt(value, 10);
                return isNaN(int) ? null : moment.unix(int);
            }
            case 'date': {
                const int = parseInt(value, 10);
                return isNaN(int) ? null : moment.unix(int).toDate();
            }
            case 'number': {
                const float = parseFloat(value);
                return isNaN(float) ? NaN : float;
            }
            default:
                try {
                    return JSON.parse(value);
                } catch (e) {
                    logger.error(e);
                    return this.defaultValue;
                }
        }
    }

    private getType(value: ValueType) {
        if (Array.isArray(value)) {
            return 'array';
        }
        if (value instanceof Date) {
            return 'date';
        }
        if (moment.isMoment(value)) {
            return 'moment-timezone';
        }

        return typeof value;
    }

    private saveInSearchParams(value: ValueType) {
        if (!Array.isArray(value)) {
            this.searchParams.set(this.key, JSON.parse(this.toString(value)));
            return;
        }

        // If it's an array, we save it by repeating the same key for each value.
        // For example: col=['a','b','c'] would be stored as col=a&col=b&col=c
        // this grants compatibility with ng-app
        this.searchParams.delete(this.key);
        value.forEach((item: ValueType) => {
            this.searchParams.append(this.key, JSON.parse(this.toString(item as ValueType)));
        });
    }

    private readSearchParams() {
        if (this.type === 'array') {
            const result = this.searchParams.getAll(this.key);
            return result.length > 0 ? result : null;
        }
        return this.searchParams.get(this.key);
    }

    get searchParams() {
        return this.params;
    }

    set searchParams(params: URLSearchParams) {
        this.params = params;
    }

    read() {
        let value = undefined;

        if (!this.excludeFromParams) {
            value = this.readSearchParams();
        }

        if ((value === undefined || value === null) && !this.excludeFromStorage) {
            value = localStorage.getItem(this.storagePrefix + this.key);
        }

        if (value) {
            return this.parse(value);
        }

        // use the default value when the parameter is not present in the url or local storage
        return this.defaultValue;
    }

    write(value: ValueType) {
        if (this.getType(value) !== this.type) {
            throw new Error(
                `[UIKey] ${value} should be of type "${this.type}" but it is "${this.getType(value)}" instead`
            );
        }

        //save in local storage
        if (!this.excludeFromStorage) {
            localStorage.setItem(this.storagePrefix + this.key, this.toString(value));
        }

        //save in url storage
        if (!this.excludeFromParams) {
            this.saveInSearchParams(value);
        }
    }
}
