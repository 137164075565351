import React, { useState } from 'react';
import { ProfilerInterface } from 'models/profiler';
import ModuleTitle from '../ModuleTitle';
import Form from './Form';
import Table from './Table';

const ProfilerPage: React.FC = () => {
    const [profiler, setProfiler] = useState<ProfilerInterface>();

    return (
        <div className="view profiler px3 pt3" data-testid="profiler-table-page">
            <ModuleTitle contextualHelpId="profiler" className="section__header">
                Profiler
            </ModuleTitle>

            <Form onFilter={setProfiler} />

            {profiler && <Table profiler={profiler} />}
        </div>
    );
};

export default ProfilerPage;
