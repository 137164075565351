import { useEffect, useState, FC } from 'react';
import { formatTimeAsync, DateTimeFormat } from 'services/timezone';

const Timestamp: FC<{ date: number; format?: DateTimeFormat }> = ({ date, format }) => {
    const [formattedTimestamp, setFormattedTimestamp] = useState('');

    useEffect(() => {
        async function formatDate() {
            const timestamp = await formatTimeAsync(date, format);

            setFormattedTimestamp(timestamp);
        }

        formatDate();
    }, [date, format]);

    return formattedTimestamp ? <span data-testid="formatted-timestamp">{formattedTimestamp}</span> : null;
};

export default Timestamp;
