import APIRequest from './Request';
import Query, { QueryDataInterface } from 'models/Query';
import { HostBreakdownInterface } from 'models/HostsBreakDown';
import TimeInterval from 'models/TimeInterval';

interface PlanbookNodeInterface {
    style: string;
    cost: string;
    display: string[];
    extra: string[];
}

interface PlanbookEdgeInterface {
    style: string;
    width: string;
}

export interface PlanbookDataInterface {
    database: string;
    version: string;
    spec: string;
    defaultNode: PlanbookNodeInterface;
    nodes: Record<string, PlanbookNodeInterface>;
    edges: Record<string, PlanbookEdgeInterface>;
}

export interface QueriesFilterInterface {
    filter: string;
    limit: number;
    exists: string;
    offset: string;
    errorCode: string;
    filterCheckbox: number;
    newQueriesOnly?: boolean;
    ignoreHostsAndTime?: boolean;
}

export async function fetch(hostFilter: string, interval: TimeInterval, filters: QueriesFilterInterface) {
    const { filterCheckbox, ...filter } = filters;
    const query = { ...filter, new: filterCheckbox === 1 ? 1 : 0 };
    let params = { ...query, host: hostFilter };

    if (filterCheckbox !== 2) {
        params = { ...params, ...interval.asParams() };
    }

    const result = await new APIRequest<QueryDataInterface[]>({
        path: `queries`,
        method: 'get',
        params,
    }).request();

    return {
        queries: result.data.map(d => new Query(d)),
        hasMore: result.metadata.more || false,
    };
}

export async function fetchQuery(id: string, withTags = false) {
    const result = await new APIRequest<QueryDataInterface[]>({
        path: `queries/${id}`,
        method: 'get',
        params: {
            tags: withTags,
        },
    }).request();

    if (!result.data.length) {
        throw new Error(`Received no data for query ${id}`);
    }

    return new Query(result.data[0]);
}

export async function fetchHostsBreakDown(id: string, columnID: string, interval: TimeInterval, hostFilter: string) {
    const result = await new APIRequest<HostBreakdownInterface[]>({
        path: 'metrics/hostBreakdown',
        method: 'get',
        params: {
            queryID: id,
            from: interval.fromTs,
            until: interval.untilTs,
            host: hostFilter ? `!type=os and ${hostFilter}` : '!type=os',
            columnID: columnID,
        },
    }).request();

    if (!result.data.length) {
        throw new Error(`Received no data for query ${id}`);
    }

    return result.data;
}

export async function getPlanbook(database: string, version: string, format = 'diagram') {
    const response = await new APIRequest<PlanbookDataInterface>({
        path: `queries/planbooks`,
        method: 'get',
        params: {
            database,
            version,
            format,
        },
    }).request();

    if (!response.data) {
        throw new Error(`Received no planbook for ${database} ${version}`);
    }

    return response.data;
}
