import Query from 'models/Query';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import { FC, useState, useEffect } from 'react';
import { fetchQuery } from 'services/api/queries';
import { Link } from 'components/url/Link';

const cache: Record<string, Query> = {};

const AsyncQueryDigest: FC<{ queryId: string }> = ({ queryId }) => {
    const [query, setQuery] = useState<Query | null>(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (cache[queryId]) {
            setQuery(cache[queryId]);

            return;
        }

        fetchQuery(queryId)
            .then((fetchedQuery: Query) => {
                cache[queryId] = fetchedQuery;
                setQuery(fetchedQuery);
            })
            .catch(() => setError(true));
    }, [queryId]);

    if (!query && !error) {
        return (
            <div className="center nowrap">
                <Loader className="loader--lightGrey" type={LoaderTypes.TABLECELL}></Loader>
            </div>
        );
    }

    if (query) {
        return (
            <div title={query.digest} className="relative flex items-center vc-table__cell--query__digest">
                <div className="truncate absolute full-width">
                    <Link
                        to={`queries/${queryId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="line-height-120"
                    >
                        {query.digest}
                    </Link>
                </div>
            </div>
        );
    }

    return <div>{queryId}</div>;
};

export default AsyncQueryDigest;
