import APIRequest from './Request';

export async function search(name: string) {
    const response = await new APIRequest<string[]>({
        path: 'metrics/search',
        params: {
            q: name,
        },
    }).request();

    return response.data;
}
