import { FC } from 'react';
import useEnv from 'hooks/useEnv';
import { Redirect, useLocation } from 'react-router-dom';

const InvalidEnvRedirect: FC = () => {
    const { pathname } = useLocation();

    try {
        useEnv();
    } catch {
        if (pathname !== '/logout') {
            return <Redirect to="/" />;
        }
    }

    return null;
};

export default InvalidEnvRedirect;
