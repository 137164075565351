import * as React from 'react';
import useOrg from 'hooks/useOrg';
import EnvironmentSelector from '../sidebar/EnvironmentSelector';
import Navigation from '../modules/app/Navigation';
import AddButton from '../sidebar/AddButton';
import moment from 'moment-timezone';
import TrialBox from '../sidebar/TrialBox';
import Icon from '../icons/Icon';

interface SidebarPropsInterface {
    collapsed: boolean;
    onToggle: () => void;
}

const Sidebar: React.FC<SidebarPropsInterface> = ({ collapsed, onToggle }) => {
    const trial = useOrg().isTrial();

    return (
        <>
            <div
                className={`sidebar fixed z2 bg-paleDark ${trial ? 'secondary--upgrade' : ''} ${
                    collapsed ? 'sidebar--collapsed' : ''
                }`}
                data-testid="sidebar"
            >
                <EnvironmentSelector />

                <Navigation collapsed={collapsed} />

                <AddButton />

                <TrialBox />

                {!collapsed && (
                    <div className="copyright no-shrink fz14 grey3 flex justify-center flex-grow-1 items-end overflow-hidden pt1 pb3">
                        &copy;{moment().year()} SolarWinds
                    </div>
                )}
                <button className="z3 sidebar__toggle relative cursor-hand" onClick={onToggle} title="Toggle sidebar">
                    <Icon icon={`keyboard_arrow_${collapsed ? 'right' : 'left'}`} className="flex grey3 h2 absolute" />
                    <Icon icon={`keyboard_arrow_${collapsed ? 'right' : 'left'}`} className="flex grey3 h2 absolute" />
                </button>
            </div>
        </>
    );
};

export default Sidebar;
