const INTEGRATION_NAME_CONVERSION: { [key: string]: string } = Object.freeze({
    MsTeams: 'Microsoft Teams',
});

export interface APIIntegrationInterface {
    id: number;
    name: string;
    type: number;
    desc: string;
    config: APIIntegrationConfigInterface[];
}

interface APIIntegrationConfigInterface {
    key: string;
    type: string;
    label: string;
    hint: string;
    default: string;
}

export default class Integration {
    readonly id: number;
    readonly name: string;
    readonly displayName: string;
    readonly type: number;
    readonly desc: string;
    readonly config: APIIntegrationConfigInterface[];

    constructor(data: APIIntegrationInterface) {
        this.id = data.id;
        this.name = data.name;
        this.type = data.type;
        this.desc = data.desc;
        this.config = data.config;
        this.displayName = INTEGRATION_NAME_CONVERSION[data.name] || data.name;
    }
}
