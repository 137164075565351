import { FC } from 'react';
import Pin from 'components/icons/Pinning';
import TimeAgo from 'components/format/TimeAgo';
import FeatureLink from 'components/url/FeatureLink';
import Query from 'models/Query';

const TableRow: FC<{ query: Query; onPinToggle: (query: Query) => void; isPin?: boolean }> = ({
    query,
    onPinToggle,
    isPin = false,
}) => {
    return (
        <tr data-testid="query-row" key={query.id}>
            <td>
                <Pin
                    onClick={() => onPinToggle(query)}
                    className={`fz18 flex justify-center cursor-hand hoverable grey3 ${isPin ? 'mainColor' : ''}`}
                />
            </td>
            <td>
                <div className="flex justify-center"></div>
            </td>
            <td className="full-width">
                <div className="relative">
                    <div className="table-cell truncate absolute vc-table--samples__sample-link" title={query.digest}>
                        <FeatureLink to={`queries/${query.id}`}>{query.digest}</FeatureLink>
                    </div>
                </div>
            </td>
            <td>
                <span className="monospace dark nowrap fz13">
                    <TimeAgo date={query.firstSeen} />
                </span>
            </td>
        </tr>
    );
};

export default TableRow;
