import BouncerButton from 'components/form/BouncerButton';
import Quantity from 'components/numbers/Quantity';
import Agent from 'models/Agent';
import { FC, useEffect, useState } from 'react';
import { fromName as unitFromName } from 'models/numbers/Unit';
import Qty from 'models/numbers/Quantity';
import LogPoller from 'services/api/LogPoller';
import useMounted from 'hooks/useMounted';

const LogFetcher: FC<{
    size: number;
    agent: Agent;
    enabled: boolean;
    onRequestLog: () => void;
    onFetchLog: (log: string, agent: Agent) => void;
    onFetchLogError: () => void;
}> = ({ size, agent, enabled, onRequestLog, onFetchLog, onFetchLogError }) => {
    const { resolveIfMounted } = useMounted();
    const [loading, setLoading] = useState(false);
    const unit = unitFromName('byte');

    const [formattedSize, setFormattedSize] = useState(new Qty(size, unit));
    const [logPoller, setLogPoller] = useState<LogPoller | null>(null);

    useEffect(() => {
        setFormattedSize(new Qty(size, unit));
        return function cleanup() {
            if (!logPoller) {
                return;
            }
            logPoller.stopAll();
        };
    }, [size, unit, logPoller]);

    const fetchLog = async () => {
        onRequestLog();
        setLoading(true);

        const logPollerInstance = new LogPoller({ hostId: agent.targetHost, size, agent });
        setLogPoller(logPollerInstance);

        resolveIfMounted(logPollerInstance.start().then(content => onFetchLog(content as string, agent)))
            .catch(() => onFetchLogError())
            .finally(() => setLoading(false));
    };

    return (
        <BouncerButton
            className="m1 primary-orange agent-actions__dropdown__button"
            type="button"
            disabled={!enabled}
            bounce={loading}
            bouncerClassName="white inline-flex ml2"
            onClick={fetchLog}
            data-testid={`log-${size}`}
        >
            <Quantity quantity={formattedSize} />
        </BouncerButton>
    );
};

export default LogFetcher;
