import { FC, useState } from 'react';
import SlideSwitch from 'components/form/SlideSwitch';

const PinSwitch: FC<{
    visible: boolean;
    text: string;
    onChange?: (checked: boolean) => void;
    onRemove?: () => void;
}> = ({ visible, text, onChange = () => {}, onRemove = () => {} }) => {
    const [selected, setSelected] = useState(true);

    const handleChange = () => {
        const newValue = !selected;
        setSelected(newValue);
        onChange(newValue);
    };

    return visible ? (
        <div className="px2 mb2 flex" data-testid="pinSwitch">
            {selected && (
                <span>
                    <span onClick={onRemove} className="cursor-hand link" data-testid="pinSwitchRemoveAll">
                        Remove all pins
                    </span>
                    <span> /&nbsp;</span>
                </span>
            )}
            <span>{text}</span>
            <span className="inline-flex ml2 relative align__bottom--1" data-testid="pinSlideSwitch">
                <SlideSwitch checked={selected} onChange={handleChange} />
            </span>
        </div>
    ) : null;
};

export default PinSwitch;
