import cn from 'helpers/classname';
import Icon from 'components/icons/Icon';
import useUrl from 'hooks/useUrl';
import { FC, FormEvent, useState, useEffect } from 'react';
import { get } from 'services/http';

interface ImpersonatorUserInterface {
    user: string;
    org: string;
}

const keyMap = {
    DOWN: 'ArrowDown',
    UP: 'ArrowUp',
};

const UserSelector: FC = () => {
    const [search, setSearch] = useState('');
    const [results, setResults] = useState<ImpersonatorUserInterface[] | null>(null);
    const [selected, setSelected] = useState(0);
    const endpoint = useUrl('orgOwner');

    useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => {
            if (!results) {
                return;
            }

            switch (e.code) {
                case keyMap.UP:
                    return setSelected(currentSelected => Math.max(currentSelected - 1, 0));
                case keyMap.DOWN:
                    return setSelected(currentSelected => Math.min(currentSelected + 1, results.length - 1));
            }
        };

        document.addEventListener('keydown', keyHandler);

        return () => document.removeEventListener('keydown', keyHandler);
    }, [results]);

    useEffect(() => {
        if (search === '') {
            setResults(null);
            setSelected(0);

            return;
        }

        get<{ data: ImpersonatorUserInterface[] }>(`${endpoint}?filter=${search}`).then(response =>
            setResults(response.data?.data)
        );
    }, [search, endpoint]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!results) {
            return;
        }

        window.location.replace(`/impersonator/request/${results[selected].user}/${results[selected].org}`);
    };

    return (
        <div className="impersonator__user">
            <div className="flex items-center p2">
                <Icon icon="people" className="white fz18 inline-flex mr2" />
                <form onSubmit={handleSubmit} className="flex-grow-1">
                    <input
                        id="impersonator-input"
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.currentTarget.value)}
                        placeholder="Email or org"
                        autoComplete="off"
                        className="no-border full-width"
                        autoFocus
                    />
                </form>
            </div>
            {results && (
                <ul className="impersonator__list list-reset bg-DarkMainColor mx2 rounded">
                    {results.map((user, i) => (
                        <li
                            className={`impersonator__result-item bg-white${cn(
                                'impersonator__result-item--selected',
                                i === selected
                            )}`}
                            key={i}
                        >
                            <a
                                href={`/impersonator/request/${user.user}/${user.org}`}
                                className="p2 flex items-center justify-between cursor-hand"
                            >
                                <div className="truncate">{user.user}</div>
                                <div className="nowrap pl2 bold">{user.org}</div>
                            </a>
                        </li>
                    ))}
                </ul>
            )}
            {results && results.length === 0 && <div className="p2 white">No results found.</div>}
        </div>
    );
};

export default UserSelector;
