import Category from 'models/bestPractices/Category';
import cn from 'helpers/classname';
import Controls from './Controls';
import Icon from 'components/icons/Icon';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import Logger from 'services/logger';
import Occurrence from 'models/bestPractices/Occurrence';
import OccurrenceTarget from 'models/bestPractices/OccurrenceTarget';
import plural from 'helpers/pluralize';
import { FC, useState, useEffect } from 'react';
import ServerError from 'components/messages/ServerError';
import Summary from 'models/bestPractices/Summary';
import TargetsTable from './TargetsTable';
import * as apiAsserts from 'services/api/asserts';
import { fetchOccurrences } from 'services/api/asserts';
import { getInteractionReporter } from 'services/analytics/analytics';
import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';
import { UserHasPermission } from 'components/security/UserHasPermission';

const logger = Logger.get('BestPracticesList');
const reportActivity = getInteractionReporter('best-practices');

interface ListPropsInterface {
    summary: Summary;
    showPassed: boolean;
    showMuted: boolean;
    togglePassed: () => void;
    toggleMuted: () => void;
    afterMuteToggle: () => void;
}

const PAG_OFFSET = 50;

const BestPracticesList: FC<ListPropsInterface> = ({ summary, showPassed, showMuted, afterMuteToggle, ...rest }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const { filter } = useHostFilter();
    const { interval } = useTimeInterval();

    useEffect(() => {
        setCategories(summary.categories);

        summary.categories.forEach(category => {
            if (category.expanded) {
                expand(category, true);
            }
        });
    }, [showPassed, showMuted, summary]); // eslint-disable-line

    function totalResults(category: Category) {
        let amount = category.failed;

        if (showPassed) {
            amount += category.passed;
        }

        if (showMuted) {
            amount += category.muted;
        }

        return amount;
    }

    function toggleCategory(category: Category) {
        category.expanded = !category.expanded;

        if (category.expanded && !category.occurrences) {
            category.expanding = true;
            category.errorExpanding = false;

            expand(category);
        }

        setCategories([...categories]);

        reportActivity('toggle', category.expanded ? 'expand category' : 'collapse category');
    }

    function toggleOccurrence(occurrence: Occurrence) {
        occurrence.expanded = !occurrence.expanded;

        setCategories([...categories]);

        reportActivity('toggle', occurrence.expanded ? 'expand occurrence' : 'collapse occurrence');
    }

    function toggleAll(expanded: boolean) {
        if (!summary) {
            return;
        }

        reportActivity('toggle', expanded ? 'expand all' : 'collapse all');

        setCategories(
            categories.map(category => {
                category.expanded = expanded;

                if (expanded) {
                    expand(category);
                }

                return category;
            })
        );
    }

    function expand(category: Category, override = false) {
        if (!override && category.occurrences?.length) {
            return;
        }

        fetchOccurrences({
            category: category.name,
            host: filter,
            showPassed,
            showMuted,
            offset: PAG_OFFSET,
            interval,
        })
            .then((occurrences: Occurrence[]) => {
                category.setOccurrences(occurrences);
            })
            .catch(e => {
                logger.error(e);

                category.errorExpanding = true;
            })
            .finally(() => {
                category.expanding = false;
                setCategories([...categories]);
            });
    }

    function paginate(category: Category, occurrence: Occurrence) {
        occurrence.offset += PAG_OFFSET;

        reportActivity('pagination', 'next page');

        fetchOccurrences({
            category: category.name,
            host: filter,
            showPassed,
            showMuted,
            offset: occurrence.offset,
            interval,
            paginateId: occurrence.id,
        })
            .then((occurrences: Occurrence[]) => {
                if (!occurrences.length) {
                    category.occurrences?.splice(category.occurrences.indexOf(occurrence), 1);

                    return;
                }

                occurrence.hasMore = occurrences[0].hasMore;
                occurrence.targets = occurrences[0].targets;
            })
            .catch(e => {
                logger.error(e);

                category.errorExpanding = true;
            })
            .finally(() => {
                category.expanding = false;
                setCategories([...categories]);
            });
    }

    async function mute(category: Category, occurrence: Occurrence, target?: OccurrenceTarget) {
        await apiAsserts.mute(occurrence, target);

        expand(category, true);

        afterMuteToggle();

        reportActivity('supression', target ? 'suppress' : 'suppress on all hosts');
    }

    async function unmute(category: Category, occurrence: Occurrence, target?: OccurrenceTarget) {
        await apiAsserts.unmute(occurrence, target);

        expand(category, true);

        afterMuteToggle();

        reportActivity('supression', target ? 'unsuppress' : 'unsuppress on all hosts');
    }

    function referenceClicked() {
        reportActivity('reference', 'clicked');
    }

    return (
        <>
            <Controls toggleAll={toggleAll} showPassed={showPassed} showMuted={showMuted} {...rest} />
            <ul className="list-reset mb3 pb4">
                {categories.map((category: Category) => (
                    <li
                        className={`tile rounded relative landing-card mb2 flex flex-column${cn(
                            'best-practices__list__item--zeroes',
                            !totalResults(category)
                        )}`}
                        data-testid="assertion-container"
                        key={category.name}
                    >
                        <div className="p2 flex items-center best-practices__list__item__title-wrap">
                            <span
                                className="no-decoration flex items-center flex-grow-1 cursor-hand health__row__toggle"
                                data-testid={`assertion-category-${category.name}`}
                                onClick={() => toggleCategory(category)}
                            >
                                <Icon
                                    icon="keyboard_arrow_right"
                                    className={`inline-flex h2 mr2 mainColor best-practices__list__item__title-wrap__arrow${cn(
                                        'health__category--hidden',
                                        category.expanded
                                    )}${cn('health__category--shown', !category.expanded)}`}
                                />
                                <div className="inline-flex bold h4 dark best-practices__list__item__title-wrap__title">
                                    {category.name} ({category.failedAsserts}{' '}
                                    {plural(category.failedAsserts, 'Recommendation')}, {category.failed}{' '}
                                    {plural(category.failed, 'Occurrence')})
                                </div>
                            </span>
                            <div className="flex h4">
                                <div className="health__severity relative flex mr2 items-center">
                                    <div
                                        className={`health__severity__indicator border border-color-red border-thick circle mr1 ${
                                            !category.critical ? 'bg-white' : 'bg-red'
                                        }`}
                                    ></div>
                                    <span data-testid="critical-indicator" className={cn('grey2', !category.critical)}>
                                        {category.critical}
                                    </span>
                                </div>
                                <div className="health__severity relative flex mr2 items-center">
                                    <div
                                        className={`health__severity__indicator border border-color-orange border-thick circle mr1 ${
                                            !category.warning ? 'bg-white' : 'bg-orange'
                                        }`}
                                    ></div>
                                    <span data-testid="warning-indicator" className={cn('grey2', !category.warning)}>
                                        {category.warning}
                                    </span>
                                </div>
                                <div className="health__severity relative flex items-center">
                                    <div
                                        className={`health__severity__indicator border border-color-blue border-thick circle mr1 ${
                                            !category.info ? 'bg-white' : 'bg-blue'
                                        }`}
                                    ></div>
                                    <span data-testid="info-indicator" className={cn('grey2', !category.info)}>
                                        {category.info}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {category.expanding && (
                            <div className="center p2 nowrap">
                                <Loader className="loader--thick" type={LoaderTypes.TABLECELL}></Loader>
                            </div>
                        )}

                        {category.errorExpanding && (
                            <div>
                                <ServerError />
                            </div>
                        )}

                        {category.expanded && (
                            <>
                                {category.occurrences &&
                                    category.occurrences.map((occurrence: Occurrence) => (
                                        <div
                                            className="e2e-health__occurrences border-top border-color-grey1"
                                            key={occurrence.id}
                                        >
                                            <div className="flex items-center p2">
                                                <span
                                                    className="flex items-center flex-grow-1 no-decoration dark cursor-hand health__row__toggle"
                                                    data-testid="occurrence"
                                                    onClick={() => toggleOccurrence(occurrence)}
                                                >
                                                    <div
                                                        className={`health__severity__indicator health__severity__indicator--occurrence border border-thick circle mx1 ${occurrence.severity}`}
                                                    ></div>
                                                    <Icon
                                                        icon="keyboard_arrow_right"
                                                        className="inline-flex h2 mr2 mainColor"
                                                    />
                                                    <div className="h4">
                                                        <span>{occurrence.title}</span>
                                                        {occurrence.muted && <span> (All Muted)</span>}
                                                        {occurrence.passed && <span> (All Passed)</span>}
                                                    </div>
                                                </span>
                                                <UserHasPermission to="changeEnv">
                                                    {!occurrence.muted && (
                                                        <span
                                                            className="primary-grey-light grey3 no-decoration cursor-hand"
                                                            onClick={() => mute(category, occurrence)}
                                                        >
                                                            Mute all
                                                        </span>
                                                    )}
                                                    {occurrence.muted && (
                                                        <span
                                                            className="primary-grey-light grey3 no-decoration cursor-hand"
                                                            onClick={() => unmute(category, occurrence)}
                                                        >
                                                            Unmute all
                                                        </span>
                                                    )}
                                                </UserHasPermission>
                                            </div>
                                            {occurrence.expanded && (
                                                <div className="health__occurrence__explain border-top border-color-grey1 mr2 py2">
                                                    <p className="grey3 spacing uppercase fz10 mb2 bold">
                                                        Recommendation
                                                    </p>
                                                    <p className="mb2">
                                                        {occurrence.description}

                                                        <a
                                                            href={`http://docs.vividcortex.com/how-to-use-vividcortex/best-practices/#${occurrence.docsHash}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="mt1 block"
                                                            onClick={referenceClicked}
                                                        >
                                                            Reference
                                                        </a>
                                                    </p>
                                                    <div className="relative overflow-auto">
                                                        <TargetsTable
                                                            occurrence={occurrence}
                                                            category={category}
                                                            mute={mute}
                                                            unmute={unmute}
                                                        />
                                                    </div>
                                                    <div className="best-practices__list__item__ocurrence">
                                                        <div className="best-practices__list__item__ocurrence__row py2 center">
                                                            {occurrence.hasMore && (
                                                                <>
                                                                    <span className="mr2">
                                                                        Showing {occurrence.targets.length} results.
                                                                    </span>
                                                                    <button
                                                                        className="primary-orange pager"
                                                                        onClick={() => paginate(category, occurrence)}
                                                                    >
                                                                        Load more results
                                                                    </button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default BestPracticesList;
