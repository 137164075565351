import Summary from 'models/bestPractices/Summary';
import * as React from 'react';

const BestPracticesSummary: React.FC<{ summary: Summary }> = ({ summary }) => {
    return (
        <section className="tile rounded relative landing-card mb2">
            <div className="flex flex-grow-1 items-center">
                <div className="landing-card__header landing-card__header--health grey3 p2 border-right border-color-grey1 no-shrink">
                    <h2 className="h2 mb3">Recommendations to Check</h2>
                    <p className="fz14 line-height-120">Expert advice about potential problems and opportunities</p>
                </div>

                <div className="health__summary__list px3">
                    <div className="px2 bg-red rounded white flex items-center justify-between health__summary__list__count">
                        <span
                            className="monospace h1 health__summary__list__count__number"
                            data-testid="critical-recommendations"
                        >
                            {summary.critical}
                        </span>
                        <span className="uppercase">Critical</span>
                    </div>
                    <div className="px2 bg-orange rounded white flex items-center justify-between health__summary__list__count">
                        <span
                            className="monospace h1 health__summary__list__count__number"
                            data-testid="warning-recommendations"
                        >
                            {summary.warning}
                        </span>
                        <span className="uppercase">Warning</span>
                    </div>
                    <div className="px2 bg-blue rounded white flex items-center justify-between health__summary__list__count">
                        <span
                            className="monospace h1 health__summary__list__count__number"
                            data-testid="info-recommendations"
                        >
                            {summary.info}
                        </span>
                        <span className="uppercase">Information</span>
                    </div>
                    <div className="px2 bg-green rounded white flex items-center justify-between health__summary__list__count">
                        <span
                            className="monospace h1 health__summary__list__count__number"
                            data-testid="passed-recommendations"
                        >
                            {summary.passed}
                        </span>
                        <span className="uppercase">Passed</span>
                    </div>
                    <div className="px2 bg-grey2 rounded white flex items-center justify-between health__summary__list__count">
                        <span
                            className="monospace h1 health__summary__list__count__number"
                            data-testid="suppressed-recommendations"
                        >
                            {summary.muted}
                        </span>
                        <span className="uppercase">Muted</span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BestPracticesSummary;
