import { ChangeEvent, FC, useState } from 'react';
import Bouncer from 'components/icons/Bouncer';
import Icon from 'components/icons/Icon';
import Logger from 'services/logger';
import useDisplayMessage from 'hooks/useDisplayMessage';
import User, { UserStatus } from 'models/User';
import Gravatar, { GravatarSize } from 'components/icons/Gravatar';
import { remove as removeUser, invite as inviteUser, getRole as getUserRole } from 'services/api/users';
import ExpandableList from 'components/lists/ExpandableList';
import { UserHasPermission } from 'components/security/UserHasPermission';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import { TableCellLoader } from 'components/loaders/Loader';

const logger = Logger.get('SettingsPeopleRow');

const UserRow: FC<{
    user: User;
    onDeleted?: (users: User[]) => void;
    isSelected: boolean;
    onSelectUser: (user: User, selected: boolean) => void;
    usingSSO: boolean;
    loadingTeams: boolean;
}> = ({ user, onDeleted, isSelected, onSelectUser, usingSSO, loadingTeams }) => {
    const [state, setState] = useState({
        isLoading: false,
        isInviting: false,
        isReinvited: false,
    });

    const { success, error } = useDisplayMessage();

    const { openModal } = useFinalActionModal();

    const setLoading = (isLoading = true) => {
        setState(currentState => ({ ...currentState, isLoading }));
    };

    const handleDeleteUser = async (selectedUser: User) => {
        setLoading();

        try {
            await removeUser(selectedUser);
            success(`User ${selectedUser.name} successfully removed from the organization`);
            if (onDeleted) {
                onDeleted([selectedUser]);
            }
        } catch (e) {
            logger.error(`Error trying to delete user ${selectedUser.id}`);
            error(e instanceof Error ? e.message : 'Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    const reinviteUser = async (selectedUser: User) => {
        setState(currentState => ({ ...currentState, ...{ isInviting: true } }));
        try {
            await inviteUser(selectedUser);
            setState(currentState => ({ ...currentState, ...{ isReinvited: true } }));
        } catch (e) {
            error('There was an error sending the invitation. Please try again later');
        } finally {
            setState(currentState => ({ ...currentState, ...{ isInviting: false } }));
        }
    };

    return (
        <tr key={user.id} data-testid="people-row">
            <td className="line-height-120">
                <div className="inline-flex">
                    <label>
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                onSelectUser(user, e.target.checked);
                            }}
                        />
                        <span></span>
                    </label>
                    <Gravatar email={user.email} name={user.name} className="mr3" size={GravatarSize.SMALL}></Gravatar>
                    <div className="nowrap">{user.name}</div>
                </div>
            </td>
            <td>
                <a href={`mailto:${user.email}`}>{user.email}</a>
            </td>
            <td className="invite">
                {!state.isReinvited ? getUserRole(user) : 'Just invited'}
                {user.status === UserStatus.INVITED && !state.isReinvited && !state.isInviting && !usingSSO ? (
                    <div className="mainColor cursor-hand" onClick={() => reinviteUser(user)}>
                        Resend email
                    </div>
                ) : null}

                {state.isInviting && <Bouncer></Bouncer>}
            </td>
            <td>
                {!loadingTeams ? (
                    <ExpandableList items={user.teams.map(team => team.name)}></ExpandableList>
                ) : (
                    <TableCellLoader />
                )}
            </td>
            <td className="center" data-testid={`remove-user-${user.id}`}>
                <UserHasPermission to="removeUsers">
                    {state.isLoading ? (
                        <Bouncer />
                    ) : (
                        <Icon
                            icon="trash"
                            className="grey3 fz27 hoverable"
                            onClick={() =>
                                openModal({
                                    onSubmit: () => handleDeleteUser(user),
                                    confirmMsg: `Are you sure you want to delete user ${user.name}?`,
                                    buttonText: 'Yes, delete this user.',
                                    title: 'Delete user?',
                                })
                            }
                        />
                    )}
                </UserHasPermission>
            </td>
        </tr>
    );
};

export default UserRow;
