import Env from 'models/Env';
import { userIsAllowedTo } from 'services/security/accessControl';

export interface SettingsNavItemInterface {
    title: string;
    link: string;
    icon: string;
}

interface HideableSettingsNavItemInterface extends SettingsNavItemInterface {
    hidden?: boolean;
}

export interface SettingsNavInterface {
    header: string;
    items: SettingsNavItemInterface[];
}

export class SettingsNavItems {
    items: SettingsNavInterface[];
    constructor(env?: Env) {
        this.items = [];

        this.registerUserSettings();

        if (env) {
            this.registerEnvironmentSettings(env);
        }

        this.registerOrgSettings();
    }

    getItems() {
        return this.items;
    }

    private registerSettings(header: string, items: SettingsNavItemInterface[]) {
        if (!items.length) {
            return;
        }

        this.items.push({ header, items });
    }

    private registerUserSettings() {
        this.registerSettings('User Settings', [
            {
                title: 'Profile',
                link: '/settings/profile',
                icon: 'account_circle',
            },
            {
                title: 'Email Preferences',
                link: '/settings/email-preferences',
                icon: 'email',
            },
        ]);
    }

    private registerEnvironmentSettings(env: Env) {
        const envName = env.name;
        const envUrl = env.url;
        const envSettings: HideableSettingsNavItemInterface[] = [
            {
                title: 'Credentials',
                link: `${envUrl}/settings/credentials`,
                icon: 'credentials',
                hidden: !userIsAllowedTo('writeCredentials'),
            },
            {
                title: 'Integrations',
                link: `${envUrl}/settings/integrations`,
                icon: 'extension',
                hidden: !userIsAllowedTo('accessEnvSettings'),
            },
            {
                title: 'Teams',
                link: `${envUrl}/settings/teams`,
                icon: 'people',
                hidden: !userIsAllowedTo('listEnvUsers'),
            },
            {
                title: 'API Tokens',
                link: `${envUrl}/settings/api-tokens`,
                icon: 'verified_user',
                hidden: !userIsAllowedTo('changeEnvSettings'),
            },
            {
                title: 'Query Data',
                link: `${envUrl}/settings/query-data`,
                icon: 'queries',
                hidden: !userIsAllowedTo('changeEnvSettings'),
            },
            {
                title: 'Anomaly Detection',
                link: `${envUrl}/settings/anomaly-detection`,
                icon: 'anomaly',
                hidden: !userIsAllowedTo('accessEnvSettings'),
            },
            {
                title: 'Preferences',
                link: `${envUrl}/settings/preferences`,
                icon: 'settings',
                hidden: !userIsAllowedTo('changeEnvSettings'),
            },
        ];

        this.registerSettings(
            `Environment Settings for ${envName}`,
            envSettings.filter(setting => !setting.hidden)
        );
    }

    private registerOrgSettings() {
        const orgSettings: HideableSettingsNavItemInterface[] = [
            {
                title: 'Overview',
                link: '/settings/overview',
                icon: 'business',
            },
            {
                title: 'Authentication',
                link: '/settings/authentication',
                icon: 'vpn_key',
                hidden: !userIsAllowedTo('setupAppAuth'),
            },
            {
                title: 'Billing',
                link: '/settings/billing',
                icon: 'credit_card',
                hidden: !userIsAllowedTo('setLicenses'),
            },
            {
                title: 'Licenses',
                link: '/settings/licenses',
                icon: 'licenses',
                hidden: !userIsAllowedTo('setLicenses'),
            },
            {
                title: 'People',
                link: '/settings/people',
                icon: 'person',
                hidden: !userIsAllowedTo('listOrgUsers'),
            },
            {
                title: 'Teams',
                link: '/settings/teams',
                icon: 'people',
                hidden: !userIsAllowedTo('listOrgUsers'),
            },
        ];

        this.registerSettings(
            'Organization Settings',
            orgSettings.filter(setting => !setting.hidden)
        );
    }
}
