import EditableInput from 'components/editable/EditableInput';
import Icon from 'components/icons/Icon';
import Group from 'models/hosts/NodeGroup';
import * as React from 'react';
import useHostAttributeUpdate from 'hooks/hosts/useHostAttributeUpdate';

const NodeGroupRow: React.FC<{
    group: Group;
    onToggle?: (group: Group) => void;
    isCollapsed: boolean;
    className?: string;
}> = ({ group, onToggle = () => {}, isCollapsed, className = '' }) => {
    const updateHostName = useHostAttributeUpdate('name');

    const handleHostNameChange = async (newName: string) => {
        updateHostName(group.host, newName);
    };

    return (
        <tr className={className} data-testid="node-group">
            <td
                className={`centered toggler toggler--root ${(!isCollapsed && 'toggler--expanded') || ''}`}
                data-testid="node-group-row-toggler"
                onClick={() => {
                    onToggle(group);
                }}
            >
                <div className="inline-flex items-center justify-center">
                    <Icon
                        icon="keyboard_arrow_right"
                        className={`h2 mainColor cursor-hand relative ${
                            (!isCollapsed && 'machines--hidden') || 'machines--shown'
                        }`}
                    />
                </div>
            </td>

            <td className="vc-table--machines__host">
                <Icon className="grey3" icon={group.groups.length > 0 ? 'nodes' : 'nodeView'} />
            </td>
            <td className="vc-table--machines__host-name">
                <span className="dark h4" title={group.host.name}>
                    <EditableInput value={group.host.name} onChange={handleHostNameChange} />
                </span>
            </td>
            <td colSpan={6}></td>
        </tr>
    );
};

export default NodeGroupRow;
