import { FieldRenderProps, Field } from 'react-final-form';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import CheckboxField from 'components/form/fields/CheckboxField';
import TextField from 'components/form/fields/TextField';
import { useState, FC, useEffect } from 'react';
import RedisCredentialModel, { RedisCredentialDataInterface } from 'models/credentials/RedisCredential';
import Host from 'models/hosts/Host';
import {
    get as getCredentials,
    getForHost as getCredentialsForHost,
    CredentialHostType,
} from 'services/api/credentials';
import Bouncer from 'components/icons/Bouncer';

const RedisCredentialsFields: FC<{
    host?: Host;
    onLoad?: (credentials: RedisCredentialModel) => void;
    values?: RedisCredentialModel;
    allowPasswordChange?: boolean;
}> = ({ host, onLoad = () => {}, values, allowPasswordChange = true }) => {
    const [loading, setLoading] = useState(true);

    const fetchCredentials = async () => {
        const result = host
            ? await getCredentialsForHost<RedisCredentialDataInterface>(host)
            : await getCredentials<RedisCredentialDataInterface>(CredentialHostType.redis);

        setLoading(false);

        if (result === undefined) {
            onLoad(new RedisCredentialModel({ host: '', port: '', pass: '' }));
        } else {
            const credentials = new RedisCredentialModel(result);
            credentials.isEmpty = false;
            onLoad(credentials);
        }
    };

    useEffect(() => {
        fetchCredentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host]);

    return (
        (!loading && values && (
            <>
                <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Address">
                    <Field name="address" required placeholder="Usually localhost:6379">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <TextField {...props} helpText="Examples: localhost:6379, 10.0.10.1:6379, [::1]:6379" />
                        )}
                    </Field>
                </FormGroup>

                <FormGroup
                    className="mt3 field-inline-labels field-inline-labels--tooltip"
                    label="Password"
                    variant={FormGroupVariantType.HORIZONTAL}
                >
                    <Field name="password" type="password" placeholder="Connection's password">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <TextField
                                {...props}
                                type="password"
                                disabled={!values.changePasswordAllowed}
                                tooltip="For security purposes, we do not populate passwords in this field.
                        If you wish to update the password, please check the box and enter a
                        new password in the input."
                            />
                        )}
                    </Field>
                </FormGroup>

                <FormGroup className="mt3" variant={FormGroupVariantType.HORIZONTAL} label=" ">
                    <Field type="checkbox" name="changePasswordAllowed">
                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                            <CheckboxField
                                {...props}
                                label="I want to change the password"
                                id="changePasswordAllowed"
                                data-testid="password-checkbox"
                            />
                        )}
                    </Field>
                </FormGroup>
            </>
        )) || <Bouncer className="my4 flex full-width justify-center" />
    );
};

export default RedisCredentialsFields;
