import Icon from 'components/icons/Icon';
import { FC, SyntheticEvent } from 'react';

const EnvironmentFilter: FC<{ onChange: (value: string) => void; name: string }> = ({ onChange, name }) => {
    const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    };

    return (
        <label className="nowrap relative view--home__env-list__filter">
            <input
                type="text"
                data-testid="filter-env"
                placeholder="Filter list (Enter keyword or environment)"
                className="view--home__filter mr2 bg-white"
                value={name}
                onChange={handleChange}
            />
            {name && (
                <Icon
                    icon="cancel"
                    className="view--home__env-list__filter__icon absolute top-1 right-3 cursor-hand grey3 h2 flex items-center hoverable"
                    onClick={() => onChange('')}
                />
            )}
        </label>
    );
};

export default EnvironmentFilter;
