import { useEffect, useState, FC } from 'react';
import Dropdown from 'components/dropdowns/Dropdown';
import Icon from 'components/icons/Icon';
import { loadPostByUrl } from 'services/documentation';
import TopicsBrowser from './TopicsBrowser';
import Changelog from './Changelog';
import Topic from './Topic';
import Post from './Post';
import { IdleState, MainState, PostState, TopicState, useDocumentationState } from './state';
import Breadcrumbs from './Breadcrumbs';
import { useDocumentationContext } from 'components/context/Documentation';
import useScrollableRef from 'hooks/useScrollableRef';

const Documentation: FC = () => {
    const { url } = useDocumentationContext();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const { ref, onScroll, scrollToAnchor } = useScrollableRef<HTMLDivElement>(null);
    const { state, idle, goToMain, activateTopic, activatePost } = useDocumentationState();

    function onDropdownToggle(expanded: boolean) {
        if (expanded !== isExpanded) {
            setIsExpanded(expanded);
        }

        if (expanded && state instanceof IdleState) {
            goToMain();
        }

        if (!expanded && !(state instanceof IdleState)) {
            idle();
        }
    }

    useEffect(() => {
        setIsExpanded(!!url.url);

        if (!url.url) {
            return;
        }

        loadPostByUrl(url.url).then(post => activatePost(post));
        // Ignore navigator, as it comes from a reducer
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return (
        <div className="documentation" data-testid="documentation-widget">
            <Dropdown
                icon="help_round"
                className={`header__tool__dropdown--changelog header__tool__dropdown ${
                    isExpanded ? 'header__tool__dropdown--open' : ''
                }`}
                direction="right"
                isExpanded={isExpanded}
                onToggle={onDropdownToggle}
                outsideClickStopsPropagation={
                    /* Prevent contextual help from opening in a new tab if the dropdown is opened */
                    false
                }
            >
                <div className="header__tool__dropdown--changelog__content header__tool__dropdown--changelog__content--documentation">
                    <div className="pt3 px3 pb2 border-bottom border-color-grey1 flex justify-between items-center">
                        <h3 className="bold fz16">Documentation</h3>
                        <a
                            className="fz16 flex items-center"
                            href="https://customerportal.solarwinds.com/support/submit-a-ticket/"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <Icon className="fz16 grey3 inline-flex mr2" icon="open-in-new" />
                            Customer Portal
                        </a>
                    </div>
                    <div
                        className="documentation-main overflow-auto"
                        data-testid="documentation-widget-content"
                        onScroll={() => {
                            /* istanbul ignore next */
                            onScroll();
                        }}
                        ref={ref}
                    >
                        {!(state instanceof MainState) && (
                            <Breadcrumbs
                                state={state}
                                onHomeClick={() => goToMain()}
                                onTopicClick={topic => activateTopic(topic)}
                            />
                        )}

                        {state instanceof TopicState && (
                            <Topic topic={state.topic} onClick={post => activatePost(post)} />
                        )}

                        {state instanceof PostState && (
                            <Post
                                post={state.post}
                                onContentNavigation={anchor => {
                                    /* istanbul ignore next */
                                    scrollToAnchor(anchor);
                                }}
                            />
                        )}

                        {state instanceof MainState && (
                            <>
                                <TopicsBrowser onClick={topic => activateTopic(topic)} />
                                <Changelog />
                            </>
                        )}
                    </div>
                </div>
            </Dropdown>
        </div>
    );
};

export default Documentation;
