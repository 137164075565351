import App from './App';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { createErrorBoundary } from 'services/error/reporter';
import axios from 'axios';

//polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import * as serviceWorker from './serviceWorker';

axios.interceptors.request.use(function (config) {
    if (['/api/v2/', '/xhr/'].some(pattern => config.url?.includes(pattern))) {
        config.headers['X-DPM-App'] = 'webapp';
    }
    return config;
});

if (process.env.REACT_APP_A11Y === 'true') {
    // Putting the require inside this conditional removes ~400Kb of code from the final bundle
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const axe = require('react-axe');

    axe(React, ReactDOM, 1000);
}

const ErrorBoundary = createErrorBoundary(React);

ReactDOM.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
//
// Initially disabling the service worker because it doesn't look like it applies to us. The code
// is not removed to give it a chance for consideration in the future, after production testing.
