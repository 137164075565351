import { useEffect, FC, useState } from 'react';
import Bouncer from 'components/icons/Bouncer';
import Icon from 'components/icons/Icon';
import loadScript from 'helpers/loadScript';
import Logger from 'services/logger';
import { get as getContext } from 'services/context';

import {
    init as initSalesforceChat,
    getSalesforceChatConfiguration,
    show,
    isReady,
} from 'services/analytics/salesforceChat';

const SalesforceChat: FC = () => {
    const bootstrap = getContext();
    const configuration = getSalesforceChatConfiguration(bootstrap);
    const [chatEnabled, setChatEnabled] = useState(false);
    const [chatInitialized, setChatInitialized] = useState(false);
    const [messageOpen, setMessageOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        if (!bootstrap.user) {
            return;
        }

        if (bootstrap.isBrainiac() && params.get('forceAnalytics')) {
            Logger.get('SalesforceChat').warn('skipped because the user is a brainiac');
            return;
        }

        if (bootstrap.impersonating) {
            Logger.get('SalesforceChat').warn('skipped because the user is impersonated');
            return;
        }

        if (!chatInitialized) {
            loadScript(configuration.scriptUrl, () => {
                initSalesforceChat(configuration);
                setChatInitialized(true);
            });
        }
    }, [bootstrap, configuration, chatInitialized]);

    useEffect(() => {
        setTimeout(() => {
            setChatEnabled(isReady());
        }, 3e3);

        const interval = setInterval(() => {
            setChatEnabled(isReady());
        }, 60e3);

        return () => clearInterval(interval);
    }, []);

    const clickOnHelpButton = () => {
        if (chatEnabled) {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 10e3);
            show();
        } else {
            toggleMessage();
        }
    };

    const toggleMessage = () => {
        setMessageOpen(!messageOpen);
    };
    return (
        <>
            {bootstrap.user && (
                <div className="absolute fixed bottom-3 mb2 mr2 right-3 z4">
                    {isLoading ? (
                        <Bouncer className="circle salesforcechat-bouncer m0 white bg-swiOrange" />
                    ) : (
                        <Icon
                            icon="question-answer"
                            className={`salesforcechat-icon ${
                                chatEnabled ? 'salesforcechat-icon--online' : 'salesforcechat-icon--offline'
                            }`}
                            onClick={clickOnHelpButton}
                        />
                    )}
                </div>
            )}
            {messageOpen && (
                <div className="absolute fixed right-3 mr2 z4 salesforcechat-message">
                    <Icon icon="close" className="absolute top-2 right-2 grey2 fz18" onClick={toggleMessage} />
                    <div className="white fz18 h3 pb2">We are currently offline.</div>
                    <span className="grey2 fz12">
                        We’d love to hear from you! please feel free to&nbsp;
                        <a
                            className="contact-us__link swiOrange"
                            title="Send us a direct message"
                            href={`mailto:${bootstrap.supportEmail}?subject=support request from DPM`}
                        >
                            send us an email
                        </a>
                    </span>
                </div>
            )}
        </>
    );
};

export default SalesforceChat;
