import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';

function useCommandBarToggler() {
    const {
        enable: enableHostsSelector,
        disable: disableHostsSelector,
        readOnly: hostsFilterIsReadOnly,
    } = useHostFilter();
    const { enable: enableTimePicker, disable: disableTimePicker, readOnly: timePickerIsReadOnly } = useTimeInterval();

    const disable = () => {
        disableHostsSelector();
        disableTimePicker();
    };
    const enable = () => {
        enableHostsSelector();
        enableTimePicker();
    };

    return { enable, disable, enabled: !hostsFilterIsReadOnly && !timePickerIsReadOnly };
}

export default useCommandBarToggler;
