import * as React from 'react';
import Icon from 'components/icons/Icon';
import { useTimeInterval } from 'components/context/TimeInterval';

const AutoRefresh: React.FC = () => {
    const { toggleAutoRefresh, autoRefreshEnabled } = useTimeInterval();

    const onClick = () => {
        toggleAutoRefresh();
    };

    return (
        <div className="relative header__tool__dropdown auto-refresh__content mr2" onClick={onClick}>
            <Icon icon="refresh" className={`h2 ${autoRefreshEnabled ? 'swiOrange auto-refresh--enabled' : 'white'}`} />
            <Icon icon="clock" className={`fz18 absolute ${autoRefreshEnabled ? 'swiOrange' : 'white'}`} />
        </div>
    );
};

export default AutoRefresh;
