import { FC, useState, useEffect } from 'react';

interface ExpandableListProps {
    items: string[];
    limit?: number;
}

const ExpandableList: FC<ExpandableListProps> = ({ items = [], limit = 3 }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [shownItems, setShownItems] = useState<string[]>([]);
    const [hiddenItems, setHiddenItems] = useState<string[]>([]);

    const handleExpand = () => {
        setExpanded(true);
        setShownItems(items);
        setHiddenItems([]);
    };

    const handleCollapse = () => {
        setExpanded(false);
        setShownItems(items.slice(0, limit));
        setHiddenItems(items.slice(limit, items.length));
    };

    useEffect(() => {
        setShownItems(items.slice(0, limit));
        setHiddenItems(items.slice(limit, items.length));
        if (expanded) {
            handleExpand();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, limit]);

    return (
        <div className="expandable-list inline">
            <div className="expandable-list__items inline">
                {shownItems.map((item, index) => (
                    <span key={index}>
                        <strong data-testid="expandable-list-item">{item}</strong>
                        {index !== shownItems.length - 1 ? (index === shownItems.length - 2 ? ' and ' : ', ') : null}
                    </span>
                ))}

                <div className="nunito spacing-normal inline">
                    {expanded && (
                        <span
                            className="mainColor cursor-hand ml2"
                            data-testid="collapse-button"
                            onClick={() => handleCollapse()}
                        >
                            show less
                        </span>
                    )}

                    {hiddenItems.length > 0 && !expanded && (
                        <span
                            className="mainColor cursor-hand ml2"
                            data-testid="expand-button"
                            onClick={() => handleExpand()}
                        >
                            (+{hiddenItems.length} more)
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExpandableList;
