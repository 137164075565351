import { useState, useEffect } from 'react';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import SlideSwitch from 'components/form/fields/SlideSwitchField';
import TextField from 'components/form/fields/TextField';
import { Form } from 'react-final-form';
import BouncerButton from 'components/form/BouncerButton';
import Bouncer from 'components/icons/Bouncer';
import useDisplayMessage from 'hooks/useDisplayMessage';
import FormActions from 'components/form/Actions';
import {
    get as getAgentConfig,
    AgentConfigDataInterface,
    save as saveAgentConfig,
    AgentConfigSavingInterface,
} from 'services/api/agentConfig';
import FormField from 'components/form/Field';

enum EventProperties {
    LONG_QUERY_ENABLED = 'enable-long-query',
    LONG_QUERY_THRESHOLD = 'long-running-event-threshold',
    PG_VACUUM_EVENTS = 'pg-vacuum-events',
    PG_VACUUM_WARNING = 'generate-warning-at',
    PG_VACUUM_CRITICAL = 'generate-critical-at',
}

const getProperties = (data: AgentConfigDataInterface) => {
    const longRunningQueryThreshold = data[EventProperties.LONG_QUERY_THRESHOLD];
    const pgVacuumEvents = data[EventProperties.PG_VACUUM_EVENTS];
    const pgVacuumWarning = pgVacuumEvents?.match(/warn:(.*)[a-z],/);
    const pgVacuumCritical = pgVacuumEvents?.match(/crit:(.*)[a-z]/);

    const response = {
        [EventProperties.LONG_QUERY_ENABLED]: !!longRunningQueryThreshold,
        [EventProperties.LONG_QUERY_THRESHOLD]: longRunningQueryThreshold?.length
            ? longRunningQueryThreshold.slice(0, -1)
            : '',
        [EventProperties.PG_VACUUM_EVENTS]: !!pgVacuumEvents,
        [EventProperties.PG_VACUUM_WARNING]: pgVacuumWarning && pgVacuumWarning[1],
        [EventProperties.PG_VACUUM_CRITICAL]: pgVacuumCritical && pgVacuumCritical[1],
    };

    return response;
};

const formatProperties = (data: AgentConfigSavingInterface) => {
    return {
        [EventProperties.LONG_QUERY_THRESHOLD]: data[EventProperties.LONG_QUERY_ENABLED]
            ? `${data[EventProperties.LONG_QUERY_THRESHOLD]}s`
            : null,
        [EventProperties.PG_VACUUM_EVENTS]: data[EventProperties.PG_VACUUM_EVENTS]
            ? `warn:${data[EventProperties.PG_VACUUM_WARNING]}s,crit:${data[EventProperties.PG_VACUUM_CRITICAL]}s`
            : null,
    };
};

const thresholdIsValid = (number: number, enabled: boolean) =>
    enabled && number <= 0 ? 'The threshold must be bigger than 0' : undefined;

const EventsSettings = () => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const [state, setState] = useState<AgentConfigSavingInterface>({});

    const { success, error } = useDisplayMessage();

    const validateFields = (values: AgentConfigSavingInterface) => {
        if (
            values[EventProperties.PG_VACUUM_EVENTS] &&
            (!values[EventProperties.PG_VACUUM_WARNING] || !values[EventProperties.PG_VACUUM_CRITICAL])
        ) {
            error('You must set both event threshold fields if you enable Postgres vacuum events.');
            return false;
        }

        // If Long running query detection is enabled, a threshold must be entered
        if (values[EventProperties.LONG_QUERY_ENABLED] && !values[EventProperties.LONG_QUERY_THRESHOLD]) {
            error('You must a set a threshold value if you enable long running query detection.');
            return false;
        }

        return true;
    };

    const onSubmit = async (values: AgentConfigSavingInterface) => {
        const isValid = validateFields(values);

        if (!isValid) {
            return;
        }

        setSaving(true);

        const data = formatProperties(values);
        try {
            await saveAgentConfig(data as AgentConfigSavingInterface);
            success('Event settings have been updated.');
        } catch {
            error('There was an error while trying to save your settings.');
        } finally {
            setSaving(false);
        }
    };

    const fetchConfig = async () => {
        const response = await getAgentConfig();
        setState({ ...state, ...getProperties(response) });
        setLoading(false);
    };

    useEffect(() => {
        fetchConfig();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="border-top border-color-grey1 pt4 mb4">
            <div className="flex">
                <h1 className="h2 mr2 lighter grey3 flex-grow-1">Events Settings</h1>
            </div>

            {loading && <Bouncer />}
            {!loading && (
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        [EventProperties.LONG_QUERY_ENABLED]: state[EventProperties.LONG_QUERY_ENABLED],
                        [EventProperties.LONG_QUERY_THRESHOLD]: state[EventProperties.LONG_QUERY_THRESHOLD] || '30',
                        [EventProperties.PG_VACUUM_EVENTS]: state[EventProperties.PG_VACUUM_EVENTS],
                        [EventProperties.PG_VACUUM_WARNING]: state[EventProperties.PG_VACUUM_WARNING],
                        [EventProperties.PG_VACUUM_CRITICAL]: state[EventProperties.PG_VACUUM_CRITICAL],
                    }}
                    render={({ pristine, invalid, handleSubmit, values }) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                                className="settings__form settings__form--event-settings mt3"
                                name="queryDataForm"
                            >
                                <FormField
                                    render={SlideSwitch}
                                    variant={FormGroupVariantType.HORIZONTAL}
                                    className="mt3 field-inline-labels flex"
                                    label="Enable long running query detection:"
                                    name={EventProperties.LONG_QUERY_ENABLED}
                                    checked={state[EventProperties.LONG_QUERY_ENABLED]}
                                    helpText="When on, VC automatically generates events when long running query is detected and you can set up an alert for these events."
                                    data-testid="enable-long-query-switch"
                                />

                                <FormField
                                    render={TextField}
                                    className="mt3 field-inline-labels field-inline-labels--append-only"
                                    variant={FormGroupVariantType.HORIZONTAL}
                                    label="Long running query threshold:"
                                    name={EventProperties.LONG_QUERY_THRESHOLD}
                                    fieldClassName="settings__form__input settings__form__input--event-settings-small"
                                    type="number"
                                    validate={(value: number) =>
                                        thresholdIsValid(value, !!values[EventProperties.LONG_QUERY_ENABLED])
                                    }
                                    disabled={!values[EventProperties.LONG_QUERY_ENABLED]}
                                    append="seconds"
                                    data-testid="long-running-event-threshold-input"
                                    htmlField
                                />

                                <FormField
                                    render={SlideSwitch}
                                    variant={FormGroupVariantType.HORIZONTAL}
                                    className="mt3 field-inline-labels"
                                    label="Postgres vacuum events:"
                                    name={EventProperties.PG_VACUUM_EVENTS}
                                    checked={state[EventProperties.PG_VACUUM_EVENTS]}
                                    helpText="Generates a Database Performance Monitor event when an autovacuum takes longer than the warning and critical thresholds."
                                    data-testid="postgres-vacuum-events-switch"
                                />

                                <FormGroup
                                    className="mt3 field-inline-labels field-inline-labels--monospace"
                                    variant={FormGroupVariantType.HORIZONTAL}
                                    label="Vacuum postgres event thresholds:"
                                >
                                    <FormField
                                        render={TextField}
                                        name={EventProperties.PG_VACUUM_WARNING}
                                        type="number"
                                        fieldClassName="settings__form__input settings__form__input--event-settings-small"
                                        validate={(value: number) =>
                                            thresholdIsValid(value, !!values[EventProperties.PG_VACUUM_EVENTS])
                                        }
                                        disabled={!values[EventProperties.PG_VACUUM_EVENTS]}
                                        prepend="Generate Warning at"
                                        append="seconds"
                                        data-testid="generate-warning-at-input"
                                        htmlField
                                    />

                                    <FormField
                                        render={TextField}
                                        name={EventProperties.PG_VACUUM_CRITICAL}
                                        type="number"
                                        fieldClassName="settings__form__input settings__form__input--event-settings-small"
                                        validate={(value: number) =>
                                            thresholdIsValid(value, !!values[EventProperties.PG_VACUUM_EVENTS])
                                        }
                                        disabled={!values[EventProperties.PG_VACUUM_EVENTS]}
                                        prepend="Generate Critical at"
                                        append="seconds"
                                        data-testid="generate-critical-at-input"
                                        htmlField
                                    />
                                </FormGroup>

                                <FormActions className="mt3">
                                    <BouncerButton
                                        bounce={loading || saving}
                                        onClick={handleSubmit}
                                        disabled={pristine || invalid || loading || saving}
                                        className="primary-orange"
                                    >
                                        Update
                                    </BouncerButton>
                                </FormActions>
                            </form>
                        );
                    }}
                />
            )}
        </div>
    );
};
export default EventsSettings;
