import TimeInterval from 'models/TimeInterval';
import { ProfilerInterface } from 'models/profiler';
import ColumnDataFetcher from './columnData';
import buildRequest from './requestBuilder';

export interface ResultElementInterface {
    rank: number;
    metric: string;
    description: string;
}

type ResponseDataElement = { rank: number } & Record<string, { description: string; metric: string }>;

export interface ProfilerDataInterface {
    requestID: string;
    totals: { registered: number; active: number; matching: number; returned: number };
    elements: ResponseDataElement[];
}

interface RankProfilerResultInterface {
    elements: ResultElementInterface[];
    fetchProfilerColumnData(): ReturnType<typeof ColumnDataFetcher.prototype.fetchProfilerData>;
}

export default async function rankProfiler<T extends ProfilerInterface>(
    profiler: T,
    interval: TimeInterval,
    hostFilter: string,
    sampleSize: number
): Promise<RankProfilerResultInterface> {
    const request = buildRequest<ProfilerDataInterface>(profiler, interval, hostFilter, {
        sampleSize,
        includeRestOfData: true,
    });

    const {
        data: { elements: resultElements, requestID },
    } = await request.request();

    const elements = resultElements.map(element => {
        const { description, metric } = element[profiler.rankBy];

        return {
            rank: element.rank,
            description,
            metric,
        };
    });

    const columnDataFetcher = new ColumnDataFetcher(profiler, interval, hostFilter, sampleSize, requestID);
    const fetchProfilerColumnData = () => columnDataFetcher.fetchProfilerData();

    return { elements, fetchProfilerColumnData };
}
