import { ProfilerInterface } from 'models/profiler';
import { ColumnInterface } from 'models/profiler/columns';
import TimeInterval from 'models/TimeInterval';
import APIRequest from '../Request';

interface RequestOptionsInterface {
    sampleSize: number;
    includeRestOfData?: boolean;
    requestID?: string;
}

export default function buildProfilerRequest<ProfilerResponseType>(
    profiler: ProfilerInterface,
    timeInterval: TimeInterval,
    hostFilter: string,
    options: RequestOptionsInterface,
    column?: ColumnInterface
): APIRequest<ProfilerResponseType> {
    const params: Record<string, string> = {
        ...timeInterval.asParams(),
        host: hostFilter,
        samplesize: options.sampleSize.toString(10),
        limit: profiler.limit.toString(10),
        rankID: profiler.rank.id,
        rankBy: profiler.rankBy,
        includeRestOfData: options.includeRestOfData ? '1' : '0',
    };

    if (options.requestID !== undefined) {
        params.requestID = options.requestID;
    }

    const query = new URLSearchParams(params);
    const columns: Record<string, string[]> = {};

    if (column) {
        columns[column.id] = [column.summarizeAs].concat('main' === column.renderAs ? ['series'] : []);
    } else {
        columns[profiler.rankBy] = [];
    }

    return new APIRequest<ProfilerResponseType>({
        method: 'post',
        path: `metrics/profiler?${query.toString()}`,
        params: { columns },
    });
}
