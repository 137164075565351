import APIRequest, { TargetEnv } from './Request';
import Logger from 'services/logger';
import APIToken, {
    APITokenDataInterface,
    APIRotationTokenInterface,
    APIDeprecatedTokenInterface,
} from 'models/APIToken';
import Role from 'models/Role';
import InstallAPIToken, { InstallAPITokenDataInterface } from 'models/InstallAPIToken';

const logger = Logger.get('APITokens');

function mapArrayDataToModel(data: APITokenDataInterface[]) {
    return data.map(token => new APIToken(token));
}

export async function getEnvTokens() {
    logger.info('fetching env tokens');

    const response = await new APIRequest<APITokenDataInterface[] | null>({
        path: 'tokens',
        params: { tree: 'true' },
    }).request();

    // this api returns a null when there is no data
    return response.data ? mapArrayDataToModel(response.data) : [];
}

export async function createEnvToken(name: string, role: Role) {
    logger.info('creating env token');

    const response = await new APIRequest<APITokenDataInterface>({
        path: 'tokens',
        method: 'post',
        params: { name, role: { id: role.id, name: role.name } },
    }).request();

    return new APIToken(response.data);
}

export async function getOrgTokens() {
    logger.info('fetching org tokens');

    const response = await new APIRequest<APITokenDataInterface[]>({
        path: 'tokens',
        target: TargetEnv.CURRENT_ORG,
    }).request();

    return response.data ? mapArrayDataToModel(response.data) : [];
}

/**
 * Tokens whose type is "api"
 */
export async function getOrgAPITokens() {
    logger.info('fetching org tokens');

    const tokens = await getOrgTokens();

    return tokens.filter((token: APIToken) => token.type === APIToken.TYPE_API);
}

export async function createOrgToken(name: string, role: Role) {
    logger.info('creating org token');

    const response = await new APIRequest<APITokenDataInterface>({
        path: 'tokens',
        method: 'post',
        params: { name, role: { id: role.id, name: role.name } },
        target: TargetEnv.CURRENT_ORG,
    }).request();

    return new APIToken(response.data);
}

export async function removeEnvToken(token: APIToken | APIRotationTokenInterface | APIDeprecatedTokenInterface) {
    logger.info('removing env token');
    return new APIRequest({ path: `tokens/${token.id}`, method: 'delete' }).request();
}

export async function removeOrgToken(token: APIToken) {
    logger.info('removing org token');
    return new APIRequest({ path: `tokens/${token.id}`, method: 'delete', target: TargetEnv.CURRENT_ORG }).request();
}

export async function createInstallationToken() {
    logger.info('creating an installation token');
    const response = await new APIRequest<InstallAPITokenDataInterface>({
        path: 'installtokens',
        method: 'post',
    }).request();
    return new InstallAPIToken(response.data);
}

export async function rotate(token: APIToken) {
    logger.info(`initiating rotation of token ${token.id}`);

    const response = await new APIRequest<APIRotationTokenInterface>({
        path: `tokens/${token.id}/rotation`,
        method: 'post',
    }).request();

    const newToken = response.data;

    // The hash replacement that we do a few lines below, needs to happen in the "new" token,
    // i.e. the token that replaces the one we are rotating, which is not always newToken.
    // In the case of rotating an 'agent' token, newToken is the TRT instead.
    // In the "normal" case of an type='api' token, the replacement happens on the newToken, as expected...
    let tokenIdToReplaceHash = newToken.id;

    // We are kinda cheating here, because we will attach the TRT hash to the new token, which is different
    // than then reality, but in the end this doesn't matter because the hash is only shown in the UI for a
    // few seconds and is not used later for anything else.
    if (token.type === 'agent') {
        tokenIdToReplaceHash = newToken.rotationTokenOf;
    }

    // In order to get the deprecated tokens and rotation tokens we need to re-fetch the whole collection of tokens
    // See for more information https://github.com/VividCortex/artifacts/issues/5089
    const tokens = await getEnvTokens();

    // When the new collection of tokens comes, the token that we just created does not include its hash
    // We still need to show the hash, so here we add it back.
    const tokenToReplaceHash = tokens.find(t => t.id === tokenIdToReplaceHash);
    if (tokenToReplaceHash) {
        tokenToReplaceHash.hash = newToken.hash;
    }

    return tokens;
}

export async function finalize(token: APIToken) {
    logger.info(`finalizing rotation of token ${token.id}`);

    await new APIRequest({
        path: `tokens/${token.id}/rotation`,
        method: 'delete',
    }).request();

    delete token.deprecatedTokens;
    delete token.rotationToken;

    // Token's hashes should not be shown again after used
    // In particular this causes the view to understand that this token is not new neither is being rotated
    delete token.hash;

    return token;
}
