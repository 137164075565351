import { useDocumentationContext } from 'components/context/Documentation';
import { useEffect, useRef } from 'react';

function isAnchorElement(element: HTMLElement): element is HTMLAnchorElement {
    return element.tagName.toLocaleLowerCase() === 'a';
}

function isDocsUrl(url: string): boolean {
    return url.includes('docs.vividcortex');
}

export default function useClickableDocumentationContent<T extends HTMLElement>(callback: (anchor: string) => void) {
    const ref = useRef<T>(null);
    const { displayDocumentationHelp } = useDocumentationContext();

    const onClick = (event: Event) => {
        const target: HTMLElement = event.target as HTMLElement;
        if (!isAnchorElement(target)) {
            return;
        }

        const href = target.getAttribute('href') || '';
        if ('#' === href.substr(0, 1)) {
            callback(href);
            event.preventDefault();
        }

        if (isDocsUrl(href)) {
            event.preventDefault();
            displayDocumentationHelp(href);
        }
    };

    useEffect(() => {
        const element = ref.current;
        if (!element) {
            return;
        }

        element.addEventListener('click', onClick, true);
        return () => {
            element.removeEventListener('click', onClick, true);
        };
    });

    return ref;
}
