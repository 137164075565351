import Qty from 'models/numbers/Quantity';
import { formatQuantity, format } from 'helpers/numbers';
import { useEffect, useRef } from 'react';
import TimeSeries from 'models/numbers/TimeSeries';
import { useCallback } from 'react';

interface QuantityPropsInterface {
    quantity: Qty;
    timeSeries?: TimeSeries;
}

const Quantity: React.FC<QuantityPropsInterface> = ({ quantity, timeSeries = { values: [] } }) => {
    const { value, symbol } = formatQuantity(quantity);
    const symbolText = symbol ? `<span>${symbol}</span>` : '';

    const holderRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const listener = useCallback(
        (event: Event) => {
            const holder = holderRef.current;
            if (holder) {
                const point = (event as CustomEvent).detail;
                if (point) {
                    const pointValue = format(timeSeries.values[point.index]);
                    holder.innerHTML = `${pointValue}${symbolText}`;
                } else {
                    holder.innerHTML = `${value}${symbolText}`;
                }
            }
        },
        [symbolText, timeSeries.values, value]
    );

    useEffect(() => {
        if (timeSeries?.values.length > 0) {
            document.addEventListener('chartMouseEvent', listener);
            return () => document.removeEventListener('chartMouseEvent', listener);
        }
        return () => {};
    }, [listener, timeSeries]);

    return (
        <abbr ref={holderRef}>
            {value} {symbol && <span>{symbol}</span>}
        </abbr>
    );
};

export default Quantity;
