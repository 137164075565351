import { Unit } from './Unit';

const microsecond: Unit = {
    symbol: 'µs',
    name: 'microsecond',
    dimension: 'time',
    factor: 1,
};

const millisecond: Unit = {
    symbol: 'ms',
    name: 'millisecond',
    dimension: 'time',
    factor: 1e3,
};

const second: Unit = {
    symbol: 'sec',
    name: 'second',
    dimension: 'time',
    factor: 1e6,
};

const minute: Unit = {
    symbol: 'min',
    name: 'minute',
    dimension: 'time',
    factor: second.factor * 60,
};

const hour: Unit = {
    symbol: 'hr',
    name: 'hour',
    dimension: 'time',
    factor: minute.factor * 60,
};

const day: Unit = {
    symbol: 'day',
    name: 'day',
    dimension: 'time',
    factor: hour.factor * 24,
};

const byte: Unit = {
    symbol: 'B',
    name: 'byte',
    dimension: 'binary',
    factor: 1,
};

const kilobyte: Unit = {
    symbol: 'KB',
    name: 'kilobyte',
    dimension: 'binary',
    factor: 1024,
};

const megabyte: Unit = {
    symbol: 'MB',
    name: 'megabyte',
    dimension: 'binary',
    factor: kilobyte.factor * 1024,
};

const gigabyte: Unit = {
    symbol: 'GB',
    name: 'gigabyte',
    dimension: 'binary',
    factor: megabyte.factor * 1024,
};

const terabyte: Unit = {
    symbol: 'TB',
    name: 'terabyte',
    dimension: 'binary',
    factor: gigabyte.factor * 1024,
};

const ratio: Unit = {
    symbol: '',
    name: 'ratio',
    dimension: 'ratio',
    factor: 1,
};

const percentage: Unit = {
    symbol: '%',
    name: 'percentage',
    dimension: 'ratio',
    factor: 0.01,
};

const permille: Unit = {
    symbol: '‰',
    name: 'permille',
    dimension: 'ratio',
    factor: 0.001,
};

const hertz: Unit = {
    symbol: '/sec',
    name: 'hertz',
    dimension: 'frequency',
    factor: 1,
};

const e_12: Unit = {
    symbol: 'p',
    name: 'e-12',
    dimension: 'dimensionless',
    factor: 1e-12,
};

const e_9: Unit = {
    symbol: 'n',
    name: 'e-9',
    dimension: 'dimensionless',
    factor: 1e-9,
};

const e_6: Unit = {
    symbol: 'µ',
    name: 'e-6',
    dimension: 'dimensionless',
    factor: 1e-6,
};

const e_3: Unit = {
    symbol: 'm',
    name: 'e-3',
    dimension: 'dimensionless',
    factor: 1e-3,
};

const e0: Unit = {
    symbol: '',
    name: 'e0',
    dimension: 'dimensionless',
    factor: 1,
};

const e3: Unit = {
    symbol: 'K',
    name: 'e3',
    dimension: 'dimensionless',
    factor: 1e3,
};

const e6: Unit = {
    symbol: 'M',
    name: 'e6',
    dimension: 'dimensionless',
    factor: 1e6,
};

const e9: Unit = {
    symbol: 'G',
    name: 'e9',
    dimension: 'dimensionless',
    factor: 1e9,
};

const e12: Unit = {
    symbol: 'T',
    name: 'e12',
    dimension: 'dimensionless',
    factor: 1e12,
};

const all: Unit[] = [
    microsecond,
    millisecond,
    second,
    minute,
    hour,
    day,
    byte,
    kilobyte,
    megabyte,
    gigabyte,
    terabyte,
    ratio,
    percentage,
    permille,
    hertz,
    e_12,
    e_9,
    e_6,
    e_3,
    e0,
    e3,
    e6,
    e9,
    e12,
];

export default all;
