import { get as getContext } from 'services/context';
import { OrgBootstrap } from 'models/Bootstrap';

const useOrg = () => {
    const bootstrap = getContext();

    if (!bootstrap.org) {
        throw new Error('Trying to access the org within a non-org context.');
    }

    return (bootstrap as OrgBootstrap).org;
};

export default useOrg;
