import md5 from 'blueimp-md5';
import moment from 'moment-timezone';
import { getCurrentTimestamp } from 'services/time';

export type OrgTier = 'premium' | 'enterprise' | 'trial';

export interface OrgDataInterface {
    created: number;
    delinquent?: boolean;
    id: number;
    isBase?: boolean;
    name: string;
    nickname: string;
    status: string;
    tier?: OrgTier;
    trialDuration?: number;
    user: number;
}

export default class Org {
    readonly created: number;
    readonly id: number;
    readonly name: string;
    readonly nickname: string;
    readonly status: string;
    readonly tier?: OrgTier;
    readonly featureFlagIdentifier: string;
    readonly user: number;
    readonly trialDuration: number;

    constructor(data: OrgDataInterface) {
        this.created = data.created;
        this.id = data.id;
        this.name = data.name;
        this.nickname = data.nickname;
        this.status = data.status;
        this.tier = data.tier;
        this.trialDuration = data.trialDuration || 0;
        this.user = data.user;

        this.featureFlagIdentifier = md5(this.nickname);
    }

    get remainingTrialDays() {
        const now = getCurrentTimestamp();

        const day = 86400;

        const remainingTime = this.created + this.trialDuration * day - now;

        if (remainingTime <= 0) {
            return 0;
        }

        return moment.duration(remainingTime, 'seconds').asDays();
    }

    isTrial() {
        return this.tier === 'trial';
    }

    static nameToNickname(name: string) {
        return name
            .replace(/[^\w\-\s]/g, '')
            .replace(/\s+/g, '-')
            .toLowerCase();
    }
}
