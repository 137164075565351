import * as React from 'react';

export enum FormGroupVariantType {
    DEFAULT = '',
    HORIZONTAL = 'horizontal',
}

export interface FormGroupPropsInterface {
    label?: string;
    className?: string;
    variant?: FormGroupVariantType;
    children?: React.ReactNode;
}

const FormGroup: React.FC<FormGroupPropsInterface> = ({
    label,
    className = '',
    children,
    variant = FormGroupVariantType.DEFAULT,
}) => {
    const variantClassName = variant === FormGroupVariantType.HORIZONTAL ? 'horizontal-layout' : '';

    return (
        <div className={`grid ${className} ${variantClassName}`}>
            {label && <b className="form__label fz10 uppercase flex items-center">{label}</b>}
            <div className="grid">{children}</div>
        </div>
    );
};

export default FormGroup;
