import { Unit, fromDimension as unitsFromDimension, fromName as unitFromName } from 'models/numbers/Unit';
import Quantity from 'models/numbers/Quantity';

/**
 * Returns the number of decimal digits that should be used to represent a number for better readability
 * These rules are arbitrary, but universal to our app. The idea is the smaller the number, the more decimal digits we need.
 */
function numberOfDecimals(number: number): number {
    if (number >= 1000) {
        return 0;
    }

    if (number >= 500) {
        return 1;
    }

    return 2;
}

/**
 * Returns a string representing a formatted number
 */
export function format(number: number): string {
    const abs = Math.abs(number);

    let result = abs.toFixed(numberOfDecimals(abs));

    // Remove non significant decimals
    result = Number(result).toString();

    return `${number < 0 ? '-' : ''}${result}`;
}

interface FormattedQuantityInterface {
    value: string;
    symbol: string;
}

export function formatQuantity(quantity: Quantity): FormattedQuantityInterface {
    if (0 === quantity.value) {
        return { value: '0', symbol: '' };
    }

    const normalizedQty = normalize(quantity);
    const value = format(normalizedQty.value);
    const symbol = normalizedQty.unit.symbol;

    return { value, symbol };
}

/**
 * Returns the unit that represents {quantity} with the best readability
 */
function getBestUnit(quantity: Quantity): Unit {
    if ('ratio' === quantity.unit.dimension) {
        return unitFromName('percentage');
    }

    const compatibleUnits = unitsFromDimension(quantity.unit.dimension);

    if (compatibleUnits.length < 2) {
        // Nothing to do as we don't have more units to use.
        return quantity.unit;
    }

    const numericValue = Math.abs(quantity.value * quantity.unit.factor);

    return compatibleUnits.reduce((prevUnit: Unit, unit: Unit) => {
        if (numericValue < unit.factor) {
            return prevUnit;
        }

        return unit;
    });
}

/**
 * Creates a new Quantity instance with a unit and value which are equivalent to the provided one, but best for readability.
 * For example, 1024KB is normalized to 1MB
 */
export function normalize(quantity: Quantity) {
    const numericValue = Math.abs(quantity.value * quantity.unit.factor);

    const sign = Math.sign(quantity.value);
    const unit = getBestUnit(quantity);
    const value = (sign * numericValue) / unit.factor;

    return new Quantity(value, unit);
}

/**
 * Transforms a string representing an hexadecimal value to a decimal form.
 * The reason we need this and can't rely on something more simple like parseInt(0x<${hexaNumber}>) is because in some cases
 * we need to deal with numbers that are bigger than Number.MAX_SAFE_INTEGER, for example, when working with query ids.
 * Source: https://stackoverflow.com/a/53751162
 */
export function hexaToDecimal(hexaValue: string) {
    let value = hexaValue;

    if (value.length % 2) {
        value = '0' + value;
    }

    try {
        const bn = BigInt('0x' + value);
        return bn.toString(10);
    } catch {
        return hexaValue;
    }
}
