import { CredentialHostType } from 'services/api/credentials';

export interface CredentialDataInterface {
    host: string;
    port: string;
    pass: string;
}

export default class Credential {
    address: string;
    password: string;
    cachedPassword: string;
    changePasswordAllowed: boolean;

    isEmpty = true;

    constructor(data: CredentialDataInterface) {
        this.address = (data.port ? `${data.host}:${data.port}` : data.host) || '';
        this.password = '';
        this.cachedPassword = data.pass || '';
        this.changePasswordAllowed = false;
    }

    update(values: this, allowPasswordChange?: boolean) {
        this.changePasswordAllowed = values.changePasswordAllowed;
        this.address = values.address;
        this.password = values.changePasswordAllowed ? values.password : this.cachedPassword;
    }

    asPayloadObject() {
        const [host, port] = this.address ? this.address.split(':') : ['', ''];
        return { host, port, pass: this.password };
    }
}

export abstract class HostCredential extends Credential {
    constructor(readonly type: CredentialHostType, data: CredentialDataInterface) {
        super(data);
    }
}
