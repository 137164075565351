import * as React from 'react';

interface FormActionsPropsInterface {
    className?: string;
    children?: React.ReactNode;
}

const FormActions: React.FC<FormActionsPropsInterface> = ({ className = '', children }) => (
    <div className={`form__actions ${className}`}>{children}</div>
);

export default FormActions;
