import Host from 'models/hosts/Host';
import HostAttribute from 'components/host/HostAttribute';
import HostIcon from 'components/icons/HostIcon';
import Icon from 'components/icons/Icon';
import { FC, RefObject, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useWindowDimensions } from 'hooks/useDimensions';
import { Subject, timer } from 'rxjs';
import { mapTo, debounce, skipWhile, tap } from 'rxjs/operators';
import getElementDimensions from 'helpers/dimensions';

const listItemHiddenClass = 'tag--hidden';

function removeHiddenClassFromContainerItems(container: HTMLUListElement) {
    container
        .querySelectorAll<HTMLLIElement>('[data-host]')
        .forEach(element => element.classList.remove(listItemHiddenClass));
}

interface HostTagPropsInterface {
    host: Host;
    remove: () => void;
}

const HostTag: FC<HostTagPropsInterface> = ({ host, remove }) => (
    <li
        className="host-filter__input__tag bg-dark white rounded items-center inline-flex truncate relative"
        data-host={host.name}
        data-testid="hosts-selector-pill"
    >
        <HostIcon host={host} className="flex white fz16" />
        <HostAttribute host={host} className="ml1 mr2 truncate fz12" />
        <button className="link remove absolute" type="button" onClick={() => remove()}>
            <Icon icon="close" className="flex white fz16" data-testid="hosts-selector-pill-remove" />
        </button>
    </li>
);

const HostsTagList: FC<{
    container: RefObject<HTMLUListElement>;
    expanded: boolean;
    hosts: Host[];
    remove: (arg0: Host) => void;
}> = ({ container, expanded, hosts, remove }) => {
    const subject = useMemo(() => new Subject<number>(), []);
    const [hiddenCount, setHiddenCount] = useState(0);
    const { width: windowWidth } = useWindowDimensions();
    const counterRef = useRef<HTMLLIElement>(null);

    const updateHiddenTagsIfCollapsed = useCallback(() => subject.next(0), [subject]);
    const debouncedUpdateHiddenTagsIfCollapsed = useCallback(() => subject.next(200), [subject]);

    const hideOverflowingTags = useCallback(
        ({ container: parent, counterElement }: { container: HTMLUListElement; counterElement: HTMLLIElement }) => {
            let counter = 0;
            const listItems = parent.querySelectorAll<HTMLLIElement>('[data-host]');

            // Force the display of the counter element, as it will be
            // hidden unless it is placed next to a hidden tag.
            counterElement.classList.add('forced-display');
            const { width: counterWidth } = getElementDimensions(counterElement);
            counterElement.classList.remove('forced-display');

            removeHiddenClassFromContainerItems(parent);

            listItems.forEach(element => {
                element.classList.remove(listItemHiddenClass);

                const { width: elementWidth, left: elementLeft, top: elementTop } = getElementDimensions(element);
                const { width: parentWidth } = getElementDimensions(parent);
                const { top: firstItemTop } = getElementDimensions(listItems.item(0));

                const offsetRight = parentWidth - (elementLeft + elementWidth);

                if (elementTop > firstItemTop || offsetRight < counterWidth) {
                    element.classList.add(listItemHiddenClass);
                    counter++;
                }
            });

            setHiddenCount(counter);
        },
        [setHiddenCount]
    );

    useEffect(() => {
        if (!container.current || !counterRef.current) {
            return;
        }

        const observable = subject.pipe(
            // Remove all current hidden classes if dropdown expanded
            tap(() => expanded && removeHiddenClassFromContainerItems(container.current as HTMLUListElement)),

            // Prevents hiding hosts if the dropdown is expanded
            skipWhile(() => expanded),

            // Debounce for a given time
            debounce(time => timer(time)),

            mapTo({
                container: container.current,
                counterElement: counterRef.current,
            })
        );

        const subscription = observable.subscribe(hideOverflowingTags);

        return () => subscription.unsubscribe();
    }, [container, expanded, hideOverflowingTags, subject]);

    useEffect(() => {
        updateHiddenTagsIfCollapsed();
    }, [updateHiddenTagsIfCollapsed, container, expanded, hosts]);

    useEffect(() => {
        debouncedUpdateHiddenTagsIfCollapsed();
    }, [debouncedUpdateHiddenTagsIfCollapsed, windowWidth]);

    return (
        <>
            {hosts.map((host, i) => (
                <HostTag host={host} key={i} remove={() => remove(host)} />
            ))}
            <li
                className="host-filter__input__tag host-filter__input__tag--ellipsis"
                data-testid="hosts-selector-hidden-counter"
                ref={counterRef}
            >
                +{hiddenCount}
            </li>
        </>
    );
};

export default HostsTagList;
