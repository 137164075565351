import Host from 'models/hosts/Host';
import HostIcon from 'components/icons/HostIcon';
import { FC } from 'react';

export type HostAttributeType = keyof Host | 'icon';

const HostAttribute: FC<{ attribute?: HostAttributeType; className?: string; host: Host }> = ({
    attribute = 'name',
    className = '',
    host,
}) => {
    if (attribute === 'icon') {
        return <HostIcon host={host} className={className} />;
    }

    return <span className={className}>{`${host[attribute]}`}</span>;
};

export default HostAttribute;
