import { useState, useEffect } from 'react';
import SettingsLayout from '../Layout';
import Alert, { AlertTypes } from 'components/messages/Alert';
import ContactLink from 'components/messages/ContactLink';
import Bouncer from 'components/icons/Bouncer';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import APIToken, { APIRotationTokenInterface, APIDeprecatedTokenInterface } from 'models/APIToken';
import APITokensList from './List';
import { getEnvTokens } from 'services/api/tokens';
import Logger from 'services/logger';
import { UserHasPermission } from 'components/security/UserHasPermission';
import Modal from 'components/modals/Modal';
import { get as getRoles } from 'services/api/roles';
import Role from 'models/Role';
import APITokenCreation from './Creation';

const logger = Logger.get('SettingsAPITokens');

const SettingsAPITokens = () => {
    const [tokens, setTokens] = useState<APIToken[]>([]);
    const [state, setState] = useState({
        isLoading: false,
        isEmpty: false,
        isError: false,
    });

    const [modalVisible, displayModal] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);

    const showModal = () => displayModal(true);
    const hideModal = () => displayModal(false);

    async function fetchRoles() {
        try {
            setRoles(await getRoles());
        } catch (e) {
            logger.warn('error while fetching roles');
        }
    }

    const handleTokenCreation = (token: APIToken) => {
        setTokens((currentTokens: APIToken[]) => {
            const newTokens = [...currentTokens];
            newTokens.push(token);
            return newTokens;
        });
        hideModal();
    };

    const tokenCreationButton = (
        <UserHasPermission to="createToken">
            <button className="primary-orange" onClick={showModal}>
                Create new
            </button>
        </UserHasPermission>
    );

    const handleDeletedToken = (deletedToken: APIToken | APIRotationTokenInterface | APIDeprecatedTokenInterface) => {
        setTokens((currentTokens: APIToken[]) => {
            const newTokens = [...currentTokens];

            newTokens.forEach((t, ind) => {
                // Check if removed a valid token
                if (t.id === deletedToken.id) {
                    newTokens.splice(ind, 1);

                    // Else check if it has removed a deprecated or rotation token
                } else if (t.deprecatedTokens || t.rotationToken) {
                    const index = t.deprecatedTokens?.findIndex(dt => dt.id === deletedToken.id);
                    if (index !== undefined && index !== -1) {
                        t.deprecatedTokens?.splice(index, 1);
                    } else if (t.rotationToken?.id === deletedToken.id) {
                        delete t.rotationToken;
                    }
                }

                //delete the hash if the token is not rotating
                if (!t.deprecatedTokens?.length && !t.rotationToken) {
                    delete t.hash;
                }
            });

            return newTokens;
        });
    };

    const handleDismissHash = (token: APIToken) => {
        setTokens((currentTokens: APIToken[]) => {
            const newTokens = [...currentTokens];

            newTokens.forEach(t => {
                if (t.id === token.id) {
                    delete t.hash;
                }
            });

            return newTokens;
        });
    };

    const handleTokenRotation = (tokensList: APIToken[]) => {
        setTokens(tokensList);
    };

    const handleFinalizedRotation = (token: APIToken) => {
        const newTokens = [...tokens];
        newTokens[newTokens.findIndex(t => t.id === token.id)] = token;
        setTokens(newTokens);
    };

    async function fetchTokens() {
        setState(currentState => ({ ...currentState, isLoading: true }));

        try {
            const fetchedTokens = await getEnvTokens();
            setTokens(fetchedTokens);
        } catch {
            logger.warn('error while fetching tokens');
            setState(currentState => ({ ...currentState, isError: true }));
        } finally {
            setState(currentState => ({ ...currentState, isLoading: false }));
        }
    }

    // We fetch the API tokens and roles just one time when the component is mounted.
    useEffect(() => {
        fetchTokens();
        fetchRoles();
    }, []);

    return (
        <SettingsLayout title="API Tokens management" metaTitle="API Tokens" headerContent={tokenCreationButton}>
            <>
                {tokens.length > 0 && (
                    <APITokensList
                        tokens={tokens}
                        onDeleted={handleDeletedToken}
                        onRotation={handleTokenRotation}
                        onFinalizeRotation={handleFinalizedRotation}
                        onDismissHash={handleDismissHash}
                    ></APITokensList>
                )}

                {state.isError && (
                    <div className="flex items-center">
                        <Alert type={AlertTypes.ERROR} sticky className="my3 full-width">
                            <strong>Sorry!</strong>&nbsp;There was an error trying to fetch the tokens. Try again later
                            or&nbsp;
                            <ContactLink></ContactLink>.
                        </Alert>
                    </div>
                )}

                {state.isLoading && <Bouncer>loading...</Bouncer>}

                {!state.isLoading && !state.isError && tokens.length === 0 && (
                    <EmptyState type={EmptyTypes.INFO}>
                        <p className="grey3">There are no API tokens created for this environment.</p>
                    </EmptyState>
                )}
            </>
            <Modal visible={modalVisible} title="Create Token" onClose={hideModal}>
                {roles.length && (
                    <APITokenCreation roles={roles} onCancel={hideModal} onSuccess={handleTokenCreation} />
                )}
            </Modal>
        </SettingsLayout>
    );
};

export default SettingsAPITokens;
