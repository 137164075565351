import Modal from 'components/modals/Modal';
import Agent from 'models/Agent';
import { FC, useState } from 'react';
import cn from 'helpers/classname';

const LogView: FC<{ log: string; visible: boolean; onClose: () => void; agent: Agent }> = ({
    log,
    visible,
    onClose,
    agent,
}) => {
    const [wrapped, setWrapped] = useState(false);

    return (
        <Modal title={'Log content'} visible={visible} onClose={onClose}>
            <h1 className="p3 dark h4">
                Log fetched for <strong className="monospace regular">{agent.name}</strong>
            </h1>
            <div className="pb3 px3 flex flex-column">
                <textarea
                    readOnly
                    value={log}
                    className={`agent__log__content full-width bg-white mb2 ${cn(
                        'agent__log__content--wrapped',
                        wrapped
                    )}`}
                    wrap="soft"
                ></textarea>
                <label className="mt2">
                    <input type="checkbox" id="wrap-check" checked={wrapped} onChange={() => setWrapped(!wrapped)} />
                    <span>Wrap text</span>
                </label>
            </div>

            <div className="p3 bg-white flex justify-end">
                <button className="primary-grey" onClick={onClose}>
                    Close
                </button>
            </div>
        </Modal>
    );
};

export default LogView;
