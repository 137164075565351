import { Helmet } from 'react-helmet';
import * as React from 'react';

const defaultTitle = 'Database Performance Monitor';

const MetaTags: React.FC<{ title: string }> = ({ title }) => (
    <Helmet>
        <title>{title || defaultTitle}</title>
        <meta name="author" content="SolarWinds Worldwide, LLC." />
        <meta
            name="description"
            content="Database Performance Monitor provides insights into query behavior and resource utilization so you can improve app efficiency and up-time."
        />
        <meta name="keywords" content="SolarWinds, DPM, Database Performance Monitor" />
        <meta name="google-site-verification" content="4zTxt05H17SjsEJMJhpOH8KHL67Bi4ciPzNmRcqegfY" />
    </Helmet>
);

export default MetaTags;
