import { LoginErrorCode } from 'services/api/login';
import { selectOauthEmail } from 'services/api/login';
import AuthError from 'components/modules/public/AuthError';
import Bouncer from 'components/icons/Bouncer';
import Icon from 'components/icons/Icon';
import { FC, SyntheticEvent } from 'react';
import useFormSubmit from 'hooks/useFormSubmit';

interface FormDataInterface {
    email: string;
}

interface EmailSelectionPropsInterface {
    emails: string[];
    endpoint: string;
    homepage: string;
}

const EmailSelection: FC<EmailSelectionPropsInterface> = ({ emails, endpoint, homepage }) => {
    const { error, success, submitting, onSubmit } = useFormSubmit<FormDataInterface>({
        endpoint: endpoint,
        service: selectOauthEmail,
    });

    if (success) {
        window.location.replace(homepage);

        return <div>Redirecting...</div>;
    }

    return (
        <div className="relative">
            {error && <AuthError error={LoginErrorCode.UNKNOWN} />}
            <p className="mb3 line-height-120">
                We noticed you have multiple email addresses registered in your Github account. Please, select the email
                address you would like to use to authenticate to DPM.
            </p>
            <div className="relative">
                {submitting && (
                    <div className="absolute full-width full-height z3">
                        <Bouncer className="flex full-height justify-center items-center" />
                    </div>
                )}
                {emails.map((email, i) => (
                    <div className={`flex ${submitting || success ? 'half-opacity' : ''}`} key={i}>
                        <a
                            href={endpoint}
                            className="flex items-center flex-grow-1 justify-between p2 card-block__oauth__mail rounded"
                            onClick={(e: SyntheticEvent) => {
                                e.preventDefault();
                                onSubmit({ email });
                            }}
                        >
                            {email}
                            <Icon icon="keyboard_arrow_right" className="mainColor h2" />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmailSelection;
