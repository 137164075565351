import { FC, useCallback, useState, useEffect } from 'react';
import Dropdown from 'components/dropdowns/Dropdown';
import NotificationRow, { OccurenceAndHostInterface } from './NotificationRow';
import { fetchOccurrencesForNotifications } from 'services/api/asserts';
import Logger from 'services/logger';
import { useTimeInterval } from 'components/context/TimeInterval';
import Occurrence from 'models/bestPractices/Occurrence';
import { useHostFilter } from 'components/context/HostFilter';
import { getHostClusters } from 'services/api/hosts';
import Host from 'models/hosts/Host';
import { OccurrenceHost } from 'models/bestPractices/OccurrenceTarget';
import { userIsAllowedTo } from 'services/security/accessControl';
import EditSettingsModal from 'components/modules/app/Inventory/EditSettingsModal';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { Link } from 'components/url/Link';
import ContactLink from 'components/messages/ContactLink';

const logger = Logger.get('NotificationsContext');

const Notifications: FC = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const { interval } = useTimeInterval();
    const { filter } = useHostFilter();

    const [displayMap, setDisplayMap] = useState<Array<OccurenceAndHostInterface> | null>();
    const [hostInEdit, setHostInEdit] = useState<Host | null>();
    const { success, error } = useDisplayMessage();

    useEffect(() => {
        getNotifications();
    }, []); // eslint-disable-line

    const getNotifications = () => {
        fetchOccurrencesForNotifications({ interval: interval, filterNotify: true, host: filter })
            .then((occurrences: Occurrence[]) => {
                let hostIds = '';
                const occurencesToDisplay = new Array<{ hostId: number; title: string }>();

                occurrences.forEach((occurence, idx) => {
                    const occurenceTitle = occurence.title + ' in ';

                    occurence.targets.forEach(oTarget => {
                        const occurenceHost: OccurrenceHost = oTarget as OccurrenceHost;
                        occurencesToDisplay.push({ title: occurenceTitle, hostId: occurenceHost.hostId });
                        hostIds += occurenceHost.hostId + ',';
                    });
                });

                if (occurencesToDisplay.length > 0) {
                    hostIds = hostIds.slice(0, -1);
                    getHostsLocal(hostIds, occurencesToDisplay);
                }
            })
            .catch(err => {
                logger.error('Failed to fetch Occurrences For Notifications', err);
            });
    };

    const getHostsLocal = (hostIDs: string, occurencesToDisplay: { hostId: number; title: string }[]) => {
        getHostClusters(interval, hostIDs)
            .then(hosts => {
                const hostMap: { [id: number]: Host } = hosts.hosts.reduce(
                    (res, host) => ({ ...res, [host.id]: host }),
                    {}
                );
                const occurenceDisplayMap = new Array<OccurenceAndHostInterface>();
                occurencesToDisplay.forEach(occurenceToDisplay => {
                    const host = hostMap[occurenceToDisplay.hostId];
                    if (host) {
                        occurenceDisplayMap.push({ occurence: occurenceToDisplay, host, read: false });
                    }
                });
                setDisplayMap(occurenceDisplayMap);
            })
            .catch(err => {
                setDisplayMap(null);
                error('Failed to fetch hosts for Occurrences For Notifications');
                logger.error('Failed to fetch hosts Occurrences For Notifications:', err);
            });
    };

    const onDropdownToggle = (expanded: boolean) => {
        if (expanded !== isExpanded) {
            setIsExpanded(expanded);
        }
    };

    const onUpdateOccurence = (updatedOccurenceAndHost: OccurenceAndHostInterface) => {
        const newDisplayMap = displayMap?.map(occurenceAndHost => {
            if (occurenceAndHost.occurence === updatedOccurenceAndHost.occurence) {
                return { ...occurenceAndHost, read: true };
            } else {
                return occurenceAndHost;
            }
        });
        onDropdownToggle(false);
        setDisplayMap(newDisplayMap);
        setHostInEdit(updatedOccurenceAndHost.host);
    };

    const drawNotifications = (occurenceDisplayMap: Array<OccurenceAndHostInterface>) => {
        return (
            <>
                {occurenceDisplayMap.map((occurenceAndHost: OccurenceAndHostInterface, idx) => (
                    <li key={idx}>
                        <NotificationRow occurenceAndHost={occurenceAndHost} onUpdate={onUpdateOccurence} />
                    </li>
                ))}
            </>
        );
    };

    const areAllOccurencesRead = () => {
        let allOccurencesRead = true;
        displayMap?.forEach(occurenceAndHost => {
            if (!occurenceAndHost.read) {
                allOccurencesRead = false;
            }
        });
        return allOccurencesRead;
    };

    const onUpdateError = useCallback(
        (selectedHost: Host) => {
            error(`The host ${selectedHost.name} could not be updated. Please try again later.`);
            setHostInEdit(null);
        },
        [error, setHostInEdit]
    );

    const onDeleteError = useCallback(
        (selectedHost: Host) => {
            error(`The host ${selectedHost.name} could not be deleted. Please try again later.`);
            setHostInEdit(null);
        },
        [error, setHostInEdit]
    );

    const onDeleteSuccess = useCallback(
        (selectedHost: Host) => {
            success(`The host ${selectedHost.name} has been successfully deleted`);
            setHostInEdit(null);
        },
        [success, setHostInEdit]
    );

    const onSuccess = useCallback(
        (selectedHost: Host) => {
            success(`The host ${selectedHost.name} has been successfully updated`);
            setHostInEdit(null);
        },
        [success, setHostInEdit]
    );

    const onModalClose = useCallback(() => {
        setHostInEdit(null);
    }, [setHostInEdit]);

    if (!displayMap || displayMap.length === 0) {
        return null;
    }

    return (
        <div className="relative">
            <Dropdown
                icon="bell"
                className="header__tool__dropdown header__tool__dropdown--notifications"
                direction="right"
                isExpanded={isExpanded}
                onToggle={onDropdownToggle}
            >
                <div className="header__tool__dropdown--sharer__wrapper bg-grey5" data-testid="notifications-header">
                    <p className="fz14 p3 grey-3 line-height-120 text-justify">
                        We detected some issues in your hosts. These may be causing problems with Database Performance
                        Monitor services.
                    </p>
                </div>
                <ul className="credentials-notifications__dropdown__content__list list-reset bg-grey05">
                    {drawNotifications(displayMap)}
                </ul>
                <div className="flex justify-between">
                    <Link
                        className="justify-start p3"
                        to="https://dpm.statuspage.io"
                        external={true}
                        propagateStatus={false}
                        appendEnv={false}
                    >
                        {'Get Application Status'}
                    </Link>
                    <ContactLink className="flex justify-end p3">Contact Support</ContactLink>
                </div>
            </Dropdown>
            {!areAllOccurencesRead() && (
                <div className="status-bulb error dropdown-toggle--new-items absolute pointer-events"></div>
            )}

            {userIsAllowedTo('changeEnvSettings') && hostInEdit && (
                <div title="Edit or delete this server">
                    <EditSettingsModal
                        host={hostInEdit}
                        visible={!!hostInEdit}
                        onClose={onModalClose}
                        onSuccess={onSuccess}
                        onError={onUpdateError}
                        onDeleteSuccess={onDeleteSuccess}
                        onDeleteError={onDeleteError}
                    />
                </div>
            )}
        </div>
    );
};

export default Notifications;
