import * as React from 'react';
import Popover from './Popover';

const StatusPopover: React.FC<{ status: { code: string; message: string } }> = ({ status }) => {
    return (
        <div className="flex items-center mr2 relative">
            <div className={`status-bulb ${status.code}`} data-testid="status-bulb"></div>
            {'' !== status.message && (
                <Popover icon="help" iconStyle="grey2 fz18 ml2">
                    {status.message}
                </Popover>
            )}
        </div>
    );
};

export default StatusPopover;
