import Actions from 'components/form/Actions';
import Alert, { AlertTypes } from 'components/messages/Alert';
import BouncerButton from 'components/form/BouncerButton';
import ContactLink from 'components/messages/ContactLink';
import Env from 'models/Env';
import Icon from 'components/icons/Icon';
import Input from 'components/form/fields/TextField';
import Group from 'components/form/Group';
import { FC, useState } from 'react';
import { create, rename } from 'services/api/envs';
import { Form, FieldRenderProps } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { isReserved } from 'services/routing/reservedRoutes';

const nameIsValid = (name = '') => (isReserved(name) ? 'Please enter a different name' : undefined);

enum EditEnvStatus {
    READY = 'ready',
    CREATING = 'creating',
    SUCCESS = 'success',
    DUPLICATED_ERROR = 'duplicate',
    QUOTA_REACHED_ERROR = 'quota-limit',
    ERROR = 'error',
}

interface EditEnvironmentPropsInterface {
    onClose: () => void;
    env?: Env;
    onSuccess: (name?: string) => void;
}

const EditEnvironment: FC<EditEnvironmentPropsInterface> = ({ env, onClose, onSuccess }) => {
    const [status, setStatus] = useState<EditEnvStatus>(EditEnvStatus.READY);

    const parseName = (value = '') => Env.nameToEnvName(value);

    async function handleSubmit({ name }: { name: string }) {
        setStatus(EditEnvStatus.CREATING);

        const expectedErrors: string[] = [EditEnvStatus.DUPLICATED_ERROR, EditEnvStatus.QUOTA_REACHED_ERROR];

        try {
            if (env) {
                await rename(env, name);
            } else {
                await create(name);
            }

            setStatus(EditEnvStatus.SUCCESS);

            onSuccess(name);
        } catch (e) {
            if (e instanceof Error && expectedErrors.includes(e.message)) {
                setStatus(e.message as EditEnvStatus);
                return;
            }

            setStatus(EditEnvStatus.ERROR);
        }
    }

    return (
        <>
            {status === EditEnvStatus.DUPLICATED_ERROR && (
                <Alert className="mb2 relative top-3 mx3" type={AlertTypes.WARNING} sticky>
                    You already have an environment with that name
                </Alert>
            )}

            {status === EditEnvStatus.QUOTA_REACHED_ERROR && (
                <Alert type={AlertTypes.ERROR} sticky className="my2">
                    You've reached your environments limit and are unable to add another. Please <ContactLink /> if you
                    need to increase your limit.
                </Alert>
            )}

            {status === EditEnvStatus.ERROR && (
                <Alert className="mb2 relative top-3 mx3" type={AlertTypes.ERROR} sticky>
                    An unknown error happened when trying to create this environment. Please <ContactLink /> if the
                    error persists.
                </Alert>
            )}

            <Form
                onSubmit={handleSubmit}
                initialValues={{ name: env ? env.name : '' }}
                render={({ pristine, invalid, handleSubmit: onSubmit, submitting }) => (
                    <form onSubmit={onSubmit} data-testid={env ? 'rename-env-form' : 'create-env-form'}>
                        <Group label="Environment name:" className="px3 pt2">
                            <Field
                                type="text"
                                name="name"
                                required
                                maxLength="45"
                                placeholder="Environment Name"
                                autoFocus
                                parse={parseName}
                                format={parseName}
                                validate={nameIsValid}
                                badInput="Please enter a different name"
                                tooLong="The name is too long"
                                valueMissing="The name is required"
                            >
                                {(props: FieldRenderProps<string, HTMLInputElement>) => (
                                    <Input className="full-width bg-white" {...props} data-testid="env-name-input" />
                                )}
                            </Field>
                        </Group>
                        {env && (
                            <div className="flex items-center px3 pt3" data-testid="rename-warning">
                                <Icon icon="warning" className="orange fz27 mr2" />
                                <p className="grey3">
                                    Renaming an environment will cause bookmarks or short urls to stop working.
                                </p>
                            </div>
                        )}
                        <Actions className="form__actions--dialog p3 bg-white right-align full-width mt3">
                            <button type="button" className="primary-grey mr2" onClick={() => onClose()}>
                                Cancel
                            </button>
                            <BouncerButton
                                bounce={submitting}
                                disabled={pristine || invalid || submitting}
                                type="submit"
                                className="primary-mainColor nowrap createEnvForm__submit"
                                data-testid={`env-${env ? 'rename' : 'create'}`}
                            >
                                {env ? 'Rename' : 'Create'}
                            </BouncerButton>
                        </Actions>
                    </form>
                )}
            />
        </>
    );
};

export default EditEnvironment;
