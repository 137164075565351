import Alert, { AlertTypes } from 'components/messages/Alert';
import { Field } from 'react-final-form-html5-validation';
import { requestPasswordChange } from 'services/api/login';
import { Form, FieldRenderProps } from 'react-final-form';
import BouncerButton from 'components/form/BouncerButton';
import TextField from '../TextField';
import * as React from 'react';
import useFormSubmit from 'hooks/useFormSubmit';
import { Link } from 'react-router-dom';

interface FormDataInterface {
    password: string;
}

interface PasswordResetPropsInterface {
    loginUrl: string;
    homepageUrl: string;
    endpoint: string;
}

const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;

const PasswordReset: React.FC<PasswordResetPropsInterface> = ({ loginUrl, homepageUrl, endpoint }) => {
    const { error, success, onSubmit } = useFormSubmit<FormDataInterface>({
        endpoint: endpoint,
        service: requestPasswordChange,
    });

    if (success) {
        return <PasswordResetSuccess homepageUrl={homepageUrl} />;
    }

    return (
        <>
            <Alert type={AlertTypes.ERROR} className="mb3" visible={error} sticky>
                An unknown error happened. Please try again or report this situation to our support team.
            </Alert>

            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <fieldset className="mb3">
                            <label>
                                <div className="relative my2">
                                    <Field
                                        name="password"
                                        type="password"
                                        data-testid="password-input"
                                        required
                                        minLength={passwordMinLength}
                                        autoComplete="new-password"
                                        valueMissing="Please choose your account's password"
                                        tooShort={`Your password must be at least ${passwordMinLength} characters long`}
                                    >
                                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                            <TextField
                                                {...props}
                                                icon="lock"
                                                label="Choose your new password:"
                                                className="mb3"
                                            />
                                        )}
                                    </Field>
                                </div>
                            </label>
                        </fieldset>
                        <fieldset>
                            <div className="inline-flex items-center justify-between full-width">
                                <Link to={loginUrl}>I remember now, let me try</Link>
                                <BouncerButton
                                    className="primary-mainColor"
                                    type="submit"
                                    disabled={submitting || invalid}
                                    bounce={submitting}
                                    bouncerClassName="white inline-flex ml2"
                                >
                                    Reset
                                </BouncerButton>
                            </div>
                        </fieldset>
                    </form>
                )}
            />
        </>
    );
};

const PasswordResetSuccess: React.FC<{ homepageUrl: string }> = ({ homepageUrl }) => {
    return (
        <>
            <Alert type={AlertTypes.SUCCESS} className="mb3" visible sticky>
                Your password has been changed
            </Alert>
            <div className="flex justify-end">
                {/* We need to render an HTML anchor to force the browser to make a request to the sf-app and update the logged-in status */}
                <a href={homepageUrl} className="primary-mainColor" data-testid="join-link">
                    OK, let me in
                </a>
            </div>
        </>
    );
};

export default PasswordReset;
