import { useRef, useState } from 'react';
import { get as getSteps, StepInterface } from 'helpers/timePicker/slider';

interface SliderPropsInterface {
    value: number;
    onSlide(arg0: number): void;
}

const steps = getSteps();

function stickSliderToCloserStep(value: number, attr: 'x' | 'value') {
    for (let i = 0; i < steps.length - 1; i += 1) {
        const diff = steps[i][attr] + (steps[i + 1][attr] - steps[i][attr]) / 2;

        if (value < diff && value >= steps[i][attr]) {
            return steps[i];
        } else if (value > diff && value <= steps[i + 1][attr]) {
            return steps[i + 1];
        }
    }

    return value < 0 ? steps[0] : steps[steps.length - 1];
}

const SliderTimePicker: React.FC<SliderPropsInterface> = ({ value, onSlide }) => {
    const [step, setStep] = useState<StepInterface>(stickSliderToCloserStep(value, 'value'));
    const [sliding, setSliding] = useState(false);
    const slider = useRef<HTMLDivElement>(null);

    function setPosition(e: React.MouseEvent<HTMLDivElement>) {
        if (!slider.current) {
            return;
        }

        const rect = slider.current.getBoundingClientRect();
        const delta = e.clientX - rect.left;
        const x = (delta * 100) / rect.width;

        const closerStep = stickSliderToCloserStep(x, 'x');

        setStep(closerStep);
        onSlide(closerStep.value);
    }

    function handleSlide(e: React.MouseEvent<HTMLDivElement>) {
        if (!sliding) {
            return;
        }

        setPosition(e);
    }

    return (
        <div className="slider px3" data-testid="timepicker-slider" onMouseLeave={() => setSliding(false)}>
            <div
                className="slider__range relative cursor-hand"
                onMouseDown={() => setSliding(true)}
                onMouseUp={() => setSliding(false)}
                onMouseMove={handleSlide}
                onClick={setPosition}
                ref={slider}
            >
                <div
                    className="slider__range__pointer bg-MainColor circle absolute z1"
                    style={{ left: `${step.x}%` }}
                    data-testid={`slider-${step.x}`}
                ></div>
            </div>
            <div className="slider__scale flex mb3 justify-between" data-testid="timepicker-scale">
                {steps.map((currentStep: StepInterface) => (
                    <div
                        className="slider__scale__marks__tick relative cursor-hand"
                        title={currentStep.name}
                        key={currentStep.value}
                    >
                        <div
                            onClick={() => {
                                setStep(currentStep);
                                onSlide(currentStep.value);
                            }}
                            className="slider__scale__marks__tick__label absolute nowrap grey3 fz12 monospace"
                        >
                            <div className="block bg-grey2 relative slider__scale__marks__tick__mark mb1 mt2" />
                            {currentStep.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SliderTimePicker;
