import { ChangeEvent, FC, useState, useEffect } from 'react';
import UserChecklist from './Checklist';
import User from 'models/User';
import BouncerButton from 'components/form/BouncerButton';

const UserMultiSelect: FC<{
    items: User[];
    checkedItems: User[];
    onCancel: () => void;
    onSubmit: (usersToAdd: User[], usersToDelete: User[]) => Promise<void>;
    onSuccess?: () => void;
}> = ({ items, checkedItems, onCancel, onSubmit, onSuccess }) => {
    const [showSelectedOnly, setShowSelectedOnly] = useState(true);
    const [users, setUsers] = useState<
        { user: User; initial: boolean; checked: boolean; visible: boolean; filtered: boolean }[]
    >([]);
    const [filter, setFilter] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
        const input = e.currentTarget.value.toLowerCase();
        const newUsers = users.map(item => {
            return {
                ...item,
                filtered: item.user.name.toLowerCase().includes(input),
            };
        });
        setUsers(newUsers);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const usersToDelete = users
            .filter(item => item.initial !== item.checked && !item.checked)
            .map(item => item.user);

        const usersToAdd = users.filter(item => item.initial !== item.checked && item.checked).map(item => item.user);

        try {
            await onSubmit(usersToAdd, usersToDelete);
            setIsSubmitting(false);

            if (onSuccess) {
                onSuccess();
            }
        } catch {
            setIsSubmitting(false);
        }
    };

    const onCheck = (item: { user: User; initial: boolean; checked: boolean; visible: boolean; filtered: boolean }) => {
        const newUsers = [...users];
        const index = newUsers.findIndex(u => u.user.id === item.user.id);
        newUsers.splice(index, 1, item);
        setUsers(newUsers);
    };

    const checkAllUsers = (checked: boolean) => {
        const newUsers = users.map(user => ({ ...user, checked }));
        setUsers(newUsers);
    };

    const toggleCheckbox = () => {
        const newValue = !showSelectedOnly;
        setShowSelectedOnly(newValue);

        const newUsers = users.map(item => {
            return {
                ...item,
                visible: newValue ? item.checked : true,
            };
        });

        setUsers(newUsers);
    };

    useEffect(() => {
        const anyUsersChecked = checkedItems.length > 0;
        const usersList = items.map(item => {
            const isChecked = undefined !== checkedItems.find(user => user.id === item.id);
            return {
                user: item,
                initial: isChecked,
                checked: isChecked,
                visible: showSelectedOnly && anyUsersChecked ? isChecked : true,
                filtered: true,
            };
        });

        setShowSelectedOnly(anyUsersChecked);
        setUsers(usersList);
    }, [checkedItems, items]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="bg-grey0 p2 flex items-center">
                <label className="mr4">
                    <input
                        data-testid="show-only-selected"
                        type="checkbox"
                        onChange={toggleCheckbox}
                        checked={showSelectedOnly}
                    />
                    <span>Show only selected</span>
                </label>
                <input
                    value={filter}
                    onChange={handleFilterChange}
                    className="full-width"
                    type="text"
                    placeholder="Search..."
                    data-testid="multiselect-filter"
                />
            </div>
            <UserChecklist
                items={users.filter(user => (filter !== '' ? user.visible && user.filtered : user.visible))}
                onCheck={onCheck}
            />
            <div className="px2 py3 flex justify-end items-center bg-grey0">
                <div className="flex flex-grow-1 flex-column">
                    <span className="flex-grow-1">
                        {users.filter(user => user.checked).length} out of {items.length} selected
                    </span>
                    <div className="flex mt2">
                        <span onClick={() => checkAllUsers(true)} className="link" data-testid="multiselect-select-all">
                            Select All
                        </span>
                        <span
                            onClick={() => checkAllUsers(false)}
                            className="ml3 link"
                            data-testid="multiselect-unselect-all"
                        >
                            Unselect All
                        </span>
                    </div>
                </div>
                <button className="primary-grey mr2" onClick={() => onCancel()}>
                    Cancel
                </button>
                <BouncerButton
                    onClick={handleSubmit}
                    bounce={isSubmitting}
                    disabled={isSubmitting}
                    className="primary-orange"
                    data-testid="add-new-members"
                >
                    Apply
                </BouncerButton>
            </div>
        </>
    );
};

export default UserMultiSelect;
