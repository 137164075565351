import { useEffect, FC } from 'react';
import { get as getContext } from 'services/context';
import { init as initGTM } from 'services/analytics/gtm';

const GTM: FC = () => {
    const bootstrap = getContext();

    useEffect(() => {
        initGTM(bootstrap);
    }, [bootstrap]);

    return null;
};

export default GTM;
