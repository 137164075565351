import Dropdown from 'components/dropdowns/Dropdown';
import Icon from 'components/icons/Icon';
import { FC, useState } from 'react';
import useOrg from 'hooks/useOrg';
import ContactLink from '../messages/ContactLink';

function getMessage(remainingDays: number) {
    switch (remainingDays) {
        case 0:
            return 'Your trial has expired. Your account will be deactivated.';
        case 1:
            return 'Your trial period will expire in 1 day.';
        default:
            return `Your trial period will expire in ${remainingDays} days.`;
    }
}

const TrialBox: FC = () => {
    const [open, setOpen] = useState(false);
    const org = useOrg();

    if (!org.isTrial()) {
        return null;
    }

    const handleOnToggle = (state: boolean) => {
        if (!state) {
            setOpen(false);
        }
    };

    const remainingDays = org.remainingTrialDays;
    const message = getMessage(remainingDays);

    return (
        <div
            className={`full-width navigation__group navigation__group--upgrade relative ${
                open ? ' navigation__group--upgrade--open' : ''
            }`}
        >
            <button
                className="navigation__group--upgrade__button white no-decoration flex full-width cursor-hand items-center relative z2 dropdown__label px3"
                onClick={() => setOpen(!open)}
                data-testid="upgrade-button"
            >
                <Icon
                    icon="upgrade_key"
                    className="menu__item__title__icon menu__item__title__icon--upgrade flex white circle my2 mr3 white fz12"
                />
                <Icon
                    icon="plus-sign"
                    className="absolute menu__item__title__icon menu__item__title__icon--upgrade--close h2 flex items-center justify-center white"
                />
                <span className="white uppercase navigation__group--upgrade__button__label">Trial</span>
            </button>
            <Dropdown isExpanded={open} onToggle={handleOnToggle} className="navigation__group--upgrade__dropdown">
                <div className="bg-paleDark white z1 dropdown-content border--dropdown cursor-normal rounded">
                    <div className="flex flex-column p3">
                        <h1 className="h3 line-height-120">Thank you for trying out Database Performance Monitor!</h1>
                        <p className="my3">{message}</p>
                        <div className="mt3">
                            <ContactLink />
                            &nbsp;to learn more about our pricing.
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>
    );
};

export default TrialBox;
