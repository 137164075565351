import * as React from 'react';
import Icon from 'components/icons/Icon';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import useChangelogs from 'hooks/documentation/useChangelogs';

const Changelog: React.FC = () => {
    const { state, posts } = useChangelogs();

    if (state !== 'loading' && posts.items.length === 0) {
        return null;
    }

    return (
        <div className="flex flex-column">
            <div className="changelog__dropdown__content__wrap px3 pt3 flex-grow-1 overflow-auto">
                <h3 className="regular fz16 pb2">What's new?</h3>

                {state === 'loading' && <Loader type={LoaderTypes.PARAGRAPH}></Loader>}

                {posts.items.map(post => (
                    <div key={post.title} className="changelog__item mb2" data-testid="in-app-documentation-changelog">
                        <div className="metadata-container flex justify-between">
                            <div className="date grey3 fz12 flex items-center">{post.formattedDate}</div>
                        </div>
                        <h4
                            data-testid="in-app-documentation-changelog-title"
                            className="title line-height-120 my2 fz14 bold"
                        >
                            {post.title}
                        </h4>
                        <p className="content lighter line-height-120">{post.content}</p>
                        <a
                            className="learn-more flex flex-grow-1 justify-end items-center py2 border-bottom border-color-grey1"
                            href={post.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more<Icon className="mainColor inline-flex h2" icon="keyboard_arrow_right"></Icon>
                        </a>
                    </div>
                ))}
            </div>

            {state === 'loading' && (
                <div className="m3">
                    <Loader type={LoaderTypes.PARAGRAPH}></Loader>
                </div>
            )}

            {posts.siteUrl && (
                <a
                    className="changelog__dropdown__content__view-all border-top border-color-grey1 p3 center mx3"
                    href={posts.siteUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View all change logs
                </a>
            )}
        </div>
    );
};

export default Changelog;
