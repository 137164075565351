import { Fragment, memo } from 'react';
import { EdgeInterface, NodeInterface } from './Diagram';
import {
    calculateEdgeMidpoint,
    detectArrowDirection,
    detectSimilarEdges,
    calculateArrowCorrection,
    detectSimilarLabels,
    getAngle,
} from 'components/diagram';

import type { DiagramEdge } from 'components/diagram';

export interface DiagramEdgeInterface {
    edges: ReadonlyArray<DiagramEdge<NodeInterface, EdgeInterface>>;
    isNodeSelected: boolean;
}

const DiagramEdges = memo<DiagramEdgeInterface>(({ edges }) => {
    const edgesWithPositions = edges.map(edge => {
        const { x1, x2, y1, y2 } = edge;
        const { cx, cy } = calculateEdgeMidpoint(edge);

        const arrowDirection = detectArrowDirection(edge);

        const similarEdges = detectSimilarEdges(edges, edge);
        const arrowCorrection = calculateArrowCorrection(similarEdges, edge);

        const vArrowCorrection = arrowDirection === 'V' ? arrowCorrection : 0;
        const hArrowCorrection = arrowDirection === 'H' ? arrowCorrection : 0;

        const correctedEdge = {
            ...edge,
            ...{
                x1: edge.x1 + vArrowCorrection,
                x2: edge.x2 + vArrowCorrection,
                y1: edge.y1 + hArrowCorrection,
                y2: edge.y2 + hArrowCorrection,
            },
        };

        const similarLabels = detectSimilarLabels(edges, correctedEdge);

        const angle = getAngle([x1, y1], [x2, y2]);

        const ax1 = x1 - 1.15 * Math.cos(angle) + vArrowCorrection;
        const ax2 = x2 - 1.15 * Math.cos(angle) + vArrowCorrection;

        const ay1 = y1 - 1.25 * Math.sin(angle) + hArrowCorrection;
        const ay2 = y2 - 1.25 * Math.sin(angle) + hArrowCorrection;

        return {
            element: edge,
            cx: cx + arrowCorrection,
            cy: cy + arrowCorrection,
            ax1,
            ay1,
            ax2,
            ay2,
            arrowCorrection,
            similarEdges,
            arrowDirection,
            similarLabels,
        };
    });

    return (
        <>
            {/** Draw the lines first and the circles/labels later to prevent the latter from being covered over */}
            {edgesWithPositions.map(({ element, ax1, ay1, ax2, ay2 }) => {
                return (
                    <g key={`line-${element.target.id} -> ${element.source.id}`}>
                        <title>{`${element.edge.display.label} from ${element.source.display.label} to ${element.target.display.label}`}</title>

                        <path
                            d={`M ${ax1} ${ay1} L ${ax2} ${ay2}`}
                            fill="none"
                            markerEnd={'url(#arrow-dark)'}
                            stroke={'var(--grey2)'}
                            strokeWidth={4}
                            data-testid={`${element.edge.display.label} from ${element.source.display.label} to ${element.target.display.label}`}
                        />
                    </g>
                );
            })}

            {edgesWithPositions.map(({ element, cx, cy, similarEdges, similarLabels }) => {
                const isArrowOverlapped = similarEdges.length > 1 && similarEdges.indexOf(element) > -1;
                const isLabelOverlapped = similarLabels.length > 1 && similarLabels.indexOf(element) > 0;

                const label = {
                    display:
                        element.edge.display.label.length > 4
                            ? element.edge.display.label.substr(0, 2).trim().concat('...')
                            : element.edge.display.label,
                    xOffset:
                        element.edge.display.label.length > 4 ? 10 : Math.pow(element.edge.display.label.length, 2),
                };

                return (
                    <g key={`${element.target.id} -> ${element.source.id}`}>
                        {similarLabels.length === 1 && (
                            <title>{`${element.edge.display.label} from ${element.source.display.label} to ${element.target.display.label}`}</title>
                        )}

                        {similarLabels.length > 1 && (
                            <title>
                                {similarLabels.map((labelElement, index) => (
                                    <Fragment key={`${element.target.id} -> ${element.source.id} - ${index}`}>
                                        {index !== 0 && '\n'}
                                        {`${labelElement.edge.display.label} from ${labelElement.source.display.label} to ${labelElement.target.display.label}`}
                                    </Fragment>
                                ))}
                            </title>
                        )}

                        <circle
                            cx={cx}
                            cy={cy}
                            r={isArrowOverlapped ? '15' : '20'}
                            fill={'var(--grey2)'}
                            stroke={'var(--white)'}
                            strokeWidth={isArrowOverlapped || isLabelOverlapped ? 2 : 0}
                        />
                        <text
                            textAnchor="end"
                            className="fz18 bold"
                            fill={'var(--semiDark)'}
                            x={cx + 5 + label.xOffset}
                            y={cy + 5}
                        >
                            {isLabelOverlapped ? '+' : label.display}
                        </text>
                    </g>
                );
            })}
        </>
    );
});
DiagramEdges.displayName = 'DiagramEdges';

export default DiagramEdges;
