import { EdgeInterface, NodeInterface } from 'components/visualizations/deadlocks/Diagram';
import type { Coordinate, DiagramEdge } from '../components/diagram/types';

export type ArrowDirection = 'V' | 'H';

/**
 * Returns the angle (in radians) between two points.
 * @param start An [x, y] pair of cartesian coordinates.
 * @param end An [x, y] pair of cartesian coordinates.
 */
export function getAngle([x1, y1]: [number, number], [x2, y2]: [number, number]): number {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    return Math.atan2(deltaY, deltaX);
}

/**
 * Converts a PointerEvent's coordinates into coordinates within the SVG.
 * @param event The PointerEvent that triggered the mouse interaction.
 */
export function getPointerSvgPoint(event: React.PointerEvent<SVGSVGElement>): DOMPoint {
    const { clientX, clientY, currentTarget: svg } = event;
    const pt = svg.createSVGPoint();
    pt.x = clientX;
    pt.y = clientY;
    // getScreenCTM isn't documented to return null as the type definition states
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return pt.matrixTransform(svg.getScreenCTM()!.inverse());
}

/**
 * Converts a MouseEvent's coordinates into coordinates within the SVG.
 * @param event The MouseEvent that triggered the mouse interaction.
 */
export function getMouseSvgPoint(event: React.MouseEvent<SVGSVGElement>): DOMPoint {
    const { clientX, clientY, currentTarget: svg } = event;
    const pt = svg.createSVGPoint();
    pt.x = clientX;
    pt.y = clientY;
    // getScreenCTM isn't documented to return null as the type definition states
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return pt.matrixTransform(svg.getScreenCTM()!.inverse());
}

/**
 *
 * @param angle A numeric expression that contains an angle measured in radians.
 * @param width
 * @param height
 * @param center An [x, y] pair of cartesian coordinates representing the center of the rectangle
 */
export function getPointOnRectangle(
    angle: number,
    width: number,
    height: number,
    [cx, cy]: [number, number]
): [number, number] {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    if (width * Math.abs(sin) < height * Math.abs(cos)) {
        const x = (Math.sign(cos) * width) / 2;
        const y = Math.tan(angle) * x;
        return [cx + x, cy + y];
    } else {
        const y = (Math.sign(sin) * height) / 2;
        const x = (1 / Math.tan(angle)) * y;
        return [cx + x, cy + y];
    }
}
/**
 *
 * Obtains the middle points of a line that represents an arrow
 */
export function calculateEdgeMidpoint(edge: DiagramEdge<NodeInterface, EdgeInterface>) {
    const { x1, x2, y1, y2 } = edge;

    const cx = x1 + (x2 - x1) / 2;
    const cy = y1 + (y2 - y1) / 2;

    return { cx, cy };
}

/**
 * Detect edges that are overlapped behind other edges
 */
export function detectSimilarEdges(
    edges: ReadonlyArray<DiagramEdge<NodeInterface, EdgeInterface>>,
    edge: DiagramEdge<NodeInterface, EdgeInterface>
) {
    const { x1, x2, y1, y2 } = edge;

    return edges.filter(e => {
        const overlaps =
            (Math.floor(e.x1) === Math.floor(x1) &&
                Math.floor(e.x2) === Math.floor(x2) &&
                Math.floor(e.y1) === Math.floor(y1) &&
                Math.floor(e.y2) === Math.floor(y2)) ||
            (Math.floor(e.x1) === Math.floor(x2) &&
                Math.floor(e.x2) === Math.floor(x1) &&
                Math.floor(e.y1) === Math.floor(y2) &&
                Math.floor(e.y2) === Math.floor(y1));

        return overlaps;
    });
}

/**
 * Detects labels (the middle circle of the edge) that are overlapped by other labels
 */
export function detectSimilarLabels(
    edges: ReadonlyArray<DiagramEdge<NodeInterface, EdgeInterface>>,
    edge: DiagramEdge<NodeInterface, EdgeInterface>
) {
    const { cx, cy } = calculateEdgeMidpoint(edge);
    return edges.filter(e => {
        const midpoints = calculateEdgeMidpoint(e);
        return midpoints.cx.toFixed(4) === cx.toFixed(4) && midpoints.cy.toFixed(4) === cy.toFixed(4);
    });
}

export function detectArrowDirection(edge: DiagramEdge<NodeInterface, EdgeInterface>): ArrowDirection {
    const { x1, x2, y1, y2 } = edge;

    const xDiff = Math.abs(x2 - x1);
    const yDiff = Math.abs(y2 - y1);
    return yDiff > xDiff ? 'V' : 'H';
}

/**
 * * Returns a number that can be used to displace overlapping arrows
 */
export function calculateArrowCorrection(
    similarEdges: ReadonlyArray<DiagramEdge<NodeInterface, EdgeInterface>>,
    edge: DiagramEdge<NodeInterface, EdgeInterface>
) {
    if (similarEdges.indexOf(edge) <= 0) {
        return 0;
    }

    const direction = similarEdges.indexOf(edge) % 2 > 0 ? 1 : -1;
    const shift = Math.ceil(similarEdges.indexOf(edge) / 2);

    // used to displace the overlapping arrow
    return 18 * shift * direction;
}

/**
 * Utility class to represent an immutable SVG ViewBox.
 */
export class ViewBox {
    readonly #height: number;
    readonly #minX: number;
    readonly #minY: number;
    readonly #width: number;

    constructor(minX: number, minY: number, width: number, height: number) {
        this.#minX = minX;
        this.#minY = minY;
        this.#width = width;
        this.#height = height;
    }

    /**
     * Returns the ViewBox center coordinate.
     */
    get center(): Coordinate {
        const x = this.#minX + this.#width / 2;
        const y = this.#minY + this.#height / 2;
        return { x, y };
    }

    get height() {
        return this.#height;
    }

    get minX() {
        return this.#minX;
    }

    get minY() {
        return this.#minY;
    }

    get width() {
        return this.#width;
    }

    toString(): string {
        return `${this.#minX} ${this.#minY} ${this.#width} ${this.#height}`;
    }

    equals(otherViewBox: ViewBox): boolean {
        return (
            this.minX.toFixed(4) === otherViewBox.minX.toFixed(4) &&
            this.minY.toFixed(4) === otherViewBox.minY.toFixed(4) &&
            this.width.toFixed(4) === otherViewBox.width.toFixed(4) &&
            this.height.toFixed(4) === otherViewBox.height.toFixed(4)
        );
    }

    /**
     * Returns a new ViewBox that represents this ViewBox translated in the x direction by dx and the y direction by dy.
     * @param dx The delta in the x direction to translate by.
     * @param dy The delta in the y direction to translate by.
     */
    translate(dx: number, dy: number): ViewBox {
        return new ViewBox(this.#minX + dx, this.#minY + dy, this.#width, this.#height);
    }

    /**
     * Returns a new ViewBox that represents this ViewBox scaled by the specified factor.
     * The x and y coordinates specified will be maintained at the same respective location in the new ViewBox.
     * @param factor The factor to scale by.
     * @param center The point to zoom into.
     */
    scale(factor: number, center?: Coordinate): ViewBox;
    /**
     * Returns a new ViewBox that represents this ViewBox scaled to the specified size.
     * The x and y coordinates specified will be maintained at the same respective location in the new ViewBox.
     * @param size The new ViewBox size.
     * @param center The point to zoom into.
     */
    scale(size: { height: number; width: number }, center?: Coordinate): ViewBox;
    scale(factor: number | { height: number; width: number }, center: Coordinate = this.center): ViewBox {
        const { x, y } = center;
        const width = typeof factor === 'number' ? this.#width * factor : factor.width;
        const height = typeof factor === 'number' ? this.#height * factor : factor.height;
        const minX = x - width * ((x - this.#minX) / this.#width);
        const minY = y - height * ((y - this.#minY) / this.#height);
        return new ViewBox(minX, minY, width, height);
    }

    static max(baseViewBox: ViewBox, ...viewBoxes: ReadonlyArray<ViewBox>): ViewBox {
        const height = Math.max(baseViewBox.height, ...viewBoxes.map(x => x.height));
        const width = Math.max(baseViewBox.width, ...viewBoxes.map(x => x.width));
        return baseViewBox.scale({ height, width });
    }
}
