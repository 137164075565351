import APIRequest from './Request';
import Logger from 'services/logger';

const logger = Logger.get('urlShortener');

export interface ShortUrlInterface {
    shortUrl: string;
}

export async function getShortUrl(longUrl: string) {
    logger.info('getting short url from urlshortener api');

    const response = await new APIRequest<ShortUrlInterface>({
        path: `urlshortener`,
        method: `post`,
        params: { longUrl },
    }).request();

    return response.data;
}
