import Calendar from './Calendar';
import Icon from 'components/icons/Icon';
import moment from 'moment-timezone';
import { useState, useEffect, FC } from 'react';
import Slider from './Slider';
import SlideSwitch from 'components/form/SlideSwitch';
import TimeInterval from 'models/TimeInterval';
import Timestamp from 'components/format/Timestamp';
import { DateTimeFormat, getCurrentTimezone } from 'services/timezone';
import { humanizeDuration } from 'services/time';
import { Link } from 'components/url/Link';
import { Tab, Tabs, TabPanel } from 'components/tabs/Tabs';
import { useTimeInterval } from 'components/context/TimeInterval';

type TimePickerType = 'duration' | 'custom';

interface TimePickerPropsInterface {
    defaultMode?: TimePickerType;
    className: string;
    readOnly?: boolean;
    highlight?: boolean;
}

const TimePicker: FC<TimePickerPropsInterface> = ({ className = '', readOnly = false, highlight = false, ...rest }) => {
    const { interval, set } = useTimeInterval();
    const [timeInterval, setTimeInterval] = useState(new TimeInterval(interval.from, interval.until));
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState<TimePickerType>(timeInterval.isOffset ? 'duration' : 'custom');
    const [currentTimezone, setCurrentTimezone] = useState<string | undefined>(undefined);

    function commit() {
        set(timeInterval.from, timeInterval.until);

        setOpen(false);
    }

    function cancel() {
        setTimeInterval(new TimeInterval(interval.from, interval.until));

        setMode(interval.isOffset ? 'duration' : 'custom');
        setOpen(false);
    }

    function toggleEndingNow(endingNow: boolean) {
        const newInterval = endingNow
            ? new TimeInterval(-timeInterval.tsOffset, 0)
            : new TimeInterval(timeInterval.fromTs, timeInterval.untilTs);

        setTimeInterval(newInterval);
    }

    function handleSliderChange(offset: number) {
        setTimeInterval(new TimeInterval(-offset, 0));
    }

    function handleFromChange(from: number) {
        setTimeInterval(new TimeInterval(from, timeInterval.until));
    }

    function handleUntilChange(until: number) {
        setTimeInterval(new TimeInterval(timeInterval.from, until));
    }

    function switchToDuration() {
        if (!timeInterval.isOffset) {
            setTimeInterval(TimeInterval.createDefaultOffsetInterval());
        }

        setMode('duration');
    }

    function switchToCustom() {
        setTimeInterval(new TimeInterval(interval.fromTs, interval.until));

        setMode('custom');
    }

    useEffect(() => {
        getCurrentTimezone().then(tz => setCurrentTimezone(tz));
    }, [open]);

    useEffect(() => {
        const intervalInstance = new TimeInterval(interval.from, interval.until);

        setTimeInterval(intervalInstance);
        setMode(intervalInstance.isOffset ? 'duration' : 'custom');
    }, [interval]);

    return (
        <div className={className} {...rest}>
            <div className="relative">
                <div
                    data-testid="timepicker-component"
                    className={`interval flex items-center cursor-hand ${open ? 'interval--open' : ''} ${
                        readOnly ? 'interval--disabled' : ''
                    } ${highlight ? 'highlight' : ''}`}
                    onClick={() => setOpen(!open)}
                >
                    <div className="interval-dropdown">
                        {mode === 'duration' && (
                            <span className="truncate line-height-120 user-select">
                                {humanizeDuration(timeInterval.tsOffset)}
                            </span>
                        )}
                        {mode === 'custom' && timeInterval.endingNow && (
                            <span className="truncate line-height-120 interval-dropdown__custom-date">
                                <span>
                                    Viewing from{' '}
                                    <strong className="nowrap">
                                        <Timestamp
                                            date={timeInterval.fromTs}
                                            format={DateTimeFormat.TIMESTAMP_LEGEND}
                                        />
                                    </strong>
                                </span>
                            </span>
                        )}
                        {mode === 'custom' && !timeInterval.endingNow && (
                            <span className="truncate line-height-120 interval-dropdown__custom-date">
                                <strong className="nowrap">
                                    <Timestamp date={timeInterval.fromTs} format={DateTimeFormat.TIMESTAMP_LEGEND} />
                                    <span className="regular">{' to '}</span>
                                </strong>
                                <strong className="nowrap">
                                    <Timestamp date={timeInterval.untilTs} format={DateTimeFormat.TIMESTAMP_LEGEND} />
                                </strong>
                            </span>
                        )}

                        <Icon icon="keyboard_arrow_down" className="flex white ml2 fz16" />
                    </div>
                </div>
                {open && (
                    <div className="interval-dropdown-content absolute bg-white rounded right-0 border--dropdown dropdown-position mt1">
                        <div>
                            <div className="bg-grey05 pt3 px3">
                                <Tabs defaultTab={mode}>
                                    <div className="border-bottom border-color-grey1 border-thick">
                                        <Tab name="duration" onClick={() => switchToDuration()}>
                                            <span className="p2 fz16">Duration</span>
                                        </Tab>
                                        <Tab name="custom" onClick={() => switchToCustom()}>
                                            <span className="p2 fz16">Custom</span>
                                        </Tab>
                                    </div>
                                    <TabPanel name="duration" className="pb3">
                                        <div className="py3">
                                            <span className="uppercase fz10 grey3 mr2 nowrap py3">Select Duration</span>
                                            <span className="dark bold mr2">
                                                {humanizeDuration(timeInterval.tsOffset)}
                                            </span>
                                            <span className="dark">Ending now</span>
                                        </div>
                                        <Slider value={timeInterval.tsOffset} onSlide={handleSliderChange}></Slider>
                                    </TabPanel>
                                    <TabPanel name="custom" className="py3">
                                        <div className="datetime flex flex-column relative">
                                            <div className="flex items-center absolute right-0 z1">
                                                <label className="uppercase fz10 grey3 mr2 nowrap bold">Now</label>
                                                <SlideSwitch
                                                    checked={timeInterval.endingNow}
                                                    onChange={e => toggleEndingNow(e.target.checked)}
                                                    data-testid="timepicker-custom-end"
                                                />
                                            </div>
                                            <div className="grid interval-dropdown-content__interval-offset relative align__top--3">
                                                <label className="uppercase fz10 grey3 nowrap bold">Start Date</label>
                                                <label className="datetime__label__end-now uppercase fz10 grey3 nowrap bold">
                                                    End Date
                                                </label>
                                                {currentTimezone && (
                                                    <>
                                                        <Calendar
                                                            className={
                                                                timeInterval.endingNow
                                                                    ? 'interval-dropdown-content__interval-offset__start-only'
                                                                    : ''
                                                            }
                                                            timestamp={timeInterval.fromTs}
                                                            timezone={currentTimezone}
                                                            onChange={handleFromChange}
                                                        />
                                                        {!timeInterval.endingNow && (
                                                            <Calendar
                                                                timestamp={timeInterval.untilTs}
                                                                timezone={currentTimezone}
                                                                onChange={handleUntilChange}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                            <div className="interval-dropdown-content__actions bg-white p3 flex items-center justify-between">
                                {!!currentTimezone && (
                                    <div>
                                        <span className="uppercase grey3 fz10">Current time zone</span>
                                        <div className="flex mt1">
                                            <span className="nowrap">
                                                ({moment.tz(currentTimezone).format('Z')}) {currentTimezone}
                                            </span>
                                            <Link
                                                className="ml2 uppercase fz10 self-end"
                                                to="settings/preferences"
                                                onClick={() => setOpen(false)}
                                            >
                                                Change
                                            </Link>
                                        </div>
                                    </div>
                                )}
                                <div className="nowrap">
                                    <button
                                        className="primary-grey ml4"
                                        onClick={cancel}
                                        data-testid="timepicker-cancel"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="primary-mainColor ml2"
                                        disabled={readOnly || timeInterval.isInvalid()}
                                        onClick={commit}
                                        data-testid="timepicker-apply"
                                    >
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TimePicker;
