interface ErrorObject {
    [key: string]: string;
}

interface ErrorResponse {
    error?: ErrorObject;
}

export default class ServiceError extends Error {
    private response?: ErrorResponse;

    constructor(message: string, response?: ErrorResponse) {
        super(`Service error: ${message}`);

        this.response = response;

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ServiceError);
        }
    }

    get error(): ErrorObject | null {
        return this.response && this.response.error ? this.response.error : null;
    }
}
