import { ColumnDefinitionInterface, ColumnInterface, MainColumn, RegularColumn } from './columns';
import { RankInterface } from './ranks';

export interface ProfilerInterface {
    columns: ColumnInterface[];
    readonly rank: Pick<RankInterface, 'id'>;
    readonly rankBy: string;
    readonly limit: number;
}

class ColumnSorter {
    private priorities: Record<string, number> = {
        rank: 1000,
        default: 0,
    };

    constructor(private mainId: string) {}

    sortColumns<T extends { id: string }>(columns: T[]): T[] {
        return columns.sort((columnA, columnB) => {
            return this.compare(columnA.id, columnB.id);
        });
    }

    private compare(idA: string, idB: string) {
        const priorityA = this.prioritize(idA);
        const priorityB = this.prioritize(idB);

        if (priorityA > priorityB) {
            return -1;
        } else if (priorityA < priorityB) {
            return 1;
        }

        return 0;
    }

    private prioritize(id: string) {
        const priority: number | undefined = this.priorities[id];
        if (undefined !== priority) {
            return priority;
        }

        if (id === this.mainId) {
            return 100;
        }

        return this.priorities.default;
    }
}

export class Profiler implements ProfilerInterface {
    readonly columns: ColumnInterface[];

    constructor(
        readonly rank: RankInterface,
        readonly rankBy: string,
        columns: ColumnDefinitionInterface[],
        // readonly compare?: CompareInterface,
        readonly limit: number = 20
    ) {
        this.columns = new ColumnSorter(this.rankBy).sortColumns(
            columns.map(column => {
                if (this.rankBy === column.id) {
                    return new MainColumn(column);
                }

                return new RegularColumn(column);
            })
        );
    }

    get mainColumn(): MainColumn {
        return this.getColumn(this.rankBy) as MainColumn;
    }

    get mainColumnTypes(): Record<string, string[]> {
        const types: Record<string, string[]> = {};

        this.columns.forEach(column => {
            types[column.id] = [column.summarizeAs];
        });

        types[this.mainColumn.id].push('series');

        return types;
    }

    get asyncColumnTypes(): Record<string, string[]> {
        const types: Record<string, string[]> = {};

        this.columns.forEach(column => {
            types[column.id] = [column.summarizeAs];
        });

        types[this.mainColumn.id].push('series');

        return types;
    }

    findColumn(id: string): ColumnInterface | undefined {
        return this.columns.find(column => column.id === id);
    }

    private getColumn(id: string): ColumnInterface {
        const column = this.findColumn(id);
        if (undefined === column) {
            throw new Error(`Invalid ranked column ${id}`);
        }

        return column;
    }
}
