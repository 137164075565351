import moment from 'moment-timezone';
import { getCurrentMsTimestamp, detectFormat, toMs } from 'services/time';

const DATE_FORMAT = 'MMM D, YYYY',
    MONTH_FORMAT = 'MMMM',
    TIME_FORMAT = 'h:mm a',
    YEAR_FORMAT = 'YYYY',
    DATE_REGEX = /^\b(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec) \d{1,2}, \d{4}$/i;

export interface CalendarDay {
    day: number;
    timestamp: number;
    pastMonth: boolean;
    future: boolean;
}

export default class CalendarHelper {
    private dateTime: moment.Moment;
    private now: moment.Moment;
    private calendar!: CalendarDay[];
    private timestamp: number;
    private timezone: string;

    constructor(timestamp: number, timezone: string) {
        this.dateTime = moment.tz(toMs(timestamp), timezone);
        this.now = moment.tz(getCurrentMsTimestamp(), timezone);
        this.timestamp = timestamp;
        this.timezone = timezone;

        this.generateCalendar();
    }

    get date() {
        return this.dateTime.format(DATE_FORMAT);
    }

    get days() {
        return this.calendar;
    }

    get time() {
        return this.dateTime.format(TIME_FORMAT);
    }

    get month() {
        return this.dateTime.format(MONTH_FORMAT);
    }

    get year() {
        return this.dateTime.format(YEAR_FORMAT);
    }

    generateCalendar() {
        const dateTime = this.dateTime.clone();
        dateTime.subtract(dateTime.date() - 1, 'days');
        dateTime.subtract(dateTime.day(), 'days');

        const endTime = this.dateTime.clone().endOf('month');
        const month = this.dateTime.month();

        const calendar = [];

        do {
            calendar.push({
                day: dateTime.date(),
                timestamp: dateTime.unix(),
                pastMonth: dateTime.month() < month,
                future: dateTime > this.now,
            });

            dateTime.add(1, 'day');
        } while (dateTime.diff(endTime, 'days', true) <= 0);

        this.calendar = calendar;
    }

    prevMonth() {
        return new CalendarHelper(moment.unix(this.timestamp).subtract(1, 'month').unix(), this.timezone);
    }

    nextMonth() {
        const nextMonth = moment.unix(this.timestamp).add(1, 'month');

        if (nextMonth > this.now) {
            return this;
        }

        return new CalendarHelper(nextMonth.unix(), this.timezone);
    }

    validateDate(date: string) {
        if (!DATE_REGEX.test(date)) {
            return false;
        }

        const dateTime = CalendarHelper.buildDateTime(date, this.time, this.timezone);

        return dateTime ? dateTime.isValid() : false;
    }

    validateTime(time: string) {
        const dateTime = CalendarHelper.buildDateTime(this.date, time, this.timezone);

        return dateTime ? dateTime.isValid() : false;
    }

    static buildDateTime(date: string, time: string, timezone: string) {
        const timeFormat = detectFormat(time);

        if (!timeFormat) {
            return false;
        }

        if (timeFormat.format !== TIME_FORMAT) {
            time = moment(time, timeFormat.format).format(TIME_FORMAT);
        }

        const dateTime = moment.tz(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`, timezone);

        if (!dateTime.isValid()) {
            return false;
        }

        const now = moment.tz(getCurrentMsTimestamp(), timezone);
        if (dateTime.isAfter(now)) {
            return false;
        }

        return dateTime;
    }
}
