import Authentication from 'models/Authentication';
import Icon from 'components/icons/Icon';
import Feature from 'components/serviceLevel/Feature';
import GitHubConfiguration, { GitHubOrgInterface } from './GitHubConfiguration';
import GoogleConfiguration from './GoogleConfiguration';
import { FC, SyntheticEvent, useState, useEffect } from 'react';
import { AuthConfigDataInterface } from 'services/settings/authentication';

interface OauthProviderSelectionPropsInterface {
    config: AuthConfigDataInterface;
    onChange: (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    onConfigChange: (key: string, value: string) => void;
}

const OauthProviderSelection: FC<OauthProviderSelectionPropsInterface> = ({ onChange, config, onConfigChange }) => {
    const [oauthWindow, setOAuthWindow] = useState<Window | null>(null);
    const [gitHubOrgs, setGitHubOrgs] = useState<GitHubOrgInterface[]>([]);

    // Window cleanup listener.
    useEffect(
        () => () => {
            if (oauthWindow && !oauthWindow.closed) {
                oauthWindow.close();
            }

            delete window.VividCortex;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    function setupOAuth() {
        const provider = config['auth.oauth.provider'];

        const setupWindow = window.open(`/oauth/services/${provider}/request-info`, 'setup-oauth', 'menubar=no');
        setOAuthWindow(setupWindow);

        window.VividCortex = {
            OAuthInfo: Object.seal({
                setupWindow,
                set info(data: DPMOAuthInfoData) {
                    if (data['auth.oauth.provider'] === 'github') {
                        onConfigChange('auth.oauth.github.email', data['auth.oauth.github.email']);
                        if (data['auth.oauth.github.organizations'].length === 1) {
                            onConfigChange(
                                'auth.oauth.github.organization',
                                data['auth.oauth.github.organizations'][0].name
                            );
                        }

                        setGitHubOrgs(data['auth.oauth.github.organizations']);
                    }

                    if (data['auth.oauth.provider'] === 'google') {
                        onConfigChange('auth.oauth.email', data['auth.oauth.email']);
                        onConfigChange('auth.oauth.google.emailDomain', data['auth.oauth.google.emailDomain']);
                    }

                    if (window.VividCortex?.OAuthInfo.setupWindow?.closed === false) {
                        window.VividCortex.OAuthInfo.setupWindow.close();
                        setOAuthWindow(null);
                    }
                },
            }),
        };
    }

    return (
        <div className="flex">
            <div>
                <b className="form__label fz10 uppercase flex items-center">Select your authentication provider:</b>

                <div className="flex mb2">
                    <Feature name="githubAuth">
                        <label className="oauth-info oauth-info--github flex mr3" data-testid="oauth-github">
                            <input
                                type="radio"
                                value={Authentication.GITHUB_PROVIDER}
                                onChange={onChange}
                                name="auth.oauth.provider"
                                checked={config['auth.oauth.provider'] === Authentication.GITHUB_PROVIDER}
                            />
                            <span className="p2 border rounded border-color-grey1 flex items-center">
                                <Icon icon="github" className="inline-flex h2 mx2" />
                                Authenticate with GitHub
                            </span>
                        </label>
                    </Feature>

                    <label className="oauth-info oauth-info--google flex" data-testid="oauth-google">
                        <input
                            type="radio"
                            value={Authentication.GOOGLE_PROVIDER}
                            name="auth.oauth.provider"
                            onChange={onChange}
                            checked={config['auth.oauth.provider'] === Authentication.GOOGLE_PROVIDER}
                        />
                        <span className="p2 border rounded border-color-grey1 flex items-center">
                            <Icon icon="google" className="inline-flex h2 mx2" />
                            Authenticate with Google
                        </span>
                    </label>
                </div>

                {config['auth.oauth.provider'] === Authentication.GITHUB_PROVIDER && (
                    <GitHubConfiguration
                        config={config}
                        onChange={onChange}
                        setupOAuth={setupOAuth}
                        gitHubOrgs={gitHubOrgs}
                    />
                )}

                {config['auth.oauth.provider'] === Authentication.GOOGLE_PROVIDER && (
                    <GoogleConfiguration config={config} setupOAuth={setupOAuth} />
                )}
            </div>
        </div>
    );
};

export default OauthProviderSelection;
