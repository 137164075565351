const PublicHeader = () => (
    <div className="landing-page-header flex column border-bottom border-color-grey1 no-shrink" role="banner">
        <div className="top-bar flex items-center justify-start mx-auto mt1 mb1 px3">
            <a
                href="https://www.vividcortex.com/"
                className="nav__logo__link flex flex-start flex-grow-1"
                title="Navigate to our website"
            >
                <svg
                    width="346"
                    height="79"
                    viewBox="0 0 346 79"
                    xmlns="http://www.w3.org/2000/svg"
                    className="swi-logo"
                >
                    <title>Solarwinds</title>
                    <g fill="none">
                        <g transform="translate(0 39.770115)" fill="#666">
                            <path d="M9 38.9C3.8 38.9 0.1 37.3 0.1 37.3L0.1 32.7C0.1 32.7 5.7 34.5 8.9 34.5 11.8 34.5 14.7 34 14.7 30.4 14.7 27.7 12.7 27.1 10.2 26.4L8 25.9C5 25 0 23.8 0 17.7 0 10.9 5.5 9.2 11.1 9.2 16.2 9.2 19.1 10.9 19.1 10.9L19.1 15.4C19.1 15.4 15.3 13.6 10.9 13.6 7.4 13.6 5.1 14.3 5.1 17.4 5.1 20.1 7.2 20.7 9.6 21.4L12.4 22.2C15.7 23.1 19.9 25 19.9 30.3 20 37.1 15 38.9 9 38.9Z" />
                            <path d="M39.2 38.9C33.6 38.9 25.9 37.1 25.9 24.1 25.9 11.3 33.7 9.2 39.2 9.2 44.3 9.2 52.5 11 52.5 24.1 52.5 37.3 44.4 38.9 39.2 38.9ZM39.2 13.2C36.2 13.2 31.2 14.1 31.2 24.1 31.2 34.1 37 34.8 39.2 34.8 41.9 34.8 47.1 33.7 47.1 24.1 47 14 42.1 13.2 39.2 13.2Z" />
                            <polygon points="59.5 38.4 59.5 0.4 64.8 0.4 64.8 38.4" />
                            <path d="M91.5 38.4L91 34.7C91 34.7 87.7 39 81.6 39 77.3 39 72.5 37.4 72.5 30.2 72.5 22.3 79.9 20.8 84.5 20.8L90.8 20.8 90.8 18.3C90.8 14.6 88.6 13.7 84.8 13.7 80.2 13.7 74.9 15.7 74.9 15.7L74.9 11.3C74.9 11.3 79.7 9.3 85.6 9.3 91.9 9.3 96.1 11.6 96.1 19.2L96.1 38.5 91.5 38.5 91.5 38.4ZM90.8 24L85.6 24.1C80.7 24.2 77.8 25.5 77.8 30 77.8 33.7 80 34.8 82.9 34.8 86.9 34.8 90.8 31.1 90.8 31.1L90.8 24Z" />
                            <path d="M115.6 14.1C112.5 14.1 110 17.6 110 17.6L110 38.4 104.6 38.4 104.6 9.7 108.9 9.7 109.6 13.7C109.6 13.7 111.4 9.2 116.2 9.2 119 9.2 120.1 10 120.1 10L120.1 14.9C120.1 14.9 118.4 14.1 115.6 14.1Z" />
                            <path d="M162.7 38.4L155.2 38.4 150.7 23.2C149.5 19.1 147.8 12.4 147.8 12.4 147.8 12.4 146.1 19.2 144.9 23.1L140.5 38.4 133 38.4 125.5 9.7 131.6 9.7 135.1 26.1C135.9 29.9 136.8 35.9 136.8 35.9 136.8 35.9 137.9 29.9 139.1 26.1L144 9.7 151.7 9.7 156.6 26.1C157.7 29.8 158.9 35.9 158.9 35.9 158.9 35.9 159.8 30.4 160.7 26.1L164.2 9.7 170.2 9.7 162.7 38.4Z" />
                            <polygon points="177 38.4 177 9.6 182.3 9.6 182.3 38.4" />
                            <path d="M210.8 38.4L210.8 18.8C210.8 15.2 209.3 13.9 205.6 13.9 200.8 13.9 196.2 17.5 196.2 17.5L196.2 38.4 190.8 38.4 190.8 9.7 195 9.7 196.1 13.8C196.1 13.8 200.8 9.2 206.9 9.2 212.2 9.2 216.2 11.5 216.2 18.9L216.2 38.4 210.8 38.4Z" />
                            <path d="M244.2 38.4L243.6 34.8C243.6 34.8 240.6 39 234.3 39 229.9 39 222.5 37 222.5 24.2 222.5 11.1 231.7 9.3 235.4 9.3 240.6 9.3 243 12.2 243 12.2L243 0.4 248.4 0.4 248.4 38.4 244.2 38.4ZM243 16.4C243 16.4 240 13.5 235.9 13.5 232 13.5 227.9 15.1 227.9 24.1 227.9 33.5 232.1 34.6 235.4 34.6 239.3 34.6 243 31.3 243 31.3L243 16.4Z" />
                            <path d="M265 38.9C259.7 38.9 256 37.3 256 37.3L256 32.7C256 32.7 261.6 34.5 264.9 34.5 267.8 34.5 270.6 34 270.6 30.4 270.6 27.7 268.6 27.1 266.2 26.4L263.9 25.9C260.9 25 255.9 23.8 255.9 17.7 255.9 10.9 261.4 9.2 267.1 9.2 272.1 9.2 275 10.9 275 10.9L275 15.4C275 15.4 271.2 13.6 266.9 13.6 263.3 13.6 261 14.3 261 17.4 261 20.1 263.1 20.7 265.6 21.4L268.3 22.2C271.6 23.1 275.8 25 275.8 30.3 275.9 37.1 271 38.9 265 38.9Z" />
                        </g>
                        <g fill="#F99D1C">
                            <path d="M301.1 32.1C301.4 32 301.6 32 301.8 31.9 307.2 30.3 312.5 28.2 317.9 26 323 23.9 328.1 21.3 332.7 17.6 337.3 13.8 340.9 8.9 343 3 343.3 2.2 343.9 0.9 343.9 0 336.8 9.8 308.2 10.8 308.2 10.8L315 4.7C287.9 4.8 269 16.2 260.4 22.4 271.4 23.6 281.5 28.2 289.3 35.3 293.2 34.1 297.2 33.2 301.1 32.1Z" />
                            <path d="M345.7 31.1C345.7 31.1 319.5 29.1 292.1 37.9 295.7 41.7 298.8 46 301.2 50.7 315.5 42.9 335.5 32.6 345.7 31.1Z" />
                            <path d="M302.9 54.9C304.6 59.2 305.7 63.7 306.2 68.5L331.3 42.8 302.9 54.9Z" />
                        </g>
                    </g>
                </svg>
            </a>
        </div>
    </div>
);

export default PublicHeader;
