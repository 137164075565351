import * as React from 'react';
import { TopicInterface } from 'services/documentation';
import { DocumentationStateInterface, TopicPostState, TopicState } from './state';

const Breadcrumbs: React.FC<{
    state: DocumentationStateInterface;
    onHomeClick: () => void;
    onTopicClick: (topic: TopicInterface) => void;
}> = ({ state, onHomeClick, onTopicClick }) => {
    return (
        <ul
            data-testid="documentation-breadcrumbs"
            className="documentation-main__breadcrumbs list-reset mx3 py2 capitalize flex flex-wrap fz12 border-top border-color-grey1 items-baseline"
        >
            <li className="documentation-main__breadcrumbs__item">
                <button
                    className="link"
                    onClick={e => {
                        e.preventDefault();
                        onHomeClick();
                    }}
                >
                    Home
                </button>
            </li>

            {state instanceof TopicState ? (
                <li className="documentation-main__breadcrumbs__item">
                    <span className="mainColor">{state.topic.title}</span>
                </li>
            ) : state instanceof TopicPostState ? (
                <li className="documentation-main__breadcrumbs__item">
                    <button
                        data-testid="documentation-breadcrumbs-topic"
                        className="link"
                        onClick={e => {
                            e.preventDefault();
                            onTopicClick(state.topic);
                        }}
                    >
                        {state.topic.title}
                    </button>
                </li>
            ) : null}
        </ul>
    );
};

export default Breadcrumbs;
