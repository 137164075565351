import { useBootstrap } from 'components/context/Bootstrap';
import { OrgBootstrap } from 'models/Bootstrap';

const useOrgContext = () => {
    const [{ bootstrap }] = useBootstrap();

    if (!(bootstrap instanceof OrgBootstrap)) {
        throw new Error('The bootstrap data does not belong to an org context.');
    }

    return bootstrap as OrgBootstrap;
};

export default useOrgContext;
