import User from 'models/User';
import Gravatar, { GravatarSize } from 'components/icons/Gravatar';

export interface ItemInterface {
    user: User;
    checked: boolean;
}

interface CheckListProps<T> {
    items: T[];
    onCheck: (item: T) => void;
}

export default function UserChecklist<T extends ItemInterface>({ items, onCheck }: CheckListProps<T>) {
    const handleChange = (item: T) => {
        const newItem = { ...item };
        newItem.checked = !item.checked;
        onCheck(newItem);
    };

    return (
        <div className="vc-checklist">
            <ul className="list-reset bg-white py1">
                {items.map(item => (
                    <li
                        key={item.user.id}
                        className="vc-checklist__item flex items-center px2 py1 full-width cursor-hand bg-white"
                        data-testid="checklist-row"
                    >
                        <label>
                            <input
                                type="checkbox"
                                checked={item.checked}
                                onChange={() => handleChange(item)}
                                data-testid="checklist-row-checkbox"
                            />
                            <span></span>
                        </label>

                        <div className="flex-grow-1">
                            <div className="flex items-center">
                                <Gravatar
                                    email={item.user.email}
                                    name={item.user.name}
                                    className="mr1"
                                    size={GravatarSize.SMALL}
                                ></Gravatar>
                                <p className="flex-grow-1 bold nowrap" title={item.user.name}>
                                    {item.user.name}
                                </p>
                                <span title={item.user.email}>{item.user.email}</span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
