import DeleteEnvironment from './Delete';
import EditEnvironment from './Edit';
import Env from 'models/Env';
import Modal from 'components/modals/Modal';
import { FC } from 'react';
import { Tab, Tabs, TabPanel } from 'components/tabs/Tabs';

interface EnvironmentActionsPropsInterface {
    visible: boolean;
    onClose: () => void;
    onRename: () => void;
    onRemove: () => void;
    env: Env;
}

const EnvironmentActions: FC<EnvironmentActionsPropsInterface> = ({
    env,
    visible = false,
    onClose,
    onRename,
    onRemove,
}) => {
    return (
        <Modal title="Edit Environment" visible={visible} onClose={onClose}>
            <Tabs defaultTab="rename-tab" className="flex flex-column">
                <div className="tabs flex border-bottom border-color-grey1 border-thick mt2 mr3 ml3">
                    <Tab className="tab__heading" name="rename-tab">
                        <span className="tab__heading__title flex p2 dark no-decoration fz16 cursor-hand">Rename</span>
                    </Tab>
                    <Tab className="tab__heading" name="delete-tab">
                        <span className="tab__heading__title flex p2 dark no-decoration fz16 cursor-hand">Delete</span>
                    </Tab>
                </div>
                <TabPanel name="rename-tab">
                    <EditEnvironment env={env} onSuccess={onRename} onClose={onClose} />
                </TabPanel>
                <TabPanel name="delete-tab">
                    <DeleteEnvironment env={env} onSuccess={onRemove} onClose={onClose} />
                </TabPanel>
            </Tabs>
        </Modal>
    );
};

export default EnvironmentActions;
