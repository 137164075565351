import { FC } from 'react';

const Pill: FC<{ text: string }> = ({ text }) => (
    <div className="flex" data-testid="pill">
        <div className="tags-picker__capsule">
            <div className="monospace spacing line-height-120 relative">{text}</div>
        </div>
    </div>
);

export default Pill;
