import { FC, useState, useEffect } from 'react';
import { ProfilerInterface } from 'models/profiler';
import { notifyError } from 'services/error/reporter';
import MainCell from './MainCell';
import QuantityCell from './QuantityCell';
import TimeAgo from 'components/format/TimeAgo';
import { ResultElementInterface as BaseResultElementInterface } from 'services/api/profiler';
import { TableCellLoader } from 'components/loaders/Loader';
import useMounted from 'hooks/useMounted';
import { ColumnInterface, RenderAs } from 'models/profiler/columns';
import { ColumnDataType } from 'models/profiler/columnData';
import { AsyncColumnDataType } from 'services/api/profiler/columnData';
import Logger from 'services/logger';

export interface ResultElementInterface extends BaseResultElementInterface {
    metricUrl: string;
    getColumnData<Column extends ColumnInterface>(column: Column): AsyncColumnDataType<Column>;
}

interface ProfilerCellPropsInterface {
    element: ResultElementInterface;
    column: ColumnInterface;
}

const ProfilerCell: FC<ProfilerCellPropsInterface> = ({ column, element }) => {
    const [cellData, setCellData] = useState<{ renderAs: RenderAs; data: ColumnDataType<typeof column> }>();
    const { resolveIfMounted } = useMounted();

    useEffect(() => {
        resolveIfMounted(element.getColumnData(column))
            .then(data => ({ data, renderAs: column.renderAs }))
            .then(setCellData)
            .catch(notifyError);
    }, [resolveIfMounted, element, column]);

    if (undefined === cellData) {
        return <TableCellLoader />;
    }

    switch (cellData.renderAs) {
        case 'main':
            return <MainCell metricUrl={element.metricUrl} description={element.description} data={cellData.data} />;
        case 'quantity':
            return <QuantityCell data={cellData.data} />;
        case 'text':
            return <>{cellData.data}</>;
        case 'age':
            return <TimeAgo date={parseInt(cellData.data, 10)} />;
        default:
            Logger.get('ProfilerRow').error(`Unsupported column render: ${column.renderAs}`);
            return <TableCellLoader />;
    }
};

interface ProfilerRowPropsInterface {
    profiler: ProfilerInterface;
    element: ResultElementInterface;
}

const ProfilerRow: FC<ProfilerRowPropsInterface> = ({ profiler, element }) => {
    return (
        <tr data-testid="profiler-row">
            {profiler.columns.map((column, index) => (
                <td key={`cell-${column.id}`} title={column.title}>
                    <ProfilerCell column={column} element={element} />
                </td>
            ))}
        </tr>
    );
};

export default ProfilerRow;
