import APIError from 'services/api/error/Error';
import Dropdown from 'components/dropdowns/Dropdown';
import MetricPicker from 'components/metrics/MetricPicker';
import HostFilterContextProvider, {
    HostFilterContext,
    HostFilterContextInterface,
} from 'components/context/HostFilter';
import { FC, useState } from 'react';
import HostsSelector from 'components/host/HostsSelector';
import { FILTER_MODE } from 'services/api/hosts';
import { EnvSubscriptionInterface, update as updateSubscription } from 'services/api/envSubscriptions';
import useDisplayMessage from 'hooks/useDisplayMessage';

const SubscriptionDropdown: FC<{ onNewSubscription: (subscription: EnvSubscriptionInterface) => void }> = ({
    onNewSubscription,
}) => {
    const { success, error } = useDisplayMessage();

    const [metric, setSelectedMetric] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    const handleSubscribe = async (hosts: string) => {
        try {
            const newSubscription: EnvSubscriptionInterface = {
                metric,
                hosts: hosts.split(',').map(id => parseInt(id)),
            };

            // We treat a metric/host combination as a subscription on its own, as opposed to a metric with an array of hosts.
            // That's why we are using the parameter "add" here instead of "replace", so that each one is added individually
            // maintaining the old subscriptions untouched. More here https://github.com/VividCortex/webapp/pull/1224#discussion_r679890940
            await updateSubscription(newSubscription, 'add');

            onNewSubscription(newSubscription);
            success('The subscription was successfully created');
        } catch (e) {
            if (e instanceof APIError && e.status === 409) {
                error('Your subscription limit has been reached.');
                return;
            }
            error(
                `There was an error creating a subscription for the metric ${metric}. Please try again later or contact support.`
            );
        }
        setIsExpanded(false);
    };

    return (
        <HostFilterContextProvider storeStatus={false}>
            <HostFilterContext.Consumer>
                {({ filter, set }: HostFilterContextInterface) => (
                    <Dropdown
                        text="NEW SUBSCRIPTION"
                        direction="right"
                        isExpanded={isExpanded}
                        className="anomaly__header__dropdown"
                        onToggle={(expanded: boolean) => {
                            setIsExpanded(expanded);
                            set('');
                            setSelectedMetric('');
                        }}
                    >
                        <div className="p3">
                            <h3 className="fz16 nowrap">Subscribe metric for anomaly detection</h3>
                            <p className="mt3">AI driven anomaly detection for metric/hosts combination.</p>

                            <div className="mt4 mb3">
                                <div className="mb4">
                                    <b className="flex uppercase fz10 mb2">Metric</b>
                                    <MetricPicker onSelect={setSelectedMetric} />
                                </div>

                                <div className="mt2">
                                    <b className="flex uppercase fz10 mb2">Host</b>
                                    <HostsSelector
                                        singleMode={FILTER_MODE.basic}
                                        readOnly={false}
                                        className="hosts-selector--form"
                                        placeholder="Select a host for subscription"
                                    />
                                </div>
                            </div>

                            <div className="right-align">
                                <button
                                    type="button"
                                    disabled={!filter || !metric}
                                    className="primary-mainColor"
                                    onClick={() => handleSubscribe(filter)}
                                >
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </Dropdown>
                )}
            </HostFilterContext.Consumer>
        </HostFilterContextProvider>
    );
};
export default SubscriptionDropdown;
