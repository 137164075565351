import { FC, useState } from 'react';
import { Link } from 'components/url/Link';
import Icon from 'components/icons/Icon';
import StatusPopover from 'components/messages/StatusPopover';
import { removeDestination, validateDestination, APIDestinationInterface } from 'services/api/envConfig';
import useDisplayMessage from 'hooks/useDisplayMessage';
import Bouncer from 'components/icons/Bouncer';
import moment from 'moment-timezone';
import { UserHasPermission } from 'components/security/UserHasPermission';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import IntegrationIcon from './IntegrationIcon';

const IntegrationsTableRow: FC<{
    destination: APIDestinationInterface;
    integrationName: string;
    onDeleted?: (destination: APIDestinationInterface) => void;
}> = ({ destination, integrationName, onDeleted }) => {
    enum ValidationStatus {
        Off,
        Error,
        Ok,
    }

    const { openModal } = useFinalActionModal();

    const testStatusFor = (code: number, message?: string) => {
        switch (code) {
            case ValidationStatus.Off:
                return {
                    code: 'off', // destination.status = 0
                    message:
                        message ||
                        'We are unable to determine status, as this integration has not been tested since it was created or last updated.',
                };
            case ValidationStatus.Error:
                return {
                    code: 'error', // destination.status = 1
                    message:
                        message ||
                        `Test failed on ${moment
                            .unix(destination.updated)
                            .format('MMMM Do YYYY, h:mm:ss')}. Please check the configuration and try again.`,
                };
            default:
                return {
                    code: 'ok', // destination.status = 2
                    message: message || '',
                };
        }
    };

    const [state, setState] = useState({
        isDeleting: false,
        isTesting: false,
        status: testStatusFor(destination.status),
    });

    const { success, error } = useDisplayMessage();

    const setDeleting = (isDeleting = true) => {
        setState(currentState => ({ ...currentState, isDeleting }));
    };

    const setTesting = (isTesting = true) => {
        setState(currentState => ({ ...currentState, isTesting }));
    };

    const setStatus = (status: { success: boolean; message: string }) => {
        const code = status.success ? ValidationStatus.Ok : ValidationStatus.Error;
        setState(currentState => ({ ...currentState, status: testStatusFor(code, status.message) }));
    };

    const handleDelete = async () => {
        setDeleting();
        try {
            await removeDestination(destination);

            setDeleting(false);
            if (onDeleted) {
                onDeleted(destination);
            }

            success(`Integration "${destination.name}" has been deleted.`);
        } catch (e) {
            setDeleting(false);
            error('There was an error deleting the integration.');
        }
    };

    const handleTesting = async (selectedDestination: APIDestinationInterface) => {
        setTesting();
        try {
            const response = (await validateDestination(selectedDestination)) as { success: boolean; message: string };

            setStatus(response);
        } catch (e) {
            error('There was an error testing this integration. Please try again or contact support.');
        } finally {
            setTesting(false);
        }
    };

    return (
        <tr data-testid="integration">
            <td className="vc-table--integrations__status">
                <div className="flex items-center justify-between">
                    <StatusPopover status={state.status}></StatusPopover>
                    {!state.isTesting && (
                        <div
                            className="mainColor cursor-hand"
                            onClick={() => handleTesting(destination)}
                            data-testid="test-destination-link"
                        >
                            Test Now
                        </div>
                    )}
                    {state.isTesting && (
                        <div className="flex justify-between" data-testid="is-testing-message">
                            <div className="action vc-icon-refresh"></div>
                            <span>Testing...</span>
                        </div>
                    )}
                </div>
            </td>
            <td>
                <div className="flex items-center">
                    <div className="relative mr2">
                        <IntegrationIcon name={integrationName} />
                    </div>
                    <span className="flex-grow-1">{destination.name}</span>
                    <UserHasPermission to="changeEnvSettings">
                        <Link to={`settings/integrations/${destination.id}`}>
                            <Icon icon="edit" className="grey3 fz27 hoverable" />
                        </Link>
                        {state.isDeleting ? (
                            <Bouncer />
                        ) : (
                            <Icon
                                className="grey3 fz27 ml3 hoverable"
                                icon="trash"
                                onClick={() =>
                                    openModal({
                                        onSubmit: handleDelete,
                                        confirmMsg: `Are you sure you want to delete this integration?`,
                                        buttonText: 'Yes, delete this integration.',
                                        title: 'Delete this integration?',
                                    })
                                }
                            />
                        )}
                    </UserHasPermission>
                </div>
            </td>
        </tr>
    );
};

export default IntegrationsTableRow;
