import { FC, useEffect, useState } from 'react';
import Query from 'models/Query';
import { fetchQuery } from 'services/api/queries';
import TimeAgo from 'components/format/TimeAgo';
import QueryTags from './QueryTags';
import QueryDigest from './QueryDigest';
import DetailsPageHeader from 'components/layout/DetailsPageHeader';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { useHostFilter } from 'components/context/HostFilter';
import QueryHostsBreakDown from './QueryHostsBreakDown';
import { useParams } from 'react-router-dom';

const QueryDetails: FC = () => {
    const [query, setQuery] = useState<Query | null>();
    const { error } = useDisplayMessage();
    const { filter } = useHostFilter();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        fetchQuery(`${id}`, true)
            .then(response => setQuery(response))
            .catch(() => {
                error('There was an error fetching query information.');
                setQuery(null);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, filter]);

    const FirstSeen = () => (
        <div className="flex flex-column mt3">
            <span className="bold grey3 spacing uppercase fz12">first seen</span>
            <div className="underline dark h4 pt1">{query?.firstSeen && <TimeAgo date={query.firstSeen} />}</div>
        </div>
    );

    return (
        <div className="full-width">
            <DetailsPageHeader
                title="Query"
                id={`${id}`}
                linkTo="queries"
                linkText="All Queries"
                rightContent={query?.firstSeen && <FirstSeen />}
            />
            {query?.digest && (
                <>
                    <QueryDigest digest={query.digest} />
                    <div className="cols two-col">
                        <div className="col">{query?.tags && <QueryTags tags={query.tags} />}</div>
                        <div className="col">
                            <QueryHostsBreakDown queryId={`${id}`} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default QueryDetails;
