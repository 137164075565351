export default class LoginError extends Error {
    readonly code: string;
    readonly displayCaptcha: boolean;

    constructor(code: string, displayCaptcha: boolean) {
        super(`Authentication error: ${code}`);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LoginError);
        }

        this.code = code;
        this.displayCaptcha = displayCaptcha;
    }
}
