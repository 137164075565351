import * as React from 'react';

interface LoginMethodLinkPropsInterface {
    className?: string;
    icon?: React.Component | React.ReactElement;
    url: string;
    children: React.ReactNode;
}

const LoginMethodLink: React.FC<LoginMethodLinkPropsInterface> = ({ url, icon, children, className = '' }) => {
    return (
        <div className={`center mb2 ${className}`}>
            <a
                href={url}
                target="_self"
                className="flex items-center bg-grey05 rounded shadow justify-center no-decoration"
            >
                <>
                    {icon}
                    <span className="ml1 mr2 nowrap dark bold">{children}</span>
                </>
            </a>
        </div>
    );
};

export default LoginMethodLink;
