import APIRequest, { TargetEnv } from './Request';
import Logger from 'services/logger';
import User from 'models/User';
import { RequestParamInterface } from './params/requestParamsEncoder';
const logger = Logger.get('userPreference');

interface UsersPreferenceDataInterface<T> {
    user: number;
    org: number;
    name: string;
    value: T;
    created: number;
    updated: number;
}

export async function get<T>(user: User, name: string) {
    logger.info('getting a user preference', name);

    const response = await new APIRequest<UsersPreferenceDataInterface<T>>({
        path: `users/${user.id}/preferences/${name}`,
        target: TargetEnv.CURRENT_ORG,
    }).request();

    return response.data.value;
}

export async function store(user: User, name: string, value: RequestParamInterface) {
    logger.info('storing a user preference', name);

    return new APIRequest({
        path: `users/${user.id}/preferences/${name}`,
        method: 'put',
        params: value,
        target: TargetEnv.CURRENT_ORG,
    }).request();
}

export async function remove(user: User, name: string) {
    logger.info('deleting a user preference', name);

    return new APIRequest({
        path: `users/${user.id}/preferences/${name}`,
        method: 'delete',
        target: TargetEnv.CURRENT_ORG,
    }).request();
}
