import APIRequest from './Request';
import Logger from 'services/logger';

const logger = Logger.get('orgConfig');

export interface OrgConfigDataInterface {
    [key: string]: string;
}

export async function get() {
    logger.info('getting the org config data');

    const response = await new APIRequest<OrgConfigDataInterface>({
        path: `org/config`,
    }).request();

    return response.data;
}

export async function store(name: string, value: string) {
    logger.info('storing an org config', name);

    const response = await new APIRequest<string>({
        path: `org/config/${name}`,
        method: 'put',
        params: { [name]: value },
    }).request();

    return response.data;
}

export async function storeMultiple(config: OrgConfigDataInterface) {
    const keys = Object.keys(config);

    const requests = keys.map(key => store(key, config[key]));

    return Promise.all(requests);
}
