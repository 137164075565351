import APIRequest from './Request';
import Logger from 'services/logger';
import Sample, { SampleDataInterface } from 'models/Sample';
import TimeInterval from 'models/TimeInterval';
import { formatTimeAsync } from 'services/timezone';
import { getHostAttribute } from 'services/api/hosts';

const logger = Logger.get('APISamples');

export async function purge() {
    return new APIRequest<void>({
        path: 'queries/samples/purge',
        method: 'post',
    }).request();
}

export interface SamplesFilterInterface {
    [key: string]: string;
    db: string;
    user: string;
    origin: string;
    latencyMin: string;
    latencyMax: string;
    connectionId: string;
    limit: string;
}

export interface SampleSearchQueryInterface {
    attribute: string;
    operator: string;
    value: string | number;
}

interface SampleSearchDataInterface {
    digests: Record<string, string>;
    samples: SampleDataInterface[];
    hasMore: boolean;
}

export async function search(hostFilter: string, interval: TimeInterval, filters: SamplesFilterInterface) {
    const { limit, ...rest } = filters;

    const query = urlEncodeSearch(rest);

    try {
        const result = await new APIRequest<SampleSearchDataInterface>({
            path: 'queries/samples/search',
            method: 'get',
            params: {
                ...interval.asParams(),
                host: hostFilter,
                limit,
                query: JSON.stringify({ filters: query }),
            },
        }).request();

        return {
            samples: result.data.samples.map((data: SampleDataInterface) => {
                const sample = new Sample(data);
                sample.queryDigest = result.data.digests[sample.queryId];

                return sample;
            }),
            hasMore: result.data.hasMore,
        };
    } catch (e) {
        logger.error(e);
    }

    return { samples: [], hasMore: false };
}

function urlEncodeSearch(searchData: Omit<SamplesFilterInterface, 'limit'>) {
    let query: SampleSearchQueryInterface[] = [];

    for (const key in searchData) {
        if (!searchData[key]) {
            continue;
        }

        query.push({
            attribute: key,
            operator: 'contains',
            value: searchData[key],
        });
    }

    query = query.map(entry => {
        if (entry.attribute.startsWith('latency')) {
            entry.operator = entry.attribute === 'latencyMin' ? '>' : '<';
            entry.attribute = 'latency';
            entry.value = parseFloat(entry.value as string);
        }

        return entry;
    });

    return query;
}

export async function get(
    hostFilter: string,
    interval: TimeInterval,
    query: string,
    { nest = 'text', limit = 400 } = {}
) {
    const result = await new APIRequest<SampleDataInterface[]>({
        path: 'queries/samples',
        method: 'get',
        params: {
            ...interval.asParams(),
            host: hostFilter,
            limit: limit.toString(),
            query,
            nest,
        },
    }).request();

    return result.data.map(sample => new Sample(sample));
}

export async function encodeSamplesForExport(interval: TimeInterval, samples: Sample[]) {
    return Promise.all(
        samples.map(async sample => {
            const sampleData: Record<string, string> = {
                'Query Digest': sample.queryDigest,
                Database: sample.db,
                User: sample.user,
                Origin: sample.origin,
                'Inventory Hostname': (await getHostAttribute(interval, sample.hostId, 'name')) as string,
                Timestamp: await formatTimeAsync(sample.timestamp),
                Latency: sample.latencyQuantity.toString(),
                'Connection ID': sample.connectionId,
            };

            return sampleData;
        })
    );
}
