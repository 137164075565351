import cn from 'helpers/classname';
import Icon from 'components/icons/Icon';
import Logger from 'services/logger';
import { byAttribute as sortByAttribute, SortByDirectionType } from 'helpers/sort';
import { FC, Fragment, ReactNode, useState, useEffect } from 'react';

const logger = Logger.get('SortableTable');

export interface ColumnInterface {
    name: string;
    column: string;
    sortable: boolean;
    className?: string;
}

interface TableHeaderPropsInterface {
    column: ColumnInterface;
    direction: SortByDirectionType;
    onClick: (arg0: ColumnInterface) => void;
    sortBy: string;
}

interface SortableTablePropsInterface<ItemType> {
    async?: boolean;
    children: (arg0: ItemType) => ReactNode;
    className?: string;
    columns: ColumnInterface[];
    defaultDirection?: SortByDirectionType;
    defaultSortBy?: string;
    items: ItemType[];
    sort?: (arg0: ItemType[], arg1: string, arg2: SortByDirectionType) => Promise<ItemType[]>;
}

interface ObjectWithIdInterface {
    id?: string | number;
}

const TableHeader: FC<TableHeaderPropsInterface> = ({ column, direction, onClick, sortBy }) => {
    if (!column.sortable) {
        return <th className={column.className}>{column.name}</th>;
    }

    const sorted = sortBy === column.column;
    const sortedAsc = sorted && direction === 'asc';
    const sortedDesc = sorted && direction === 'desc';

    return (
        <th
            className={`sortable${cn('sorted', sorted)}${cn('reverse', sortedDesc)} ${column.className}`}
            onClick={() => onClick(column)}
            data-testid={`sort-by-${column.column}`}
        >
            {column.name}
            {!sorted && <Icon icon="sort" className="sorted__icon" />}
            {sortedAsc && <Icon icon="sorted-up" className="sorted__icon sorted__icon--sorted" />}
            {sortedDesc && <Icon icon="sorted-down" className="sorted__icon sorted__icon--reverse" />}
        </th>
    );
};

const SortableTable = <ItemType extends ObjectWithIdInterface>({
    async = false,
    className,
    columns,
    children,
    items,
    defaultDirection = 'desc',
    defaultSortBy = '',
    sort,
    ...etc
}: SortableTablePropsInterface<ItemType>) => {
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [direction, setDirection] = useState<SortByDirectionType>(defaultDirection);
    const [sortedItems, setSortedItems] = useState(items);
    const [sorting, setSorting] = useState(false);

    if (async && !sort) {
        logger.error('You must provide a sort function when asynchronically sorting');
    }

    useEffect(() => {
        if (!sortBy) {
            setSortedItems(items);
            return;
        }

        if (async && sort) {
            setSorting(true);

            sort(items, sortBy, direction)
                .then(setSortedItems)
                .finally(() => setSorting(false));
        } else {
            setSortedItems(sortByAttribute(items, sortBy, direction));
        }
    }, [async, sort, items, sortBy, direction]);

    function handleSortBy(column: ColumnInterface) {
        if (sorting) {
            return;
        }

        if (sortBy === column.column) {
            setDirection(direction === 'asc' ? 'desc' : 'asc');
        } else {
            setDirection(defaultDirection);
        }

        setSortBy(column.column);
    }

    return (
        <table className={className} {...etc}>
            <thead>
                <tr>
                    {columns.map(column => (
                        <TableHeader
                            key={column.column}
                            column={column}
                            sortBy={sortBy}
                            onClick={handleSortBy}
                            direction={direction}
                        />
                    ))}
                </tr>
            </thead>
            <tbody>
                {sortedItems.map((item, index) => (
                    <Fragment key={item.id || index}>{children(item)}</Fragment>
                ))}
            </tbody>
        </table>
    );
};

export default SortableTable;
