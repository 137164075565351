import Env from 'models/Env';
import { ActionType, userIsAllowedTo } from 'services/security/accessControl';
import { FlaggedFeature } from 'services/serviceLevel/configuration';

export class NavItem {
    children: NavItem[] = [];

    constructor(
        readonly name: string,
        readonly url: string,
        readonly icon?: string,
        readonly security?: ActionType,
        readonly feature?: FlaggedFeature
    ) {}

    get accessible() {
        if (this.security && !userIsAllowedTo(this.security)) {
            return false;
        }

        return true;
    }
}

class NavItems {
    private env?: Env;

    constructor(env?: Env) {
        this.env = env;
    }

    getBaseItems(): NavItem[] {
        return [
            new NavItem('Settings', `${this.baseUrl}/settings/overview`, 'settings'),
            new NavItem('Sign Out', `${this.baseUrl}/logout`, 'logout'),
        ];
    }

    private getEnvironmentItems(): NavItem[] {
        const healthItems = [
            new NavItem('Best Practices', `${this.baseUrl}/best-practices`),
            new NavItem('Events', `${this.baseUrl}/events`),
            new NavItem('Faults', `${this.baseUrl}/faults`),
        ];

        const health = new NavItem('Health', '', 'health');
        health.children = healthItems;

        const inventoryItems = [
            new NavItem('Machines', `${this.baseUrl}/inventory/machines`),
            new NavItem('Groups', `${this.baseUrl}/inventory/groups`, undefined, undefined, 'nodeGroupsDashboard'),
            new NavItem('Agents', `${this.baseUrl}/inventory/agents`),
        ];

        const inventory = new NavItem('Inventory', '', 'inventory');
        inventory.children = inventoryItems;

        const chartsItems = [
            new NavItem('Dashboards', `${this.baseUrl}/charts/dashboards`),
            new NavItem('Metrics', `${this.baseUrl}/charts/metrics`),
        ];

        const charts = new NavItem('Charts', '', 'charts');
        charts.children = chartsItems;

        const items = [
            new NavItem('Summary', `${this.baseUrl}`, 'summary'),
            new NavItem('Explorer', `${this.baseUrl}/explorer`, 'explorer', undefined, 'explorer'),
            new NavItem('Profiler', `${this.baseUrl}/profiler`, 'profiler'),
            new NavItem('Deadlocks', `${this.baseUrl}/deadlocks`, 'deadlocks', undefined, 'deadlocks'),
            health,
            inventory,
            new NavItem('Queries', `${this.baseUrl}/queries`, 'queries'),
            new NavItem('Samples', `${this.baseUrl}/samples`, 'samples', 'seeQuerySamples'),
            charts,
            new NavItem('Notebooks', `${this.baseUrl}/notebooks`, 'notebook', undefined, 'notebooks'),
            new NavItem('Alerts', `${this.baseUrl}/alerts`, 'alerts'),
        ];

        return items;
    }

    get baseUrl() {
        return this.env?.url || '';
    }

    getItems() {
        if (this.env) {
            return this.getEnvironmentItems();
        }
        return [];
    }
}

export default NavItems;
