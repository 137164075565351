import { scaleLinear } from 'd3';
import * as React from 'react';

interface BackgroundBarPropsInterface {
    val: number;
    min?: number;
    max: number;
    className: string;
}

function buildScale(min: number, max: number) {
    return scaleLinear().domain([min, max]).range([0, 1]);
}

const BackgroundBar: React.FC<BackgroundBarPropsInterface> = ({ val, min = 0, max, className = '' }) => {
    const scale = buildScale(min, max);

    return <PercentageBackgroundBar percentage={scale(val) || 0} className={className} />;
};

export const PercentageBackgroundBar: React.FC<{ percentage: number; className: string }> = ({
    percentage,
    className,
}) => (
    <div
        className={`vc-viz-background-bar ${className}`}
        style={{ backgroundSize: `${percentage * 100}% 100%` }}
        data-testid="background-bar-visualization"
    />
);

export default BackgroundBar;
