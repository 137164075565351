import { createContext, useState, useEffect, FC } from 'react';
import { get as getContext } from 'services/context';
import { toggleInternals } from 'services/serviceLevel/featureFlag';

export const FeatureContext = createContext(false);

const FeatureToggler: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [showInternals, setShowInternals] = useState(false);
    const bootstrap = getContext();

    useEffect(() => {
        // We don't want non-brainiacs to bypass the feature flags.
        if (bootstrap.isBrainiac() === false) {
            return;
        }

        document.addEventListener('keydown', e => {
            if (e.key === '\\') {
                setShowInternals(toggleInternals());
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (bootstrap.isBrainiac() === false) {
        return <>{children}</>;
    }

    return <FeatureContext.Provider value={showInternals}>{children}</FeatureContext.Provider>;
};

export default FeatureToggler;
