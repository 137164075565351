import Alert, { AlertTypes } from 'components/messages/Alert';
import BouncerButton from 'components/form/BouncerButton';
import Actions from 'components/form/Actions';
import Modal from 'components/modals/Modal';
import { FC, useState, SyntheticEvent } from 'react';
import { useFinalActionModal } from 'components/context/FinalActionModal';

enum FinalActionModalStatus {
    READY = 'ready',
    ACCEPTED = 'accepted',
    SUBMITTING = 'submitting',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface FinalActionModalInterface {
    visible: boolean;
    onSubmit: () => Promise<void> | void;
    title: string;
    confirmMsg: string;
    onClose?: () => void;
    buttonText?: string;
    showWarning?: boolean;
}

const FinalActionModal: FC<FinalActionModalInterface> = ({
    visible,
    onSubmit,
    title,
    confirmMsg,
    onClose = () => {},
    buttonText = 'Confirm',
    showWarning = true,
}) => {
    const [status, setStatus] = useState<FinalActionModalStatus>(FinalActionModalStatus.READY);

    const { closeModal } = useFinalActionModal();

    async function handleSubmit(e: SyntheticEvent<HTMLFormElement>) {
        e.preventDefault();

        setStatus(FinalActionModalStatus.SUBMITTING);

        try {
            await onSubmit();
            setStatus(FinalActionModalStatus.SUCCESS);
        } catch {
            setStatus(FinalActionModalStatus.ERROR);
        } finally {
            closeModal();
        }
    }

    const handleClose = () => {
        onClose();
        closeModal();
    };

    function handleCheckboxChange() {
        setStatus(
            status !== FinalActionModalStatus.ACCEPTED ? FinalActionModalStatus.ACCEPTED : FinalActionModalStatus.READY
        );
    }

    return (
        <Modal title={title} visible={visible} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <div className="p3">
                    {showWarning && (
                        <Alert type={AlertTypes.WARNING} className="mb2" sticky>
                            Be careful, this action CANNOT be undone.
                        </Alert>
                    )}
                    <p className="line-height-120 py2">{confirmMsg}</p>

                    <p className="line-height-120 mt2">
                        <label htmlFor="confirm-check" data-testid="confirm-check">
                            <input
                                type="checkbox"
                                id="confirm-check"
                                checked={
                                    status === FinalActionModalStatus.ACCEPTED ||
                                    status === FinalActionModalStatus.SUBMITTING
                                }
                                onChange={handleCheckboxChange}
                            />
                            <span>I know what I'm doing</span>
                        </label>
                    </p>
                </div>

                <Actions className="form__actions--dialog p3 bg-white right-align full-width">
                    <button type="button" className="primary-grey mr2" onClick={handleClose}>
                        Cancel
                    </button>
                    <BouncerButton
                        bounce={status === FinalActionModalStatus.SUBMITTING}
                        disabled={status !== FinalActionModalStatus.ACCEPTED}
                        type="submit"
                        data-testid="confirm-submit"
                        className="primary-red nowrap deleteEnvForm__submit"
                    >
                        {buttonText}
                    </BouncerButton>
                </Actions>
            </form>
        </Modal>
    );
};

export default FinalActionModal;
