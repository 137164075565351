export interface QueryDataInterface {
    digest: string;
    firstSeen: number;
    id: string;
    lastSeen: number;
    tags?: Record<string, string[]>[];
}

class Query {
    digest: string;
    firstSeen: number;
    id: string;
    lastSeen: number;
    tags?: Record<string, string[]>[];

    constructor({ digest, firstSeen, id, lastSeen, tags }: QueryDataInterface) {
        this.digest = digest;
        this.firstSeen = firstSeen;
        this.id = id;
        this.lastSeen = lastSeen;
        this.tags = tags;
    }
}

export default Query;
