import { FC, useEffect, useState } from 'react';
import ModuleTitle from '../ModuleTitle';
import { get } from 'services/api/samples';
import { getPlanbook } from 'services/api/queries';
import Sample, { CPRInterface } from 'models/Sample';
import { useTimeInterval } from 'components/context/TimeInterval';
import { useHostFilter } from 'components/context/HostFilter';
import useDisplayMessage from 'hooks/useDisplayMessage';
import Diagram from 'components/visualizations/vep/Diagram';
import VisualExplainPlanModel, { NodeInterface } from 'models/VisualExplainPlan';
import { useParams } from 'react-router-dom';
import Bouncer from 'components/icons/Bouncer';
import Sidebar from './Sidebar';
import FeatureLink from 'components/url/FeatureLink';
import { hexaToDecimal } from 'helpers/numbers';
import DiagramErrorBoundary from './DiagramErrorBoundary';
import useCommandBarToggler from 'hooks/useCommandBarToggler';

const VisualExplainPlan: FC = () => {
    const { interval } = useTimeInterval();
    const { filter } = useHostFilter();
    const { error } = useDisplayMessage();
    const { enable, disable } = useCommandBarToggler();

    const [sample, setSample] = useState<Sample | undefined>();
    const [vepModel, setVEPModel] = useState<VisualExplainPlanModel | undefined>();

    const { query } = useParams<{ query: string }>();
    const [loading, setLoading] = useState(true);
    const [selectedNode, setSelectedNode] = useState<NodeInterface | null>(null);

    const getCPR = () => {
        get(filter, interval, hexaToDecimal(`${query}`), {
            nest: 'cpr,text',
            limit: 1,
        })
            .then((samples: Sample[]) => {
                const selectedSample = samples[0];

                setSample(selectedSample);

                if (!selectedSample.cpr) {
                    error('The selected sample is not valid to display a Visual Explain Plan');
                    setLoading(false);
                    return;
                }

                const database = selectedSample.cpr.host.type;
                const version = selectedSample.cpr.host.dbversion;

                return getPlanbook(database, version).then(planbook => {
                    setLoading(false);
                    const model = new VisualExplainPlanModel(selectedSample.cpr as CPRInterface, planbook);

                    setVEPModel(model);
                });
            })
            .catch(() => {
                setLoading(false);
                error('There was an error while trying to obtain the data for this query');
            });
    };

    useEffect(() => {
        disable(); // disable command bar on the first render

        getCPR();

        return () => {
            enable();
        }; // re-enable command bar on unmount
    }, []); //eslint-disable-line

    return (
        <div className="vc-viz-visual-explain-plan">
            <ModuleTitle contextualHelpId="Visual Explain Plan">
                <div className="flex fz12 mb2 mainColor">
                    <FeatureLink to="queries">All Queries</FeatureLink>
                    {sample && <span className="ml1">{'> ' + query}</span>}
                </div>
                Visual Explain Plan
            </ModuleTitle>
            {!loading && sample && (
                <div className="tile p3 query-box">
                    <h2 className="bold mb2 fz18">Query</h2>
                    <span className="monospace">{sample.text}</span>
                </div>
            )}

            {!loading && vepModel && (
                <section className="flex flex-1 tile relative overflow-hidden">
                    <DiagramErrorBoundary>
                        <>
                            <Diagram
                                nodes={vepModel.nodes}
                                edges={vepModel.edges}
                                selectedNode={selectedNode}
                                onNodeClick={node => setSelectedNode(prev => (prev?.id === node.id ? null : node))}
                            />
                            <Sidebar node={selectedNode} onClose={() => setSelectedNode(null)} />
                        </>
                    </DiagramErrorBoundary>
                </section>
            )}
            {loading && <Bouncer className="my4 flex full-width justify-center" />}
        </div>
    );
};

export default VisualExplainPlan;
