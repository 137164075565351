export interface FeatureUrlInterface {
    local: boolean;

    toString(): string;
}

export default class FeatureUrl implements FeatureUrlInterface {
    readonly local: boolean;
    constructor(private url: string, local: boolean) {
        // Is local also if the URL is not absolute. This way, components won't
        // use react-router's <Link> for these URLs, which would generate an
        // invalid link.
        this.local = local && url.startsWith('/') && !url.startsWith('//');
    }

    toString() {
        return this.url;
    }
}
