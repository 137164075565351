import { virtualPageView as gtmPageView, interaction as gaInteraction, GTMEventPropertiesInterface } from './gtm';
import Logger from 'services/logger';

const logger = Logger.get('Analytics');

type AnalyticsInteraction =
    | 'add-host-wizard'
    | 'auto-refresh'
    | 'best-practices'
    | 'charts'
    | 'dashboards'
    | 'events-on-charts'
    | 'explorer'
    | 'export'
    | 'fullscreen'
    | 'hosts-selector'
    | 'in-app-docs'
    | 'indicator-meaning'
    | 'interval-arrows'
    | 'inventory'
    | 'learn-more'
    | 'markdown-chart'
    | 'metrics'
    | 'multiple-invite'
    | 'notebooks'
    | 'notifications'
    | 'profiler'
    | 'queries'
    | 'query'
    | 'recommendations'
    | 'samples-dashboard'
    | 'sharer'
    | 'time-picker'
    | 'url-shortener';

function trackInteractionInGoogleAnalytics(gaCategory: string, gaAction: string, gaLabel?: string) {
    const properties: GTMEventPropertiesInterface = {
        category: gaCategory,
    };

    if (gaLabel) {
        properties.label = gaLabel;
    }

    gaInteraction(gaAction, properties);
}

function trackInteraction(feature: AnalyticsInteraction, action: string, data?: string) {
    logger.debug(`Tracking interaction ${feature} ${action} ${data}`);

    trackInteractionInGoogleAnalytics(feature, action, data);
}

/**
 * Returns an instance of an interaction reporter function with the feature name bound to it.
 * Usage:
 *   const interactionReporter = getInteractionReporter('your-feature-name');
 */
export const getInteractionReporter = (feature: AnalyticsInteraction) => {
    return trackInteraction.bind(null, feature);
};

export const virtualPageView = (path: string) => {
    gtmPageView(path);
};
