import CopyToClipboard from 'components/clipboard/CopyToClipboard';
import APIToken from 'models/APIToken';
import * as React from 'react';

const APITokenHash: React.FC<{
    token: APIToken;
    onDismiss: (token: APIToken) => void;
}> = ({ token, onDismiss }) => {
    return (
        (token.hash && (
            <>
                <input
                    className="vc-table--table-token__rotation__input-copy"
                    id="token-hash"
                    readOnly={true}
                    value={token.hash}
                    type="text"
                    data-testid="api-tokens-new-token-hash"
                />

                <CopyToClipboard target="token-hash"></CopyToClipboard>
                <p className="spacing regular fz12 grey3 mt2 pb2">
                    Make sure to copy your new token now. You won't be able to see it again.{' '}
                    <button
                        onClick={() => onDismiss(token)}
                        className="link mr1 inline-flex"
                        data-testid="api-tokens-new-token-hash-dismiss"
                    >
                        Dismiss
                    </button>
                    this message.
                </p>
            </>
        )) ||
        null
    );
};

export default APITokenHash;
