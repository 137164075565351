import Loader, { LoaderTypes } from 'components/loaders/Loader';
import * as React from 'react';
import { TopicInterface } from 'services/documentation';
import useTopics from 'hooks/documentation/useTopics';

interface TopicsBrowserProps {
    onClick: (topic: TopicInterface) => void;
}

const TopicsBrowser: React.FC<TopicsBrowserProps> = ({ onClick }) => {
    const { state, topics } = useTopics();

    return (
        <div className="topics-wrap mt1 flex-grow-1 flex flex-column px3 mt2">
            <h3 className="regular fz16 pb2">Browse Topics</h3>

            <ul>
                {topics.map((topic, index) => (
                    <li key={index} className="p2">
                        <button
                            className="h3 regular link"
                            data-testid="in-app-documentation-topic"
                            onClick={() => onClick(topic)}
                        >
                            {topic.title}
                        </button>
                    </li>
                ))}

                {/* To be replaced by a skeleton loader */}
                {state === 'loading' && <Loader type={LoaderTypes.PARAGRAPH}></Loader>}

                <li className="p2">
                    <a
                        href="https://dpm.statuspage.io"
                        className="h3 regular"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Get Application Status
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default TopicsBrowser;
