import Env from 'models/Env';
import Environment from './Item';
import { FC, useState, useEffect } from 'react';

interface EnvironmentListInterface {
    filter: string;
    clearFilter: () => void;
    envs: Env[];
    editEnv: (env: Env) => void;
}

const EnvironmentList: FC<EnvironmentListInterface> = ({ filter = '', clearFilter, envs, editEnv }) => {
    const [filteredEnvs, setFilteredEnvs] = useState<Env[]>(envs);

    useEffect(() => {
        setFilteredEnvs(envs.filter(env => env.name.toLowerCase().includes(filter.toLowerCase())));
    }, [envs, filter]);

    return (
        <>
            <ul className="view--home__env-list list-reset">
                {filteredEnvs.map(env => (
                    <Environment env={env} key={env.id} edit={editEnv} />
                ))}
            </ul>

            {filter && filteredEnvs.length === 0 && (
                <div className="flex justify-center flex-wrap fz15">
                    <p className="flex justify-center full-width dark">
                        No environments found with filter &apos;{filter}&apos;
                    </p>
                    <span onClick={() => clearFilter()} className="mt1 cursor-hand mainColor hoverable">
                        Clear Filter
                    </span>
                </div>
            )}

            {!filter && filteredEnvs.length === 0 && (
                <div className="flex justify-center flex-wrap fz15">
                    <p className="flex justify-center full-width dark">There are no environments to show.</p>
                </div>
            )}
        </>
    );
};

export default EnvironmentList;
