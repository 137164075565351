import { useState, useEffect } from 'react';
import SettingsLayout from '../Layout';
import { get as getUsers } from 'services/api/users';
import Logger from 'services/logger';
import User from 'models/User';
import Bouncer from 'components/icons/Bouncer';
import PeopleList from './List';
import useOrg from 'hooks/useOrg';
import { get as getTeams } from 'services/api/teams';
import useDisplayMessage from 'hooks/useDisplayMessage';
import Team from 'models/Team';
import InviteUsersModal from './InviteUsersModal';
import Icon from 'components/icons/Icon';
import { isUsingSSO } from 'services/settings/authentication';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';

const logger = Logger.get('SettingsPeople');

const SettingsPeople = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingTeams, setLoadingTeams] = useState(true);
    const [title, setTitle] = useState<string>('');
    const [people, setPeople] = useState<User[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [usingSSO, setUsingSSO] = useState(false);
    const org = useOrg();
    const { error } = useDisplayMessage();

    async function fetchData() {
        setIsLoading(true);
        setLoadingTeams(true);
        try {
            setUsingSSO(await isUsingSSO());
            const users = await getUsers();

            //we don't have to wait for teams to load in order to show the list of users
            getTeams({ withUsers: true, format: 'number' })
                .then(fetchedTeams => {
                    users.forEach(user => user.setTeams(fetchedTeams));
                    setTeams(fetchedTeams);
                })
                .catch(() => {
                    error('There was an error while trying to fetch the teams for the users');
                })
                .finally(() => {
                    setLoadingTeams(false);
                });

            setPeople(users);
        } catch (e) {
            logger.error('error while fetching the users');
            error('There was an error while trying to fetch the users');
            setTitle('');
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeletedUsers = (deletedUsers: User[]) => {
        const newPeople = people.filter(user => !deletedUsers.includes(user));
        setPeople(newPeople);
    };

    useEffect(() => {
        if (!people.length) {
            return;
        }
        setTitle(`${people.length} users in the ${org.name} organization`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [people]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const invitedUsersHandler = () => {
        fetchData();
    };

    return (
        <SettingsLayout
            title={title}
            metaTitle="People"
            headerContent={
                teams.length > 0 && (
                    <InviteUsersModal teams={teams} disabled={usingSSO} onSuccess={invitedUsersHandler} />
                )
            }
        >
            <FinalActionModalProvider>
                {isLoading && <Bouncer>loading...</Bouncer>}
                {!isLoading && (
                    <>
                        {usingSSO && (
                            <div className="h4 lighter mt4 mb3 flex" data-testid="people-sso-message">
                                <Icon icon="info" className="grey3 mr2" />
                                When using SSO, users aren't added or deleted from this page. To add or remove users, do
                                so from your identity provider.
                            </div>
                        )}
                        <div className="flex items-center mt2" data-testid="people-page">
                            {people.length > 0 && (
                                <PeopleList
                                    people={people}
                                    loadingTeams={isLoadingTeams}
                                    onDeleted={handleDeletedUsers}
                                    teams={teams}
                                    usingSSO={usingSSO}
                                />
                            )}
                        </div>
                    </>
                )}
            </FinalActionModalProvider>
        </SettingsLayout>
    );
};

export default SettingsPeople;
