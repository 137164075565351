import { useBootstrap } from 'components/context/Bootstrap';
import { AuthenticatedBootstrap } from 'models/Bootstrap';

const useUser = () => {
    const [{ bootstrap }] = useBootstrap();

    if (!(bootstrap instanceof AuthenticatedBootstrap)) {
        throw new Error('Trying to access the user within an unauthenticated context.');
    }

    return (bootstrap as AuthenticatedBootstrap).user;
};

export default useUser;
