const loadScript = (src: string, callback: () => void) => {
    const script = document.createElement('script');

    script.src = src;
    script.async = true;
    script.addEventListener('load', callback);

    document.body.appendChild(script);
};

export default loadScript;
