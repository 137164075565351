import NodeGroup from 'models/hosts/NodeGroup';

const MIN_REQUIRED_ITEMS = 300;
const ITEMS_PER_PAGE = 15;

type SortingDirection = 'asc' | 'desc';

type SortingColumn = 'name' | 'lastSeen';

interface InventoryPaginatorSortingInterface {
    column: SortingColumn;
    direction: SortingDirection;
}

export default class InventoryPaginator {
    pages: NodeGroup[][] = [];

    private currentPage = 0;

    constructor(
        private groups: NodeGroup[] = [],
        readonly hostsAmount: number = 0,
        private sorting: InventoryPaginatorSortingInterface,
        private paginationThreshold: number = MIN_REQUIRED_ITEMS,
        readonly pageLimit: number = ITEMS_PER_PAGE
    ) {
        this.sortBy();
    }

    sortBy(sorting?: InventoryPaginatorSortingInterface) {
        if (sorting) {
            this.sorting = sorting;
        }

        this.pages = this.generatePages(this.sort(this.groups));
    }

    goToPage(page: number) {
        if (page === this.currentPage + 1) {
            return;
        }

        this.currentPage = page - 1;
    }

    get itemsInCurrentPage() {
        return this.pages[this.currentPage];
    }

    private generatePages(groups: NodeGroup[]) {
        if (this.hostsAmount < this.paginationThreshold) {
            return [groups];
        }

        const pages: NodeGroup[][] = [[]];
        let page = 0;

        groups.reduce((hosts, group) => {
            const addedHosts = group.hosts.length + 1;

            if (hosts > 0 && hosts + addedHosts > this.pageLimit) {
                page += 1;
                pages.push([]);
                hosts = 0;
            }

            pages[page].push(group);

            return hosts + addedHosts;
        }, 0);

        return pages;
    }

    private sort(groups: NodeGroup[]) {
        return this.sorting.direction === 'asc'
            ? groups.sort((a, b) => (a.host[this.sorting.column] > b.host[this.sorting.column] ? 1 : -1))
            : groups.sort((a, b) => (a.host[this.sorting.column] > b.host[this.sorting.column] ? -1 : 1));
    }
}
