import { useEffect, useState } from 'react';
import { fetchRanks } from 'services/api/profiler/ranks';
import { RankInterface } from 'models/profiler/ranks';
import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';

export default function useRanks(): RankInterface[] {
    const { interval } = useTimeInterval();
    const { filter } = useHostFilter();

    const [ranks, setRanks] = useState<RankInterface[]>([]);

    useEffect(() => {
        fetchRanks(interval, filter).then(setRanks);
    }, [filter, interval]);

    return ranks;
}
