import Logger from 'services/logger';
import React, { createContext, useContext, useState, FC, useRef, useEffect, useCallback } from 'react';
import TimeInterval from 'models/TimeInterval';
import useUIStatus from 'hooks/useUIStatus';
import { Location } from 'history';
import { DEFAULT_INTERVAL } from 'services/time';

const logger = Logger.get('TimeIntervalContext');

interface IntervalContextInterface {
    interval: TimeInterval;
    set(from: number, until: number): void;
    toggleAutoRefresh(): void;
    enable(): void;
    disable(): void;
    autoRefreshEnabled: boolean;
    readOnly: boolean;
}

export const TimeIntervalContext = createContext<IntervalContextInterface | null>(null);

export const useTimeInterval = () => {
    const context = useContext(TimeIntervalContext);

    if (context === null) {
        throw new Error('Trying to access uninitialized context');
    }

    return context;
};

/**
 * The TimeIntervalProvider adds time interval context around a component tree.
 * By default, the members app will have a global TimeInterval context representing
 * the current time selection for the application.
 * SubTimeIntervalProvider can be nested to override values deeper within the tree,
 * for example when your component requires a custom time picker, different from
 * the global time.
 */
const TimeIntervalProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [uiStatus, setUIStatus] = useUIStatus(
        {
            ...DEFAULT_INTERVAL,
            autoRefreshEnabled: false,
        },
        {
            id: 'timepicker-interval',
            excludeStorageKeys: ['from', 'until'],
            clearOnRouteChange: false,
        },
        handleRouteChange
    );

    let initialValue: TimeInterval;
    try {
        initialValue = new TimeInterval(uiStatus.from, uiStatus.until);
    } catch (e) {
        initialValue = TimeInterval.createDefaultOffsetInterval();
        setUIStatus({ from: initialValue.from, until: initialValue.until });
    }

    const [interval, setInterval] = useState(initialValue);
    const [enabled, setEnabled] = useState(true);

    const set = useCallback(
        (from: number, until: number) => {
            const newInterval = new TimeInterval(from, until);

            setUIStatus({ from, until });

            setInterval(newInterval);
        },
        [setUIStatus]
    );

    function handleRouteChange(location: Location) {
        const params = new URLSearchParams(location.search);
        const from = parseInt(`${params.get('from')}`, 10);
        const until = parseInt(`${params.get('until')}`, 10);

        if (!from && !until) {
            return;
        }

        if (from !== interval.from || until !== interval.until) {
            let newInterval: TimeInterval;

            try {
                newInterval = new TimeInterval(from, until);
            } catch (e) {
                logger.error(e);

                return;
            }

            setInterval(newInterval);
        }
    }

    const timeoutRef = useRef<NodeJS.Timeout>(setTimeout(() => {}, 1000));
    const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(uiStatus.autoRefreshEnabled);

    const reload = useCallback(() => {
        clearTimeout(timeoutRef.current);
        if (autoRefreshEnabled) {
            timeoutRef.current = setTimeout(() => {
                set(interval.from, interval.until);
            }, 60 * 1000);
        }
    }, [autoRefreshEnabled, interval, set]);

    const toggleAutoRefresh = () => {
        setAutoRefreshEnabled(!autoRefreshEnabled);
        setUIStatus({ autoRefreshEnabled: !autoRefreshEnabled });
    };

    const enable = () => setEnabled(true);
    const disable = () => {
        setEnabled(false);

        if (autoRefreshEnabled) {
            toggleAutoRefresh();
        }
    };

    useEffect(() => {
        reload();
    }, [autoRefreshEnabled, reload]);

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current);
    }, []);

    return (
        <TimeIntervalContext.Provider
            value={{ interval, set, toggleAutoRefresh, autoRefreshEnabled, readOnly: !enabled, enable, disable }}
        >
            {children}
        </TimeIntervalContext.Provider>
    );
};

export const SubTimeIntervalProvider: FC<{ timeInterval: TimeInterval; children?: React.ReactNode }> = ({
    children,
    timeInterval,
}) => {
    const [interval, setInterval] = useState(timeInterval);

    const set = (from: number, until: number) => {
        setInterval(new TimeInterval(from, until));
    };

    const toggleAutoRefresh = () => {};
    const autoRefreshEnabled = false;
    const enable = () => {};
    const disable = () => {};

    return (
        <TimeIntervalContext.Provider
            value={{ interval, set, toggleAutoRefresh, autoRefreshEnabled, readOnly: false, enable, disable }}
        >
            {children}
        </TimeIntervalContext.Provider>
    );
};

export default TimeIntervalProvider;
