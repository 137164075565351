interface HostOptionsInteface {
    'query-capture'?: string;
    'replica-state'?: string;
    role?: string;
}

type HostType = 'os' | 'mysql' | 'pgsql' | 'mongo' | 'redis' | 'vitess' | 'mssql';

export enum HostCaptureModes {
    SNIFF = 'sniff',
    POLL = 'poll',
    SLOWLOG = 'slow-log',
    QUERYLOG = 'query-log',
}

export interface HostFeatureDataInterface {
    agent: string;
    description: string;
    feature: string;
    host: number;
    severity: string;
    state: string;
    state_extra: string;
}

export interface HostDataInterface {
    bindings: string[] | null;
    cluster?: number;
    created: number;
    deleted: number;
    description: string;
    id: number;
    lastSeen: number;
    name: string;
    options: HostOptionsInteface;
    os: string;
    parent: number;
    status: number;
    tags: string[];
    type: HostType;
    updated: number;
    uuid: string;
    arch?: string;
    children?: HostDataInterface[];
    rootHost?: boolean;
    hidden?: boolean;
    isNameUnique: boolean;
}

export default class Host {
    bindings: string[] | null;
    cluster?: number;
    created: number;
    deleted: number;
    id: number;
    lastSeen: number;
    dataName: string;
    description: string;
    options: HostOptionsInteface;
    os: string;
    parent?: Host;
    status: number;
    tags: string[];
    type: HostType;
    updated: number;
    uuid: string;
    arch?: string;
    children?: HostDataInterface[];
    rootHost: boolean;
    isHidden: boolean;
    isNameUnique: boolean;

    level = 0;

    constructor(data: HostDataInterface, parent?: HostDataInterface) {
        this.bindings = data.bindings;
        this.cluster = data.cluster;
        this.created = data.created;
        this.deleted = data.deleted;
        this.description = data.description;
        this.id = data.id;
        this.lastSeen = data.lastSeen;
        this.dataName = data.name;
        this.options = { ...data.options };
        this.os = data.os;
        this.status = data.status;
        this.tags = data.tags ? [...data.tags] : [];
        this.type = data.type;
        this.updated = data.updated;
        this.uuid = data.uuid;
        this.children = data.children;
        this.arch = data.arch;
        this.rootHost = data.rootHost || false;
        this.parent = parent ? new Host(parent) : undefined;
        this.isHidden = data.hidden || false;
        this.isNameUnique = data.isNameUnique;
    }

    get name() {
        let name = this.dataName;

        if (!this.isNameUnique && this.bindings?.length) {
            name += ` at ${this.bindings.join(', ')}`;
        }

        return name;
    }

    set name(newName: string) {
        this.dataName = newName;
    }

    get captureMode() {
        if ('os' === this.type) {
            // OS hosts do not have a capture mode
            return null;
        }

        // In some cases the api is not returning a capturing mode, we need to assume poll in that case
        return this.options['query-capture'] || HostCaptureModes.POLL;
    }

    /**
     * A database host (part or not part of a group)
     */
    get isDatabase() {
        return !this.isOs && !!this.parent;
    }

    get isDeleted() {
        return !!this.deleted;
    }

    /**
     * A "virtual" host representing a cluster or a shard
     */
    get isGroup() {
        return 'os' !== this.type && !this.parent;
    }

    get isOs() {
        return 'os' === this.type;
    }

    /**
     * The role of the host, as a node inside a group.
     *
     * @type string
     *
     * For Mongo it's any of: "mongod" (regular node), "mongos" (proxy) or "config-server" (dictionary for the shards).
     * @see https://github.com/VividCortex/agents/issues/3212
     *
     * For PG "pooler", "citus", "citus-coordinator", "both". We fix that inconsistency by ignoring them here and moving those values into the roleTags property.
     * @see https://github.com/VividCortex/artifacts/issues/1205
     *
     */
    get role() {
        return this.options?.role;
    }

    /**
     * Role tags
     * Additional role information represented as tags in the UI
     *
     * @type array
     *
     * For mongo, the replica state is represented here ("primary", "secondary"). It only applies for regular nodes (role="mongod")
     * @see https://github.com/VividCortex/agents/issues/3212
     * @see https://docs.mongodb.com/manual/reference/replica-states/
     *
     * For PG, also the replica state is mapped here
     */
    get roleTags() {
        return this.options['replica-state']?.split(',').filter(state => !!state);
    }
}
