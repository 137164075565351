import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { handlePageChange } from 'services/status/pageStatus';

const UIStatusParameterHandler: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        // We need to maintain the same params storage per module to keep all UIStatus instances in sync
        handlePageChange(new URLSearchParams(location.search));
    }, [history.location.pathname]); //eslint-disable-line

    return null;
};

export default UIStatusParameterHandler;
