import * as React from 'react';
import Alert, { AlertTypes } from 'components/messages/Alert';
import Icon from 'components/icons/Icon';

const EmailPreferenceBulkSubscribe: React.FC<{
    onSubscribe: () => void;
    onUnsubscribe: () => void;
    showError: boolean;
}> = ({ onSubscribe, onUnsubscribe, showError }) => {
    return (
        <div className="lighter mt3" data-testid="email-pref-bulk-subscribe">
            <div className="flex items-center justify-end border-bottom border-color-grey1 py3 pr3">
                <div
                    className="mainColor cursor-hand email-pref-bulk__subscription"
                    data-testid="bulk-unsubscribe"
                    onClick={onUnsubscribe}
                >
                    Unsubscribe All
                </div>
                <div
                    className="mainColor cursor-hand ml4 email-pref-bulk__subscription"
                    data-testid="bulk-subscribe"
                    onClick={onSubscribe}
                >
                    Subscribe All
                </div>
            </div>
            <Alert type={AlertTypes.ERROR} className="mb3" visible={showError} sticky>
                <Icon icon="error" className="white inline-flex mr2 h2"></Icon> Updates for some environments failed.
                Please review and try again.
            </Alert>
        </div>
    );
};

export default EmailPreferenceBulkSubscribe;
