const versionPattern = /[\d]+\.[\d]+\.[\d]+/;
const outerRegex = /0*(\d+)/;
const innerRegex = /\.0*(\d+)/g;

/**
 * Removes all padding zeroes from version parts
 */
const agentsVersion = (version: string) => {
    if (!versionPattern.test(version)) {
        return version;
    }

    return version.replace(outerRegex, '$1').replace(innerRegex, '.$1');
};

export default agentsVersion;
