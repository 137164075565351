export type ExportDataType = Record<string, string | number>;

export function buildJSON(data: ExportDataType[]) {
    return JSON.stringify(data, undefined, 4);
}

/**
 * Builds a CSV string using the keys of the array as columns.
 */
export function buildCSV(data: ExportDataType[]) {
    const hasQuotes = (str: string) => `${str}`.indexOf('"') >= 0;

    let result = '';
    const columnDelimiter = ',';
    const lineDelimiter = '\n';

    const keys = Object.keys(data[0]);
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
        keys.forEach(function (key, index) {
            if (index > 0) {
                result += columnDelimiter;
            }

            let value = item[key];

            if (typeof value === 'string' && hasQuotes(value)) {
                value = value.replace(/"/g, '""');
            }

            result += `"${value}"`;
        });
        result += lineDelimiter;
    });

    return result;
}

export function downloadFile(file: Blob, title: string) {
    // MDN does not recommend to use msSaveOrOpenBlob
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveOrOpenBlob

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', URL.createObjectURL(file));
    downloadLink.setAttribute('target', '_self');
    downloadLink.setAttribute('download', title);

    //this is needed for it to work in FF
    document.getElementsByTagName('body')[0].appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
}

export function jsonExport(data: ExportDataType[], title: string) {
    const json = buildJSON(data);
    const blob = new Blob([json], { type: 'application/json' });

    downloadFile(blob, title);
}

export function csvExport(data: ExportDataType[], title: string) {
    const csv = buildCSV(data);
    const blob = new Blob([csv], { type: 'text/csv' });

    downloadFile(blob, title);
}
