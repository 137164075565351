import APIRequest from './Request';
import Logger from 'services/logger';
import Integration, { APIIntegrationInterface } from 'models/Integration';

const logger = Logger.get('envConfig');

export interface APIDestinationInterface {
    id: number;
    name: string;
    integration: number;
    status: number;
    updated: number;
    config: { [key: string]: string[] };
}

export interface APIAlertInterface {
    id: number;
    destinations: number[];
    disabled: number;
    policy: number;
    type: number;
    name: string;
    annotations: string;
}

export interface APIAlertTypeInterface {
    id: number;
    name: string;
}

export async function getDestination(id: string) {
    logger.info('getting environment destinations');

    const response = await new APIRequest<APIDestinationInterface>({
        path: `config/destinations/${id}`,
    }).request();

    return response.data;
}

export async function getDestinations() {
    logger.info('getting environment destinations');

    const response = await new APIRequest<APIDestinationInterface[]>({
        path: `config/destinations`,
    }).request();

    return response.data;
}

export async function getIntegrations() {
    logger.info('getting environment integrations');

    const response = await new APIRequest<APIIntegrationInterface[]>({
        path: `config/integrations`,
    }).request();

    return response.data.map(apiIntegration => new Integration(apiIntegration));
}

export async function removeDestination(destination: APIDestinationInterface) {
    logger.info('removing destination');
    return new APIRequest({ path: `config/destinations/${destination.id}`, method: 'delete' }).request();
}

export async function saveDestination(
    name: string,
    integration: number,
    config: { [key: string]: string[] },
    destination?: APIDestinationInterface
) {
    logger.info('saving destination');

    const path = destination ? `config/destinations/${destination.id}` : `config/destinations`;
    const method = destination ? `put` : `post`;

    const response = await new APIRequest({
        path,
        method,
        params: { name, integration, config },
    }).request();

    return response.data;
}

export async function validateDestination(destination: APIDestinationInterface) {
    logger.info('validating destination');
    const response = await new APIRequest({
        path: `config/destinations/${destination.id}/validate`,
        method: 'post',
    }).request();

    return response.data;
}

export async function getAlerts() {
    logger.info('getting environment alerts');

    const response = await new APIRequest<APIAlertInterface[]>({
        path: `config/alerts`,
    }).request();

    return response.data.sort((a, b) => a.name.localeCompare(b.name));
}

export async function getAlertTypes() {
    logger.info('getting environment alert types');

    const response = await new APIRequest<APIAlertTypeInterface[]>({
        path: `config/alert-opts/types`,
    }).request();

    return response.data;
}

export async function updateAlert(alert: APIAlertInterface) {
    logger.info('updating alert');

    const response = await new APIRequest<APIAlertInterface>({
        path: `config/alerts/${alert.id}`,
        method: 'put',
        params: { ...alert },
    }).request();

    return response.data;
}

export async function removeAlert(alert: APIAlertInterface) {
    logger.info('removing alert');
    return new APIRequest({ path: `config/alerts/${alert.id}`, method: 'delete' }).request();
}
