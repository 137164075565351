import { AppContext, createContextFromData } from 'models/Bootstrap';

export interface StateInterface {
    bootstrap: AppContext;
    loaded: boolean;
}

export interface ActionInterface {
    type: string;
    payload: AppContext;
}

export enum ActionType {
    SET = 'SET_BOOTSTRAP',
    RELOAD = 'RELOAD_BOOTSTRAP',
}

export const getInitialState = () => {
    const embeddedBootstrap = window._bacon_;

    if (window._bacon_ && !window._bacon_.version) {
        window._bacon_.version = process.env.REACT_APP_VERSION;
    }

    return {
        bootstrap: createContextFromData(embeddedBootstrap),
        loaded: true,
    };
};

export const actions = {
    set: (bootstrap: AppContext) => ({
        type: ActionType.SET,
        payload: bootstrap,
    }),
    reload: () => ({
        type: ActionType.RELOAD,
        payload: null,
    }),
};

export const reducer: React.Reducer<StateInterface, ActionInterface> = (state, action) => {
    switch (action.type) {
        case ActionType.SET:
            return { ...state, bootstrap: action.payload, loaded: true };
        case ActionType.RELOAD:
            return { ...state, loaded: false };
        default:
            throw new Error(`[bootstrap] unexpected action type ${action.type}`);
    }
};
