import * as React from 'react';
import { APIStatus } from './EmailPreferences';
import { SubscriptionInterface } from 'services/api/userSubscriptions';
import SlideSwitch, { SlideSwitchEventInterface } from 'components/form/SlideSwitch';
import Bouncer from 'components/icons/Bouncer';

const EmailPreferenceRow: React.FC<{
    subscription: SubscriptionInterface;
    onChange: (row: SlideSwitchEventInterface) => void;
}> = ({ subscription, onChange }) => {
    const requestStatus = subscription.status;

    return (
        <div className="flex items-center justify-between border-bottom border-color-grey1 pr3 settings__form--email-pref__row">
            <span>{subscription.name}</span>
            <div className="flex items-center" data-testid="email-pref-switch">
                {requestStatus === APIStatus.inProcess && (
                    <span className="mr3 grey3" data-testid="email-pref-bouncer">
                        Loading <Bouncer />
                    </span>
                )}
                {requestStatus === APIStatus.success && (
                    <span className="mr3 darkgreen" data-testid="email-pref-updated">
                        Updated!
                    </span>
                )}
                {requestStatus === APIStatus.error && (
                    <span className="mr3 red" data-testid="email-pref-failed">
                        Update failed!
                    </span>
                )}
                {requestStatus !== APIStatus.inProcess && (
                    <SlideSwitch name={subscription.name} checked={subscription.subscribed} onChange={onChange} />
                )}
            </div>
        </div>
    );
};

export default EmailPreferenceRow;
