import { useState, useEffect } from 'react';
import Integration from 'models/Integration';
import { getDestinations, getIntegrations, APIDestinationInterface } from 'services/api/envConfig';
import IntegrationsTableRow from './Row';
import useDisplayMessage from 'hooks/useDisplayMessage';
import Bouncer from 'components/icons/Bouncer';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';

const IntegrationsTable = () => {
    const [destinations, setDestinations] = useState<APIDestinationInterface[]>([]);
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [state, setState] = useState({
        isLoading: false,
    });

    const { error } = useDisplayMessage();

    const fetchDestinations = async () => {
        setState({ ...state, isLoading: true });

        try {
            const response = await getDestinations();
            setDestinations(response);
        } catch (e) {
            error("There was an error fetching this environment's integrations.");
        } finally {
            setState({ ...state, isLoading: false });
        }
    };

    const fetchIntegrations = async () => {
        setState({ ...state, isLoading: true });

        try {
            setIntegrations(await getIntegrations());
        } catch (e) {
            error('There was an error fetching integrations.');
        } finally {
            setState({ ...state, isLoading: false });
        }
    };

    const handleDelete = async (destination: APIDestinationInterface) => {
        const newDestinations = [...destinations];
        newDestinations.splice(newDestinations.indexOf(destination), 1);
        setDestinations(newDestinations);
    };

    const integrationNameFor = (destination: APIDestinationInterface) => {
        const integration = integrations.find(({ id }) => id === destination.integration);
        return integration?.name || '';
    };

    useEffect(() => {
        fetchIntegrations();
        fetchDestinations();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {state.isLoading && <Bouncer />}
            {!state.isLoading && destinations.length === 0 && (
                <div className="absolute centerXY">
                    <EmptyState type={EmptyTypes.INFO} fullSize={false}>
                        There are no integrations created for this environment.
                    </EmptyState>
                </div>
            )}
            {!state.isLoading && destinations.length > 0 && (
                <div className="my3">
                    <table className="vc-table vc-table--settings vc-table--integrations full-width">
                        <thead>
                            <tr>
                                <th className="vc-table--integrations__status">Status</th>
                                <th>Authorized Integrations</th>
                            </tr>
                        </thead>
                        <tbody>
                            {destinations.map(destination => (
                                <IntegrationsTableRow
                                    key={destination.id}
                                    destination={destination}
                                    integrationName={integrationNameFor(destination)}
                                    onDeleted={() => handleDelete(destination)}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default IntegrationsTable;
