import * as React from 'react';

export enum StatusBulbValues {
    warning = 'warning',
    error = 'error',
    info = 'info',
    ok = 'ok',
    crit = 'crit',
    off = 'off',
    unknown = 'unknown',
}
interface StatusBulbInterface {
    status: StatusBulbValues;
}

const StatusBulb: React.FC<StatusBulbInterface> = ({ status }) => (
    <div data-testid={`status-bulb-${status}`} className={`status-bulb ${status}`}></div>
);
export default StatusBulb;
