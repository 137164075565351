import { memo } from 'react';
import { EdgeInterface, NodeInterface } from 'models/VisualExplainPlan';
import { useDiagramHover } from 'components/diagram';
import type { DiagramEdge } from 'components/diagram';

export interface DiagramEdgeInterface {
    edges: ReadonlyArray<DiagramEdge<NodeInterface, EdgeInterface>>;
    isNodeSelected: boolean;
}

function getPath({ x1, y1, x2, y2 }: Pick<DiagramEdge<NodeInterface, EdgeInterface>, 'x1' | 'y1' | 'x2' | 'y2'>) {
    if (y1 === y2) return `M ${x2} ${y2} H ${x1}`;
    else {
        const arcSize = 10; // radius of curve
        const xMidPoint = (x2 - x1) / 2 + x1 + arcSize - 25; // -25 to avoid overlapping with the edge label
        const clockwiseArc = `${arcSize},${arcSize} 0 0 1 -${arcSize},-${arcSize}`;
        const counterClockwiseArc = `${arcSize},${arcSize} 1 0 0 -${arcSize},-${arcSize}`;

        return `M ${x2} ${y2} H ${xMidPoint} a ${clockwiseArc} V ${y1 + arcSize} a ${counterClockwiseArc} H ${x1}`;
    }
}

const DiagramEdges = memo<DiagramEdgeInterface>(({ edges, isNodeSelected }) => {
    const { onEdgeHover, onMouseOut, isEdgeHovered, isAnythingHovered } = useDiagramHover<
        NodeInterface,
        EdgeInterface
    >();

    const sortedEdges = [...edges].sort((a, b) => +isEdgeHovered(a) - +isEdgeHovered(b));

    return (
        <>
            {sortedEdges.map(edge => {
                // adjusted to compensate since locations are center based
                const { x1, x2, y1, y2 } = edge;
                const adjustedPosition: Pick<DiagramEdge<NodeInterface, EdgeInterface>, 'x1' | 'y1' | 'x2' | 'y2'> = {
                    x1: x1 + 90 + 10,
                    y1: y1 + 2,
                    x2: x2 - 90,
                    y2: y2 + 2,
                };

                return (
                    <g
                        onMouseOver={() => {
                            if (!isAnythingHovered) onEdgeHover(edge);
                        }}
                        onMouseOut={() => {
                            if (!isNodeSelected) onMouseOut();
                        }}
                        key={`${edge.target.id} -> ${edge.source.id}`}
                    >
                        <title>{`${edge.edge.display.label} from ${edge.target.label} to ${edge.source.label}`}</title>
                        <path
                            d={getPath(adjustedPosition)}
                            fill="none"
                            markerEnd={
                                !isAnythingHovered || isEdgeHovered(edge) ? 'url(#arrow-dark)' : 'url(#arrow-light)'
                            }
                            stroke={!isAnythingHovered || isEdgeHovered(edge) ? 'var(--grey2)' : 'var(--grey05)'}
                            strokeWidth={4}
                            data-testid={`${edge.edge.display.label} from ${edge.target.label} to ${edge.source.label}`}
                        />
                        <text
                            textAnchor="end"
                            className="fz12"
                            fill={!isAnythingHovered || isEdgeHovered(edge) ? 'var(--semiDark)' : 'var(--grey2)'}
                            x={adjustedPosition.x2 - 4}
                            y={adjustedPosition.y2 - 6}
                        >
                            {edge.edge.display.label}
                        </text>
                    </g>
                );
            })}
        </>
    );
});
DiagramEdges.displayName = 'DiagramEdges';

export default DiagramEdges;
