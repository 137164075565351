import { useCallback, useEffect, useRef } from 'react';

interface MountHookInterface {
    isMounted(): boolean;
    resolveIfMounted<T>(promise: Promise<T>): Promise<T>;
}

export default function useMounted(): MountHookInterface {
    const mountRef = useRef(false);
    useEffect(() => {
        mountRef.current = true;

        return () => {
            mountRef.current = false;
        };
    }, []);

    const isMounted = useCallback(() => mountRef.current, []);
    const resolveIfMounted = useCallback(
        function <T>(promise: Promise<T>): Promise<T> {
            return new Promise<T>((resolve, reject) => {
                promise
                    .then((result: T) => {
                        isMounted() && resolve(result);
                    })
                    .catch((error: unknown) => {
                        isMounted() && reject(error);
                    });
            });
        },
        [isMounted]
    );

    return {
        isMounted,
        resolveIfMounted,
    };
}
