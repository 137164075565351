import Dropdown from 'components/dropdowns/Dropdown';
import Icon from 'components/icons/Icon';
import Logger from 'services/logger';
import { csvExport, jsonExport } from 'services/dataExport';
import { ExportDataType } from 'services/dataExport';
import { FC, useState } from 'react';
import { getInteractionReporter } from 'services/analytics/analytics';

interface ExportTogglerPropsInterface {
    getData(): Promise<ExportDataType[]>;
    title: string;
    className?: string;
}

type ExportFunctionType = (data: ExportDataType[], title: string) => void;

const logger = Logger.get('ExportToggler');
const reportActivity = getInteractionReporter('export');

const ExportToggler: FC<ExportTogglerPropsInterface> = ({ className = '', getData, title }) => {
    const [open, setOpen] = useState(false);
    const [jsonError, setJSONError] = useState(false);
    const [csvError, setCSVError] = useState(false);
    const [data, setData] = useState<ExportDataType[] | null>(null);

    function toggle() {
        if (open) {
            setData(null);
        } else {
            getData()
                .then(exportData => setData(exportData))
                .catch(e => {
                    logger.error(e);
                    setJSONError(true);
                    setCSVError(true);
                });
        }

        setOpen(!open);
    }

    function downloadAsJSON() {
        reportActivity('json', title);
        download(jsonExport, setJSONError);
    }

    function downloadAsCSV() {
        reportActivity('csv', title);
        download(csvExport, setCSVError);
    }

    function download(exportFn: ExportFunctionType, errorFn: typeof setJSONError) {
        if (!data) {
            errorFn(true);
            return;
        }

        errorFn(false);

        try {
            exportFn(data, title);
        } catch (e) {
            logger.error(e);
            errorFn(true);
        }
    }

    return (
        <div className={`samples__export export-toggler ${className} ${open ? 'samples__export--open' : ''}`}>
            <label className="dropdown__label">
                <button className="nowrap flex items-center dark no-decoration cursor-hand" onClick={toggle}>
                    <Icon icon="download" className="flex grey3 mr2 h2" />
                    <span>Export</span>
                    <Icon icon="keyboard_arrow_down" className="flex fz18 mainColor ml2" />
                </button>
            </label>
            <Dropdown className="flex" isExpanded={open} onToggle={openStatus => setOpen(openStatus)}>
                <div
                    className="config-list__label__content bg-grey05 absolute border--dropdown"
                    data-testid="export-toggler-content"
                >
                    <div className="overflow-auto monospace fz13 pt2">
                        <div className="mx3 py2 border-bottom border-color-grey1 flex items-center flex-wrap">
                            <span className="flex-grow-1">Download as CSV</span>
                            {data && (
                                <button
                                    type="button"
                                    onClick={downloadAsCSV}
                                    className="primary-orange export-toggler__download-button flex items-center p0 justify-center"
                                    data-testid="download-csv"
                                >
                                    <Icon icon="download" className="white fz18 relative" />
                                </button>
                            )}
                            {csvError && (
                                <div className="full-width flex red mt2 export-toggler__download-error">
                                    <Icon icon="info" className="red mr2 relative" />
                                    <span className="monospace fz10 line-height-120">
                                        There was an error generating this file.
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="mx3 py2 border-bottom border-color-grey1 flex items-center flex-wrap">
                            <span className="flex-grow-1">Download as JSON</span>
                            {data && (
                                <button
                                    type="button"
                                    onClick={downloadAsJSON}
                                    className="primary-orange export-toggler__download-button flex items-center p0 justify-center"
                                    data-testid="download-json"
                                >
                                    <Icon icon="download" className="white fz18 relative" />
                                </button>
                            )}
                            {jsonError && (
                                <div className="full-width flex red mt2 export-toggler__download-error">
                                    <Icon icon="info" className="red mr2 relative" />
                                    <span className="monospace fz10 line-height-120">
                                        There was an error generating this file.
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="p3 fz10 grey-3 line-height-120">
                            Files include the data visible in this table. Configure your view to change the contents of
                            downloaded files.
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>
    );
};

export default ExportToggler;
