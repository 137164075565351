import Alert, { AlertTypes } from 'components/messages/Alert';
import ContactLink from 'components/messages/ContactLink';
import Feature from 'components/serviceLevel/Feature';
import OrgTokenManager from './OrgTokenManager';
import * as React from 'react';
import { AuthConfigDataInterface } from 'services/settings/authentication';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';

interface SAMLConfigurationPropsInterface {
    config: AuthConfigDataInterface;
    onChange: (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const SAMLConfiguration: React.FC<SAMLConfigurationPropsInterface> = ({ config, onChange }) => {
    const fallbackContent = (
        <Alert sticky className="my2" type={AlertTypes.WARNING}>
            This is a Premium feature <ContactLink className="inline-flex" /> to enable it.
        </Alert>
    );

    return (
        <Feature name="authSAMLRequired" fallback={fallbackContent}>
            <div className="settings__form py2" data-testid="saml-configuration">
                <h2 className="h3 mr2 lighter grey3 flex-grow-1">Provisioning token</h2>
                <p className="lighter mt3 mb3">
                    <span>
                        An API token is required to enable provisioning features when adding the Database Performance
                        Monitor app to your Identity Provider&apos;s Dashboard.
                    </span>
                </p>

                <FinalActionModalProvider>
                    <OrgTokenManager />
                </FinalActionModalProvider>

                <fieldset className="my3">
                    <label className="flex" htmlFor="saml-fed-metadata">
                        <b className="form__label fz10 uppercase flex items-center">Federation metadata:</b>
                        <textarea
                            className="full-width mt2"
                            data-testid="saml-fed-metadata"
                            id="saml-fed-metadata"
                            placeholder="Paste your federation metadata here."
                            name="auth.saml.IdPMetadata"
                            value={config['auth.saml.IdPMetadata']}
                            onChange={onChange}
                            rows={7}
                        ></textarea>
                    </label>
                </fieldset>
                <p className="py3 h4 lighter">
                    More information is available in our{' '}
                    <a href="https://docs.vividcortex.com/general-reference/sso/">Single Sign-On Configuration Guide</a>
                </p>
            </div>
        </Feature>
    );
};

export default SAMLConfiguration;
