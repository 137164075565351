import Icon from 'components/icons/Icon';
import moment from 'moment-timezone';

const currentYear = moment().year();

const Footer = () => (
    <footer>
        <div className="footer-bar flex justify-between mx-auto pb3 mt4 px3">
            <div className="flex flex-column">
                <a
                    href="https://maps.google.com?q=300+Preston+Ave+Charlottesville+VA+22902"
                    className="no-decoration line-height-120 dark"
                >
                    300 Preston Ave, Charlottesville
                    <br />
                    Virginia 22902
                    <br />
                    United States
                </a>
                <a href="tel:+1-877-738-2863" className="flex items-center fz14 dark no-decoration my2">
                    <Icon icon="phone" className="h2" />
                    +1 (877) 738-2863
                </a>
                <p className="grey3">© {currentYear} SolarWinds Worldwide, LLC. All rights reserved.</p>
            </div>
            <div className="flex flex-column items-end">
                <div className="flex items-center">
                    <p>Find us on:</p>
                    <ul className="list-reset flex items-center">
                        <li className="mx2">
                            <a href="https://twitter.com/SolarWinds">
                                <Icon icon="twitter" className="h1 dark hoverable" />
                            </a>
                        </li>
                        <li className="mx2">
                            <a href="https://www.facebook.com/VividCortexInc">
                                <Icon icon="facebook" className="h2 dark hoverable" />
                            </a>
                        </li>
                        <li className="ml2">
                            <a href="https://www.linkedin.com/company/solarwinds">
                                <Icon icon="linkedin" className="h2 dark hoverable" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="flex items-end flex-grow-1">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.solarwinds.com/-/media/solarwinds/swdcv2/legal/legal-documents/solarwinds-software-services-agreement-combined-cloud-and-core.ashx"
                    >
                        Software Services Agreement and Privacy Notice
                    </a>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
