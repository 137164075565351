import { useState } from 'react';
import moment from 'moment';
import { get as getRetention, RetentionDataInterface } from 'services/api/retention';
import OverviewItemRow from './ItemRow';
import Logger from 'services/logger';

const logger = Logger.get('OverviewRetention');
interface Resolution {
    unit: string;
    value: number;
}

type LapseUnit = 'days' | 'hours' | 'minutes' | 'seconds';

const OverviewRetention = () => {
    const [retentionData, setRetentionData] = useState<RetentionDataInterface[]>([]);

    const handleError = () => {
        logger.warn('error while fetching the retention data');
    };

    const handleSuccess = (retentions: RetentionDataInterface[]) => {
        setRetentionData(retentions);
    };

    const formatResolution = (retention: RetentionDataInterface) => {
        const resolution: Resolution = { value: 0, unit: '' };

        const m = moment.duration({ seconds: retention.resolution });
        ['day', 'hour', 'minute', 'second'].some(lapse => {
            const momentLapse = `${lapse}s` as LapseUnit;
            resolution.value = m[momentLapse]();
            resolution.unit = lapse;

            // stop the iteration when we find the correct unit
            return resolution.value !== 0;
        });

        return resolution;
    };

    return (
        <OverviewItemRow label="Retention" onLoad={getRetention} onError={handleError} onSuccess={handleSuccess}>
            <ul className="pl2 ml1">
                {retentionData.map((retention: RetentionDataInterface, index: number) => (
                    <li
                        key={retention.retention.toString() + retention.resolution.toString()}
                        data-testid="retention-item"
                    >
                        {index > 0 && '+'}
                        {retention.retention} days {formatResolution(retention).value}-
                        {formatResolution(retention).unit} resolution
                    </li>
                ))}
            </ul>
        </OverviewItemRow>
    );
};

export default OverviewRetention;
