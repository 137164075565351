import * as React from 'react';
import { virtualPageView } from 'services/analytics/analytics';

const VirtualPageView: React.FC = () => {
    virtualPageView(window.location.pathname);

    return null;
};

export default VirtualPageView;
