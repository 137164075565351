import Icon from 'components/icons/Icon';
import React, { useState, useEffect } from 'react';

export enum PaginatorLayout {
    DEFAULT = 'default',
    SMALL = 'small',
}

interface PaginatorProps {
    itemsLength: number;
    perPage: number;
    onPaging?: (page: number) => void;
    layout?: PaginatorLayout;
}

const Paginator: React.FC<PaginatorProps> = ({ onPaging, itemsLength, perPage, layout = PaginatorLayout.DEFAULT }) => {
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    const [isPaginationNeeded, setIsPaginationNeeded] = useState(false);

    const isOnFirstPage = selectedPage === 1;
    const isOnLastPage = selectedPage === totalPages;

    const computeTotalPages = () => {
        const pages = Math.ceil(itemsLength / perPage);
        if (!isOnFirstPage) {
            setSelectedPage(1);
        }
        setTotalPages(pages);
    };

    useEffect(() => {
        computeTotalPages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsLength, perPage]);

    useEffect(() => {
        setIsPaginationNeeded(totalPages > 1);
    }, [totalPages]);

    useEffect(() => {
        if (!selectedPage || !onPaging) {
            return;
        }

        onPaging(selectedPage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage]);

    const decrementPage = () => {
        if (isOnFirstPage) {
            return;
        }

        setSelectedPage(selectedPage - 1);
    };

    const incrementPage = () => {
        if (isOnLastPage) {
            return;
        }

        setSelectedPage(selectedPage + 1);
    };

    return isPaginationNeeded ? (
        layout === PaginatorLayout.DEFAULT ? (
            <div className="toolbar toolbar--paginator flex items-center justify-center nowrap" data-testid="paginator">
                <button
                    className="toolbar--paginator__pager primary-grey flex items-center justify-evenly"
                    onClick={() => decrementPage()}
                    disabled={isOnFirstPage}
                    data-testid="back-button"
                >
                    <Icon
                        icon="keyboard_arrow_left"
                        className="toolbar--paginator__pager__icon toolbar--paginator__pager__icon--left inline-flex h2 white"
                    />
                    Previous
                </button>
                <div
                    className="toolbar--paginator__index flex justify-center monospace grey3 italic nowrap"
                    data-testid="page-info"
                >
                    {selectedPage}
                    <span className="mx1">/</span>
                    {totalPages}
                </div>
                <button
                    className="toolbar--paginator__pager primary-grey flex items-center justify-evenly"
                    onClick={() => incrementPage()}
                    disabled={isOnLastPage}
                    data-testid="next-button"
                >
                    Next
                    <Icon
                        icon="keyboard_arrow_right"
                        className="toolbar--paginator__pager__icon toolbar--paginator__pager__icon--right inline-flex h2 white"
                    />
                </button>
            </div>
        ) : (
            <div
                className="toolbar toolbar--paginator--small flex items-center nowrap justify-center"
                data-testid="paginator"
            >
                <button
                    className="toolbar--paginator__pager--small primary-grey flex items-center justify-center"
                    onClick={() => decrementPage()}
                    disabled={isOnFirstPage}
                    data-testid="back-button"
                >
                    <Icon icon="keyboard_arrow_left" className="inline-flex h2 white" />
                </button>
                <div
                    className="toolbar--paginator__index flex justify-center monospace grey3 italic nowrap"
                    data-testid="page-info"
                >
                    {selectedPage}
                    <span className="mx1">/</span>
                    {totalPages}
                </div>
                <button
                    className="toolbar--paginator__pager--small primary-grey flex items-center justify-center"
                    onClick={() => incrementPage()}
                    disabled={isOnLastPage}
                    data-testid="next-button"
                >
                    <Icon icon="keyboard_arrow_right" className="inline-flex h2 white" />
                </button>
            </div>
        )
    ) : null;
};

export default Paginator;
