import moment from 'moment-timezone';
import * as contextService from 'services/context';
import Logger from 'services/logger';
import * as preferenceService from 'services/api/userPreference';
import * as parameterService from 'services/api/parameter';
import { notifyError } from 'services/error/reporter';
import { toMs } from 'services/time';

const logger = Logger.get('TimezoneService');

interface UserTimezonePreferenceInterface {
    selectedTimezone: string;
}

let identifiedTimezone: string | undefined;
let initPromise: Promise<string> | undefined;

function getUser() {
    const context = contextService.get();

    if (!context.user) {
        throw new Error('Trying to load user timezone with no user');
    }

    if (!context.user.id) {
        const error = new Error('Trying to resolve the timezone of a user with no ID');
        notifyError(error);
        throw error;
    }

    return context.user;
}

export async function fromEnvironment() {
    try {
        const parameter = await parameterService.get('timezone');

        logger.debug(`Got timezone from API: ${parameter.value}`);

        return parameter.value;
    } catch (e) {
        logger.debug('No environment timezone set');

        return null;
    }
}

export async function fromUser() {
    const user = getUser();

    try {
        const preference = await preferenceService.get<UserTimezonePreferenceInterface>(user, 'timezone');
        const timezone = preference.selectedTimezone;

        logger.debug(`Got timezone from API: ${timezone}`);

        return timezone;
    } catch (e) {
        logger.debug('No custom timezone set');

        return null;
    }
}

export function reset() {
    identifiedTimezone = undefined;
    initPromise = undefined;
}

export async function saveEnvironmentTimezone(timezone: string) {
    await parameterService.store('timezone', timezone);

    reset();
}

export async function clearEnvironmentTimezone() {
    await parameterService.remove('timezone');

    reset();
}

export async function saveUserTimezone(timezone: string) {
    const user = getUser();

    await preferenceService.store(user, 'timezone', { selectedTimezone: timezone });

    reset();
}

export async function clearUserTimezone() {
    const user = getUser();

    await preferenceService.remove(user, 'timezone');

    reset();
}

export function fromBrowser() {
    return moment.tz.guess();
}

export function fromURL() {
    const url = new URL(window.location.href);

    return url.searchParams.get('timezone');
}

async function resolveUserTimezone() {
    // urlTimezone || userTimezone || envTimezone || browserTimezone
    const urlTimezone = fromURL();
    if (urlTimezone) {
        return urlTimezone;
    }

    const userTimezone = await fromUser();
    if (userTimezone) {
        return userTimezone;
    }

    const envTimezone = await fromEnvironment();
    if (envTimezone) {
        return envTimezone;
    }

    const contextTimezone = contextService.get().env?.timezone;
    if (contextTimezone) {
        return contextTimezone;
    }

    return fromBrowser();
}

export async function init() {
    const context = contextService.get();

    if (!context?.user?.id) {
        logger.debug('Skipping initialization');
        return Promise.resolve(false);
    }

    if (initPromise) {
        return initPromise;
    }

    return (initPromise = resolveUserTimezone().then(timezone => (identifiedTimezone = timezone)));
}

export async function getCurrentTimezone() {
    await init();

    return identifiedTimezone;
}

export enum DateTimeFormat {
    TIMESTAMP_LARGE = 'h:mm:ss A MMM DD, YYYY',
    TIMESTAMP_CHARTS = 'MMM DD, YYYY h:mm:ss A z',
    TIMESTAMP_SHORT = 'MM/DD/YYYY',
    TIMESTAMP_LEGEND = 'M/D/YYYY h:mm A',
}

/**
 * Prefer formatTimeAsync(...) if you're unsure if the timezone could be undefined
 * at the time of requesting format.
 */
export function formatTime(timestamp: number | moment.Moment, format = DateTimeFormat.TIMESTAMP_LARGE) {
    const momentDate = moment.isMoment(timestamp) ? timestamp : moment(toMs(timestamp));

    if (!identifiedTimezone) {
        throw new Error('Timezone service was not initialized');
    }

    return momentDate.tz(identifiedTimezone).format(format);
}

async function getMomentDateAsync(timestamp: number | moment.Moment) {
    const momentDate = moment.isMoment(timestamp) ? timestamp : moment(toMs(timestamp));

    const timezone = await getCurrentTimezone();
    return momentDate.tz(timezone as string);
}

export async function formatTimeAsync(timestamp: number | moment.Moment, format = DateTimeFormat.TIMESTAMP_LARGE) {
    const momentDate = await getMomentDateAsync(timestamp);

    // We can be sure that there will be a timezone after waiting for init
    return momentDate.format(format);
}

export async function fromNow(timestamp: number | moment.Moment) {
    const momentDate = await getMomentDateAsync(timestamp);

    return momentDate.fromNow();
}

export function getTimezones() {
    return moment.tz.names();
}
