import SettingsLayout from '../Layout';
import AgentMaintenance from './AgentMaintenance';
import DeleteSamples from './DeleteSamples';
import EnvPreferences from './EnvPreferences';
import EventsSettings from './EventsSettings';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';
import { UserHasPermission } from 'components/security/UserHasPermission';

const SettingsPreferences = () => {
    return (
        <FinalActionModalProvider>
            <SettingsLayout title="Environment Preferences">
                <EnvPreferences></EnvPreferences>
                <EventsSettings></EventsSettings>
                <AgentMaintenance></AgentMaintenance>
                <UserHasPermission to="purgeQuerySamples">
                    <DeleteSamples></DeleteSamples>
                </UserHasPermission>
            </SettingsLayout>
        </FinalActionModalProvider>
    );
};
export default SettingsPreferences;
