import { ColumnDefinitionInterface } from 'models/profiler/columns';
import { ParameterizableIntervalInterface } from '../../../models/TimeInterval';
import APIRequest from '../Request';

export function fetchColumnDefinitions(
    interval: ParameterizableIntervalInterface,
    hostFilter: string
): Promise<ColumnDefinitionInterface[]> {
    return new APIRequest<ColumnDefinitionInterface[]>({
        method: 'get',
        path: 'features/columns',
        params: {
            ...interval.asParams(),
            host: hostFilter,
        },
    })
        .request()
        .then(({ data }) => data);
}
