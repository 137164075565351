import encodeInterval from './timeIntervalParamEncoder';
import encodeHosts from './hostsParamEncoder';
import encodeFilter from './filterParamEncoder';
import encodeSimpleParams from './simpleParamsEncoder';

export interface TimeIntervalInterface {
    fromTs: number;
    untilTs: number;
}
export interface HostInterface {
    id: number;
}

export interface RequestParamInterface {
    [key: string]:
        | Record<string, unknown>
        | boolean
        | number
        | string
        | string[]
        | TimeIntervalInterface
        | HostInterface[];
}

type EncodedParamType = Record<string, unknown> | number | string | boolean;

export type EncodedParamsType = EncodedParamType[] | EncodedParamType | undefined;

export function hasParam(key: string, params: RequestParamInterface) {
    return key in params;
}

export type RequestParamEncoder = (params: RequestParamInterface) => void;

export function requestParamsEncoder<ParamType>(
    params: ParamType | undefined,
    encoders: RequestParamEncoder[] = [encodeInterval, encodeHosts, encodeFilter, encodeSimpleParams]
): EncodedParamsType {
    if (!params) {
        return;
    }

    //clone the params
    const encodedParams = JSON.parse(JSON.stringify(params));

    encoders.forEach(encoder => {
        encoder(encodedParams);
    });
    return encodedParams;
}
