import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import Icon from 'components/icons/Icon';
import Pill from 'components/form/Pill';
import WidgetBox from 'components/layout/WidgetBox';
import Paginator, { PaginatorLayout } from 'components/lists/Paginator';

const PER_PAGE = 15;

const QueryTags: FC<{ tags?: Record<string, string[]>[] }> = ({ tags = [] }) => {
    const [filter, setFilter] = useState<string>('');
    const [paginatedList, setPaginatedList] = useState<string[]>([]);

    useEffect(() => {
        setPaginatedList(tagList.slice(0, PER_PAGE));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tagList = useMemo(() => {
        const list = [] as string[];
        tags.forEach(tag => {
            tag.values.forEach(value => list.push(`${tag.key}=${value}`));
        });
        return list.sort();
    }, [tags]);

    const filterList = (list: string[], filterInput = filter) => {
        if (!filterInput.length) return list;

        return list.filter(tag => {
            return tag.toLowerCase().includes(filterInput.toLowerCase());
        });
    };

    const PaginateList = (page: number, filterInput = filter) => {
        const list = filterList(tagList, filterInput);
        setPaginatedList(list.slice(PER_PAGE * (page - 1), PER_PAGE * page));
    };

    const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilter(event.currentTarget.value);
        PaginateList(1, event.currentTarget.value);
    };

    const handleFilterClear = () => {
        setFilter('');
        PaginateList(1, '');
    };

    const renderFilter = () => (
        <div className="nowrap relative">
            <input
                type="text"
                value={filter}
                onChange={handleFilterChange}
                data-testid="filter-tags"
                placeholder="Filter Tags"
                className="bg-white flex-grow-1 flex-grow-1 relative filter_input"
            />
            {filter.length > 0 && (
                <Icon
                    icon="cancel"
                    onClick={handleFilterClear}
                    className="filter__icon absolute right-2 cursor-hand grey3 h2 flex items-center"
                />
            )}
        </div>
    );

    const renderTagList = () => {
        return (
            <div className="flex flex-row flex-wrap items-start p1">
                {paginatedList.map(tag => {
                    return <Pill data-testid="tag-pill" key={tag} text={tag} />;
                })}
            </div>
        );
    };

    const filteredTags = filterList(tagList, filter);

    return (
        <WidgetBox>
            <h2 className="h2 grey3 pb2 pt2" data-testid="tags-header">
                Tags
            </h2>

            <div className="flex justify-between items-center">
                <div className="widget--counter flex">
                    <p className="bold flex items-center">
                        {filteredTags.length} {filteredTags.length > 1 ? 'Tags' : 'Tag'}
                    </p>
                </div>
                {tagList.length > 1 && renderFilter()}
            </div>

            {renderTagList()}
            <Paginator
                itemsLength={filteredTags.length}
                layout={PaginatorLayout.SMALL}
                perPage={PER_PAGE}
                onPaging={PaginateList}
            />
        </WidgetBox>
    );
};

export default QueryTags;
