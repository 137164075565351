import { AxiosResponse } from 'axios';

export interface APIResponseInterface<ResponseType, ResponseStructureType> {
    data: ResponseType;
    response: ResponseStructureType;
    metadata: MetadataInterface;
    headers: Record<string, unknown>;
    errors: unknown;
}

type MetadataStringProps = 'samplesize' | 'from' | 'until';

interface TypeIntervalInterface {
    from: number;
    until: number;
}

interface MetadataInterface {
    samplesize?: number;
    from?: number;
    until?: number;
    more?: boolean;
    timeInterval?: TypeIntervalInterface;
    duration?: number;
}

export class APIResponse<ResponseType, ResponseStructureType = { data: ResponseType }>
    implements APIResponseInterface<ResponseType, ResponseStructureType>
{
    constructor(private axiosResponse: AxiosResponse) {}

    get metadata() {
        const metadata: MetadataInterface = {};

        const metadataProps: MetadataStringProps[] = ['samplesize', 'from', 'until'];
        metadataProps.forEach(prop => {
            const value = this.getMetadataHeader(prop);
            if (value) {
                metadata[prop] = parseInt(value, 10);
            }
        });

        metadata.more = !!this.getMetadataHeader('more');

        // the IF is only needed because the /queries api sends from=0 and until=0 when from and until are not provided in the request
        if (metadata.from && metadata.until) {
            // @TODO: transform this to an instance of the TimeInterval model
            metadata.timeInterval = { from: metadata.from, until: metadata.until };
            metadata.duration = metadata.until - metadata.from;
        }
        return metadata;
    }

    get data() {
        return this.axiosResponse.data?.data !== undefined ? this.axiosResponse.data.data : this.axiosResponse.data;
    }

    get response(): ResponseStructureType {
        return this.axiosResponse.data;
    }

    get errors() {
        return this.axiosResponse.data.errors;
    }

    get headers() {
        return { ...this.axiosResponse.headers };
    }

    private getMetadataHeader(header: string) {
        const headers = Object.keys(this.headers).reduce((destination: { [key: string]: string }, key) => {
            destination[key.toLowerCase()] = this.headers[key];
            return destination;
        }, {});

        return headers['x-vc-meta-' + header] || headers['x-client-' + header];
    }
}
