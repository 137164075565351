import { CredentialDataInterface, HostCredential } from './Credential';
import { CredentialHostType } from 'services/api/credentials';

type SQLServerSSLModeType = 'require' | 'verify-ca' | 'disable' | '';

export interface SQLServerCredentialDataInterface extends CredentialDataInterface {
    sslEnabled: string;
    sslMode: SQLServerSSLModeType;
    user: string;
    db: string;
}

export default class SQLServerCredential extends HostCredential {
    user: string;
    sslEnabled: boolean;
    sslMode: SQLServerSSLModeType;
    database: string;

    constructor(data: SQLServerCredentialDataInterface) {
        super(CredentialHostType.mssql, data);
        this.user = data.user || '';
        this.sslEnabled = data.sslEnabled === 'true';
        this.sslMode = data.sslMode || '';
        this.database = data.db || '';
    }

    update(values: this, allowPasswordChange?: boolean) {
        super.update(values, allowPasswordChange ?? true);
        this.user = values.user;
        this.sslEnabled = values.sslEnabled;
        this.sslMode = values.sslMode;
        this.database = values.database;
    }

    asPayloadObject() {
        return {
            ...super.asPayloadObject(),
            user: this.user,
            sslEnabled: this.sslEnabled.toString(),
            sslMode: this.sslMode,
            db: this.database,
        };
    }
}
