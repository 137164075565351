import { FC, useState } from 'react';
import User from 'models/User';
import { remove as removeUser } from 'services/api/users';
import { addUsers } from 'services/api/teams';
import Dropdown from 'components/dropdowns/Dropdown';
import Team from 'models/Team';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { UserHasPermission } from 'components/security/UserHasPermission';

const AddToTeamsDropdown: FC<{
    selectedUsers: User[];
    teams: Team[];
    onUnselect: () => void;
    onDeleted: (users: User[]) => void;
}> = ({ selectedUsers, teams, onUnselect, onDeleted }) => {
    const { success, error } = useDisplayMessage();
    const [expanded, setExpanded] = useState<boolean>(false);

    const unselectUsers = () => {
        setExpanded(false);
        onUnselect();
    };

    const addToTeam = async (team: Team) => {
        if (!selectedUsers.length) {
            return;
        }
        try {
            await addUsers(selectedUsers, team);
            success(`${selectedUsers.length} users have been added to the ${team.name} team`);
        } catch (e) {
            error(`There was a problem adding users to the ${team.name} team`);
        } finally {
            unselectUsers();
        }
    };

    const deleteUsers = async () => {
        if (!selectedUsers.length) {
            return;
        }
        const removedUsers: User[] = [];
        const promises = selectedUsers.map(async user => {
            try {
                await removeUser(user);
                removedUsers.push(user);
                success(`User ${user.name} successfully removed from the organization`);
            } catch (e) {
                error(e instanceof Error ? e.message : 'Something went wrong');
            }
        });
        await Promise.allSettled(promises);

        if (removedUsers.length) {
            onDeleted(removedUsers);
        }

        unselectUsers();
    };

    const handleToggle = (isExpanded: boolean) => {
        setExpanded(isExpanded);
    };

    return (
        <>
            <Dropdown
                className="flex dropdown--right"
                text="Add to the team or delete"
                isExpanded={expanded}
                onToggle={handleToggle}
            >
                <div>
                    <strong className="title flex pb2 pt3 px3">Add to the team:</strong>
                    <ul className="add-to-team-list list-reset overflow-auto">
                        {teams.map(team => (
                            <li
                                key={team.id}
                                data-testid="add-to-team-item"
                                className="add-to-team-list__item p2 cursor-hand mainColor nowrap truncate"
                                title={team.name}
                                onClick={() => {
                                    addToTeam(team);
                                }}
                            >
                                {team.name}
                            </li>
                        ))}
                    </ul>

                    <div className="add-to-team-list__actions bg-grey05 p3 flex items-center justify-between">
                        <button
                            className="add-to-team-list__actions__item mainColor cursor-hand"
                            onClick={unselectUsers}
                        >
                            Deselect
                        </button>
                        <UserHasPermission to="removeUsers">
                            <button
                                className="add-to-team-list__actions__item mainColor nowrap ml3 cursor-hand"
                                onClick={() => deleteUsers()}
                            >
                                Remove from organization
                            </button>
                        </UserHasPermission>
                    </div>
                </div>
            </Dropdown>
        </>
    );
};

export default AddToTeamsDropdown;
