import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import TimezoneSelector from 'components/timezone/Selector';
import useEnv from 'hooks/useEnv';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { useFinalActionModal } from 'components/context/FinalActionModal';
import { remove, ResponseErrorMessage } from 'services/api/envs';

const EnvPreferences = () => {
    const env = useEnv();

    const { openModal } = useFinalActionModal();

    const { success, error } = useDisplayMessage();

    const handleSubmit = async () => {
        try {
            await remove(env);
            success(`Environment ${env.name} successfully removed`);
            window.location.replace('/');
        } catch (e) {
            if (e instanceof Error && e.message === ResponseErrorMessage.FAILURE) {
                error('This environment cannot be removed.');
            } else {
                error('An error happened when trying to delete this environment.');
            }
        }
    };

    return (
        <>
            <div className="mb3">
                <TimezoneSelector isEnvironmentSetting={true} />
            </div>
            <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Delete environment:" className="mb3">
                <button
                    className="link flex form__link items-center justify-self-start"
                    onClick={() => {
                        openModal({
                            onSubmit: handleSubmit,
                            confirmMsg: `You are about to delete the environment "${env.name}"`,
                            buttonText: 'Delete this environment',
                            title: 'Delete Environment',
                        });
                    }}
                    data-testid="delete-env"
                >
                    Delete this environment
                </button>

                <div className="form__input-container__explain grey3 line-height-120 flex flex-column">
                    <span>Deleting an environment cannot be undone once performed.</span>
                </div>
            </FormGroup>
        </>
    );
};
export default EnvPreferences;
