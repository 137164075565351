import ContactLink from 'components/messages/ContactLink';
import useUrl from 'hooks/useUrl';

const JoinBlock = () => {
    const signupUrl = useUrl('signup');

    return (
        <div className="card-block__login__join__wrap full-height">
            <h2 className="fz18 regular p3 white">Don&apos;t Have An Account?</h2>
            <p className="white line-height-120 pt3 pb2 px3">
                DPM is the best way to see what your database servers are doing in production. If you need access to
                your account, ask your organization&apos;s account administrator to invite you.
            </p>
            <p className="white line-height-120 py2 px3">
                You can also <ContactLink /> to receive help getting access to your organization&apos;s dashboard. If
                your company isn&apos;t a DPM customer, please{' '}
                <a href="https://www.vividcortex.com/request-a-demo" target="_blank" rel="noreferrer noopener">
                    contact Sales to request a demo
                </a>
                .
            </p>
            <div className="border-top border-color-white mx3 mt3 half-opacity" />
            <div className="white line-height-120 px3 mt3 pt2 pb3">
                <p>
                    New to DPM?{' '}
                    <a className="cnt__link" data-testid="sign-up-link" href={signupUrl}>
                        Create an account
                    </a>
                </p>
            </div>
        </div>
    );
};

export default JoinBlock;
