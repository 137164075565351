import { SyntheticEvent, FC } from 'react';

interface CheckboxPropsInterface {
    className?: string;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    value?: string;
    helpText?: string;
    error?: string;
    checked?: boolean;
    label: string;
    id: string;
}

const Checkbox: FC<CheckboxPropsInterface> = ({
    onChange,
    helpText,
    label,
    id,
    className = '',
    error = '',
    checked = false,
    ...rest
}) => (
    <div className={`grid form__checkbox ${className}`}>
        <label className="form__label flex items-center" htmlFor={id}>
            <input type="checkbox" checked={checked} id={id} onChange={onChange} {...rest} /> <span>{label}</span>
        </label>
        <div>
            {error && <div className="form__input-container__error red mt2 full-width">{error}</div>}
            {helpText && <p className="form__input-container__explain grey3 mt2">{helpText}</p>}
        </div>
    </div>
);

export default Checkbox;
