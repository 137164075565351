type TargetSeverityType = 'info' | 'warn' | 'crit';

type OccurrenceObjectType = 'query';

interface OccurrenceObjectDataInterface {
    objectID: string;
    objectType: OccurrenceObjectType;
}

export interface OccurrenceTargetDataInterface {
    firstSeen: number;
    id: number;
    lastSeen: number;
    message: string;
    muted: boolean;
    passed: boolean;
    severity: TargetSeverityType;
    objectsID?: string;
    host?: number;
    objects?: OccurrenceObjectDataInterface[];
}

export default abstract class OccurrenceTarget {
    firstSeen: number;
    id: number;
    lastSeen: number;
    message: string;
    muted: boolean;
    passed: boolean;
    severity: TargetSeverityType;

    constructor({ firstSeen, id, lastSeen, message, muted, passed, severity }: OccurrenceTargetDataInterface) {
        this.firstSeen = firstSeen;
        this.id = id;
        this.lastSeen = lastSeen;
        this.message = message;
        this.muted = muted;
        this.passed = passed;
        this.severity = severity;
    }
}

export class OccurrenceHost extends OccurrenceTarget {
    hostId: number;
    host = undefined;

    constructor({ host, ...props }: OccurrenceTargetDataInterface) {
        super(props);

        if (!host) {
            throw new Error(`Trying to make an OccurrenceHost instance with undefined objects`);
        }

        this.hostId = host;
    }
}

export class OccurrenceObject extends OccurrenceTarget {
    readonly queryId: string;
    readonly type: OccurrenceObjectType;
    readonly objectsID: string;

    constructor({ objects, objectsID, ...props }: OccurrenceTargetDataInterface) {
        super(props);

        if (!objects) {
            throw new Error(`Trying to make an OccurrenceObject instance with undefined objects`);
        }

        this.queryId = objects[0].objectID;
        this.type = objects[0].objectType;
        this.objectsID = objectsID as string;
    }
}
