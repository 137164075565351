interface DimensionsInterface {
    width: number;
    height: number;
    top: number;
    left: number;
}

/**
 * This function is a wrapper around the access to offsetWidth, offsetHeight,
 * offsetTop and offsetLeft. It simplifies testing cases(by mocking it) where
 * the logic depends on the element dimensions, as jsdom always returns 0 due to
 * not running the test in a real browser.
 */
export default function getElementDimensions<T extends HTMLElement>(element: T): DimensionsInterface {
    return {
        get width() {
            return element.offsetWidth;
        },
        get height() {
            return element.offsetHeight;
        },
        get top() {
            return element.offsetTop;
        },
        get left() {
            return element.offsetLeft;
        },
    };
}
