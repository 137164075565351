export function getInitialsFromName(name: string) {
    return name
        .trim()
        .replace(/\s\s+/g, ' ') //replaces multiple spaces between name and last name
        .split(' ')
        .reduce(function (result, w) {
            return result.charAt(0) + w[0];
        }, '')
        .toUpperCase();
}
