export interface EnvDataInterface {
    id: number;
    name: string;
    sg_uri?: string;
    timezone?: string;
    url?: string;
}

export default class Env {
    readonly id: number;
    name: string;
    readonly securityGatewayUri?: string;
    readonly timezone?: string;
    url: string;

    constructor(data: EnvDataInterface) {
        this.id = data.id;
        this.name = data.name;
        this.securityGatewayUri = data.sg_uri;
        if (data.timezone) {
            this.timezone = data.timezone;
        }

        this.url = data.url || `/${data.name}`;
    }

    static nameToEnvName(name: string) {
        return name.replace(/[^\w\-\s]/g, '').replace(/\s+/g, '-');
    }
}
