import { useEffect, FC } from 'react';
import { post } from 'services/http';

/**
 * Logout component to simulate the sign-out experience.
 */

const FakeLogout: FC = () => {
    useEffect(() => {
        post('/logout').then(() => {
            window.location.replace('/login');
        });
    }, []);

    return <div />;
};

export default FakeLogout;
