import { useState } from 'react';
import User from 'models/User';
import { get as getUsers } from 'services/api/users';
import OverviewItemRow, { SettingOverviewLink } from './ItemRow';
import Logger from 'services/logger';
import { userIsAllowedTo } from 'services/security/accessControl';

const logger = Logger.get('OverviewPeople');

const OverviewPeople = () => {
    const [users, setUsers] = useState<User[]>([]);

    const handleError = () => {
        logger.warn('error while fetching the users');
    };

    const handleSuccess = (usersList: User[]) => {
        setUsers(usersList);
    };

    const urlAttrs: SettingOverviewLink = {
        href: '/settings/people',
        text: 'View People',
    };

    return (
        <OverviewItemRow
            label="People"
            urlAttrs={userIsAllowedTo('listOrgUsers') ? urlAttrs : undefined}
            onLoad={getUsers}
            onError={handleError}
            onSuccess={handleSuccess}
        >
            <span className="flex-grow-1" data-testid="people-info">
                {users.length}
            </span>
        </OverviewItemRow>
    );
};

export default OverviewPeople;
