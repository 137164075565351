import Alert, { AlertTypes } from 'components/messages/Alert';
import { Field } from 'react-final-form-html5-validation';
import { requestOrgReminder } from 'services/api/login';
import { Form } from 'react-final-form';
import useUrl from 'hooks/useUrl';
import BouncerButton from 'components/form/BouncerButton';
import Icon from 'components/icons/Icon';
import * as React from 'react';
import useFormSubmit from 'hooks/useFormSubmit';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

interface FormDataInterface {
    email: string;
}

const FindOrganization = () => {
    const loginUrl = useUrl('login');
    const findEndpoint = useUrl('find');
    const { hash, subscription } = useParams<{ hash: string; subscription: string }>();

    const returnUrl = hash && subscription ? `/marketplace/${hash}?subscription=${subscription}` : loginUrl;

    const { error, success, onSubmit } = useFormSubmit<FormDataInterface>({
        endpoint: findEndpoint,
        service: requestOrgReminder,
    });

    if (success) {
        return <FindOrganizationSuccess loginUrl={returnUrl} />;
    }

    return (
        <>
            <p className="mb3">
                We&apos;ll send you an email with a link to your organization at Database Performance Monitor.
            </p>

            <Alert type={AlertTypes.ERROR} className="mb3" visible={error} sticky>
                An unknown error happened. Please try again or report this situation to our support team.
            </Alert>

            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <fieldset className="mb3">
                            <label>
                                <b className="uppercase fz10 spacing inline-flex items-center">Email:</b>
                                <div className="relative my2">
                                    <Field
                                        component="input"
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email address"
                                        autoComplete="work email"
                                        className="full-width pl4"
                                        autoFocus
                                        required
                                    />
                                    <Icon icon="email" className="grey2 h2 absolute mt1 top-0 left-2" />
                                </div>
                            </label>
                        </fieldset>
                        <fieldset>
                            <div className="inline-flex items-center justify-between full-width">
                                <Link to={returnUrl}>I remember now, let me try</Link>
                                <BouncerButton
                                    className="primary-mainColor"
                                    type="submit"
                                    disabled={submitting || invalid}
                                    bounce={submitting}
                                    bouncerClassName="white inline-flex ml2"
                                >
                                    Send
                                </BouncerButton>
                            </div>
                        </fieldset>
                    </form>
                )}
            />
        </>
    );
};

const FindOrganizationSuccess: React.FC<{ loginUrl: string }> = ({ loginUrl }) => (
    <>
        <Alert type={AlertTypes.SUCCESS} className="mb3" visible sticky>
            If the email you provided is correct and the account exists, then we&apos;ve sent you an email with
            instructions to access your organization.
        </Alert>

        <div className="card-block__find-org absolute right-0">
            <Link to={loginUrl}>I remember now, let me try</Link>
        </div>
    </>
);

export default FindOrganization;
