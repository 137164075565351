import BouncerButton from 'components/form/BouncerButton';
import Actions from 'components/form/Actions';
import { FC, SyntheticEvent, useState } from 'react';
import { remove } from 'services/api/hosts';
import Host, { HostCaptureModes } from 'models/hosts/Host';
import Alert, { AlertTypes } from 'components/messages/Alert';
import Icon from 'components/icons/Icon';

interface DeleteHostPropsInterface {
    onCancel: (host: Host) => void;
    onSuccess: (host: Host) => void;
    onError: (host: Host) => void;
    host: Host;
}

const DeleteHost: FC<DeleteHostPropsInterface> = ({ onCancel, onSuccess, onError, host }) => {
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e: SyntheticEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
            setLoading(true);
            await remove(host);
            onSuccess(host);
        } catch (error) {
            onError(host);
        } finally {
            setLoading(false);
        }
    }

    return (
        <section className="host-info__edit-credentials">
            {
                <div className="form">
                    <form onSubmit={handleSubmit}>
                        <div className="p3">
                            <Alert type={AlertTypes.WARNING} className="mb2" sticky>
                                Be careful, this action CANNOT be undone.
                            </Alert>
                            <p className="line-height-120 py2">
                                You are about to delete the host <strong>{host.name}</strong>.
                            </p>

                            <p className="line-height-120 mt2">
                                <label htmlFor="confirm-check" data-testid="confirm-check">
                                    <input
                                        type="checkbox"
                                        id="confirm-check"
                                        checked={confirmed}
                                        onChange={() => setConfirmed(!confirmed)}
                                    />
                                    <span>I know what I'm doing</span>
                                </label>
                            </p>

                            {host.captureMode === HostCaptureModes.SNIFF && (
                                <div className="flex mt3">
                                    <Icon icon="error" className="orange h2 mr2" />
                                    <p className="relative align__top--3 line-height-120 dialog__delete-host__desc">
                                        You may need to uninstall the Database Performance Monitor software from this
                                        host; uninstallation is a necessary step for on-host (TCP-based)
                                        installations.&nbsp;
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            title="Uninstalling Linux and FreeBSD"
                                            href="https://docs.vividcortex.com/getting-started/installing/#uninstalling-linux-and-freebsd"
                                            className="inline-flex"
                                        >
                                            Learn more
                                        </a>
                                    </p>
                                </div>
                            )}
                        </div>

                        <Actions className="form__actions--dialog p3 bg-white right-align full-width">
                            <button type="button" className="primary-grey" onClick={() => onCancel(host)}>
                                Cancel
                            </button>
                            <BouncerButton
                                bounce={loading}
                                disabled={loading || !confirmed}
                                type="submit"
                                data-testid="confirm-submit"
                                className="primary-red nowrap deleteEnvForm__submit"
                            >
                                Delete this host
                            </BouncerButton>
                        </Actions>
                    </form>
                </div>
            }
        </section>
    );
};

export default DeleteHost;
