import APIRequest from './Request';
import Logger from 'services/logger';
import { get as getUsers } from 'services/api/users';
import Team, { TeamDataInterface } from 'models/Team';
import User from 'models/User';
import Env from 'models/Env';
import Role, { RoleDataInterface } from 'models/Role';

const logger = Logger.get('teams');

export async function create(name: string, role: RoleDataInterface) {
    logger.info(`creating team with name: ${name}`);

    const response = await new APIRequest<TeamDataInterface>({
        path: `teams`,
        method: 'post',
        params: { name, role },
    }).request();

    return new Team(response.data);
}

export async function getTeam(teamId: number) {
    logger.info(`fetching team details for team with ID: ${teamId}`);

    const response = await new APIRequest<TeamDataInterface>({
        path: `teams/${teamId}`,
    }).request();

    return new Team(response.data);
}

export async function get(
    { withUsers, format, forEnv }: { withUsers: boolean; format: 'number' | 'object'; forEnv?: boolean } = {
        withUsers: false,
        format: 'number',
        forEnv: false,
    }
) {
    logger.info('getting the teams');

    const response = await new APIRequest<TeamDataInterface[]>({
        path: forEnv ? 'env/teams' : 'teams',
    }).request();

    const teams = response.data.map(data => new Team(data));
    if (!withUsers) {
        return teams;
    }

    const users = await getUsers();
    const responseWithUsersAndEnvs = await Promise.all(
        teams.map(async team => {
            const teamWithIds = await getTeam(team.id);
            if (format === 'number') {
                return teamWithIds;
            }

            return new Team({
                ...teamWithIds,
                users: teamWithIds.users.map(user => users.find(u => u.id === user.id)) as User[],
            });
        })
    );

    return responseWithUsersAndEnvs.sort((a, b) => (a.name > b.name ? 1 : -1));
}

export async function remove(team: Team) {
    logger.info(`removing team ${team.name}`);

    return await new APIRequest<TeamDataInterface>({
        path: `teams/${team.id}`,
        method: 'delete',
    }).request();
}

export async function addUsers(users: User[], team: Team) {
    logger.info('getting the teams');

    const usersToAdd = users.map(({ id }) => ({ id }));

    if (!usersToAdd.length) {
        throw new Error('No users to add');
    }

    return new APIRequest<TeamDataInterface[]>({
        path: `teams/${team.id}`,
        method: 'patch',
        params: { users: usersToAdd },
    })
        .request()
        .then(() => {
            team.addUsers(usersToAdd);
            users.forEach(user => {
                if (!user.hasTeam(team)) {
                    user.setTeams([...user.teams, team]);
                }
            });
        });
}

export async function rename(team: Team, newName: string) {
    return new APIRequest({
        path: `teams/${team.id}`,
        method: 'patch',
        params: { name: newName },
    }).request();
}

export async function changeRole(team: Team, role: Role) {
    return new APIRequest({
        path: `teams/${team.id}`,
        method: 'patch',
        params: { role: { id: role.id } },
    }).request();
}

export async function updateUsers(team: Team, forAddition: User[], forRemoval: User[]) {
    logger.info(`Updating team ${team.name}`);

    const usersToUpdate: { operation?: 'delete'; id: number }[] = forAddition
        .map(({ id }) => ({ id }))
        .concat(forRemoval.map(({ id }) => ({ operation: 'delete', id })));

    if (!usersToUpdate.length) {
        throw new Error('No users to update');
    }

    await new APIRequest<TeamDataInterface[]>({
        path: `teams/${team.id}`,
        method: 'patch',
        params: { users: usersToUpdate },
    }).request();

    team.addUsers(forAddition);
    team.removeUsers(forRemoval);
    forAddition.forEach(user => {
        if (!user.hasTeam(team)) {
            user.setTeams([...user.teams, team]);
        }
    });

    forRemoval.forEach(user => {
        user.removeFromTeam(team);
    });
}

export async function addEnvToTeam(team: Team, env: Env, role: Role) {
    return new APIRequest({
        path: `teams/${team.id}/envs/${env.id}`,
        method: 'put',
        params: { role: role.id },
    }).request();
}

export async function removeEnvFromTeam(team: Team, env: Env) {
    return new APIRequest({
        path: `teams/${team.id}/envs/${env.id}`,
        method: 'delete',
    }).request();
}
