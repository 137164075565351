import { FC } from 'react';
import Icons from 'assets/icons/integrations/integrations.svg';

const INTEGRATION_NAME_CONVERSION: { [key: string]: string } = {
    MsTeams: 'Microsoft Teams',
};

const IntegrationIcon: FC<{
    className?: string;
    name: string;
    withName?: boolean;
}> = ({ className, name, withName }) => {
    const integrationUrl = `${Icons}#integration-${name.toLowerCase()}`;
    const integrationLabel = INTEGRATION_NAME_CONVERSION[name] || name;
    return (
        <>
            {withName && (
                <>
                    <span
                        id={name}
                        className={`integration__icon relative align__top--1 ml3 pl2 integration__icon--${name.toLowerCase()}`}
                    >
                        {integrationLabel}
                    </span>
                    <title>{integrationLabel}</title>
                </>
            )}
            <svg focusable="false" width="30px" height="30px" className={className}>
                <title>{integrationLabel}</title>
                <use href={integrationUrl} xlinkHref={integrationUrl} data-testid={integrationUrl} />
            </svg>
        </>
    );
};

export default IntegrationIcon;
