export default class Timer {
    private collection: Map<string, number>;

    constructor() {
        this.collection = new Map<string, number>();
    }

    set(name: string, callback: () => void, timeout: number) {
        this.clear(name);
        this.collection.set(
            name,
            window.setTimeout(() => {
                this.clear(name);
                callback();
            }, timeout)
        );
    }

    clear(name: string) {
        if (!this.collection.has(name)) {
            return;
        }

        window.clearTimeout(this.collection.get(name));
        this.collection.delete(name);
    }

    clearAll() {
        this.collection.forEach(timer => window.clearTimeout(timer));
        this.collection.clear();
    }
}
