import { FC } from 'react';
import { MainDataInterface, MainQuantityDataInterface } from 'models/profiler/columnData';
import { PercentageBackgroundBar } from 'components/visualizations/BackgroundBar';
import Quantity from 'components/numbers/Quantity';

interface MainCellPropsInterface extends Omit<MainDataInterface, 'metric'> {
    data: MainQuantityDataInterface;
    metricUrl: string;
}

const MainCell: FC<MainCellPropsInterface> = ({ description, metricUrl, data }) => {
    return (
        <div className="relative full-width" data-testid="profiler-main-cell">
            <div className="query-wrap table-cell middle full-width relative pl2">
                <div className="query-wrap__description absolute bottom-0 top-0">
                    <a className="query-wrap__description__link h4" title={description} href={metricUrl}>
                        {description}
                    </a>
                </div>

                <div className="query-wrap__value monospace float-right relative pr3" data-testid="main-quantities">
                    <Quantity quantity={data.percentage} />
                    <br />
                    <Quantity quantity={data.value} timeSeries={data.series} />
                </div>

                <PercentageBackgroundBar className="mt1" percentage={data.percentage.value} />
            </div>
            <div className="graph-wrap table-cell pr2">
                <div className="graph relative">
                    {/* <SparkLines timeSeries={data.series} width={200} height={50} />
                    <vc-viz-sparkline
                    time-series="cell.timeseries"
                    width="{{ ::sparklinesSize.width }}"
                    height="{{ ::sparklinesSize.height }}"
                    id="{{ sparklineId }}"
                    padding="4"
                    highlight="highlight"
                    on-brush="zoomIn(extent)"
                    auto-clear-brush />
                <vc-time-marker
                    className="absolute full-width"
                    ng-if="showTimeMarker && $root.vc.featureFlags.isEnabled('newChangeMarkers')"
                    data="$root.vc.eventsCollection.filtered.events.all"
                    interval="cell.timeseries.timeInterval"
                ></vc-time-marker> */}
                </div>
            </div>
        </div>
    );
};

export default MainCell;
