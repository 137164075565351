import { useState } from 'react';
import { update as updateCredentials } from 'services/api/credentials';
import SQLServerCredentialModel from 'models/credentials/SQLServerCredential';
import SQLServerCredentialsFields from 'components/credentials/SQLServerCredentialsFields';
import { Form } from 'react-final-form';
import FormActions from 'components/form/Actions';
import BouncerButton from 'components/form/BouncerButton';
import useDisplayMessage from 'hooks/useDisplayMessage';

const SQLServerCredentials = () => {
    const { success, error } = useDisplayMessage();

    const [model, setModel] = useState<SQLServerCredentialModel | undefined>(undefined);

    const handleCredentialsLoad = (credentials?: SQLServerCredentialModel) => {
        if (!credentials) {
            return;
        }
        setModel(credentials);
    };

    const saveChanges = async (formValues: SQLServerCredentialModel) => {
        if (!model) {
            throw new Error('The credentials are undefined');
        }

        model.update(formValues);

        try {
            await updateCredentials(model);
            success('Your credentials have been successfully updated');
        } catch (e) {
            error('There was an error while saving the changes. Please try again later.');
        }
    };

    return (
        <Form
            initialValues={model}
            onSubmit={saveChanges}
            render={({ pristine, invalid, handleSubmit, submitting, values }) => (
                <form className="m3 credential__form" onSubmit={handleSubmit}>
                    <SQLServerCredentialsFields onLoad={handleCredentialsLoad} values={values} />
                    <FormActions className="mt3">
                        <BouncerButton
                            bounce={submitting}
                            disabled={pristine || invalid || submitting}
                            type="submit"
                            className="primary-orange"
                        >
                            Update
                        </BouncerButton>
                    </FormActions>
                </form>
            )}
        />
    );
};

export default SQLServerCredentials;
