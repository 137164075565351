import BestPracticesSummary from 'models/bestPractices/Summary';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import List from './List';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import { FC, useEffect, useState } from 'react';
import ServerError from 'components/messages/ServerError';
import Summary from './Summary';
import useUIStatus from 'hooks/useUIStatus';
import { fetchSummary as fetch } from 'services/api/asserts';
import { getInteractionReporter } from 'services/analytics/analytics';
import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';
import ModuleTitle from 'components/modules/app/ModuleTitle';

enum States {
    LOADING = 'loading',
    READY = 'ready',
    ERROR = 'error',
}

const reportActivity = getInteractionReporter('best-practices');

const BestPractices: FC = () => {
    const [uiStatus, setUIStatus] = useUIStatus(
        {
            showPassed: false,
            showSuppressed: false,
        },
        {
            id: 'best-practices',
        }
    );

    const [summary, setSummary] = useState<BestPracticesSummary | undefined>();
    const [categories, setCategories] = useState<BestPracticesSummary | undefined>();
    const [state, setState] = useState({
        state: States.LOADING,
        showPassed: !!uiStatus.showPassed,
        showMuted: !!uiStatus.showSuppressed,
    });

    const { interval } = useTimeInterval();
    const { filter, noMatchingHosts } = useHostFilter();

    useEffect(() => {
        fetchCategories();
    }, [filter, interval, state.showPassed, state.showMuted]); // eslint-disable-line

    useEffect(() => {
        fetchSummary();
    }, [filter, interval]); // eslint-disable-line

    function fetchCategories() {
        fetch({ interval, showPassed: state.showPassed, showMuted: state.showMuted, host: filter }, categories)
            .then((fetchedCategories: BestPracticesSummary) => {
                setCategories(fetchedCategories);
                setState({ ...state, state: States.READY });
            })
            .catch(() => setState(currentState => ({ ...currentState, state: States.ERROR })));
    }

    function fetchSummary() {
        fetch({ interval, showPassed: true, showMuted: true, host: filter })
            .then((fetchedSummary: BestPracticesSummary) => {
                setSummary(fetchedSummary);
            })
            .catch(() => setState(currentState => ({ ...currentState, state: States.ERROR })));
    }

    function togglePassed() {
        const showPassed = !state.showPassed;

        setUIStatus({ showPassed });
        setState({ ...state, showPassed });

        reportActivity('filter', showPassed ? 'show passed' : 'hide passed');
    }

    function toggleMuted() {
        const showMuted = !state.showMuted;

        setUIStatus({ showSuppressed: showMuted });
        setState({ ...state, showMuted });

        reportActivity('filter', showMuted ? 'show suppressed' : 'hide suppressed');
    }

    function afterMuteToggle() {
        fetchCategories();
        fetchSummary();
    }

    const hasOccurrences = summary && summary.categories.length > 0;

    return (
        <div className="view best-practices" data-testid="best-practices">
            <div className="pb3">
                <ModuleTitle contextualHelpId="best-practices">Best Practices</ModuleTitle>
            </div>
            {summary && hasOccurrences && <Summary summary={summary} />}

            {state.state === States.LOADING && (
                <div className="mb3 p3 center nowrap">
                    <Loader className="loader--thick mb3" type={LoaderTypes.TABLECELL}></Loader>
                </div>
            )}

            {state.state === States.ERROR && (
                <div className="mb3 p3 center">
                    <ServerError />
                </div>
            )}

            {categories && hasOccurrences && (
                <List
                    summary={categories}
                    showPassed={state.showPassed}
                    showMuted={state.showMuted}
                    togglePassed={togglePassed}
                    toggleMuted={toggleMuted}
                    afterMuteToggle={afterMuteToggle}
                />
            )}

            {!noMatchingHosts && !hasOccurrences && (
                <EmptyState type={EmptyTypes.OK}>
                    Awesome <br />
                    There are no occurrences to display
                </EmptyState>
            )}
        </div>
    );
};

export default BestPractices;
