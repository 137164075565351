import { APIResponse, APIResponseInterface } from './Response';
import {
    RequestParamInterface as ReqParamInterface,
    requestParamsEncoder,
    EncodedParamsType,
    RequestParamEncoder,
} from './params/requestParamsEncoder';
import { RequestErrorHandler } from './error/RequestErrorHandler';
import axios, { AxiosError, CancelTokenSource, AxiosRequestConfig } from 'axios';
import { getAuthHeader } from './auth';
import { get as getContext } from 'services/context';

interface APIRequestParamsInterface<ParamType> {
    path: string;
    method?: RequestMethod;
    params?: ParamType | EncodedParamsType;
    headers?: HeadersInterface;
    timeout?: number;
    errorHandler?: typeof RequestErrorHandler;
    target?: RequestTarget;
    paramsEncoders?: RequestParamEncoder[];
    requiresAuth?: boolean;
}

export interface APIRequestInterface<ResponseType, ResponseStructureType> {
    request(): Promise<APIResponseInterface<ResponseType, ResponseStructureType>>;
    cancel(): void;
    isInProgress: boolean;
}

interface HeadersInterface {
    [key: string]: string;
}

export type RequestMethod = 'get' | 'post' | 'put' | 'delete' | 'head' | 'patch';

export enum TargetEnv {
    CURRENT_ORG = 'org',
    CURRENT_ENV = 'env',
}

export type RequestTarget = TargetEnv | number;

const pathPrefix = '/api/v2/';

const getDefaultTarget = () => {
    const context = getContext();
    return context.env ? TargetEnv.CURRENT_ENV : TargetEnv.CURRENT_ORG;
};

class APIRequest<ResponseType, ParamType = ReqParamInterface, ResponseStructureType = { data: ResponseType }>
    implements APIRequestInterface<ResponseType, ResponseStructureType>
{
    private inProgress = false;
    private headers: HeadersInterface;
    private params: ParamType | EncodedParamsType;
    private timeout: number;
    private path: string;
    private method: RequestMethod;
    private axiosIdentifier?: CancelTokenSource;
    private errorHandler: typeof RequestErrorHandler;
    private target: RequestTarget;
    private requiresAuth: boolean;

    constructor({
        path,
        method = 'get',
        params,
        headers = {},
        timeout = 300000,
        errorHandler = RequestErrorHandler,
        target = getDefaultTarget(),
        paramsEncoders,
        requiresAuth = true,
    }: APIRequestParamsInterface<ParamType>) {
        this.path = pathPrefix + path;
        this.method = method;
        this.headers = headers;
        this.params = this.encodeParams(params as ParamType, paramsEncoders);
        this.timeout = timeout;
        this.errorHandler = errorHandler;
        this.headers = { ...headers };
        this.target = target;
        this.requiresAuth = requiresAuth;
    }

    async request(): Promise<APIResponseInterface<ResponseType, ResponseStructureType>> {
        this.inProgress = true;
        const config = await this.buildRequestConfig();
        try {
            const response = await axios(config);
            return new APIResponse(response);
        } catch (error) {
            throw this.errorHandler.handle(error as AxiosError);
        } finally {
            this.onRequestFinished();
        }
    }

    cancel() {
        if (!this.axiosIdentifier) {
            throw new Error("Can't cancel a request that hasn't been initiated");
        }
        this.axiosIdentifier.cancel();
        this.inProgress = false;
    }

    get isInProgress() {
        return this.inProgress;
    }

    private async buildRequestConfig() {
        const CancelToken = axios.CancelToken;
        this.axiosIdentifier = CancelToken.source();

        if (this.requiresAuth) {
            this.headers.Authorization = await getAuthHeader(this.target);
        }

        const config: AxiosRequestConfig = {
            method: this.method,
            headers: this.headers,
            url: this.path,
            timeout: this.timeout,
            cancelToken: this.axiosIdentifier.token,
        };

        const key: keyof AxiosRequestConfig = this.hasBodyParams() ? 'data' : 'params';
        config[key] = this.params;

        return config;
    }

    private hasBodyParams() {
        return ['put', 'post', 'patch'].includes(this.method);
    }

    private onRequestFinished() {
        this.inProgress = false;
        this.axiosIdentifier = undefined;
    }

    private encodeParams(params?: ParamType, paramsEncoders?: RequestParamEncoder[]) {
        if (this.hasBodyParams()) {
            return params;
        }
        return requestParamsEncoder<ParamType>(params, paramsEncoders);
    }
}

export default APIRequest;
