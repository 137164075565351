import { FC, useContext } from 'react';
import ReactDOM from 'react-dom';
import ModalContext from 'components/context/Modal';

const ModalRenderer: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const container = useContext(ModalContext);

    if (!container || !container.current) {
        return null;
    }

    return ReactDOM.createPortal(children, container.current);
};

export default ModalRenderer;
