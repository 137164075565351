import { useBootstrap } from 'components/context/Bootstrap';
import { AuthenticatedBootstrap } from 'models/Bootstrap';

const useAuthenticatedContext = () => {
    const [{ bootstrap }] = useBootstrap();

    if (!(bootstrap instanceof AuthenticatedBootstrap)) {
        throw new Error('The bootstrap data does not belong to an authenticated context.');
    }

    return bootstrap as AuthenticatedBootstrap;
};

export default useAuthenticatedContext;
