import * as React from 'react';
import SettingsNavigation from './Navigation';
import { Helmet } from 'react-helmet';

interface SettingsLayoutPropsInterface {
    title?: string;
    headerContent?: React.ReactNode;
    metaTitle?: string;
    children?: React.ReactNode;
}

const SettingsLayout: React.FC<SettingsLayoutPropsInterface> = ({ title, metaTitle, children, headerContent }) => (
    <>
        <Helmet>
            <title>Settings - {metaTitle || title} - DPM</title>
        </Helmet>
        <div className="view settings p4 view--salesforce mb4">
            <div className="flex tile">
                <SettingsNavigation />
                <section className="settings__section-content flex-grow-1 p3">
                    <div className="flex items-center">
                        <div className="flex items-baseline flex-grow-1">
                            {title && (
                                <h1 data-testid="settings-title" className="h2 mr2 lighter grey3 flex-grow-1">
                                    {title}
                                </h1>
                            )}
                        </div>
                        {headerContent}
                    </div>
                    {children}
                </section>
            </div>
        </div>
    </>
);
export default SettingsLayout;
