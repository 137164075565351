import { useState, useEffect, FC } from 'react';
import Icon from 'components/icons/Icon';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import cn from 'helpers/classname';

type DropdownDirection = 'right' | 'left';

interface DropdownProps {
    text?: string;
    icon?: string;
    iconClassName?: string;
    outsideClickStopsPropagation?: boolean;
    direction?: DropdownDirection;
    isExpanded?: boolean;
    className?: string;
    onToggle?: (expanded: boolean) => void;
    textIcon?: string;
    expandedClassName?: string;
    color?: string;
    children?: React.ReactNode;
}

const Dropdown: FC<DropdownProps> = ({
    text,
    icon,
    iconClassName = 'h2 mainColor cursor-hand relative',
    outsideClickStopsPropagation = true,
    isExpanded = false,
    direction = 'left',
    className,
    onToggle = () => {},
    children,
    textIcon,
    expandedClassName = 'dropdown--open',
    color = 'mainColor',
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { ref } = useOnOutsideClick<HTMLDivElement>((e: MouseEvent) => {
        if (!expanded) {
            return;
        }

        if (outsideClickStopsPropagation) {
            e.stopPropagation();
        }

        setExpanded(false);
        onToggle(false);
    });

    const handleToggle = () => {
        setExpanded(!expanded);
        onToggle(!expanded);
    };

    useEffect(() => {
        setExpanded(isExpanded);
    }, [isExpanded]);

    return (
        <div className={`${className} relative`} ref={ref} data-testid="dropdown-container">
            {text && (
                <button
                    className={`nowrap ${color} fz14 relative dropdown ${cn(expandedClassName, expanded)}`}
                    onClick={handleToggle}
                    data-testid="dropdown-toggle"
                >
                    {textIcon && <Icon icon={textIcon} className="h2 mr2 grey3" />}
                    {text}
                    <Icon
                        icon="keyboard_arrow_down"
                        className="fz16 ml2 mainColor relative align__top--2 dropdown__arrow-icon"
                    />
                </button>
            )}
            {icon && (
                <div
                    onClick={handleToggle}
                    data-testid="dropdown-icon"
                    className={`dropdown relative ${cn(expandedClassName, expanded)}`}
                >
                    <Icon icon={icon} className={iconClassName} />
                </div>
            )}
            {expanded && <div className={`dropdown__content absolute ${direction}-0 top-4 bg-white`}>{children}</div>}
        </div>
    );
};

export default Dropdown;
