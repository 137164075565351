import CalendarHelper, { CalendarDay } from 'helpers/timePicker/calendar';
import cn from 'helpers/classname';
import Icon from 'components/icons/Icon';
import { SyntheticEvent, useEffect, useState, FC } from 'react';

interface CalendarInterface {
    timestamp: number;
    className?: string;
    timezone: string;
    onChange(arg0: number): void;
}

const CalendarTimePicker: FC<CalendarInterface> = ({ timestamp, className = '', timezone, onChange }) => {
    const [calendar, setCalendar] = useState(new CalendarHelper(timestamp, timezone));
    const [date, setDate] = useState(calendar.date);
    const [time, setTime] = useState(calendar.time);

    function handleDateChange(e: SyntheticEvent<HTMLInputElement>) {
        setDate(e.currentTarget.value);
    }

    function handleTimeChange(e: SyntheticEvent<HTMLInputElement>) {
        setTime(e.currentTarget.value);
    }

    function handleDateBlur() {
        const datetime = CalendarHelper.buildDateTime(date, time, timezone);

        if (!datetime) {
            setDate(calendar.date);
            return;
        }

        onChange(datetime.unix());
    }

    function handleTimeBlur() {
        const datetime = CalendarHelper.buildDateTime(date, time, timezone);

        if (!datetime) {
            setTime(calendar.time);
            return;
        }

        onChange(datetime.unix());
    }

    function prev() {
        setCalendar(calendar.prevMonth());
    }

    function next() {
        setCalendar(calendar.nextMonth());
    }

    function select(day: CalendarDay) {
        onChange(day.timestamp);
    }

    useEffect(() => {
        const helper = new CalendarHelper(timestamp, timezone);

        setCalendar(helper);
        setDate(helper.date);
        setTime(helper.time);
    }, [timestamp, timezone]);

    return (
        <div className={`vc-datetime-input-container ${className}`} data-testid="calendar-component">
            <div className="flex my2">
                <input
                    className={`vc-datetime-input-container__input vc-datetime-input-container__input--date bg-white no-border mr2 center${cn(
                        'error',
                        !calendar.validateDate(date)
                    )}`}
                    type="text"
                    value={date}
                    onChange={handleDateChange}
                    onBlur={handleDateBlur}
                />
                <input
                    className={`vc-datetime-input-container__input vc-datetime-input-container__input--time bg-white no-border center${cn(
                        'error',
                        !calendar.validateTime(time)
                    )}`}
                    type="text"
                    value={time}
                    onChange={handleTimeChange}
                    onBlur={handleTimeBlur}
                />
            </div>
            <div
                className="calendar bg-white p3 flex flex-column center fz12 dark overflow-hidden"
                data-testid="timepicker-calendar"
            >
                <div className="flex flex-wrap items-center left-align">
                    <span className="h4 dark flex-grow-1 justify-start">
                        {calendar.month}, {calendar.year}
                    </span>
                    <button className="flex bg-grey2 circle cursor-hand mr2" onClick={prev}>
                        <Icon icon="keyboard_arrow_left" className="flex white fz18" />
                    </button>
                    <button className="flex bg-grey2 circle cursor-hand" onClick={next}>
                        <Icon icon="keyboard_arrow_right" className="flex white fz18" />
                    </button>
                    <ol className="list-reset full-width flex justify-around uppercase py2 fz10 grey3 overflow-auto">
                        <li>s</li>
                        <li>m</li>
                        <li>t</li>
                        <li>w</li>
                        <li>t</li>
                        <li>f</li>
                        <li>s</li>
                    </ol>
                </div>
                <ol className="days list-reset center overflow-visible dark z1">
                    {calendar.days.map(day => (
                        <li
                            className={`cursor-hand overflow-visible relative${cn('not-this-month', day.pastMonth)}${cn(
                                'future',
                                day.future
                            )}${cn('selected', day.timestamp === timestamp)}`}
                            onClick={() => select(day)}
                            key={day.timestamp}
                            data-testid={day.timestamp === timestamp ? 'calendar-date-selected' : undefined}
                        >
                            <span className="relative">{day.day}</span>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

export default CalendarTimePicker;
