import { CPRInterface, CPRNodeInterface, CPREdgeInterface } from './Sample';
import { PlanbookDataInterface } from 'services/api/queries';
import Quantity from 'models/numbers/Quantity';
import { fromName as unitFromName } from 'models/numbers/Unit';
import { notifyError } from 'services/error/reporter';

const e0 = unitFromName('e0');
const percentage = unitFromName('percentage');

interface NodeDisplayInterface {
    cost: {
        value: string;
        percentage: number;
    };
}

interface EdgeDisplayInterface {
    label: string;
}

export interface NodeInterface {
    id: number;
    label: string;
    display: NodeDisplayInterface;
    extra: Record<string, unknown>;
    style?: string;
}

export interface EdgeInterface {
    source: number;
    target: number;
    display: EdgeDisplayInterface;
    style?: string;
}

class VisualExplainPlan {
    readonly nodes: NodeInterface[] = [];
    readonly edges: EdgeInterface[] = [];
    private costSum = 0;

    private calculateCostSum() {
        this.cpr.nodes.forEach(cprNode => {
            const costProperty = this.planbook.nodes[cprNode.type]?.cost || this.planbook.defaultNode.cost;
            const costValue: number =
                cprNode.metadata && typeof cprNode.metadata[costProperty] === 'number'
                    ? (cprNode.metadata[costProperty] as number)
                    : 0;

            this.costSum = this.costSum + costValue;
        });
    }

    /**
     * Obtains the cost percentage for a node by dividing the cost of that node by the sum of the costs of all the nodes.
     *
     * @param nodeCost The cost value of the node for which we want to obtain its percentage
     * @param costPropName The prop name that represents the cost
     * @returns
     */
    private calculateCostPercentage(nodeCost: number) {
        const costPercentage = this.costSum > 0 ? nodeCost / this.costSum : 0;
        return new Quantity(costPercentage, percentage).value;
    }

    private formatEdgeLabel(value: unknown) {
        if (typeof value === 'number') {
            return new Quantity(value, e0).toString().trim();
        }

        return `${value}`;
    }

    private buildNodeData(node: CPRNodeInterface, costPropName: string): NodeDisplayInterface {
        if (!node.metadata || !(costPropName in node.metadata)) {
            notifyError(new Error(`Cost property ${costPropName} not present in the CPR node ${node.type}`));
        }

        const costValue =
            node.metadata && typeof node.metadata[costPropName] === 'number'
                ? (node.metadata[costPropName] as number)
                : 0;

        const nodeData: NodeDisplayInterface = {
            cost: {
                value: new Quantity(costValue, e0).toString().trim(),
                percentage: this.calculateCostPercentage(costValue),
            },
        };

        return nodeData;
    }

    private buildEdgeData(edge: CPREdgeInterface, labelProp: string): EdgeDisplayInterface {
        //Find the source node referenced by the edge
        const node = this.cpr.nodes.find(({ id }) => id === edge.source);

        if (!node?.metadata || node.metadata[labelProp] === undefined || node.metadata[labelProp] === null) {
            return { label: '[N/A]' };
        }

        return { label: this.formatEdgeLabel(node.metadata[labelProp]) };
    }

    private buildExtraData(node: CPRNodeInterface, nodePropsToShow: string[]) {
        const nodeProperties = nodePropsToShow.filter(itemName => {
            if (!node.metadata || !(itemName in node.metadata)) {
                notifyError(new Error(`Property ${itemName} not present in the CPR node ${node.type}`));
            }
            return node.metadata && itemName in node.metadata;
        });

        const nodeData: Record<string, unknown> = {};

        nodeProperties.forEach(itemName => {
            if (node.metadata) {
                nodeData[itemName] = node.metadata[itemName];
            }
        });

        return nodeData;
    }
    /**
     * Creates a node array where each node contains all necessary data to be displayed in a visualization
     * This data comes from the combination of the CPR and the planbook.
     */
    private buildNodes(): NodeInterface[] {
        return this.cpr.nodes.map(cprNode => {
            const planbookConfig = this.planbook.nodes[cprNode.type] || this.planbook.defaultNode;

            const costProperty = planbookConfig?.cost || '';
            const extra = planbookConfig?.extra || [];

            const node = {
                id: cprNode.id,
                label: cprNode.label,
                style: planbookConfig?.style,
                display: this.buildNodeData(cprNode, costProperty),
                extra: this.buildExtraData(cprNode, extra),
            };

            return node;
        });
    }

    /**
     * Creates an edge array where each edge links nodes by id and also contains display and styling data
     * This data comes from the combination of the CPR and the planbook.
     */
    private buildEdges(): EdgeInterface[] {
        return this.cpr.edges.map(cprEdge => {
            const planbookConfig = this.planbook.edges[cprEdge.type];

            if (!planbookConfig) {
                notifyError(new Error(`Edge type ${cprEdge.type} not present in the planbook`));
            }

            const labelProperty = planbookConfig?.width || '';

            const edge = {
                // The Diagram expects this information to be flipped in order to build the layout correctly
                // More info here: https://github.com/VividCortex/webapp/pull/1070#pullrequestreview-669408289
                source: cprEdge.target,
                target: cprEdge.source,
                style: planbookConfig?.style,
                display: this.buildEdgeData(cprEdge, labelProperty),
            };

            return edge;
        });
    }

    constructor(private cpr: CPRInterface, private planbook: PlanbookDataInterface) {
        this.calculateCostSum();

        this.nodes = this.buildNodes();
        this.edges = this.buildEdges();
    }
}

export default VisualExplainPlan;
