import * as React from 'react';
import Bouncer from 'components/icons/Bouncer';

type BouncerButtonProps = { bouncerClassName?: string; bounce: boolean } & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

const BouncerButton: React.FunctionComponent<BouncerButtonProps> = ({
    bouncerClassName,
    children,
    bounce,
    ...rest
}) => {
    const bouncer = bounce ? <Bouncer className={bouncerClassName} /> : '';

    return (
        <button {...rest}>
            {children}
            {bouncer}
        </button>
    );
};

export default BouncerButton;
