import Alert, { AlertTypes } from 'components/messages/Alert';
import * as React from 'react';
import useUser from 'hooks/useUser';
import { AuthConfigDataInterface, isOAuthEmailValid, isOAuthEmailDomainValid } from 'services/settings/authentication';

interface GoogleConfigurationPropsInterface {
    config: AuthConfigDataInterface;
    setupOAuth: () => void;
}

const GoogleConfiguration: React.FC<GoogleConfigurationPropsInterface> = ({ config, setupOAuth }) => {
    const user = useUser();

    const emailsDontMatch = config['auth.oauth.email'] && !isOAuthEmailValid(config);
    const emailIsNotValid = config['auth.oauth.google.emailDomain'] && !isOAuthEmailDomainValid(config);
    const configured = !emailsDontMatch && !emailIsNotValid && config['auth.oauth.google.emailDomain'];

    return (
        <>
            <div className="mt3" data-testid="google-provider-setup">
                {!config['auth.oauth.google.emailDomain'] && (
                    <p className="grey3 line-height-120">
                        The email address of your Google G Suite account must match your email address in Database
                        Performance Monitor.
                    </p>
                )}

                {config['auth.oauth.google.emailDomain'] && (
                    <dl className="mt2">
                        <dt className="uppercase fz10 spacing inline-flex bold">Google G Suite domain:</dt>
                        <dd className="ml3 inline-flex" data-testid="google-domain">
                            {config['auth.oauth.google.emailDomain']}
                        </dd>
                    </dl>
                )}

                {emailsDontMatch && (
                    <Alert type={AlertTypes.WARNING} sticky className="my2">
                        Your Google email address ({config['auth.oauth.email']}) is not the same as your email address
                        in Database Performance Monitor ({user.email}). Please note that, once the authentication method
                        is set to OAuth, you won't be able to sign-in again using your current user.
                    </Alert>
                )}

                {emailIsNotValid && (
                    <Alert type={AlertTypes.ERROR} sticky className="my2">
                        Google accounts are not allowed. You must authenticate with your Google G Suite account.
                    </Alert>
                )}
            </div>

            {!configured && (
                <button type="button" className="primary-orange mt2" onClick={() => setupOAuth()}>
                    Initiate OAuth Setup
                </button>
            )}
        </>
    );
};

export default GoogleConfiguration;
