import * as React from 'react';
import ContextualHelp from 'components/documentation/ContextualHelp';
import Icon from 'components/icons/Icon';

interface HeaderInterface {
    title?: string;
    rightContent?: React.ReactNode;
    leftContent?: React.ReactNode;
    contextualHelpId?: string;
}

const Header: React.FC<HeaderInterface> = ({ title, rightContent, leftContent, contextualHelpId }) => (
    <>
        {(rightContent || leftContent) && (
            <div className="filters">
                {leftContent && (
                    <div className="flex items-center flex-grow-1 full-height overflow-auto">{leftContent}</div>
                )}
                {rightContent && (
                    <div className="pl3 pr2 border-left border-color-grey1 flex items-center full-height">
                        {rightContent}
                    </div>
                )}
            </div>
        )}
        <div className="flex items-end py3" data-testid="inventory-title">
            <h1 className="flex items-center h2 mr2 lighter">{title}</h1>
            {contextualHelpId && (
                <ContextualHelp id={contextualHelpId}>
                    Learn more
                    <Icon icon="keyboard_arrow_right" className="inline-flex fz18 mr2 mainColor relative top-1" />
                </ContextualHelp>
            )}
        </div>
    </>
);
export default Header;
