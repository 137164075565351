import { FC, useState, useEffect } from 'react';
import AlertRow from './Row';
import LoaderRow from 'components/loaders/LoaderRow';
import Integration from 'models/Integration';
import {
    getAlerts,
    getAlertTypes,
    getIntegrations,
    APIAlertInterface,
    APIAlertTypeInterface,
    APIDestinationInterface,
    updateAlert,
} from 'services/api/envConfig';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import useDisplayMessage from 'hooks/useDisplayMessage';
import { userIsAllowedTo } from 'services/security/accessControl';

const AlertsTable: FC<{
    destinations: APIDestinationInterface[];
}> = ({ destinations }) => {
    const [alerts, setAlerts] = useState<APIAlertInterface[]>([]);
    const [types, setTypes] = useState<APIAlertTypeInterface[]>([]);
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [state, setState] = useState({
        isLoading: true,
        errorOnFetch: false,
    });

    const { error } = useDisplayMessage();

    const fetchAlerts = async () => {
        setState({ ...state, errorOnFetch: false, isLoading: true });

        try {
            setAlerts(await getAlerts());
            setTypes(await getAlertTypes());
            setIntegrations(await getIntegrations());
            setState({ ...state, errorOnFetch: false, isLoading: false });
        } catch {
            setState({ ...state, errorOnFetch: true, isLoading: false });
        }
    };

    const handleAlertSwitch = async (alert: APIAlertInterface) => {
        try {
            const newAlert = await updateAlert(alert);

            if ((newAlert.destinations === null || !newAlert.destinations.length) && !!newAlert.disabled) {
                error('An integration must be connected to this alert before it can be enabled.');
            }

            setAlerts((currentAlerts: APIAlertInterface[]) => {
                const newAlerts = [...currentAlerts];
                const oldAlert = newAlerts.find(a => a.id === newAlert.id);

                if (oldAlert === undefined) {
                    return newAlerts;
                }

                newAlerts.splice(newAlerts.indexOf(oldAlert), 1, newAlert);
                return newAlerts;
            });
        } catch {
            error('An error occurred. Please refresh the page and try again.');
        }
    };

    const handleDelete = async (alert: APIAlertInterface) => {
        setAlerts((currentAlerts: APIAlertInterface[]) => {
            const newAlerts = [...currentAlerts];
            newAlerts.splice(newAlerts.indexOf(alert), 1);
            return newAlerts;
        });
    };

    const integrationsForAlert = (alert: APIAlertInterface) => {
        const destinationsForAlert = destinations
            .filter(d => alert.destinations?.includes(d.id))
            .map(d => d.integration);
        return integrations.filter(i => destinationsForAlert.includes(i.id)).map(i => i.name);
    };

    useEffect(() => {
        fetchAlerts();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {!state.isLoading && !state.errorOnFetch && alerts.length === 0 && (
                <EmptyState type={EmptyTypes.INFO}>There are no alerts created for this environment.</EmptyState>
            )}
            {!state.isLoading && state.errorOnFetch && (
                <EmptyState type={EmptyTypes.SERVER}>
                    There was an error fetching alert information. Please try again in a few minutes.
                </EmptyState>
            )}
            {(state.isLoading || (alerts.length > 0 && !state.errorOnFetch)) && (
                <div className="my3">
                    <table className="vc-table" data-testid="alerts-table">
                        <thead>
                            <tr>
                                <th className="vc-table--alert-list__status">Status</th>
                                <th className="full-width">Name</th>
                                <th className="vc-table--alert-list__type">Type</th>
                                <th>Integrations</th>
                                {userIsAllowedTo('changeEnvSettings') && (
                                    <th className="vc-table--alert-list__actions"></th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {state.isLoading && <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 5 : 4} />}
                            {!state.isLoading &&
                                alerts.map(alert => (
                                    <AlertRow
                                        key={alert.id}
                                        alert={alert}
                                        type={types.find(type => type.id === alert.type)?.name || 'Unknown'}
                                        integrations={integrationsForAlert(alert)}
                                        onChange={handleAlertSwitch}
                                        onDeleted={handleDelete}
                                    />
                                ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default AlertsTable;
