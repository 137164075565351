import Modal from 'components/modals/Modal';
import { FC } from 'react';
import Host from 'models/hosts/Host';
import { Tab, TabPanel, Tabs } from 'components/tabs/Tabs';
import EditHost from './EditHost';
import EditCredentials from './EditCredentials';
import DeleteHost from './DeleteHost';
import SamplesCollection from './SamplesCollection';

interface EditSettingsModalPropsInterface {
    visible: boolean;
    onClose: () => void;
    onSuccess: (host: Host) => void;
    onError: (host: Host) => void;
    onDeleteSuccess: (host: Host) => void;
    onDeleteError: (host: Host) => void;
    host: Host;
}

const EditSettingsModal: FC<EditSettingsModalPropsInterface> = ({
    visible = false,
    onClose,
    onSuccess,
    onError,
    onDeleteSuccess,
    onDeleteError,
    host,
}) => {
    const handleHostEditError = (selectedHost: Host) => {
        onError(selectedHost);
    };

    const handleHostEditSuccess = (selectedHost: Host) => {
        onSuccess(selectedHost);
    };

    const showCredentialsTab = () => {
        return host.isDatabase && host.type !== 'vitess';
    };

    const showSamplesCollectionTab = () => {
        return false;
    };

    return (
        <Modal title={`Edit host ${host.name}`} visible={visible} onClose={onClose}>
            <Tabs defaultTab="edit-host" className="edit-host">
                <div className="tabs mx3 border-bottom border-thick border-color-grey1">
                    <Tab name="edit-host" className="flex dark no-decoration fz16">
                        <div className="flex items-center py3">Edit Host</div>
                    </Tab>
                    {showCredentialsTab() && (
                        <Tab name="edit-credentials" className="flex dark no-decoration fz16">
                            <div className="flex items-center py3">Edit Credentials</div>
                        </Tab>
                    )}
                    {showSamplesCollectionTab() && (
                        <Tab name="temporarily-collect-samples" className="flex dark no-decoration fz16">
                            <div className="flex items-center py3">Temporarily Collect Samples</div>
                        </Tab>
                    )}
                    <Tab name="delete-host" className="flex dark no-decoration fz16">
                        <div className="flex items-center py3">Delete</div>
                    </Tab>
                </div>

                <div className="full-width">
                    <TabPanel name="edit-host">
                        <EditHost
                            host={host}
                            onCancel={onClose}
                            onSuccess={handleHostEditSuccess}
                            onError={handleHostEditError}
                        />
                    </TabPanel>
                    {showCredentialsTab() && (
                        <TabPanel name="edit-credentials">
                            <EditCredentials host={host} onCancel={onClose} onSuccess={onSuccess} onError={onError} />
                        </TabPanel>
                    )}
                    {showSamplesCollectionTab() && (
                        <TabPanel name="temporarily-collect-samples">
                            <SamplesCollection host={host} onCancel={onClose} onSuccess={onSuccess} onError={onError} />
                        </TabPanel>
                    )}
                    <TabPanel name="delete-host">
                        <DeleteHost
                            host={host}
                            onCancel={onClose}
                            onSuccess={onDeleteSuccess}
                            onError={onDeleteError}
                        />
                    </TabPanel>
                </div>
            </Tabs>
        </Modal>
    );
};

export default EditSettingsModal;
