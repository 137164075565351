import Icon from 'components/icons/Icon';
import * as React from 'react';

interface ControlsPropsInterface {
    showPassed: boolean;
    showMuted: boolean;
    togglePassed: () => void;
    toggleMuted: () => void;
    toggleAll: (arg0: boolean) => void;
}

const BestPracticesControls: React.FC<ControlsPropsInterface> = ({
    showPassed,
    showMuted,
    togglePassed,
    toggleMuted,
    toggleAll,
}) => {
    return (
        <section className="tile rounded relative landing-card mb2 flex">
            <div className="flex p2 border-right border-color-grey1 best-practices__togglers no-shrink">
                <span className="flex items-center mr3 cursor-hand nowrap mainColor" onClick={() => toggleAll(true)}>
                    <Icon icon="expand_all" className="mainColor h4 mr1" />
                    <span className="bold h4">Expand All</span>
                </span>
                <span
                    id="best-practices-collapse-all"
                    className="flex items-center cursor-hand nowrap mainColor"
                    onClick={() => toggleAll(false)}
                >
                    <Icon icon="expand_all" className="mainColor h4 flip-vertical mr1" />
                    <span className="bold h4">Collapse All</span>
                </span>
            </div>
            <div className="full-width flex items-center">
                <label htmlFor="vcCheckboxPassed" className="pl3">
                    <input
                        type="checkbox"
                        id="vcCheckboxPassed"
                        data-testid="bp-checkbox-passed"
                        value="1"
                        checked={showPassed}
                        onChange={togglePassed}
                    />
                    <span className="h4">Show passed best practices</span>
                </label>
                <label htmlFor="vcCheckboxMuted" className="pl3">
                    <input
                        type="checkbox"
                        id="vcCheckboxMuted"
                        value="1"
                        data-testid="bp-checkbox-muted"
                        checked={showMuted}
                        onChange={toggleMuted}
                    />
                    <span className="h4">Show muted best practices</span>
                </label>
            </div>
        </section>
    );
};

export default BestPracticesControls;
