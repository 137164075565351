import TimeAgo from 'components/format/TimeAgo';
import StatusBulb, { StatusBulbValues } from 'components/icons/StatusBulb';
import Popover from 'components/messages/Popover';
import Agent from 'models/Agent';
import { FC } from 'react';
import { userIsAllowedTo } from 'services/security/accessControl';
import AsyncHostAttribute from 'components/host/AsyncHostAttribute';
import AgentActions from './Actions';
import { useFinalActionModal } from 'components/context/FinalActionModal';

const AgentRow: FC<{
    agent: Agent;
    onToggle: (agent: Agent) => void;
    selected: boolean;
    onStartAgent: (agents: Agent[]) => void;
    onStopAgent: (agents: Agent[]) => void;
    onRestartAgent: (agents: Agent[]) => void;
    onLogFetched?: (log: string, agent: Agent) => void;
}> = ({ agent, onToggle, selected, onStartAgent, onStopAgent, onRestartAgent, onLogFetched = () => {} }) => {
    const { openModal } = useFinalActionModal();

    return (
        <tr data-testid="agent-row" className="agents__row">
            <td className="vc-table--agents__status">
                <div className="flex items-center flex justify-center">
                    {userIsAllowedTo('changeEnvSettings') && (
                        <div className="inline-flex">
                            <label>
                                <input
                                    type="checkbox"
                                    data-testid="agent-checkbox"
                                    checked={selected}
                                    onChange={() => {
                                        onToggle(agent);
                                    }}
                                />
                                <span />
                            </label>
                        </div>
                    )}
                    <StatusBulb
                        status={
                            (agent.hasWarnings && StatusBulbValues.warning) ||
                            (agent.isOff && StatusBulbValues.off) ||
                            StatusBulbValues.ok
                        }
                    />
                    {agent.hasWarnings && (
                        <Popover icon="info" className="cursor-hand ml2">
                            <div className="agent-status-popover">
                                <div>
                                    <strong className="fz10 spacing uppercase">Last successful report:</strong>
                                    <span className="monospace fz13">
                                        <TimeAgo date={agent.lastSuccessTs} />
                                    </span>
                                </div>
                                <div className="mt2">
                                    <strong className="fz10 spacing uppercase">Last error:</strong>
                                    <span className="monospace fz13">
                                        {agent.lastErrorDesc} - <TimeAgo date={agent.lastErrorTs} />
                                    </span>
                                </div>
                                <div className="mt2">
                                    <strong className="fz10 spacing uppercase">Status:</strong>
                                    <span className="monospace fz13">{agent.status}</span>
                                </div>
                                <div className="mt2">
                                    <strong className="fz10 spacing uppercase">Is running:</strong>
                                    <span className="monospace fz13">{agent.enabled ? 'Yes' : 'No'}</span>
                                </div>
                            </div>
                        </Popover>
                    )}
                </div>
            </td>
            <td className="agents-data--agent">
                <div className="flex items-center flex-grow-1 full-height">
                    <div className="dark h4 flex-grow-1">{agent.name}</div>
                    <div className="flex flex-column items-end full-height justify-evenly">
                        <div className="flex items-center">
                            <span className="fz10 uppercase grey3 agents-data--agent__label pr2">Memory</span>

                            <div className="flex items-center agents-data--agent__reference pl2">
                                <span className="sparkline__reference__color sparkline__reference__color--memory mr2 rounded"></span>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="fz10 uppercase grey3  agents-data--agent__label pr2">CPU</span>

                            <div className="flex items-center agents-data--agent__reference pl2">
                                <span className="sparkline__reference__color sparkline__reference__color--cpu mr2 rounded"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            <td>
                <span className="fz13 monospace dark">{agent.currentVersion}</span>
            </td>
            <td>
                <AsyncHostAttribute id={agent.targetHost} />
            </td>
            <td>
                <div className="monospace fz13 nowrap dark">
                    <TimeAgo date={agent.lastSeen} />
                </div>
            </td>
            {userIsAllowedTo('changeEnvSettings') && (
                <td data-testid="agent-actions-button" className="flex justify-center items-center">
                    <AgentActions
                        agents={[agent]}
                        showLog={true}
                        onStart={() => onStartAgent([agent])}
                        onRestart={() => onRestartAgent([agent])}
                        onStop={() => {
                            openModal({
                                onSubmit: () => onStopAgent([agent]),
                                confirmMsg: 'This will stop all agents in the selected host. Are you sure?',
                                buttonText: 'Stop agent',
                                title: 'Stop agent',
                                showWarning: false,
                            });
                        }}
                        onLogFetched={onLogFetched}
                    />
                </td>
            )}
        </tr>
    );
};
export default AgentRow;
