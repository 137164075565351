import { get as getContext } from 'services/context';

type UseUrlType =
    | 'baseHost'
    | 'find'
    | 'login'
    | 'orgHome'
    | 'orgLogin'
    | 'orgOwner'
    | 'ping'
    | 'signup'
    | 'forgot'
    | 'homepage'
    | 'loginCheck'
    | 'reset'
    | 'join';

const useUrl = (url: UseUrlType) => {
    const { urls } = getContext();

    if (!urls[url]) {
        throw new Error(`Missing URL in context: ${url}`);
    }

    return urls[url] as string;
};

export default useUrl;
