import cn from 'helpers/classname';
import Icon from 'components/icons/Icon';
import Host from 'models/hosts/Host';
import HostIcon from 'components/icons/HostIcon';
import Timestamp from 'components/format/Timestamp';
import { FC, Fragment } from 'react';

const BoldMatch: FC<{ suggestion: Host; filter: string; className: string }> = ({ suggestion, filter, className }) => {
    if (!filter) {
        return <div className={className}>{suggestion.name}</div>;
    }

    const regex = new RegExp(filter.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'ig');
    const formattedTokens = [];
    const filterLength = filter.length;
    let unformattedStart = 0;
    let token;

    while ((token = regex.exec(suggestion.name)) !== null) {
        const plainText = suggestion.name.substring(unformattedStart, token.index);
        if (plainText) {
            formattedTokens.push(<>{plainText}</>);
        }

        unformattedStart = token.index + filterLength;

        const boldText = suggestion.name.substring(token.index, unformattedStart);

        formattedTokens.push(<strong data-testid="bold-suggestion">{boldText}</strong>);
    }

    if (unformattedStart < suggestion.name.length) {
        formattedTokens.push(<>{suggestion.name.substring(unformattedStart)}</>);
    }

    return (
        <div className={className}>
            {formattedTokens.map((formattedToken, i) => (
                <Fragment key={i}>{formattedToken}</Fragment>
            ))}
        </div>
    );
};

/**
 * Amount of hosts shown in the suggestions list or the preview list.
 * This number comes from thread number 10 in Figma https://www.figma.com/file/YuaTojP4KV8XiLrptEWdVz/Host-Filtering-Changes
 */
export const PREVIEW_SIZE = 30;
export const selectedClassName = 'host-filter-results__item--selected';

const HostsSelectorSuggestions: FC<{
    filter: string;
    highlight: Host | undefined;
    isBasicMode: boolean;
    onClick: (suggestion: Host) => void;
    suggestions: Host[];
}> = ({ filter, highlight, isBasicMode, onClick, suggestions }) => {
    return (
        <ul
            className={`host-filter__results__list list-reset overflow-auto${cn(
                'hosts-selector-suggestions--advanced',
                !isBasicMode
            )}`}
        >
            {suggestions.map((suggestion, i) =>
                i < PREVIEW_SIZE ? (
                    <li
                        key={suggestion.id}
                        className={`px3 py2 host-filter-results__item${cn('cursor-hand', isBasicMode)}${cn(
                            selectedClassName,
                            highlight?.id === suggestion.id
                        )}`}
                        onClick={() => (isBasicMode ? onClick(suggestion) : null)}
                        data-testid="hosts-selector-suggestion"
                    >
                        <div>
                            <div className="flex">
                                <HostIcon host={suggestion} className="mr1 fz12" />
                                <div className="flex nowrap items-baseline truncate">
                                    <BoldMatch
                                        suggestion={suggestion}
                                        filter={filter}
                                        className="dark fz13 line-height-120 mr1"
                                    />
                                    <div className="grey3 fz10 monospace line-height-120 truncate">
                                        {suggestion.description}
                                    </div>
                                </div>
                            </div>
                            {suggestion.deleted > 0 && (
                                <div className="host-deleted flex grey3 mt1 items-center justify-end fz10">
                                    <Icon icon="info" className="grey2 mr1 inline-flex fz12" />
                                    <span className="delete-tag">Deleted</span>
                                    <span>
                                        {' '}
                                        on <Timestamp date={suggestion.deleted} />
                                    </span>
                                </div>
                            )}
                        </div>
                    </li>
                ) : null
            )}
        </ul>
    );
};

export default HostsSelectorSuggestions;
