import { ReactNode, useCallback } from 'react';
import { createContext, useContext, useState } from 'react';
import type { DiagramEdgeInput } from '../types';

interface DiagramHoverProviderProps<TNode, TEdge> {
    edges: ReadonlyArray<DiagramEdgeInput<TNode, TEdge>>;
    children: ReactNode;
}

export interface UseDiagramHoverResult<TNode, TEdge> {
    onNodeHover: (node: TNode) => void;
    onEdgeHover: (edge: DiagramEdgeInput<TNode, TEdge>) => void;
    isNodeHovered: (node: TNode) => boolean;
    isEdgeHovered: (edge: DiagramEdgeInput<TNode, TEdge>) => boolean;
    onMouseOut: () => void;
    isAnythingHovered: boolean;
}

const DiagramHoverContext = createContext<UseDiagramHoverResult<unknown, unknown> | undefined>(undefined);
DiagramHoverContext.displayName = 'DiagramHoverContext';

export function DiagramHoverProvider<TNode, TEdge>({ children, edges }: DiagramHoverProviderProps<TNode, TEdge>) {
    const [hoveredItem, setHoveredItem] = useState<TNode | DiagramEdgeInput<TNode, TEdge> | null>(null);

    function onNodeHover(node: TNode): void {
        setHoveredItem(node);
    }

    function onEdgeHover(edge: DiagramEdgeInput<TNode, TEdge>): void {
        setHoveredItem(edge);
    }

    function isEdgeHovered(edgeToLookUp: DiagramEdgeInput<TNode, TEdge>): boolean {
        if (!hoveredItem) {
            return false;
        } else if (typeof hoveredItem === 'object' && 'source' in hoveredItem) {
            return edgeToLookUp === hoveredItem;
        } else {
            return edges
                .filter(edge => edge.source === hoveredItem || edge.target === hoveredItem)
                .some(edge => edge === edgeToLookUp);
        }
    }

    function isNodeHovered(node: TNode): boolean {
        if (!hoveredItem) {
            return false;
        } else if (typeof hoveredItem === 'object' && 'source' in hoveredItem) {
            return hoveredItem.source === node || hoveredItem.target === node;
        } else {
            return node === hoveredItem;
        }
    }

    function onMouseOut(): void {
        setHoveredItem(null);
    }

    return (
        <DiagramHoverContext.Provider
            value={
                {
                    onNodeHover: useCallback(onNodeHover, []),
                    onEdgeHover: useCallback(onEdgeHover, []),
                    onMouseOut: useCallback(onMouseOut, []),
                    isNodeHovered: useCallback(isNodeHovered, [hoveredItem]),
                    isEdgeHovered: useCallback(isEdgeHovered, [hoveredItem, edges]),
                    isAnythingHovered: hoveredItem !== null,
                } as UseDiagramHoverResult<unknown, unknown>
            }
        >
            {children}
        </DiagramHoverContext.Provider>
    );
}

export function useDiagramHover<TNode, TEdge>(): UseDiagramHoverResult<TNode, TEdge> {
    const diagramHoverContext = useContext(DiagramHoverContext);
    if (!diagramHoverContext) {
        throw new Error('useDiagramHover was called outside of a DiagramHoverProvider.');
    }
    return diagramHoverContext;
}
