import React, { useState, useEffect, useRef, FC } from 'react';
import Clipboard from 'clipboard';
import useDisplayMessage from 'hooks/useDisplayMessage';

const CopyToClipboard: FC<{ target: string; children?: React.ReactNode }> = ({ target }) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const { error } = useDisplayMessage();

    useEffect(() => {
        if (!buttonRef.current) {
            return;
        }

        const clipboard = new Clipboard(buttonRef.current);

        clipboard.on('success', () => {
            setSuccess(true);
        });

        clipboard.on('error', () => {
            error('Oops. We were unable to copy this text');
        });

        return () => {
            clipboard.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <button type="button" ref={buttonRef} className="primary-grey mx2" data-clipboard-target={`#${target}`}>
                Copy
            </button>
            {success && <span>Copied!</span>}
        </>
    );
};

export default CopyToClipboard;
