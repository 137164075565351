import * as loginService from 'services/api/login';
import { AnyObject } from 'final-form';
import { Field } from 'react-final-form-html5-validation';
import { Form } from 'react-final-form';
import useOrg from 'hooks/useOrg';
import useUrl from 'hooks/useUrl';
import { FC, useState, useEffect } from 'react';
import Authentication from 'models/Authentication';
import AuthError from 'components/modules/public/AuthError';
import BouncerButton from 'components/form/BouncerButton';
import { UserLoginHeading } from 'components/modules/public/Heading';
import Icon from 'components/icons/Icon';
import LoginError from 'models/errors/LoginError';
import LoginMethods from './LoginMethods';
import ReCAPTCHA from 'react-google-recaptcha';
import Logger from 'services/logger';
import Org from 'models/Org';
import useOrgContext from 'hooks/useOrgContext';
import { Link } from 'react-router-dom';
import { OrgURLInterface } from 'models/URL';

interface LoginStateInterface {
    errorCode: string;
    displayCaptcha: boolean;
    captcha: string | null;
}

const LoginLayout: FC<{ org: Org; children?: React.ReactNode }> = ({ children, org }) => (
    <>
        <UserLoginHeading org={org} />

        <section className="card-block  shadow mx-auto my4 relative flex" data-testid="login-form-component">
            <div className="bg-white flex-grow-1 p3">{children}</div>
        </section>
    </>
);

const Login = () => {
    const org = useOrg();
    const loginEndpoint = useUrl('loginCheck');
    const homepageUrl = useUrl('homepage');
    const orgSelectionUrl = useUrl('baseHost');
    const forgotPasswordUrl = useUrl('forgot');

    const { auth, urls } = useOrgContext();
    const [loginState, setLoginState] = useState<LoginStateInterface>({
        errorCode: auth.errorCode,
        displayCaptcha: auth.requiredCaptchaForLogin(),
        captcha: '',
    });
    const [loginSucceeded, setLoginSucceeded] = useState(false);

    useEffect(() => {
        if (loginSucceeded) {
            window.location.replace(homepageUrl);
        }
    }, [homepageUrl, loginSucceeded]);

    const informError = ({ code: errorCode, displayCaptcha }: LoginError) => {
        setLoginState((prevState: LoginStateInterface) => ({
            errorCode,
            displayCaptcha: prevState.displayCaptcha || displayCaptcha,
            captcha: prevState.captcha,
        }));
    };

    const onSubmit = async (formValues: AnyObject) => {
        const logger = Logger.get('LoginComponent');

        try {
            await loginService.doLogin(loginEndpoint, {
                username: formValues.username,
                password: formValues.password,
                rememberMe: formValues.rememberMe,
                captcha: loginState.captcha,
            });

            window.location.replace(homepageUrl);

            setLoginSucceeded(true);
        } catch (error) {
            if (error instanceof LoginError) {
                informError(error);
                return;
            }

            logger.error('[login] unexpected error', error);

            informError(new LoginError(loginService.LoginErrorCode.UNKNOWN, false));
        }
    };

    const handleCaptchaChange = (token: string | null) => {
        setLoginState((prevState: LoginStateInterface) => ({
            ...prevState,
            captcha: token,
        }));
    };

    if (loginSucceeded) {
        return (
            <LoginLayout org={org}>
                Successful login. Please wait while we redirect you or <a href={homepageUrl}>click here</a>.
            </LoginLayout>
        );
    }

    return (
        <LoginLayout org={org}>
            {loginState.errorCode && <AuthError error={loginState.errorCode} />}

            <LoginMethods auth={auth} urls={urls as OrgURLInterface} />

            <Form
                onSubmit={onSubmit}
                initialValues={{ rememberMe: true }}
                render={({ handleSubmit, submitting, invalid }) => (
                    <form onSubmit={handleSubmit} id="login-form">
                        {auth.isMethodSupported(Authentication.BASIC_METHOD) && (
                            <div className="basic-sign-in">
                                <fieldset className="mb3">
                                    <label>
                                        <b className="uppercase fz10 spacing inline-flex items-center">Email:</b>
                                        <div className="relative my2">
                                            <Field
                                                component="input"
                                                type="email"
                                                name="username"
                                                autoComplete="work email"
                                                placeholder="Enter your email address"
                                                className="full-width pl4"
                                                autoFocus
                                                required
                                            />
                                            <Icon icon="email" className="grey2 h2 absolute mt1 top-0 left-2" />
                                        </div>
                                    </label>
                                </fieldset>

                                <fieldset>
                                    <label>
                                        <b className="uppercase fz10 spacing inline-flex items-center">Password:</b>
                                        <div className="relative my2">
                                            <Field
                                                component="input"
                                                type="password"
                                                name="password"
                                                placeholder="Enter your password"
                                                className="full-width pl4"
                                                required
                                            />
                                            <Icon icon="lock" className="grey2 h2 absolute mt1 top-0 left-2" />
                                        </div>
                                    </label>
                                </fieldset>

                                <Link className="flex mb3 justify-end" to={forgotPasswordUrl}>
                                    I forgot my password
                                </Link>

                                {loginState.displayCaptcha && (
                                    <fieldset className="recaptcha" data-testid="recaptcha-field">
                                        <div id="recaptcha_area">
                                            <ReCAPTCHA sitekey={auth.captchaKey} onChange={handleCaptchaChange} />
                                        </div>
                                        <span className="error error--recaptcha">
                                            No robots allowed here. Please pass the recaptcha test to prove that you are
                                            a human.
                                        </span>
                                    </fieldset>
                                )}

                                <fieldset>
                                    <div className="flex">
                                        <div className="flex flex-grow-1 items-center">
                                            <label>
                                                <Field
                                                    type="checkbox"
                                                    component="input"
                                                    name="rememberMe"
                                                    id="remember-me"
                                                />
                                                <span className="inline view-value" />
                                            </label>
                                            <label htmlFor="remember-me" className="cursor-hand">
                                                Remember me
                                            </label>
                                        </div>

                                        <BouncerButton
                                            className="primary-mainColor"
                                            type="submit"
                                            disabled={submitting || invalid}
                                            bounce={submitting}
                                            bouncerClassName="inline-flex ml2 white"
                                        >
                                            Log in
                                        </BouncerButton>
                                    </div>
                                </fieldset>
                            </div>
                        )}
                    </form>
                )}
            />

            <div className="card-block__not-member absolute right-0">
                <p>
                    Not a <span className="cnt__actions--org">{org.name}</span> member?{' '}
                    <a className="cnt__link" href={orgSelectionUrl} id="switch-org-link">
                        Access another organization
                    </a>
                </p>
            </div>
        </LoginLayout>
    );
};

export default Login;
