import Alert, { AlertTypes } from 'components/messages/Alert';
import Bouncer from 'components/icons/Bouncer';
import { FC, useState, useEffect } from 'react';
import Timestamp from 'components/format/Timestamp';
import { getActivity, UserActivityDataInterface } from 'services/api/users';

const SettingsRecentActivity: FC = () => {
    const [activity, setActivity] = useState<UserActivityDataInterface[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    async function fetchActivity() {
        try {
            setActivity(await getActivity());
        } catch (e) {
            setError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchActivity();
    }, []);

    return (
        <div>
            <h2 className="flex h2 mt3 lighter grey3 flex-grow-1">Recent Account Activity</h2>
            <p className="h4 lighter mt4 mb3">This is a security log of important events involving your account.</p>

            {error && (
                <Alert className="mb3" sticky type={AlertTypes.ERROR}>
                    Could not retrieve your recent activity.
                </Alert>
            )}

            <table
                className="vc-table vc-table--settings vc-table--security-list full-width"
                data-testid="settings-user-activity"
            >
                <thead>
                    <tr>
                        <th className="vc-table--security-list__index">#</th>
                        <th>Date & Time</th>
                        <th>Action</th>
                        <th>IP Address</th>
                    </tr>
                </thead>
                {activity.length > 0 && (
                    <tbody>
                        {activity.map((record, i) => (
                            <tr key={i}>
                                <td>{i}</td>
                                <td>
                                    <Timestamp date={record.ts} />
                                </td>
                                <td>Logged in</td>
                                <td>{record.ip}</td>
                            </tr>
                        ))}
                    </tbody>
                )}
                <tfoot>
                    {!loading && !activity.length && (
                        <tr>
                            <td className="center" colSpan={4}>
                                There are no events to show.
                            </td>
                        </tr>
                    )}
                    {loading && (
                        <tr>
                            <td className="center" colSpan={4}>
                                <Bouncer className="inline-flex ml1" />
                            </td>
                        </tr>
                    )}
                </tfoot>
            </table>
        </div>
    );
};

export default SettingsRecentActivity;
