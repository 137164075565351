import Alert, { AlertTypes } from 'components/messages/Alert';
import BouncerButton from 'components/form/BouncerButton';
import FormActions from 'components/form/Actions';
import { FormGroupVariantType } from 'components/form/Group';
import TextField from 'components/form/fields/TextField';
import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { update } from 'services/api/users';
import FormField from 'components/form/Field';
import { requestNewTokens } from 'services/api/authTokens';

const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;

const EditPassword: FC = () => {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) => {
        setError('');
        setSuccess(false);

        try {
            await update({
                password: {
                    current: currentPassword,
                    new: newPassword,
                },
            });

            requestNewTokens();

            setSuccess(true);
        } catch (e) {
            setError(e instanceof Error ? e.message : 'There was an error updating your password');
        }
    };

    return (
        <>
            <h2 className="flex h2 mt3 lighter grey3 flex-grow-1">Change Password</h2>

            {error && (
                <Alert type={AlertTypes.ERROR} visible className="my2" data-testid="wrong-pass-message">
                    There was an <strong>error</strong> trying to update your password. {error}
                </Alert>
            )}

            {success && (
                <Alert type={AlertTypes.SUCCESS} data-testid="success-password-change" visible className="my2">
                    <strong>Success!</strong> Your password has been changed
                </Alert>
            )}
            <Form
                onSubmit={handleSubmit}
                render={({ pristine, invalid, handleSubmit: onSubmit, submitting }) => (
                    <form className="settings__form settings__form--profile mt3" onSubmit={onSubmit}>
                        <FormField
                            render={TextField}
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mb3"
                            label="Current Password:"
                            autoComplete="current-password"
                            type="password"
                            name="currentPassword"
                            required
                            valueMissing="Please type your current password"
                            data-testid="current-password-input"
                            htmlField
                        />
                        <FormField
                            render={TextField}
                            variant={FormGroupVariantType.HORIZONTAL}
                            className="mb3"
                            label="New Password:"
                            autoComplete="new-password"
                            type="password"
                            name="newPassword"
                            minLength={passwordMinLength}
                            required
                            valueMissing="Please type your new password"
                            tooShort={`Your password must be at least ${passwordMinLength} characters long`}
                            data-testid="new-password-input"
                            htmlField
                        />

                        <FormActions>
                            <BouncerButton
                                data-testid="change-pass-btn"
                                className="primary-orange"
                                type="submit"
                                bounce={submitting}
                                disabled={pristine || invalid}
                            >
                                Change my Password
                            </BouncerButton>
                        </FormActions>
                    </form>
                )}
            />
        </>
    );
};

export default EditPassword;
