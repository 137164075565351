import * as React from 'react';
import Row from './Row';
import Role from 'models/Role';
import Team from 'models/Team';
import Env from 'models/Env';

const EnvTeamsTable: React.FC<{ teams: Team[]; roles: Role[]; env: Env; onDelete?: (team: Team) => void }> = ({
    teams,
    roles,
    env,
    onDelete,
}) => {
    const handleDelete = (team: Team) => {
        if (onDelete) {
            onDelete(team);
        }
    };

    return (
        <div className="my3">
            <table className="vc-table vc-table--settings vc-table--integrations full-width">
                <thead>
                    <tr>
                        <th>Team</th>
                        <th className="full-width">Members</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    {teams.map(team => (
                        <Row
                            key={team.id}
                            team={team}
                            roles={roles.filter(role => role.assignable.toTeams)}
                            env={env}
                            onDelete={handleDelete}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EnvTeamsTable;
