import Header from './MembersHeader';
import HostFilterProvider from 'components/context/HostFilter';
import HostsSelectionStatus from 'components/messages/HostsSelectionStatus';
import MessagesDisplayer from 'components/messages/Displayer';
import ModalContext from 'components/context/Modal';
import Sidebar from 'components/layout/Sidebar';
import TimeIntervalProvider from 'components/context/TimeInterval';
import useUIStatus from 'hooks/useUIStatus';
import useAuthenticatedContext from 'hooks/useAuthenticatedContext';
import DocumentationProvider from 'components/context/Documentation';
import Documentation from 'components/documentation/Documentation';
import Notifications from 'components/notifications/Notifications';
import OfflineIndicator from 'components/messages/OfflineIndicator';
import Impersonator from 'components/security/Impersonator';
import FullScreenButton from 'components/fullScreen/FullScreenButton';
import Incidents from 'components/messages/Incidents';
import { FC, useState, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { isCurrentLocationSettings } from 'services/routes';
import { useLocation } from 'react-router-dom';

const MembersLayout: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [status, writeUIStatus] = useUIStatus(
        { collapsed: false },
        {
            id: 'sidebar',
            excludeSearchKeys: ['collapsed'],
            clearOnRouteChange: false,
        }
    );

    const modalRef = useRef(null);

    const [sidebarCollapsed, setSidebarCollapsed] = useState(status.collapsed);
    const toggleSidebar = () => {
        const collapsed = !sidebarCollapsed;
        writeUIStatus({ collapsed });
        setSidebarCollapsed(collapsed);
    };
    const fullScreenHandle = useFullScreenHandle();
    const userHasSelectedEnv = useAuthenticatedContext().env;
    const userInSettingsPage = isCurrentLocationSettings(useLocation().pathname);

    return (
        <TimeIntervalProvider>
            <HostFilterProvider>
                <DocumentationProvider>
                    <MessagesDisplayer />
                    <Impersonator />
                    <ModalContext.Provider value={modalRef}>
                        <FullScreen handle={fullScreenHandle}>
                            <div id="modal-root" ref={modalRef} />
                            <div
                                className={`bg-grey0 ${!fullScreenHandle.active ? '' : 'full-height overflow-auto'}`}
                                data-testid="members-layout"
                            >
                                <OfflineIndicator>
                                    <Incidents />

                                    <Header sidebarCollapsed={sidebarCollapsed} fullscreen={fullScreenHandle.active}>
                                        {userHasSelectedEnv && !userInSettingsPage && <Notifications />}
                                        <Documentation />
                                        <FullScreenButton handle={fullScreenHandle} />
                                    </Header>

                                    <div className="main-content-wrp full-height relative">
                                        {!fullScreenHandle.active && (
                                            <Sidebar collapsed={sidebarCollapsed} onToggle={toggleSidebar} />
                                        )}

                                        <div className={`relative z1 ${!fullScreenHandle.active ? 'main' : ''}`}>
                                            {children}
                                            <HostsSelectionStatus />
                                        </div>
                                    </div>
                                </OfflineIndicator>
                            </div>
                        </FullScreen>
                    </ModalContext.Provider>
                </DocumentationProvider>
            </HostFilterProvider>
        </TimeIntervalProvider>
    );
};

export default MembersLayout;
