import ForgotPasswordForm from './Form';
import Heading from 'components/modules/public/Heading';
import MetaTags from 'components/layout/MetaTags';
import useUrl from 'hooks/useUrl';

const FindOrganization = () => {
    const loginUrl = useUrl('login');
    const forgotUrl = useUrl('forgot');

    return (
        <>
            <MetaTags title="Forgotten password - DPM" />

            <Heading>Reset your account password</Heading>

            <section id="reset" className="card-block shadow mx-auto my4 relative flex bg-white p3 flex-column">
                <ForgotPasswordForm loginUrl={loginUrl} endpoint={forgotUrl} />
            </section>
        </>
    );
};

export default FindOrganization;
