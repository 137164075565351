import { useMemo } from 'react';
import type { DiagramLayoutFunction } from '../../types';
import { ViewBox } from '../../../../helpers/diagram';

export type UseLayoutPaddingOptions =
    | {
          readonly bottom: number;
          readonly left: number;
          readonly right: number;
          readonly top: number;
      }
    | {
          readonly x: number;
          readonly y: number;
      }
    | number;

/**
 * Translates the various forms of user specified padding into a consistent object.
 */
function translatePadding(padding: UseLayoutPaddingOptions): {
    readonly bottom: number;
    readonly left: number;
    readonly right: number;
    readonly top: number;
} {
    if (typeof padding === 'number') {
        return {
            bottom: padding,
            left: padding,
            right: padding,
            top: padding,
        };
    } else if ('bottom' in padding) {
        return padding;
    } else {
        return {
            bottom: padding.y,
            left: padding.x,
            right: padding.x,
            top: padding.y,
        };
    }
}

/**
 * Applies ViewBox padding to a diagram layout.
 * Use when creating the diagram layout outside of render (preferred).
 * @param layout The diagram layout to apply padding to.
 * @param padding The amount of padding to apply.
 * @returns Diagram layout with padding applied.
 */
export function applyPadding<TNode, TEdge>(
    layout: DiagramLayoutFunction<TNode, TEdge>,
    padding: UseLayoutPaddingOptions
): DiagramLayoutFunction<TNode, TEdge> {
    const { bottom, left, right, top } = translatePadding(padding);
    return (...args) => {
        const { viewBox, ...positions } = layout(...args);
        return {
            ...positions,
            viewBox: new ViewBox(
                viewBox.minX - left,
                viewBox.minY - top,
                viewBox.width + left + right,
                viewBox.height + top + bottom
            ),
        };
    };
}

/**
 * Applies ViewBox padding to a diagram layout.
 * Use when padding varies by state or props and must be applied in render.
 * Memoizes return value using padding values and layout function.
 * @param layout The diagram layout to apply padding to.
 * @param padding The amount of padding to apply.
 * @returns Diagram layout with padding applied.
 */
export function useLayoutPadding<TNode, TEdge>(
    layout: DiagramLayoutFunction<TNode, TEdge>,
    padding: UseLayoutPaddingOptions
): DiagramLayoutFunction<TNode, TEdge> {
    const { bottom, left, right, top } = translatePadding(padding);
    return useMemo(() => applyPadding(layout, { bottom, left, right, top }), [layout, bottom, left, right, top]);
}
