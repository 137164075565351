import Bootstrap from 'models/Bootstrap';
import Logger from 'services/logger';

const logger = Logger.get('GTM');

let initialized = false;

export const isInternalUser = (context: Bootstrap) => {
    const internalPrefix = 'Internal:';
    const disabledStages = ['prod', 'local'];
    const disabledOrgs = ['demo'];

    const nickname = context.org ? context.org.nickname : '';
    const orgName = context.org ? context.org.name : '';

    const isDisabledStage = context.isBrainiac() && disabledStages.indexOf(context.stage) >= 0;

    return isDisabledStage || disabledOrgs.indexOf(nickname) >= 0 || orgName.includes(internalPrefix);
};

export const isEnabled = (context: Bootstrap) => {
    // if we have a GTM id, init service
    if (!context.tagManagerId) {
        logger.warn("Can't initialize without an ID");
        return false;
    }

    // Force running GTM even when normally we wouldn't
    // This is useful for debugging purposes
    const manuallyForcedToRun = window.location.search.includes('forceGTM');
    if (manuallyForcedToRun) {
        logger.warn('Force starting service');
        return true;
    }

    // activate GTM only if the user is not internal. we don't want to measure our own activity.
    if (isInternalUser(context)) {
        logger.warn('Deactivated for internal users');
        return false;
    }

    // activate GTM only if the user is not being impersonated. we don't want to measure our own activity.
    if (context.isImpersonation()) {
        logger.warn('Deactivated for impersonated users');
        return false;
    }

    return true;
};

export const init = (context: Bootstrap) => {
    if (initialized) {
        logger.error('Attempting to reinitialize.');

        return;
    }

    if (!isEnabled(context)) {
        return;
    }

    initialized = true;

    (function (w, d, s, l: keyof Window, i) {
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        w[l] = w[l] || [];
        // @ts-ignore
        w[l].push({ 'gtm.start': Date.now(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            // eslint-disable-next-line
            dl = l != 'dataLayer' ? '&l=' + l : '';
        // @ts-ignore
        j.async = true;
        // @ts-ignore
        j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
        // @ts-ignore
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', context.tagManagerId);
};

function getDataLayer() {
    return (window.dataLayer = window.dataLayer || []);
}

export const virtualPageView = (path: string) => {
    if (!initialized) {
        logger.debug('GTM uninitialized, skipping virtual page view');

        return;
    }

    const dataLayer = getDataLayer();

    dataLayer.push({
        event: 'content-view',
        'content-name': path,
    });
};

export interface GTMEventPropertiesInterface {
    category: string;
    label?: string;
}

/**
 * Report an interaction event to GA.
 */
export const interaction = (action: string, properties: GTMEventPropertiesInterface) => {
    if (!initialized) {
        logger.debug('GTM uninitialized, skipping interaction event');

        return;
    }

    const dataLayer = getDataLayer();

    dataLayer.push({
        event: 'interaction',
        target: properties.category,
        action: action,
        'target-properties': properties.label,
    });
};

/**
 * Report an event with an arbitrary content.
 */
export const event = (eventData: DataLayerInterface) => {
    if (!initialized) {
        logger.debug('GTM uninitialized, skipping event');

        return;
    }

    const dataLayer = getDataLayer();

    dataLayer.push(eventData);
};
