import { Event, Breadcrumb } from '@bugsnag/js';

const reservedKeys = ['Authorization', 'X-XSRF-TOKEN', 'tokens'];
const maskedString = '***MASKED***';

const sanitizeReport = (event: Pick<Event, 'breadcrumbs' | 'request'>) => {
    (event.breadcrumbs || []).forEach((breadcrumb: Breadcrumb) => {
        Object.entries(breadcrumb.metadata).forEach(([key, value]) => {
            reservedKeys.forEach((reservedKey: string) => {
                if (key === reservedKey || (typeof value === 'string' && value.includes(reservedKey))) {
                    breadcrumb.metadata[key] = maskedString;
                }
            });
        });
    });

    if (!event.request) {
        return;
    }

    const reservedParams = ['bootstrap', 'token'];
    const url = new URL(decodeURI(event.request.url || ''));

    reservedParams.forEach(param => {
        if (url.searchParams.has(param)) {
            url.searchParams.set(param, maskedString);
        }
    });

    event.request.url = url.toString();
};

export default sanitizeReport;
