import { createContext, Dispatch, FC, SetStateAction, MouseEvent, useState, useContext } from 'react';

interface TabProvider {
    activeTab: string;
    changeTab: Dispatch<SetStateAction<string>>;
}

interface TabsProps {
    defaultTab: string;
    className?: string;
    children?: React.ReactNode;
}

interface TabProps {
    name: string;
    className?: string;
    onClick?: (event: MouseEvent) => void;
    children?: React.ReactNode;
}

interface TabPanelProps {
    name: string;
    className?: string;
    children?: React.ReactNode;
}

const TabContext = createContext<TabProvider | undefined>(undefined);

export const Tabs: FC<TabsProps> = ({ defaultTab, className = '', children, ...rest }) => {
    const [activeTab, changeTab] = useState(defaultTab);
    const tabProviderValue = { activeTab, changeTab };

    return (
        <TabContext.Provider value={tabProviderValue}>
            <div className={`${className}`} {...rest}>
                {children}
            </div>
        </TabContext.Provider>
    );
};

export const Tab: FC<TabProps> = ({ name, className = '', onClick, children, ...rest }) => {
    const tabContext = useContext(TabContext);

    if (!tabContext) {
        return <div />;
    }

    const handleClick = (event: MouseEvent) => {
        tabContext.changeTab(name);
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <div className={`tab__heading ${tabContext.activeTab === name ? 'active' : ''} ${className}`}>
            <div
                data-testid={`tab-${name}`}
                className="tab__heading__title flex items-center px2"
                onClick={handleClick}
                {...rest}
            >
                {children}
            </div>
        </div>
    );
};

export const TabPanel: FC<TabPanelProps> = ({ name, className = '', children, ...rest }) => {
    const tabContext = useContext(TabContext);

    if (!tabContext) {
        return <div />;
    }

    return tabContext.activeTab === name ? (
        <div data-testid="tabs-panel" className={`tab-panel ${className}`} {...rest}>
            {children}
        </div>
    ) : null;
};
