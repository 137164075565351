import * as React from 'react';
import { Helmet } from 'react-helmet';
import useEnv from 'hooks/useEnv';
import ModuleTitle from '../ModuleTitle';

const KPISummary: React.FC = () => {
    const env = useEnv();

    return (
        <>
            <Helmet>
                <title>Environment Summary | {env.name} - DPM</title>
            </Helmet>
            <div className="view view--environment-summary view--salesforce">
                <div className="flex full-width pb3">
                    <div className="flex items-center full-height">
                        <ModuleTitle contextualHelpId="summary">Summary</ModuleTitle>
                    </div>
                </div>
            </div>
        </>
    );
};

export default KPISummary;
