import cacheResult from 'helpers/cacheResult';
import { HostDataInterface, HostFeatureDataInterface } from 'models/hosts/Host';
import Host from 'models/hosts/Host';
import HostClusters from 'models/hosts/HostClusters';
import APIRequest from './Request';
import Logger from 'services/logger';
import TimeInterval from 'models/TimeInterval';
import Timer from 'services/timer';
import * as cache from 'services/host/cache';

const logger = Logger.get('hosts');

const timer = new Timer();

export async function getHostClusters(interval: TimeInterval, filter = '') {
    logger.info('getting the hosts');

    const response = await new APIRequest<HostDataInterface[]>({
        path: 'hosts/clusters',
        params: {
            ...interval.asParams(),
            nest: 'tags,options',
            filter,
        },
    }).request();

    return new HostClusters(response.data);
}

interface HostRequestInterface {
    id: number;
    resolve: (arg0: Host) => void;
    reject: (arg0: Error) => void;
}

interface FilterStackInterface {
    interval: TimeInterval | null;
    hosts: HostRequestInterface[];
}

// We will use this variable to accummulate getHost() calls and make a single request.
const hostFilterStack: FilterStackInterface = {
    interval: null,
    hosts: [],
};

export async function getHosts(interval: TimeInterval, filter = '') {
    const { data } = await new APIRequest<HostDataInterface[]>({
        path: 'hosts',
        params: {
            ...interval.asParams(),
            host: filter,
        },
    }).request();
    return data.map(hostData => new Host(hostData));
}

function resolveFilterStack() {
    const requestedHosts = [...hostFilterStack.hosts];
    // Using a set is a clean and fast way to remove duplicates
    const hostIds = [...new Set(requestedHosts.map(filter => filter.id))].join(',');
    const interval = hostFilterStack.interval;

    if (!interval) {
        logger.error('Trying to request hosts without a time interval');

        return;
    }

    function unresolvedHostError(id: number, timeInterval: TimeInterval) {
        return new Error(
            `Found no hosts with id ${id} in the interval ${timeInterval.fromTs} - ${timeInterval.untilTs}`
        );
    }

    hostFilterStack.hosts = [];
    hostFilterStack.interval = null;

    getHosts(interval, hostIds)
        .then(hosts => {
            requestedHosts.forEach(filter => {
                const host = hosts.find(({ id }) => id === filter.id);

                if (!host) {
                    // If we request a host by id it must exist, so this has little to no chance of happening.
                    // This is different from the getHosts() calls which is potentially related with user input (hosts query parameter).
                    filter.reject(unresolvedHostError(filter.id, interval));
                    return;
                }

                cache.set(host);

                filter.resolve(host);
            });
        })
        .catch(e => {
            requestedHosts.forEach(filter => {
                logger.error(e);

                filter.reject(unresolvedHostError(filter.id, interval));
            });
        });
}

export function getHost(interval: TimeInterval, id: number) {
    const host = cache.get(id);

    if (host) {
        return Promise.resolve(host);
    }

    return new Promise<Host>((resolve, reject) => {
        if (hostFilterStack.interval && hostFilterStack.interval !== interval) {
            resolveFilterStack();
        }

        hostFilterStack.interval = interval;

        hostFilterStack.hosts.push({
            id,
            resolve,
            reject,
        });

        timer.set('resolveHostsStack', () => resolveFilterStack(), 250);
    });
}

export async function getHostAttribute(interval: TimeInterval, id: number, attribute: keyof Host) {
    try {
        const host = await getHost(interval, id);

        return host[attribute];
    } catch (e) {
        return `Host ${id}`;
    }
}

export interface HostTotalsInterface {
    registered: number;
    active: number;
    matching: number;
    returned: number;
}

export async function getHostsCount(interval: TimeInterval, filter = '') {
    const response = await new APIRequest<
        HostDataInterface[],
        Record<string, unknown>,
        { data: HostDataInterface[]; totals: HostTotalsInterface }
    >({
        path: 'hosts',
        params: {
            ...interval.asParams(),
            host: filter,
            limit: 0,
        },
    }).request();

    return response.response.totals;
}

export async function filterHosts(interval: TimeInterval, filter = '', options?: { searchByName: boolean }) {
    const param = options?.searchByName ? 'name' : 'host';

    const response = await new APIRequest<
        HostDataInterface[],
        Record<string, unknown>,
        { data: HostDataInterface[]; totals: HostTotalsInterface }
    >({
        path: 'hosts',
        params: {
            ...interval.asParams(),
            [param]: filter,
        },
    }).request();

    return { hosts: response.response.data.map(hostData => new Host(hostData)), count: response.response.totals };
}

/**
 * Fetch the status information for all the hosts.
 * Each <HostConnectionStatus> calls this method, so we need to debounce to avoid triggering too many requests
 */
export const getFeatures = cacheResult<HostFeatureDataInterface[]>(async function getFeatures() {
    logger.info('getting host features');

    const { data } = await new APIRequest<HostFeatureDataInterface[]>({
        path: 'hosts/0/features',
    }).request();

    return data;
    /**
     * Time after we regard the status information to be stale and we need to fetch again.
     * Agents push data once per minute, but because the second of the minute at which they push is not determined, we need to fetch more than once a minute.
     */
}, 20);

export async function update(host: Host) {
    logger.info(`updating the host ${host.id}`);

    return new APIRequest<HostDataInterface[]>({
        path: `hosts/${host.id}`,
        method: 'put',
        params: {
            name: host.name,
            description: host.description,
            tags: host.tags,
        },
    }).request();
}

export async function remove(host: Host) {
    logger.info(`deleting the host ${host.id}`);

    return new APIRequest<HostDataInterface[]>({
        path: `hosts/${host.id}`,
        method: 'delete',
    }).request();
}

export function expressionIsAdvancedSyntax(expression: string) {
    // For backwards compatibility with the old hosts selector. The API supports this format now.
    // Source: https://github.com/VividCortex/ng-app/blob/master/src/js/components/uhf/directives/vcHostsSelector.js#L575
    if (expression.match(/^(?:id=\d+ *)+$/i) !== null || expression.match(/^(?:\d+, *)+$/i) !== null) {
        return false;
    }

    return expression.match(/(tag=|type=|#)/i) !== null;
}

export enum FILTER_MODE {
    basic = 'basic',
    advanced = 'advanced',
}
