import { Subject, Subscription } from 'rxjs';
import ping, { CheckResultInterface } from './ping';
import Timer from './timer';

export interface NoticeInterface {
    message: string;
    isError: boolean;
}

class Notice implements NoticeInterface {
    constructor(readonly message: string, readonly isError: boolean = false) {}
}

export default class SessionChecker {
    readonly sessionCheckSafetyOffset = 5e3;
    private subject: Subject<NoticeInterface>;
    private triggerCheckOnActivity = false;
    private timer: Timer;
    private expired: boolean;

    constructor(private pingUrl: string, private sessionTTL: number) {
        this.timer = new Timer();
        this.subject = new Subject();
        this.expired = false;

        this.subscribe(() => {
            this.expired = true;
            this.timer.clearAll();
        });
    }

    init() {
        this.subject.next(new Notice('session expires in ' + this.sessionTTL + 's'));
        this.timer.set('expiration', () => this.safeCheckForSessionExpired(), this.sessionTTL * 1e3);

        this.subject.next(new Notice(this.waitTime + 's nap'));
        this.timer.set('activityDetector', () => this.checkActivity(), this.waitTime);
    }

    registerActivity() {
        if (!this.triggerCheckOnActivity || this.expired) {
            // If the session already expired, ignore this activity event.
            return;
        }

        // As soon as activity is detected we refresh the session
        this.checkSessionExpired();
    }

    subscribe(onExpiration: () => void, onCheck?: (notice: NoticeInterface) => void): Subscription {
        return this.subject.subscribe(onCheck, () => null, onExpiration);
    }

    /**
     * We wait for a time that is a specific fraction of the session's TTL to
     * compensate for possible network failures. The substraction also gives
     * some margin before the expiration for the same reason.
     */
    get waitTime() {
        const partial = this.sessionTTL / 3;
        return (partial - Math.min(10, partial / 2)) * 1e3;
    }

    /**
     * After we suppose that the session expired, we wait for a bit before
     * checking. This waiting time avoids refreshing the session endlessly.
     */
    private safeCheckForSessionExpired() {
        this.subject.next(new Notice('session may have expired'));
        this.timer.set('safety', () => this.checkSessionExpired(), this.sessionCheckSafetyOffset);
    }

    private async checkSessionExpired() {
        // Prevent re-checking session expired until the check for activity is
        // re-enabled.
        this.triggerCheckOnActivity = false;

        let result: CheckResultInterface;
        try {
            result = await ping(this.pingUrl);
        } catch (error) {
            const expired = typeof error === 'object' ? (error as CheckResultInterface).expired : false;

            result = { error: true, expired };
        }

        this.processCheckResult(result);
    }

    private processCheckResult(result: CheckResultInterface) {
        result = result || {};

        if (result.expired) {
            this.subject.next(new Notice('session expired'));
            this.subject.complete();
            return;
        }

        if (!result.error) {
            this.subject.next(new Notice('Successfully refreshed the session'));

            // The session gets refreshed after a successful ping in the sf-app
            this.init();

            return;
        }

        this.subject.next(new Notice('failed to refresh the session. Will retry.', true));

        // Retry this check in a few seconds. We don't want to miss the session
        // refresh if the network was down momentarilly.
        this.timer.set(
            'retry',
            () => {
                this.subject.next(new Notice('retry'));
                this.checkSessionExpired();
            },
            this.waitTime
        );
    }

    private checkActivity() {
        this.subject.next(new Notice('listening for activity'));
        this.triggerCheckOnActivity = !this.expired;
    }
}
