import APIRequest, { RequestTarget } from './Request';
import Logger from 'services/logger';
import User from 'models/User';

const logger = Logger.get('userSubscriptions');

export interface SubscriptionInterface {
    id: number;
    name: string;
    subscribed: boolean;
    status: number;
}

export interface APISubscriptionInterface {
    report: number;
    env: number;
    subscribed: boolean;
}

export interface APIAutosubscriptionInterface {
    autosubscribe: boolean;
}

export async function getSubscriptions(user: User) {
    logger.info('getting a user subscription');

    const response = await new APIRequest<APISubscriptionInterface[]>({
        path: `users/${user.id}/subscriptions/reports/1`,
    }).request();

    return response.data;
}

export async function getAutoSubscription(user: User) {
    logger.info('getting the user autosubscription');

    const response = await new APIRequest<APIAutosubscriptionInterface>({
        path: `users/${user.id}/subscriptions/reports/1/settings/autosubscribe`,
    }).request();

    return response.data.autosubscribe;
}

export async function update(user: User, target?: RequestTarget, isEnabled = true) {
    logger.info('updating a user subscription');

    return new APIRequest({
        path: `users/${user.id}/subscriptions/reports/1`,
        method: isEnabled ? 'put' : 'delete',
        target,
    }).request();
}

export async function updateAutoSubscription(user: User, isEnabled = true) {
    logger.info('updating a user autosubscription');

    return new APIRequest({
        path: `users/${user.id}/subscriptions/reports/1/settings/autosubscribe`,
        method: isEnabled ? 'put' : 'delete',
    }).request();
}
