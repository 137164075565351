import { AuthMethodType } from 'models/AuthMethod';
import Authentication from 'models/Authentication';
import * as authSettingsService from 'services/settings/authentication';
import Bouncer from 'components/icons/Bouncer';
import BouncerButton from 'components/form/BouncerButton';
import Logger from 'services/logger';
import MethodInformation from './MethodInformation';
import OAuthProviderSelection from './OAuthProviderSelection';
import { FC, SyntheticEvent, useState, useEffect } from 'react';
import SAMLConfiguration from './SAMLConfiguration';
import SettingsLayout from '../Layout';
import useDisplayMessage from 'hooks/useDisplayMessage';
import FormGroup, { FormGroupVariantType } from 'components/form/Group';

enum AuthenticationStatus {
    LOADING = 'loading',
    READY = 'ready',
    SAVING = 'saving',
}

const logger = Logger.get('SettingsAuthentication');

const SettingsAuthentication: FC = () => {
    const [status, setStatus] = useState(AuthenticationStatus.LOADING);
    const [config, setConfig] = useState(authSettingsService.defaultConfig);
    const { success, error } = useDisplayMessage();

    async function init() {
        const fetchedConfig = await authSettingsService.getConfig();

        setConfig(fetchedConfig);
        setStatus(AuthenticationStatus.READY);
    }

    useEffect(() => {
        init();
    }, []);

    const handleConfigChange = (key: string, value: string) => {
        config[key] = value;

        setConfig({ ...config });
    };

    const handleChange = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const newConfig = { ...config };

        newConfig[e.currentTarget.name] = e.currentTarget.value;

        setConfig(newConfig);
    };

    const save = async () => {
        setStatus(AuthenticationStatus.SAVING);

        try {
            await authSettingsService.saveConfig(config);
        } catch (e) {
            logger.error(e);

            error('There was an error trying to save your configuration.');

            return;
        }

        setStatus(AuthenticationStatus.READY);

        success('Your configuration has been saved.');
    };

    if (status === AuthenticationStatus.LOADING) {
        return (
            <SettingsLayout title="Authentication" metaTitle="Auth">
                <Bouncer />
            </SettingsLayout>
        );
    }

    return (
        <SettingsLayout title="Authentication">
            <div className="settings__form settings__form--authentication">
                <form className="pt4 pb3">
                    <FormGroup variant={FormGroupVariantType.HORIZONTAL} label="Select authentication method:">
                        <fieldset className="grid">
                            <label
                                htmlFor="auth_type_basic"
                                data-testid="auth-basic"
                                className="form__label flex items-center"
                            >
                                <input
                                    type="radio"
                                    name="auth.method"
                                    value={Authentication.BASIC_METHOD}
                                    onChange={handleChange}
                                    checked={config['auth.method'] === Authentication.BASIC_METHOD}
                                    id="auth_type_basic"
                                    required
                                />
                                <span>Default (e-mail/password and OAuth)</span>
                            </label>

                            <label
                                htmlFor="auth_type_oauth"
                                data-testid="auth-oauth"
                                className="form__label flex items-center"
                            >
                                <input
                                    type="radio"
                                    name="auth.method"
                                    value={Authentication.OAUTH_METHOD}
                                    onChange={handleChange}
                                    checked={config['auth.method'] === Authentication.OAUTH_METHOD}
                                    id="auth_type_oauth"
                                    required
                                />
                                <span>Require OAuth authentication</span>
                            </label>
                            <label
                                htmlFor="auth_type_saml"
                                data-testid="auth-saml"
                                className="form__label flex items-center"
                            >
                                <input
                                    type="radio"
                                    name="auth.method"
                                    value={Authentication.SAML_METHOD}
                                    onChange={handleChange}
                                    checked={config['auth.method'] === Authentication.SAML_METHOD}
                                    id="auth_type_saml"
                                    required
                                />
                                <span>SAML SSO</span>
                            </label>
                        </fieldset>
                    </FormGroup>
                </form>

                <div className="mb2">
                    <MethodInformation method={config['auth.method'] as AuthMethodType} />
                </div>

                {config['auth.method'] === Authentication.OAUTH_METHOD && (
                    <OAuthProviderSelection
                        config={config}
                        onChange={handleChange}
                        onConfigChange={handleConfigChange}
                    />
                )}

                {config['auth.method'] === Authentication.SAML_METHOD && (
                    <SAMLConfiguration config={config} onChange={handleChange} />
                )}

                <BouncerButton
                    type="button"
                    onClick={save}
                    bounce={status === AuthenticationStatus.SAVING}
                    className="primary-orange mt2"
                    data-testid="auth-save"
                    disabled={!authSettingsService.isConfigValid(config)}
                >
                    Save
                </BouncerButton>
            </div>
        </SettingsLayout>
    );
};

export default SettingsAuthentication;
