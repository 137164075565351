import { useState, useEffect, RefObject } from 'react';

function useHover<T extends HTMLElement = HTMLElement>(elementRef: RefObject<T>): boolean {
    const [isHover, setHovered] = useState(false);

    useEffect(() => {
        const node = elementRef?.current;
        const handleMouseOver = () => setHovered(true);
        const handleMouseOut = () => setHovered(false);

        if (!node) {
            return;
        }

        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
            node.removeEventListener('mouseover', handleMouseOver);
            node.removeEventListener('mouseout', handleMouseOut);
        };
    }, [elementRef]);
    return isHover;
}

export default useHover;
