import Role, { RoleDataInterface } from './Role';
import User from './User';

interface UserInTeam {
    id: number;
}

interface EnvInTeamInterface {
    id: number;
    role: { id: number; name: string };
}

export interface TeamDataInterface {
    id: number;
    org: number;
    name: string;
    type: string;
    role: RoleDataInterface;
    users?: UserInTeam[];
    envs?: EnvInTeamInterface[];
}

export default class Team {
    readonly id: number;
    readonly org: number;
    readonly name: string;
    readonly type: string;
    readonly role: Role;
    users: (User | UserInTeam)[];
    readonly envs: EnvInTeamInterface[];

    constructor(data: TeamDataInterface) {
        this.id = data.id;
        this.org = data.org;
        this.name = data.name;
        this.type = data.type;
        this.role = new Role(data.role);
        this.users = data.users || [];
        this.envs = data.envs || [];
    }

    addUsers(users: User[] | UserInTeam[]) {
        const newUsers = users.filter((user: UserInTeam | User) => {
            return !this.users.find((userInTeam: UserInTeam | User) => userInTeam.id === user.id);
        });

        this.users.push(...newUsers);
    }

    removeUsers(users: User[]) {
        const userIdsToRemove = users.map((user: User) => user.id);
        this.users = this.users.filter((user: UserInTeam | User) => !userIdsToRemove.includes(user.id));
    }
}
