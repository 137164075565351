/* eslint-disable @typescript-eslint/naming-convention */
import { AgentWindowInterface, TimeWindow, DayWindow } from 'services/api/agentConfig';

interface AgentWindowsInterface {
    updateOnDays: boolean[];
    updateOnTimes: boolean[];
}

interface WindowTimes {
    [key: number]: string;
}

const windowTimes: WindowTimes = {
    0: '12am',
    179: '3am',
    180: '3am',
    359: '6am',
    360: '6am',
    539: '9am',
    540: '9am',
    719: '12pm',
    720: '12pm',
    899: '3pm',
    900: '3pm',
    1079: '6pm',
    1080: '6pm',
    1259: '9pm',
    1260: '9pm',
    1439: '12am',
};

//With these values we can consider the selection to be "any day" and "any time"
const ANY_DAY_DURATION = 6;
const ANY_TIME_DURATION = 1439;

//Each time option consists of a block of 3hs (180min)
const TIME_MINUTE_BLOCK = 180;

export const agentDaysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const agentTimes = [
    { id: 'twelveToThree', endFrame: '3:00am', startFrame: '12:00am' },
    { id: 'threeToSix', endFrame: '6:00am', startFrame: '3:00am' },
    { id: 'sixToNine', endFrame: '9:00am', startFrame: '6:00am' },
    { id: 'nineToTwelve', endFrame: '12:00pm', startFrame: '9:00am' },
    { id: 'twelveToFifteen', endFrame: '3:00pm', startFrame: '12:00pm' },
    { id: 'fifteenToEighteen', endFrame: '6:00pm', startFrame: '3:00pm' },
    { id: 'eighteenToTwentyOne', endFrame: '9:00pm', startFrame: '6:00pm' },
    { id: 'twentyOneToTwentyFour', endFrame: '12:00am', startFrame: '9:00pm' },
];

export class AgentWindows implements AgentWindowsInterface {
    updateOnDays = [false, false, false, false, false, false, false]; // 0:Monday - 6:Sunday
    updateOnTimes = [false, false, false, false, false, false, false, false]; // 0: 00AM to 3AM - 7: 09PM to 00AM

    constructor(windows: AgentWindowInterface[] | []) {
        windows.forEach(updateWindow => {
            // Starting with the start day, enable every day between (and including) the start and
            // end days.
            for (let x = updateWindow.day_start; x <= updateWindow.day_end; x += 1) {
                this.updateOnDays[x] = true;
            }

            // The view for picking specific times is blocked into eight 3 hour (180 minute) blocks.
            // So we take the start minute and divide by 180 and round it down to the nearest
            // integer. This rounded value will be equal to the index we need to set to true.
            // We then do the same calculation on the ending minute so that we can enable each block
            // between the start and end minute.
            for (
                let y = Math.floor(updateWindow.minute_start / TIME_MINUTE_BLOCK);
                y <= Math.floor(updateWindow.minute_end / TIME_MINUTE_BLOCK);
                y += 1
            ) {
                this.updateOnTimes[y] = true;
            }
        });
    }

    clone() {
        return new AgentWindows(this.toRawFormat());
    }

    /**
     * Returns the update windows in the format expected by the API
     */
    toRawFormat() {
        let dayWindows = this.calculateDayWindows();
        let timeWindows = this.calculateTimeWindows();

        if (!timeWindows.length) {
            timeWindows = [{ minute_start: -2, minute_end: -1 }];
        }

        if (!dayWindows.length) {
            dayWindows = [{ day_start: -2, day_end: -1 }];
        }

        const updateWindows: AgentWindowInterface[] = [];

        dayWindows.forEach(dayWindow => {
            const dayComment = this.calculateDayComment([dayWindow]);

            timeWindows.forEach(timeWindow => {
                const newWindow: AgentWindowInterface = Object.assign({ comment: '' }, timeWindow, dayWindow);
                const timeComment = this.calculateTimeComment([timeWindow]);

                newWindow.comment = dayComment + ' / ' + timeComment + ' UTC';
                updateWindows.push(newWindow);
            });
        });

        return updateWindows;
    }

    /**
     * Calculates the summary comment
     */
    calculateSummaryComment() {
        const dayWindows = this.calculateDayWindows();
        const timeWindows = this.calculateTimeWindows();

        if (dayWindows.length && timeWindows.length) {
            return this.calculateDayComment(dayWindows) + ' / ' + this.calculateTimeComment(timeWindows) + ' UTC';
        }
        return 'You must choose at least one of the “Days of the Week” and at least one “Time of Day”.';
    }

    /**
     * Calculates API parameters day_start and day_end
     */
    private calculateDayWindows() {
        const response = [];
        let inProgress = false;

        let newWindow: DayWindow = {
            day_start: 0,
            day_end: 0,
        };

        this.updateOnDays.forEach((val, ind) => {
            // If a value is TRUE and we have not started a new update window, then we make
            // the update window with the start and end date the same.
            if (val && !inProgress) {
                newWindow.day_start = ind;
                newWindow.day_end = ind;
                inProgress = true;
            }
            // If we hit a continuous string of days, then we just want to change the end day.
            else if (val) {
                newWindow.day_end = ind;
            }
            // Once we hit a value of false, we want to push this update window to the response. Any
            // future day will need to be accounted for in a separate update window.
            else if (inProgress) {
                response.push(newWindow);
                inProgress = false;
                newWindow = { day_start: 0, day_end: 0 };
            }
        });

        // If inProgress is true, that means the last day of the week (Sunday) was set to true. We
        // want to push this last value to the response.
        if (inProgress) {
            response.push(newWindow);
            inProgress = false;
        }
        return response;
    }

    /**
     * Calculates API parameters minute_start and minute_end
     */
    private calculateTimeWindows() {
        const response = [];
        const timeBlock = TIME_MINUTE_BLOCK;

        let inProgress = false;

        let newWindow: TimeWindow = {
            minute_start: 0,
            minute_end: 0,
        };

        this.updateOnTimes.forEach((val, ind) => {
            // If a value is TRUE and we have not started a new update window, then we take the index
            // and make the correct calculations, based on a block of value 'timeBlock'.
            if (val && !inProgress) {
                newWindow.minute_start = ind * timeBlock;
                newWindow.minute_end = (ind + 1) * timeBlock - 1; // We want to subtract 1 minute here
                inProgress = true;
            }
            // If we hit a continuous string of hours, then we just want to change the end time.
            else if (val) {
                newWindow.minute_end = (ind + 1) * timeBlock - 1; // We want to subtract 1 minute here
            }
            // Once we hit a value of false, we want to push this update window to the response. Any
            // future time will need to be accounted for in a separate update window.
            else if (inProgress) {
                response.push(newWindow);
                inProgress = false;
                newWindow = { minute_start: 0, minute_end: 0 };
            }
        });

        // If inProgress is true, that means the last time block was set to true. We
        // want to push this last value to the response.
        if (inProgress) {
            response.push(newWindow);
            inProgress = false;
        }
        return response;
    }

    /**
     * Calculates comment (description) regarding what days are selected.
     */
    private calculateDayComment(dayWindows: DayWindow[]) {
        const response: string[] = [];

        dayWindows.forEach((window: DayWindow) => {
            if (window.day_start === 0 && window.day_end === ANY_DAY_DURATION) {
                response.push('Any Day');
            } else {
                response.push(
                    window.day_start === window.day_end
                        ? agentDaysOfWeek[window.day_start]
                        : agentDaysOfWeek[window.day_start] + ' - ' + agentDaysOfWeek[window.day_end]
                );
            }
        });

        return response.join(', ');
    }

    /**
     * Calculates comment (description) regarding what times are selected.
     */
    private calculateTimeComment(timeWindows: TimeWindow[]) {
        const response: string[] = [];

        timeWindows.forEach((window: TimeWindow) => {
            if (window.minute_start === 0 && window.minute_end === ANY_TIME_DURATION) {
                response.push('Any Time');
            } else {
                response.push(windowTimes[window.minute_start] + ' - ' + windowTimes[window.minute_end]);
            }
        });

        return response.join(', ');
    }
}
