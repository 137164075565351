import Role, { RoleDataInterface } from './Role';

export type APITokenType = 'api' | 'sso' | 'agent';

export interface APITokenInterface {
    env: number;
}

interface APITokenCommonInterface extends APITokenInterface {
    id: number;
    user: number;
    hash: string;
    org: number;
    name: string;
    status: number;
    expires?: number;
    created: number;
    lastUsed?: number;
    updated: number;
    deleted: number;
    type: APITokenType;
    role: RoleDataInterface;
}

export interface APIRotationTokenInterface extends APITokenCommonInterface {
    rotationTokenOf: number;
}

export interface APIDeprecatedTokenInterface extends APITokenCommonInterface {
    supersededBy: number;
}

export interface APITokenDataInterface extends APITokenCommonInterface {
    rotationToken?: APIRotationTokenInterface;
    deprecatedTokens?: APIDeprecatedTokenInterface[];
}

export default class APIToken {
    static TYPE_API = 'api';
    static TYPE_SSO = 'sso';

    readonly id: number;
    readonly user: number;
    readonly env: number;
    readonly org: number;
    readonly status: number;
    readonly expires?: number;
    readonly created: number;
    readonly updated: number;
    readonly deleted: number;
    readonly lastUsed?: number;
    readonly type: APITokenType;
    role: Role;
    name: string;
    hash?: string;
    rotationToken?: APIRotationTokenInterface;
    deprecatedTokens?: APIDeprecatedTokenInterface[];

    constructor(data: APITokenDataInterface) {
        this.id = data.id;
        this.user = data.user;
        this.hash = data.hash;
        this.env = data.env;
        this.org = data.org;
        this.status = data.status;
        this.expires = data.expires;
        this.created = data.created;
        this.updated = data.updated;
        this.deleted = data.deleted;
        this.lastUsed = data.lastUsed;
        this.type = data.type;
        this.role = new Role(data.role);
        this.name = data.name;
        this.rotationToken = data.rotationToken;
        this.deprecatedTokens = data.deprecatedTokens;
    }
}
