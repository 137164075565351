import { AuthMethodType } from 'models/AuthMethod';
import Authentication from 'models/Authentication';
import Feature from 'components/serviceLevel/Feature';
import * as React from 'react';

interface MethodInformationPropsInterface {
    method: AuthMethodType;
}

const MethodInformation: React.FC<MethodInformationPropsInterface> = ({ method }) => {
    switch (method) {
        case Authentication.OAUTH_METHOD: {
            const fallbackContent = (
                <div className="line-height-120" data-testid="oauth-method-unavailable">
                    <p>
                        Google OAuth authentication is required for your team, using your company&apos;s Google G Suite
                        domain email address.
                    </p>
                    <p className="mt2">Users are created the first time they login with default privileges.</p>
                </div>
            );
            return (
                <div className="line-height-120" data-testid="oauth-method-information">
                    <Feature name="githubAuth" fallback={fallbackContent}>
                        <p>Require OAuth authentication for your team.</p>
                        <p className="mt2" data-testid="oauth-method-available">
                            If you chose to use Google oAuth then your users will authenticate using your company&apos;s
                            Google G Suite domain email address. On the other hand, if you chose to use GitHub oAuth
                            then your users will authenticate using their GitHub account and will access Database
                            Performance Monitor only if they are part of the GitHub organization you choose.
                        </p>
                        <p className="mt2">Users are created the first time they login with default privileges.</p>
                    </Feature>
                </div>
            );
        }
        case Authentication.SAML_METHOD:
            return (
                <div className="line-height-120" data-testid="saml-method-information">
                    <p>
                        If you use SAML Single sign-on, all your team is provisioned (and deprovisioned) in real time
                        from a central ID provider of your organization.
                    </p>
                    <p className="mt2">
                        At this time, Okta, OneLogin, and Azure are our supported providers for single sign-on. Please
                        contact us if you require a different provider.
                    </p>
                    <Feature name="authSAMLRequired">
                        <p className="mt2" data-testid="saml-method-available">
                            Below you can find a quick guide on how to configure your ID Provider.
                        </p>
                    </Feature>
                </div>
            );
        default:
            return (
                <div className="line-height-120" data-testid="basic-method-information">
                    <p>
                        The default authentication setting allows users to login using their email/password or OAuth
                        with their Google or GitHub account.
                    </p>
                    <p className="mt2">
                        These authentication methods only work for accounts created beforehand; i.e., you have to invite
                        people to your team for them to get access.
                    </p>
                </div>
            );
    }
};

export default MethodInformation;
