import Alert, { AlertTypes } from 'components/messages/Alert';
import BouncerButton from 'components/form/BouncerButton';
import FormActions from 'components/form/Actions';
import Gravatar, { GravatarSize } from 'components/icons/Gravatar';
import Group, { FormGroupVariantType } from 'components/form/Group';
import Input from 'components/form/Input';
import TextField from 'components/form/fields/TextField';
import { FC, useState } from 'react';
import useUser from 'hooks/useUser';
import { Field } from 'react-final-form-html5-validation';
import { Form, FieldRenderProps } from 'react-final-form';
import { update, validateName } from 'services/api/users';

const EditProfile: FC = () => {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const user = useUser();

    const nameIsValid = (name = '') => {
        if (!name.includes(' ')) {
            return 'Please tell us both your first and last names';
        }

        return validateName(name) ? undefined : 'Some symbols are not allowed in your name';
    };

    const handleSubmit = async ({ name }: { name: string }) => {
        setError('');
        setSuccess(false);

        try {
            await update({ ...user.toJson(), name });

            user.updateName(name);

            setSuccess(true);
        } catch (e) {
            setError(e instanceof Error ? e.message : 'There was an error updating your profile');
        }
    };

    return (
        <>
            {error && (
                <Alert type={AlertTypes.ERROR} visible className="my2" data-testid="error-information-change">
                    <strong>Oops!</strong> There was an error trying to update your information. {error}
                </Alert>
            )}

            {success && (
                <Alert type={AlertTypes.SUCCESS} visible className="my2" data-testid="success-information-change">
                    <strong>Success!</strong> Your information has been updated.
                </Alert>
            )}

            <Form
                onSubmit={handleSubmit}
                render={({ pristine, invalid, handleSubmit: onSubmit, submitting }) => (
                    <form className="settings__form settings__form--profile mt4" onSubmit={onSubmit}>
                        <Group variant={FormGroupVariantType.HORIZONTAL} label="Avatar:">
                            <div className="inline-flex items-center">
                                <Gravatar size={GravatarSize.BIG} className="mr3" email={user.email} name={user.name} />
                                <div className="lighter h4 line-height-120">
                                    <p>You can change your avatar in</p>
                                    <a target="_blank" rel="noopener noreferrer" href="https://gravatar.com/">
                                        https://gravatar.com/
                                    </a>
                                </div>
                            </div>
                        </Group>

                        <Group variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="Full Name:">
                            <Field
                                name="name"
                                initialValue={user.name}
                                validate={nameIsValid}
                                required
                                maxLength="45"
                                valueMissing="Your name can't be empty"
                                tooLong="The user name cannot be longer than 45 characters"
                            >
                                {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                    <TextField {...props} data-testid="full-name-input" />
                                )}
                            </Field>
                        </Group>

                        <Group variant={FormGroupVariantType.HORIZONTAL} className="mt3" label="E-mail:">
                            <Input
                                type="email"
                                value={user.email}
                                disabled
                                helpText="Your email is for logging in and cannot be changed."
                            />
                        </Group>

                        <FormActions className="mt3">
                            <BouncerButton
                                disabled={pristine || invalid}
                                data-testid="update-btn"
                                bounce={submitting}
                                className="primary-orange"
                            >
                                Update
                            </BouncerButton>
                        </FormActions>
                    </form>
                )}
            />
        </>
    );
};

export default EditProfile;
