import * as React from 'react';
import Org from 'models/Org';

const Heading: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <h1 data-testid="heading" className="h2 dark center lighter">
            {children}
        </h1>
    );
};

export const OrgLoginHeading = () => <Heading>Log Into Your Database Performance Monitor Account</Heading>;

export const UserLoginHeading: React.FC<{ org: Org }> = ({ org }) => (
    <Heading>
        Login to your <span className="mainColor">{org.name}</span> account at DPM
    </Heading>
);

export default Heading;
