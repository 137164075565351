import AsyncHostAttribute from 'components/host/AsyncHostAttribute';
import Category from 'models/bestPractices/Category';
import Digest from 'components/query/AsyncDigest';
import Occurrence from 'models/bestPractices/Occurrence';
import OccurrenceTarget, { OccurrenceHost, OccurrenceObject } from 'models/bestPractices/OccurrenceTarget';
import * as React from 'react';
import TimeAgo from 'components/format/TimeAgo';
import Timestamp from 'components/format/Timestamp';
import { UserHasPermission } from 'components/security/UserHasPermission';

type ToggleSupressionCallbackType = (arg0: Category, arg1: Occurrence, arg2?: OccurrenceTarget) => void;

const TargetsTable: React.FC<{
    occurrence: Occurrence;
    category: Category;
    mute: ToggleSupressionCallbackType;
    unmute: ToggleSupressionCallbackType;
}> = ({ occurrence, mute, unmute, category }) => {
    return (
        <table className="vc-table vc-table--settings vc-table--best-practices full-width">
            <thead>
                <tr>
                    <th className="vc-table--best-practices__occurence">{occurrence.type}</th>
                    <th className="vc-table--best-practices__status">Status</th>
                    <th>First Seen</th>
                    <th>Last Seen</th>
                    <UserHasPermission to="changeEnv">
                        <th>Actions</th>
                    </UserHasPermission>
                </tr>
            </thead>
            <tbody>
                {occurrence.targets.map(target => (
                    <tr data-testid="occurrence-host" key={target.id}>
                        <td>
                            {target instanceof OccurrenceHost && (
                                <div className="flex items-center" title={`id=${target.hostId}`}>
                                    <AsyncHostAttribute id={target.hostId} attribute="icon" className="grey3 h2" />

                                    <AsyncHostAttribute id={target.hostId} className="ml2 regular" />
                                </div>
                            )}

                            {target instanceof OccurrenceObject && (
                                <div className="flex flex-column justify-center">
                                    <Digest queryId={target.queryId} />
                                </div>
                            )}
                        </td>
                        <td className="py2">
                            <div className="flex flex-column">
                                <span>{target.muted ? 'Muted' : target.passed ? 'Passed' : 'Actionable'}</span>
                                <span className="monospace fz12 grey3 mt1">{target.message}</span>
                            </div>
                        </td>
                        <td>
                            <div className="flex flex-column">
                                <TimeAgo date={target.firstSeen} />
                                <span className="monospace fz12 grey3 mt1 nowrap">
                                    <Timestamp date={target.firstSeen} />
                                </span>
                            </div>
                        </td>
                        <td>
                            <div className="flex flex-column">
                                <TimeAgo date={target.lastSeen} />
                                <span className="monospace fz12 grey3 mt1 nowrap">
                                    <Timestamp date={target.lastSeen} />
                                </span>
                            </div>
                        </td>
                        <UserHasPermission to="changeEnv">
                            <td>
                                <span
                                    className="vc-table--best-practices__mute cursor-hand mainColor"
                                    onClick={() =>
                                        target.muted
                                            ? unmute(category, occurrence, target)
                                            : mute(category, occurrence, target)
                                    }
                                >
                                    {target.muted ? 'Unmute' : 'Mute'}
                                </span>
                            </td>
                        </UserHasPermission>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TargetsTable;
