import Heading from 'components/modules/public/Heading';
import JoinForm from './Form';
import useUrl from 'hooks/useUrl';

const Join = () => {
    const homepageUrl = useUrl('homepage');
    const loginUrl = useUrl('login');
    const joinUrl = useUrl('join');

    return (
        <>
            <Heading>Join Database Performance Monitor</Heading>

            <section id="invite" className="card-block shadow mx-auto my4 relative flex bg-white p3 flex-column">
                <JoinForm loginUrl={loginUrl} homepageUrl={homepageUrl} endpoint={joinUrl} />
            </section>
        </>
    );
};

export default Join;
