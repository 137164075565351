import AsyncHostAttribute from 'components/host/AsyncHostAttribute';
import Diagram, { NodeInterface } from 'components/visualizations/deadlocks/Diagram';
import Deadlock from 'models/queries/Deadlock';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import Header from './DeadlockHeader';
import Loader, { LoaderTypes } from 'components/loaders/Loader';
import Logger from 'services/logger';
import ModuleTitle from 'components/modules/app/ModuleTitle';
import NodeDetail from './DeadlockNodeDetail';
import useTimeAndHostFilter from 'hooks/useTimeAndHostFilter';
import { FC, useEffect, useState } from 'react';
import { get } from 'services/api/deadlocks';
import { Link } from 'components/url/Link';
import { useParams } from 'react-router-dom';

const logger = Logger.get('Deadlocks');

const PageHeader: FC<{ children?: React.ReactNode }> = ({ children }) => (
    <>
        <div className="fz12 mb2 mainColor">
            <Link to="deadlocks">All Deadlocks</Link>
        </div>
        <div className="fz18 regular">{children}</div>
    </>
);

const Loading: FC = () => (
    <div className="view deadlocks">
        <ModuleTitle contextualHelpId="Deadlocks">
            <PageHeader>
                Deadlock on:
                <Loader type={LoaderTypes.TABLECELL} className="inline-block" />
            </PageHeader>
        </ModuleTitle>
        <div className="tile p3 mt3">
            <Loader type={LoaderTypes.TABLECELL} />
        </div>
    </div>
);

const NotFound: FC = () => (
    <div className="view deadlocks">
        <ModuleTitle contextualHelpId="Deadlocks">
            <PageHeader>Deadlock not found</PageHeader>
        </ModuleTitle>
        <div className="p3 mt3">
            <EmptyState type={EmptyTypes.SERVER}>
                No deadlock information found for the provided identifier, timestamp and host selection.
            </EmptyState>
        </div>
    </div>
);

const DeadlockDetail: FC = () => {
    const [selectedNode, setSelectedNode] = useState<NodeInterface | null>(null);
    const [deadlock, setDeadlock] = useState<undefined | null | Deadlock>(undefined);
    const { filter, interval } = useTimeAndHostFilter();
    const { id, ts } = useParams<{ id: string; ts: string }>();

    useEffect(() => {
        get(`${id}`, `${ts}`, filter, interval)
            .then(deadlockDetail => {
                setDeadlock(deadlockDetail);

                if (deadlockDetail) {
                    setSelectedNode(deadlockDetail.defaultVictimNode || null);
                }
            })
            .catch(error => {
                logger.error(error);

                setDeadlock(null);
            });
    }, [id, ts, filter, interval]);

    if (deadlock === undefined) {
        return <Loading />;
    }

    if (deadlock === null) {
        return <NotFound />;
    }

    return (
        <>
            <div className="view deadlocks">
                <ModuleTitle contextualHelpId="Deadlocks">
                    <PageHeader>
                        Deadlock on:
                        <AsyncHostAttribute id={deadlock.hostId} attribute="icon" className="ml3 inline-block" />
                        <AsyncHostAttribute id={deadlock.hostId} className="ml2 inline-block" />
                        <span className="ml3 grey3 fz12">ID: {id}</span>
                    </PageHeader>
                </ModuleTitle>
                <div className="tile p3 mt3">
                    <Header deadlock={deadlock} />
                </div>
                <div className="tile mt3 flex flex-1 relative overflow-hidden full-height">
                    <div className="viz-deadlock flex-column deadlock__diagram full-width">
                        <Diagram
                            nodes={deadlock.diagramNodes}
                            edges={deadlock.diagramEdges}
                            selectedNode={selectedNode}
                            onNodeClick={node => setSelectedNode(node)}
                        />
                    </div>
                    {selectedNode && <NodeDetail deadlock={deadlock} node={selectedNode} />}
                </div>
            </div>
        </>
    );
};

export default DeadlockDetail;
