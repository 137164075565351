import { ColumnInterface, QuantityColumnInterface, MainColumnInterface } from 'models/profiler/columns';
import { ColumnDataType, QuantityDataInterface } from 'models/profiler/columnData';
import { ProfilerInterface } from 'models/profiler';
import Quantity from 'models/numbers/Quantity';
import { fromName } from 'models/numbers/Unit';
import TimeSeries from 'models/numbers/TimeSeries';
import TimeInterval from 'models/TimeInterval';
import buildProfilerRequest from './requestBuilder';

interface ColumnDataAPIResponse<Element> {
    elements: Element[];
}

export type AsyncColumnDataType<Column extends ColumnInterface> = Promise<ColumnDataType<Column>>;

type AsyncColumnElementsDataType<Column extends ColumnInterface> = Promise<
    ColumnDataAPIResponse<ColumnDataType<Column>>
>;
type FetchProfilerDataResult<Column extends ColumnInterface> = Record<string, AsyncColumnElementsDataType<Column>>;

export default class ColumnDataFetcher {
    constructor(
        private profiler: ProfilerInterface,
        private timeInterval: TimeInterval,
        private hostFilter: string,
        private sampleSize: number,
        private requestId: string
    ) {}

    fetchProfilerData(): FetchProfilerDataResult<ColumnInterface> {
        return this.profiler.columns.reduce<FetchProfilerDataResult<ColumnInterface>>((accumulator, column) => {
            switch (column.renderAs) {
                case 'main':
                    accumulator[column.id] = this.fetchMainColumnData(
                        column as MainColumnInterface
                    ) as AsyncColumnElementsDataType<ColumnInterface>;
                    break;
                case 'quantity':
                    accumulator[column.id] = this.fetchQuantityColumnData(
                        column as QuantityColumnInterface
                    ) as AsyncColumnElementsDataType<ColumnInterface>;
            }

            return accumulator;
        }, {});
    }

    private fetchMainColumnData(column: MainColumnInterface): AsyncColumnElementsDataType<MainColumnInterface> {
        const columnUnit = fromName(column.unit);
        const ratioUnit = fromName('ratio');

        type SummarizedKeys = typeof column.summarizeAs | `${typeof column.summarizeAs}Pct`;
        type ResponseElement = Record<typeof column.id, Record<SummarizedKeys, number> & Record<'series', number[]>>;

        return this.fetchColumnData<MainColumnInterface, ResponseElement>(column, (element: ResponseElement) => ({
            value: new Quantity(element[column.id][column.summarizeAs], columnUnit),
            percentage: new Quantity(element[column.id][column.summarizeAs + 'Pct'], ratioUnit),
            series: new TimeSeries(columnUnit, element[column.id].series, this.timeInterval),
        }));
    }

    private fetchQuantityColumnData(
        column: QuantityColumnInterface
    ): AsyncColumnElementsDataType<QuantityColumnInterface> {
        const columnUnit = fromName(column.unit);
        const ratioUnit = fromName('ratio');

        type SummarizedKeys = typeof column.summarizeAs | `${typeof column.summarizeAs}Pct`;
        type ResponseElement = Record<typeof column.id, Record<SummarizedKeys, number>>;

        return this.fetchColumnData<QuantityColumnInterface, ResponseElement>(
            column,
            (element: ResponseElement): QuantityDataInterface => ({
                value: new Quantity(element[column.id][column.summarizeAs], columnUnit),
                percentage: new Quantity(element[column.id][column.summarizeAs + 'Pct'], ratioUnit),
            })
        );
    }

    private fetchColumnData<Column extends ColumnInterface, Element>(
        column: Column,
        elementMapper: (element: Element) => ColumnDataType<Column>
    ): AsyncColumnElementsDataType<Column> {
        const request = buildProfilerRequest<ColumnDataAPIResponse<Element>>(
            this.profiler,
            this.timeInterval,
            this.hostFilter,
            {
                includeRestOfData: true,
                sampleSize: this.sampleSize,
                requestID: this.requestId,
            },
            column
        );

        return request
            .request()
            .then(({ data }) => data)
            .then(({ elements }) => ({ elements: elements.map(elementMapper) }));
    }
}
