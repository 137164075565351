import { createContext, FC, useContext } from 'react';
import { useBootstrap } from 'components/context/Bootstrap';
import User from 'models/User';

export const UserContext = createContext<User | undefined>(undefined);

export const AuthenticatedUserProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const [{ bootstrap }] = useBootstrap();

    const user = bootstrap.user?.id ? bootstrap.user : undefined;

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useAuthenticatedUser = (): User | undefined => {
    return useContext(UserContext);
};
