import OccurrenceTarget, { OccurrenceTargetDataInterface, OccurrenceHost, OccurrenceObject } from './OccurrenceTarget';

export interface OccurrenceDataInterface {
    readonly condition: string;
    readonly description: string;
    readonly hasMore: boolean;
    readonly id: number;
    readonly hosts?: OccurrenceTargetDataInterface[];
    readonly objects?: OccurrenceTargetDataInterface[];
    readonly title: string;
}

type OccurrenceType = 'Query' | 'Host';
export type OccurrenceSeverityType = 'passed' | 'muted' | 'critical' | 'warning' | 'info';

export default class Occurrence {
    readonly condition: string;
    readonly description: string;
    readonly id: number;
    readonly title: string;
    readonly type?: OccurrenceType;
    hasMore: boolean;
    targets: OccurrenceTarget[] = [];
    offset = 50;
    severity!: OccurrenceSeverityType;
    passed!: boolean;
    muted!: boolean;
    expanded = false;

    constructor({ condition, description, hasMore, id, title, ...props }: OccurrenceDataInterface) {
        this.condition = condition;
        this.description = description;
        this.hasMore = hasMore;
        this.id = id;
        this.title = title;

        if (props.hosts) {
            this.targets = props.hosts.map(data => new OccurrenceHost(data));
            this.type = 'Host';
        } else if (props.objects) {
            this.targets = props.objects.map(data => new OccurrenceObject(data));
            this.type = 'Query';
        }

        this.setDerivedAttributes();
    }

    get docsHash() {
        const hash = this.condition
            .replace(/\./, '-best-practice-')
            .replace('os.', 'operating-system-')
            .replace('net.', 'network-')
            .replace('repl.', 'replication-')
            .replace('fs.', 'filesystem-')
            .replace(/[._]/g, '-');

        return hash;
    }

    private setDerivedAttributes() {
        this.muted = this.targets.reduce((muted: boolean, target: OccurrenceTarget) => muted && target.muted, true);
        this.passed = this.targets.reduce((passed: boolean, target: OccurrenceTarget) => passed && target.passed, true);

        if (this.muted) {
            this.severity = 'muted';
            return;
        }

        if (this.passed) {
            this.severity = 'passed';
            return;
        }

        for (const target of this.targets) {
            if (target.severity === 'crit') {
                this.severity = 'critical';

                return;
            }

            if (target.severity === 'warn') {
                this.severity = 'warning';

                continue;
            }

            if (!this.severity) {
                this.severity = target.severity;
            }
        }
    }
}
