import Icon from 'components/icons/Icon';
import SettingsLayout from '../Layout';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import {
    EnvSubscriptionInterface,
    get as getSubscriptions,
    update as updateSubscription,
} from 'services/api/envSubscriptions';

import useDisplayMessage from 'hooks/useDisplayMessage';
import FeatureLink from 'components/url/FeatureLink';
import LoaderRow from 'components/loaders/LoaderRow';
import AsyncHostAttribute from 'components/host/AsyncHostAttribute';
import SubscriptionDropdown from './SubscriptionDropdown';
import { UserHasPermission } from 'components/security/UserHasPermission';
import { userIsAllowedTo } from 'services/security/accessControl';
import { useFinalActionModal } from 'components/context/FinalActionModal';

const SettingsAnomalyDetection = () => {
    const [loading, isLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState<EnvSubscriptionInterface[] | null>(null);
    const [filter, setFilter] = useState('');
    const { openModal } = useFinalActionModal();

    const { success, error } = useDisplayMessage();

    const filteredSubscriptions = useMemo(() => {
        if (!filter) {
            return subscriptions;
        }
        return subscriptions?.filter(subscription => subscription.metric.includes(filter.trim().toLowerCase())) || [];
    }, [filter, subscriptions]);

    const fetchSubscriptions = async () => {
        isLoading(true);
        try {
            const result = await getSubscriptions();
            //break out by host as that's what the table needs to display
            const subscriptionsByHost: EnvSubscriptionInterface[] = [];
            result.forEach(subscription => {
                subscription.hosts.forEach(host => {
                    subscriptionsByHost.push({ metric: subscription.metric, hosts: [host] });
                });
            });

            subscriptionsByHost.sort((a, b) => a.metric.localeCompare(b.metric) || a.hosts[0] - b.hosts[0]);

            setSubscriptions(subscriptionsByHost);
        } catch (e) {
            error('We are unable to fetch the subscriptions at this time. Please try again or contact support.');
        } finally {
            isLoading(false);
        }
    };

    const handleNewSubscription = () => {
        fetchSubscriptions();
    };

    const handleFilter = (e: SyntheticEvent<HTMLInputElement>) => {
        setFilter(e.currentTarget.value);
    };

    const handleDeleteSubscription = async (subscription: EnvSubscriptionInterface) => {
        try {
            await updateSubscription(subscription, 'remove');
            success(`The subscription for metric ${subscription.metric} has been removed`);

            fetchSubscriptions();
        } catch {
            error('We are unable to remove the subscription at this time. Please try again or contact support.');
        }
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const headerTools = (
        <div className="flex flex-wrap justify-end">
            {subscriptions && subscriptions.length > 0 ? (
                <div className="anomalies__metric-search relative my1">
                    <input
                        className="full-width bg-white rounded"
                        placeholder="Search for metric"
                        onChange={handleFilter}
                    />
                    <Icon icon="search" className="grey3 h2 absolute right-1 top-1" />
                </div>
            ) : null}

            <UserHasPermission to="changeEnvSettings">
                <SubscriptionDropdown onNewSubscription={handleNewSubscription} />
            </UserHasPermission>
        </div>
    );
    return (
        <SettingsLayout
            title="Anomaly Detection Subscriptions"
            metaTitle="Anomaly Detection Subscriptions"
            headerContent={headerTools}
        >
            <div className="relative">
                {((subscriptions && subscriptions.length > 0) || loading) && (
                    <table className="vc-table vc-table vc-table--settings full-width pt4">
                        <thead>
                            <tr>
                                <th>Metric</th>
                                <th>Host</th>

                                <UserHasPermission to="changeEnvSettings">
                                    <th className="vc-table--anomalies__header__trash">
                                        <Icon icon="trash" className="justify-center swordSteel fz18" />
                                    </th>
                                </UserHasPermission>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                filteredSubscriptions &&
                                filteredSubscriptions.map(subscription => (
                                    <tr
                                        key={`${subscription.metric}-${subscription.hosts[0]}`}
                                        data-testid="subscription-row"
                                    >
                                        <td>
                                            <FeatureLink to={`charts/metrics/${subscription.metric}`}>
                                                {subscription.metric}
                                            </FeatureLink>
                                        </td>
                                        <td>
                                            <AsyncHostAttribute id={subscription.hosts[0]} />
                                        </td>

                                        <UserHasPermission to="changeEnvSettings">
                                            <td>
                                                <Icon
                                                    icon="trash"
                                                    className="justify-center swordSteel fz18 hoverable"
                                                    onClick={() =>
                                                        openModal({
                                                            onSubmit: () => handleDeleteSubscription(subscription),
                                                            confirmMsg: `Are you sure you want to delete this subscription?`,
                                                            buttonText: 'Yes, delete this subscription',
                                                            title: 'Delete this subscription?',
                                                        })
                                                    }
                                                />
                                            </td>
                                        </UserHasPermission>
                                    </tr>
                                ))}

                            {loading && <LoaderRow cols={userIsAllowedTo('changeEnvSettings') ? 3 : 2} />}
                        </tbody>
                    </table>
                )}
                {!loading && (!subscriptions || subscriptions.length === 0) && (
                    <div className="pt4">There are no subscriptions.</div>
                )}
            </div>
        </SettingsLayout>
    );
};
export default SettingsAnomalyDetection;
