import { FC, useState } from 'react';
import Icon from 'components/icons/Icon';

/**
 * @todo In ng-app, the popover allows clicking the icon, as well as positioning the icon. Currently
 * this component works on hover only, and positions the popover below the icon
 */
const Popover: FC<{ icon: string; iconStyle?: string; className?: string; children?: React.ReactNode }> = ({
    icon,
    iconStyle,
    children,
    className = '',
}) => {
    const [state, setState] = useState({
        isVisible: false,
    });

    const setVisible = (isVisible = true) => {
        setTimeout(() => setState(currentState => ({ ...currentState, isVisible })), 200);
    };

    return (
        <div onMouseEnter={() => setVisible()} onMouseLeave={() => setVisible(false)} className={className}>
            <Icon icon={icon} className={iconStyle || ''} />
            {state.isVisible && (
                <div className="vc-popover bg-dark rounded white absolute z4 p2" data-testid="popover-message">
                    <div className="vc-popover-inner">{children}</div>
                </div>
            )}
        </div>
    );
};

export default Popover;
