import type { DiagramEdge, DiagramEdgeInput, DiagramLayoutFunction, DiagramNode } from '../types';
import { ViewBox } from '../../../helpers/diagram';

export interface TreeLayoutOptions {
    horizontalSpacing: number;
    nodeHeight: number;
    nodeWidth: number;
    verticalSpacing: number;
}

interface Position {
    x: number;
    y: number;
}

const TreeValidationErrorCodes = Object.freeze({
    INVALID_TREE_STRUCTURE: Symbol('INVALID_TREE_STRUCTURE'),
    MULTIPLE_ROOT_NODES: Symbol('MULTIPLE_ROOT_NODES'),
    NO_NODES: Symbol('NO_NODES'),
    NO_ROOT_NODE: Symbol('NO_ROOT_NODE'),
});

type TreeValidationErrorCode = (typeof TreeValidationErrorCodes)[keyof typeof TreeValidationErrorCodes];

export class TreeValidationError extends Error {
    readonly #code: TreeValidationErrorCode;

    constructor(code: TreeValidationErrorCode, message?: string) {
        super(message);
        this.#code = code;
    }

    get code() {
        return this.#code;
    }

    get name() {
        return 'TreeValidationError';
    }

    static get INVALID_TREE_STRUCTURE() {
        return TreeValidationErrorCodes.INVALID_TREE_STRUCTURE;
    }

    static get MULTIPLE_ROOT_NODES() {
        return TreeValidationErrorCodes.MULTIPLE_ROOT_NODES;
    }

    static get NO_NODES() {
        return TreeValidationErrorCodes.NO_NODES;
    }

    static get NO_ROOT_NODE() {
        return TreeValidationErrorCodes.NO_ROOT_NODE;
    }
}

function getRootNode<TNode, TEdge>(
    nodes: ReadonlyArray<TNode>,
    edges: ReadonlyArray<DiagramEdgeInput<TNode, TEdge>>
): TNode {
    const targetNodes = new Set(edges.map(edge => edge.target));
    const roots = nodes.filter(node => !targetNodes.has(node));

    if (roots.length === 0) {
        throw new TreeValidationError(
            TreeValidationError.NO_ROOT_NODE,
            `Tree layout requires a single root node, but one was not found.`
        );
    } else if (roots.length > 1) {
        throw new TreeValidationError(
            TreeValidationError.MULTIPLE_ROOT_NODES,
            `Tree layout only supports one root node. Found ${roots.length}`
        );
    } else {
        return roots[0];
    }
}

export class DefaultTree<TNode, TEdge> {
    readonly #tree: Tree<TNode, TEdge>;
    readonly #options: TreeLayoutOptions;

    constructor(tree: Tree<TNode, TEdge>, options: TreeLayoutOptions) {
        this.#tree = tree;
        this.#options = options;
    }

    /**
     * Returns the root node position (center point).
     */
    private get rootPosition(): Position {
        return {
            x: this.#options.nodeWidth / 2,
            y: this.#options.nodeHeight / 2,
        };
    }

    /**
     * Yields start and end positions of edges between node center points.
     * @param tree The tree/subtree to evaluate positions for.
     * @param offset Position of the tree root node.
     */
    private *getEdgePositions(
        tree: Tree<TNode, TEdge>,
        offset: Position = this.rootPosition
    ): Generator<DiagramEdge<TNode, TEdge>, number[], void> {
        const { x: x1, y: y1 } = offset;
        const ySpacing = this.#options.nodeHeight + this.#options.verticalSpacing;
        const x2 = x1 + this.#options.horizontalSpacing + this.#options.nodeWidth;
        let depthOffsets: number[] = [y1];

        for (const [{ edge, source, target }, subtree] of tree.children) {
            const y2 = Math.max(...depthOffsets.slice(0, subtree.depth));

            yield { edge, source, target, x1, x2, y1, y2 };

            const subtreeDepths = yield* this.getEdgePositions(subtree, {
                x: x2,
                y: y2,
            });

            const prevDepthOffsets = depthOffsets;
            depthOffsets = Array.from({ length: Math.max(depthOffsets.length, subtree.depth) }, (_, i) => {
                const prevOffsetForDepth = prevDepthOffsets[i] ?? 0;
                const nextOffset = y2 + ySpacing;
                const subtreeOffset = subtreeDepths[i - 1] ?? 0;
                return Math.max(prevOffsetForDepth, nextOffset, subtreeOffset);
            });
        }
        return depthOffsets;
    }

    /**
     * Yields start and end positions of edges between node center points.
     * @param offset Position of tree root node.
     */
    *getAllEdges(offset?: Position): Iterable<DiagramEdge<TNode, TEdge>> {
        yield* this.getEdgePositions(this.#tree, offset);
    }

    /**
     * Yields positions of all nodes of the tree. Node coordinates are center points.
     * @param offset Position of tree root node.
     */
    *getAllNodes(offset: Position = this.rootPosition): Iterable<DiagramNode<TNode>> {
        yield { node: this.#tree.node, x: offset.x, y: offset.y };
        for (const { target, x2: x, y2: y } of this.getEdgePositions(this.#tree, offset)) {
            yield {
                node: target,
                x,
                y,
            };
        }
    }

    /**
     * Returns the default view box displaying the full diagram with no padding.
     */
    getViewBox(): ViewBox {
        let width = 0;
        let height = 0;
        for (const node of this.getAllNodes()) {
            // x and y are center points, so pad by half the size
            width = Math.max(width, node.x + this.#options.nodeWidth / 2);
            height = Math.max(height, node.y + this.#options.nodeHeight / 2);
        }
        return new ViewBox(0, 0, width, height);
    }
}

class Tree<TNode, TEdge> {
    readonly #children: ReadonlyArray<[DiagramEdgeInput<TNode, TEdge>, Tree<TNode, TEdge>]>;
    readonly #node: TNode;

    get depth(): number {
        return 1 + Math.max(0, ...this.#children.map(([_edge, subtree]) => subtree.depth));
    }

    get children() {
        return this.#children;
    }

    get node() {
        return this.#node;
    }

    constructor(root: TNode, edgesBySource: ReadonlyMap<TNode, ReadonlyArray<DiagramEdgeInput<TNode, TEdge>>>) {
        this.#node = root;
        this.#children = (edgesBySource.get(root) ?? []).map(edge => [edge, new Tree(edge.target, edgesBySource)]);
    }
}

function makeEdgesBySource<TNode, TEdge>(
    edges: ReadonlyArray<DiagramEdgeInput<TNode, TEdge>>
): ReadonlyMap<TNode, ReadonlyArray<DiagramEdgeInput<TNode, TEdge>>> {
    const map = new Map<TNode, Array<DiagramEdgeInput<TNode, TEdge>>>();
    for (const edge of edges) {
        const value = map.get(edge.source) ?? [];
        value.push(edge);
        map.set(edge.source, value);
    }
    return map;
}

export function makeTreeLayout<TNode, TEdge>(options: TreeLayoutOptions): DiagramLayoutFunction<TNode, TEdge> {
    return (nodes, edges) => {
        if (nodes.length === 0) {
            throw new TreeValidationError(
                TreeValidationError.NO_NODES,
                'Tree layout requires at least one node but none were supplied.'
            );
        }
        const root = getRootNode(nodes, edges);
        const edgesBySource = makeEdgesBySource(edges);
        const baseTree = new Tree(root, edgesBySource);
        const rootTree = new DefaultTree<TNode, TEdge>(baseTree, options);
        const result = {
            edges: Array.from(rootTree.getAllEdges()),
            nodes: Array.from(rootTree.getAllNodes()),
            viewBox: rootTree.getViewBox(),
        };
        if (nodes.length !== result.nodes.length || edges.length !== result.edges.length) {
            throw new TreeValidationError(
                TreeValidationError.INVALID_TREE_STRUCTURE,
                'The input nodes and edges form an invalid tree. Check that your tree contains no cycles and all nodes are connected.'
            );
        }
        return result;
    };
}
