import { FC, useState } from 'react';
import UsersList from 'components/lists/UsersList';
import UserMultiSelect from './MultiSelect';
import ExpandableList from 'components/lists/ExpandableList';
import BouncerButton from 'components/form/BouncerButton';
import Modal from 'components/modals/Modal';
import User from 'models/User';
import Team from 'models/Team';
import Role from 'models/Role';
import { changeRole, rename, remove, updateUsers } from 'services/api/teams';
import useEnvs from 'hooks/useEnvs';
import useDisplayMessage from 'hooks/useDisplayMessage';
import Icon from 'components/icons/Icon';
import Dropdown from 'components/dropdowns/Dropdown';
import { UserHasPermission, UserHasNoPermission } from 'components/security/UserHasPermission';
import { useFinalActionModal } from 'components/context/FinalActionModal';

const OrgTeam: FC<{
    team: Team;
    roles: Role[];
    users: User[];
    canInviteUsers: boolean;
    onRename?: (team: Team) => void;
    onDelete?: (team: Team) => void;
    usingSAMLGroupProvisioning?: boolean;
}> = ({ team, roles, users, canInviteUsers, onRename, onDelete, usingSAMLGroupProvisioning }) => {
    const [teamRole, setTeamRole] = useState<Role>(team.role);
    const [state, setState] = useState<{
        nameInputValue: string;
        renameInProgress: boolean;
        showRenameForm: boolean;
        roleDropdownExpanded: boolean;
        actionsExpanded: boolean;
    }>({
        nameInputValue: team.name,
        renameInProgress: false,
        showRenameForm: false,
        roleDropdownExpanded: false,
        actionsExpanded: false,
    });
    const [modalVisible, displayModal] = useState(false);

    const showModal = () => {
        displayModal(true);
        setState({ ...state, actionsExpanded: false });
    };
    const hideModal = () => displayModal(false);

    const { openModal } = useFinalActionModal();

    const envs = useEnvs();
    const { error } = useDisplayMessage();
    const teamEnvs = team.envs.map(env => envs.find(e => e.id === env.id));

    const canEditTeam =
        canInviteUsers && !usingSAMLGroupProvisioning && team.type !== 'everyone' && team.type !== 'owners';

    const handleDeleteClick = () => {
        setState({ ...state, actionsExpanded: false });
        openModal({
            onSubmit: handleDelete,
            confirmMsg: `Are you sure you want to remove team: ${team.name}?`,
            buttonText: 'Yes, delete this team.',
            title: `Confirmation to remove team`,
        });
    };

    const handleDelete = async () => {
        try {
            await remove(team);
            if (onDelete) {
                onDelete(team);
            }
        } catch {
            error(`There was an error removing team ${team.name}`);
        }
    };

    const handleActionToggle = (expanded: boolean) => {
        setState({ ...state, actionsExpanded: expanded });
    };

    const handleToggle = (expanded: boolean) => {
        setState({ ...state, roleDropdownExpanded: expanded });
    };

    const handleRename = async () => {
        setState({ ...state, renameInProgress: true });

        try {
            await rename(team, state.nameInputValue);
            if (onRename) {
                onRename(new Team({ ...team, name: state.nameInputValue }));
            }
        } catch {
            error(`There was an error while trying to rename team: ${team.name}`);
        } finally {
            setState({ ...state, renameInProgress: false, showRenameForm: false });
        }
    };

    const handleRoleSelection = async (role: Role) => {
        setState({ ...state, roleDropdownExpanded: false });
        try {
            await changeRole(team, role);
            setTeamRole(role);
        } catch {
            error(`There was an error while trying to update ${team.name}'s role`);
        }
    };

    const handleUpdateUsers = async (usersToAdd: User[], usersToRemove: User[]) => {
        try {
            await updateUsers(team, usersToAdd, usersToRemove);
        } catch {
            error(`There was an error while updating ${team.name}'s users`);
        }
    };

    return (
        <>
            <div className="bg-grey0 p3 mb3 rounded relative settings__section-content__team" data-testid="team">
                {state.showRenameForm && (
                    <div>
                        <input
                            value={state.nameInputValue}
                            onChange={e => setState({ ...state, nameInputValue: e.currentTarget.value })}
                            placeholder="Team Name"
                            type="text"
                            className="mr3"
                            data-testid="rename-input"
                        />
                        <button
                            type="button"
                            className="primary-grey mr2"
                            onClick={() => setState({ ...state, showRenameForm: false })}
                        >
                            Cancel
                        </button>
                        <BouncerButton
                            bounce={state.renameInProgress}
                            onClick={handleRename}
                            className="primary-orange"
                            disabled={state.renameInProgress}
                        >
                            Set Name
                        </BouncerButton>
                    </div>
                )}
                {!state.showRenameForm && <span data-testid="team-name">{team.name}</span>}
                {canEditTeam && (
                    <div className="view-menu flex absolute right-3 top-2">
                        <Dropdown
                            className="dropdown--option-included"
                            icon="more_horiz"
                            onToggle={handleActionToggle}
                            isExpanded={state.actionsExpanded}
                        >
                            <div className="view-menu__content absolute bg-dark white rounded right-0 top-0 shadow">
                                <ul className="list-reset pt3 pb2">
                                    <li>
                                        <span
                                            onClick={showModal}
                                            className="view-menu__content__list__item flex items-center justify-end bold fz10 nowrap py1 pl3 pr2 uppercase white cursor-hand"
                                        >
                                            Edit
                                            <Icon icon="edit_people" className="inline-flex white fz16 ml2" />
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={() =>
                                                setState({
                                                    ...state,
                                                    actionsExpanded: false,
                                                    nameInputValue: team.name,
                                                    showRenameForm: true,
                                                })
                                            }
                                            data-testid="rename-dropdown-item"
                                            className="view-menu__content__list__item flex items-center justify-end bold fz10 nowrap py1 pl3 pr2 uppercase white cursor-hand"
                                        >
                                            Rename
                                            <Icon icon="edit" className="inline-flex white fz16 ml2" />
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            onClick={handleDeleteClick}
                                            data-testid="delete-dropdown-item"
                                            className="view-menu__content__list__item flex items-center justify-end bold fz10 nowrap py1 pl3 pr2 uppercase white cursor-hand"
                                        >
                                            Delete
                                            <Icon icon="trash" className="inline-flex white fz16 ml2" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </Dropdown>
                    </div>
                )}
                <div className="mt2 pl2">
                    <div className="flex items-center settings__section-content__team__row">
                        <span className="uppercase mr2 fz10  monospace nowrap spacing settings__section-content__team__row__label">
                            Role
                        </span>
                        <div>
                            <UserHasNoPermission to="changeTeamRole">
                                <span className="px2" data-testid="team-role-name">
                                    {team.role.name}
                                </span>
                            </UserHasNoPermission>
                            <UserHasPermission to="changeTeamRole">
                                <span className="mainColor" data-testid="team-role-name-dropdown">
                                    <Dropdown
                                        className="flex settings__section-content__team__row__role-dropdown"
                                        text={teamRole.name}
                                        isExpanded={state.roleDropdownExpanded}
                                        onToggle={handleToggle}
                                    >
                                        <div data-testid="dropdown-content">
                                            <ul className="add-to-team-list list-reset overflow-auto py2">
                                                {roles.map(
                                                    role =>
                                                        role.name !== teamRole.name && (
                                                            <li
                                                                key={role.id}
                                                                className="add-to-team-list__item p2 cursor-hand mainColor nowrap"
                                                                onClick={() => handleRoleSelection(role)}
                                                                data-testid="dropdown-role-value"
                                                            >
                                                                {role.name}
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        </div>
                                    </Dropdown>
                                </span>
                            </UserHasPermission>
                        </div>
                    </div>
                    <div className="flex items-center settings__section-content__team__row">
                        <span className="uppercase mr2 fz10  monospace nowrap spacing relative align__top--1 settings__section-content__team__row__label">
                            Environments
                        </span>
                        <ExpandableList items={teamEnvs.map(env => env?.name || '')}></ExpandableList>
                    </div>
                    <div className="flex items-center settings__section-content__team__row">
                        <span className="uppercase mr2 fz10  monospace nowrap spacing settings__section-content__team__row__label">
                            Team Members
                        </span>
                        <UsersList users={team.users as User[]} onExpandClick={showModal} />
                        {canInviteUsers && (
                            <div className="mx2" data-testid="org-team-edit">
                                <span
                                    className="uppercase bold spacing flex items-center mainColor cursor-hand"
                                    onClick={showModal}
                                >
                                    <Icon icon="edit_people" className="fz18 mr1 mainColor"></Icon>
                                    <span>Edit</span>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal visible={modalVisible} title="Add or Remove Members" onClose={hideModal}>
                <UserMultiSelect
                    items={users}
                    checkedItems={team.users as User[]}
                    onCancel={hideModal}
                    onSubmit={handleUpdateUsers}
                    onSuccess={hideModal}
                />
            </Modal>
        </>
    );
};

export default OrgTeam;
