import * as React from 'react';
import Gravatar, { GravatarSize } from 'components/icons/Gravatar';
import User from 'models/User';
import Popover from 'components/popover/Popover';

interface UsersListPropsInterface {
    users: User[];
    onExpandClick?: () => void;
    limit?: number;
}

const UsersList: React.FC<UsersListPropsInterface> = ({ users = [], onExpandClick, limit = 10 }) => {
    const numberOfUsersOverLimit = users.length - limit;
    const usersToShow = users.slice(0, limit); // Users where gravatars will be shown.

    const handleExpandClick = () => {
        if (onExpandClick) {
            onExpandClick();
        }
    };

    return (
        <div>
            <div className="flex flex-wrap">
                {usersToShow.map(user => {
                    return (
                        <div className="relative" key={user.id}>
                            <div className="absolute member__icon">
                                <Popover icon="account_circle" direction="top">
                                    <div className="flex items-center p1">
                                        <Gravatar
                                            email={user.email}
                                            name={user.name}
                                            className="mr3"
                                            size={GravatarSize.MEDIUM}
                                        ></Gravatar>
                                        <div className="flex flex-column">
                                            <span className="bold mb1">{user.name}</span>
                                            <span className="hotelExecutive fz12">{user.email}</span>
                                        </div>
                                    </div>
                                </Popover>
                            </div>
                            <Gravatar
                                email={user.email}
                                name={user.name}
                                className="m1"
                                size={GravatarSize.SMALL}
                            ></Gravatar>
                        </div>
                    );
                })}
                {users.length > limit && (
                    <div
                        className="member__count gravatar--small dark nunito bold fz12 m1"
                        onClick={handleExpandClick}
                        title={`+${numberOfUsersOverLimit} more`}
                        data-testid="user-list-show-more"
                    >
                        +{numberOfUsersOverLimit}
                    </div>
                )}
            </div>
        </div>
    );
};

export default UsersList;
