import { OrgURLInterface } from 'models/URL';
import Authentication from 'models/Authentication';
import Icon from 'components/icons/Icon';
import LoginMethodLink from './LoginMethodLink';
import * as React from 'react';

interface LoginMethodsPropsInterface {
    auth: Authentication;
    urls: OrgURLInterface;
}

const LoginMethods: React.FC<LoginMethodsPropsInterface> = ({ auth, urls }) => (
    <>
        {auth.isMethodSupported(Authentication.OAUTH_METHOD, Authentication.GOOGLE_PROVIDER) && urls.oauth && (
            <LoginMethodLink
                url={urls.oauth.google}
                icon={<Icon icon="google-color" className="h2 inline-flex mx2" />}
                className="oauth-sign-in"
            >
                Sign in with Google
            </LoginMethodLink>
        )}

        {auth.isMethodSupported(Authentication.OAUTH_METHOD, Authentication.GITHUB_PROVIDER) && urls.oauth && (
            <LoginMethodLink
                url={urls.oauth.github}
                icon={<Icon icon="github" className="h2 inline-flex mx2 dark" />}
                className="oauth-sign-in"
            >
                Sign in with Github
            </LoginMethodLink>
        )}

        {auth.isMethodSupported(Authentication.SAML_METHOD) && urls.auth && (
            <LoginMethodLink url={urls.auth.saml} className="saml-sign-in">
                Sign in via SAML
            </LoginMethodLink>
        )}

        {auth.supportsMultipleMethods() && <p className="google-divider__text">or</p>}
    </>
);

export default LoginMethods;
