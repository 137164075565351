import { useState, useLayoutEffect, RefObject } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const dueTime = 100; // milliseconds
const observable = fromEvent(window, 'resize').pipe(debounceTime(dueTime));

/**
 * A simple hook that returns the width and height of the window. Internally, it
 * listens to the resize event of the window, updating the dimensions when the
 * window is resized.
 */
export function useWindowDimensions() {
    const [width, setWidth] = useState<Window['innerWidth']>(() => window.innerWidth);
    const [height, setHeight] = useState<Window['innerHeight']>(() => window.innerHeight);

    useLayoutEffect(() => {
        const subscription = observable.subscribe(() => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        });

        return () => subscription.unsubscribe();
    }, []);

    return { width, height };
}

/**
 * A simple hook that returns the width and height of an element. Internally, it
 * uses the `useWindowDimensions` hook to update the dimensions.
 */
export function useElementDimensions<T extends HTMLElement>(
    elementRef: RefObject<
        Pick<T, 'offsetWidth' | 'offsetHeight' | 'offsetTop' | 'offsetLeft' | 'scrollHeight' | 'clientHeight'>
    >
) {
    const { width: windowsWidth, height: windowsHeight } = useWindowDimensions();

    const [offsetWidth, setWidth] = useState<T['offsetWidth']>(() => elementRef.current?.offsetWidth || 0);
    const [offsetHeight, setHeight] = useState<T['offsetHeight']>(() => elementRef.current?.offsetHeight || 0);
    const [offsetTop, setTop] = useState<T['offsetTop']>(() => elementRef.current?.offsetTop || 0);
    const [offsetLeft, setLeft] = useState<T['offsetLeft']>(() => elementRef.current?.offsetLeft || 0);
    const [scrollHeight, setScrollHeight] = useState<T['scrollHeight']>(() => elementRef.current?.scrollHeight || 0);
    const [clientHeight, setClientHeight] = useState<T['clientHeight']>(() => elementRef.current?.clientHeight || 0);

    useLayoutEffect(() => {
        if (!elementRef.current) {
            return;
        }

        setWidth(elementRef.current.offsetWidth);
        setHeight(elementRef.current.offsetHeight);
        setTop(elementRef.current.offsetTop);
        setLeft(elementRef.current.offsetLeft);
        setScrollHeight(elementRef.current.scrollHeight);
        setClientHeight(elementRef.current.clientHeight);
    }, [windowsWidth, windowsHeight, elementRef]);

    return { offsetWidth, offsetHeight, offsetTop, offsetLeft, scrollHeight, clientHeight };
}
