import { GlobalMessageTypes } from 'components/messages/GlobalMessage';
import { clearMessage, fadeMessage } from 'services/messages';

export interface GlobalMessageInterface {
    id: string;
    sourceId: string;
    message: string;
    dismissable: boolean;
    type: GlobalMessageTypes;
    fade: boolean;
}

export enum ActionType {
    PUSH = 'PUSH_GLOBAL_MESSAGE',
    DISMISS = 'DISMISS_GLOBAL_MESSAGE',
    FADEOUT = 'FADEOUT_GLOBAL_MESSAGE',
}

export interface StateInterface {
    messages: GlobalMessageInterface[];
}

export interface ActionInterface {
    type: ActionType;
    payload: GlobalMessageInterface | string;
}

export const getInitialState = () => ({ messages: [] });

export const actions = {
    push: (message: string, id: string, sourceId: string, type: GlobalMessageTypes, dismissable = true) => {
        return {
            type: ActionType.PUSH,
            payload: {
                id,
                sourceId,
                message,
                dismissable,
                type,
                fade: false,
            },
        };
    },
    dismiss: (id: string) => ({
        type: ActionType.DISMISS,
        payload: id,
    }),
    fadeout: (id: string) => ({
        type: ActionType.FADEOUT,
        payload: id,
    }),
};

export const reducer: React.Reducer<StateInterface, ActionInterface> = (state, action) => {
    switch (action.type) {
        case ActionType.PUSH: {
            const newState = { messages: [...state.messages] };

            // If the action type is PUSH, we will have a payload.
            newState.messages.push(action.payload as GlobalMessageInterface);
            return newState;
        }
        case ActionType.DISMISS: {
            return { messages: clearMessage(action.payload as string, state.messages) };
        }
        case ActionType.FADEOUT: {
            return { messages: fadeMessage(action.payload as string, state.messages) };
        }
        default:
            throw new Error(`[global-messages] unexpected action type ${action.type}`);
    }
};
