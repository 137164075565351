import Occurrence, { OccurrenceSeverityType } from './Occurrence';

export interface BySeverityInterface {
    info?: number;
    warn?: number;
    crit?: number;
}

export default class Category {
    failedAsserts = 0;
    failed = 0;
    passed = 0;
    muted = 0;
    name: string;
    critical = 0;
    warning = 0;
    info = 0;
    occurrences?: Occurrence[];
    expanded = false;
    errorExpanding = false;
    expanding = false;

    constructor(
        name: string,
        failedAsserts: number,
        failed: number,
        passed: number,
        suppressed: number,
        bySeverity: BySeverityInterface
    ) {
        this.name = name;

        this.update(failedAsserts, failed, passed, suppressed, bySeverity);
    }

    update(failedAsserts: number, failed: number, passed: number, suppressed: number, bySeverity: BySeverityInterface) {
        this.failedAsserts = failedAsserts;
        this.failed = failed;
        this.passed = passed;
        this.muted = suppressed;
        this.critical = bySeverity.crit ?? 0;
        this.warning = bySeverity.warn ?? 0;
        this.info = bySeverity.info ?? 0;
    }

    get severity() {
        if (this.muted > 0 && this.failed === 0 && this.passed === 0) {
            return 'muted';
        }

        if (this.passed > 0 && this.failed === 0 && this.muted === 0) {
            return 'passed';
        }

        if (this.critical > 0) {
            return 'critical';
        }

        if (this.warning > 0) {
            return 'warning';
        }

        if (this.info > 0) {
            return 'info';
        }

        return 'none';
    }

    /**
     * Comparison function to sort the occurrences.
     * Order: critical, info, warning, passed, muted
     */
    private compare(a: Occurrence, b: Occurrence) {
        const sortCriteria: OccurrenceSeverityType[] = ['critical', 'warning', 'info'];

        const aPrecedence = sortCriteria.indexOf(a.severity),
            bPrecedence = sortCriteria.indexOf(b.severity);

        if ((a.passed && b.passed) || (a.muted && b.muted) || a.severity === b.severity) {
            return 0;
        }

        if (a.passed) {
            return b.muted ? -1 : 1;
        }

        if (b.passed) {
            return a.muted ? 1 : -1;
        }

        if (a.muted) {
            return 1;
        }

        if (b.muted) {
            return -1;
        }

        return aPrecedence < bPrecedence ? -1 : 1;
    }

    setOccurrences(occurrences: Occurrence[]) {
        if (this.occurrences) {
            occurrences.forEach(occurrence => {
                const expandedOccurrence = this.occurrences?.find(o => o.expanded && o.id === occurrence.id);

                if (expandedOccurrence) {
                    occurrence.expanded = true;
                }
            });
        }

        this.occurrences = occurrences.sort(this.compare);
    }
}
