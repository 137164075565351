import { FC } from 'react';
import WidgetBox from 'components/layout/WidgetBox';
import ToggleableDigest from 'components/query/ToggleableDigest';

const DigestQuery: FC<{ digest: string }> = ({ digest }) => {
    return (
        <WidgetBox wholeRow>
            <h2 className="bold fz16 mb2">Digested Query</h2>
            <ToggleableDigest className="queries--digest">{digest}</ToggleableDigest>
        </WidgetBox>
    );
};

export default DigestQuery;
