import StatusBulb, { StatusBulbValues } from 'components/icons/StatusBulb';
import Host from 'models/hosts/Host';
import { useEffect, useState } from 'react';
import { getFeatures } from 'services/api/hosts';
import Logger from 'services/logger';

const logger = Logger.get('HostConnectionStatus');

const HostConnectionStatus: React.FC<{ host: Host }> = ({ host }) => {
    const [status, setStatus] = useState<StatusBulbValues | null>(null);

    useEffect(() => {
        if (host.isOs) {
            setStatus(StatusBulbValues.ok);
            return;
        }

        getFeatures()
            .then(data => {
                const connectionFeature = data
                    .filter(feature => feature.host === host.id)
                    .find(feature => feature.feature === 'DB Connection');

                if (!connectionFeature) {
                    // Status not available for this host
                    // Should we display an error status instead?
                    setStatus(StatusBulbValues.unknown);
                    return;
                }

                const hostStatus =
                    connectionFeature.state.toLowerCase() === 'ok' ? StatusBulbValues.ok : StatusBulbValues.error;

                setStatus(hostStatus);
            })
            .catch(e => {
                logger.error(e);
                setStatus(StatusBulbValues.unknown);
            });
    }, [host]);

    return status !== null ? <StatusBulb status={status} /> : null;
};
export default HostConnectionStatus;
