import { isEnabled } from 'services/serviceLevel/featureFlag';
import { FlaggedFeature } from 'services/serviceLevel/configuration';
import { FeatureContext } from 'components/context/FeatureToggler';
import * as React from 'react';

interface FeatureInterface {
    name?: FlaggedFeature;
    fallback?: React.ReactNode;
    children?: React.ReactNode;
}

const Feature: React.FC<FeatureInterface> = ({ name, children, fallback = null }) => {
    // This allows to wrap components that are conditionally under a feature flag
    // and bypass the check if no feature name is provided. E.g: the app navigation items.
    if (!name) {
        return <>{children}</>;
    }

    return <FeatureContext.Consumer>{() => (isEnabled(name) ? <>{children}</> : fallback)}</FeatureContext.Consumer>;
};

export default Feature;
