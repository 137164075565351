import UIStatus, { UIStatusCallbacks, UIStatusConfigInterface, UIStatusValueInterface } from './UIStatus';

let searchParams = new URLSearchParams(window.location.search);
let uiStatusInstances: UIStatus[] = [];

const buildStatus = (
    defaults: UIStatusValueInterface,
    config: UIStatusConfigInterface,
    callbacks: UIStatusCallbacks
) => {
    const uiStatus = new UIStatus(defaults, config, callbacks, searchParams);

    // if a new UIStatus instance with an existing ID was created, we replace it by discarding the old one
    uiStatusInstances = uiStatusInstances.filter(instance => instance.id !== uiStatus.id);

    uiStatusInstances.push(uiStatus);

    return uiStatus;
};

const handlePageChange = (params: URLSearchParams) => {
    searchParams = params;
    uiStatusInstances = uiStatusInstances.filter(uiStatus => !uiStatus.clearOnRouteChange);
    uiStatusInstances.forEach(uiStatus => {
        uiStatus.searchParams = params;
    });
};

const getStatusInstance = (statusID: string) => {
    return uiStatusInstances.find(uiStatus => uiStatus.id === statusID);
};

export { buildStatus, handlePageChange, getStatusInstance };
