import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';
import { FC, useEffect, useState } from 'react';
import { Link as RouterLink, NavLink as RouterNavLink, LinkProps, NavLinkProps } from 'react-router-dom';
import { envPath } from 'services/routes';

export interface LinkInterface extends LinkProps {
    propagateStatus?: boolean;
    external?: boolean;
    to: string;
    appendEnv?: boolean;
}

export interface NavLinkInterface extends NavLinkProps {
    propagateStatus?: boolean;
    to: string;
}

const usePropagateStatus = (href: string, propagate: boolean, external: boolean) => {
    const { interval } = useTimeInterval();
    const { filter } = useHostFilter();

    const getURLWithStatus = () => {
        const url = new URL(href, window.origin);
        const params = url.searchParams;

        if (!params.get('hosts')) {
            params.set('hosts', filter);
        }

        if (!params.get('from')) {
            params.set('from', interval.from.toString());
        }

        if (!params.get('until')) {
            params.set('until', interval.until.toString());
        }

        return external ? url.toString() : url.pathname + url.search;
    };

    const [url, setUrl] = useState(href);

    useEffect(() => {
        setUrl(() => (propagate ? getURLWithStatus() : href));
    }, [href, propagate, interval, filter, external]); // eslint-disable-line

    return url;
};

export const Link: FC<LinkInterface> = ({
    to,
    propagateStatus = true,
    external = false,
    appendEnv = true,
    children,
    ...rest
}) => {
    if (appendEnv) {
        to = envPath(to);
    }

    const url = usePropagateStatus(to, propagateStatus, external);

    return !external ? (
        <RouterLink to={url} {...rest}>
            {children}
        </RouterLink>
    ) : (
        <a href={url} {...rest}>
            {children}
        </a>
    );
};

export const NavLink: FC<NavLinkInterface> = ({ to, propagateStatus = true, children, ...rest }) => {
    const url = usePropagateStatus(to, propagateStatus, false);

    return (
        <RouterNavLink to={url} {...rest}>
            {children}
        </RouterNavLink>
    );
};
