import cn from 'helpers/classname';
import debouncePromise from 'awesome-debounce-promise';
import ContextualHelp from 'components/documentation/ContextualHelp';
import Host from 'models/hosts/Host';
import HostsTagList from 'components/host/HostsTagList';
import Icon from 'components/icons/Icon';
import Suggestions from 'components/host/HostsSelectorSuggestions';
import TimeInterval from 'models/TimeInterval';
import { byName as sortByName } from 'helpers/sort';
import { FC, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { expressionIsAdvancedSyntax, filterHosts, FILTER_MODE, HostTotalsInterface } from 'services/api/hosts';
import APIError from 'services/api/error/Error';
import Feature from '../serviceLevel/Feature';
import { useHostFilterSuggestions } from 'hooks/useHostFilterSuggestions';

const emptyCount = { registered: 0, active: -1, matching: -1, returned: 0 };

interface TagAutocompleteInterface {
    suggestion: string;
    replace: string;
}

interface HostFilterPropsInterface {
    commit: (arg0: string) => void;
    clear: () => void;
    expand: () => void;
    expanded: boolean;
    filter: string;
    mode: FILTER_MODE;
    onValueChange: (arg0: string) => void;
    setMode?: (arg0: FILTER_MODE) => void;
    timeInterval: TimeInterval;
    value: string;
    inputPlaceholder?: string;
    children?: React.ReactNode;
    savedSyntaxQueries: string;
    onRemovePill: (arg0: string) => void;
}

const debouncedFilter = debouncePromise(filterHosts, 200);

const HostsFilter: FC<HostFilterPropsInterface> = ({
    children,
    clear,
    commit,
    expand,
    expanded,
    filter,
    inputPlaceholder = '(Click to filter)',
    timeInterval,
    mode,
    onValueChange,
    setMode,
    value,
    savedSyntaxQueries,
    onRemovePill,
}) => {
    const isBasicMode = mode === FILTER_MODE.basic;
    const isAdvancedMode = !isBasicMode;

    const [tagSuggestions, setTagSuggestions] = useState<TagAutocompleteInterface[]>([]);
    const [error, setError] = useState(false);
    const [count, setCount] = useState<HostTotalsInterface>(emptyCount);
    const tagContainerRef = useRef(null);

    const pills = !savedSyntaxQueries ? [] : savedSyntaxQueries.split(',');
    const {
        suggestions,
        setSuggestions,
        clearSuggestions,
        selected,
        setSelected,
        select,
        unselectLast,
        clearSelection,
        activeSuggestion,
        activatePreviousSuggestion,
        activateNextSuggestion,
        unselectedSuggestions,
    } = useHostFilterSuggestions([]);

    // Change suggestions based on the current filter value.
    useEffect(() => {
        if (!expanded) {
            clearSuggestions();
            return;
        }

        setError(false);
        setTagSuggestions([]);

        debouncedFilter(timeInterval, value, { searchByName: !isAdvancedMode })
            .then(result => {
                setSuggestions(sortByName<Host>(result.hosts));
                setCount(result.count);
            })
            .catch((apiError: APIError<{ suggestion?: { tags?: TagAutocompleteInterface[] } }>) => {
                const tags = apiError.response.data.suggestion?.tags || [];
                if (0 < tags.length) {
                    setTagSuggestions(tags);
                }

                clearSuggestions();
                setError(true);
            });
    }, [value, expanded, timeInterval, isAdvancedMode, setSuggestions, clearSuggestions]);

    // Initialize and restore selected hosts.
    useEffect(() => {
        filterHosts(timeInterval, filter)
            .then(result => {
                if (filter !== '' && !isAdvancedMode) {
                    setSelected(sortByName(result.hosts));
                } else {
                    clearSelection();
                }

                setCount(result.count);
            })
            .catch(() => {
                clearSelection();
                setCount(emptyCount);
            });
    }, [isAdvancedMode, timeInterval, filter, expanded, setSelected, clearSelection]);

    function autocompleteTag(tag: TagAutocompleteInterface): void {
        onValueChange(value + tag.replace);
    }

    function removeSelectedHost(host: Host) {
        const newSelected = selected.filter(selectedHost => selectedHost !== host);
        setSelected(newSelected);

        if (!expanded) {
            commit(newSelected.map(({ id }) => id).join(','));
        }
    }

    function selectSuggestion(suggestion: Host) {
        if (selected.includes(suggestion)) {
            return;
        }

        setSelected(sortByName([...selected, suggestion]));
    }

    function onClearFilter(e: SyntheticEvent) {
        e.stopPropagation();

        clearSelection();
        clear();
    }

    function handleBasicInputKeyPress(e: KeyboardEvent) {
        switch (e.key) {
            case 'ArrowDown':
                return activateNextSuggestion();
            case 'ArrowUp':
                return activatePreviousSuggestion();
            case 'Enter':
                if (undefined === activeSuggestion && !!value && suggestions.length > 0) {
                    selectedAllSuggestions();
                    e.preventDefault();
                    return;
                }

                if (undefined === activeSuggestion) {
                    return handleSubmit(e);
                }

                e.preventDefault();
                select(activeSuggestion);
                return;
            case 'Backspace':
                if (value) {
                    return;
                }

                return unselectLast();
            case 'Escape':
                clearSelection();
                commit(filter);
                return;
            default:
                break;
        }
    }

    function handleAdvancedInputKeyPress(e: KeyboardEvent) {
        switch (e.key) {
            case 'Enter':
                handleSubmit(e);
                return;
            case 'Escape':
                commit(filter);
                return;
        }
    }

    function handleSubmit(e: SyntheticEvent) {
        e.preventDefault();

        commit(isBasicMode ? selected.map(({ id }) => id).join(',') : value);
    }

    function selectedAllSuggestions() {
        setSelected(sortByName([...selected, ...suggestions]));
    }

    function handlePillClick(e: SyntheticEvent, syntax: string) {
        e.preventDefault();
        onValueChange(syntax);
        commit(syntax);
    }

    function handleRemovePill(e: SyntheticEvent | undefined, syntax: string) {
        e?.stopPropagation();

        onRemovePill(syntax);
    }

    const filterActive = filter !== '';

    return (
        <form onSubmit={handleSubmit} className="flex-grow-1">
            <div
                className={`host-filter__input full-height relative z1 overflow-auto ${cn(
                    'host-filter__input--filter-active',
                    filterActive
                )}`}
            >
                <div
                    className="host-filter__input__tag-list bg-MoonCrater relative flex full-width full-height"
                    onMouseDown={expand}
                >
                    <ul className="list-reset flex flex-wrap flex-grow-1 items-start" ref={tagContainerRef}>
                        {isBasicMode && (
                            <HostsTagList
                                container={tagContainerRef}
                                expanded={expanded}
                                hosts={selected}
                                remove={removeSelectedHost}
                            />
                        )}
                        <li className="host-filter__input__tag-list__input-item flex-auto order-2">
                            {!expanded && !filterActive && (
                                <span className="inline-flex items-center ml3 mr2 white pointer-events nowrap">
                                    Showing All Hosts
                                </span>
                            )}
                            <input
                                value={value}
                                onChange={e => onValueChange(e.currentTarget.value)}
                                onKeyDown={isAdvancedMode ? handleAdvancedInputKeyPress : handleBasicInputKeyPress}
                                className={`flex-grow-1 no-border full-height bg-MoonCrater ${cn('red', error)}`}
                                type="text"
                                data-testid="hosts-selector-input"
                                placeholder={expanded || filterActive ? '' : inputPlaceholder}
                            />
                        </li>
                    </ul>
                    {(expanded || filterActive) && (
                        <div
                            className={`host-filter__input__host-count ${cn(
                                'host-filter__input__host-count--filter-active',
                                filterActive
                            )}`}
                            data-testid="hosts-selector-count"
                        >
                            {`${count.matching >= 0 ? selected.length || count.matching : '■'}/${
                                count.active >= 0 ? count.active : '■'
                            }`}
                        </div>
                    )}
                    {filterActive && (
                        <button
                            className="host-filter__input__clear-selection"
                            onClick={onClearFilter}
                            data-testid="hosts-selector-clear"
                            type="button"
                        >
                            <Icon icon="close" className="flex dark fz18" />
                        </button>
                    )}
                </div>
            </div>
            {expanded && (
                <div className={`host-filter__results ${cn('host-filter__results--advanced-mode', isAdvancedMode)}`}>
                    <>
                        {setMode && isBasicMode && expressionIsAdvancedSyntax(value) && (
                            <div className="px3 py2 flex items-center">
                                <Icon icon="info-outline" className="fz12 mr1 grey3 relative align__top--1" />
                                <p className="fz10 line-height-120">
                                    If you are looking for a specific type or tag you can{' '}
                                    <button
                                        onClick={() => setMode(FILTER_MODE.advanced)}
                                        className="bold"
                                        data-testid="hosts-selector-advanced-suggestion"
                                    >
                                        use the advanced filter
                                    </button>{' '}
                                    for more flexibility.
                                </p>
                            </div>
                        )}

                        {isAdvancedMode && (
                            <div className="pt3 px3 fz14">
                                <h1 className="mb2">Recently used:</h1>
                                <ul className="list-reset">
                                    {pills.map((syntax, index) => (
                                        <li
                                            key={index}
                                            onClick={e => handlePillClick(e, syntax)}
                                            className="inline-flex items-center my1 mr2 border rounded p1 border-color-grey2 monospace cursor-hand truncate"
                                            data-testid="query-syntax-pill"
                                        >
                                            <p className="grey3 truncate" title={syntax}>
                                                {syntax}
                                            </p>
                                            <Icon
                                                icon="close"
                                                onClick={e => handleRemovePill(e, syntax)}
                                                className="ml2 mainColor"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {isAdvancedMode && value === '' && (
                            <div className="host-filter__results--advanced-mode__help">
                                <table
                                    className="p2 host-filter__results--advanced-mode__help__table"
                                    aria-describedby="help-screen-title"
                                >
                                    <caption className="bold px2 pb2 pt1 left-align" id="help-screen-title">
                                        Most common syntax queries:
                                    </caption>
                                    <thead className="grey3">
                                        <tr>
                                            <th scope="col">Filter by</th>
                                            <th scope="col">Syntax</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>host type</td>
                                            <td>type=os, type=mysql, type=mongo</td>
                                        </tr>
                                        <tr>
                                            <td>tag</td>
                                            <td>#</td>
                                        </tr>
                                        <tr>
                                            <td>tag expressions</td>
                                            <td>tags[&ldquo;tag_name&rdquo;]=value</td>
                                        </tr>
                                        <tr>
                                            <td>logical operators</td>
                                            <td>AND, OR, NOT</td>
                                        </tr>
                                        <tr>
                                            <td>multiple host ids</td>
                                            <td>hostid1, hostid2</td>
                                        </tr>
                                        <tr>
                                            <td>regular expression</td>
                                            <td>Google Go regexp syntax</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {suggestions.length > 0 && isAdvancedMode && (
                            <p className="host-filter__results--advanced-mode__results-title">
                                <b>Results Preview</b>{' '}
                                <span className="grey3">(The results shown are based on the selected timeframe)</span>
                            </p>
                        )}
                        {unselectedSuggestions.length > 0 && (
                            <Suggestions
                                suggestions={unselectedSuggestions}
                                filter={value}
                                onClick={suggestion => selectSuggestion(suggestion)}
                                isBasicMode={isBasicMode}
                                highlight={activeSuggestion}
                            />
                        )}
                        {suggestions.length === 0 && value !== '' && !error && (
                            <div className="host-filter__results__no-results">No results</div>
                        )}
                        {error && value !== '' && (
                            <div data-testid="hosts-selector-error" className="uppercase grey3 m3">
                                SYNTAX ERROR
                            </div>
                        )}
                    </>
                    <div className="host-filter__results__help bg-white flex justify-between items-center px3 py2 full-width">
                        <div className="flex justify-between full-width mr1">
                            <ContextualHelp id="hosts-filter">Learn more about host selection</ContextualHelp>
                            {isBasicMode && (
                                <button className="link flex" onClick={selectedAllSuggestions}>
                                    Select All
                                </button>
                            )}
                        </div>

                        {children}
                    </div>
                </div>
            )}

            {expanded && isAdvancedMode && tagSuggestions.length > 0 && (
                <Feature name="hostsSelectorTagAutocomplete">
                    <div className="host-filter__results__autocomplete absolute bg-grey05 overflow-hidden rounded-bottom rounded-right border--dropdown">
                        <ul className="list-reset">
                            <li className="host-filter__results__autocomplete__title bold bold mb2">Tag Suggestions</li>

                            {tagSuggestions.map(tag => (
                                <li key={tag.suggestion} data-testid="host-selector-tag-suggestion">
                                    <button
                                        className="host-filter__results__autocomplete__suggestion"
                                        onClick={event => {
                                            autocompleteTag(tag);
                                            event.preventDefault();
                                        }}
                                    >
                                        {tag.suggestion}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Feature>
            )}
        </form>
    );
};

export default HostsFilter;
