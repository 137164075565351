import * as React from 'react';
import Checkbox from '../Checkbox';
import { FieldRenderProps } from 'react-final-form';

export interface CheckboxFieldPropsInterface extends FieldRenderProps<string, HTMLInputElement> {
    helpText?: string;
    className?: string;
    type?: string;
    label: string;
    id: string;
}

const CheckboxField: React.FC<CheckboxFieldPropsInterface> = ({
    className,
    input,
    meta: { touched, error },
    helpText,
    type = 'text',
    label,
    id,
    ...rest
}) => {
    const extraClassName = touched && error ? ' border border-color-red' : '';

    return (
        <Checkbox
            className={`${className}${extraClassName}`}
            type={type}
            helpText={helpText}
            error={touched ? error : ''}
            label={label}
            id={id}
            {...input}
            {...rest}
        />
    );
};

export default CheckboxField;
