import { FC, default as React } from 'react';
import { Link } from 'components/url/Link';

interface DetailsPageHeaderInterface {
    title: string;
    id: string;
    linkTo: string;
    linkText: string;
    rightContent?: React.ReactNode;
}

const DetailsPageHeader: FC<DetailsPageHeaderInterface> = ({ title, id, linkTo, linkText, rightContent }) => {
    return (
        <div data-testid={`${title}-header`} className="flex justify-between px3 pt3">
            <div className="flex flex-column">
                <Link className="flex mb2" to={linkTo}>
                    {`${linkText}`}
                </Link>
                <div className="flex items-baseline">
                    <span className="h1 lighter">{title}</span>
                    <span className="fz12 grey-3 ml2">{id}</span>
                </div>
            </div>
            {rightContent}
        </div>
    );
};

export default DetailsPageHeader;
