import Icon from 'components/icons/Icon';
import { FC, useState, useEffect } from 'react';

export enum AlertTypes {
    INFO = 'info',
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
}

interface AlertPropsInterface {
    visible?: boolean;
    sticky?: boolean;
    type: AlertTypes.INFO | AlertTypes.ERROR | AlertTypes.SUCCESS | AlertTypes.WARNING;
    className?: string;
    children?: React.ReactNode;
}

const Alert: FC<AlertPropsInterface> = ({ visible = true, sticky = false, children, type, className = '' }) => {
    const [isVisible, setIsVisible] = useState(visible);
    const hide = () => setIsVisible(false);

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    if (!isVisible) {
        return null;
    }

    return (
        <div
            className={`alert flex items-center p2 rounded white fz13 line-height-120 alert-${type} ${className}`}
            data-testid={`alert-${type}-component`}
        >
            <div className="flex-grow-1 items-center inline">{children}</div>
            {!sticky && (
                <Icon icon="close" className="white mr2 inline-flex fz18 relative alert__close-icon" onClick={hide} />
            )}
        </div>
    );
};

export default Alert;
