import jwt_decode from 'jwt-decode';

export interface TokenInterface {
    actions: string[];
    env: number;
    expires: number;
    jwt: string;
    id: number;
    user: number;
}

export const decode = (token: string): TokenInterface => {
    let decoded: TokenInterface;

    try {
        decoded = jwt_decode(token) as TokenInterface;
    } catch (e) {
        throw new Error('The JWT is invalid or not a token');
    }

    return { ...decoded, jwt: token };
};
