import * as React from 'react';
import { Link } from 'components/url/Link';
import FeatureLink from 'components/url/FeatureLink';
import APITokensRow from './Row';
import APIToken, { APIRotationTokenInterface, APIDeprecatedTokenInterface } from 'models/APIToken';
import { FinalActionModalProvider } from 'components/context/FinalActionModal';

const APITokensList: React.FC<{
    tokens: APIToken[];
    onDeleted: (token: APIToken | APIDeprecatedTokenInterface | APIRotationTokenInterface) => void;
    onRotation: (tokens: APIToken[]) => void;
    onFinalizeRotation: (token: APIToken) => void;
    onDismissHash: (token: APIToken) => void;
}> = ({ tokens, onDeleted, onRotation, onFinalizeRotation, onDismissHash }) => (
    <FinalActionModalProvider>
        <div data-testid="api-tokens-list">
            <p className="h4 lighter mt4 mb3">
                Use this page to rotate a token or create tokens for fetching data from the DPM API. Do not use this
                page to create tokens for installation or authentication. For installation, please use the tokens
                provided by the <FeatureLink to="inventory/machines?add">Add New Host wizard</FeatureLink>; for
                authentication, create a token on the{' '}
                <Link to="settings/authentication" appendEnv={false}>
                    Authentication
                </Link>{' '}
                settings page.
            </p>
            <div className="table-scrollhint">
                <div className="overflow-auto relative full-width z1">
                    <table className="vc-table vc-table--settings vc-table--settings--api-tokens full-width">
                        <thead>
                            <tr>
                                <th className="table-token__name">Name</th>
                                <th className="table-token__type">Type</th>
                                <th className="table-token__expiration">Expiration</th>
                                <th className="table-token__role">Role</th>
                                <th className="table-token__created">Created</th>
                                <th className="table-token__last-used">Last Used</th>
                                <th className="vc-table--settings--api-tokens__actions">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tokens
                                .sort((t1, t2) => t1.name.localeCompare(t2.name))
                                .map(token => (
                                    <APITokensRow
                                        key={token.id}
                                        token={token}
                                        onDeleted={onDeleted}
                                        onRotation={onRotation}
                                        onFinalizeRotation={onFinalizeRotation}
                                        onDismissHash={onDismissHash}
                                    />
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </FinalActionModalProvider>
);

export default APITokensList;
