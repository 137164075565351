import * as React from 'react';
import ContextualHelp from '../../documentation/ContextualHelp';
import Icon from '../../icons/Icon';

interface ModuleTitleInterface {
    className?: string;
    contextualHelpId?: string;
    headerContent?: React.ReactNode;
    children?: React.ReactNode;
}

const ModuleTitle: React.FC<ModuleTitleInterface> = ({
    className = '',
    contextualHelpId = '',
    headerContent,
    children,
}) => (
    <div className="flex justify-between items-center">
        <div className={`flex items-center ${className}`}>
            <h1 className="h2 lighter"> {children} </h1>
            {contextualHelpId && (
                <div className="ml2">
                    <ContextualHelp id={contextualHelpId}>
                        Learn more
                        <Icon icon="keyboard_arrow_right" className="inline-flex fz18 mr2 mainColor relative top-1" />
                    </ContextualHelp>
                </div>
            )}
        </div>
        {headerContent}
    </div>
);

export default ModuleTitle;
