import { SyntheticEvent, FC, ReactNode } from 'react';
import Icon from 'components/icons/Icon';

export interface SelectOptionsInterface {
    name: string;
    value: string | number;
}

export interface GroupedSelectOptionsInterface {
    name: string;
    options: SelectOptionsInterface[];
}

export interface SelectPropsInterface {
    helpText?: string;
    className?: string;
    type?: string;
    prepend?: ReactNode;
    append?: ReactNode;
    options: SelectOptionsInterface[] | GroupedSelectOptionsInterface[];
    onChange: (e: SyntheticEvent<HTMLSelectElement>) => void;
    error?: string;
    grouped?: boolean;
    value?: string | number;
}

const Select: FC<SelectPropsInterface> = ({
    className,
    error,
    helpText,
    prepend,
    append,
    options,
    grouped = false,
    value,
    ...rest
}) => {
    const generateOptions = (optionsList: SelectOptionsInterface[]) =>
        optionsList.map(({ name, value: optionValue }) => (
            <option key={optionValue} value={optionValue}>
                {name}
            </option>
        ));

    return (
        <>
            <div className="grid form__select">
                {prepend && <span className="self-center form__input-container__prepend pr2">{prepend}</span>}
                <div className="relative">
                    {!grouped && (
                        <select className={`full-width ${className}`} value={value} {...rest}>
                            {generateOptions(options as SelectOptionsInterface[])}
                        </select>
                    )}

                    {grouped && (
                        <select className={`full-width ${className}`} value={value} {...rest}>
                            {(options as GroupedSelectOptionsInterface[]).map(group => (
                                <optgroup label={group.name} key={group.name}>
                                    {generateOptions(group.options)}
                                </optgroup>
                            ))}
                        </select>
                    )}
                    <Icon className={`fz18 mainColor absolute right-1 pointer-events`} icon="keyboard_arrow_down" />
                </div>
                {append && <span className="self-center form__input-container__append pl2">{append}</span>}
                {error && <div className="form__input-container__error red mt2">{error}</div>}
                {helpText && <span className="form__input-container__explain grey3 mt2">{helpText}</span>}
            </div>
        </>
    );
};

export default Select;
