import FormGroup, { FormGroupVariantType } from 'components/form/Group';
import { FieldRenderProps, Field } from 'react-final-form';
import TextField from 'components/form/fields/TextField';
import SlideSwitchField from 'components/form/fields/SlideSwitchField';
import { useState, FC, useEffect } from 'react';
import MongoCredentialModel, { MongoCredentialDataInterface } from 'models/credentials/MongoCredential';
import Host from 'models/hosts/Host';
import {
    get as getCredentials,
    getForHost as getCredentialsForHost,
    CredentialHostType,
} from 'services/api/credentials';
import Bouncer from 'components/icons/Bouncer';
import FormField from 'components/form/Field';
import CheckboxField from 'components/form/fields/CheckboxField';

const MongoCredentialsFields: FC<{
    host?: Host;
    onLoad?: (credentials: MongoCredentialModel) => void;
    values?: MongoCredentialModel;
}> = ({ host, onLoad = () => {}, values }) => {
    const [loading, setLoading] = useState(true);

    const fetchCredentials = async () => {
        const result = host
            ? await getCredentialsForHost<MongoCredentialDataInterface>(host)
            : await getCredentials<MongoCredentialDataInterface>(CredentialHostType.mongo);

        setLoading(false);

        if (result === undefined) {
            onLoad(
                new MongoCredentialModel({ sslEnabled: 'false', user: '', host: '', port: '', pass: '', sslKey: '' })
            );
        } else {
            const credentials = new MongoCredentialModel(result);
            credentials.isEmpty = false;
            onLoad(credentials);
        }
    };

    useEffect(() => {
        fetchCredentials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [host]);

    return (
        (!loading && values && (
            <>
                <FormField
                    render={TextField}
                    variant={FormGroupVariantType.HORIZONTAL}
                    label="Address"
                    name="address"
                    placeholder="Usually localhost:27017"
                    required
                    helpText="Examples: localhost:27017, 10.0.10.1:27017, [::1]:27017"
                    htmlField
                />
                <FormField
                    render={TextField}
                    variant={FormGroupVariantType.HORIZONTAL}
                    className="mt3"
                    label="User"
                    name="user"
                    placeholder="Connection's username"
                    htmlField
                />
                <FormField
                    render={TextField}
                    className="mt3 field-inline-labels field-inline-labels--tooltip"
                    label="Password"
                    variant={FormGroupVariantType.HORIZONTAL}
                    name="password"
                    type="password"
                    placeholder="Connection's password"
                    disabled={!values.changePasswordAllowed}
                    tooltip="For security purposes, we do not populate passwords in this field.
                        If you wish to update the password, please check the box and enter a
                        new password in the input."
                />

                <FormGroup className="mt3" variant={FormGroupVariantType.HORIZONTAL} label=" ">
                    <Field type="checkbox" name="changePasswordAllowed">
                        {(props: FieldRenderProps<string, HTMLInputElement>) => (
                            <CheckboxField
                                {...props}
                                label="I want to change the password"
                                id="changePasswordAllowed"
                                data-testid="password-checkbox"
                            />
                        )}
                    </Field>
                </FormGroup>

                <FormField
                    render={SlideSwitchField}
                    variant={FormGroupVariantType.HORIZONTAL}
                    className="mt3"
                    label="SSL Enabled"
                    checked={values.sslEnabled}
                    name="sslEnabled"
                />
                <FormField
                    render={TextField}
                    variant={FormGroupVariantType.HORIZONTAL}
                    className="mt3 field-inline-labels field-inline-labels--tooltip"
                    label="SSL Key"
                    name="sslKey"
                    tooltip="Key file location. The file must contain PEM encoded data."
                    htmlField
                />
            </>
        )) || <Bouncer className="my4 flex full-width justify-center" />
    );
};
export default MongoCredentialsFields;
