import TimeInterval from 'models/TimeInterval';
import { Unit } from './Unit';
import { min, max, sum } from 'd3';

type Point = {
    date: Date;
    value: number;
};

/**
 * A TimeSeries represents a series of observations of a metric in a specific time interval
 */
export default class TimeSeries {
    readonly values: number[];
    readonly unit: Unit;
    readonly timeInterval: TimeInterval;

    constructor(unit: Unit, values: number[], timeInterval: TimeInterval) {
        this.values = values;
        this.unit = unit;
        this.timeInterval = timeInterval;
    }

    get duration() {
        return this.timeInterval.untilTs - this.timeInterval.fromTs;
    }

    get sampleSize() {
        return this.duration / this.values.length;
    }

    /**
     * Computes the average value (arithmetic mean) of the points of the timeseries.
     *
     * For metrics, which have to be summarized as an average per second, this is the property that should be used.
     *
     * Here's why:
     * The total of a metric in a timeseries of samplesize SS is
     * ∑(points) * SS
     * The average per second of that metric hence is
     * ∑(points) * SS / D
     * Where D is the duration of the time interval in seconds.
     * Now, the samplesize is D / N (where N is the number of points in the timeseries) so
     * ∑(points) * (D / N) / D
     * which is equal to:
     * ∑(points) / N
     *
     * So the average per second is the same thing as the arithmetic mean of the timeseries.
     */
    get avg() {
        return this.sum / this.values.length;
    }

    get sum() {
        return sum(this.values);
    }

    get min() {
        return min(this.values);
    }

    get max() {
        return max(this.values);
    }

    /**
     * Returns the integral of the values of the timeseries over the time period.
     *
     * The api stores time averages of some metrics, for instance tput, time_us, latency_us.
     * (An example of a throughput is something that happens multiple times per second, like a query.)
     * For this kind of metrics, the API counts the number of times that an event happened per second and stores this number
     * But then the number gets averaged when we ask for it at a samplesize higher than 1 second
     * Say for example that an event happens only at seconds 1, 3 and 59 of every minute.
     * When we ask the api for an hour of data with a samplesize of one minute, all minutes will have the same value
     * of 0.05 which is 3/60
     *
     * If we need to know the quantity of events that happened in the entire period, we can't do just 0.05 * 60.
     * We need to take in account the samplesize: 0.05 * 60 * 60.
     *
     * Although some people refer to this number as "the sum", the equivalent mathematical operation is not a sum but an integral over time.
     *
     * See another example in the graphite blog http://code.hootsuite.com/accurate-counting-with-graphite-and-statsd/
     */
    get integral() {
        return this.sum * this.sampleSize;
    }

    /**
     * An array of points in the format that visualizations usually expect them (especially d3).
     */
    get points(): Point[] {
        if (this.values.length < 2) {
            throw new Error("[TimeSeries] can't compute points with less than 2 values");
        }

        const initialTs = this.timeInterval.fromTs;

        return this.values.map((value, ind) => {
            const ts = initialTs + ind * this.sampleSize;
            const date = new Date(ts * 1e3);
            return { date, value } as Point;
        });
    }
}
