import md5 from 'blueimp-md5';
import Team from './Team';

export enum UserStatus {
    ENABLED = 'Member',
    INVITED = 'Invited',
    BLOCKED = 'Blocked',
}

export interface UserDataInterface {
    id: number;
    name: string;
    email: string;
    emails?: string[];
    created: number;
    updated: number;
    status: string;
    orgStatus?: string;
    teams?: number[];
}

export default class User {
    readonly id: number;
    private fullName: string;
    readonly email: string;
    readonly emails: string[];
    readonly hashedIdentifier: string;
    readonly statusID: string;
    readonly created: number;
    teams: Team[] = [];

    constructor(data: UserDataInterface) {
        this.id = data.id;
        this.fullName = data.name;
        this.email = data.email;
        this.emails = data.emails || [];
        this.hashedIdentifier = md5(this.email);
        this.statusID = data.status;
        this.created = data.created;
    }

    get status() {
        switch (this.statusID) {
            case '0':
                return UserStatus.ENABLED;
            case '1':
                return UserStatus.INVITED;
            case '2':
                return UserStatus.BLOCKED;
            default:
                return this.statusID;
        }
    }

    get name() {
        return this.fullName;
    }

    setTeams(teams: Team[]) {
        this.teams = teams.filter(team => team.users.find(user => user.id === this.id) !== undefined);
    }

    hasTeam(team: Team) {
        return !!this.teams.find(userTeam => userTeam.id === team.id);
    }

    removeFromTeam(team: Team) {
        const index = this.teams.indexOf(team);

        if (index !== -1) {
            this.teams.splice(index, 1);
        }
    }

    updateName(name: string) {
        this.fullName = name;
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            status: this.statusID,
        };
    }
}
