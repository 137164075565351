import { Redirect } from 'react-router-dom';
import EmailSelection from './EmailSelection';
import MetaTags from 'components/layout/MetaTags';
import * as React from 'react';
import Heading from 'components/modules/public/Heading';
import useUrl from 'hooks/useUrl';
import { useBootstrap } from 'components/context/Bootstrap';
import { useLocation } from 'react-router-dom';

const OAuthEmailSelection: React.FC = () => {
    const [{ bootstrap }] = useBootstrap();
    const { pathname } = useLocation();
    const homepageUrl = useUrl('homepage');

    if (!bootstrap.user || bootstrap.user.emails.length === 0) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <MetaTags title="Select your email - DPM" />
            <Heading>OAuth email sign in</Heading>

            <section className="card-block card-block__oauth shadow mx-auto my4 relative flex bg-white p3 flex-column">
                <h2 className="fz18 regular mb3">Select your email</h2>

                <EmailSelection emails={bootstrap.user.emails} endpoint={pathname} homepage={homepageUrl} />
            </section>
        </>
    );
};

export default OAuthEmailSelection;
