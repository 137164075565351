import AppContext from 'models/Bootstrap';
import Logger from 'services/logger';
import tokenStorage from 'services/token/storage';

const logger = Logger.get('Context');

let context: AppContext;

export function init(appContext: AppContext) {
    logger.debug('Context received');

    context = appContext;

    if (context.rawTokens) {
        tokenStorage.refreshFromObject(context.rawTokens);
    }
}

export function get(): AppContext {
    if (!context) {
        throw new Error('Service must be initialized with Context data');
    }
    return context;
}
