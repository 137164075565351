import { AuthenticatedBootstrap } from 'models/Bootstrap';
import { getFeatureConfig, FlaggedFeature } from './configuration';
import { get as getAppContext } from 'services/context';

/**
 * If TRUE, force internal featureConfig to be shown, (works only for brainiacs).
 * @see https://github.com/VividCortex/ng-app/issues/2165
 */
let showInternals = false;

function getContext(): AuthenticatedBootstrap {
    const context = getAppContext();

    if (context instanceof AuthenticatedBootstrap) {
        return context;
    }

    throw new Error('Trying to access service level with an unauthenticated context');
}

export function isEnabled(featureName: FlaggedFeature): boolean {
    const context = getContext();

    // allow access for brainiacs and impersonated users.
    if (showInternals && (context.isBrainiac() || context.isImpersonation())) {
        return true;
    }

    const featureFlag = getFeatureConfig(featureName, context);

    // Return FALSE if the feature cannot be enabled in the current stage.
    if (featureFlag.isStageAllowed() === false) {
        return false;
    }

    // Return FALSE if the org should not be granted access to this feature
    if (featureFlag.isOrgExplicitlyDisallowed()) {
        return false;
    }

    // Return TRUE if the org is a beta-tester of this feature
    if (featureFlag.isOrgExplicitlyAllowed()) {
        return true;
    }

    // Return TRUE if the user is allowed
    if (featureFlag.isUserAllowed()) {
        return true;
    }

    // Allow beta orgs
    if (featureFlag.selectedInControlledRollup) {
        return true;
    }

    // If the feature is internal don't show it
    if (featureFlag.internal) {
        return false;
    }

    // Disallow if the feature is not allowed for this tier
    if (featureFlag.isTierAllowed() === false) {
        return false;
    }

    // If customer is on trial and the feature is forbidden on trials, disallow access
    if (featureFlag.isTrialAllowed() === false) {
        return false;
    }

    return true;
}

export function isInternal(featureName: FlaggedFeature) {
    const context = getContext();

    const featureFlag = getFeatureConfig(featureName, context);

    return featureFlag.internal;
}

export function isBeta(featureName: FlaggedFeature) {
    const context = getContext();

    const featureFlag = getFeatureConfig(featureName, context);

    return featureFlag.isBeta();
}

export function toggleInternals() {
    showInternals = !showInternals;

    return showInternals;
}
