import { createContext, useContext, useEffect, useState, FC } from 'react';
import { getHostsCount, HostTotalsInterface } from 'services/api/hosts';
import { useBootstrap } from 'components/context/Bootstrap';
import { useTimeInterval } from './TimeInterval';
import useUIStatus from 'hooks/useUIStatus';
import { FILTER_MODE } from 'services/api/hosts';
import Logger from 'services/logger';

const logger = Logger.get('HostFilterContext');

export interface HostFilterContextInterface {
    filter: string;
    defaultMode: FILTER_MODE;
    savedSyntaxQueries: string;
    set(filter: string): void;
    enable(): void;
    disable(): void;
    setHostsMode(mode: string): void;
    onRemovePill(pill: string): void;
    totals?: HostTotalsInterface;
    noMatchingHosts: boolean;
    readOnly: boolean;
}

export const HostFilterContext = createContext<HostFilterContextInterface>({
    filter: '',
    defaultMode: FILTER_MODE.basic,
    savedSyntaxQueries: '',
    set: () => {},
    disable: () => {},
    enable: () => {},
    setHostsMode: () => {},
    onRemovePill: () => {},
    noMatchingHosts: false,
    readOnly: false,
});

export const useHostFilter = () => {
    const context = useContext(HostFilterContext);

    return context;
};

const HostFilterContextProvider: FC<{ storeStatus?: boolean; children?: React.ReactNode }> = ({
    storeStatus = true,
    children,
}) => {
    const [uiStatus, setUIStatus] = useUIStatus(
        {
            hosts: '',
            hostsMode: '',
            savedSyntaxQueries: '',
        },
        {
            id: 'uhf',
            excludeStorageKeys: ['hosts'],
            excludeSearchKeys: ['hostsMode', 'savedSyntaxQueries'],
            clearOnRouteChange: false,
        }
    );

    const [filter, setFilter] = useState(storeStatus ? uiStatus.hosts : '');
    const [defaultMode, setDefaultMode] = useState(storeStatus ? uiStatus.hostsMode : '');
    const [savedSyntaxQueries, setSavedSyntaxQueries] = useState<string>(
        storeStatus ? uiStatus.savedSyntaxQueries : ''
    );
    const [totals, setTotals] = useState<HostTotalsInterface | undefined>(undefined);
    const [enabled, setEnabled] = useState(true);
    const { interval } = useTimeInterval();
    const [{ bootstrap }] = useBootstrap();

    useEffect(() => {
        // We omit this request when an environment is not in the context.
        if (!bootstrap.env) {
            return;
        }

        getHostsCount(interval, filter)
            .then(newTotals => setTotals(newTotals))
            .catch(error => logger.error('Failed to retrieve the hosts count', error));
    }, [interval, filter, bootstrap]);

    const set = (newFilter: string) => {
        if (storeStatus) {
            setUIStatus({ hosts: newFilter });
        }

        setFilter(newFilter);

        setTotals(undefined);

        addFilterToPills(newFilter);
    };
    const enable = () => setEnabled(true);
    const disable = () => setEnabled(false);

    const noMatchingHosts = totals?.matching === 0;

    const setHostsMode = (newMode: FILTER_MODE) => {
        if (storeStatus) {
            setUIStatus({ hostsMode: newMode });
        }

        setDefaultMode(newMode);
    };

    const onRemovePill = (pill: string) => {
        const newSavedSyntaxQueries = savedSyntaxQueries
            .split(',')
            .filter(value => value !== pill)
            .join();
        if (storeStatus) {
            setUIStatus({ savedSyntaxQueries: newSavedSyntaxQueries });
        }

        setSavedSyntaxQueries(newSavedSyntaxQueries);
    };

    const addFilterToPills = (newFilter: string) => {
        if (newFilter.length === 0) {
            return;
        }

        if (defaultMode !== FILTER_MODE.advanced) {
            return;
        }

        const pillsAsArray = !savedSyntaxQueries ? [] : savedSyntaxQueries.split(',');
        const newSavedSyntaxQueries = [...new Set([...pillsAsArray, newFilter])].join(',');

        if (storeStatus) {
            setUIStatus({ savedSyntaxQueries: newSavedSyntaxQueries });
        }

        setSavedSyntaxQueries(newSavedSyntaxQueries);
    };

    return (
        <HostFilterContext.Provider
            value={{
                filter,
                defaultMode,
                savedSyntaxQueries,
                set,
                totals,
                noMatchingHosts,
                readOnly: !enabled,
                enable,
                disable,
                setHostsMode,
                onRemovePill,
            }}
        >
            {children}
        </HostFilterContext.Provider>
    );
};

export default HostFilterContextProvider;
