import Authentication, { CaptchaConfigurationType } from './Authentication';
import { AuthenticatedURLInterface, OrgURLInterface, URLInterface } from './URL';
import { AuthMethodInterface } from './AuthMethod';
import Org, { OrgDataInterface } from './Org';
import Env, { EnvDataInterface } from './Env';
import User, { UserDataInterface } from './User';

export type AppContext = Bootstrap | OrgBootstrap | AuthenticatedBootstrap;

export type WebappStage = 'prod' | 'stage' | 'dev' | 'local' | 'test';

export interface BootstrapTokensInterface {
    org: string;
    env?: string;
}

export interface BootstrapDataInterface {
    auth?: {
        methods: AuthMethodInterface[];
        supportedMethods: string[];
    };
    impersonating: boolean;
    installation: string;
    org?: OrgDataInterface;
    envs?: EnvDataInterface[];
    previousOrgs?: OrgDataInterface[];
    session: {
        ttl: number;
    };
    stage: WebappStage;
    support: {
        account: string;
    };
    sales: {
        account: string;
    };
    thirdParty: {
        Google: { tagManagerId: string };
        captcha: CaptchaConfigurationType;
        Pendo?: { appId: string };
    };
    tokens?: BootstrapTokensInterface;
    urls: URLInterface | OrgURLInterface | AuthenticatedURLInterface;
    user?: UserDataInterface;
    utcdate: number;
    version?: string;
    multiplexerPaths: string[];
    errors?: {
        auth?: string;
    };
}

export interface OrgBootstrapDataInterface extends BootstrapDataInterface {
    org: OrgDataInterface;
}

export interface AuthenticatedBootstrapDataInterface extends OrgBootstrapDataInterface {
    user: UserDataInterface;
    env?: EnvDataInterface;
    envs: EnvDataInterface[];
}

export default class Bootstrap {
    readonly installation: string;
    readonly impersonating: boolean;
    readonly env?: Env;
    readonly multiplexerPaths: string[];
    readonly timestamp: number;
    readonly org?: Org;
    readonly previousOrgs: Org[] = [];
    readonly user?: User;
    readonly version?: string;
    readonly urls: URLInterface | OrgURLInterface | AuthenticatedURLInterface;

    constructor(protected data: BootstrapDataInterface) {
        this.installation = data.installation;
        this.impersonating = data.impersonating;
        this.multiplexerPaths = data.multiplexerPaths;
        if (data.previousOrgs) {
            this.previousOrgs = data.previousOrgs.map(envData => new Org(envData));
        }
        this.timestamp = data.utcdate;
        this.urls = data.urls as URLInterface;
        this.version = data.version;

        if (data.user && !this.user) {
            this.user = new User(data.user);
        }
    }

    /**
     * Return raw bootstrap tokens data for processing. Direct access is discouraged.
     * @see services/token/storage.ts
     */
    get rawTokens(): BootstrapTokensInterface | undefined {
        return this.data.tokens;
    }

    get stage(): WebappStage {
        return this.data.stage;
    }

    get supportEmail(): string {
        return this.data.support.account;
    }

    get salesEmail(): string {
        return this.data.sales.account;
    }

    get tagManagerId(): string {
        return this.data.thirdParty.Google ? this.data.thirdParty.Google.tagManagerId : '';
    }

    get sessionTTL(): number {
        return this.data.session.ttl;
    }

    isMainInstallation() {
        return this.installation === 'main';
    }

    isBrainiac(): boolean {
        return false;
    }

    isImpersonation(): boolean {
        return false;
    }
}

export class OrgBootstrap extends Bootstrap {
    readonly auth: Authentication;
    readonly org: Org;
    readonly urls: OrgURLInterface | AuthenticatedURLInterface;

    constructor(protected data: OrgBootstrapDataInterface) {
        super(data);

        this.auth = Authentication.fromBootstrapData(data);
        this.org = new Org(data.org);
        this.urls = data.urls as OrgURLInterface;
    }

    isBrainiac(): boolean {
        return this.org.nickname === 'vividcortex' || this.isImpersonation();
    }
}

export class AuthenticatedBootstrap extends OrgBootstrap {
    readonly user: User;
    readonly env?: Env;
    private environments!: Env[];
    readonly urls: AuthenticatedURLInterface;

    constructor(protected data: AuthenticatedBootstrapDataInterface) {
        super(data);

        this.user = new User(data.user);

        if (data.env) {
            this.env = new Env(data.env);
        }

        this.envs = data.envs.map(envData => new Env(envData));
        this.urls = data.urls as AuthenticatedURLInterface;
    }

    get envs() {
        return this.environments;
    }

    set envs(envs: Env[]) {
        this.environments = envs.sort((e1, e2) => (e1.name < e2.name ? -1 : 1));
    }

    isImpersonation(): boolean {
        return this.data.impersonating === true;
    }

    addEnv(env: Env) {
        this.envs = [...this.envs, env];
    }

    removeEnv(env: Env) {
        const envs = [...this.envs];

        envs.splice(envs.indexOf(env), 1);

        this.envs = envs;
    }

    renameEnv(env: Env, newName: string) {
        const envs = [...this.envs];

        envs.forEach(currentEnv => {
            if (currentEnv.id === env.id) {
                currentEnv.name = newName;
            }
        });

        this.envs = envs;
    }
}

export function createContextFromData(
    data: AuthenticatedBootstrapDataInterface | OrgBootstrapDataInterface | BootstrapDataInterface
): AppContext {
    // User is authenticated if present and with an id
    if (data.user?.id) {
        return new AuthenticatedBootstrap(data as AuthenticatedBootstrapDataInterface);
    }

    if (data.org) {
        return new OrgBootstrap(data as OrgBootstrapDataInterface);
    }

    return new Bootstrap(data as BootstrapDataInterface);
}
