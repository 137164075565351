import { getCurrentTimestamp, getOffsetPreset, DEFAULT_INTERVAL } from 'services/time';
import { formatTime, DateTimeFormat } from 'services/timezone';

const NOW = 0;

export interface ParameterizableIntervalInterface {
    asParams(): { from: string; until: string };
}

export default class TimeInterval implements ParameterizableIntervalInterface {
    from: number;
    until: number;
    currentTimestamp!: number;

    /**
     * @param {number} from Timestamp or offset (e.g. -3600)
     * @param {number} until Timestamp or 0 for "now"
     **/
    constructor(from: number, until: number) {
        this.from = from;
        this.until = until;

        if (this.isInvalid()) {
            throw new Error(`Invalid interval ${from} - ${until}`);
        }

        this.updateTimestamp();
    }

    static createDefaultOffsetInterval() {
        return new TimeInterval(DEFAULT_INTERVAL.from, DEFAULT_INTERVAL.until);
    }

    get fromTs() {
        if (this.isOffset) {
            return this.untilTs + this.from;
        }

        return this.from;
    }

    get untilTs() {
        // 0 until means now or last known "now"
        if (this.until === NOW) {
            return this.currentTimestamp;
        }

        return this.until;
    }

    get tsOffset() {
        return this.until - this.from;
    }

    get isOffset() {
        // Negative from means time offset from the past
        return this.from < 0;
    }

    get fromString() {
        if (!this.isOffset) {
            return formatTime(this.from, DateTimeFormat.TIMESTAMP_LEGEND);
        }

        const preset = getOffsetPreset(Math.abs(this.from));

        // This shouldn't happen will probably be updated when the timepicker is created.
        return preset?.text || 'Error';
    }

    get untilString() {
        return this.until === NOW ? 'now' : formatTime(this.until, DateTimeFormat.TIMESTAMP_LEGEND);
    }

    get endingNow() {
        return this.until === NOW;
    }

    asParams() {
        return {
            from: this.fromTs.toString(),
            until: this.untilTs.toString(),
        };
    }

    /**
     * When an instance is created, we store the current timestamp to have a lock
     * on what "now" means and allow repeated requests using the same "now".
     * Whenever we need to update the "now" timestamp (e.g. auto refresh), this
     * method should be called.
     */
    updateTimestamp() {
        this.currentTimestamp = getCurrentTimestamp();
    }

    isInvalid() {
        if (this.fromTs >= this.untilTs) {
            return true;
        }

        if (this.isOffset && !getOffsetPreset(Math.abs(this.from))) {
            return true;
        }

        return false;
    }
}
