import * as React from 'react';
import Icon from 'components/icons/Icon';
import useDisplayMessage from 'hooks/useDisplayMessage';
import cn from 'helpers/classname';

export enum GlobalMessageTypes {
    ERROR = 'error',
    SUCCESS = 'done',
    WARNING = 'warning',
}

interface GlobalMessagePropsInterface {
    type: GlobalMessageTypes;
    dismissable: boolean;
    fade: boolean;
    id: string;
    children?: React.ReactNode;
}

const GlobalMessage: React.FC<GlobalMessagePropsInterface> = ({ type, children, dismissable = true, fade, id }) => {
    const { dismiss } = useDisplayMessage();
    const onClose = () => dismiss(id);

    return (
        <div
            className={`${cn('vc-msg--fade-out ', fade)}vc-msg flex items-stretch rounded bg-white m2 nowrap ${type}`}
            onClick={dismissable ? onClose : undefined}
        >
            <Icon icon={type} className="white fz18 vc-msg__icon py3 px2 flex items-center" />
            <p className="inline-flex left-align items-center fz15 dark px2 py3 my0 flex-grow-1 pre-wrap">{children}</p>
            {dismissable && <Icon icon="close" className="h2 inline-flex cursor-hand pl3 pr2 items-center grey-2" />}
        </div>
    );
};

export default GlobalMessage;
