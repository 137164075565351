import APIRequest from './Request';
import Logger from 'services/logger';

const logger = Logger.get('envSubscriptions');

export interface EnvSubscriptionInterface {
    metric: string;
    hosts: number[];
}

export type SubscriptionOperation = 'add' | 'remove' | 'replace';

export async function get() {
    logger.info('getting the env subscription');

    const response = await new APIRequest<EnvSubscriptionInterface[]>({
        path: `aiops/subscriptions`,
    }).request();

    return response.data;
}

export async function update(subscription: EnvSubscriptionInterface, operation: SubscriptionOperation) {
    logger.info('updating an env subscription');

    const response = await new APIRequest({
        path: `aiops/subscriptions?metric=${
            subscription.metric
        }&hosts=${subscription.hosts.join()}&update=${operation}`,

        method: 'put',
    }).request();

    return response.data;
}

export async function remove(subscription: EnvSubscriptionInterface) {
    logger.info('upddeleting an env subscription');

    return new APIRequest({
        path: `aiops/subscriptions`,
        params: {
            metric: subscription.metric,
        },
        method: 'delete',
    }).request();
}
