import { useRef, useState } from 'react';

// JSDOM does not support scrolling, so we cannot test this feature with Jest
// istanbul ignore next
export default function useScrollableRef<T extends Element>(initialValue: T | null) {
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const ref = useRef<T>(initialValue);

    function onScroll() {
        if (!ref.current) {
            return;
        }

        if (!isScrolled && ref.current.scrollTop > 0) {
            setIsScrolled(true);
        } else if (isScrolled && ref.current.scrollTop === 0) {
            setIsScrolled(false);
        }
    }

    function scrollToTop() {
        if (!ref.current) {
            return;
        }

        ref.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    function scrollToAnchor(anchor: string) {
        setTimeout(() => {
            if (!ref?.current) {
                return;
            }

            const element = ref.current.querySelector(anchor);
            if (!element) {
                return;
            }

            element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }, 0);
    }

    return { ref, isScrolled, onScroll, scrollToTop, scrollToAnchor };
}
