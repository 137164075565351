import AsyncHostAttribute from 'components/host/AsyncHostAttribute';
import cn from 'helpers/classname';
import DeadlockPreview from 'models/queries/DeadlockPreview';
import EmptyState, { EmptyTypes } from 'components/messages/EmptyState';
import Logger from 'services/logger';
import Paginator from 'components/lists/OffsetPaginator';
import SortableTable, { ColumnInterface } from 'components/tables/SortableTable';
import TimeAgo from 'components/format/TimeAgo';
import useTimeAndHostFilter from 'hooks/useTimeAndHostFilter';
import { FC } from 'react';
import { getAll } from 'services/api/deadlocks';
import { Link } from 'components/url/Link';
import { useEffect, useState } from 'react';

const logger = Logger.get('deadlocks');

const columns: ColumnInterface[] = [
    {
        name: 'Deadlock ID',
        column: 'id',
        sortable: false,
        className: 'vc-table--deadlocks__id',
    },
    {
        name: 'Host',
        column: 'hostId',
        sortable: false,
    },
    {
        name: 'Name',
        column: 'hostName',
        sortable: true,
        className: 'full-width',
    },
    {
        name: 'Seen',
        column: 'timestamp',
        sortable: true,
        className: 'vc-table--deadlocks__collection',
    },
];

const Deadlocks: FC = () => {
    const [deadlocks, setDeadlocks] = useState<DeadlockPreview[] | undefined>(undefined);
    const [moreResults, setMoreResults] = useState(false);
    const [offset, setOffset] = useState<DeadlockPreview[]>([]);
    const { filter, interval, noMatchingHosts } = useTimeAndHostFilter();

    useEffect(() => {
        setDeadlocks(undefined);

        getAll(filter, interval, offset[offset.length - 1])
            .then(response => {
                setDeadlocks(response.data);
                setMoreResults(response.hasMore);
            })
            .catch(e => {
                setDeadlocks([]);
                logger.error(e);
            });
    }, [filter, interval, offset]);

    const prevPage = () => {
        offset.pop();

        setOffset([...offset]);
    };

    const nextPage = () => {
        if (deadlocks) {
            setOffset([...offset, deadlocks[deadlocks.length - 1]]);
        }
    };

    const paginates = moreResults || offset.length > 0;
    const hasDeadlocks = paginates || (deadlocks && deadlocks.length > 0);

    return (
        <>
            <div className={`view view--salesforce${cn('deadlocks', !noMatchingHosts)}`}>
                <h1 className="flex items-center h2 mr2 lighter">Deadlocks</h1>
                {!noMatchingHosts && hasDeadlocks && (
                    <>
                        <div className="full-width mt3 overflow-auto">
                            <SortableTable
                                className="vc-table vc-table--tile vc-table--deadlocks full-width pb3"
                                data-testid="deadlocks-list"
                                columns={columns}
                                items={deadlocks || []}
                            >
                                {(deadlock: DeadlockPreview) => (
                                    <tr data-testid="deadlocks-row">
                                        <td>
                                            <Link to={`deadlocks/${deadlock.id}/${deadlock.timestamp}`}>
                                                {deadlock.id}
                                            </Link>
                                        </td>
                                        <td>
                                            <AsyncHostAttribute
                                                id={deadlock.hostId}
                                                attribute="icon"
                                                className="fz27"
                                            />
                                        </td>
                                        <td>{deadlock.hostName}</td>
                                        <td>
                                            <span className="dark nowrap fz13">
                                                <TimeAgo date={deadlock.timestamp} />
                                            </span>
                                        </td>
                                    </tr>
                                )}
                            </SortableTable>
                        </div>
                        {paginates && (
                            <Paginator
                                onFirstPage={offset.length === 0}
                                onLastPage={!moreResults}
                                onPageDown={() => prevPage()}
                                onPageUp={() => nextPage()}
                            />
                        )}
                    </>
                )}

                {!noMatchingHosts && deadlocks && deadlocks.length === 0 && (
                    <div className="full-width mt3">
                        <EmptyState type={EmptyTypes.INFO}>
                            There are no results with the current hosts filter and time selections.
                        </EmptyState>
                    </div>
                )}
            </div>
        </>
    );
};

export default Deadlocks;
