import * as React from 'react';

const Bouncer: React.FC<{ className?: string; children?: React.ReactNode }> = ({ className = '' }) => (
    <div className={`vc-bouncer ${className}`} data-testid="bouncer">
        <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
        </div>
    </div>
);

export default Bouncer;
