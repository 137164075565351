import { useEffect, useState } from 'react';
import Team from 'models/Team';
import { get as getTeams } from 'services/api/teams';
import OverviewItemRow, { SettingOverviewLink } from './ItemRow';
import Logger from 'services/logger';

const logger = Logger.get('OverviewTeams');

const OverviewTeams = () => {
    const [teams, setTeams] = useState<Team[]>([]);

    useEffect(() => {
        getTeams()
            .then(fetchedTeams => setTeams(fetchedTeams))
            .catch(() => {
                logger.warn('error while fetching the teams');
            });
    }, []);

    if (!teams.length) {
        return null;
    }

    const urlAttrs: SettingOverviewLink = {
        href: '/settings/teams',
        text: 'View Teams',
    };

    return (
        <OverviewItemRow label="Teams" urlAttrs={urlAttrs}>
            <span className="flex-grow-1" data-testid="teams-info">
                {teams.length}
            </span>
        </OverviewItemRow>
    );
};

export default OverviewTeams;
