import cn from 'helpers/classname';
import Filter from 'components/host/HostsFilter';
import TimeInterval from 'models/TimeInterval';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { expressionIsAdvancedSyntax, FILTER_MODE } from 'services/api/hosts';
import { FC, useState } from 'react';
import { useHostFilter } from 'components/context/HostFilter';
import { useTimeInterval } from 'components/context/TimeInterval';

export const readOnlyClassName = 'hosts-selector--disabled';

const HostsSelector: FC<{
    timeInterval?: TimeInterval;
    className?: string;
    readOnly?: boolean;
    singleMode?: FILTER_MODE;
    placeholder?: string;
}> = ({ timeInterval, className = '', readOnly = false, singleMode, placeholder }) => {
    const { filter, defaultMode, set: setFilter, setHostsMode, savedSyntaxQueries, onRemovePill } = useHostFilter();
    const { interval } = useTimeInterval();
    const [mode, setMode] = useState<FILTER_MODE>(() => {
        if (undefined !== singleMode) {
            return singleMode;
        }

        if (defaultMode !== undefined && filter === '') {
            return defaultMode === 'advanced' ? FILTER_MODE.advanced : FILTER_MODE.basic;
        }

        return expressionIsAdvancedSyntax(filter) ? FILTER_MODE.advanced : FILTER_MODE.basic;
    });
    const [modeOnOpen, setModeOnOpen] = useState(mode);
    const [expanded, setExpanded] = useState(false);
    const [error] = useState(false);
    const [filterValue, setFilterValue] = useState(mode === FILTER_MODE.basic ? '' : filter);
    const { ref } = useOnOutsideClick<HTMLDivElement>(() => {
        if (!expanded) {
            return;
        }

        cancel();
    });

    const intervalInstance = timeInterval || interval;
    const isBasicMode = mode === FILTER_MODE.basic;
    const isAdvancedMode = !isBasicMode;

    function expand() {
        if (!expanded) {
            setExpanded(true);

            setModeOnOpen(mode);
        }
    }

    function cancel() {
        setExpanded(false);

        setFilterValue(modeOnOpen === FILTER_MODE.basic ? '' : filter);

        if (mode !== modeOnOpen) {
            setMode(modeOnOpen);
            setHostsMode(modeOnOpen);
        }
    }

    function onValueChange(newFilter: string) {
        setFilterValue(newFilter);
    }

    function commit(newFilter: string) {
        setExpanded(false);
        setFilter(newFilter);

        if (mode === FILTER_MODE.basic) {
            setFilterValue('');
        }
    }

    function clear() {
        commit('');
        setFilterValue('');
    }

    function switchMode(selectedMode: FILTER_MODE) {
        setMode(selectedMode);
        setHostsMode(selectedMode);

        setFilterValue('');
    }

    return (
        <div
            className={`flex items-start hosts-selector relative ${className} ${cn(
                'hosts-selector--expanded',
                expanded
            )}${cn('hosts-selector--error', error)}${cn(readOnlyClassName, readOnly)}`}
            data-testid="hosts-selector"
            ref={ref}
        >
            {singleMode === undefined && expanded && (
                <div className="hosts-selector__filter-mode" data-testid="hosts-selector-mode">
                    <div className="hosts-selector__filter-mode__dropdown">
                        <ul className="list-reset bg-grey05">
                            <li
                                className={`host-filter-mode__dropdown__list__item ${cn(
                                    'host-filter-mode__dropdown__list__item--selected',
                                    isBasicMode
                                )}`}
                            >
                                <button
                                    className="flex items-center nowrap px3 py1 cursor-hand full-width border-bottom border-color-grey1"
                                    onClick={() => switchMode(FILTER_MODE.basic)}
                                    data-testid="hosts-selector-mode-basic"
                                >
                                    Select host
                                </button>
                            </li>
                            <li
                                className={`host-filter-mode__dropdown__list__item ${cn(
                                    'host-filter-mode__dropdown__list__item--selected',
                                    isAdvancedMode
                                )}`}
                            >
                                <button
                                    className="flex items-center nowrap px3 py1 cursor-hand full-width"
                                    onClick={() => switchMode(FILTER_MODE.advanced)}
                                    data-testid="hosts-selector-mode-advanced"
                                >
                                    Use Syntax
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            )}

            <div className="host-filter__input-wrap flex items-center relative full-width">
                <Filter
                    clear={clear}
                    mode={mode}
                    setMode={singleMode === undefined ? setMode : undefined}
                    value={filterValue}
                    filter={filter}
                    expanded={expanded}
                    onValueChange={onValueChange}
                    timeInterval={intervalInstance}
                    commit={commit}
                    expand={expand}
                    inputPlaceholder={placeholder}
                    savedSyntaxQueries={savedSyntaxQueries ?? ''}
                    onRemovePill={onRemovePill}
                >
                    <div className="flex">
                        <button
                            data-testid="hosts-selector-cancel"
                            type="button"
                            className="primary-grey mx1"
                            onClick={cancel}
                        >
                            Cancel
                        </button>
                        <button data-testid="hosts-selector-apply" type="submit" className="primary-mainColor ml1">
                            Apply
                        </button>
                    </div>
                </Filter>
            </div>
        </div>
    );
};

export default HostsSelector;
