import { Component, ReactNode } from 'react';

interface DiagramErrorBoundaryProps {
    children: ReactNode;
}

interface DiagramErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
}

class DiagramErrorBoundary extends Component<DiagramErrorBoundaryProps, DiagramErrorBoundaryState> {
    state: DiagramErrorBoundaryState = {
        error: null,
        hasError: false,
    };

    static getDerivedStateFromError(): DiagramErrorBoundaryState {
        return { error: null, hasError: true };
    }

    componentDidCatch(error: Error) {
        this.setState({ error });
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children;
        } else {
            return (
                <div className="grid place-center full-width">
                    <h1>There was an error building the diagram for the selected query.</h1>
                </div>
            );
        }
    }
}

export default DiagramErrorBoundary;
