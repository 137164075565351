import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { search as searchMetrics } from 'services/api/metrics';
import debouncePromise from 'awesome-debounce-promise';

const MetricPicker: FC<{ className?: string; onSelect: (metric: string) => void; placeholder?: string }> = ({
    className = '',
    onSelect,
    placeholder = 'Type metric (example os.cpu.loadavg)',
}) => {
    const [metrics, setMetrics] = useState<string[] | null>(null);
    const [metricsInput, setMetricsInput] = useState('');

    const debouncedFilter = useCallback(debouncePromise(searchMetrics, 300), []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInputChange = (e: SyntheticEvent<HTMLInputElement>) => {
        const name = e.currentTarget?.value || '';
        setMetricsInput(name);
    };

    const fetchMetrics = async (query: string) => {
        try {
            const matchingMetrics = await searchMetrics(query);
            setMetrics(matchingMetrics);
        } catch {
            setMetrics([]);
        }
    };

    useEffect(() => {
        debouncedFilter(metricsInput).then((result: string[]) => {
            setMetrics(result);
        });
    }, [metricsInput]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchMetrics('');
    }, []);

    return (
        <div className="grid grid-align-stretch form__input-container">
            <input
                className={`large ${className}`}
                list="metric-list"
                value={metricsInput}
                onChange={handleInputChange}
                onSelect={e => {
                    onSelect(e.currentTarget?.value || '');
                }}
                type="text"
                placeholder={placeholder}
                autoComplete="off"
                required
            />
            {(metrics?.length && (
                <datalist id="metric-list" data-testid="metrics-list" className="full-width">
                    {metrics.map(metric => (
                        <option key={metric} value={metric}>
                            {metric}
                        </option>
                    ))}
                </datalist>
            )) ||
                null}
        </div>
    );
};

export default MetricPicker;
