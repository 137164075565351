import * as React from 'react';
import { userIsAllowedTo, ActionType } from 'services/security/accessControl';

interface UserPermissionPropsInterface {
    to: ActionType;
    children: React.ReactNode;
}

export const UserHasPermission: React.FC<UserPermissionPropsInterface> = ({ children, to }) => {
    return userIsAllowedTo(to) ? <>{children}</> : null;
};

export const UserHasNoPermission: React.FC<UserPermissionPropsInterface> = ({ children, to }) => {
    return !userIsAllowedTo(to) ? <>{children}</> : null;
};
