import Group, { FormGroupPropsInterface } from './Group';
import * as React from 'react';
import { Html5ValidationFieldProps, Field as HTML5Field } from 'react-final-form-html5-validation';
import { Field as FinalFormField } from 'react-final-form';

interface FormFieldInterface {
    fieldClassName?: string;
    htmlField: boolean;
}

type Props = FormGroupPropsInterface & Html5ValidationFieldProps & FormFieldInterface;

const FormField: React.FC<Props> = ({
    variant,
    className,
    label,
    fieldClassName,
    htmlField = false,
    ...fieldProps
}) => {
    const FieldComponent = htmlField ? HTML5Field : FinalFormField;
    return label ? (
        <Group variant={variant} className={className} label={label}>
            <FieldComponent {...fieldProps} className={fieldClassName} />
        </Group>
    ) : (
        <FieldComponent {...fieldProps} className={fieldClassName} />
    );
};

export default FormField;
