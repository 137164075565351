import { FC } from 'react';
import { TableCellLoader } from 'components/loaders/Loader';

const LoaderRow: FC<{ cols: number }> = ({ cols }) => {
    return (
        <tr data-testid="loader-row">
            {[...Array(cols)].map((_, i) => (
                <td key={i}>
                    <TableCellLoader className="loader--thick" />
                </td>
            ))}
        </tr>
    );
};

export default LoaderRow;
