import Deadlock from 'models/queries/Deadlock';
import { FC } from 'react';

const DeadlockHeader: FC<{ deadlock: Deadlock }> = ({ deadlock }) => {
    return (
        <table className="deadlock__table full-width overflow-auto" data-testid="deadlock-header">
            <thead>
                <tr>
                    {deadlock.header.map(header => (
                        <th key={header.label}>{header.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {deadlock.headerRows.map((row, i) => (
                    <tr key={i}>
                        {row.map((col, j) => (
                            <td key={j}>{col}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DeadlockHeader;
