import Icon from 'components/icons/Icon';
import ModalRenderer from './Renderer';
import { FC, useEffect } from 'react';

interface ModalPropsInterface {
    title: string;
    visible: boolean;
    onClose: () => void;
    children?: React.ReactNode;
}

const Modal: FC<ModalPropsInterface> = ({ title, children, visible = true, onClose }) => {
    useEffect(() => {
        function escapeListener(e: KeyboardEvent) {
            if (visible && e.key === 'Escape') {
                onClose();
            }
        }

        document.addEventListener('keydown', escapeListener, true);

        return () => document.removeEventListener('keydown', escapeListener, true);
    }, [onClose, visible]);

    if (!visible) {
        return null;
    }

    return (
        <ModalRenderer>
            <div
                className="dialog fixed full-height full-width flex items-center justify-center z4 top-0"
                data-testid="dialog"
            >
                <div className="dialog__backdrop fixed full-height full-width" />

                <div className="dialog__container relative z1">
                    <div className="dialog__container__header bg-MainColor flex line-height-120 items-center">
                        <h3 className="h4 white bold flex-grow-1 truncate">{title}</h3>
                        {onClose && (
                            <Icon
                                icon="close"
                                className="flex h2 white absolute right-3"
                                onClick={onClose}
                                data-testid="dialog-close-button"
                            />
                        )}
                    </div>
                    <div className="dialog__container__content bg-grey05">{children}</div>
                </div>
            </div>
        </ModalRenderer>
    );
};

export default Modal;
