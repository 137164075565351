import Host from '../models/hosts/Host';
import { byName as sortByName } from '../helpers/sort';
import { useReducer, useCallback } from 'react';

interface StateInterface {
    suggestions: Host[];
    selected: Host[];
    active: Host | undefined;
}

type Action =
    | { type: 'set'; suggestions: Host[] }
    | { type: 'clear' }
    | { type: 'activatePrevious' }
    | { type: 'activateNext' }
    | { type: 'setSelected'; hosts: Host[] }
    | { type: 'unselectLast' }
    | { type: 'clearSelection' }
    | { type: 'select'; host: Host };

function filterSuggestions(suggestions: Host[], selected: Host[]): Host[] {
    return suggestions.filter(suggestion => !selected.find(host => host.id === suggestion.id));
}

function getPreviousActiveHost(state: StateInterface): Host | undefined {
    const hosts = filterSuggestions(state.suggestions, state.selected);
    if (!state.active) {
        return hosts.pop();
    }

    let index = hosts.indexOf(state.active);
    if (0 < index) {
        index--;
    }

    return hosts[index];
}

function getNextActiveHost(state: StateInterface): Host | undefined {
    const hosts = filterSuggestions(state.suggestions, state.selected);
    if (!state.active) {
        return hosts.shift();
    }

    let index = hosts.indexOf(state.active);
    if (index < hosts.length - 1) {
        index++;
    }

    return hosts[index];
}

function suggestionsReducer(state: StateInterface, action: Action) {
    switch (action.type) {
        case 'set':
            return {
                ...state,
                suggestions: action.suggestions,
                active: filterSuggestions(action.suggestions, state.selected).find(
                    host => host.id === state.active?.id
                ),
            };
        case 'clear':
            return { ...state, suggestions: [], active: undefined };
        case 'activateNext':
            return { ...state, active: getNextActiveHost(state) };
        case 'activatePrevious':
            return { ...state, active: getPreviousActiveHost(state) };
        case 'select':
            return {
                ...state,
                active: undefined,
                selected: sortByName(
                    [...state.selected, action.host].filter(
                        (host, index, hosts) => hosts.indexOf(host) === index // Remove duplicates
                    )
                ),
            };
        case 'unselectLast':
            return {
                ...state,
                selected: state.selected.slice(0, -1),
            };
        case 'clearSelection':
            return { ...state, selected: [] };
        case 'setSelected':
            return {
                ...state,
                active: undefined,
                selected: sortByName(action.hosts),
            };
        default:
            /* istanbul ignore next */
            throw new Error(`Invalid action ${JSON.stringify(action)}`);
    }
}

export function useHostFilterSuggestions(initial: Host[]) {
    const [{ suggestions, selected, active }, dispatch] = useReducer(suggestionsReducer, {
        suggestions: initial,
        selected: [],
        active: undefined,
    });

    const setSuggestions = useCallback(
        (hosts: Host[]) =>
            dispatch({
                type: 'set',
                suggestions: hosts,
            }),
        [dispatch]
    );
    const clearSuggestions = useCallback(() => dispatch({ type: 'clear' }), [dispatch]);
    const setSelected = useCallback(
        (hosts: Host[]) =>
            dispatch({
                type: 'setSelected',
                hosts,
            }),
        [dispatch]
    );
    const select = useCallback(
        (host: Host) =>
            dispatch({
                type: 'select',
                host,
            }),
        [dispatch]
    );
    const unselectLast = useCallback(() => dispatch({ type: 'unselectLast' }), [dispatch]);
    const clearSelection = useCallback(() => dispatch({ type: 'clearSelection' }), [dispatch]);
    const activatePreviousSuggestion = useCallback(() => dispatch({ type: 'activatePrevious' }), [dispatch]);
    const activateNextSuggestion = useCallback(() => dispatch({ type: 'activateNext' }), [dispatch]);

    return {
        suggestions,
        setSuggestions,
        clearSuggestions,

        selected,
        setSelected,
        clearSelection,
        select,
        unselectLast,

        activeSuggestion: active,
        activatePreviousSuggestion,
        activateNextSuggestion,

        get unselectedSuggestions() {
            return suggestions.filter(suggestion => !selected.find(host => host.id === suggestion.id));
        },
    };
}
