/**
 * Covers the functionality of what apiConfig provides in the ng-app with the
 * following API methods: fetchParameter, storeParameter, deleteParameter
 */
import APIRequest from './Request';
import Logger from 'services/logger';
import Parameter, { ParameterDataInterface } from 'models/Parameter';

const logger = Logger.get('APIParameter');

export async function get(parameter: string) {
    const response = await new APIRequest<ParameterDataInterface>({
        path: `config/params/${parameter}`,
    }).request();

    return new Parameter(response.data);
}

export async function store(parameter: string, value: string) {
    const params = {
        name: parameter,
        value: value,
    };

    await new APIRequest<null>({
        path: `config/params`,
        method: 'put',
        params,
    }).request();

    logger.debug(`Stored parameter ${parameter}=${value}`);
}

export async function remove(parameter: string) {
    await new APIRequest<null>({
        path: `config/params/${parameter}`,
        method: 'delete',
    }).request();

    logger.debug(`Removed parameter ${parameter}`);
}
