export interface ParameterDataInterface {
    name: string;
    value: string;
}

export default class Parameter {
    readonly name: string;
    readonly value: string;

    constructor(data: ParameterDataInterface) {
        this.name = data.name;
        this.value = data.value;
    }
}
