import Icon from 'components/icons/Icon';
import { FC } from 'react';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import TextField from 'components/form/fields/TextField';

const AgentsFilter: FC<{ onApply: (text: string) => void }> = ({ onApply }) => {
    const handleApply = (values: { text: string }) => {
        onApply(values.text ? values.text.toLowerCase() : '');
    };

    return (
        <div className="ml3 flex-three-quarters">
            <Form
                initialValues={{ text: undefined }}
                onSubmit={handleApply}
                render={({ pristine, invalid, handleSubmit, submitting, values, form }) => (
                    <form className="flex items-center flex-grow-1 full-height" onSubmit={handleSubmit}>
                        <fieldset className="flex-grow-1 filters__agents__input">
                            <label className="flex items-center">
                                <b className="mr2 fz10 uppercase nowrap">Agent Text</b>
                                <div className="flex-grow-1 relative" placeholder="Filter by agent name">
                                    <Field name="text">
                                        {({ ...props }: FieldRenderProps<string, HTMLInputElement>) => (
                                            <TextField
                                                {...props}
                                                placeholder="Filter by agent name"
                                                data-testid="filter-input"
                                            />
                                        )}
                                    </Field>

                                    {values.text && (
                                        <button
                                            className="link remove absolute top-2 right-2"
                                            data-testid="clear-button"
                                            type="button"
                                            onClick={() => {
                                                form.change('text', '');
                                            }}
                                        >
                                            <Icon icon="close" className="flex fz18"></Icon>
                                        </button>
                                    )}
                                </div>
                            </label>
                        </fieldset>
                        <fieldset>
                            <button
                                disabled={pristine || invalid || submitting}
                                type="submit"
                                className="ml3 mr3 primary-orange"
                            >
                                Apply
                            </button>
                        </fieldset>
                    </form>
                )}
            />
        </div>
    );
};
export default AgentsFilter;
