import TimezoneSelector from 'components/timezone/Selector';
import * as React from 'react';

const EditTimezone: React.FC = () => (
    <>
        <h2 className="flex h2 mt3 lighter grey3 flex-grow-1">Change Timezone</h2>

        <TimezoneSelector isEnvironmentSetting={false} />
    </>
);

export default EditTimezone;
