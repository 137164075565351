import { useState, useCallback } from 'react';
import { AnyObject } from 'final-form';
import ServiceError from 'models/errors/ServiceError';
import Logger from 'services/logger';

interface UseFormSubmitParamsInterface<T> {
    endpoint: string;
    service: (endpoint: string, data: T) => Promise<boolean>;
}

interface UseFormSubmitHook {
    success: boolean;
    error: boolean;
    onSubmit: (data: AnyObject) => Promise<void>;
    submitting: boolean;
    errorResponse: ServiceError | null;
}

function useFormSubmit<T>({ endpoint, service }: UseFormSubmitParamsInterface<T>): UseFormSubmitHook {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errorResponse, setErrorResponse] = useState<ServiceError | null>(null);

    const onSubmit = useCallback(
        async (data: AnyObject) => {
            setError(false);
            setSubmitting(true);

            try {
                // at some point we may want to add an extra parameter to the hook providing a function
                // to check for more complex service return values than boolean or just promise resolution.
                await service(endpoint, data as T);
                setSubmitting(false);
                setSuccess(true);
            } catch (requestError) {
                setSubmitting(false);

                Logger.get('FormSubmit').error(`error sending ${endpoint} request`, requestError);

                let serviceError = requestError;
                if (!(requestError instanceof ServiceError)) {
                    serviceError = new ServiceError(
                        requestError instanceof Error ? requestError.message : 'Something went wrong'
                    );
                }

                setErrorResponse(serviceError as ServiceError);

                setError(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [endpoint, service]
    );

    return { success, error, onSubmit, submitting, errorResponse };
}

export default useFormSubmit;
