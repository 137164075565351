import { useState, useEffect, PropsWithChildren } from 'react';
import FeatureLink from 'components/url/FeatureLink';
import Bouncer from 'components/icons/Bouncer';

export interface SettingOverviewLink {
    href: string;
    text: string;
}

interface OverviewItemRowProps<ResponseType> {
    label: string;
    urlAttrs?: SettingOverviewLink;
    onLoad?: () => Promise<ResponseType>;
    onSuccess?: (data: ResponseType) => void;
    onError?: (e: Error) => void;
}

function OverviewItemRow<ResponseType>({
    label,
    urlAttrs,
    onLoad,
    onSuccess,
    onError,
    children,
}: PropsWithChildren<OverviewItemRowProps<ResponseType>>) {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    async function fetchData() {
        if (!onLoad) {
            setIsLoading(false);
            return;
        }
        try {
            const data = await onLoad();
            if (onSuccess) {
                onSuccess(data);
            }
        } catch (e) {
            if (e instanceof Error && onError) {
                onError(e);
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="h4 lighter border-bottom border-color-grey1 flex py3 line-height-120 items-center">
            <div className="settings__form__label grey3 flex items-center">{label}:</div>
            {isLoading ? <Bouncer className="flex-grow-1"></Bouncer> : children}
            {urlAttrs && (
                <FeatureLink
                    to={urlAttrs.href}
                    className="card-block__login__previous-orgs__org flex items-center rounded no-decoration"
                    appendEnv={false}
                >
                    {urlAttrs.text}
                </FeatureLink>
            )}
        </div>
    );
}

export default OverviewItemRow;
